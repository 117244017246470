import React, { useState, useEffect } from "react";
import Page from "../Page";
import SnakeLoader from "../SnakeLoader";
import axios from "axios";
import * as AA from "../Utilities/AA";

const NovaShareholders = (props) => {
  const [loading, setLoading] = useState(false);
  const [shareholders, setShareholders] = useState({
    Cygnus: {},
    RCW: {},
    Velax: {},
    Xiphias: {},
  });

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const r1 = await axios.get(
        AA.API +
          "atomicassets/v1/templates?collection_name=novarallywax&has_assets=true&order=desc&page=1&schema_name=shares&sort=created"
      );
      const templateDenoms = r1.data.data.map((x) => [
        x.template_id,
        x.immutable_data.Team,
        x.immutable_data.Denomination,
      ]);
      const sh = {
        Velax: {},
        Cygnus: {},
        RCW: {},
        Xiphias: {},
      };
      for (let i = 0; i < templateDenoms.length; i++) {
        const r2 = await axios.get(
          AA.API +
            "atomicassets/v1/accounts?collection_name=novarallywax&schema_name=shares&page=1&limit=100&order=desc&template_id=" +
            templateDenoms[i][0]
        );
        const tempacc = r2.data.data;
        tempacc.forEach((x) => {
          sh[templateDenoms[i][1]][x.account] =
            sh[templateDenoms[i][1]][x.account] | 0;
          sh[templateDenoms[i][1]][x.account] +=
            x.assets * templateDenoms[i][2];
        });
      }
      setShareholders(sh);
      setLoading(false);
    };
    run();
  }, []);

  return (
    <Page title="Nova Rally Super Circuit Shareholders">
      <h2>Super Circuit Shareholders</h2>
      {loading ? (
        <SnakeLoader />
      ) : (
        <div style={{ minWidth: "420px", margin: "auto" }}>
          {Object.entries(shareholders).map(([teamName, teamData]) => {
            const teamTotal = Object.values(teamData).reduce(
              (a, b) => a + b,
              0
            );
            return (
              <div key={teamName}>
                <h2>
                  {teamName} - {teamTotal} shares
                </h2>
                <table
                  style={{ margin: "0px auto", borderSpacing: "10px 4px" }}
                >
                  <thead>
                    <tr>
                      <th style={{ textAlign: "left" }}>WALLET</th>
                      <th>SHARES</th>
                      <th>PERCENTAGE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(teamData)
                      .sort((a, b) => b[1] - a[1])
                      .map(([wallet, numShares]) => {
                        return (
                          <tr key={teamName + wallet}>
                            <td style={{ textAlign: "left" }}>{wallet}</td>
                            <td style={{ textAlign: "right" }}>{numShares}</td>
                            <td style={{ textAlign: "right" }}>
                              {((100 * numShares) / teamTotal).toFixed(2)}%
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            );
          })}
        </div>
      )}
      <br />
      <br />
    </Page>
  );
};

export default NovaShareholders;
