import React, { useEffect, useState } from "react";
import firebase from "../Firebase";
import Page from "../Page";
import SnakeLoader from "../SnakeLoader";
import { RPLANET_ELEMENTS } from "rplanet";

const TriedRecipes = () => {
  if (process.env.NODE_ENV === "development") {
    firebase.functions().useEmulator("localhost", 5001);
  }

  const FILTERS = {
    AAAA: "AAAA",
    AAAB: "AAAB",
    AABB: "AABB",
    AABC: "AABC",
    ABCD: "ABCD",
  };

  const COLUMNS = {
    date: "date",
    recipe: "recipe",
    cost: "cost",
  };

  RPLANET_ELEMENTS["HEAT"].date = "2021-02-17T00:31:13.000Z";

  const [attempts, setAttempts] = useState([]);
  const [filters, setFilters] = useState({
    [FILTERS.AAAA]: true,
    [FILTERS.AAAB]: true,
    [FILTERS.AABB]: true,
    [FILTERS.AABC]: true,
    [FILTERS.ABCD]: true,
  });
  const [elementFilters, setElementFilters] = useState([]);
  const [sortBy, setSortBy] = useState(COLUMNS.date);

  const getPattern = (recipe) => {
    const set = [...new Set(recipe)];
    if (set.length === 1) {
      return "AAAA";
    } else if (set.length === 3) {
      return "AABC";
    } else if (set.length === 4) {
      return "ABCD";
    } else {
      if (recipe[0] === recipe[1] && recipe[0] !== recipe[2]) {
        return "AABB";
      } else {
        return "AAAB";
      }
    }
  };

  useEffect(() => {
    let getTriedRecipes = firebase.functions().httpsCallable("getTriedRecipes");
    getTriedRecipes().then((result) => {
      setAttempts(result.data);
    });
  }, []);

  const toggleFilter = (f) => {
    setFilters((old) => {
      return { ...old, [f]: !filters[f] };
    });
  };

  const toggleFilterOnly = (f) => {
    const off = {
      [FILTERS.AAAA]: false,
      [FILTERS.AAAB]: false,
      [FILTERS.AABB]: false,
      [FILTERS.AABC]: false,
      [FILTERS.ABCD]: false,
    };
    setFilters(() => {
      return { ...off, [f]: true };
    });
  };

  const renderFilterCheck = (f) => {
    return (
      <span key={f}>
        <input
          type="checkbox"
          checked={filters[f]}
          onChange={() => toggleFilter(f)}
          style={{ cursor: "pointer" }}
        />
        <span onClick={() => toggleFilterOnly(f)} style={{ cursor: "pointer" }}>
          {f}
        </span>
        &nbsp;&nbsp;
      </span>
    );
  };

  const toggleElementFilter = (el) => {
    setElementFilters((old) => {
      if (old.includes(el)) {
        return [...old.filter((x) => x !== el)];
      } else {
        return [...old, el];
      }
    });
  };

  return (
    <Page title="RPlanet Tried Recipes">
      <h2>Tried Recipes</h2>
      <div style={{ maxWidth: "640px", fontSize: "80%", margin: "auto" }}>
        {Object.keys(RPLANET_ELEMENTS)
          .filter((x) => RPLANET_ELEMENTS[x].inventor !== "")
          .sort((a, b) => {
            return RPLANET_ELEMENTS[a].date.localeCompare(
              RPLANET_ELEMENTS[b].date
            );
          })
          .map((el) => {
            const yes = elementFilters.includes(el);
            const borderStyle = yes ? "1px solid green" : "1px solid white";
            const backgroundColor = yes ? "yellow" : "inherit";
            const textColor = yes ? "black" : "inherit";
            const br =
              el === "FIRE" || el === "HEAT" ? (
                <div>
                  <br />
                </div>
              ) : null;
            return (
              <React.Fragment key={"filter" + el}>
                <div
                  onClick={() => toggleElementFilter(el)}
                  style={{
                    display: "inline-block",
                    cursor: "pointer",
                    margin: "2px",
                    padding: "2px",
                    paddingLeft: "10px",
                    textAlign: "center",
                    border: borderStyle,
                    backgroundColor: backgroundColor,
                    color: textColor,
                  }}
                >
                  {el}&nbsp;
                </div>
                {br}
              </React.Fragment>
            );
          })}
        <br />
      </div>
      <br />
      <div style={{ marginBottom: "4px" }}>
        {Object.keys(FILTERS).map((f) => renderFilterCheck(f))}
      </div>
      <span style={{ fontSize: "60%" }}>
        Click checkbox to toggle individual, click text to select only that
        pattern
      </span>
      <br />
      {attempts.length === 0 ? (
        <SnakeLoader />
      ) : (
        <table
          style={{
            margin: "auto",
            borderSpacing: "8px 4px",
          }}
        >
          <tbody>
            {attempts
              .filter((e) => {
                const filts = [];
                Object.entries(filters).forEach(([k, v]) => {
                  if (v) {
                    filts.push(k);
                  }
                });
                return filts.includes(getPattern(e.recipe));
              })
              .filter((e) => {
                if (elementFilters.length > 0) {
                  for (let i = 0; i < elementFilters.length; i++) {
                    if (!e.recipe.includes(elementFilters[i])) {
                      return false;
                    }
                  }
                  return true;
                }
                return true;
              })
              .sort((a, b) => {
                if (sortBy === COLUMNS.recipe) {
                  return (
                    a.recipe[0].localeCompare(b.recipe[0]) * 8 +
                    a.recipe[1].localeCompare(b.recipe[1]) * 4 +
                    a.recipe[2].localeCompare(b.recipe[2]) * 2 +
                    a.recipe[3].localeCompare(b.recipe[3])
                  );
                } else if (sortBy === COLUMNS.date) {
                  return new Date(b.date) - new Date(a.date);
                }
                return b[sortBy] - a[sortBy];
              })
              .map((attempt, index, array) => {
                const showCount =
                  index === 0 ? (
                    <tr style={{ fontSize: "80%" }}>
                      <td
                        colSpan="5"
                        style={{ textAlign: "left", paddingBottom: "12px" }}
                      >
                        count: {array.length}
                      </td>
                      <td
                        colSpan="5"
                        style={{ textAlign: "right", paddingBottom: "12px" }}
                      >
                        <div>
                          sort&nbsp;
                          <select
                            value={sortBy}
                            onChange={(e) => setSortBy(e.target.value)}
                          >
                            <option value={COLUMNS.date}>{COLUMNS.date}</option>
                            <option value={COLUMNS.recipe}>
                              {COLUMNS.recipe}
                            </option>
                            <option value={COLUMNS.cost}>{COLUMNS.cost}</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                  ) : null;
                return (
                  <React.Fragment key={attempt.craftNumber}>
                    {showCount}
                    <tr>
                      {Object.entries(attempt).map(([key, val], i) => {
                        if (key === "recipe") {
                          return val.map((v, ii) => (
                            <td key={attempt + "col" + i + ii}>{v}</td>
                          ));
                        } else if (key === "cost") {
                          return (
                            <td
                              key={attempt + "col" + i}
                              style={{ textAlign: "right", fontSize: "85%" }}
                            >
                              {parseInt(val).toLocaleString()}
                            </td>
                          );
                        } else if (key === "craftNumber") {
                          return null;
                        }
                        return (
                          <td
                            key={attempt + "col" + i}
                            style={{ fontSize: "70%" }}
                          >
                            {val}
                          </td>
                        );
                      })}
                    </tr>
                  </React.Fragment>
                );
              })}
          </tbody>
        </table>
      )}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </Page>
  );
};

export default TriedRecipes;
