import { useState, useEffect, useCallback } from "react";
import axios from "axios";

const useTokenPrices = () => {
  const [tokenPrices, setTokenPrices] = useState(null);

  const getTokenPrice = useCallback(
    (tokenPair) => {
      return tokenPrices[tokenPair] ?? 0;
    },
    [tokenPrices]
  );

  useEffect(() => {
    const run = async () => {
      const obj = {};
      try {
        const r1 = await axios.get("https://wax.alcor.exchange/api/markets");
        const rows = r1.data;
        for (let i = 0; i < rows.length; i++) {
          const token =
            rows[i].quote_token.symbol.name + rows[i].base_token.symbol.name;
          obj[token] = rows[i].last_price;
        }
        const r2 = axios.get(
          "https://api.coingecko.com/api/v3/simple/price?ids=wax&vs_currencies=usd"
        );
        obj["WAXUSD"] = (await r2).data.wax.usd;
      } catch (e) {
        console.log(e);
      }
      setTokenPrices(obj);
    };

    run();
  }, []);

  return [tokenPrices, getTokenPrice];
};

export default useTokenPrices;
