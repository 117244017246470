import React, { useRef } from "react";
import { Link } from "react-router-dom";
import RaceModal from "./RaceModal";

const RARITY_COLOR = {
  COMMON: "#DAA87A",
  UNCOMMON: "#999999",
  RARE: "#FFD700",
  CLASSIC: "#5577FF",
  SKETCH: "#FFFFFF",
  Unknown: "#FFFFFF",
};

const POSITION_COLOR = {
  1: "#F1D350",
  2: "#EFDFA7",
  3: "#D89C42",
  4: "#B9B2A9",
  5: "#B9B2A9",
  6: "#B9B2A9",
  7: "#B9B2A9",
  8: "#B9B2A9",
  9: "#B9B2A9",
};

const LEAGUE_SYMBOL = {
  ROOKIE: "R",
  INTERMEDIATE: "I",
  VETERAN: "V",
  MASTER: "M",
  Unknown: "#FFFFFF",
};

const colorShade = (col, amt) => {
  col = col.replace(/^#/, "");
  if (col.length === 3)
    col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];

  let [r, g, b] = col.match(/.{2}/g);
  [r, g, b] = [
    parseInt(r, 16) + amt,
    parseInt(g, 16) + amt,
    parseInt(b, 16) + amt,
  ];

  r = Math.max(Math.min(255, r), 0).toString(16);
  g = Math.max(Math.min(255, g), 0).toString(16);
  b = Math.max(Math.min(255, b), 0).toString(16);

  const rr = (r.length < 2 ? "0" : "") + r;
  const gg = (g.length < 2 ? "0" : "") + g;
  const bb = (b.length < 2 ? "0" : "") + b;

  return `#${rr}${gg}${bb}`;
};

const NovaLeague = ({ league, rarity }) => {
  rarity = rarity ? rarity.toUpperCase() : "SKETCH";
  league = league ? league.toUpperCase() : "ROOKIE";
  let stars = null;
  const starStyle = {
    color: colorShade(RARITY_COLOR[rarity], 40),
    position: "absolute",
    bottom: "-8px",
    WebkitTextStroke: "1px " + colorShade(RARITY_COLOR[rarity], -160),
  };
  const starCharacterOuter = {
    position: "relative",
    height: "6px",
    width: "6px",
    backgroundColor: colorShade(RARITY_COLOR[rarity], -80),
    borderRadius: "50%",
    display: "inline-block",
    boxShadow: "0 0 2px black",
  };
  const starCharacterMid = {
    position: "absolute",
    top: "1px",
    left: "1px",
    height: "4px",
    width: "4px",
    backgroundColor: colorShade(RARITY_COLOR[rarity], -40),
    borderRadius: "50%",
    display: "inline-block",
  };
  const starCharacterInner = {
    position: "absolute",
    top: "1px",
    left: "1px",
    height: "2px",
    width: "2px",
    backgroundColor: colorShade(RARITY_COLOR[rarity], 40),
    borderRadius: "50%",
    display: "inline-block",
  };
  const star = (left) => {
    return (
      <div style={{ ...starStyle, left: left + "px" }}>
        <div style={starCharacterOuter}>
          <div style={starCharacterMid}>
            <div style={starCharacterInner}></div>
          </div>
        </div>
      </div>
    );
  };
  if (league === "INTERMEDIATE") {
    stars = <div>{star("3.5")}</div>;
  } else if (league === "VETERAN") {
    stars = (
      <div>
        {star("-3")}
        {star("10")}
      </div>
    );
  } else if (league === "MASTER") {
    stars = (
      <div>
        {star("-3")}
        {star("3.5")}
        {star("10")}
      </div>
    );
  }
  return (
    <div
      style={{
        position: "relative",
        display: "inline-block",
        border: "2px solid",
        borderColor: colorShade(RARITY_COLOR[rarity], -40),
        borderRadius: "4px",
        padding: "0px 3px",
        fontSize: "90%",
        fontWeight: "bold",
        backgroundColor: "white",
        color: "black",
        WebkitTextStroke: "0.25px #000",
      }}
    >
      {LEAGUE_SYMBOL[league]}
      {stars}
    </div>
  );
};

const NovaLeagueTableData = ({ league, rarity }) => {
  return (
    <td>
      <NovaLeague league={league} rarity={rarity} />
    </td>
  );
};

const NovaPosition = ({ position }) => {
  position = position ?? 8;

  return (
    <div
      style={{
        position: "relative",
        display: "inline-block",
        border: "2px solid",
        borderColor: colorShade(POSITION_COLOR[position], -80),
        borderRadius: "4px",
        padding: "0px 3px",
        fontSize: "90%",
        fontWeight: "bold",
        backgroundColor: POSITION_COLOR[position],
        color: "black",
      }}
    >
      {position}
    </div>
  );
};

const NovaPositionTableData = ({ position }) => {
  return (
    <td>
      <NovaPosition position={position} />
    </td>
  );
};

const NovaName = ({ name }) => {
  name = name && name !== "" ? name : "Unknown";
  if (name === "Otis Carlton Daniels") name = "OCD";
  else if (name === "Sammy Snake") name = "Sammy";
  else if (name === "George Square") name = "George";
  else if (name === "King Earl") name = "Earl";
  else if (name === "Scapegrace") name = "Grace";
  else if (name === "Salamander") name = "Salamndr";
  else if (name === "Prizefighter") name = "Przfghtr";

  return (
    <div
      style={{
        display: "inline-block",
        fontWeight: "bold",
        paddingLeft: "4px",
      }}
    >
      {name}
    </div>
  );
};

const NovaColorName = ({ name, rarity, league, width }) => {
  rarity = rarity ?? "SKETCH";
  league = league ?? "ROOKIE";
  width = width ?? "78px";

  rarity = rarity.toUpperCase();

  return (
    <div
      style={{
        color: RARITY_COLOR[rarity],
        width: width,
      }}
    >
      <nobr>
        <NovaLeague league={league} rarity={rarity} />
        <NovaName name={name} />
      </nobr>
    </div>
  );
};

const NovaColorTime = ({ time }) => {
  const formatRaceTime = (ms) => {
    return (
      (ms > 3600000 ? parseInt(ms / 3600000) + ":" : "") +
      parseInt((ms % 3600000) / 60000)
        .toString()
        .padStart(2, "0") +
      ":" +
      ((ms % 60000) / 1000).toFixed(3).toString().padStart(6, "0")
    );
  };

  const speed = time <= 1200000 ? 1200000 - time : 0;
  const yellow = 127 - Math.abs(42500 - speed) / 335;
  const cc = Math.floor(speed / 335);
  const speedColorG = parseInt(yellow + cc / 1.2).toString(16);
  const speedColorR = parseInt(yellow + (255 - cc) / 1.4).toString(16);

  const hex =
    "#" + speedColorR.padStart(2, "0") + speedColorG.padStart(2, "0") + "00";

  let t1, t2;
  if (time <= 1200000) {
    const t = formatRaceTime(time).split(".");
    t1 = t[0];
    t2 = t[1];
  } else {
    t1 = "DNF";
    t2 = "";
  }

  return (
    <span
      style={{
        color: hex,
        fontWeight: "bold",
      }}
    >
      {t1}
      <span
        style={{
          verticalAlign: "top",
          fontSize: "80%",
          paddingLeft: "2px",
        }}
      >
        {t2}
      </span>
    </span>
  );
};

const NovaTimeTableData = ({ time }) => {
  return (
    <td>
      <NovaColorTime time={time} />
    </td>
  );
};

const NovaDateTableData = ({ date }) => {
  return (
    <td style={{ fontSize: "85%" }}>
      <NovaDate date={date} />
    </td>
  );
};

const NovaDate = ({ date }) => {
  let d = new Date(date * 1000).toUTCString();
  d = d.split(" ").slice(1, -1);
  const t = d.pop().split(":").slice(0, -1).join(":");
  d = d.join(" ").replace("2021", "21");
  return (
    <>
      <nobr>{d}</nobr>&nbsp;
      <nobr>{t}</nobr>
    </>
  );
};

const NovaComboTableData = ({ combo, width }) => {
  return (
    <React.Fragment>
      <td>
        <NovaColorName
          name={combo.vehicleName}
          rarity={combo.vehicleRarity}
          league={combo.vehicleLeague}
          width={width}
        />
      </td>
      <td>
        <NovaColorName
          name={combo.driver1Name}
          rarity={combo.driver1Rarity}
          league={combo.driver1League}
          width={width}
        />
      </td>
      <td>
        <NovaColorName
          name={combo.driver2Name}
          rarity={combo.driver2Rarity}
          league={combo.driver2League}
          width={width}
        />
      </td>
    </React.Fragment>
  );
};

const NovaWalletTableData = ({ wallet }) => {
  return (
    <td style={{ textAlign: "left" }}>
      <Link to={"/nova/racer/" + wallet}> {wallet}</Link>
    </td>
  );
};

const NovaRaceIdTableData = ({ id }) => {
  const raceModal = useRef(null);
  return (
    <td
      style={{
        cursor: "pointer",
        textAlign: "center",
        border: "1px solid #666",
        borderRadius: "4px",
        padding: "0 3px",
      }}
    >
      <span
        style={{ cursor: "pointer" }}
        onClick={() => raceModal.current.open(id)}
      >
        {id}
      </span>
      <RaceModal ref={raceModal} />
    </td>
  );
};

const NovaBoostTableData = ({ boost }) => {
  return (
    <td>
      {boost ? (
        <div
          style={{
            position: "relative",
            display: "inline-block",
            border: "2px solid",
            borderColor: "#0036C4",
            borderRadius: "4px",
            padding: "0px 3px",
            fontSize: "90%",
            fontWeight: "bold",
            backgroundColor: "#E047DC",
            color: "#0036C4",
            WebkitTextStroke: "0.25px #000",
          }}
        >
          B
        </div>
      ) : (
        <div>&nbsp;</div>
      )}
    </td>
  );
};

export {
  NovaLeague,
  NovaLeagueTableData,
  NovaPosition,
  NovaPositionTableData,
  NovaColorName,
  NovaColorTime,
  NovaDate,
  NovaComboTableData,
  NovaDateTableData,
  NovaTimeTableData,
  NovaWalletTableData,
  NovaRaceIdTableData,
  NovaBoostTableData,
};
