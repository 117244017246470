import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Page from "../Page";
import { linkNewWindow } from "../Utilities/LinkUtilities";
import { rpc } from "../Utilities/RPC";
import WalletInput from "../Utilities/WalletInput";
import * as C from "./Constants";
import * as API from "./API";
import SnakeLoader from "../SnakeLoader";
import * as AA from "../Utilities/AA";

const TrainBuilder = (props) => {
  const [loading, setLoading] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);
  const [currentTrain, setCurrentTrain] = useState({
    railroader: "",
    train: "",
    locomotive: "",
    conductor: "",
    loads: [],
  });
  const [allAssets, setAllAssets] = useState({
    train: [],
    locomotive: [],
    conductor: [],
    railcar: [],
    commodity: [],
    passengercar: [],
    passenger: [],
  });
  const [wallet, setWallet] = useState(props.match.params.wallet || "");
  const history = useHistory();

  const handleSetWallet = (w) => {
    history.push("/trains/build/" + w);
    setWallet(w);
  };

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      if (wallet) {
        setCurrentTrain({
          railroader: wallet,
          train: "",
          locomotive: "",
          conductor: "",
          loads: [],
        });
        setAllAssets({
          train: [],
          locomotive: [],
          conductor: [],
          railcar: [],
          commodity: [],
          passengercar: [],
          passenger: [],
        });

        const rarityRates = await API.getRarityRates("modern");
        const commValues = await API.getCommodityValues("modern", rarityRates);
        const stations = await API.getStations("modern");
        const passBasePay = await API.getPassengerBasePay("modern");

        const getEquippedAssets = async (lowerBound = "", result = []) => {
          const r = await rpc.get_table_rows({
            code: "rr.century",
            table: "assets",
            scope: wallet,
            limit: 1000,
            lower_bound: lowerBound,
          });
          if (r.rows.length > 0) {
            result = result.concat(r.rows);
            const lb = parseInt(r.rows[r.rows.length - 1].asset_id) + 1;
            return getEquippedAssets(lb, result);
          } else {
            return result.map((x) => x.asset_id);
          }
        };
        const _equippedAssets = await getEquippedAssets();

        const getUserTrains = async () => {
          const r = await rpc.get_table_rows({
            code: "rr.century",
            table: "trains",
            scope: wallet,
            limit: 1000,
          });
          const r2 = await rpc.get_table_rows({
            code: "rr.century",
            table: "upgrades",
            scope: wallet,
            limit: 1000,
          });
          return r.rows.map((x, i) => {
            const u = r2.rows.find((xx) => xx.train === x.train);
            const station = stations.find(
              (s) => s.station_id === x.current_station
            );
            return {
              ...x,
              schema: "train",
              asset_id: x.train,
              available: x.locomotives.length === 0,
              num_railcars: u ? u.extra_rc_slot + 1 : 1,
              station_region_id: station.region_id,
              station_rarity: station.rarity,
            };
          });
        };

        const getUserAssets = async (pageNumber = 1, result = []) => {
          const r = await axios.get(
            AA.API +
              "atomicassets/v1/assets?" +
              "collection_name=centurytrain&owner=" +
              wallet +
              "&limit=1000&order=asc&sort=asset_id&page=" +
              pageNumber
          );
          if (r.data.data.length > 0) {
            result = result.concat(r.data.data);
            pageNumber += 1;
            return getUserAssets(pageNumber, result);
          } else {
            return result.map((x) => {
              return {
                ...x.data,
                schema: x.schema.schema_name,
                template_id: x.template.template_id,
                asset_id: x.asset_id,
                mint: x.template_mint,
                available: _equippedAssets.indexOf(x.asset_id) === -1,
              };
            });
          }
        };

        const ut = await getUserTrains();
        setAllAssets((old) => {
          return { ...old, train: ut };
        });

        const ua = await getUserAssets();
        let a = ua
          .filter((x) => x.schema === "locomotive")
          .map((x) => {
            return {
              ...x,
              total_haul: x.hauling_power + (x.haul_boost ?? 0),
              total_speed: x.speed + (x.speed_boost ?? 0),
              total_distance: x.distance + (x.distance_boost ?? 0),
              total_luck: x.luck_boost ?? 0,
            };
          })
          .sort((a, b) => {
            return (
              b.total_haul - a.total_haul ||
              b.total_speed - a.total_speed ||
              b.total_distance - a.total_distance ||
              b.total_luck - a.total_luck ||
              C.RARITY_ORDER.indexOf(b.rarity) -
                C.RARITY_ORDER.indexOf(a.rarity) ||
              a.name.localeCompare(b.name)
            );
          });
        setAllAssets((old) => {
          return { ...old, locomotive: a };
        });

        a = ua
          .filter((x) => x.schema === "conductor")
          .sort((a, b) => {
            return (
              C.RARITY_ORDER.indexOf(b.rarity) -
                C.RARITY_ORDER.indexOf(a.rarity) || a.name.localeCompare(b.name)
            );
          });
        setAllAssets((old) => {
          return { ...old, conductor: a };
        });

        a = ua
          .filter((x) => x.schema === "railcar")
          .map((x) => {
            return { ...x, name: x.name.charAt(0) };
          })
          .sort((a, b) => {
            return (
              a.type.localeCompare(b.type) ||
              C.RARITY_ORDER.indexOf(b.rarity) -
                C.RARITY_ORDER.indexOf(a.rarity)
            );
          });
        setAllAssets((old) => {
          return { ...old, railcar: a };
        });

        a = ua
          .filter((x) => x.schema === "commodity")
          .map((x) => {
            return {
              ...x,
              value: commValues[x.template_id],
            };
          })
          .sort((a, b) => {
            return (
              a.type.localeCompare(b.type) ||
              C.RARITY_ORDER.indexOf(b.rarity) -
                C.RARITY_ORDER.indexOf(a.rarity) ||
              b.volume - a.volume ||
              b.value - a.value ||
              a.name.localeCompare(b.name)
            );
          });
        setAllAssets((old) => {
          return { ...old, commodity: a };
        });

        a = ua
          .filter((x) => x.schema === "passengercar")
          .map((x) => {
            return {
              ...x,
              name: x.seats === 3 ? "L" : x.seats === 2 ? "M" : "S",
            };
          })
          .sort(
            (a, b) =>
              C.RARITY_ORDER.indexOf(b.rarity) -
              C.RARITY_ORDER.indexOf(a.rarity)
          );
        setAllAssets((old) => {
          return { ...old, passengercar: a };
        });

        a = ua
          .filter((x) => x.schema === "passenger")
          .map((x) => {
            return {
              ...x,
              value: passBasePay.find(
                (xx) => xx.template_id.toString() === x.template_id
              ).base,
            };
          })
          .sort(
            (a, b) =>
              C.RARITY_ORDER.indexOf(b.rarity) -
                C.RARITY_ORDER.indexOf(a.rarity) || a.name.localeCompare(b.name)
          );
        setAllAssets((old) => {
          return { ...old, passenger: a };
        });
      }
      setLoading(false);
    };
    run();
  }, [wallet, refreshCount]);

  const refresh = () => {
    setRefreshCount((old) => old + 1);
  };

  const getCellData = (
    data,
    align = "right",
    color = "white",
    bold = "default",
    onClick = () => {}
  ) => {
    return {
      data: data,
      align: align,
      color: color,
      fontWeight: bold,
      onClick: onClick,
    };
  };

  const getTrainData = (data) => {
    return [
      getCellData(data.num_railcars, "right", "orange"),
      getCellData(data.train, "left"),
      getCellData(C.REGIONS_SHORT[data.station_region_id], "left"),
      getCellData(
        C.STATION_NAMES[data.current_station],
        "left",
        C.RARITY_COLORS[data.station_rarity]
      ),
    ];
  };

  const getLocoData = (data) => {
    return [
      getCellData(data.name, "left", C.RARITY_COLORS[data.rarity]),
      getCellData(data.conductor_threshold),
      getCellData(data.composition.charAt(0)),
      getCellData(data.total_haul),
      getCellData(data.haul_boost ?? 0, "right", "green"),
      getCellData(data.total_speed),
      getCellData(data.speed_boost ?? 0, "right", "green"),
      getCellData(data.total_distance),
      getCellData(data.distance_boost ?? 0, "right", "green"),
      getCellData(data.total_luck),
      getCellData(data.luck_boost ?? 0, "right", "green"),
    ];
  };

  const getCondData = (data) => {
    return [
      getCellData(data.name, "left", C.RARITY_COLORS[data.rarity]),
      getCellData(data.conductor_level),
      getCellData(data.perk.split(" ").shift().charAt(0), "left"),
      getCellData(data.perk_boost),
      getCellData(
        data.perk2 ? data.perk2.split(" ").shift().charAt(0) : "",
        "left"
      ),
      getCellData(data.perk_boost2 ? data.perk_boost2 : ""),
    ];
  };

  const getRailData = (data) => {
    const oc = () => addRailcar(data);
    return [
      getCellData(data.amount, "right", "orange", true, oc),
      getCellData(data.name, "left", C.RARITY_COLORS[data.rarity], true, oc),
      getCellData(data.capacity, "right", "white", "default", oc),
      getCellData(data.type.split("_").shift(), "left", "white", "default", oc),
      getCellData(
        data.commodity_type.split("_").shift(),
        "left",
        "white",
        "default",
        () => addRailcar(data, data.commodity_type.trim())
      ),
      getCellData(data.commodity_type2, "left", "white", "default", () =>
        addRailcar(data, data.commodity_type2)
      ),
    ];
  };

  const getCommData = (data) => {
    return [
      getCellData(data.amount, "right", "orange", true),
      getCellData(data.name, "left", C.RARITY_COLORS[data.rarity]),
      getCellData(data.volume),
      getCellData(data.weight),
      getCellData(data.type.split("_").shift(), "left"),
    ];
  };

  const getPassCarData = (data) => {
    const oc = () => addPassCar(data);
    return [
      getCellData(data.amount, "right", "orange", true, oc),
      getCellData(data.name, "left", C.RARITY_COLORS[data.rarity], true, oc),
      getCellData(
        data.seats,
        "right",
        C.RARITY_COLORS[data.rarity],
        "default",
        oc
      ),
      getCellData("passenger", "right", "white", "default", oc),
      getCellData(data.weight, "right", "white", "default", oc),
      getCellData("D", "center", "white", "default", () =>
        addPassCar(data, "D")
      ),
      getCellData("L", "center", "white", "default", () =>
        addPassCar(data, "L")
      ),
      getCellData("T", "center", "white", "default", () =>
        addPassCar(data, "T")
      ),
      getCellData("W", "center", "white", "default", () =>
        addPassCar(data, "W")
      ),
    ];
  };

  const getPassData = (data) => {
    return [
      getCellData(data.amount, "right", "orange", true),
      getCellData(data.name, "left", C.RARITY_COLORS[data.rarity]),
      getCellData(C.REGIONS_SHORT[data.home_regionid], "left"),
      getCellData(data.tip),
      getCellData(data.criterion.charAt(0), "center"),
      getCellData(data.threshold),
    ];
  };

  const renderTableRow = (schema, assetData, index) => {
    let rowData = [];
    switch (schema) {
      case "train":
        rowData = getTrainData(assetData);
        break;
      case "locomotive":
        rowData = getLocoData(assetData);
        break;
      case "conductor":
        rowData = getCondData(assetData);
        break;
      case "railcar":
        rowData = getRailData(assetData);
        break;
      case "commodity":
        rowData = getCommData(assetData);
        break;
      case "passengercar":
        rowData = getPassCarData(assetData);
        break;
      case "passenger":
        rowData = getPassData(assetData);
        break;
      default:
        break;
    }
    if (assetData.available) {
      switch (schema) {
        case "train":
        case "locomotive":
        case "conductor":
          rowData = rowData.map((x) => {
            return {
              ...x,
              onClick: () => addToTrain(schema, assetData.asset_id),
              cursor: "pointer",
            };
          });
          break;
        case "railcar":
        case "passengercar":
          rowData = rowData.map((x) => {
            return {
              ...x,
              cursor: "pointer",
            };
          });
          break;
        default:
          break;
      }
    }
    return (
      <tr key={schema + "-" + index}>
        {rowData.map((cellData, i) => {
          return (
            <td
              key={schema + "-" + index + "-" + i}
              onClick={assetData.available ? cellData.onClick : () => {}}
              style={{
                cursor: cellData.cursor,
                color: cellData.color,
                textAlign: cellData.align,
                fontWeight: cellData.fontWeight,
              }}
            >
              {cellData.data}
            </td>
          );
        })}
      </tr>
    );
  };

  const groupAssets = (a) => {
    let b = [];
    let prevTemplate = "";
    for (let i = 0; i < a.length; i++) {
      if (a[i].template_id === prevTemplate) {
        b[b.length - 1].amount += 1;
      } else {
        a[i].amount = 1;
        b.push(a[i]);
      }
      prevTemplate = a[i].template_id;
    }
    return b;
  };

  const renderAssets = (available, schema) => {
    let a = allAssets[schema].filter((x) => x.available === available);
    if (
      schema === "commodity" ||
      schema === "railcar" ||
      schema === "passengercar" ||
      schema === "passenger"
    ) {
      a = groupAssets(a);
    }
    return allAssets[schema] ? (
      <div>
        {a.length === 0
          ? "(no " +
            (schema === "commodity" ? "commoditie" : schema) +
            "s available)"
          : ""}
        <table style={{ margin: "0px auto" }}>
          <tbody>
            {a.map((row, i) => {
              return renderTableRow(schema, row, i);
            })}
            <tr></tr>
          </tbody>
        </table>
      </div>
    ) : null;
  };

  const addToTrain = (schema, asset_id) => {
    let oldAssets = JSON.parse(JSON.stringify(allAssets));
    oldAssets = toggleAvailable(oldAssets, schema, asset_id);
    if (currentTrain[schema]) {
      oldAssets = toggleAvailable(oldAssets, schema, currentTrain[schema]);
    }
    setAllAssets(oldAssets);
    setCurrentTrain((old) => {
      return {
        ...old,
        [schema]: asset_id,
      };
    });
  };

  const getColorName = (name, rarity) => {
    return <span style={{ color: C.RARITY_COLORS[rarity] }}>{name}</span>;
  };

  const getTrainByAssetId = (asset_id) => {
    if (asset_id) {
      return allAssets["train"].find((x) => x.asset_id === asset_id).train;
    } else {
      return "(no train)";
    }
  };

  const getLocoByAssetId = (asset_id) => {
    if (asset_id) {
      const data = allAssets["locomotive"].find((x) => x.asset_id === asset_id);
      return (
        <span>
          {getColorName(data.name, data.rarity)}&nbsp;
          {data.conductor_threshold}&nbsp;
          {data.total_haul}&nbsp;
          {data.total_speed}&nbsp;
          {data.total_distance}&nbsp;
          {data.total_luck}
        </span>
      );
    } else {
      return "(no locomotive)";
    }
  };

  const getCondByAssetId = (asset_id) => {
    if (asset_id) {
      const data = allAssets["conductor"].find((x) => x.asset_id === asset_id);
      return (
        <span>
          {getColorName(data.name, data.rarity)}&nbsp;
          {data.conductor_level}&nbsp;
          {data.perk}&nbsp;
          {data.perk_boost}&nbsp;
          {data.perk2}&nbsp;
          {data.perk_boost2}&nbsp;
        </span>
      );
    } else {
      return "(no conductor)";
    }
  };

  const getRailByAssetId = (asset_id) => {
    const raildata = allAssets["railcar"].find((x) => x.asset_id === asset_id);
    const passdata = allAssets["passengercar"].find(
      (x) => x.asset_id === asset_id
    );
    if (raildata) {
      return (
        <span>
          {getColorName(raildata.name, raildata.rarity)}&nbsp;
          {raildata.capacity}&nbsp;
          {raildata.type.split("_").shift()}&nbsp;
        </span>
      );
    } else if (passdata) {
      return (
        <span>
          {getColorName(passdata.name, passdata.rarity)}&nbsp;
          {getColorName(passdata.seats, passdata.seats)}&nbsp;
          {passdata.weight}&nbsp;
        </span>
      );
    }
  };

  const getCommByAssetId = (asset_id) => {
    const commdata = allAssets["commodity"].find(
      (x) => x.asset_id === asset_id
    );
    const passdata = allAssets["passenger"].find(
      (x) => x.asset_id === asset_id
    );
    if (commdata) {
      return (
        <span>
          {commdata.type.split("_").shift()}&nbsp;
          {getColorName(commdata.name, commdata.rarity)}&nbsp;
          {commdata.volume}&nbsp;
          {commdata.weight}&nbsp;
        </span>
      );
    } else if (passdata) {
      return (
        <span>
          {getColorName(passdata.name, passdata.rarity)}&nbsp;
          {C.REGIONS_SHORT[passdata.home_regionid]}&nbsp;
          {passdata.tip}&nbsp;
          {passdata.criterion}&nbsp;
          {passdata.threshold}&nbsp;
        </span>
      );
    }
  };

  const getCommValues = (commsOrPassengers) => {
    let commValue = 0;
    let passValue = 0;
    for (let i = 0; i < commsOrPassengers.length; i++) {
      const comm = allAssets.commodity.find(
        (x) => x.asset_id === commsOrPassengers[i]
      );
      const pass = allAssets.passenger.find(
        (x) => x.asset_id === commsOrPassengers[i]
      );
      if (comm) {
        commValue += parseInt(comm.value);
      } else {
        passValue += parseInt(pass.value);
      }
    }
    const DISTANCE = 40;
    if (commValue === 0) {
      return passValue;
    }
    return (
      passValue +
      (1 + 0.19 * Math.pow((commValue * DISTANCE) / 1000, 1.05))
    ).toFixed(2);
  };

  const renderCurrentTrain = () => {
    return (
      <div>
        <div>{getTrainByAssetId(currentTrain.train)}</div>
        <div>{getLocoByAssetId(currentTrain.locomotive)}</div>
        <div>{getCondByAssetId(currentTrain.conductor)}</div>
        <br />
        <div>
          {renderWeights()}&nbsp;&nbsp;{renderNumRailcars()}&nbsp;&nbsp;
          <span style={{ fontWeight: "bold", color: "yellow" }}>
            {getCommValues(
              [].concat.apply(
                [],
                currentTrain.loads.map((x) => x.load_ids)
              )
            )}
          </span>
        </div>
        <br />
        <div>
          {currentTrain.loads.map((load, i) => {
            return (
              <div key={"train-load-" + i}>
                <div>{getRailByAssetId(load.railcar_asset_id)}</div>
                <div
                  style={{
                    fontWeight: "bold",
                    color: "yellow",
                  }}
                >
                  {getCommValues(load.load_ids)}
                </div>
                {load.load_ids.map((comm_id, j) => {
                  return (
                    <div key={"train-comm-" + i + "-" + j}>
                      {getCommByAssetId(comm_id)}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderTrainLink = () => {
    const link =
      "https://wax.bloks.io/account/rr.century?loadContract=true&tab=Actions&account=rr.century&scope=rr.century&action=settrain" +
      "&railroader=" +
      currentTrain.railroader +
      "&train=" +
      currentTrain.train +
      "&locomotives=" +
      JSON.stringify([currentTrain.locomotive]) +
      "&conductors=" +
      JSON.stringify([currentTrain.conductor]) +
      "&loads=" +
      JSON.stringify(currentTrain.loads);
    return (
      <div
        style={{
          fontSize: "120%",
          fontWeight: "bold",
          textDecoration: "underline",
          cursor: "pointer",
        }}
        onClick={() => linkNewWindow(link)}
      >
        SET TRAIN ON BLOKS
      </div>
    );
  };

  const getOptimizedLoad = (volume, comms) => {
    const getPossibleVolumes = (volume, sizes) => {
      const sizeArrays = [];
      const goDeeper = (maxVolume, remainingSizes, sizeArray = []) => {
        const size = remainingSizes.shift();

        while (true) {
          if (remainingSizes.length > 0) {
            goDeeper(maxVolume, [...remainingSizes], [...sizeArray]);
          }
          const sum = sizeArray.reduce((a, b) => a + b, 0);
          if (size <= maxVolume - sum) {
            sizeArray.push(size);
          } else {
            if (remainingSizes.length === 0) {
              sizeArrays.push(sizeArray);
            } else if (remainingSizes.at(-1) + sum >= maxVolume) {
              sizeArrays.push(sizeArray);
            }
            break;
          }
        }
      };

      goDeeper(volume, sizes);

      return sizeArrays;
    };

    const sizes = [...new Set(comms.map((item) => parseInt(item.volume)))].sort(
      (a, b) => b - a
    );
    const volumes = getPossibleVolumes(volume, sizes);
    const commArrays = [];
    for (let i = 0; i < volumes.length; i++) {
      const commsCopy = [...comms].sort((a, b) => {
        return (
          b.volume - a.volume ||
          b.value - a.value ||
          a.type.localeCompare(b.type) ||
          C.RARITY_ORDER.indexOf(b.rarity) - C.RARITY_ORDER.indexOf(a.rarity) ||
          a.name.localeCompare(b.name)
        );
      });
      const volumeArray = volumes[i];
      const commArray = [];
      let totalValue = 0;
      let totalVolume = 0;
      let totalWeight = 0;
      for (let j = 0; j < volumeArray.length; j++) {
        const volume = volumeArray[j];
        const iii = commsCopy.findIndex((x) => parseInt(x.volume) === volume);
        if (iii > -1) {
          const comm = commsCopy.splice(iii, 1)[0];
          totalValue += comm.value;
          totalVolume += parseInt(comm.volume);
          totalWeight += parseInt(comm.weight);
          commArray.push(comm);
        }
      }
      commArrays.push({
        load: commArray,
        value: totalValue,
        volume: totalVolume,
        weight: totalWeight,
      });
    }
    if (commArrays.length > 0) {
      commArrays.sort((a, b) => b.value - a.value);
      return commArrays[0].load;
    }
  };

  const addRailcar = (railcar, commodityType) => {
    const commTypes = commodityType
      ? [commodityType]
      : C.RAILCAR_COMMODITY_TYPES[railcar.type];
    const comms = allAssets.commodity.filter(
      (x) => x.available && commTypes.includes(x.type)
    );
    if (railcar && comms.length > 0 && currentTrain.loads.length < 8) {
      const railcarComms = getOptimizedLoad(railcar.capacity, comms);
      let old = JSON.parse(JSON.stringify(allAssets));
      old = toggleAvailable(old, "railcar", railcar.asset_id);
      for (let i = 0; i < railcarComms.length; i++) {
        old = toggleAvailable(old, "commodity", railcarComms[i].asset_id);
      }
      setAllAssets(old);
      setCurrentTrain((old) => {
        return {
          ...old,
          loads: [
            ...old.loads,
            {
              railcar_asset_id: railcar.asset_id,
              load_ids: railcarComms.map((x) => x.asset_id),
            },
          ],
        };
      });
    }
  };

  const addPassCar = (passcar, passengerType) => {
    const type = passengerType ? C.PASSENGER_TYPES[passengerType] : null;
    const passengers = allAssets.passenger.filter(
      (x) => x.available && (type ? x.criterion === type : true)
    );
    if (passcar && passengers.length > 0 && currentTrain.loads.length < 8) {
      let old = JSON.parse(JSON.stringify(allAssets));
      const passengerIds = [];
      old = toggleAvailable(old, "passengercar", passcar.asset_id);
      for (let i = 0; i < Math.min(passengers.length, passcar.seats); i++) {
        const id = passengers[i].asset_id;
        passengerIds.push(id);
        old = toggleAvailable(old, "passenger", id);
      }
      setAllAssets(old);
      setCurrentTrain((old) => {
        return {
          ...old,
          loads: [
            ...old.loads,
            {
              railcar_asset_id: passcar.asset_id,
              load_ids: passengerIds,
            },
          ],
        };
      });
    }
  };

  const removeRailcar = () => {
    if (currentTrain.loads.length > 0) {
      let oldAssets = JSON.parse(JSON.stringify(allAssets));
      const oldLoads = JSON.parse(JSON.stringify(currentTrain.loads));
      const last = oldLoads.pop();
      oldAssets = toggleAvailable(oldAssets, "railcar", last.railcar_asset_id);
      oldAssets = toggleAvailable(
        oldAssets,
        "passengercar",
        last.railcar_asset_id
      );
      for (let i = 0; i < last.load_ids.length; i++) {
        oldAssets = toggleAvailable(oldAssets, "commodity", last.load_ids[i]);
        oldAssets = toggleAvailable(oldAssets, "passenger", last.load_ids[i]);
      }
      setAllAssets(oldAssets);
      setCurrentTrain((old) => {
        return { ...old, loads: oldLoads };
      });
    }
  };

  const toggleAvailable = (old, schema, assetId) => {
    const index = old[schema].findIndex((x) => x.asset_id === assetId);
    if (index > -1) {
      old[schema][index].available = !old[schema][index].available;
    }
    return old;
  };

  const getWeightLimit = () => {
    let weightLimit = 0;
    if (currentTrain.locomotive) {
      weightLimit =
        allAssets.locomotive.find((x) => x.asset_id === currentTrain.locomotive)
          .total_haul * 10;
      if (currentTrain.conductor) {
        const cond = allAssets.conductor.find(
          (x) => x.asset_id === currentTrain.conductor
        );
        if (cond.perk === "Hauling Power") {
          weightLimit *= 1 + cond.perk_boost / 100;
        } else if (cond.perk2 === "Hauling Power") {
          weightLimit *= 1 + cond.perk_boost2 / 100;
        }
        weightLimit = parseInt(weightLimit);
      }
    }
    return weightLimit;
  };

  const getCurrentWeight = () => {
    let currentWeight = 0;
    if (currentTrain.loads.length > 0) {
      for (let i = 0; i < currentTrain.loads.length; i++) {
        const ids = currentTrain.loads[i].load_ids;
        const isComms = allAssets.commodity.find((x) => x.asset_id === ids[0]);
        if (isComms) {
          for (let j = 0; j < ids.length; j++) {
            const comm = allAssets.commodity.find((x) => x.asset_id === ids[j]);
            currentWeight += comm ? parseInt(comm.weight) : 0;
          }
        } else {
          const passcar_id = currentTrain.loads[i].railcar_asset_id;
          const passcar = allAssets.passengercar.find(
            (x) => x.asset_id === passcar_id
          );
          currentWeight += passcar ? parseInt(passcar.weight) : 0;
        }
      }
    }
    return currentWeight;
  };

  const renderWeights = () => {
    const a = getCurrentWeight();
    const b = getWeightLimit();
    const color = a > b ? "red" : "white";
    return (
      <span>
        <span style={{ color: color }}>{a}</span>/{b}
      </span>
    );
  };

  const renderNumRailcars = () => {
    const a = currentTrain.loads.length;
    const temp = allAssets["train"].find(
      (x) => x.asset_id === currentTrain.train
    );
    const b = temp ? temp.num_railcars : 0;
    const color = a > b ? "red" : "white";
    return (
      <span>
        <span style={{ color: color }}>{a}</span>/{b}
      </span>
    );
  };

  return (
    <Page title="Train Builder">
      <h2>Train Builder</h2>
      <div style={{ fontFamily: "sans-serif" }}>
        <p
          style={{
            width: "450px",
            fontSize: "70%",
            margin: "0px auto",
            textAlign: "left",
          }}
        >
          NOTES:
          <br />• Enter your address and wait for your assets to load
          <br />• Select a train, locomotive, and conductor from available
          assets
          <br />
          <span style={{ color: "yellow" }}>
            • In the list of available railcars, click on a railcar name (ex: L
            150 boxcar) to load the best commodities of any type, or click on a
            specific commodity type (ex: pallet) to load the best commodities of
            that type
          </span>
          <br />• The yellow numbers in the current train show the value of a 40
          distance run. For each railcar, the value shown is how much those
          commodities would pay if that railcar was the only one on the train
          <br />• The bloks link takes you to the settrain action with the
          fields prefilled so all you have to do is submit the transaction
          <br />• Lowest mint railcars and commodities are added first
          <br />• If setting multiple trains, refresh the page after submitting
          each transaction <br />• Bookmark the bloks link to save specific
          setups, or clear all your trains and rebuild quickly from scratch as
          needed
        </p>
        <br />
        {loading ? (
          <SnakeLoader />
        ) : (
          <WalletInput
            wallet={props.match.params.wallet || ""}
            setWallet={handleSetWallet}
            buttonText="CHOO CHOO"
            loading={false}
          />
        )}
        <br />
        {wallet && !loading ? (
          <span
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={refresh}
          >
            REFRESH
          </span>
        ) : null}
        <table style={{ margin: "0px auto", borderSpacing: "32px 0px" }}>
          <tbody>
            <tr>
              <td style={{ verticalAlign: "top" }}>
                <h3>Available Assets</h3>
                {renderAssets(true, "train")}
                <br />
                {renderAssets(true, "locomotive")}
                <br />
                {renderAssets(true, "conductor")}
                <br />
                {renderAssets(true, "railcar")}
                <br />
                {renderAssets(true, "passengercar")}
                <br />
                {renderAssets(true, "commodity")}
                <br />
                {renderAssets(true, "passenger")}
                <br />
              </td>
              <td style={{ verticalAlign: "top" }}>
                <h3>Current Train</h3>
                {renderTrainLink()}
                <br />
                <button
                  style={{ cursor: "pointer" }}
                  onClick={() => removeRailcar()}
                >
                  REMOVE LAST
                </button>
                <br />
                <br />
                {renderCurrentTrain()}
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
        <hr />
        <h3>Equipped Assets</h3>
        {renderAssets(false, "train")}
        <br />
        {renderAssets(false, "locomotive")}
        <br />
        {renderAssets(false, "conductor")}
        <br />
        {renderAssets(false, "railcar")}
        <br />
        {renderAssets(false, "passengercar")}
        <br />
        {renderAssets(false, "commodity")}
        <br />
        {renderAssets(false, "passenger")}
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </Page>
  );
};

export default TrainBuilder;
