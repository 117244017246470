import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { createPortal } from "react-dom";

const Modal = ({ children, fade = false, defaultOpened = false }, ref) => {
  const overlayStyle = {
    position: "fixed",
    zIndex: "99",
    left: "0",
    top: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(0,0,0, 0.5)",
  };

  const wrapperStyle = {
    position: "fixed",
    zIndex: "100",
    left: "0",
    top: "0",
    width: "100%",
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    outline: "0",
  };

  const modalStyle = {
    zIndex: "101",
    backgroundColor: "black",
    opacity: "1",
    position: "relative",
    margin: "30px auto",
    padding: "30px 30px",
    maxWidth: "500px",
    border: "1px solid white",
  };

  const closeStyle = {
    position: "absolute",
    zIndex: "102",
    right: "15px",
    top: "6px",
    cursor: "pointer",
    fontSize: "200%",
  };

  const [isOpen, setIsOpen] = useState(defaultOpened);

  const close = useCallback(() => setIsOpen(false), []);
  const open = () => setIsOpen(true);

  useImperativeHandle(
    ref,
    () => ({
      open,
      close,
    }),
    [close]
  );

  const handleEscape = useCallback(
    (event) => {
      if (event.keyCode === 27) close();
    },
    [close]
  );

  useEffect(() => {
    if (isOpen) document.addEventListener("keydown", handleEscape, false);
    return () => {
      document.removeEventListener("keydown", handleEscape, false);
    };
  }, [handleEscape, isOpen]);

  return createPortal(
    isOpen ? (
      <div style={overlayStyle}>
        <div style={wrapperStyle} onClick={close} />

        <div style={modalStyle}>
          <span style={closeStyle} aria-label="close" onClick={close}>
            x
          </span>
          {children}
        </div>
      </div>
    ) : null,
    document.body
  );
};

export default forwardRef(Modal);
