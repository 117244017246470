import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Page from "../Page";
import SnakeLoader from "../SnakeLoader";
import WalletInput from "../Utilities/WalletInput";
import axios from "axios";
import { makeColorGradient } from "../Utilities/Colors";
import * as AA from "../Utilities/AA";

const NftDraftCollection = (props) => {
  const SORT_BY = { team: "team", position: "position" };

  const history = useHistory();
  const [wallet, setWallet] = useState(props.match.params.wallet || "");
  const [loading, setLoading] = useState(false);
  const [topWallets, setTopWallets] = useState([]);
  const [playerData, setPlayerData] = useState([]);
  const [sortBy, setSortBy] = useState(SORT_BY.team);
  const [rarityTotals, setRarityTotals] = useState({
    Common: 0,
    Standout: 0,
    "Team Captain": 0,
    MVP: 0,
    GOAT: 0,
  });

  const POSITION_ORDER = [
    "QB #1",
    "QB #2",
    "RB #1",
    "RB #2",
    "RB #3",
    "WR #1",
    "WR #2",
    "WR #3",
    "WR #4",
    "WR #5",
    "TE #1",
    "TE #2",
    "TE #3",
    "DEF/K",
    "DEF",
    "K",
  ];
  const RARITY_ORDER = ["Common", "Standout", "Team Captain", "MVP", "GOAT"];

  useEffect(() => {
    const run = async () => {
      try {
        const r = await axios.get(
          AA.API +
            "atomicassets/v1/accounts?collection_name=nftdraft2121&page=1&limit=10&order=desc"
        );
        setTopWallets(r.data.data);
      } catch (e) {
        console.log(e);
      }
    };
    run();
  }, []);

  useEffect(() => {
    const run = async () => {
      if (wallet) {
        setLoading(true);
        const getMultiplePages = async (url, limit) => {
          let result = [];
          const more = async (pageNumber, limit) => {
            const response = await axios.get(
              AA.API +
                "atomicassets/v1/" +
                url +
                "&page=" +
                pageNumber +
                "&limit=" +
                limit
            );
            const data = response.data.data;
            result = [...result, ...data];
            if (data.length === limit) {
              await more(pageNumber + 1, limit);
            }
          };
          await more(1, limit);
          return result;
        };

        const r1 = await getMultiplePages(
          "templates?collection_name=nftdraft2121&schema_name=playercard21&order=desc&sort=created",
          1000
        );

        const iii = r1.findIndex((x) => x.template_id === "226217");
        r1[iii].immutable_data.Team = "New England";
        r1[iii].immutable_data.Name = "New England WR #5";
        r1[iii].immutable_data["Point Cost"] = 2;
        const jjj = r1.findIndex((x) => x.template_id === "225969");
        r1[jjj].immutable_data.Position = "QB #2";
        r1[jjj].immutable_data.Name = "Washington QB #2";

        const response2 = await axios.get(
          AA.API + "atomicassets/v1/accounts/" + wallet + "/nftdraft2121"
        );

        const r2 = response2.data.data.templates;

        const rarTots = {
          Common: 0,
          Standout: 0,
          "Team Captain": 0,
          MVP: 0,
          GOAT: 0,
        };

        const templates_to_delete = ["280518", "280857"];
        r1.forEach((x) => {
          if (x.immutable_data.Team === "Jacksonville ") {
            templates_to_delete.push(x.template_id);
          } else {
            for (let i = 0; i < r1.length; i++) {
              if (
                x.immutable_data.Position === r1[i].immutable_data.Position &&
                x.immutable_data.Rarity === r1[i].immutable_data.Rarity &&
                x.immutable_data.Team === r1[i].immutable_data.Team &&
                x.template_id !== r1[i].template_id &&
                x.template_id < r1[i].template_id
              ) {
                x.blend_template_id = r1[i].template_id;
                templates_to_delete.push(r1[i].template_id);
                break;
              }
            }
          }
        });

        templates_to_delete.forEach((x) => {
          const ii = r1.findIndex((y) => y.template_id === x);
          r1.splice(ii, 1);
        });

        r1.forEach((p) => {
          const ii = r2.findIndex(
            (x) =>
              x.template_id === p.template_id ||
              x.template_id === p.blend_template_id
          );
          if (ii >= 0) {
            p.count = r2[ii].assets;
            rarTots[p.immutable_data.Rarity]++;
          } else {
            p.count = 0;
          }
          const pos = p.immutable_data.Position;
          if (pos === "Kicker") p.immutable_data.Position = "K";
          else if (pos === "Defense") p.immutable_data.Position = "DEF";
          else if (pos === "Defense + Kicker")
            p.immutable_data.Position = "DEF/K";
        });

        setRarityTotals(rarTots);
        setPlayerData(r1);
        setLoading(false);
      }
    };
    run();
  }, [history, wallet]);

  const handleSetWallet = (w) => {
    history.push("/nftdraft/collection/" + w);
    setWallet(w);
  };

  const renderTableHeader = () => {
    return (
      <thead>
        <tr style={{ fontSize: "80%" }}>
          <th></th>
          <th style={{ width: "32px" }}>BASE</th>
          <th style={{ width: "32px" }}>&nbsp;SO&nbsp;</th>
          <th style={{ width: "32px" }}>CAPT</th>
          <th style={{ width: "32px" }}>MVP</th>
          <th style={{ width: "32px" }}>GOAT</th>
        </tr>
      </thead>
    );
  };

  const tablesByTeam = () => {
    if (playerData.length === 0) {
      return [];
    }
    playerData.sort((a, b) => {
      const aa = a.immutable_data.Team.localeCompare(b.immutable_data.Team);
      let bb =
        POSITION_ORDER.indexOf(a.immutable_data.Position) -
        POSITION_ORDER.indexOf(b.immutable_data.Position);
      let cc =
        RARITY_ORDER.indexOf(a.immutable_data.Rarity) -
        RARITY_ORDER.indexOf(b.immutable_data.Rarity);
      bb = bb > 0 ? 1 : bb < 0 ? -1 : 0;
      cc = cc > 0 ? 1 : cc < 0 ? -1 : 0;
      return aa * 4 + bb * 2 + cc;
    });

    const tableData = [];
    let currentTeam = "";
    let currentPos = "";
    playerData.forEach((p) => {
      const team = p.immutable_data.Team;
      const pos = p.immutable_data.Position;
      if (currentTeam !== team) {
        tableData.push({ team: team, positions: [] });
        currentTeam = team;
      }
      const iTeam = tableData.length - 1;
      if (currentPos !== pos) {
        tableData[iTeam].positions.push([]);
        currentPos = pos;
      }
      const iPos = tableData[iTeam].positions.length - 1;
      tableData[iTeam].positions[iPos].push(p);
    });

    const tables = [];

    const bgCols = makeColorGradient(0.3, 0.3, 0.3, 0, 2, 4);
    let bgCol = "inherit";

    tableData.forEach((table) => {
      tables.push(
        <React.Fragment>
          <h2 style={{ margin: "8px" }}>{table.team}</h2>
          <table>
            {renderTableHeader()}
            <tbody>
              {table.positions.map((pos, iPos) => {
                const posName = pos[0].immutable_data.Position;
                if (posName.startsWith("QB")) {
                  bgCol = bgCols[0];
                } else if (posName.startsWith("RB")) {
                  bgCol = bgCols[1];
                } else if (posName.startsWith("WR")) {
                  bgCol = bgCols[2];
                } else if (posName.startsWith("TE")) {
                  bgCol = bgCols[3];
                } else if (
                  posName.startsWith("DEF") ||
                  posName.startsWith("K")
                ) {
                  bgCol = bgCols[4];
                }
                return (
                  <tr key={table.team + "-" + iPos}>
                    <td
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: "120%",
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        borderRadius: "8px",
                        backgroundColor: bgCol,
                      }}
                    >
                      {posName}
                    </td>
                    {pos.map((rar, iRar) => {
                      return (
                        <td key={table.team + "-" + iPos + "-" + iRar}>
                          <span style={{ fontSize: "90%" }}>
                            {rar.count ? rar.count : <span>&nbsp;</span>}
                          </span>
                          <div
                            style={{
                              fontWeight: "bold",
                              color: bgCol,
                              fontSize: "60%",
                            }}
                          >
                            {rar.immutable_data["Point Cost"]}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </React.Fragment>
      );
    });

    return tables;
  };

  const tablesByPosition = () => {
    if (playerData.length === 0) {
      return [];
    }
    playerData.sort((a, b) => {
      const aa = a.immutable_data.Team.localeCompare(b.immutable_data.Team);
      let bb =
        POSITION_ORDER.indexOf(a.immutable_data.Position) -
        POSITION_ORDER.indexOf(b.immutable_data.Position);
      let cc =
        RARITY_ORDER.indexOf(a.immutable_data.Rarity) -
        RARITY_ORDER.indexOf(b.immutable_data.Rarity);
      bb = bb > 0 ? 1 : bb < 0 ? -1 : 0;
      cc = cc > 0 ? 1 : cc < 0 ? -1 : 0;
      return bb * 4 + aa * 2 + cc;
    });

    const tableData = [];
    let currentPos = "";
    let currentTeam = "";
    playerData.forEach((p) => {
      const pos = p.immutable_data.Position;
      const team = p.immutable_data.Team;
      if (currentPos !== pos) {
        tableData.push({ position: pos, teams: [] });
        currentPos = pos;
      }
      const iPos = tableData.length - 1;
      if (currentTeam !== team) {
        tableData[iPos].teams.push([]);
        currentTeam = team;
      }
      const iTeam = tableData[iPos].teams.length - 1;
      tableData[iPos].teams[iTeam].push(p);
    });

    const tables = [];

    const bgCols = makeColorGradient(0.05, 0.05, 0.05, 0, 2, 4, 64, 63, 32);

    tableData.forEach((table) => {
      tables.push(
        <React.Fragment>
          <h2 style={{ margin: "8px" }}>{table.position}</h2>
          <table>
            {renderTableHeader()}
            <tbody>
              {table.teams.map((team, iTeam) => {
                const teamName = team[0].immutable_data.Team;
                return (
                  <tr key={table.team + "-" + iTeam}>
                    <td
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: "120%",
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        borderRadius: "8px",
                        backgroundColor: bgCols[iTeam],
                      }}
                    >
                      {teamName}
                    </td>
                    {team.map((rar, iRar) => {
                      return (
                        <td key={table.team + "-" + iTeam + "-" + iRar}>
                          <span style={{ fontSize: "90%" }}>
                            {rar.count ? rar.count : <span>&nbsp;</span>}
                          </span>
                          <div
                            style={{
                              fontWeight: "bold",
                              color: bgCols[iTeam],
                              fontSize: "60%",
                            }}
                          >
                            {rar.immutable_data["Point Cost"]}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </React.Fragment>
      );
    });

    return tables;
  };

  const renderTables = () => {
    let tables = [];
    if (sortBy === SORT_BY.team) {
      tables = tablesByTeam();
    } else if (sortBy === SORT_BY.position) {
      tables = tablesByPosition();
    }
    return tables.map((table, i) => {
      return (
        <div
          key={"table--" + i}
          style={{ display: "inline-block", margin: "10px" }}
        >
          {table}
        </div>
      );
    });
  };

  const onChangeSort = (event) => {
    if (sortBy === SORT_BY.team) {
      setSortBy(SORT_BY.position);
    } else {
      setSortBy(SORT_BY.team);
    }
  };

  const renderSortButton = (sort) => {
    let activeStyle = {};
    if (sortBy === sort) {
      activeStyle = { backgroundColor: "green" };
    }
    return (
      <input
        type="button"
        value={sort}
        name={sort}
        onClick={onChangeSort}
        style={{
          ...activeStyle,
          cursor: "pointer",
          fontFamily: "Cousine",
          fontWeight: "bold",
        }}
      />
    );
  };

  const renderSortButtons = () => {
    return (
      <div>
        {renderSortButton(SORT_BY.team)}
        &nbsp;
        {renderSortButton(SORT_BY.position)}
      </div>
    );
  };

  const renderTopWallets = () => {
    const handleLink = (wallet) => {
      setWallet(wallet);
      history.push("/nftdraft/collection/" + wallet);
    };
    return topWallets ? (
      <div style={{ maxWidth: "560px", margin: "auto" }}>
        <div style={{ marginBottom: "4px" }}>Top wallets:</div>
        {topWallets.map((w) => {
          return (
            <div
              style={{
                display: "inline-block",
                margin: "5px",
                fontSize: "80%",
              }}
              key={"topwallet-" + w.account}
            >
              <span
                style={{ cursor: "pointer" }}
                onClick={() => handleLink(w.account)}
              >
                {w.account} ({w.assets})
              </span>
            </div>
          );
        })}
      </div>
    ) : null;
  };

  return (
    <Page title="NFTDraft Collection">
      <h2>Collection</h2>
      {renderTopWallets()}
      <br />
      {loading ? (
        <SnakeLoader />
      ) : (
        <WalletInput
          wallet={props.match.params.wallet || ""}
          setWallet={handleSetWallet}
          buttonText="GET PLAYERS"
          loading={false}
        />
      )}
      <br />
      {loading || playerData.length === 0 ? null : wallet === "nftdraftbotx" ? (
        <div>
          <div>GOATs pulled so far: {512 - rarityTotals.GOAT}</div>
          <br />
          <div>GOATs remaining: {rarityTotals.GOAT}</div>
        </div>
      ) : (
        <table
          style={{
            margin: "auto",
            textAlign: "center",
            borderSpacing: "12px 4px",
          }}
        >
          <thead>
            <tr>
              <th colSpan="3">Completion Stats</th>
            </tr>
            <tr>
              <th></th>
              <th
                style={{
                  fontSize: "80%",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                OWNED
              </th>
              <th
                style={{
                  fontSize: "80%",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                MISSING
              </th>
            </tr>
          </thead>
          <tbody style={{ textAlign: "right" }}>
            <tr>
              <td style={{ textAlign: "left" }}>Common</td>
              <td>{rarityTotals.Common}</td>
              <td>{512 - rarityTotals.Common}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>Standout</td>
              <td>{rarityTotals.Standout}</td>
              <td>{512 - rarityTotals.Standout}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>Captain</td>
              <td>{rarityTotals["Team Captain"]}</td>
              <td>{512 - rarityTotals["Team Captain"]}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>MVP</td>
              <td>{rarityTotals.MVP}</td>
              <td>{512 - rarityTotals.MVP}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>GOAT</td>
              <td>{rarityTotals.GOAT}</td>
              <td>{512 - rarityTotals.GOAT}</td>
            </tr>
          </tbody>
        </table>
      )}
      <br />
      {loading ? null : (
        <div>
          <div style={{ marginBottom: "4px" }}>SORT BY</div>
          {renderSortButtons()}
          {renderTables()}
        </div>
      )}
      <br />
      <br />
    </Page>
  );
};

export default NftDraftCollection;
