import axios from "axios";
import React, {
  forwardRef,
  useCallback,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import SnakeLoader from "../SnakeLoader";
import Modal from "../Utilities/Modal";
import { NovaContext } from "./NovaContextProvider";
import {
  NovaBoostTableData,
  NovaComboTableData,
  NovaLeagueTableData,
  NovaPositionTableData,
  NovaTimeTableData,
  NovaWalletTableData,
} from "./NovaDisplay";

const RaceModal = (props, ref) => {
  const modal = useRef(null);
  const { getComboData, getTemplateData } = useContext(NovaContext);

  const [raceId, setRaceId] = useState(0);
  const [raceData, setRaceData] = useState(null);
  const [racerData, setRacerData] = useState([]);
  const [loading, setLoading] = useState(false);

  const open = useCallback(
    (raceId) => {
      modal.current.open();
      setRaceId(raceId);
      const run = async () => {
        setLoading(true);

        const r = await axios.get(
          "https://nr-api.win-win.software/api/v1/races/" + raceId + "/"
        );

        const rd = r?.data?.data.participants ?? [];

        const result = await Promise.all(
          rd.map(async (participant) => {
            const oldRace = {
              vehicle_asset_id: participant.vehicleAssetId,
              driver1_asset_id: participant.driver1AssetId,
              driver2_asset_id: participant.driver2AssetId,
            };

            const combo = await getComboData(oldRace);

            var date = new Date(r?.data?.data.startedAt);
            var launch_ts = parseInt(date.getTime() / 1000);

            return {
              race_id: participant.id,
              race_time: launch_ts,
              winner: participant.accountName,
              time: participant.timeMs,
              league: participant.league,
              boost: participant.useBoost,
              position: participant.position,
              prize:
                participant.prizes &&
                participant.prizes[0] &&
                participant.prizes[0].templateId
                  ? participant.prizes[0].templateId
                  : "-1",
              ...combo,
            };
          })
        );

        setRacerData(result);
        setRaceData({ startedAt: r?.data?.data.startedAt });

        setLoading(false);
      };
      run();
    },
    [getComboData]
  );

  useImperativeHandle(
    ref,
    () => ({
      open,
      close: () => modal.current.close(),
    }),
    [open]
  );

  const renderPrize = (id) => {
    if (id && id > -1) {
      let str = getTemplateData(id);
      if (!str) {
        str = "Shard";
      }
      return str;
    }
    return "None. YOU GET NOTHING. YOU LOSE. GOOD DAY SIR";
  };

  return (
    <Modal ref={modal}>
      <div
        style={{
          textAlign: "center",
        }}
      >
        <h2
          style={{
            textAlign: "center",
            margin: "auto",
          }}
        >
          Race #{raceId}
        </h2>
        {loading ? (
          <SnakeLoader />
        ) : (
          <div>
            <br />
            {raceData && racerData.length > 0 ? (
              <div>
                <div>{new Date(raceData.startedAt).toUTCString()}</div>
                <br />
                <div style={{ fontSize: "80%" }}>
                  Prize: {renderPrize(racerData[0].prize)}
                </div>
                <br />
                <table
                  style={{
                    margin: "auto",
                    textAlign: "left",
                    borderSpacing: "6px 3px",
                    fontSize: "80%",
                  }}
                >
                  <tbody>
                    {racerData.map((racer) => {
                      return (
                        <tr key={"racemodal" + racer.race_id + racer.winner}>
                          <NovaBoostTableData boost={racer.boost} />
                          <NovaPositionTableData position={racer.position} />
                          <NovaLeagueTableData league={racer.league} />
                          <NovaWalletTableData wallet={racer.winner} />
                          <NovaTimeTableData time={racer.time} />
                          <NovaComboTableData combo={racer} />
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default forwardRef(RaceModal);
