import React from "react";
import { Link } from "react-router-dom";
import Page from "../Page";

const VirtualDream = () => {
  const linkStyle = {
    display: "block",
    padding: "7px",
    fontSize: "120%",
    width: "220px",
    margin: "0px auto",
  };
  return (
    <Page title="VirtualDream">
      <Link style={linkStyle} to="/dream/staking">
        Staking Calculator
      </Link>
      <br />
      <Link style={linkStyle} to="/dream/leaderboard">
        Leaderboard
      </Link>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </Page>
  );
};

export default VirtualDream;
