import React, { useEffect, useState } from "react";
import Page from "../Page";
import { rpc } from "../Utilities/RPC";
import txn from "../txn.png";
import {
  ClaimBreedersZone,
  ClaimCoinPirates,
  ClaimCollectSocial,
  ClaimNeftyBlocks,
  ClaimNovaRally,
  ClaimOliveland,
  // ClaimTrainCentury,
  ClaimTalesOfCrypto,
  ClaimVirtualDream,
} from "./ClaimSection";

const ClaimTokens = ({ ual }) => {
  const [activeUser, setActiveUser] = useState();
  const [account, setAccount] = useState();

  useEffect(() => {
    const run = async () => {
      if (ual.activeUser) {
        setActiveUser(ual.activeUser);
        const acc = await rpc.get_account(ual.activeUser.accountName);
        setAccount(acc);
      }
    };
    run();
  }, [ual.activeUser]);

  const renderLogoutButton = () => {
    if (!!ual.activeUser && !!ual.activeAuthenticator) {
      return (
        <button
          style={{
            cursor: "pointer",
            fontWeight: "bold",
            fontSize: "80%",
          }}
          onClick={ual.logout}
        >
          Logout
        </button>
      );
    }
  };

  const openLoginModal = () => {
    if (!ual.activeUser) {
      ual.showModal();
    }
  };

  const renderLoginButton = () => {
    return (
      <div>
        <br />
        <br />
        <button
          style={{
            cursor: "pointer",
            fontWeight: "bold",
            fontSize: "140%",
          }}
          onClick={openLoginModal}
        >
          Login
        </button>
      </div>
    );
  };

  const renderNameAndBalance = () => {
    return activeUser && account ? (
      <div>
        <h2>
          {activeUser.accountName} {renderLogoutButton()}
        </h2>
        {account.core_liquid_balance}
      </div>
    ) : null;
  };

  return (
    <Page title="Claim Tokens">
      <p
        style={{
          margin: "auto",
          width: "340px",
          fontSize: "80%",
          fontFamily: "sans-serif",
          fontWeight: "bold",
        }}
      >
        Use EXTREME CAUTION when perfoming transactions, especially on a third
        party website. Always verify the action in your wallet before signing a
        transaction.
      </p>
      <br />
      <img
        src={txn}
        style={{ width: "254px", height: "35px" }}
        alt="transaction action"
      />
      {ual.activeUser ? renderNameAndBalance() : null}
      <br />
      {ual.activeUser ? null : renderLoginButton()}
      {ual.activeUser ? (
        <div>
          <ClaimNovaRally activeUser={ual.activeUser} />
          <ClaimBreedersZone activeUser={ual.activeUser} />
          <ClaimCoinPirates activeUser={ual.activeUser} />
          <ClaimCollectSocial activeUser={ual.activeUser} />
          <ClaimNeftyBlocks activeUser={ual.activeUser} />
          <ClaimOliveland activeUser={ual.activeUser} />
          {/* <ClaimTrainCentury activeUser={ual.activeUser} /> */}
          <ClaimTalesOfCrypto activeUser={ual.activeUser} />
          <ClaimVirtualDream activeUser={ual.activeUser} />
          <br />
          <br />
          <br />
          <br />
        </div>
      ) : null}
    </Page>
  );
};

export default ClaimTokens;
