import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Page from "../Page";
import SnakeLoader from "../SnakeLoader";
import { calculateHourlyRates } from "../Utilities/Calculate";
import useTokenPrices from "../Utilities/useTokenPrices";
import { rpc } from "../Utilities/RPC";
import { TokenPriceTable } from "../Utilities/Display";

const OliveLeaderboard = () => {
  const columns = {
    account: "account",
    olv: "olv",
    wax: "wax",
  };

  const [tokenPrices, getTokenPrice] = useTokenPrices();
  const [leaders, setLeaders] = useState([]);
  const [leaderCount, setLeaderCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentSort, setCurrentSort] = useState(null);

  useEffect(() => {
    const run = async () => {
      if (tokenPrices) {
        setLeaderCount(0);
        setLoading(true);
        const r = await rpc.get_table_rows({
          json: true,
          code: "olivelandstk",
          scope: "olivelandstk",
          table: "staking",
          lower_bound: "",
          upper_bound: "",
          limit: 1000,
        });
        const poolTable = r.rows;
        let result = [];
        const next = async (payload) => {
          const r1 = await rpc.get_table_rows(payload);
          result = result.concat(r1.rows);
          if (r1.rows.length > 0) {
            const newPayload = {
              ...payload,
              lower_bound: r1.rows[r1.rows.length - 1].account + "1",
            };
            setLeaderCount((old) => old + r1.rows.length);
            await new Promise((r) => setTimeout(r, 20));
            next(newPayload);
          } else {
            result.forEach((user, i) => {
              const userData = calculateHourlyRates(user, poolTable);
              user = {
                ...user,
                olv: {
                  day: 0,
                },
                wax: {
                  day: 0,
                },
                usd: {
                  day: 0,
                },
              };
              userData.data.forEach((pool) => {
                const total = pool.hourlyTotal;
                user.olv.day += total * 24;
              });
              user.wax.day = getTokenPrice("OLVWAX") * user.olv.day;
              user.usd.day = getTokenPrice("WAXUSD") * user.wax.day;
              result[i] = user;
            });
            setLeaders(result.filter((x) => x.olv.day > 0));
            sortTable(columns.olv);
            setLoading(false);
            Promise.resolve("SUCCESS");
          }
        };
        next({
          json: true,
          code: "olivelandstk",
          scope: "olivelandstk",
          table: "user",
          lower_bound: "",
          upper_bound: "",
          index_position: 1,
          limit: 1000,
        });
      }
    };
    run();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenPrices, getTokenPrice]);

  const sortTable = (col) => {
    if (currentSort === col) {
      setCurrentSort(null);
      setLeaders((old) => {
        return [...old].reverse();
      });
    } else {
      setCurrentSort(col);
      setLeaders((old) => {
        if (col === columns.account) {
          return [...old.sort((a, b) => a[col].localeCompare(b[col]))];
        } else {
          return [
            ...old.sort((a, b) => {
              const x = a[col].day ? a[col].day : 0;
              const y = b[col].day ? b[col].day : 0;
              return y - x;
            }),
          ];
        }
      });
    }
  };

  const renderLeaderboard = () => {
    return (
      <table
        style={{
          margin: "auto",
          textAlign: "right",
          borderSpacing: "12px 4px",
        }}
      >
        <thead>
          <tr>
            <th></th>
            <th
              style={{ cursor: "pointer", textAlign: "left" }}
              onClick={() => sortTable(columns.account)}
            >
              olive lover
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.olv)}
            >
              OLV/day
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.wax)}
            >
              WAX/day
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.wax)}
            >
              USD/day
            </th>
          </tr>
        </thead>
        <tbody>
          {leaders.map((leader, i) => {
            return (
              <tr key={"leader-" + leader.account}>
                <td style={{ textAlign: "center" }}>{i + 1}</td>
                <td style={{ textAlign: "left" }}>
                  <Link to={"/olive/staking/" + leader.account}>
                    {leader.account}
                  </Link>
                </td>
                <td>{parseInt(leader.olv.day).toLocaleString()}</td>
                <td>
                  {leader.wax.day.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td>
                  {leader.usd.day.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <Page title="Oliveland Leaderboard">
      <h2>Staking Leaderboard</h2>
      <Link to="/olive/staking">Staking Calculator</Link>
      &nbsp;&nbsp;
      <Link to="/olive/values">Staking Values</Link>
      <br />
      <br />
      <div>
        {tokenPrices ? (
          <TokenPriceTable
            coinPriceArray={[
              ["OLV", getTokenPrice("OLVWAX")],
              ["WAX", getTokenPrice("WAXUSD")],
            ]}
          />
        ) : null}
        <br />
        {loading ? <div>{leaderCount}</div> : null}
        {loading || leaders.length === 0 ? (
          <SnakeLoader />
        ) : (
          renderLeaderboard()
        )}
      </div>
      <br />
      <br />
    </Page>
  );
};

export default OliveLeaderboard;
