import React, { useState, useEffect } from "react";
import firebase from "../Firebase";
import Page from "../Page";
import SnakeLoader from "../SnakeLoader";
import { RPLANET_ELEMENTS } from "rplanet";
import ReactImageTooltip from "../Utilities/ReactImageTooltip";
import { makeColorGradient } from "../Utilities/Colors";
import axios from "axios";
import useTokenPrices from "../Utilities/useTokenPrices";

const ElementData = () => {
  if (process.env.NODE_ENV === "development") {
    firebase.functions().useEmulator("localhost", 5001);
  }

  const COLUMNS = {
    symbol: "symbol",
    name: "name",
    cost: "cost",
    wax: "wax",
    gen: "gen",
    tier: "tier",
    order: "order",
    eoc: "eoc",
    pattern: "pattern",
    recipe: "recipe",
    AIR: "AIR",
    EARTH: "EARTH",
    WATER: "WATER",
    FIRE: "FIRE",
    steps: "steps",
    burnt: "burnt",
    supply: "supply",
    crafted: "crafted",
    spent: "spent",
    inventor: "inventor",
    dayOfWeek: "dayOfWeek",
    prettyDate: "prettyDate",
    time: "time",
  };

  const FILTERS = {
    undiscovered: "undiscovered",
    base: "base",
  };

  const [tokenPrices, getTokenPrice] = useTokenPrices();
  const [allElements, setAllElements] = useState({});
  const [currentSort, setCurrentSort] = useState(null);
  const [filters, setFilters] = useState({
    [FILTERS.undiscovered]: false,
    [FILTERS.base]: false,
  });
  const [aetherPrice, setAetherPrice] = useState({});
  const [craftFee, setCraftFee] = useState(0.01);

  const isBase = (el) => {
    return ["AIR", "WATER", "EARTH", "FIRE"].includes(el);
  };

  useEffect(() => {
    const getIngredients = (element, allEls) => {
      const list = [];
      const base = [];
      const more = (e) => {
        const el = allEls[e];
        if (el && el.recipe) {
          el.recipe.forEach((x) => {
            if (isBase(x)) {
              base.push(x);
            } else {
              list.push(x);
              more(x);
            }
          });
        }
      };
      more(element);
      return [
        list
          .sort((a, b) => allEls[a].tier - allEls[b].tier)
          .reduce(function (acc, curr) {
            acc[curr] ? ++acc[curr] : (acc[curr] = 1);
            return acc;
          }, {}),
        base
          .sort((a, b) => allEls[a].tier - allEls[b].tier)
          .reduce(function (acc, curr) {
            acc[curr] ? ++acc[curr] : (acc[curr] = 1);
            return acc;
          }, {}),
      ];
    };

    const run = async () => {
      if (tokenPrices) {
        const _burnt = {};
        const _supply = {};

        try {
          const r = await axios.post(
            "https://wax.dfuse.eosnation.io/v1/chain/get_table_rows",
            {
              code: "a.rplanet",
              scope: "a.rplanet",
              table: "globalc",
              key_type: "i64",
              limit: 500,
              json: true,
            }
          );
          const cf = r.data.rows[0].bank;
          setCraftFee(parseInt(cf) / 50000000);
        } catch (e) {
          setCraftFee(0.01);
        }
        try {
          const r = await axios.post(
            "https://wax.dfuse.eosnation.io/v1/chain/get_table_rows",
            {
              code: "a.rplanet",
              scope: "a.rplanet",
              table: "gmelements",
              key_type: "i64",
              limit: 500,
              json: true,
            }
          );
          r.data.rows.forEach((row) => {
            _burnt[row.element.split(",")[1]] = row.burncount - 1;
          });
        } catch (e) {}
        try {
          const r = await axios.get(
            "https://wax.api.simpleassets.io/v1/author/a.rplanet/ft-assets"
          );
          r.data.results.forEach((row) => {
            _supply[row.symbol] = parseInt(row.supply.split(" ")[0]);
          });
        } catch (e) {}
        let _aetherPrice = 0.0002;
        try {
          _aetherPrice = getTokenPrice("AETHERWAX");
        } catch (e) {
        } finally {
          setAetherPrice(_aetherPrice);
        }
        const r = RPLANET_ELEMENTS;
        const disc = {};
        for (const [key, value] of Object.entries(r)) {
          disc[key] = value;
          let d;
          if (isBase(key)) {
            d = new Date(0);
            disc[key].burnt = _burnt[key] ? _burnt[key] : 0;
            disc[key].supply = _supply[key] ? _supply[key] : 0;
            disc[key].crafted = disc[key].burnt + disc[key].supply;
            disc[key].spent = disc[key].crafted * disc[key].cost;
          } else if (disc[key].inventor !== "") {
            const [list, base] = getIngredients(key, r);
            Object.entries(base).forEach(([k, v]) => {
              disc[key][k] = v;
            });
            disc[key].steps = (disc[key].cost - 10000) / 30000;
            disc[key].list = list;
            disc[key].burnt = _burnt[key] ? _burnt[key] : 0;
            disc[key].supply = _supply[key] ? _supply[key] : 0;
            disc[key].crafted = disc[key].burnt + disc[key].supply;
            disc[key].spent = disc[key].crafted * disc[key].cost;
            let found = false;
            disc[key].eoc = "";
            for (const [kk, vv] of Object.entries(r)) {
              if (!isBase(kk) && vv.inventor !== "" && kk !== key) {
                if (vv.recipe.includes(key)) {
                  found = true;
                }
              }
            }
            if (!found) {
              disc[key].eoc = "✓";
            }
            d = new Date(disc[key].date);
          } else {
            delete disc[key].tier;
            delete disc[key].cost;
            disc[key].steps = [];
            disc[key].list = [];
            d = new Date(1641024000000);
          }
          disc[key].datetime = d.toISOString();
          disc[key].dayOfWeek = d.getUTCDay();
          disc[key].time = d.toUTCString().split(" ")[4];
          disc[key].prettyDate =
            ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][d.getUTCDay()] +
            " " +
            [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ][d.getUTCMonth()] +
            " " +
            d.getUTCDate().toString().padStart(2, "0") +
            " " +
            d.getUTCFullYear();

          if (disc[key].cost) {
            disc[key].wax = _aetherPrice * disc[key].cost;
          }
        }

        console.log(disc);
        setAllElements(disc);
        sortTable("order");
        sortTable("prettyDate");
      }
    };
    run();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenPrices, getTokenPrice]);

  const sortTable = (col) => {
    if (currentSort === col) {
      setCurrentSort(null);
      setAllElements((old) => {
        return Object.fromEntries(Object.entries(old).reverse());
      });
    } else {
      setCurrentSort(col);
      setAllElements((old) => {
        let sort;
        if ([COLUMNS.symbol].includes(col)) {
          sort = ([a], [b]) => {
            return a.localeCompare(b);
          };
        } else if (
          [
            COLUMNS.symbol,
            COLUMNS.name,
            COLUMNS.inventor,
            COLUMNS.pattern,
            COLUMNS.eoc,
          ].includes(col)
        ) {
          sort = ([, a], [, b]) => {
            const x = a[col] ? a[col] : "";
            const y = b[col] ? b[col] : "";
            if (col === COLUMNS.eoc) return y.localeCompare(x);
            return x.localeCompare(y);
          };
        } else if ([COLUMNS.time].includes(col)) {
          sort = ([, a], [, b]) => {
            let da = new Date(a.datetime);
            let db = new Date(b.datetime);
            const seconds = (d) => {
              return (
                d.getUTCHours() * 3600 +
                d.getUTCMinutes() * 60 +
                d.getUTCSeconds()
              );
            };
            return seconds(da) - seconds(db);
          };
        } else if ([COLUMNS.prettyDate].includes(col)) {
          sort = ([, a], [, b]) => {
            return (
              new Date(b.datetime).getTime() - new Date(a.datetime).getTime()
            );
          };
        } else if ([COLUMNS.recipe].includes(col)) {
          sort = ([, a], [, b]) => {
            return (
              a.recipe[0].localeCompare(b.recipe[0]) * 1000 +
              a.recipe[1].localeCompare(b.recipe[1]) * 100 +
              a.recipe[2].localeCompare(b.recipe[2]) * 10 +
              a.recipe[3].localeCompare(b.recipe[3])
            );
          };
        } else if ([COLUMNS.dayOfWeek].includes(col)) {
          sort = ([, a], [, b]) => {
            const x = a[col] ? a[col] : 0;
            const y = b[col] ? b[col] : 0;
            return x - y;
          };
        } else {
          sort = ([, a], [, b]) => {
            const x = a[col] ? a[col] : 0;
            const y = b[col] ? b[col] : 0;
            return y - x;
          };
        }
        return Object.fromEntries(Object.entries(old).sort(sort));
      });
    }
  };

  const toggleFilter = (f) => {
    setFilters((old) => {
      return { ...old, [f]: !filters[f] };
    });
  };

  const bgCols = makeColorGradient(0.9, 0.8, 0.7, 0, 2, 4, 88, 44, 16);

  return (
    <Page title="RPlanet Element Data">
      <h2>Element Data</h2>
      <h6>
        * Exist/Burnt numbers do not yet account for FTs spent on land upgrades
        *
      </h6>
      {Object.keys(allElements).length === 0 ? (
        <SnakeLoader />
      ) : (
        <React.Fragment>
          <div>
            Total elements crafted:{" "}
            {Object.keys(allElements)
              .reduce((accum, key) => {
                if (isBase(key)) return accum;
                else
                  return (
                    accum +
                    (allElements[key].crafted !== undefined
                      ? allElements[key].crafted
                      : 0)
                  );
              }, 0)
              .toLocaleString()}
          </div>
          <br />
          <div>
            Aether spent crafting/inventing:{" "}
            {(
              (allElements["AIR"].burnt +
                allElements["FIRE"].burnt +
                allElements["EARTH"].burnt +
                allElements["WATER"].burnt) *
              10000
            ).toLocaleString()}
          </div>
          <br />
          <span>
            <input
              type="checkbox"
              checked={filters.undiscovered}
              onChange={() => toggleFilter(FILTERS.undiscovered)}
            />
            <span
              onClick={() => toggleFilter(FILTERS.undiscovered)}
              style={{ cursor: "pointer", fontSize: "90%" }}
            >
              Undiscovered
            </span>
          </span>
          &nbsp;&nbsp;
          <span>
            <input
              type="checkbox"
              checked={filters.base}
              onChange={() => toggleFilter(FILTERS.base)}
            />
            <span
              onClick={() => toggleFilter(FILTERS.base)}
              style={{ cursor: "pointer", fontSize: "90%" }}
            >
              Base
            </span>
          </span>
          <br />
          <br />
          <table
            style={{
              margin: "auto",
              textAlign: "center",
              borderSpacing: "4px 2px",
              fontSize: "75%",
              fontFamily: "Cousine",
            }}
          >
            <thead style={{ fontWeight: "bold" }}>
              <tr>
                <td></td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => sortTable(COLUMNS.symbol)}
                >
                  Symbol
                </td>
                <td
                  style={{
                    cursor: "pointer",
                    fontSize: "80%",
                    textAlign: "left",
                  }}
                  onClick={() => sortTable(COLUMNS.name)}
                >
                  Name
                </td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => sortTable(COLUMNS.cost)}
                >
                  <abbr title="Aether cost to craft from scratch">Cost</abbr>
                </td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => sortTable(COLUMNS.cost)}
                >
                  <abbr title="Aether per hour of a common element. Multiply by 3/6/12/25/100 for uncommon/rare/epic/legendary/mythic">
                    AE/hr
                  </abbr>
                </td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => sortTable(COLUMNS.wax)}
                >
                  <abbr
                    title={
                      "WAX cost to craft from scratch (based on last price from Alcor: " +
                      aetherPrice +
                      ")"
                    }
                  >
                    WAX
                  </abbr>
                </td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => sortTable(COLUMNS.wax)}
                >
                  <abbr
                    title={
                      "Rplanet crafting fee (" +
                      craftFee +
                      ") * number of steps"
                    }
                  >
                    Fee
                  </abbr>
                </td>
                <td
                  style={{ cursor: "pointer", fontSize: "80%" }}
                  onClick={() => sortTable(COLUMNS.gen)}
                >
                  <abbr title="Gen1 = Feb 16, 2021 | Gen2 = Mar 30, 2021 | Gen3 = Jun 15, 2021">
                    Gen
                  </abbr>
                </td>
                <td
                  style={{ cursor: "pointer", fontSize: "80%" }}
                  onClick={() => sortTable(COLUMNS.order)}
                >
                  <abbr title="Order in official elements list">Order</abbr>
                </td>
                <td
                  style={{ cursor: "pointer", fontSize: "80%" }}
                  onClick={() => sortTable(COLUMNS.tier)}
                >
                  <abbr title="Highest tier element in recipe +1">Tier</abbr>
                </td>
                <td
                  style={{ cursor: "pointer", fontSize: "80%" }}
                  onClick={() => sortTable(COLUMNS.eoc)}
                >
                  <abbr title="End of chain, not used in any other recipe (yet!)">
                    EoC
                  </abbr>
                </td>
                <td
                  style={{ cursor: "pointer", fontSize: "80%" }}
                  onClick={() => sortTable(COLUMNS.pattern)}
                >
                  <abbr title="Recipe pattern: AAAA, AAAB, AABB, AABC, or ABCD">
                    ABCD
                  </abbr>
                </td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => sortTable(COLUMNS.recipe)}
                  colSpan="4"
                >
                  Recipe
                </td>
                <td></td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => sortTable(COLUMNS.AIR)}
                >
                  <abbr title="Air elements required to craft from scratch">
                    A
                  </abbr>
                </td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => sortTable(COLUMNS.EARTH)}
                >
                  <abbr title="Earth elements required to craft from scratch">
                    E
                  </abbr>
                </td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => sortTable(COLUMNS.WATER)}
                >
                  <abbr title="Water elements required to craft from scratch">
                    W
                  </abbr>
                </td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => sortTable(COLUMNS.FIRE)}
                >
                  <abbr title="Fire elements required to craft from scratch">
                    F
                  </abbr>
                </td>
                <td
                  style={{ cursor: "pointer", fontSize: "80%" }}
                  onClick={() => sortTable(COLUMNS.steps)}
                >
                  <abbr title="Total crafts required to craft from scratch">
                    Steps
                  </abbr>
                </td>
                <td></td>
                <td
                  style={{ cursor: "pointer", fontSize: "80%" }}
                  onClick={() => sortTable(COLUMNS.supply)}
                >
                  <abbr title="Existing supply of FTs">Exist</abbr>
                </td>
                <td
                  style={{ cursor: "pointer", fontSize: "80%" }}
                  onClick={() => sortTable(COLUMNS.burnt)}
                >
                  <abbr title="FTs burned through crafting and inventing">
                    Burnt
                  </abbr>
                </td>
                <td
                  style={{ cursor: "pointer", fontSize: "80%" }}
                  onClick={() => sortTable(COLUMNS.crafted)}
                >
                  <abbr title="Total FTs ever created">Created</abbr>
                </td>
                {/* <td></td>
                <td
                  style={{ cursor: "pointer", fontSize: "80%" }}
                  onClick={() => sortTable(COLUMNS.spent)}
                >
                  Cost*Created
                </td> */}
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => sortTable(COLUMNS.inventor)}
                >
                  Inventor
                </td>
                <td
                  style={{
                    cursor: "pointer",
                    fontSize: "80%",
                    textAlign: "left",
                  }}
                  onClick={() => sortTable(COLUMNS.dayOfWeek)}
                >
                  Day
                </td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => sortTable(COLUMNS.prettyDate)}
                >
                  Date
                </td>
                <td
                  style={{ cursor: "pointer", fontSize: "80%" }}
                  onClick={() => sortTable(COLUMNS.time)}
                >
                  UTC Time
                </td>
              </tr>
            </thead>
            <tbody style={{ fontSize: "80%", fontWeight: "bold" }}>
              {Object.keys(allElements)
                .filter((e) => {
                  if (["AIR", "EARTH", "WATER", "FIRE"].includes(e)) {
                    return filters.base;
                  } else if (allElements[e].inventor === "") {
                    return filters.undiscovered;
                  } else {
                    return true;
                  }
                })
                .map((symbol, i) => {
                  const e = allElements[symbol];
                  return (
                    <tr key={symbol}>
                      <td>{i + 1}</td>
                      <td style={{ textAlign: "right", cursor: "default" }}>
                        <ReactImageTooltip
                          image={
                            process.env.PUBLIC_URL +
                            "/elements/" +
                            symbol +
                            ".png"
                          }
                        >
                          {symbol}
                        </ReactImageTooltip>
                      </td>
                      <td
                        style={{
                          textAlign: "left",
                          fontSize: "80%",
                          cursor: "default",
                        }}
                      >
                        <ReactImageTooltip
                          image={
                            process.env.PUBLIC_URL +
                            "/elements/" +
                            symbol +
                            ".png"
                          }
                        >
                          <nobr>{e.name}</nobr>
                        </ReactImageTooltip>
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          backgroundColor: e.cost
                            ? bgCols[
                                e.cost <= 500000
                                  ? 0
                                  : e.cost <= 2000000
                                  ? 1
                                  : e.cost <= 5000000
                                  ? 2
                                  : e.cost <= 10000000
                                  ? 3
                                  : e.cost <= 20000000
                                  ? 4
                                  : 5
                              ]
                            : "inherit",
                          borderRadius: "4px",
                          paddingLeft: "4px",
                          paddingRight: "4px",
                        }}
                      >
                        {e.cost ? e.cost.toLocaleString() : ""}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {e.cost ? (e.cost / 8000).toFixed(2) : ""}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {e.wax ? e.wax.toFixed(1) : ""}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {(e.steps * craftFee).toFixed(1)}
                      </td>
                      <td
                        style={{
                          backgroundColor: bgCols[e.gen + 3],
                          borderRadius: "4px",
                          paddingLeft: "4px",
                          paddingRight: "4px",
                        }}
                      >
                        {e.gen}
                      </td>
                      <td>{e.order}</td>
                      <td
                        style={{
                          backgroundColor: bgCols[e.tier - 1],
                          borderRadius: "4px",
                        }}
                      >
                        {e.tier}
                      </td>
                      <td>{e.eoc}</td>
                      <td
                        style={{
                          backgroundColor:
                            bgCols[
                              ["AAAA", "AAAB", "AABB", "AABC", "ABCD"].indexOf(
                                e.pattern
                              )
                            ],
                          borderRadius: "4px",
                          paddingLeft: "4px",
                          paddingRight: "4px",
                        }}
                      >
                        {e.pattern}
                      </td>
                      <td>{e.recipe[0]}</td>
                      <td>{e.recipe[1]}</td>
                      <td>{e.recipe[2]}</td>
                      <td>{e.recipe[3]}</td>
                      <td></td>
                      <td style={{ textAlign: "right" }}>{e.AIR}</td>
                      <td style={{ textAlign: "right" }}>{e.EARTH}</td>
                      <td style={{ textAlign: "right" }}>{e.WATER}</td>
                      <td style={{ textAlign: "right" }}>{e.FIRE}</td>
                      <td style={{ textAlign: "right" }}>{e.steps}</td>
                      <td></td>
                      <td style={{ textAlign: "right" }}>{e.supply}</td>
                      <td style={{ textAlign: "right" }}>{e.burnt}</td>
                      <td style={{ textAlign: "right" }}>{e.crafted}</td>
                      {/* <td></td>
                      <td style={{ textAlign: "right" }}>
                        {e.spent ? e.spent.toLocaleString() : ""}
                      </td> */}
                      <td>{e.inventor}</td>
                      {e.datetime === new Date(1641024000000).toISOString() ||
                      e.datetime === new Date(0).toISOString() ? (
                        <td></td>
                      ) : (
                        <td colSpan="2">
                          <nobr>{e.prettyDate}</nobr>
                        </td>
                      )}
                      {e.datetime === new Date(1641024000000).toISOString() ||
                      e.datetime === new Date(0).toISOString() ? (
                        <td></td>
                      ) : (
                        <td style={{ textAlign: "right" }}>
                          <nobr>{e.time}</nobr>
                        </td>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </React.Fragment>
      )}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </Page>
  );
};

export default ElementData;
