import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Page from "../Page";
import StakeableAssets from "../Utilities/StakeableAssets";
import WalletInput from "../Utilities/WalletInput";
import { linkNewWindow } from "../Utilities/LinkUtilities";

const KickbackAssetIds = (props) => {
  const [wallet, setWallet] = useState(props.match.params.wallet || "");
  const history = useHistory();
  const [loading] = useState(false);

  const handleSetWallet = (w) => {
    history.push("/kickback/ids/" + w);
    setWallet(w);
  };

  return (
    <Page title="Kickback Asset IDs">
      <h2>Asset IDs</h2>

      <div>
        <div style={{ margin: "auto" }}>
          <WalletInput
            wallet={wallet}
            setWallet={handleSetWallet}
            loading={loading}
            buttonText="KICK ME"
          />
        </div>
      </div>
      <br />
      <div
        style={{
          cursor: "pointer",
          textAlign: "center",
          textDecoration: "underline",
          margin: "auto",
        }}
        onClick={() =>
          linkNewWindow(
            "https://wax.bloks.io/account/rc2.kickback?loadContract=true&tab=Actions&table=cards&account=rc2.kickback&scope=rewardpoolab&limit=100&action=claim"
          )
        }
      >
        Claim on bloks
      </div>
      {wallet ? (
        <React.Fragment>
          <h4>Steve Asset IDs:</h4>
          <div>
            <StakeableAssets
              key={"kickback" + wallet}
              collection="kickback"
              wallet={wallet}
              noTitle={true}
            />
          </div>
        </React.Fragment>
      ) : null}
      <br />
      <br />
    </Page>
  );
};

export default KickbackAssetIds;
