import React, { useState, useEffect } from "react";
import Page from "../Page";
import SnakeLoader from "../SnakeLoader";
import {
  calculateDailyDream,
  calculateUnclaimedDream,
  getTocPools,
} from "../Utilities/Calculate";
import { linkNewWindow } from "../Utilities/LinkUtilities";
import { rpc } from "../Utilities/RPC";
import useTokenPrices from "../Utilities/useTokenPrices";

const COLUMNS = {
  account: "account",
  rate: "rate",
  balance: "balance",
};

const TalesOfCryptoLeaderboard = (props) => {
  const [shroomers, setShroomers] = useState([]);
  const [currentSort, setCurrentSort] = useState(null);
  const [tokenPrices, getTokenPrice] = useTokenPrices();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const run = async () => {
      if (tokenPrices) {
        setLoading(true);

        const getShroomers = async (lowerBound = "", result = []) => {
          const r = await rpc.get_table_rows({
            code: "shroomstaker",
            scope: "shroomstaker",
            table: "user",
            json: true,
            limit: 1000,
            lower_bound: lowerBound,
            upper_bound: "",
          });
          if (r.rows && r.rows.length > 0) {
            result = result.concat(r.rows);
            const lb = result[result.length - 1].account + "1";
            return getShroomers(lb, result);
          } else {
            return result;
          }
        };

        const ds = await getShroomers();
        const pools = await getTocPools();
        const radsPrice = getTokenPrice("RADSWAX");

        for (let i = 0; i < ds.length; i++) {
          const ur = ds[i];
          if (Object.keys(ur).length > 0) {
            const uc = calculateUnclaimedDream(ur, pools);
            const daily = calculateDailyDream(ur, pools);

            const final = [];

            const getUserLevelCount = (userRow, poolName, levelKey) => {
              const aa = userRow.data.find(
                (userPool) => userPool.pool === poolName
              );
              if (aa) {
                const bb = aa.inventory.find((ii) => ii.key === levelKey);
                if (bb) {
                  return bb.value;
                }
              }
              return 0;
            };

            pools.forEach((pool) => {
              const levels = Object.fromEntries(
                pool.levels.map((level) => [
                  level.key,
                  {
                    rate: parseInt(level.value),
                    count: getUserLevelCount(ur, pool.pool, level.key),
                  },
                ])
              );
              final.push({
                pool: pool.pool,
                levels: levels,
                daily: parseInt(daily),
                dailyWax: parseInt(daily) * radsPrice,
                unclaimed: uc,
              });
            });

            ur["staking"] = final;
          }
        }

        setShroomers(ds);
        sortTable(COLUMNS.rate);
        setLoading(false);
      }
    };
    run();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenPrices]);

  const sortTable = (col) => {
    if (currentSort === col) {
      setCurrentSort(null);
      setShroomers((old) => {
        return [...old].reverse();
      });
    } else {
      setCurrentSort(col);
      setShroomers((old) => {
        if (col === COLUMNS.account) {
          return [...old.sort((a, b) => a[col].localeCompare(b[col]))];
        } else if (col === COLUMNS.rate) {
          return [
            ...old.sort((a, b) => {
              return b.staking[0].daily - a.staking[0].daily;
            }),
          ];
        } else {
          return [...old.sort((a, b) => b[col] - a[col])];
        }
      });
    }
  };

  const renderHeaderColumn = (col, title) => {
    return (
      <th
        style={{ cursor: "pointer", textAlign: "left" }}
        onClick={() => sortTable(col)}
      >
        {title}
      </th>
    );
  };

  const renderTables = () => {
    return (
      <>
        <div>
          Total RADS/day:{" "}
          {shroomers
            .reduce(function (a, b) {
              return a + b.staking[0].daily;
            }, 0)
            .toLocaleString()}
        </div>
        <br />
        <br />
        <table
          style={{
            margin: "auto",
            borderSpacing: "10px 4px",
            textAlign: "left",
          }}
        >
          <thead style={{ textAlign: "center" }}>
            <tr>
              <th></th>
              {renderHeaderColumn(COLUMNS.account, "WALLET")}
              {renderHeaderColumn(COLUMNS.rate, "RADS/day")}
              {renderHeaderColumn(COLUMNS.rate, "WAX/day")}
            </tr>
          </thead>
          <tbody>
            {shroomers.map((d, i) => {
              return (
                <tr style={{ textAlign: "right" }} key={d.account}>
                  <td>{i + 1}</td>
                  <td style={{ textAlign: "left" }}>
                    <span
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                      onClick={() =>
                        linkNewWindow(
                          "https://wax.atomichub.io/explorer/account/" +
                            d.account +
                            "?collection_name=talesofcrypt&order=desc&sort=transferred"
                        )
                      }
                    >
                      {d.account}
                    </span>
                  </td>
                  <td>{d.staking[0].daily.toLocaleString()}</td>
                  <td>{d.staking[0].dailyWax.toFixed(2)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };

  return (
    <Page title="Tales of the Crypto Leaderboard">
      <h2>Staking Leaderboard</h2>
      <br />
      {loading ? (
        <SnakeLoader />
      ) : shroomers && shroomers.length > 0 ? (
        renderTables()
      ) : null}
      <br />
      <br />
      <br />
    </Page>
  );
};

export default TalesOfCryptoLeaderboard;
