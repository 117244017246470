import React, { useState, useEffect, useContext } from "react";
import Page from "../Page";
import SnakeLoader from "../SnakeLoader";
import {
  NovaBoostTableData,
  NovaComboTableData,
  NovaDateTableData,
  NovaLeagueTableData,
  NovaRaceIdTableData,
  NovaTimeTableData,
  NovaWalletTableData,
} from "./NovaDisplay";
import { NovaContext } from "./NovaContextProvider";
import axios from "axios";

const NovaRecentRaces = () => {
  const novaContext = useContext(NovaContext);

  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState([]);

  const [refreshMe, setRefreshMe] = useState(0);

  useEffect(() => {
    if (novaContext.templates) {
      const run = async () => {
        setApiData([]);
        setLoading(true);

        try {
          const r = await axios.get(
            "https://nr-api.win-win.software/api/v1/races/?currentAccount=van&isCurrentOnly=false&page=0&size=200"
          );

          const rd = r?.data?.data ?? [];

          const result = await Promise.all(
            rd.map(async (race) => {
              const oldRace = {
                vehicle_asset_id: race.winner.vehicleAssetId,
                driver1_asset_id: race.winner.driver1AssetId,
                driver2_asset_id: race.winner.driver2AssetId,
              };

              const combo = await novaContext.getComboData(oldRace);

              var date = new Date(race.startedAt);
              var launch_ts = parseInt(date.getTime() / 1000);

              return {
                race_id: race.id,
                race_time: launch_ts,
                winner: race.winner.accountName,
                time: race.winner.timeMs,
                league: race.winner.league,
                boost: race.winner.useBoost,
                ...combo,
              };
            })
          );
          setApiData(result);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };

      run();
    }
  }, [novaContext, refreshMe]);

  const renderApiData = () => {
    return apiData.length > 0 ? (
      <table
        style={{
          margin: "auto",
          textAlign: "left",
          borderSpacing: "12px 4px",
          fontSize: "80%",
        }}
      >
        <thead>
          <tr>
            <th></th>
            <th>ID</th>
            <th>DATE</th>
            <th>LG</th>
            <th>WINNER</th>
            <th>TIME</th>
            <th>VEHICLE</th>
            <th>DRIVER 1</th>
            <th>DRIVER 2</th>
          </tr>
        </thead>
        <tbody>
          {apiData.map((race, i) => {
            return (
              <tr key={"toprace" + i}>
                <NovaBoostTableData boost={race.boost} />
                <NovaRaceIdTableData id={race.race_id} />
                <NovaDateTableData date={race.race_time} />
                <NovaLeagueTableData league={race.league} />
                <NovaWalletTableData wallet={race.winner} />
                <NovaTimeTableData time={race.time} />
                <NovaComboTableData combo={race} />
              </tr>
            );
          })}
        </tbody>
      </table>
    ) : null;
  };

  return (
    <Page title="Nova Rally Recent Races">
      <h2>Recent Races</h2>
      {apiData && apiData.length > 0 ? (
        <button
          style={{
            cursor: "pointer",
            fontFamily: "Cousine",
            fontWeight: "bold",
          }}
          onClick={() => {
            setRefreshMe((old) => old + 1);
          }}
        >
          REFRESH
        </button>
      ) : null}
      <br />
      <br />
      {loading ? <SnakeLoader /> : renderApiData()}
      <br />
      <br />
      <br />
      <br />
    </Page>
  );
};

export default NovaRecentRaces;
