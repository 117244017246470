import React, { useState, useEffect, useContext } from "react";
import Page from "../Page";
import SnakeLoader from "../SnakeLoader";
import { rpc } from "../Utilities/RPC";
import {
  NovaComboTableData,
  NovaDateTableData,
  NovaLeagueTableData,
  NovaRaceIdTableData,
  NovaTimeTableData,
  NovaWalletTableData,
} from "./NovaDisplay";
import { NovaContext } from "./NovaContextProvider";

const NovaTop = () => {
  const NUM_TOP_RACES = 500;

  const novaContext = useContext(NovaContext);

  const [loading, setLoading] = useState(false);
  const [raceCount, setRaceCount] = useState(0);
  const [topRaces, setTopRaces] = useState([]);

  useEffect(() => {
    if (novaContext.templates) {
      const run = async () => {
        setLoading(true);
        setRaceCount(0);

        const getRaces = async (lowerBound = "", result = []) => {
          const r = await rpc.get_table_rows({
            code: "novarallyapp",
            scope: "novarallyapp",
            table: "races",
            json: true,
            limit: 1000,
            lower_bound: lowerBound,
          });
          if (r.rows.length > 0) {
            setRaceCount((old) => old + r.rows.length);
            result = result.concat(r.rows);
            const lb = r.rows[r.rows.length - 1].id + 1;
            return getRaces(lb, result);
          } else {
            return result;
          }
        };

        const result = await getRaces();

        result.sort((a, b) => {
          return a.best_time_ms - b.best_time_ms;
        });

        const combined = [];
        let promises = [];
        const CHUNK_SIZE = 100;
        for (let i = 0; i < NUM_TOP_RACES; i++) {
          setRaceCount((old) => i + 1);
          const promise = rpc.get_table_rows({
            code: "novarallyapp",
            scope: result[i].winner,
            table: "playerraces",
            json: true,
            limit: 1,
            lower_bound: result[i].id,
          });
          promises.push(promise);

          if ((i + 1) % CHUNK_SIZE === 0 || i === NUM_TOP_RACES - 1) {
            const chunk =
              (i + 1) % CHUNK_SIZE === 0 ? CHUNK_SIZE : (i + 1) % CHUNK_SIZE;
            const r = await Promise.allSettled(promises);
            const map = await Promise.all(
              r.map(async (x, j) => {
                const current = result[i + 1 - chunk + j];
                const combo = await novaContext.getComboData(x.value.rows[0]);
                return {
                  ...combo,
                  race_id: current.id,
                  date: current.launch_ts,
                  winner: current.winner,
                  time: current.best_time_ms,
                  league: x.value.rows[0].league,
                };
              })
            );
            combined.push(...map);
            promises = [];
          }
        }
        setTopRaces(combined);
        setLoading(false);
      };

      run();
    }
  }, [NUM_TOP_RACES, novaContext]);

  const renderRaces = (races) => {
    return (
      <table
        style={{
          margin: "auto",
          textAlign: "left",
          borderSpacing: "12px 4px",
          fontSize: "80%",
        }}
      >
        <thead>
          <tr>
            <th></th>
            <th>ID</th>
            <th>DATE</th>
            <th>WINNER</th>
            <th>LG</th>
            <th>TIME</th>
            <th>VEHICLE</th>
            <th>DRIVER 1</th>
            <th>DRIVER 2</th>
          </tr>
        </thead>
        <tbody>
          {races.map((race, i) => {
            return (
              <tr key={"toprace" + i}>
                <td style={{ textAlign: "right" }}>{i + 1}</td>
                <NovaRaceIdTableData id={race.race_id} />
                <NovaDateTableData date={race.date} />
                <NovaWalletTableData wallet={race.winner} />
                <NovaLeagueTableData league={race.league} />
                <NovaTimeTableData time={race.time} />
                <NovaComboTableData combo={race} />
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <Page title="Nova Rally Top Races">
      <h2>Fastest Wins Overall</h2>
      {loading ? <div>{raceCount}</div> : null}
      {loading || !topRaces ? <SnakeLoader /> : renderRaces(topRaces)}
      <br />
      <br />
    </Page>
  );
};

export default NovaTop;
