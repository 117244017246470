import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Page from "../Page";
import SnakeLoader from "../SnakeLoader";
import WalletInput from "../Utilities/WalletInput";
import { renderLinkNewWindow } from "../Utilities/LinkUtilities";
import { makeColorGradient } from "../Utilities/Colors";
import axios from "axios";
import * as AA from "../Utilities/AA";

const GalaSet = (props) => {
  const [loading, setLoading] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [promoData, setPromoData] = useState([]);
  const history = useHistory();
  const [wallet, setWallet] = useState(props.match.params.wallet || "");

  useEffect(() => {
    const run = async () => {
      if (wallet) {
        setLoading(true);
        const RARITY_ORDER = [
          "Titanium",
          "Meteorite",
          "Beskar",
          "Chromium",
          "Plasma",
          "Luminaya",
          "Unicorn",
          "Supernova",
          "GoldStorm",
        ];
        const TYPE_ORDER = ["Ship", "Character", "Planet", "Other", "Satoship"];
        const SERIES_ORDER = [
          "Alliance",
          "Luminayan",
          "Purgamen",
          "Razatech",
          "GALAKTIKA",
        ];
        const r1 = await axios.get(
          AA.API +
            "atomicassets/v1/templates?collection_name=galaktikanft&schema_name=cards&limit=1000&order=desc&sort=created"
        );
        let cards = r1.data.data.filter((x) => x.issued_supply > 0);
        const r2 = await axios.get(
          AA.API +
            "atomicassets/v1/templates?collection_name=galaktikanft&schema_name=promo&limit=1000&order=desc&sort=created"
        );
        const promo = r2.data.data.filter((x) => x.issued_supply > 0);
        const r3 = await axios.get(
          AA.API + "atomicassets/v1/accounts/" + wallet + "/galaktikanft"
        );
        const user = r3.data.data.templates;

        cards.forEach((x) => {
          const ii = user.findIndex((y) => y.template_id === x.template_id);
          if (ii >= 0) {
            x.count = user[ii].assets;
          } else {
            x.count = 0;
          }
          x.series = x.immutable_data.series ?? "Other";
          x.rarity = x.immutable_data.rarity ?? "Titanium";

          if (x.series.startsWith("SP")) {
            x.type = "Spark";
            x.series = "GALAKTIKA";
          } else if (x.immutable_data.class === "Bounty") {
            x.type = "Bounty";
            x.series = "GALAKTIKA";
          } else if (x.name.startsWith("Satoship")) {
            x.name = "Satoship";
            x.type = "Satoship";
          } else if (
            x.name.toLowerCase().includes("ship") ||
            x.name.includes("Station")
          ) {
            x.type = "Ship";
          } else if (
            x.name.includes("Planet") ||
            x.name.includes("Earth") ||
            x.name.includes("Jupiter") ||
            x.name.includes("Mars")
          ) {
            x.type = "Planet";
          } else if (
            x.name.includes("Commander") ||
            x.name.includes("Chief Navigator") ||
            x.name.includes("Flight Captain") ||
            x.name.includes("ELRUA") ||
            x.name.includes("NOBA") ||
            x.name.includes("TALNA") ||
            x.name.includes("Recon Pilot") ||
            x.name.includes("Speed Queen") ||
            x.name.includes("Flight Engineer") ||
            x.name.includes("Cargo Brawn")
          ) {
            x.type = "Character";
          } else {
            x.type = "Other";
          }
        });

        promo.forEach((x) => {
          const ii = user.findIndex((y) => y.template_id === x.template_id);
          if (ii >= 0) {
            x.count = user[ii].assets;
          } else {
            x.count = 0;
          }
        });

        cards = cards.filter((c) => {
          return ![
            "230823",
            "230790",
            "230740",
            "230757",
            "230806",
            "230774",
            "230839",
            "293162",
          ].includes(c.template_id);
        });

        cards.sort((a, b) => {
          let aa =
            SERIES_ORDER.indexOf(b.series) - SERIES_ORDER.indexOf(a.series);
          let bb = TYPE_ORDER.indexOf(a.type) - TYPE_ORDER.indexOf(b.type);
          const cc = a.name.localeCompare(b.name);
          let dd =
            RARITY_ORDER.indexOf(a.immutable_data.rarity) -
            RARITY_ORDER.indexOf(b.immutable_data.rarity);
          aa = aa > 0 ? -1 : aa < 0 ? 1 : 0;
          bb = bb > 0 ? 1 : bb < 0 ? -1 : 0;
          dd = dd > 0 ? 1 : dd < 0 ? -1 : 0;
          return aa * 8 + bb * 4 + cc * 2 + dd;
        });

        const groupBy = (arr, property) => {
          return arr.reduce((acc, cur) => {
            acc[cur[property]] = [...(acc[cur[property]] || []), cur];
            return acc;
          }, {});
        };

        const group = groupBy(cards, "series");

        Object.entries(group).forEach(([k, v]) => {
          group[k] = groupBy(v, "type");
          Object.entries(group[k]).forEach(([kk, vv]) => {
            group[k][kk] = groupBy(vv, "name");
            Object.entries(group[k][kk]).forEach(([kkk, vvv]) => {
              group[k][kk][kkk] = groupBy(vvv, "rarity");
            });
          });
        });

        setCardData(group);

        setPromoData(
          promo.sort((a, b) => {
            const aa = a.name.split("\t");
            const bb = b.name.split("\t");
            return aa[aa.length - 1].localeCompare(bb[bb.length - 1]);
          })
        );
        setLoading(false);
      }
    };
    run();
  }, [wallet]);

  const handleSetWallet = (w) => {
    history.push("/galaktika/collection/" + w);
    setWallet(w);
  };

  const renderCardTable = () => {
    if (cardData.length === 0) {
      return null;
    }

    const bgCols = makeColorGradient(0.7, 0.7, 0.7, 44, 99, 110, 64, 44, 10);

    return (
      <div
        style={{
          display: "inline-block",
        }}
      >
        {Object.entries(cardData).map(([series, types]) => {
          return (
            <table
              key={"table" + series}
              style={{
                margin: "auto",
                borderSpacing: "3px 3px",
                fontSize: "80%",
              }}
            >
              <thead>
                <tr style={{ fontSize: "80%" }}>
                  <th
                    style={{
                      fontSize: "200%",
                      textAlign: "left",
                    }}
                  >
                    {series}
                  </th>
                  <th style={{ width: "28px" }}>TIT</th>
                  <th style={{ width: "28px" }}>MET</th>
                  <th style={{ width: "28px" }}>BSK</th>
                  <th style={{ width: "28px" }}>CHR</th>
                  <th style={{ width: "28px" }}>PLS</th>
                  <th style={{ width: "28px" }}>LUM</th>
                  <th style={{ width: "28px" }}>UNI</th>
                  {series === "Alliance" ||
                  series === "Luminayan" ||
                  series === "Purgamen" ||
                  series === "Razatech" ? (
                    <th style={{ width: "28px" }}>SUP</th>
                  ) : (
                    <th style={{ width: "28px" }}>&nbsp;</th>
                  )}
                  {series === "Razatech" ? (
                    <th style={{ width: "28px" }}>GLD</th>
                  ) : (
                    <th style={{ width: "28px" }}>&nbsp;</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {Object.entries(types).map(([type, names], typeIndex) => {
                  return Object.entries(names).map(([nam, rarities]) => {
                    return (
                      <tr key={series + type + nam}>
                        <td
                          style={{
                            textAlign: "left",
                            width: "148px",
                            padding: "2px 6px",
                            borderRadius: "4px",
                            backgroundColor:
                              bgCols[bgCols.length - 3 - typeIndex],
                          }}
                        >
                          {nam}
                        </td>
                        {Object.entries(rarities).map(([rarity, card]) => {
                          return (
                            <td key={series + type + nam + rarity}>
                              {renderLinkNewWindow(
                                "https://wax.atomichub.io/market?collection_name=galaktikanft&order=asc&sort=price&template_id=" +
                                  card[0].template_id,
                                card[0].count ? (
                                  card[0].count
                                ) : (
                                  <span style={{ opacity: "20%" }}>
                                    &nbsp;0&nbsp;
                                  </span>
                                ),
                                false
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  });
                })}
              </tbody>
            </table>
          );
        })}
      </div>
    );
  };

  const renderPromoTable = () => {
    if (promoData.length === 0) {
      return null;
    }

    const bgCols = makeColorGradient(0.7, 0.7, 0.7, 44, 99, 110, 64, 44, 10);

    return (
      <table
        style={{
          margin: "auto",
          borderSpacing: "3px 3px",
          fontSize: "80%",
        }}
      >
        <tbody>
          {promoData.map((promoCard) => {
            let bgCol = "darkslategray";
            if (promoCard.name.startsWith("ALLIANCE")) {
              bgCol = bgCols[bgCols.length - 3 - 0];
            } else if (promoCard.name.startsWith("LUMINAYAN")) {
              bgCol = bgCols[bgCols.length - 3 - 1];
            } else if (promoCard.name.startsWith("PURGAMEN")) {
              bgCol = bgCols[bgCols.length - 3 - 2];
            } else if (
              promoCard.name.startsWith("RAZATECH") ||
              promoCard.name.startsWith("\tRAZATECH")
            ) {
              bgCol = bgCols[bgCols.length - 3 - 3];
            }
            return (
              <tr key={promoCard.name}>
                <td
                  style={{
                    textAlign: "left",
                    borderRadius: "4px",
                    padding: "2px 6px",
                    backgroundColor: bgCol,
                  }}
                >
                  {promoCard.name}
                </td>
                <td>
                  {renderLinkNewWindow(
                    "https://wax.atomichub.io/market?collection_name=galaktikanft&order=asc&sort=price&template_id=" +
                      promoCard.template_id,
                    promoCard.count ? (
                      promoCard.count
                    ) : (
                      <span style={{ opacity: "20%" }}>&nbsp;0&nbsp;</span>
                    ),
                    false
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <Page title="Galaktika Collection">
      <h2>Collection</h2>
      {loading ? (
        <SnakeLoader />
      ) : (
        <WalletInput
          wallet={props.match.params.wallet || ""}
          setWallet={handleSetWallet}
          buttonText="ILLUMINATE ME"
          loading={false}
        />
      )}
      <br />
      {loading ? null : <div>{renderCardTable()}</div>}
      {loading ? null : <div>{renderPromoTable()}</div>}
      <br />
      <br />
    </Page>
  );
};

export default GalaSet;
