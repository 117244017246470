import React, { useState } from "react";
import SnakeLoader from "../SnakeLoader";

const WalletInput = ({ wallet, setWallet, loading, buttonText }) => {
  const [walletInput, setWalletInput] = useState(wallet);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (walletInput.trim()) {
      setWallet(walletInput.trim());
    }
  };

  const handleWalletChange = (e) => {
    setWalletInput(e.target.value.toLowerCase().trim());
  };

  return loading ? (
    <SnakeLoader />
  ) : (
    <div>
      <form onSubmit={handleSubmit}>
        wallet address
        <br />
        <br />
        <input
          size="12"
          type="text"
          style={{
            fontFamily: "Cousine",
            textAlign: "center",
            textTransform: "lowercase",
          }}
          name="walletInput"
          onChange={handleWalletChange}
          value={walletInput}
        />
        <br />
        <br />
        <input
          type="submit"
          style={{ fontFamily: "Cousine", fontWeight: "bold" }}
          value={buttonText ? buttonText : "SET WALLET"}
          disabled={loading}
        />
      </form>
    </div>
  );
};

export default WalletInput;
