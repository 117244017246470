import React, { useState, useEffect } from "react";
import firebase from "../Firebase";
import Page from "../Page";
import axios from "axios";
import { RPLANET_TEMPLATES } from "rplanet";
// import SnakeLoader from "../SnakeLoader";

const Winpacks = () => {
  if (process.env.NODE_ENV === "development") {
    firebase.functions().useEmulator("localhost", 5001);
  }

  const [pageNumber, setPageNumber] = useState(0);
  const [stopGo, setStopGo] = useState(false);

  useEffect(() => {
    if (stopGo) {
      let setMintDataExternal = firebase
        .functions()
        .httpsCallable("setMintDataExternal");
      const getAssetLogs = async (id) => {
        const r = await axios.get(
          "https://wax.simplemarket.io/api/v1/asset/logs?assetId=" + id
        );
        return r.data;
      };
      const getRarityVariant = async (tx) => {
        const r = await axios.get(
          "https://wax.dfuse.eosnation.io/v0/transactions/" + tx
        );
        const t =
          r.data.execution_trace.action_traces[0].inline_traces[0].act.data
            .template_id;
        const ii = RPLANET_TEMPLATES.findIndex((x) => x[0] === t);
        return [RPLANET_TEMPLATES[ii][2], RPLANET_TEMPLATES[ii][3]];
      };

      const run = async () => {
        const result = [];
        const r1 = await axios.get(
          "https://wax.api.simpleassets.io/v1/author/a.rplanet/assets?category=winpacks&page=" +
            pageNumber +
            "&limit=500&sortField=assetId&sortOrder=asc"
        );
        const d = r1.data.results;
        console.log(d);
        for (let i = 0; i < d.length; i++) {
          const e = d[i];
          const el = {};
          el.assetId = e.assetId;
          el.status = e.status;
          const logs = await getAssetLogs(e.assetId);
          logs.forEach((log) => {
            if (log.act.name === "createlog") {
              el.crafter = log.act.data.owner;
              el.craftDate = log.block_time;
              el.craftTx = log.trx_id;
            } else if (
              log.act.name === "transfer" &&
              log.act.data.to === "u.rplanet"
            ) {
              el.opener = log.act.data.from;
              el.openDate = log.block_time;
            } else if (log.act.name === "burn") {
              el.openTx = log.trx_id;
            }
          });
          if (el.openTx) {
            const [rarity, variant] = await getRarityVariant(el.openTx);
            el.rarity = rarity;
            el.variant = variant;
          }
          result.push(el);
          const final = {
            name: e.idata.name,
            mint: e.idata.minted,
            data: el,
          };
          console.log(i, final);
          try {
            setMintDataExternal(final);
            await new Promise((r) => setTimeout(r, 200));
          } catch (e) {
            await new Promise((r) => setTimeout(r, 5000));
            i--;
          }
        }
      };
      run();
    }
  }, [pageNumber, stopGo]);

  return (
    <Page title="RPlanet Crafted Elements">
      <h2>Crafted Elements</h2>
      <br />
      <br />
      <br />
      <input
        type="button"
        value={"next page " + pageNumber}
        onClick={() => {
          setPageNumber((old) => old + 1);
        }}
      />
      <input
        type="button"
        value={stopGo ? "STOP" : "GO"}
        onClick={() => {
          setStopGo((old) => !old);
        }}
      />
      <br />
    </Page>
  );
};

export default Winpacks;
