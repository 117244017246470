import React from "react";
import { Link } from "react-router-dom";
import Page from "../Page";

const Breeders = () => {
  return (
    <Page title="Breeders Zone">
      <h2>
        <Link to="/breeders/staking">Staking Calculator + Asset IDs</Link>
        <br />
        <br />
        <Link to="/breeders/leaderboard">Staking Leaderboard</Link>
        <br />
        <br />
        {/* <Link to="/breeders/values">Staking Values</Link>
        <br />
        <br /> */}
      </h2>
    </Page>
  );
};

export default Breeders;
