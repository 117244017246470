import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Page from "../Page";
import SnakeLoader from "../SnakeLoader";
import WalletInput from "../Utilities/WalletInput";
import useTokenPrices from "../Utilities/useTokenPrices";
import axios from "axios";
import { TokenPriceTable } from "../Utilities/Display";
import * as AA from "../Utilities/AA";

const LandCalculator = (props) => {
  const [tokenPrices, getTokenPrice] = useTokenPrices();
  const [mineralPrices, setMineralPrices] = useState([]);
  const history = useHistory();
  const [landAssets, setLandAssets] = useState([]);
  const [upgradeQueue, setUpgradeQueue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [wallet, setWallet] = useState(props.match.params.wallet || "");
  const [aetherSpendLimit, setAetherSpendLimit] = useState(10000000);
  const [equalPrices, setEqualPrices] = useState(false);

  useEffect(() => {
    setLandAssets([]);
    setUpgradeQueue([]);
    if (wallet) {
      const run = async () => {
        if (tokenPrices) {
          setLoading(true);
          const wecanPrice = getTokenPrice("WECANWAX");
          const waxonPrice = getTokenPrice("WAXONWAX");
          const eneftPrice = getTokenPrice("ENEFTWAX");
          const caponPrice = getTokenPrice("CAPONWAX");
          const aetherPrice = getTokenPrice("AETHERWAX");
          let tp = {
            Wecanite: wecanPrice,
            Waxon: waxonPrice,
            Enefterium: eneftPrice,
            Caponium: caponPrice,
            Aether: aetherPrice,
          };
          if (equalPrices) {
            const avg = (wecanPrice + waxonPrice + eneftPrice + caponPrice) / 4;
            tp = {
              Wecanite: avg,
              Waxon: avg,
              Enefterium: avg,
              Caponium: avg,
              Aether: aetherPrice,
            };
          }

          const r1 = await axios.get(
            AA.API +
              "atomicassets/v1/assets?collection_name=rplanet&schema_name=lands&page=1&limit=100&order=desc&sort=asset_id&owner=" +
              wallet
          );
          const r2 = await axios.get(
            AA.API +
              "atomicassets/v1/assets?collection_name=rplanet&schema_name=lands2&page=1&limit=100&order=desc&sort=asset_id&owner=" +
              wallet
          );

          const a = r1.data.data.concat(r2.data.data);
          if (a.length > 0) {
            const final = calculateLandValues(a, tp);
            setLandAssets(final);
            setMineralPrices(tp);
            buildUpgradeQueue(final, 0, tp);
          }
          setLoading(false);
        }
      };
      run();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet, equalPrices, aetherSpendLimit]);

  const calculateLandValues = (lands, tp) => {
    const result = [];
    lands.forEach((land) => {
      result.push(calculateLandValue(land, tp));
    });
    result.sort((a, b) => a.roi - b.roi);
    return result;
  };

  const calculateLandValue = (land, tp) => {
    const level = land.mutable_data.level ? land.mutable_data.level : 0;
    const rate = getRate(level, land.template.immutable_data.rarity);
    const nextRate =
      getRate(level + 1, land.template.immutable_data.rarity) - rate;
    const nextCostAether = Math.ceil(60000 * Math.pow(1.03, level));
    const nextCostWax = nextCostAether * tp.Aether;
    const gain = 365 * 24 * nextRate * tp[land.name];
    const roi = (12 * nextCostWax) / gain;
    return {
      id: land.asset_id,
      owner: land.owner,
      mineral: land.name,
      mint: land.template_mint,
      startLevel: level,
      level: level,
      rarity: land.template.immutable_data.rarity,
      rate: rate,
      nextRate: nextRate,
      nextCostAether: nextCostAether,
      nextCostWax: nextCostWax,
      gain: gain,
      roi: roi,
    };
  };

  const recalculateLandValues = (lands, tp) => {
    const result = [];
    lands.forEach((land) => {
      result.push(recalculateLandValue(land, tp));
    });
    result.sort((a, b) => a.roi - b.roi);
    return result;
  };

  const recalculateLandValue = (land, tp) => {
    const rate = getRate(land.level, land.rarity);
    const nextRate = getRate(land.level + 1, land.rarity) - rate;
    const nextCostAether = Math.ceil(60000 * Math.pow(1.03, land.level));
    const nextCostWax = nextCostAether * tp.Aether;
    const gain = 365 * 24 * nextRate * tp[land.mineral];
    const roi = (12 * nextCostWax) / gain;
    return {
      ...land,
      rate: rate,
      nextRate: nextRate,
      nextCostAether: nextCostAether,
      nextCostWax: nextCostWax,
      gain: gain,
      roi: roi,
    };
  };

  const buildUpgradeQueue = (currentLands, cumulativeCost, tp) => {
    const cost = cumulativeCost + currentLands[0].nextCostAether;
    if (cost < aetherSpendLimit) {
      currentLands[0].level++;
      setUpgradeQueue((old) => {
        const a = [
          ...old,
          {
            ...currentLands[0],
            cumulativeCost: cost,
          },
        ];
        a.reverse();
        const uniques = [];
        a.forEach((x) => {
          if (!uniques.includes(x.id)) {
            uniques.push(x.id);
            x.last = "true";
          } else {
            x.last = "false";
          }
        });
        a.reverse();
        return a;
      });
      const newLands = recalculateLandValues([...currentLands], tp);
      buildUpgradeQueue(newLands, cost, tp);
    }
  };

  const getRate = (level, rarity) => {
    let rate = 100;
    if (level > 200) level = 200;
    if (rarity === "uncommon") rate = 120;
    if (rarity === "rare") rate = 140;
    if (rarity === "epic") rate = 160;
    if (rarity === "legendary") rate = 180;
    if (rarity === "mythic") rate = 200;
    return rate * Math.pow(1.02, level ? level : 0);
  };

  const getCumulativeCost = (level, startLevel = 0) => {
    let cum = 0;
    for (let i = startLevel; i < level; i++) {
      cum += Math.ceil(60000 * Math.pow(1.03, i));
    }
    return cum;
  };

  const handleSetWallet = (w) => {
    history.push("/rplanet/land/calculator/" + w);
    setWallet(w);
  };

  const renderLands = () => {
    return (
      <table
        style={{
          margin: "auto",
          borderSpacing: "10px 4px",
          textAlign: "left",
          fontSize: "80%",
        }}
      >
        <thead>
          <tr style={{ textAlign: "center" }}>
            <td>Asset ID</td>
            <td>Mint</td>
            <td>Mineral</td>
            <td>Rarity</td>
            <td>Level</td>
            <td>Rate</td>
            <td style={{ fontSize: "80%" }}>
              Rate
              <br />
              increase
            </td>
            <td style={{ fontSize: "80%" }}>
              AETH Cost
              <br />
              to upgrade
            </td>
            <td style={{ fontSize: "80%" }}>
              WAX Cost
              <br />
              to upgrade
            </td>
            <td style={{ fontSize: "80%" }}>
              WAX Gain
              <br />
              (yearly)
            </td>
            <td style={{ fontSize: "80%" }}>
              ROI
              <br />
              (months)
            </td>
          </tr>
        </thead>
        <tbody>
          {landAssets.map((land) => {
            return (
              <tr key={"land-" + land.id}>
                <td style={{ fontSize: "80%" }}>{land.id}</td>
                <td style={{ textAlign: "center" }}>{land.mint}</td>
                <td>{land.mineral}</td>
                <td>{land.rarity}</td>
                <td style={{ textAlign: "center" }}>{land.startLevel}</td>
                <td style={{ textAlign: "center" }}>{land.rate.toFixed(3)}</td>
                <td style={{ textAlign: "center" }}>
                  {land.nextRate.toFixed(3)}
                </td>
                <td style={{ textAlign: "center" }}>
                  {land.nextCostAether.toFixed(0)}
                </td>
                <td style={{ textAlign: "center" }}>
                  {land.nextCostWax.toFixed(3)}
                </td>
                <td style={{ textAlign: "center" }}>{land.gain.toFixed(3)}</td>
                <td style={{ textAlign: "center" }}>{land.roi.toFixed(2)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const renderUpgradeQueue = () => {
    return (
      <table
        style={{
          margin: "auto",
          borderSpacing: "10px 4px",
          textAlign: "left",
          fontSize: "80%",
        }}
      >
        <thead>
          <tr style={{ textAlign: "center" }}>
            <th>Asset ID</th>
            <th>Mint</th>
            <th>Mineral</th>
            <th>Rarity</th>
            <th>Level</th>
            <th>
              Overall
              <br />
              Cost
            </th>
            <th>
              Land
              <br />
              Cost
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {upgradeQueue.map((land, i) => {
            return (
              <tr key={i + "upgrade" + land.id}>
                <td style={{ fontSize: "80%" }}>{land.id}</td>
                <td style={{ textAlign: "center" }}>{land.mint}</td>
                <td>{land.mineral}</td>
                <td>{land.rarity}</td>
                <td style={{ textAlign: "center" }}>{land.level}</td>
                <td style={{ textAlign: "right" }}>{land.cumulativeCost}</td>
                <td style={{ textAlign: "right" }}>
                  {getCumulativeCost(land.level, land.startLevel)}
                </td>
                <td>{land.last === "true" ? "←" : ""}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <Page title="Land Calculator">
      <h2>Land Calculator</h2>
      <Link to="/rplanet/land/list">Land List</Link>&nbsp;&nbsp;
      <Link to="/rplanet/land/leaderboard">Land Leaderboard</Link>
      <br />
      <br />
      {loading ? (
        <SnakeLoader />
      ) : (
        <WalletInput
          wallet={props.match.params.wallet || ""}
          setWallet={handleSetWallet}
          buttonText="LAND HO"
          loading={false}
        />
      )}
      {loading || landAssets.length === 0 ? null : (
        <div>
          <br />
          <div>Lands: {landAssets.length}</div>
          <br />
          <TokenPriceTable
            coinPriceArray={[
              ["WECAN", mineralPrices.Wecanite.toFixed(8)],
              ["WAXON", mineralPrices.Waxon.toFixed(8)],
              ["ENEFT", mineralPrices.Enefterium.toFixed(8)],
              ["CAPON", mineralPrices.Caponium.toFixed(8)],
              ["AETHER", mineralPrices.Aether.toFixed(8)],
            ]}
          />
          <br />
          <span>
            <input
              type="checkbox"
              checked={equalPrices}
              onChange={() => setEqualPrices((old) => !old)}
            />
            <span
              onClick={() => setEqualPrices((old) => !old)}
              style={{ cursor: "pointer", fontSize: "90%" }}
            >
              Equalize mineral prices
            </span>
          </span>
          <br />
          <br />

          <div>
            Aether to spend:&nbsp;
            <select
              value={aetherSpendLimit}
              onChange={(e) => setAetherSpendLimit(e.target.value)}
            >
              <option value={1000000}>{(1000000).toLocaleString()}</option>
              <option value={5000000}>{(5000000).toLocaleString()}</option>
              <option value={10000000}>{(10000000).toLocaleString()}</option>
              <option value={50000000}>{(50000000).toLocaleString()}</option>
              <option value={100000000}>
                {(100000000).toLocaleString()} (slow)
              </option>
              <option value={500000000}>
                {(500000000).toLocaleString()} (slower)
              </option>
              <option value={1000000000}>
                {(1000000000).toLocaleString()} (very slow)
              </option>
            </select>
          </div>
          <br />
          <h3>Optimal Upgrade Path:</h3>
          {renderUpgradeQueue()}
          <br />
          <h3>Best Next Upgrade:</h3>
          {renderLands()}
        </div>
      )}
      <br />
      <br />
    </Page>
  );
};

export default LandCalculator;
