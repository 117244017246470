import React, { useState, useEffect } from "react";

const WaxLogin = ({ ual }) => {
  const [activeUser, setActiveUser] = useState();

  useEffect(() => {
    const run = async () => {
      if (ual && ual.activeUser) {
        setActiveUser(ual.activeUser);
      }
    };
    run();
  }, [ual]);

  const renderLogoutButton = () => {
    if (!!ual.activeUser && !!ual.activeAuthenticator) {
      return (
        <button
          style={{
            cursor: "pointer",
            fontWeight: "bold",
            fontSize: "80%",
          }}
          onClick={ual.logout}
        >
          Logout
        </button>
      );
    }
  };

  const openLoginModal = () => {
    if (!ual.activeUser) {
      ual.showModal();
    }
  };

  const renderLoginButton = () => {
    return (
      <div>
        <br />
        <br />
        <button
          style={{
            cursor: "pointer",
            fontWeight: "bold",
            fontSize: "140%",
          }}
          onClick={openLoginModal}
        >
          Login
        </button>
      </div>
    );
  };

  const renderNameAndBalance = () => {
    return activeUser ? (
      <div>
        <h2>
          {activeUser.accountName} {renderLogoutButton()}
        </h2>
      </div>
    ) : null;
  };

  return (
    <div>
      {ual.activeUser ? renderNameAndBalance() : null}
      {ual.activeUser ? null : renderLoginButton()}
    </div>
  );
};

export default WaxLogin;
