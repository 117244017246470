import React from "react";
import { linkNewWindow } from "./LinkUtilities";

const TokenPriceTable = ({ coinPriceArray }) => {
  return (
    <table
      style={{
        margin: "auto",
        borderSpacing: "10px 4px",
      }}
    >
      <tbody>
        {coinPriceArray.map((x) => {
          return (
            <TokenPriceTableRow
              key={"tokenprice" + x[0] + x[1]}
              coin={x[0]}
              price={x[1]}
            />
          );
        })}
      </tbody>
    </table>
  );
};

const TokenPriceTableRow = ({ coin, price }) => {
  coin = coin.toUpperCase();
  return (
    <tr>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        <span>{coin}</span>/
        <span style={{ fontSize: "80%" }}>
          {coin === "WAX" ? "USD" : "WAX"}
        </span>
      </td>
      <td style={{ textAlign: "left" }}>{price}</td>
    </tr>
  );
};

const AlcorLinks = ({ coinContractArray }) => {
  const renderLink = (coin, contract) => {
    return (
      <span
        onClick={() =>
          linkNewWindow(
            "https://wax.alcor.exchange/trade/" +
              coin +
              "-" +
              contract +
              "_wax-eosio.token"
          )
        }
        style={{
          textDecoration: "underline",
          cursor: "pointer",
          fontWeight: "bold",
        }}
      >
        {coin.toUpperCase()}
      </span>
    );
  };

  return (
    <div>
      Buy/sell&nbsp;
      {coinContractArray.map((x) => {
        return (
          <React.Fragment key={"alcorlink" + x[0] + x[1]}>
            {renderLink(x[0], x[1])}
            &nbsp;
          </React.Fragment>
        );
      })}
    </div>
  );
};

const AccountBalances = ({ balanceArray, showDecimals }) => {
  return balanceArray &&
    balanceArray.filter((balance) => !balance.startsWith("0 ")).length > 0 ? (
    <div>
      <div>Account balance:</div>
      <table style={{ margin: "auto", borderSpacing: "10px 4px" }}>
        <tbody>
          {balanceArray
            .filter((balance) => !balance.startsWith("0 "))
            .map((balance) => {
              const amount = balance.split(" ")[0];
              const token = balance.split(" ")[1];
              return (
                <tr
                  key={"accountbalance" + token}
                  style={{
                    fontSize: "120%",
                    fontWeight: "bold",
                  }}
                >
                  <td style={{ textAlign: "right" }}>
                    {showDecimals
                      ? parseFloat(amount).toLocaleString()
                      : parseInt(amount).toLocaleString()}
                  </td>
                  <td style={{ textAlign: "left" }}>{token}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  ) : null;
};

export { TokenPriceTable, AlcorLinks, AccountBalances };
