import axios from "axios";
import React, { useState, useEffect } from "react";
import Page from "../Page";
import SnakeLoader from "../SnakeLoader";
import { rpc } from "../Utilities/RPC";
import WaxLogin from "../Wallet/Login";
import * as C from "./Constants";
import * as TXN from "../Wallet/Transaction";
import * as AA from "../Utilities/AA";

const OwnerStaking = ({ ual }) => {
  const [loading, setLoading] = useState(false);
  const [ownedYards, setOwnedYards] = useState([]);
  const [ownedLounges, setOwnedLounges] = useState([]);
  // const [stakedYards, setStakedYards] = useState([]);
  // const [stakedLounges, setStakedLounges] = useState([]);

  useEffect(() => {
    const run = async () => {
      if (ual.activeUser) {
        let r = await axios.get(
          AA.API +
            "atomicassets/v1/assets?collection_name=centurytrain&schema_name=station&owner=" +
            ual.activeUser.accountName +
            "&page=1&limit=100&order=desc&sort=asset_id"
        );
        const owned = r.data.data.map((x) => x.asset_id);
        const oy = [];
        const ol = [];
        for (let i = 0; i < owned.length; i++) {
          r = await rpc.get_table_rows({
            code: "s.century",
            table: "stations",
            scope: "modern",
            lower_bound: owned[i],
            upper_bound: owned[i],
            limit: 1,
          });
          const result = r.rows;
          if (result.length > 0) {
            if (result[0].railyard_tier > 0) {
              oy.push(owned[i]);
            }
            if (result[0].lounge_tier > 0) {
              ol.push(owned[i]);
            }
          }
        }
        setOwnedYards(oy);
        setOwnedLounges(ol);

        // const getRRAssets = async (lowerBound = "", result = []) => {
        //   const r = await rpc.get_table_rows({
        //     code: "s.century",
        //     table: "rrassets",
        //     scope: ual.activeUser.accountName,
        //     lower_bound: lowerBound,
        //     limit: 1000,
        //   });
        //   if (r.rows.length > 0) {
        //     result = result.concat(r.rows);
        //     const lb = parseInt(r.rows.at(-1).asset_id) + 1;
        //     return getRRAssets(lb, result);
        //   } else {
        //     return result;
        //   }
        // };
        // r = await getRRAssets();
        // const uniqueYards = [
        //   ...new Set(
        //     r
        //       .filter((x) => x.schema === "locomotive")
        //       .map((x) => x.station_asset_id)
        //   ),
        // ];
        // const uniqueLounges = [
        //   ...new Set(
        //     r
        //       .filter((x) => x.schema === "conductor")
        //       .map((x) => x.station_asset_id)
        //   ),
        // ];
        // setStakedYards(uniqueYards);
        // setStakedLounges(uniqueLounges);
      }
    };
    run();
  }, [ual.activeUser]);

  const getStationClaimAction = async (stationId, schema, type) => {
    let data = {};
    if (type === "soclaim") {
      data = {
        railroader: ual.activeUser.accountName,
        century: "modern",
        schema: schema,
        station_id: stationId,
      };
    } else {
      const r = await axios.get(AA.API + "atomicassets/v1/assets/" + stationId);
      data = {
        railroader: ual.activeUser.accountName,
        station_owner: r.data.data.owner,
        century: "modern",
        schema: schema,
        station_id: stationId,
      };
    }
    const action = TXN.createAction(
      "s.century",
      type,
      ual.activeUser.accountName,
      data
    );
    return action;
  };

  const claimAllStations = async (stationIds, schema, type) => {
    setLoading(true);
    const actions = [];
    for (let i = 0; i < stationIds.length; i++) {
      const stationId = stationIds[i];
      const action = await getStationClaimAction(stationId, schema, type);
      actions.push(action);
    }
    await TXN.signTransaction(ual.activeUser, actions);
    setLoading(false);
  };

  const claimStation = async (stationId, schema, type) => {
    const action = await getStationClaimAction(stationId, schema, type);
    await TXN.signTransaction(ual.activeUser, [action]);
  };

  const Station = ({ stationId, schema, type }) => {
    return (
      <tr>
        <td>{stationId}</td>
        <td>{C.STATION_NAMES[stationId]}</td>
        <td
          style={{ cursor: "pointer" }}
          onClick={() => claimStation(stationId, schema, type)}
        >
          CLAIM
        </td>
      </tr>
    );
  };

  const renderStationSection = (stations, title, schema, type) => {
    stations.sort((a, b) => a - b);
    return stations.length > 0 ? (
      <div>
        <h3>{title}</h3>
        {loading ? (
          <SnakeLoader />
        ) : (
          <span
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() => claimAllStations(stations, schema, type)}
          >
            CLAIM ALL
          </span>
        )}
        <br />
        <br />
        <table
          style={{
            margin: "0px auto",
            textAlign: "left",
            borderSpacing: "12px 0px",
          }}
        >
          <tbody>
            {stations.map((s) => {
              return (
                <Station
                  stationId={s}
                  schema={schema}
                  type={type}
                  key={title + s}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    ) : null;
  };

  return (
    <Page title="Station Owner Claim">
      <h2>Station Owner Claim</h2>
      <WaxLogin ual={ual} />
      {ual.activeUser ? (
        <div>
          {renderStationSection(
            ownedYards,
            "Owned Yards",
            "locomotive",
            "soclaim"
          )}
          {renderStationSection(
            ownedLounges,
            "Owned Lounges",
            "conductor",
            "soclaim"
          )}
          {/* {renderStationSection(
            stakedYards,
            "Staked Yards",
            "locomotive",
            "rrclaim"
          )}
          {renderStationSection(
            stakedLounges,
            "Staked Lounges",
            "conductor",
            "rrclaim"
          )} */}
        </div>
      ) : null}
      <br />
      <br />
      <br />
      <br />
    </Page>
  );
};

export default OwnerStaking;
