import axios from "axios";
import React, { useEffect } from "react";
import firebase from "../Firebase";
import Page from "../Page";
import * as AA from "../Utilities/AA";

const NovaUpdateTemplates = ({ schema }) => {
  if (process.env.NODE_ENV === "development") {
    firebase.functions().useEmulator("localhost", 5001);
  }

  useEffect(() => {
    let setTemplateInfo = firebase.functions().httpsCallable("setTemplateInfo");

    const run = async () => {
      const r1 = await axios.get(
        AA.API +
          "atomicassets/v1/templates?" +
          "collection_name=novarallywax&schema_name=drivers&page=1&limit=1000&order=desc&sort=created"
      );
      const r2 = await axios.get(
        AA.API +
          "atomicassets/v1/templates?" +
          "collection_name=novarallywax&schema_name=vehicles&page=1&limit=1000&order=desc&sort=created"
      );
      const a = r1.data.data.concat(r2.data.data);
      for (let i = 0; i < a.length; i++) {
        const t = a[i];
        const r = await setTemplateInfo({
          templateId: t.template_id,
          name: t.immutable_data.name,
          rarity: t.immutable_data.Rarity,
          league: t.immutable_data["Nova League"],
        });
        console.log(r);
      }
    };

    run();
  }, []);

  return (
    <Page title="Nova Update">
      <br />
      <br />
    </Page>
  );
};

export default NovaUpdateTemplates;
