import React, { useState, useEffect } from "react";
import firebase from "../Firebase";
import Page from "../Page";
// import { Link } from "react-router-dom";
// import axios from "axios";
// import SnakeLoader from "../SnakeLoader";

const UnpackStats = (props) => {
  // const [loading, setLoading] = useState(false);
  // const [wallet, setWallet] = useState(props.match.params.wallet);
  // const [walletInput, setWalletInput] = useState("");
  // const [assetIds, setAssetIds] = useState([]);
  const [rarities, setRarities] = useState({
    Common: { count: 0, odds: 0.62, assets: [] },
    Uncommon: { count: 0, odds: 0.21, assets: [] },
    Rare: { count: 0, odds: 0.095, assets: [] },
    Epic: { count: 0, odds: 0.05, assets: [] },
    Legendary: { count: 0, odds: 0.02, assets: [] },
    Mythic: { count: 0, odds: 0.005, assets: [] },
  });

  if (process.env.NODE_ENV === "development") {
    firebase.functions().useEmulator("localhost", 5001);
  }

  useEffect(() => {}, []);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (walletInput.trim()) {
  //     setWallet(walletInput);
  //   }
  // };

  // const handleWalletChange = (e) => {
  //   setWalletInput(e.target.value.toLowerCase());
  // };

  const handleRaritiesChange = (e) => {
    const value = e.target.value.replace(/\D/, "");
    if (value) {
      let i = parseInt(value);
      i = i > 9999 ? 9999 : i;
      setRarities({
        ...rarities,
        [e.target.name]: { ...rarities[e.target.name], count: i },
      });
    } else {
      setRarities({
        ...rarities,
        [e.target.name]: { ...rarities[e.target.name], count: 0 },
      });
    }
  };

  const handleFocus = (event) => event.target.select();

  const renderInput = (name) => {
    return (
      <input
        type="text"
        pattern="[0-9]*"
        inputMode="numeric"
        style={{ width: "60px", textAlign: "center" }}
        onChange={handleRaritiesChange}
        onFocus={handleFocus}
        name={name}
        value={rarities[name].count}
      />
    );
  };

  const getTotalAssets = () => {
    let count = 0;
    for (const value of Object.values(rarities)) {
      count += value.count;
    }
    return count;
  };

  return (
    <Page title="RPlanet Unpacking Stats">
      <h2>Unpacking Stats</h2>
      <div>
        {/*loading ? (
          <SnakeLoader />
        ) : (
            { <form onSubmit={handleSubmit}>
              wallet address
              <br />
              <br />
              <input
                size="12"
                type="text"
                style={{
                  fontFamily: "Cousine",
                  textAlign: "center",
                  textTransform: "lowercase",
                }}
                name="walletInput"
                onChange={handleWalletChange}
                value={walletInput}
              />
              <br />
              <br />
              <input
                type="submit"
                style={{ fontFamily: "Cousine", fontWeight: "bold" }}
                value="AM I LUCKY?"
              />
            </form> }
        )*/}
        <table
          style={{
            margin: "auto",
            textAlign: "center",
            borderSpacing: "20px 8px",
          }}
        >
          <tbody>
            <tr>
              <td>Common</td>
              <td>Uncommon</td>
              <td>Rare</td>
            </tr>
            <tr>
              <td>{renderInput("Common")}</td>
              <td>{renderInput("Uncommon")}</td>
              <td>{renderInput("Rare")}</td>
            </tr>
            <tr>
              <td>Epic</td>
              <td>Legendary</td>
              <td>Mythic</td>
            </tr>
            <tr>
              <td>{renderInput("Epic")}</td>
              <td>{renderInput("Legendary")}</td>
              <td>{renderInput("Mythic")}</td>
            </tr>
          </tbody>
        </table>
        <h3>Total crafts: {getTotalAssets()}</h3>
        <table
          style={{
            margin: "auto",
            textAlign: "center",
            borderSpacing: "20px 8px",
          }}
        >
          <thead>
            <tr>
              <td></td>
              <td>Expected</td>
              <td>Actual</td>
              <td>Expected</td>
              <td>Actual</td>
              <td>Diff</td>
            </tr>
          </thead>
          <tbody>
            {Object.keys(rarities).map((rar) => {
              let expected = rarities[rar].odds * getTotalAssets();
              let actualPercent =
                rarities[rar].count > 0
                  ? rarities[rar].count / getTotalAssets()
                  : 0;
              let diff =
                expected > 0 && rarities[rar].count > 0
                  ? (
                      (100 * (rarities[rar].count - expected)) /
                      expected
                    ).toFixed(2)
                  : 0;
              return (
                <tr key={rar} style={{ textAlign: "center" }}>
                  <td style={{ textAlign: "left" }}>{rar}</td>
                  <td>{(100 * rarities[rar].odds).toFixed(1) + "%"}</td>
                  <td>{(100 * actualPercent).toFixed(2) + "%"}</td>
                  <td>{expected.toFixed(2)}</td>
                  <td>{rarities[rar].count}</td>
                  <td
                    style={{
                      color: diff > 0 ? "green" : diff < 0 ? "red" : "inherit",
                    }}
                  >
                    {diff > 0 ? "+" + diff : diff}%
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <br />
      <br />
      <br />
      <br />
    </Page>
  );
};

export default UnpackStats;
