import React, { useState, useEffect } from "react";
import Page from "../Page";
import SnakeLoader from "../SnakeLoader";
import { calculateHourlyBreeders } from "../Utilities/Calculate";
import { TokenPriceTable } from "../Utilities/Display";
import { rpc } from "../Utilities/RPC";
import useTokenPrices from "../Utilities/useTokenPrices";

const columns = {
  wallet: "wallet",
  unclaimed: "unclaimed",
  hourly: "hourly",
};

const BreedersLeaderboard = () => {
  const [tokenPrices, getTokenPrice] = useTokenPrices();
  const [leaders, setLeaders] = useState([]);
  const [currentSort, setCurrentSort] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const run = async () => {
      if (tokenPrices) {
        setLoading(true);
        const r1 = await rpc.get_table_rows({
          json: true,
          code: "breederspool",
          scope: "breederspool",
          table: "pools",
          limit: 1,
        });
        const poolRow = r1.rows[0];

        const getUsers = async (lowerBound = "", result = []) => {
          const r = await rpc.get_table_rows({
            json: true,
            code: "breederspool",
            scope: "breederspool",
            table: "users",
            lower_bound: lowerBound,
            limit: 1000,
          });
          if (r.rows.length > 0) {
            result = result.concat(r.rows);
            const lb = r.rows[r.rows.length - 1].username + "1";
            return getUsers(lb, result);
          } else {
            return result;
          }
        };

        const users = await getUsers();
        const final = users.map((userRow) => {
          const hr = calculateHourlyBreeders(userRow, poolRow);
          const uc = parseFloat(userRow.data[0].unclaimed);
          return { wallet: userRow.username, hourly: hr, unclaimed: uc };
        });
        setCurrentSort(columns.hourly);
        setLeaders(
          final
            .filter((x) => x.hourly > 0 || x.unclaimed > 0)
            .sort((a, b) => b.unclaimed - a.unclaimed)
            .sort((a, b) => b.hourly - a.hourly)
        );
        setLoading(false);
      }
    };
    run();
  }, [tokenPrices]);

  const sortTable = (col) => {
    if (currentSort === col) {
      setCurrentSort(null);
      setLeaders((old) => {
        return [...old].reverse();
      });
    } else {
      setCurrentSort(col);
      setLeaders((old) => {
        if (col === columns.wallet) {
          return [...old.sort((a, b) => a[col].localeCompare(b[col]))];
        } else {
          return [
            ...old.sort((a, b) => {
              const x = a[col] ? a[col] : 0;
              const y = b[col] ? b[col] : 0;
              return y - x;
            }),
          ];
        }
      });
    }
  };

  const renderLeaders = () => {
    return leaders.length > 0 ? (
      <table
        style={{
          margin: "auto",
          fontSize: "80%",
          textAlign: "right",
          borderSpacing: "12px 4px",
        }}
      >
        <thead>
          <tr>
            <th></th>
            <th
              style={{ cursor: "pointer", textAlign: "left" }}
              onClick={() => sortTable(columns.wallet)}
            >
              wallet
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.unclaimed)}
            >
              unclaimed
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.hourly)}
            >
              ESB/hour
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.hourly)}
            >
              ESB/day
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.hourly)}
            >
              WAX/day
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.hourly)}
            >
              USD/day
            </th>
          </tr>
        </thead>
        <tbody>
          {leaders
            .filter((leader) => leader.wallet !== "bxjfo.wam")
            .map((leader, i) => {
              return (
                <tr key={leader.wallet}>
                  <td>{i + 1}</td>
                  <td style={{ textAlign: "left" }}>{leader.wallet}</td>
                  <td>{leader.unclaimed.toFixed(4)}</td>
                  <td>{leader.hourly.toFixed(4)}</td>
                  <td>{(leader.hourly * 24).toFixed(4)}</td>
                  <td>
                    {(leader.hourly * 24 * getTokenPrice("ESBWAX")).toFixed(3)}
                  </td>
                  <td>
                    {(
                      leader.hourly *
                      24 *
                      getTokenPrice("ESBWAX") *
                      getTokenPrice("WAXUSD")
                    ).toFixed(2)}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    ) : null;
  };

  return (
    <Page title="Breeders Zone Staking Leaderboard">
      <h2>Staking Leaderboard</h2>
      {tokenPrices ? (
        <TokenPriceTable
          coinPriceArray={[
            ["ESB", getTokenPrice("ESBWAX")],
            ["WAX", getTokenPrice("WAXUSD")],
          ]}
        />
      ) : null}
      <br />
      {loading ? <SnakeLoader /> : renderLeaders()}
      <br />
      <br />
    </Page>
  );
};

export default BreedersLeaderboard;
