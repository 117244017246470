import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Page from "../Page";
import SnakeLoader from "../SnakeLoader";
import WalletInput from "../Utilities/WalletInput";
import {
  NovaComboTableData,
  NovaLeagueTableData,
  NovaPositionTableData,
  NovaRaceIdTableData,
  NovaTimeTableData,
} from "./NovaDisplay";
import { NovaContext } from "./NovaContextProvider";
import axios from "axios";

const COLUMNS = {
  race_id: "race_id",
  position: "position",
  league: "league",
  time: "time",
};

const LEAGUE_ORDER = ["rookie", "intermediate", "veteran", "master"];

const NovaRacer = (props) => {
  const novaContext = useContext(NovaContext);

  const [loading, setLoading] = useState(false);
  const [topRaces, setTopRaces] = useState([]);
  const [streaks, setStreaks] = useState(null);
  const [totals, setTotals] = useState(null);
  const [currentSort, setCurrentSort] = useState(null);
  const history = useHistory();
  const [wallet, setWallet] = useState(props.match.params.wallet || "");

  const handleSetWallet = (w) => {
    history.push("/nova/racer/" + w);
    setWallet(w);
  };

  useEffect(() => {
    if (novaContext.templates) {
      const run = async () => {
        if (wallet) {
          setLoading(true);

          const getRaces = async (pageNum = 0, result = []) => {
            const r = await axios.get(
              "https://nr-api.win-win.software/api/v1/races/?currentAccount=" +
                wallet +
                "&page=" +
                pageNum +
                "&isCurrentOnly=true&size=200"
            );
            if (r.data.data.length === 200) {
              result = result.concat(r.data.data);
              const pn = pageNum + 1;
              return getRaces(pn, result);
            } else {
              result = result.concat(r.data.data);
              return result;
            }
          };

          const result = await getRaces();

          const strk = {
            win: 0,
            pod: 0,
            noWin: 0,
            noPod: 0,
          };
          const curr = {
            win: 0,
            pod: 0,
            noWin: 0,
            noPod: 0,
          };
          for (let i = 0; i < result.length; i++) {
            const race = result[i];
            const pos = race.player.position;
            if (pos === 1) {
              curr.win++;
              curr.pod++;
            } else {
              if (curr.win > strk.win) {
                strk.win = curr.win;
              }
              curr.win = 0;
              if (pos < 4) {
                curr.pod++;
              } else {
                if (curr.pod > strk.pod) {
                  strk.pod = curr.pod;
                }
                curr.pod = 0;
              }
            }
            if (pos > 3) {
              curr.noWin++;
              curr.noPod++;
            } else {
              if (curr.noPod > strk.noPod) {
                strk.noPod = curr.noPod;
              }
              curr.noPod = 0;
              if (pos > 1) {
                curr.noWin++;
              } else {
                if (curr.noWin > strk.noWin) {
                  strk.noWin = curr.noWin;
                }
                curr.noWin = 0;
              }
            }
          }
          if (curr.win > strk.win) strk.win = curr.win;
          if (curr.pod > strk.pod) strk.pod = curr.pod;
          if (curr.noWin > strk.noWin) strk.noWin = curr.noWin;
          if (curr.noPod > strk.noPod) strk.noPod = curr.noPod;
          setStreaks(strk);

          result.sort((a, b) => {
            return a.player.timeMs - b.player.timeMs;
          });

          const wins = result.reduce((acc, curr) => {
            return curr.player.position === 1 ? acc + 1 : acc;
          }, 0);
          const podiums = result.reduce((acc, curr) => {
            return curr.player.position <= 3 ? acc + 1 : acc;
          }, 0);
          setTotals({
            races: result.length,
            wins: wins,
            podiums: podiums,
            winPercentage: wins / result.length,
            podiumPercentage: podiums / result.length,
          });

          const tr = await Promise.all(
            result.map(async (item) => {
              const oldResult = {
                vehicle_asset_id: item.player.vehicleAssetId,
                driver1_asset_id: item.player.driver1AssetId,
                driver2_asset_id: item.player.driver2AssetId,
              };
              const combo = await novaContext.getComboData(oldResult);
              return {
                race_id: item.id,
                time: item.player.timeMs,
                position: item.player.position,
                league: item.player.league,
                ...combo,
              };
            })
          );

          setTopRaces(tr);

          setLoading(false);
        }
      };

      run();
    }
  }, [wallet, novaContext]);

  const sortTable = (col) => {
    if (currentSort === col) {
      setCurrentSort(null);
      setTopRaces((old) => {
        return [...old].reverse();
      });
    } else {
      setCurrentSort(col);
      setTopRaces((old) => {
        if (col === COLUMNS.league) {
          return [
            ...old.sort((a, b) => {
              let aa =
                LEAGUE_ORDER.indexOf(b[col]) - LEAGUE_ORDER.indexOf(a[col]);
              aa = aa > 0 ? 1 : aa < 0 ? -1 : 0;
              return aa;
            }),
          ];
        } else if (col === COLUMNS.race_id) {
          return [...old.sort((a, b) => b[col] - a[col])];
        } else {
          return [...old.sort((a, b) => a[col] - b[col])];
        }
      });
    }
  };

  const renderHeaderColumn = (col, title, left) => {
    return (
      <th
        style={{ cursor: "pointer", textAlign: left ? "left" : "center" }}
        onClick={() => sortTable(col)}
      >
        {title}
      </th>
    );
  };

  const renderRaces = (races) => {
    return (
      <div>
        <table
          style={{
            margin: "auto",
            textAlign: "left",
            borderSpacing: "12px 4px",
            fontSize: "80%",
          }}
        >
          <thead>
            <tr>
              <th></th>
              {renderHeaderColumn(COLUMNS.race_id, "ID", true)}
              {renderHeaderColumn(COLUMNS.league, "LG")}
              {renderHeaderColumn(COLUMNS.position, "P")}
              {renderHeaderColumn(COLUMNS.time, "TIME", true)}
              <th>VEHICLE</th>
              <th>DRIVER 1</th>
              <th>DRIVER 2</th>
            </tr>
          </thead>
          <tbody>
            {races.map((race, i) => {
              return (
                <tr key={"toprace" + i}>
                  <td style={{ textAlign: "right" }}>{i + 1}</td>
                  <NovaRaceIdTableData id={race.race_id} wallet={wallet} />
                  <NovaLeagueTableData league={race.league} />
                  <NovaPositionTableData position={race.position} />
                  <NovaTimeTableData time={race.time} />
                  <NovaComboTableData combo={race} />
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const renderTotals = () => {
    return (
      <table
        style={{
          margin: "auto",
          textAlign: "center",
          borderSpacing: "12px 4px",
          fontSize: "130%",
        }}
      >
        <thead style={{ fontWeight: "bold", fontSize: "120%" }}>
          <tr>
            <td style={{ width: "113px", textDecoration: "underline" }}>
              RACES
            </td>
            <td style={{ width: "113px", textDecoration: "underline" }}>
              WINS
            </td>
            <td style={{ width: "113px", textDecoration: "underline" }}>
              PODIUMS
            </td>
          </tr>
        </thead>
        <tbody style={{ fontWeight: "bold" }}>
          <tr>
            <td>{totals.races}</td>
            <td>{totals.wins}</td>
            <td>{totals.podiums}</td>
          </tr>
          <tr style={{ fontSize: "60%" }}>
            <td></td>
            <td>
              <div style={{ marginBottom: "5px" }}>
                {(totals.winPercentage * 100).toFixed(2)}%
              </div>
              <div
                style={
                  totals.winPercentage - 0.125 > 0
                    ? { color: "green" }
                    : { color: "red" }
                }
              >
                {totals.winPercentage - 0.125 > 0 ? "+" : ""}
                {((totals.winPercentage - 0.125) * 100).toFixed(2)}%
              </div>
            </td>
            <td>
              <div style={{ marginBottom: "5px" }}>
                {(totals.podiumPercentage * 100).toFixed(2)}%
              </div>
              <div
                style={
                  totals.podiumPercentage - 0.375 > 0
                    ? { color: "green" }
                    : { color: "red" }
                }
              >
                {totals.podiumPercentage - 0.375 > 0 ? "+" : ""}
                {((totals.podiumPercentage - 0.375) * 100).toFixed(2)}%
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const renderStreaks = () => {
    return (
      <table
        style={{
          margin: "auto",
          textAlign: "center",
          borderSpacing: "12px 4px",
          fontSize: "80%",
        }}
      >
        <thead style={{ fontWeight: "bold", fontSize: "120%" }}>
          <tr>
            <th colSpan="4" style={{ textDecoration: "underline" }}>
              STREAKS
            </th>
          </tr>
          <tr style={{ fontSize: "80%" }}>
            <th>WIN</th>
            <th>POD</th>
            <th>NO WIN</th>
            <th>NO POD</th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ fontSize: "140%" }}>
            <td>{streaks.win}</td>
            <td>{streaks.pod}</td>
            <td>{streaks.noWin}</td>
            <td>{streaks.noPod}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <Page title="Nova Rally Racer Stats">
      <h2>Racer Stats</h2>
      <WalletInput
        wallet={props.match.params.wallet || ""}
        setWallet={handleSetWallet}
        buttonText="WHO FAST"
        loading={false}
      />
      <br />
      {totals ? renderTotals() : null}
      <br />
      {streaks ? renderStreaks() : null}
      <br />
      {loading ? <SnakeLoader /> : wallet ? renderRaces(topRaces) : null}
      <br />
      <br />
      <br />
      <br />
    </Page>
  );
};

export default NovaRacer;
