/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Page from "../Page";
import SnakeLoader from "../SnakeLoader";
import { rpc } from "../Utilities/RPC";

const RPlanetStaked = (props) => {
  const history = useHistory();
  const [wallet, setWallet] = useState(props.match.params.wallet);
  const [walletInput, setWalletInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [stakedAssets, setStakedAssets] = useState([]);
  const [assetCount, setAssetCount] = useState(0);
  const [totals, setTotals] = useState({ collections: 0, assets: 0 });

  useEffect(() => {
    if (wallet && wallet.trim()) {
      history.push("/rplanet/staked/" + wallet);
      setWalletInput(wallet.trim());
    }
  }, [wallet, history]);

  useEffect(() => {
    const charToInt64 = (char) => {
      const ch = (ch) => {
        return BigInt(ch.charCodeAt());
      };
      let c = ch(char);
      if (ch("a") <= c && c <= ch("z")) {
        return c - ch("a") + 6n;
      }
      if (ch("1") <= c && c <= ch("5")) {
        return c - ch("1") + 1n;
      }
      if (c === ch(".")) {
        return 0n;
      }
    };

    const stringToInt64 = (s) => {
      let answer = 0n;
      for (let i = 0; i < wallet.length; i++) {
        let x =
          (charToInt64(wallet.charAt(i)) & 0x1fn) <<
          (64n - 5n * (BigInt(i) + 1n));
        answer |= x;
      }
      return answer;
    };

    if (wallet && wallet.length > 0) {
      setLoading(true);
      setStakedAssets([]);
      setAssetCount(0);
      setTotals({ collections: 0, assets: 0 });
      let a = stringToInt64(wallet);
      let lower = a << 64n;
      let max32 = BigInt(0xffffffffffffffff) - 1n;
      let upper = lower | max32;

      let result = [];
      let _totals = { collections: 0, assets: 0 };
      let counter = 1;
      const next = async (shiftedWallet, lowerBound, upperBound) => {
        await rpc
          .get_table_rows({
            json: true,
            code: "s.rplanet",
            scope: "atomicassets",
            table: "assets",
            lower_bound: lowerBound,
            upper_bound: upperBound,
            index_position: 2,
            key_type: "i128",
            limit: 10000,
            reverse: false,
            show_payer: false,
          })
          .then(async (r) => {
            const s = r.rows;
            if (s.length > 0) {
              s.forEach((v) => {
                _totals.assets++;

                if (v.collection === "metaforce") {
                  if (v.rarity.includes("common")) v.rarity = "common";
                  if (v.rarity.includes("uncommon")) v.rarity = "uncommon";
                  if (v.rarity.includes("rare")) v.rarity = "rare";
                  if (v.rarity.includes("epic")) v.rarity = "epic";
                  if (v.rarity.includes("legendary")) v.rarity = "legendary";
                  if (v.rarity.includes("mythic")) v.rarity = "mythic";
                  if (v.rarity.includes("collectors")) v.rarity = "collectors";
                } else if (v.collection === "collectwhale") {
                  v.rarity = "key";
                } else {
                  v.rarity = v.rarity ? v.rarity : "unspecified";
                }

                let i = result.findIndex((x) => x.collection === v.collection);
                if (i < 0) {
                  _totals.collections++;
                  result.push({
                    collection: v.collection,
                    rarities: [{ rarity: v.rarity, ids: [v.id] }],
                  });
                } else {
                  let j = result[i].rarities.findIndex(
                    (x) => x.rarity === v.rarity
                  );
                  if (j < 0) {
                    result[i].rarities.push({
                      rarity: v.rarity,
                      ids: [v.id],
                    });
                  } else {
                    result[i].rarities[j].ids.push(v.id);
                  }
                }
              });
              setAssetCount((old) => old + s.length);
              const lastAssetId = s[s.length - 1].id;
              const newLowerBound = (
                (BigInt(shiftedWallet) | BigInt(lastAssetId)) +
                1n
              ).toString();
              console.log(counter++, newLowerBound);
              // await new Promise((r) => setTimeout(r, 50));
              next(shiftedWallet, newLowerBound, upperBound);
            } else {
              setLoading(false);
              setStakedAssets(result);
              setTotals(_totals);
            }
          })
          .catch((e) => {
            console.error(e);
          });
      };
      next(lower.toString(), lower.toString(), upper.toString());
    }
  }, [wallet]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (walletInput.trim()) {
      setWallet(walletInput);
    }
  };

  const handleWalletChange = (e) =>
    setWalletInput(e.target.value.toLowerCase());

  const handleAssetLink = (id) => {
    const url = "https://wax.atomichub.io/explorer/asset/" + id;
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Page title="RPlanet Staked Assets">
      <h2>Staked Assets</h2>
      <br />
      <div>
        {loading ? (
          <div>
            {assetCount}
            <br />
            <SnakeLoader />
          </div>
        ) : (
          <div>
            <form onSubmit={handleSubmit}>
              wallet address
              <br />
              <br />
              <input
                size="12"
                type="text"
                style={{
                  fontFamily: "Cousine",
                  textAlign: "center",
                  textTransform: "lowercase",
                }}
                name="walletInput"
                onChange={handleWalletChange}
                value={walletInput}
              />
              <br />
              <br />
              <input
                type="submit"
                style={{ fontFamily: "Cousine", fontWeight: "bold" }}
                value="SHOW ME STEAK"
              />
            </form>
          </div>
        )}
        <br />
        <br />
        <div>Staked collections: {totals.collections}</div>
        <br />
        <div>Staked assets: {totals.assets}</div>
        <br />
        <br />
        <div style={{ wordWrap: "break-word", textAlign: "left" }}>
          {stakedAssets
            .sort((a, b) => {
              let alen = 0,
                blen = 0;
              a.rarities.forEach((x) => {
                alen += x.ids.length;
              });
              b.rarities.forEach((x) => {
                blen += x.ids.length;
              });
              return blen - alen;
            })
            .map((coll, i) => {
              let collTotal = 0;
              coll.rarities.forEach((x) => {
                collTotal += x.ids.length;
              });
              return (
                <div
                  key={i + "-" + coll.collection}
                  style={{ border: "20px solid transparent" }}
                >
                  <h1>
                    {coll.collection} - {collTotal} assets
                  </h1>
                  {coll.rarities
                    .sort((a, b) => {
                      return b.ids.length - a.ids.length;
                    })
                    .map((rar) => {
                      return (
                        <div key={coll.collection + "-" + rar.rarity}>
                          <h3>
                            {rar.rarity} - {rar.ids.length} assets
                          </h3>
                          <div>
                            {rar.ids.map((id) => {
                              return (
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleAssetLink(id)}
                                  key={
                                    coll.collection +
                                    "-" +
                                    rar.rarity +
                                    "-" +
                                    id
                                  }
                                >
                                  {id + " "}
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  <br />
                  <br />
                  <br />
                </div>
              );
            })}
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
    </Page>
  );
};

export default RPlanetStaked;
