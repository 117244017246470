import "./global.scss";
import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Page from "./Page";
import AccountDashboard from "./Account/Dashboard";
import RPlanet from "./RPlanet/RPlanet";
import RPlanetStaked from "./RPlanet/Staked";
import RPlanetAetherValue from "./RPlanet/AetherValue";
import AetherLeaderboard from "./RPlanet/AetherLeaderboard";
import FTLeaderboard from "./RPlanet/FTLeaderboard";
import TriedRecipes from "./RPlanet/TriedRecipes";
import RPlanetTickets from "./RPlanet/Tickets";
import ElementData from "./RPlanet/ElementData";
import LandLeaderboard from "./RPlanet/LandLeaderboard";
import LandList from "./RPlanet/LandList";
import LandCalculator from "./RPlanet/LandCalculator";
import UnpackStats from "./RPlanet/UnpackStats";
import Winpacks from "./RPlanet/Winpacks";
import NovaRally from "./NovaRally/NovaRally";
import NovaSnakingPower from "./NovaRally/SnakingPower";
import NovaAssetData from "./NovaRally/AssetData";
import NovaCollection from "./NovaRally/NovaCollection";
import SnakingLeaderboard from "./NovaRally/SnakingLeaderboard";
import SnakingValues from "./NovaRally/SnakingValues";
import NovaRacingLeaderboard from "./NovaRally/RacingLeaderboard";
import NovaRacingLeaderboardUpdate from "./NovaRally/RacingLeaderboardUpdate";
import NovaDeathRallyResults from "./NovaRally/DeathRallyResults";
import NovaPvpStats from "./NovaRally/PvpStats";
import NovaTop from "./NovaRally/NovaTopRaces";
import NovaRacer from "./NovaRally/NovaRacer";
import NovaAssetsRPW from "./NovaRally/AssetsRPW";
import NovaRecentRaces from "./NovaRally/RecentRaces";
import NovaRaceQueue from "./NovaRally/RaceQueue";
import NovaTimeTrials from "./NovaRally/TimeTrials";
import NovaUpdateAssetIdsVehicles from "./NovaRally/NovaUpdateAssetIdsVehicles";
import NovaUpdateAssetIdsDrivers from "./NovaRally/NovaUpdateAssetIdsDrivers";
import NovaUpdateTemplates from "./NovaRally/NovaUpdateTemplates";
import { NovaContextProvider } from "./NovaRally/NovaContextProvider";
import PrivateAetherLeaderboardUpdate from "./RPlanet/PrivateAetherLeaderboardUpdate";
import { RPlanetContextProvider } from "./RPlanet/RPlanetContextProvider";
import NftDraftCollection from "./NftDraft/NftDraftCollection";
import GalaSet from "./Galaktika/GalaSet";
import Oliveland from "./Oliveland/Oliveland";
import OliveStaking from "./Oliveland/OliveStaking";
import OliveLeaderboard from "./Oliveland/OliveLeaderboard";
import OliveStakingValues from "./Oliveland/OliveStakingValues";
import MoreLanding from "./More/More";
import ClaimTokens from "./Claim/ClaimTokens";
import { UALProvider, withUAL } from "ual-reactjs-renderer";
import { Anchor } from "ual-anchor";
import { Wax } from "@eosdacio/ual-wax";
import VirtualDreamsStaking from "./VirtualDream/DreamStaking";
import VirtualDreamsLeaderboard from "./VirtualDream/DreamLeaderboard";
import VirtualDream from "./VirtualDream/VirtualDream";
import VirtualDreamsRemoveIds from "./VirtualDream/RemoveIds";
import Breeders from "./Breeders/Breeders";
import BreedersStaking from "./Breeders/Staking";
import BreedersLeaderboard from "./Breeders/Leaderboard";
import KickbackAssetIds from "./Kickback/AssetIds";
import AssetIds from "./Assets/Ids";
import TalesOfCrypto from "./TalesOfCrypto/TalesOfCrypto";
import TalesOfCryptoStaking from "./TalesOfCrypto/Staking";
import TalesOfCryptoLeaderboard from "./TalesOfCrypto/Leaderboard";
import TalesOfCryptoRemoveIds from "./TalesOfCrypto/RemoveIds";
import CenturyTrain from "./CenturyTrain/CenturyTrain";
import TrainBuilder from "./CenturyTrain/TrainBuilder";
import TrainRouteFinder from "./CenturyTrain/RouteFinder";
import Novopangea from "./Novopangea/Novopangea";
import WakeWorkers from "./Novopangea/WakeWorkers";
import ClaimDispatch from "./CenturyTrain/ClaimDispatch";
import OwnerStaking from "./CenturyTrain/ClaimStation";
import NovaShareholders from "./NovaRally/NovaShareholders";

const myChain = {
  chainId: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
  rpcEndpoints: [
    {
      protocol: "https",
      host: "wax.greymass.com",
      port: "443",
    },
  ],
};
const anchor = new Anchor([myChain], { appName: "nf.tools" });
const wax = new Wax([myChain], { appName: "nf.tools" });

const App = () => {
  return (
    <Router>
      <div>
        <br />
        <nav style={{ textAlign: "left", marginLeft: "20px" }}>
          <Link to="/games">Games</Link>
          &nbsp;&nbsp;
          <Link to="/claim">Claim</Link>
        </nav>
        <div></div>
        <UALProvider
          chains={[myChain]}
          authenticators={[anchor, wax]}
          appName={"nf.tools"}
        >
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/account/:wallet?" component={AccountDashboard} />
            <Route path="/nova" component={NovaRallyRoute} />
            <Route path="/trains" component={TrainsRoute} />
            <Route path="/novo" component={NovoRoute} />
            <Route path="/rplanet" component={RPlanetRoute} />
            <Route path="/nftdraft" component={NftDraftRoute} />
            <Route path="/galaktika" component={GalaktikaRoute} />
            <Route path="/olive" component={OliveRoute} />
            <Route path="/dream" component={DreamRoute} />
            <Route path="/totc" component={TalesOfCryptoRoute} />
            <Route path="/more" component={MoreRoute} />
            <Route path="/games" component={MoreRoute} />
            <Route path="/claim" component={ClaimRoute} />
            <Route path="/breeders" component={BreedersRoute} />
            <Route path="/kickback" component={KickbackRoute} />
            <Route path="/assets" component={AssetsRoute} />
          </Switch>
        </UALProvider>
      </div>
    </Router>
  );
};

const ClaimRoute = () => {
  const UALClaimTokens = withUAL(ClaimTokens);
  return (
    <Page title="Claim Tokens">
      <h1>
        <Link to="/claim">Claim Tokens</Link>
      </h1>
      <Switch>
        <Route path="/claim" component={UALClaimTokens} />
      </Switch>
    </Page>
  );
};

const NovaRallyRoute = () => {
  return (
    <Page title="NovaRally">
      <h1>
        <Link to="/nova">Nova Rally</Link>
      </h1>
      <NovaContextProvider>
        <Switch>
          <Route path="/nova/leaderboard" component={SnakingLeaderboard} />
          <Route path="/nova/snaking/:wallet?" component={NovaSnakingPower} />
          <Route path="/nova/values" component={SnakingValues} />
          <Route path="/nova/racing" component={NovaRacingLeaderboard} />
          <Route
            path="/nova/racingUpdate"
            component={NovaRacingLeaderboardUpdate}
          />
          <Route path="/nova/top" component={NovaTop} />
          <Route path="/nova/racer/:wallet?" component={NovaRacer} />
          <Route path="/nova/pvp" component={NovaPvpStats} />
          <Route path="/nova/recent" component={NovaRecentRaces} />
          <Route path="/nova/death/:scope?" component={NovaDeathRallyResults} />
          <Route path="/nova/tt" component={NovaTimeTrials} />
          <Route path="/nova/queue" component={NovaRaceQueue} />
          <Route path="/nova/rpw/:wallet?" component={NovaAssetsRPW} />
          <Route path="/nova/collection/:wallet?" component={NovaCollection} />
          <Route path="/nova/shares" component={NovaShareholders} />
          <Route path="/nova/assets" component={NovaAssetData} />
          <Route
            path="/nova/updateVehicles"
            component={NovaUpdateAssetIdsVehicles}
          />
          <Route
            path="/nova/updateDrivers"
            component={NovaUpdateAssetIdsDrivers}
          />
          <Route path="/nova/updateTemplates" component={NovaUpdateTemplates} />
          <Route path="/nova" component={NovaRally} />
        </Switch>
      </NovaContextProvider>
    </Page>
  );
};

const RPlanetRoute = () => {
  return (
    <Page title="RPlanet🤡">
      <h1>
        <Link to="/rplanet">RPlanet🤡</Link>
      </h1>
      <RPlanetContextProvider>
        <Switch>
          <Route path="/rplanet/tickets" component={RPlanetTickets} />
          <Route path="/rplanet/recipes" component={TriedRecipes} />
          <Route path="/rplanet/elements" component={ElementData} />
          <Route path="/rplanet/mintsUpdate" component={Winpacks} />
          <Route path="/rplanet/land/list" component={LandList} />
          <Route path="/rplanet/land/leaderboard" component={LandLeaderboard} />
          <Route
            path="/rplanet/land/calculator/:wallet?"
            component={LandCalculator}
          />
          <Route path="/rplanet/luck/:wallet?" component={UnpackStats} />
          <Route path="/rplanet/staked/:wallet?" component={RPlanetStaked} />
          <Route
            path="/rplanet/aether/leaderboard"
            component={AetherLeaderboard}
          />
          <Route
            path="/rplanet/ftleaders/:elements?"
            component={FTLeaderboard}
          />
          <Route
            path="/rplanet/aether/leaderboardUpdate"
            component={PrivateAetherLeaderboardUpdate}
          />
          <Route
            path="/rplanet/aether/value/:wallet?"
            component={RPlanetAetherValue}
          />
          <Route path="/rplanet" component={RPlanet} />
        </Switch>
      </RPlanetContextProvider>
    </Page>
  );
};

const TrainsRoute = () => {
  const UALCenturyTrain = withUAL(CenturyTrain);
  const UALClaimDispatch = withUAL(ClaimDispatch);
  const UALOwnerStaking = withUAL(OwnerStaking);
  return (
    <Page title="Train of the Century">
      <h1>
        <Link to="/trains">Train of the Century</Link>
      </h1>
      <Switch>
        <Route path="/trains/build/:wallet?" component={TrainBuilder} />
        <Route path="/trains/routes" component={TrainRouteFinder} />
        <Route path="/trains/claim" component={UALClaimDispatch} />
        <Route path="/trains/owner" component={UALOwnerStaking} />
        <Route path="/trains" component={UALCenturyTrain} />
      </Switch>
    </Page>
  );
};

const NovoRoute = () => {
  const UALWakeWorkers = withUAL(WakeWorkers);
  return (
    <Page title="Novopangea">
      <h1>
        <Link to="/novo">Novopangea</Link>
      </h1>
      <Switch>
        <Route path="/novo/wake" component={UALWakeWorkers} />
        <Route path="/novo" component={Novopangea} />
      </Switch>
    </Page>
  );
};

const NftDraftRoute = () => {
  return (
    <Page title="NFTDraft">
      <h1>
        <Link to="/nftdraft">NFTDraft</Link>
      </h1>
      <Switch>
        <Route
          path="/nftdraft/collection/:wallet?"
          component={NftDraftCollection}
        />
        <Route path="/nftdraft" component={NftDraftCollection} />
      </Switch>
    </Page>
  );
};

const GalaktikaRoute = () => {
  return (
    <Page title="Galaktika">
      <h1>
        <Link to="/galaktika">Galaktika</Link>
      </h1>
      <Switch>
        <Route path="/galaktika/collection/:wallet?" component={GalaSet} />
        <Route path="/galaktika" component={GalaSet} />
      </Switch>
    </Page>
  );
};

const OliveRoute = () => {
  return (
    <Page title="Oliveland">
      <h1>
        <Link to="/olive">Oliveland</Link>
      </h1>
      <Switch>
        <Route path="/olive/staking/:wallet?" component={OliveStaking} />
        <Route path="/olive/leaderboard" component={OliveLeaderboard} />
        <Route path="/olive/values" component={OliveStakingValues} />
        <Route path="/olive" component={Oliveland} />
      </Switch>
    </Page>
  );
};

const DreamRoute = () => {
  return (
    <Page title="Virtual Dreams">
      <h1>
        <Link to="/dream">VirtualDream</Link>
      </h1>
      <Switch>
        <Route
          path="/dream/staking/:wallet?"
          component={VirtualDreamsStaking}
        />
        <Route path="/dream/leaderboard" component={VirtualDreamsLeaderboard} />
        <Route path="/dream/remove" component={VirtualDreamsRemoveIds} />
        <Route path="/dream" component={VirtualDream} />
      </Switch>
    </Page>
  );
};

const TalesOfCryptoRoute = () => {
  return (
    <Page title="Tales of the Crypto">
      <h1>
        <Link to="/totc">Tales of the Crypto</Link>
      </h1>
      <Switch>
        <Route path="/totc/staking/:wallet?" component={TalesOfCryptoStaking} />
        <Route path="/totc/leaderboard" component={TalesOfCryptoLeaderboard} />
        <Route path="/totc/remove" component={TalesOfCryptoRemoveIds} />
        <Route path="/totc" component={TalesOfCrypto} />
      </Switch>
    </Page>
  );
};

const BreedersRoute = () => {
  return (
    <Page title="Breeders Zone">
      <h1>
        <Link to="/breeders">Breeders Zone</Link>
      </h1>
      <Switch>
        <Route path="/breeders/staking/:wallet?" component={BreedersStaking} />
        <Route path="/breeders/leaderboard" component={BreedersLeaderboard} />
        <Route path="/breeders" component={Breeders} />
      </Switch>
    </Page>
  );
};

const KickbackRoute = () => {
  return (
    <Page title="Kickback">
      <h1>
        <Link to="/kickback/ids">Kickback</Link>
      </h1>
      <Switch>
        <Route path="/kickback/ids" component={KickbackAssetIds} />
      </Switch>
    </Page>
  );
};

const AssetsRoute = () => {
  return (
    <Page title="Asset Data">
      <h1>
        <Link to="/assets">Asset Data</Link>
      </h1>
      <Switch>
        <Route path="/assets/:wallet?/:collection?" component={AssetIds} />
      </Switch>
    </Page>
  );
};

const MoreRoute = () => {
  return (
    <Page title="Games and Collections">
      <h1>
        <Link to="/games">Games and Collections</Link>
      </h1>
      <Switch>
        <Route path="/more" component={MoreLanding} />
        <Route path="/games" component={MoreLanding} />
      </Switch>
    </Page>
  );
};

const Home = () => {
  return (
    <Page title="Home">
      <h1>NFTools</h1>
      <br />
      <h2>
        <Link to="/games">Games and Collections</Link>
        <br />
        <br />
        <Link to="/claim">Claim Tokens</Link>
      </h2>
    </Page>
  );
};

export default App;
