import React, { useState, useEffect, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import firebase from "../Firebase";
import Page from "../Page";
import SnakeLoader from "../SnakeLoader";
import { RPLANET_ELEMENTS } from "rplanet";
import { RPlanetContext } from "./RPlanetContextProvider";
import { makeColorGradient } from "../Utilities/Colors";

const RPlanetAetherValue = (props) => {
  const columns = {
    tier: "tier",
    element: "element",
    amount: "amount",
    cost: "cost",
    total: "total",
  };

  const rpContext = useContext(RPlanetContext);

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [wallet, setWallet] = useState(props.match.params.wallet);
  const [walletInput, setWalletInput] = useState("");
  const [aetherPlayerData, setAetherPlayerData] = useState({});
  const [currentSort, setCurrentSort] = useState(null);

  if (process.env.NODE_ENV === "development") {
    firebase.functions().useEmulator("localhost", 5001);
  }

  useEffect(() => {
    if (wallet && wallet.trim()) {
      history.push("/rplanet/aether/value/" + wallet.trim());
      setWalletInput(wallet.trim());
    }
  }, [wallet, history]);

  useEffect(() => {
    if (wallet && wallet.length > 0 && wallet.trim()) {
      setLoading(true);
      history.push("/rplanet/aether/value/" + wallet.trim());
      setWalletInput(wallet.trim());
      setAetherPlayerData([]);
      let getAetherPlayerData = firebase
        .functions()
        .httpsCallable("getAetherPlayerData");
      getAetherPlayerData({ wallet: wallet.trim() }).then((response) => {
        const apd = response.data;
        if (rpContext.aetherPlayers) {
          const index = rpContext.aetherPlayers.findIndex(
            (x) => x.wallet === wallet.trim()
          );
          if (index >= 0) {
            rpContext.aetherPlayers[index] = {
              ...rpContext.aetherPlayers[index],
              ...apd,
            };
          }
        }
        apd.fts = [];
        Object.keys(apd.ft).forEach((el) => {
          apd.fts.push({
            tier: RPLANET_ELEMENTS[el].tier,
            element: el,
            amount: apd.ft[el],
            cost: RPLANET_ELEMENTS[el].cost,
            total: RPLANET_ELEMENTS[el].cost * apd.ft[el],
          });
        });
        setAetherPlayerData(apd);
        sortTable("total");
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet, history, rpContext.aetherPlayers]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (walletInput.trim()) {
      setWallet(walletInput.trim());
    }
  };

  const handleWalletChange = (e) =>
    setWalletInput(e.target.value.toLowerCase());

  const sortTable = (col) => {
    if (currentSort === col) {
      setCurrentSort(null);
      setAetherPlayerData((old) => {
        const fts = old.fts.slice(0).reverse();
        return { ...old, fts: fts };
      });
    } else {
      setCurrentSort(col);
      setAetherPlayerData((old) => {
        if (col === columns.element) {
          old.fts.sort((a, b) => a[col].localeCompare(b[col]));
          return { ...old };
        } else {
          old.fts.sort((a, b) => b[col] - a[col]);
          return { ...old };
        }
      });
    }
  };

  const renderFtTable = () => {
    const bgCols = makeColorGradient(0.9, 0.8, 0.7, 0, 2, 4, 88, 44, 16);
    if (aetherPlayerData.fts && aetherPlayerData.fts.length > 0) {
      return (
        <table
          style={{
            margin: "auto",
            textAlign: "right",
            borderSpacing: "12px 4px",
            minWidth: "360px",
          }}
        >
          <thead>
            <tr>
              <td
                style={{ cursor: "pointer" }}
                onClick={() => sortTable(columns.tier)}
              >
                Tier
              </td>
              <td
                style={{ cursor: "pointer" }}
                onClick={() => sortTable(columns.element)}
              >
                Element
              </td>
              <td
                style={{
                  cursor: "pointer",
                }}
                onClick={() => sortTable(columns.amount)}
              >
                Amount
              </td>
              <td
                style={{ cursor: "pointer" }}
                onClick={() => sortTable(columns.cost)}
              >
                AE Cost
              </td>
              <td
                style={{ cursor: "pointer" }}
                onClick={() => sortTable(columns.total)}
              >
                AE Total
              </td>
            </tr>
          </thead>
          <tbody>
            {aetherPlayerData.fts.map((f) => {
              return f.amount > 0 ? (
                <tr key={f.element}>
                  <td
                    style={{
                      textAlign: "center",
                      borderRadius: "6px",
                      backgroundColor: bgCols[f.tier - 1],
                    }}
                  >
                    {f.tier}
                  </td>
                  <td style={{ textAlign: "left" }}>{f.element}</td>
                  <td style={{ textAlign: "center" }}>
                    {(Math.round(f.amount * 10000) / 10000).toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 4,
                      }
                    )}
                  </td>
                  <td>{f.cost.toLocaleString()}</td>
                  <td>{f.total.toLocaleString()}</td>
                </tr>
              ) : null;
            })}
          </tbody>
        </table>
      );
    } else {
      return null;
    }
  };

  return (
    <Page title="Aether Value Overview">
      <h2>Aether/FT Balance</h2>
      <br />
      <div>
        {loading ? (
          <SnakeLoader />
        ) : (
          <div>
            <form onSubmit={handleSubmit}>
              wallet address
              <br />
              <br />
              <input
                size="12"
                type="text"
                style={{
                  fontFamily: "Cousine",
                  textAlign: "center",
                  textTransform: "lowercase",
                }}
                name="walletInput"
                onChange={handleWalletChange}
                value={walletInput}
              />
              <br />
              <br />
              <input
                type="submit"
                style={{ fontFamily: "Cousine", fontWeight: "bold" }}
                value="SHOW ME THE MONEY"
              />
            </form>
            <br />
            <br />
            <Link to="/rplanet/aether/leaderboard">Aether Leaders</Link>
            &nbsp;&nbsp;
            <Link to="/rplanet/ftleaders">
              <nobr>FT Leaders</nobr>
            </Link>
            &nbsp;&nbsp;
            <Link to="/rplanet/land/leaderboard">
              <nobr>Land Leaders</nobr>
            </Link>
            {aetherPlayerData &&
            Object.keys(aetherPlayerData).length > 0 &&
            aetherPlayerData.total ? (
              <div>
                <br />
                <h1>
                  Total account value:
                  <br />
                  {parseInt(aetherPlayerData.total).toLocaleString() + " AE"}
                </h1>
                {aetherPlayerData.aether ? (
                  <h2>
                    Aether balance:
                    <br />
                    {parseInt(aetherPlayerData.aether).toLocaleString() + " AE"}
                  </h2>
                ) : null}
                {aetherPlayerData.unclaimed ? (
                  <React.Fragment>
                    <h2>
                      Unclaimed aether:
                      <br />
                      {parseInt(aetherPlayerData.unclaimed).toLocaleString() +
                        " AE"}
                    </h2>
                    <div style={{ fontSize: "60%", marginTop: "-12px" }}>
                      *only includes unclaimed aether from RPlanet elements and
                      rigs
                    </div>
                  </React.Fragment>
                ) : null}
                {aetherPlayerData.ftTotal ? (
                  <h2>
                    FT crafting cost:
                    <br />
                    {parseInt(aetherPlayerData.ftTotal).toLocaleString() +
                      " AE"}
                  </h2>
                ) : null}
                <br />
                {renderFtTable()}
              </div>
            ) : null}
            {aetherPlayerData.total === 0 ? (
              <div>
                <br />
                <h3>This wallet contains no aether value.</h3>
              </div>
            ) : null}
          </div>
        )}
      </div>
      <br />
      <br />
      <br />
      <br />
    </Page>
  );
};

export default RPlanetAetherValue;
