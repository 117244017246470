import React from "react";
import { Link } from "react-router-dom";
import Page from "../Page";

const RPlanet = () => {
  const headerStyle = {
    width: "190px",
    margin: "24px auto 10px",
    border: "1px solid white",
    padding: "3px",
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
    fontSize: "120%",
  };
  const linkStyle = {
    display: "block",
    padding: "7px",
    fontSize: "120%",
    width: "220px",
    margin: "0px auto",
  };
  return (
    <Page title="RPlanet🤡">
      <div style={headerStyle}>Aether/FT Data</div>
      <Link style={linkStyle} to="/rplanet/aether/value">
        Aether/FT Balance
      </Link>
      <Link style={linkStyle} to="/rplanet/aether/leaderboard">
        Aether Leaders
      </Link>
      <Link style={linkStyle} to="/rplanet/ftleaders">
        FT Leaders
      </Link>
      <div style={headerStyle}>Land Data</div>
      <Link style={linkStyle} to="/rplanet/land/leaderboard">
        Land Leaders
      </Link>
      <Link style={linkStyle} to="/rplanet/land/list">
        Asset List
      </Link>
      <Link style={linkStyle} to="/rplanet/land/calculator">
        Optimizer
      </Link>
      <div style={headerStyle}>Element Data</div>
      <Link style={linkStyle} to="/rplanet/tickets">
        Raffle Tickets
      </Link>
      <Link style={linkStyle} to="/rplanet/elements">
        Element Data
      </Link>
      <Link style={linkStyle} to="/rplanet/recipes">
        Tried Recipes
      </Link>
      <div style={headerStyle}>Extra Data</div>
      <Link style={linkStyle} to="/rplanet/luck">
        Unpacking Stats
      </Link>
      <Link style={linkStyle} to="/rplanet/staked">
        Staked Assets
      </Link>
      <br />
      <br />
      <br />
      <br />
    </Page>
  );
};

export default RPlanet;
