import React, { useState, useEffect } from "react";
import SnakeLoader from "../SnakeLoader";
import Page from "../Page";
import { rpc } from "../Utilities/RPC";

const RPlanetTickets = () => {
  const [ticketData, setTicketData] = useState([]);
  const [walletCount, setWalletCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const run = async () => {
      setLoading(true);

      const result = [];
      const more = async (lowerBound) => {
        const r = await rpc.get_table_by_scope({
          code: "a.rplanet",
          table: "nftmembers",
          limit: 1000,
          lower_bound: lowerBound,
        });
        if (r.rows.length > 0) {
          result.push(...r.rows);
          const lb = r.rows[r.rows.length - 1].scope + "1";
          return more(lb);
        } else {
          return result;
        }
      };
      const wallets = await more("");
      const final = [];
      const walletsLength = wallets.length;
      let promises = [];
      let promiseWallets = [];
      for (let i = 0; i < walletsLength; i++) {
        setWalletCount((100 * (i + 1)) / walletsLength);
        let p = rpc.get_table_rows({
          json: true,
          code: "a.rplanet",
          table: "nftmembers",
          scope: wallets[i].scope,
        });
        promises.push(p);
        promiseWallets.push(wallets[i].scope);
        if ((i + 1) % 20 === 0 || i === walletsLength - 1) {
          const responses = await Promise.allSettled(promises);
          for (let j = 0; j < responses.length; j++) {
            const w = promiseWallets[j];
            responses[j].value.rows.forEach((row) => {
              final.push({
                wallet: w,
                count: row.tickets,
                element: row.code,
                nfts: row.nfts,
              });
            });
          }
          promises = [];
          promiseWallets = [];
        }
      }
      final.sort((a, b) => {
        const aa = b.count - a.count;
        const bb = b.nfts - a.nfts;
        const xx = aa > 0 ? 1 : aa < 0 ? -1 : 0;
        const yy = bb > 0 ? 1 : bb < 0 ? -1 : 0;
        return xx * 2 + yy;
      });
      const a = [];
      final.forEach((x) => {
        const ii = a.findIndex((xx) => xx.element === x.element);
        if (ii < 0) {
          a.push({
            element: x.element,
            data: [x],
            totalTickets: x.count,
            totalNfts: x.nfts,
          });
        } else {
          a[ii].data.push(x);
          a[ii].totalTickets += x.count;
          a[ii].totalNfts += x.nfts;
        }
      });
      setTicketData(a);
      setLoading(false);
    };
    run();
  }, []);

  const renderTables = () => {
    return ticketData.map((table) => {
      return (
        <React.Fragment key={"table" + table.element}>
          <h3>
            {table.element} ({table.totalTickets} tickets, {table.data.length}{" "}
            accounts, {table.totalNfts} NFTs)
          </h3>
          <table
            style={{
              margin: "auto",
              textAlign: "center",
              borderSpacing: "12px 4px",
              fontSize: "80%",
            }}
          >
            <thead>
              <tr>
                <th></th>
                <th style={{ textAlign: "left" }}>Wallet</th>
                <th>Tickets</th>
                <th>NFTs</th>
              </tr>
            </thead>
            <tbody>
              {table.data.map((user, i) => {
                return (
                  <tr key={table.element + "-" + user.wallet}>
                    <td>{i + 1}</td>
                    <td style={{ textAlign: "left" }}>{user.wallet}</td>
                    <td>{user.count}</td>
                    <td>{user.nfts}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <br />
        </React.Fragment>
      );
    });
  };

  return (
    <Page title="RPlanet Tickets">
      <h2>Unclaimed Raffle Tickets</h2>
      {loading ? <div>{walletCount.toFixed(1)}%</div> : null}
      {loading ? <SnakeLoader /> : renderTables()}
    </Page>
  );
};

export default RPlanetTickets;
