export const RARITY_COLORS = {
  Common: "#666600",
  Uncommon: "green",
  Rare: "royalblue",
  Epic: "violet",
  Legendary: "yellow",
  Mythic: "red",
  common: "#666600",
  uncommon: "green",
  rare: "royalblue",
  epic: "violet",
  legendary: "yellow",
  mythic: "red",
};

export const RARITY_ORDER = [
  "Common",
  "Uncommon",
  "Rare",
  "Epic",
  "Legendary",
  "Mythic",
];

export const COMMODITY_TYPES = {
  AGGR: "aggregate",
  AUTO: "automobile",
  BUILDING: "building_materials",
  CRATE: "crate",
  GAS: "gas",
  GRAIN: "grain",
  GRANULE: "granule",
  LIQUID: "liquid",
  ORE: "ore",
  OVERSIZE: "oversized",
  PALLET: "pallet",
  PERISH: "perishable",
  SECRET: "top_secret",
};

export const RAILCAR_TYPES = {
  ARMORED: "armored",
  AUTO: "auto_rack",
  BOXCAR: "boxcar",
  CENTER: "centerbeam",
  COVERED: "covered_hopper",
  FLAT: "flat_car",
  FRIDGE: "refrigerated",
  OPEN: "open_top_hopper",
  TANK: "tank_car",
};

export const RAILCAR_COMMODITY_TYPES = {
  armored: [COMMODITY_TYPES.SECRET, COMMODITY_TYPES.PALLET],
  auto_rack: [COMMODITY_TYPES.AUTO],
  boxcar: [COMMODITY_TYPES.CRATE, COMMODITY_TYPES.PALLET],
  centerbeam: [COMMODITY_TYPES.BUILDING, COMMODITY_TYPES.PALLET],
  covered_hopper: [COMMODITY_TYPES.GRAIN, COMMODITY_TYPES.GRANULE],
  flat_car: [COMMODITY_TYPES.OVERSIZE, COMMODITY_TYPES.CRATE],
  refrigerated: [COMMODITY_TYPES.PERISH],
  open_top_hopper: [COMMODITY_TYPES.AGGR, COMMODITY_TYPES.ORE],
  tank_car: [COMMODITY_TYPES.GAS, COMMODITY_TYPES.LIQUID],
};

export const PASSENGER_TYPES = {
  D: "Distance",
  L: "Luck",
  T: "Time",
  W: "Weight Hauled",
};

export const REGIONS = [
  "",
  "Centuryville",
  "Pemberton Heights",
  "Trevithick Pines",
  "Pawpaw Plains",
  "James Park",
];

export const REGIONS_SHORT = ["", "CEN", "PMB", "TRV", "PAW", "JAM"];

export const STATION_NAMES = {
  1099577685806: "Gloom Landing",
  1099577685807: "Mountain Gardens",
  1099577685808: "Sleeping Park",
  1099577685809: "Burning Gardens",
  1099577685810: "Dire Park",
  1099577685811: "Sea Landing",
  1099577685812: "Talon Park",
  1099577685813: "Terror Park",
  1099577685814: "First Depot",
  1099577685815: "Lake Depot",
  1099577685850: "Storm Gardens",
  1099577685851: "Raven Gardens",
  1099577685852: "Sorrow Park",
  1099577685853: "Shadow Landing",
  1099577685854: "Pinnacle Park",
  1099577685855: "Oracle Station",
  1099577685856: "Doom Depot",
  1099577685857: "Eclipse Gardens",
  1099577685858: "Eco-Dome Depot",
  1099577685859: "Winter's Landing",
  1099577685912: "Forest Station",
  1099577685913: "Winter's Depot",
  1099577685914: "Victor Park",
  1099577685915: "Hunter Landing",
  1099577685916: "Garden Gardens",
  1099577685917: "Ebon Landing",
  1099577686463: "Azure Depot",
  1099577686464: "Barren Park",
  1099577686465: "Dreamer's Park",
  1099577686466: "Brownkeep Depot",
  1099577686467: "Skeleton Landing",
  1099577686468: "Bone Gardens",
  1099577686469: "Writhing Landing",
  1099577686675: "Spring's Landing",
  1099577686676: "Sandy Depot",
  1099577686677: "Warden Station",
  1099577686678: "Phantom Park",
  1099577687016: "Pinnacle Station",
  1099577687366: "Summer's Station",
  1099577687512: "Valor Landing",
  1099577688059: "Crown Landing",
  1099577688060: "Crystal Station",
  1099577688061: "Second Gardens",
  1099577688062: "Crown Park",
  1099577688063: "Lake Gardens",
  1099577688064: "Timber Depot",
  1099577688065: "Echo Station",
  1099577688066: "Final Landing",
  1099577688067: "Third Landing",
  1099577688068: "Solitude Station",
  1099577688101: "Third Station",
  1099577688102: "Tempest Station",
  1099577688103: "Azure Landing",
  1099577688104: "Forest Landing",
  1099577688105: "Warden Gardens",
  1099577688106: "Brownkeep Park",
  1099577688107: "Final Gardens",
  1099577688108: "Echo Gardens",
  1099577688109: "Honor Park",
  1099577688110: "Eco-Dome Gardens",
  1099577688250: "Pinnacle Landing",
  1099577688251: "Sleeping Gardens",
  1099577688252: "Anvilcross Depot",
  1099577688253: "Sea Station",
  1099577688254: "Forsaken Depot",
  1099577688255: "Skeleton Depot",
  1099577688256: "Death Landing",
  1099577688257: "Sorrow Landing",
  1099577688258: "Crown Station",
  1099577688259: "Canyon Gardens",
  1099577688270: "Eternal Landing",
  1099577688271: "Ocean Gardens",
  1099577688272: "Vortex Depot",
  1099577688273: "Lake Station",
  1099577688274: "Anvilcross Station",
  1099577688275: "Burning Station",
  1099577688276: "Terror Station",
  1099577688277: "Dire Landing",
  1099577688278: "Iron Gardens",
  1099577688279: "Watcher's Gardens",
  1099577688380: "Forsaken Station",
  1099577688381: "Mountain-Foot Park",
  1099577688382: "Triumph Station",
  1099577688383: "Venom Landing",
  1099577688384: "Cinder Station",
  1099577688385: "Fury Landing",
  1099577688386: "Winter's Park",
  1099577688759: "Mountain-Foot Gardens",
  1099577688760: "Victor Landing",
  1099577688761: "Doom Landing",
  1099577688762: "Obsidian Gardens",
  1099577688763: "Raven Landing",
  1099577688764: "Falcon Depot",
  1099577688765: "Watcher's Station",
  1099577688766: "Hunter Park",
  1099577688767: "Hidden Station",
  1099577688768: "Canyon Depot",
  1099577688787: "Terror Depot",
  1099577688788: "Razor Depot",
  1099577688789: "Eclipse Park",
  1099577688790: "Obsidian Landing",
  1099577688791: "Marsh Park",
  1099577688792: "Cinder Park",
  1099577688793: "Twilight Landing",
  1099577688794: "Final Park",
  1099577688795: "Arid Landing",
  1099577688796: "Crown Gardens",
  1099577688856: "Honor Station",
  1099577688857: "Dead Park",
  1099577688858: "Canyon Landing",
  1099577688859: "Forest Depot",
  1099577688860: "Summit Park",
  1099577688861: "Oasis Gardens",
  1099577688862: "Crossroad Gardens",
  1099577688863: "Death Station",
  1099577688864: "Eclipse Station",
  1099577688865: "Marshal Gardens",
  1099577688929: "Dead Landing",
  1099577689181: "Lagoon Station",
  1099577689182: "Raven Depot",
  1099577689183: "Tempest Gardens",
  1099577689184: "Boulder Station",
  1099577689185: "First Station",
  1099577689186: "Twilight Park",
  1099577689187: "Burning Park",
  1099577689188: "Winter's Station",
  1099577689343: "Sleeping Depot",
  1099577689344: "Timber Gardens",
  1099577689345: "Dusk Station",
  1099577689346: "Scarlet Depot",
  1099577689347: "Twin Park",
  1099577689348: "Ocean Landing",
  1099577689349: "Light's Gardens",
  1099577689621: "Brownkeep Gardens",
  1099577689622: "Cinder Gardens",
  1099577689821: "Autumn's Station",
  1099577690300: "Summer's Landing",
  1099577690301: "Crystal Park",
  1099577690302: "Dawn Station",
  1099577690303: "Starfall Station",
  1099577690304: "Summit Station",
  1099577690305: "Mountain Station",
  1099577690306: "Seabreeze Landing",
  1099577690307: "Vengeance Station",
  1099577690308: "Spring's Depot",
  1099577690309: "Forsaken Gardens",
  1099577690324: "Eco-Dome Landing",
  1099577690325: "Triumph Depot",
  1099577690326: "Boulder Depot",
  1099577690327: "Dusk Park",
  1099577690328: "Nightmare Landing",
  1099577690329: "Garden Station",
  1099577690330: "Mountain Landing",
  1099577690331: "Night Landing",
  1099577690332: "Dusty Park",
  1099577690333: "Night Park",
  1099577690364: "Dead Gardens",
  1099577690365: "Seabreeze Depot",
  1099577690366: "Starfall Landing",
  1099577690367: "Fury Depot",
  1099577690368: "Seabreeze Station",
  1099577690369: "Wild Landing",
  1099577690370: "Timber Station",
  1099577690371: "Sabremill Landing",
  1099577690372: "Autumn's Park",
  1099577690373: "Whitekeep Landing",
  1099577690412: "Sabremill Depot",
  1099577690413: "First Gardens",
  1099577690414: "Thunderstorm Depot",
  1099577690415: "Skeleton Gardens",
  1099577690416: "Oasis Station",
  1099577690417: "Sorrow Station",
  1099577690418: "Iron Station",
  1099577690419: "Oasis Park",
  1099577690420: "Shadow Park",
  1099577690421: "Mountain-Foot Depot",
  1099577690453: "Dreamer's Station",
  1099577690454: "Desolation Depot",
  1099577690455: "Bone Landing",
  1099577690456: "Barren Depot",
  1099577690457: "Vortex Landing",
  1099577690458: "Shadow Station",
  1099577690459: "Bone Park",
  1099577690460: "Third Gardens",
  1099577690461: "Obsidian Station",
  1099577690462: "Pinnacle Gardens",
  1099577690481: "Sabremill Station",
  1099577690482: "Third Depot",
  1099577690483: "Bone Station",
  1099577690484: "Phantom Gardens",
  1099577690485: "Summer's Gardens",
  1099577690486: "Spring's Park",
  1099577690487: "Twilight Station",
  1099577690488: "Azure Gardens",
  1099577690489: "Boulder Park",
  1099577690490: "Terror Gardens",
  1099577690520: "Talon Landing",
  1099577690521: "Dark Station",
  1099577690522: "Whitekeep Park",
  1099577690523: "Third Park",
  1099577690524: "Starlight Park",
  1099577690646: "Vortex Gardens",
  1099577690647: "Oracle Depot",
  1099577690648: "Second Landing",
  1099577690649: "Whitekeep Gardens",
  1099577690650: "Lagoon Gardens",
  1099577690651: "Wilding Gardens",
  1099577690652: "Skeleton Park",
  1099577690653: "Death Depot",
  1099577690654: "Twin Landing",
  1099577690655: "Night Station",
  1099577690668: "Solitude Landing",
  1099577690669: "Venom Park",
  1099577690670: "Falcon Gardens",
  1099577690671: "Gloom Station",
  1099577690672: "Fury Station",
  1099577690673: "Flamelight Station",
  1099577690674: "Oracle Park",
  1099577690675: "Eco-Dome Station",
  1099577690676: "Marsh Depot",
  1099577690677: "Crossroad Park",
  1099577690693: "River Station",
  1099577690694: "Twin  Depot",
  1099577690695: "Talon Station",
  1099577690696: "Blood Gardens",
  1099577690901: "Seabreeze Gardens",
  1099577690902: "Razor Station",
  1099577690903: "Valley Station",
  1099577690904: "Flamelight Gardens",
  1099577690905: "Desolation Park",
  1099577690906: "Marshal Landing",
  1099577690907: "Final Depot",
  1099577691125: "Victor Depot",
  1099577691126: "Sandy Gardens",
  1099577691127: "Iron Depot",
  1099577691128: "Scarlet Station",
  1099577691129: "Lagoon Depot",
  1099577691340: "Hunter Depot",
  1099577691341: "Dawn Park",
  1099577691487: "Valley Gardens",
  1099577691795: "Starfall Gardens",
  1099577691796: "Garden Depot",
  1099577691797: "Iron Park",
  1099577691798: "Crystal Landing",
  1099577691799: "Death Park",
  1099577691800: "Dusty Station",
  1099577691801: "Thunderstorm Station",
  1099577691802: "Doom Gardens",
  1099577691803: "Thunder Station",
  1099577691804: "Triumph Landing",
  1099577691819: "Storm Depot",
  1099577691820: "Eclipse Landing",
  1099577691821: "Nightmare Park",
  1099577691822: "Barren Landing",
  1099577691823: "Wildling Station",
  1099577691824: "Bone Depot",
  1099577691825: "Boulder Landing",
  1099577691826: "Mountain Depot",
  1099577691827: "Night Gardens",
  1099577691828: "Eternal Park",
  1099577691843: "Writhing Depot",
  1099577691844: "Burning Landing",
  1099577691845: "Hunter Station",
  1099577691846: "Mountain Park",
  1099577691847: "Sabermill Gardens",
  1099577691848: "Vortex Station",
  1099577691849: "Valor Park",
  1099577691850: "Dusk Gardens",
  1099577691851: "Gloom Depot",
  1099577691852: "Crown Depot",
  1099577691866: "Sea Park",
  1099577691867: "Venom Depot",
  1099577691868: "Timber Park",
  1099577691869: "Sandy Park",
  1099577691870: "Dusty Landing",
  1099577691871: "Desolation Gardens",
  1099577691872: "Echo Landing",
  1099577691873: "Forest Park",
  1099577691874: "Raven Station",
  1099577691875: "Blood Landing",
  1099577691889: "Valor Gardens",
  1099577691890: "Cinder Depot",
  1099577691891: "Seabreeze Park",
  1099577691892: "Dreamer's Landing",
  1099577691893: "Writhing Gardens",
  1099577691894: "Honor Depot",
  1099577691895: "Mountain-Foot Station",
  1099577691896: "Dire Gardens",
  1099577691897: "Fury Gardens",
  1099577691898: "Anvilcross Park",
  1099577691906: "Dire Depot",
  1099577691907: "Blood Park",
  1099577691908: "Lagoon Park",
  1099577691909: "Vendetta Park",
  1099577691910: "Wildling Depot",
  1099577691911: "Venom Station",
  1099577691912: "Razor Gardens",
  1099577691913: "Ember Depot",
  1099577691914: "Solitude Depot",
  1099577691915: "Oasis  Depot",
  1099577691936: "Starlight Gardens",
  1099577691937: "Light's Depot",
  1099577691938: "Phantom Landing",
  1099577691939: "River Landing",
  1099577691940: "Forward Depot",
  1099577691941: "Thunderstorm Park",
  1099577691942: "Eco-Dome Park",
  1099577691943: "Iron Landing",
  1099577691944: "Scarlet Landing",
  1099577691945: "Dark Gardens",
  1099577691958: "Dark Landing",
  1099577691959: "Ebon Park",
  1099577692068: "Victor Gardens",
  1099577692069: "Final Station",
  1099577692070: "Thunderstorm Gardens",
  1099577692071: "Ocean Station",
  1099577692072: "Anvilcross Landing",
  1099577692073: "Echo Depot",
  1099577692074: "Wildling Park",
  1099577692075: "Venom Gardens",
  1099577692076: "Arid Depot",
  1099577692077: "Arid Station",
  1099577692090: "Oasis Landing",
  1099577692091: "Timber Landing",
  1099577692092: "Twin Station",
  1099577692093: "Sandy Landing",
  1099577692094: "Second Depot",
  1099577692095: "Wildling Landing",
  1099577692096: "Thunder Gardens",
  1099577692097: "Valor Depot",
  1099577692098: "Vengeance Gardens",
  1099577692099: "Desolation Landing",
  1099577692122: "Honor Gardens",
  1099577692178: "Anvilcross Gardens",
  1099577692179: "Triumph Park",
  1099577692180: "Storm Station",
  1099577692181: "Vengeance Park",
  1099577692182: "Second Park",
  1099577692183: "Second Station",
  1099577692184: "Tempest Landing",
  1099577692185: "Shadow Gardens",
  1099577692186: "Blood Station",
  1099577692187: "Flamelight Depot",
  1099577692195: "Storm Landing",
  1099577692196: "Fury Park",
  1099577692197: "Dusty Gardens",
  1099577692267: "Eternal Depot",
  1099577692268: "Flamelight Park",
  1099577692269: "Twilight Gardens",
  1099577692270: "Vengeance Landing",
  1099577692271: "Crystal Depot",
  1099577692272: "Forward Landing",
  1099577692273: "Starlight Landing",
  1099577692345: "Summit Depot",
  1099577692346: "Falcon Park",
  1099577692431: "Victor Station",
  1099577692595: "Brownkeep Landing",
  1099577692596: "Lagoon Landing",
  1099577692597: "Pinnacle Depot",
  1099577692598: "Winter's Gardens",
  1099577692599: "First Park",
  1099577692600: "Falcon Landing",
  1099577692601: "Sleeping Station",
  1099577692602: "Ember Gardens",
  1099577692603: "Shadow Depot",
  1099577692604: "Sandy Station",
  1099577692628: "Writhing Park",
  1099577692629: "Valley Depot",
  1099577692630: "Marsh Landing",
  1099577692631: "Solitude Park",
  1099577692632: "Hidden Landing",
  1099577692633: "River Park",
  1099577692634: "Summer's Park",
  1099577692635: "Crossroad Station",
  1099577692636: "Solitude Gardens",
  1099577692637: "Sea Gardens",
  1099577692655: "Marshal Station",
  1099577692656: "Dusk Landing",
  1099577692657: "Nightmare Depot",
  1099577692658: "Sorrow Gardens",
  1099577692659: "Gloom Park",
  1099577692744: "Ember Station",
  1099577692745: "Sea Depot",
  1099577692746: "Gloom Gardens",
  1099577692747: "Wild Gardens",
  1099577692748: "Oracle Gardens",
  1099577692749: "Sleeping Landing",
  1099577692750: "Twin Gardens",
  1099577692751: "Raven Park",
  1099577692752: "Summit Landing",
  1099577692753: "Eternal Gardens",
  1099577692768: "Valor Station",
  1099577692769: "Watcher's Park",
  1099577692909: "Autumn's Landing",
  1099577692910: "Echo Park",
  1099577692911: "Watcher's Landing",
  1099577693235: "Wild Depot",
  1099577693236: "Obsidian Park",
  1099577693396: "Doom Park",
  1099577693475: "Vendetta Landing",
};

export const COMMODITIES = [
  {
    name: "Wood Chips",
    template_id: "287916",
    commodity_multiplier: "400",
    rarity: "common",
    type: "aggregate",
    rarity_multiplier: "20",
    volume: "50",
    weight: "22",
  },
  {
    name: "Sedan",
    template_id: "287921",
    commodity_multiplier: "120",
    rarity: "common",
    type: "automobile",
    rarity_multiplier: "20",
    volume: "10",
    weight: "12",
  },
  {
    name: "Text Books",
    template_id: "287911",
    commodity_multiplier: "121",
    rarity: "common",
    type: "crate",
    rarity_multiplier: "20",
    volume: "10",
    weight: "20",
  },
  {
    name: "Rice",
    template_id: "287917",
    commodity_multiplier: "222",
    rarity: "common",
    type: "grain",
    rarity_multiplier: "20",
    volume: "25",
    weight: "20",
  },
  {
    name: "Petroleum",
    template_id: "287913",
    commodity_multiplier: "197",
    rarity: "common",
    type: "liquid",
    rarity_multiplier: "20",
    volume: "25",
    weight: "12",
  },
  {
    name: "Nutrition Bars",
    template_id: "287908",
    commodity_multiplier: "91",
    rarity: "common",
    type: "pallet",
    rarity_multiplier: "20",
    volume: "10",
    weight: "10",
  },
  {
    name: "Milk",
    template_id: "287919",
    commodity_multiplier: "110",
    rarity: "common",
    type: "perishable",
    rarity_multiplier: "20",
    volume: "10",
    weight: "12",
  },
  {
    name: "Gravel",
    template_id: "288093",
    commodity_multiplier: "240",
    rarity: "uncommon",
    type: "aggregate",
    rarity_multiplier: "45",
    volume: "50",
    weight: "28",
  },
  {
    name: "Minivan",
    template_id: "288097",
    commodity_multiplier: "150",
    rarity: "uncommon",
    type: "automobile",
    rarity_multiplier: "45",
    volume: "25",
    weight: "18",
  },
  {
    name: "Vitamins",
    template_id: "288091",
    commodity_multiplier: "60",
    rarity: "uncommon",
    type: "crate",
    rarity_multiplier: "45",
    volume: "10",
    weight: "10",
  },
  {
    name: "Propane",
    template_id: "288092",
    commodity_multiplier: "100",
    rarity: "uncommon",
    type: "gas",
    rarity_multiplier: "45",
    volume: "25",
    weight: "8",
  },
  {
    name: "Sand",
    template_id: "288094",
    commodity_multiplier: "120",
    rarity: "uncommon",
    type: "granule",
    rarity_multiplier: "45",
    volume: "25",
    weight: "14",
  },
  {
    name: "Canned Soup",
    template_id: "288090",
    commodity_multiplier: "50",
    rarity: "uncommon",
    type: "pallet",
    rarity_multiplier: "45",
    volume: "10",
    weight: "15",
  },
  {
    name: "Apples",
    template_id: "288096",
    commodity_multiplier: "68",
    rarity: "uncommon",
    type: "perishable",
    rarity_multiplier: "45",
    volume: "10",
    weight: "10",
  },
  {
    name: "River Rock",
    template_id: "288103",
    commodity_multiplier: "200",
    rarity: "rare",
    type: "aggregate",
    rarity_multiplier: "65",
    volume: "50",
    weight: "26",
  },
  {
    name: "Coupe",
    template_id: "288112",
    commodity_multiplier: "60",
    rarity: "rare",
    type: "automobile",
    rarity_multiplier: "65",
    volume: "10",
    weight: "16",
  },
  {
    name: "Fine Cigars",
    template_id: "288100",
    commodity_multiplier: "50",
    rarity: "rare",
    type: "crate",
    rarity_multiplier: "65",
    volume: "10",
    weight: "8",
  },
  {
    name: "Buckwheat",
    template_id: "288107",
    commodity_multiplier: "100",
    rarity: "rare",
    type: "grain",
    rarity_multiplier: "65",
    volume: "25",
    weight: "16",
  },
  {
    name: "Water",
    template_id: "288101",
    commodity_multiplier: "90",
    rarity: "rare",
    type: "liquid",
    rarity_multiplier: "65",
    volume: "25",
    weight: "15",
  },
  {
    name: "Tractor",
    template_id: "288110",
    commodity_multiplier: "215",
    rarity: "rare",
    type: "oversized",
    rarity_multiplier: "65",
    volume: "50",
    weight: "28",
  },
  {
    name: "Puppy Food",
    template_id: "288099",
    commodity_multiplier: "50",
    rarity: "rare",
    type: "pallet",
    rarity_multiplier: "65",
    volume: "10",
    weight: "15",
  },
  {
    name: "Ice Cream",
    template_id: "288108",
    commodity_multiplier: "64",
    rarity: "rare",
    type: "perishable",
    rarity_multiplier: "65",
    volume: "10",
    weight: "14",
  },
  {
    name: "SUV",
    template_id: "288129",
    commodity_multiplier: "135",
    rarity: "epic",
    type: "automobile",
    rarity_multiplier: "80",
    volume: "25",
    weight: "22",
  },
  {
    name: "Plywood",
    template_id: "288127",
    commodity_multiplier: "200",
    rarity: "epic",
    type: "building_materials",
    rarity_multiplier: "80",
    volume: "50",
    weight: "25",
  },
  {
    name: "Laptop Computers",
    template_id: "288116",
    commodity_multiplier: "50",
    rarity: "epic",
    type: "crate",
    rarity_multiplier: "80",
    volume: "10",
    weight: "12",
  },
  {
    name: "Nitrous Oxide",
    template_id: "288117",
    commodity_multiplier: "90",
    rarity: "epic",
    type: "gas",
    rarity_multiplier: "80",
    volume: "25",
    weight: "18",
  },
  {
    name: "Sugar",
    template_id: "288121",
    commodity_multiplier: "122",
    rarity: "epic",
    type: "granule",
    rarity_multiplier: "80",
    volume: "25",
    weight: "22",
  },
  {
    name: "Halite",
    template_id: "288119",
    commodity_multiplier: "198",
    rarity: "epic",
    type: "ore",
    rarity_multiplier: "80",
    volume: "50",
    weight: "30",
  },
  {
    name: "Bulldozer",
    template_id: "288125",
    commodity_multiplier: "210",
    rarity: "epic",
    type: "oversized",
    rarity_multiplier: "80",
    volume: "60",
    weight: "38",
  },
  {
    name: "Sweet Jeans",
    template_id: "288113",
    commodity_multiplier: "50",
    rarity: "epic",
    type: "pallet",
    rarity_multiplier: "80",
    volume: "10",
    weight: "12",
  },
  {
    name: "Fish",
    template_id: "288123",
    commodity_multiplier: "69",
    rarity: "epic",
    type: "perishable",
    rarity_multiplier: "80",
    volume: "10",
    weight: "12",
  },
  {
    name: "Truck",
    template_id: "288215",
    commodity_multiplier: "47",
    rarity: "legendary",
    type: "automobile",
    rarity_multiplier: "315",
    volume: "25",
    weight: "28",
  },
  {
    name: "Lumber",
    template_id: "288214",
    commodity_multiplier: "60",
    rarity: "legendary",
    type: "building_materials",
    rarity_multiplier: "315",
    volume: "60",
    weight: "32",
  },
  {
    name: "Whiskey",
    template_id: "288184",
    commodity_multiplier: "20",
    rarity: "legendary",
    type: "crate",
    rarity_multiplier: "315",
    volume: "10",
    weight: "12",
  },
  {
    name: "Soda Ash",
    template_id: "288205",
    commodity_multiplier: "50",
    rarity: "legendary",
    type: "granule",
    rarity_multiplier: "315",
    volume: "25",
    weight: "24",
  },
  {
    name: "Jet Fuel",
    template_id: "288187",
    commodity_multiplier: "32",
    rarity: "legendary",
    type: "liquid",
    rarity_multiplier: "315",
    volume: "25",
    weight: "10",
  },
  {
    name: "Magnetite",
    template_id: "288188",
    commodity_multiplier: "60",
    rarity: "legendary",
    type: "ore",
    rarity_multiplier: "315",
    volume: "50",
    weight: "38",
  },
  {
    name: "Tank",
    template_id: "288212",
    commodity_multiplier: "70",
    rarity: "legendary",
    type: "oversized",
    rarity_multiplier: "315",
    volume: "75",
    weight: "52",
  },
  {
    name: "Assorted Toys",
    template_id: "288183",
    commodity_multiplier: "20",
    rarity: "legendary",
    type: "pallet",
    rarity_multiplier: "315",
    volume: "10",
    weight: "10",
  },
  {
    name: "Insulin",
    template_id: "288211",
    commodity_multiplier: "28",
    rarity: "legendary",
    type: "perishable",
    rarity_multiplier: "315",
    volume: "10",
    weight: "8",
  },
  {
    name: "Item 632",
    template_id: "288218",
    commodity_multiplier: "20",
    rarity: "legendary",
    type: "top_secret",
    rarity_multiplier: "315",
    volume: "5",
    weight: "4",
  },
  {
    name: "Item 203",
    template_id: "288220",
    commodity_multiplier: "18",
    rarity: "legendary",
    type: "top_secret",
    rarity_multiplier: "315",
    volume: "5",
    weight: "2",
  },
  {
    name: "Item 147",
    template_id: "288221",
    commodity_multiplier: "20",
    rarity: "legendary",
    type: "top_secret",
    rarity_multiplier: "315",
    volume: "5",
    weight: "4",
  },
  {
    name: "Sports Car",
    template_id: "288260",
    commodity_multiplier: "24",
    rarity: "mythic",
    type: "automobile",
    rarity_multiplier: "625",
    volume: "10",
    weight: "10",
  },
  {
    name: "Steel Beams",
    template_id: "288258",
    commodity_multiplier: "45",
    rarity: "mythic",
    type: "building_materials",
    rarity_multiplier: "625",
    volume: "75",
    weight: "40",
  },
  {
    name: "Antique Clocks",
    template_id: "288247",
    commodity_multiplier: "18",
    rarity: "mythic",
    type: "crate",
    rarity_multiplier: "625",
    volume: "10",
    weight: "8",
  },
  {
    name: "Xenon",
    template_id: "288248",
    commodity_multiplier: "25",
    rarity: "mythic",
    type: "gas",
    rarity_multiplier: "625",
    volume: "25",
    weight: "6",
  },
  {
    name: "Quinoa",
    template_id: "288255",
    commodity_multiplier: "32",
    rarity: "mythic",
    type: "grain",
    rarity_multiplier: "625",
    volume: "25",
    weight: "18",
  },
  {
    name: "Stibnite",
    template_id: "288249",
    commodity_multiplier: "40",
    rarity: "mythic",
    type: "ore",
    rarity_multiplier: "625",
    volume: "50",
    weight: "36",
  },
  {
    name: "Miracle Cream",
    template_id: "288246",
    commodity_multiplier: "18",
    rarity: "mythic",
    type: "pallet",
    rarity_multiplier: "625",
    volume: "10",
    weight: "8",
  },
  {
    name: "Wagyu Beef",
    template_id: "288256",
    commodity_multiplier: "21",
    rarity: "mythic",
    type: "perishable",
    rarity_multiplier: "625",
    volume: "10",
    weight: "8",
  },
  {
    name: "Photo 115",
    template_id: "288261",
    commodity_multiplier: "20",
    rarity: "mythic",
    type: "top_secret",
    rarity_multiplier: "625",
    volume: "5",
    weight: "2",
  },
  {
    name: "Photo 1077",
    template_id: "288263",
    commodity_multiplier: "20",
    rarity: "mythic",
    type: "top_secret",
    rarity_multiplier: "625",
    volume: "5",
    weight: "2",
  },
  {
    name: "Entity 9",
    template_id: "288265",
    commodity_multiplier: "30",
    rarity: "mythic",
    type: "top_secret",
    rarity_multiplier: "625",
    volume: "55",
    weight: "30",
  },
];

export const ColorRarity = (name, rarity) => {
  return (
    <span
      style={{
        color: RARITY_COLORS[rarity],
      }}
    >
      {name}
    </span>
  );
};
