import { rpc } from "../Utilities/RPC";

const SNAKING_RATES = {
  rookie: {
    Sketch: 100,
    Classic: 35,
    Rare: 12.5,
    Uncommon: 3.5,
    Common: 1,
    Wins: 0,
    Podium: 0,
  },
  intermediate: {
    Sketch: 100,
    Classic: 35,
    Rare: 12.5,
    Uncommon: 3.5,
    Common: 1,
    Wins: 0,
    Podium: 0,
  },
  veteran: {
    Sketch: 100,
    Classic: 35,
    Rare: 12.5,
    Uncommon: 3.5,
    Common: 1,
    Wins: 0,
    Podium: 0,
  },
  master: {
    Sketch: 100,
    Classic: 35,
    Rare: 12.5,
    Uncommon: 3.5,
    Common: 1,
    Wins: 0,
    Podium: 0,
  },
  olivelandnft: {
    Immortal: 85.07462687,
    Mythical: 40.60150376,
    Legendary: 25.5,
    "Ultra Rare": 14.41441441,
    Rare: 9.635974304,
    Organic: 6.296851574,
    Ecological: 4.498269896,
    Uncommon: 2.077322562,
    Common: 1.302803,
    Abundant: 1,
    Wins: 0,
    Podium: 0,
  },
  promorplanet: {
    Hero: 5,
    Villain: 5,
    Gold: 4,
    Silver: 3,
    Bronze: 2,
    Wins: 0,
    Podium: 0,
  },
  music: {
    Rare: 9,
    Common: 1,
  },
  beasts: {
    Common: 1,
    Uncommon: 3,
    Rare: 5,
    Epic: 7,
    Legendary: 10,
  },
  promo: {
    Common: 1,
    Uncommon: 3,
    Rare: 5,
    Epic: 7,
    Legendary: 10,
  },
  springs: {
    Common: 1,
    Uncommon: 3,
    Rare: 5,
    Epic: 7,
    Legendary: 10,
  },
};

const calculateTokenUnit = (poolName, rarities, hourlyMax) => {
  let multCardsTotal = 0;
  rarities.forEach((x) => {
    if (SNAKING_RATES[poolName] && SNAKING_RATES[poolName][x.key]) {
      multCardsTotal += SNAKING_RATES[poolName][x.key] * x.value;
    }
  });
  return hourlyMax / multCardsTotal;
};

const calculateNovaRarityValues = async (contract) => {
  const c = contract ?? "novarallysnk";
  const r = await rpc.get_table_rows({
    json: true,
    code: c,
    scope: c,
    table: "staking",
    limit: 100,
  });

  const poolTable = r.rows;

  const result = {};
  poolTable.forEach((pool) => {
    result[pool.pool] = {};
    const tokenUnit = calculateTokenUnit(
      pool.pool,
      pool.rarities,
      pool.hourly_max
    );
    if (SNAKING_RATES[pool.pool]) {
      Object.entries(SNAKING_RATES[pool.pool]).forEach(
        ([rarity, multiplier]) => {
          result[pool.pool][rarity] = multiplier * tokenUnit;
          if (c === "novarallysnk") {
            result[pool.pool].PoolSize = pool.hourly_max;
          }
        }
      );
    }
  });
  return result;
};

const calculateHourlyRates = (userRow, poolTable) => {
  poolTable.forEach((row) => {
    row.tokenUnit = calculateTokenUnit(row.pool, row.rarities, row.hourly_max);
  });

  if (userRow) {
    userRow.data.forEach((userPool) => {
      const poolTableIndex = poolTable.findIndex(
        (x) => x.pool === userPool.pool
      );
      let total = 0;
      userPool.rarities.forEach((rarity) => {
        let mult = 0;
        if (
          SNAKING_RATES[userPool.pool] &&
          SNAKING_RATES[userPool.pool][rarity.key]
        ) {
          mult = SNAKING_RATES[userPool.pool][rarity.key];
        }
        const unit = poolTable[poolTableIndex].tokenUnit;
        total += rarity.value * mult * unit;
      });
      userPool.hourlyTotal = total;
    });
  }

  return userRow;
};

const getDreamPools = async () => {
  let pools = [
    {
      pool: "virtualdream",
      bonuses: "1.00000000000000000",
      levels: [
        { key: "one", value: "1.00000000000000000" },
        { key: "two", value: "2.00000000000000000" },
        { key: "three", value: "3.00000000000000000" },
        { key: "four", value: "5.00000000000000000" },
        { key: "five", value: "6.00000000000000000" },
        { key: "six", value: "10.00000000000000000" },
        { key: "seven", value: "25.00000000000000000" },
        { key: "eight", value: "50.00000000000000000" },
        { key: "nine", value: "100.00000000000000000" },
        { key: "ten", value: "200.00000000000000000" },
        { key: "eleven", value: "500.00000000000000000" },
        { key: "twelve", value: "1000.00000000000000000" },
      ],
    },
  ];
  try {
    const r1 = await rpc.get_table_rows({
      code: "dreamstaking",
      scope: "dreamstaking",
      table: "collections",
      json: true,
      limit: 1000,
    });
    if (r1.rows) {
      pools = r1.rows;
    }
  } catch (e) {
    console.log(e);
  }
  return pools;
};

const getTocPools = async () => {
  let pools = [
    {
      pool: "talesofcrypt",
      bonuses: "1.00000000000000000",
      levels: [
        { key: "levela", value: "5.00000000000000000" },
        { key: "levelb", value: "10.00000000000000000" },
        { key: "levelc", value: "15.00000000000000000" },
        { key: "leveld", value: "20.00000000000000000" },
        { key: "levele", value: "25.00000000000000000" },
        { key: "levelf", value: "50.00000000000000000" },
        { key: "levelg", value: "75.00000000000000000" },
        { key: "levelh", value: "100.00000000000000000" },
        { key: "leveli", value: "150.00000000000000000" },
        { key: "levelj", value: "200.00000000000000000" },
        { key: "levelk", value: "250.00000000000000000" },
        { key: "levell", value: "300.00000000000000000" },
        { key: "levelm", value: "500.00000000000000000" },
        { key: "leveln", value: "1000.00000000000000000" },
        { key: "levelo", value: "2000.00000000000000000" },
        { key: "levelp", value: "2500.00000000000000000" },
        { key: "levelq", value: "3000.00000000000000000" },
        { key: "levelr", value: "5000.00000000000000000" },
        { key: "levels", value: "7500.00000000000000000" },
        { key: "levelt", value: "8000.00000000000000000" },
        { key: "levelu", value: "9000.00000000000000000" },
        { key: "levelv", value: "10000.00000000000000000" },
        { key: "levelw", value: "12500.00000000000000000" },
        { key: "levelx", value: "15000.00000000000000000" },
      ],
    },
  ];
  try {
    const r1 = await rpc.get_table_rows({
      code: "shroomstaker",
      scope: "shroomstaker",
      table: "collections",
      json: true,
      limit: 1000,
    });
    if (r1.rows) {
      pools = r1.rows;
    }
  } catch (e) {
    console.log(e);
  }
  return pools;
};

const calculateDailyDream = (userRow, poolTable) => {
  if (userRow && poolTable) {
    let dailyRate = 0;
    userRow.data.forEach((userPool) => {
      userPool.inventory.forEach((userKV) => {
        const pt = poolTable.find(
          (stakePool) => stakePool.pool === userPool.pool
        );
        const obj = pt.levels.find((poolValue) => poolValue.key === userKV.key);
        if (obj) {
          const currentRate = obj.value * pt.bonuses;
          const currentTotal = parseFloat(currentRate) * parseInt(userKV.value);
          dailyRate += currentTotal;
        }
      });
    });
    return dailyRate;
  }
  return 0;
};

const calculateUnclaimedDream = (userRow, poolTable) => {
  if (userRow && poolTable) {
    const uc = parseFloat(userRow.unclaimed) ?? 0;
    const tslc = Date.now() / 1000 - userRow.last_claim;
    const hours = parseInt(tslc / 3600);
    const dailyRate = calculateDailyDream(userRow, poolTable);
    return uc + (hours * dailyRate) / 24;
  }
  return 0;
};

const calculateUnclaimedToc = (userRow, poolTable) => {
  if (userRow && poolTable) {
    const uc = parseFloat(userRow.data[0].unclaimed) ?? 0;
    const tslc = Date.now() / 1000 - userRow.data[0].last_claim;
    const hours = parseInt(tslc / 3600);
    const dailyRate = calculateDailyDream(userRow, poolTable);
    return uc + (hours * dailyRate) / 24;
  }
  return 0;
};

const calculateHourlyBreeders = (userRow, poolRow) => {
  if (userRow && poolRow) {
    let userWeight = 0;
    userRow.data.forEach((userPool) => {
      userPool.rarities.forEach((userKV) => {
        if (
          poolRow.rarities.find((poolValue) => poolValue.name === userKV.name)
        ) {
          const currentRate = poolRow.rarities.find(
            (poolValue) => poolValue.name === userKV.name
          ).weight;
          const currentTotal = parseFloat(currentRate) * parseInt(userKV.total);
          userWeight += currentTotal;
        }
      });
    });
    let poolWeight = 0;
    poolRow.rarities_total.forEach((rarity) => {
      const currentRate = poolRow.rarities.find(
        (poolValue) => poolValue.name === rarity.name
      ).weight;
      const currentTotal = parseFloat(currentRate) * parseInt(rarity.total);
      poolWeight += currentTotal;
    });
    return (poolRow.reward * userWeight) / poolWeight / 6;
  }
  return 0;
};

const charToInt64 = (char) => {
  const ch = (ch) => {
    return ch.charCodeAt();
  };
  let c = ch(char);
  if (ch("a") <= c && c <= ch("z")) {
    return c - ch("a") + 6;
  }
  if (ch("1") <= c && c <= ch("5")) {
    return c - ch("1") + 1;
  }
  if (c === ch(".")) {
    return 0;
  }
};

const stringToInt64 = (s) => {
  let answer = 0;
  for (let i = 0; i < s.length; i++) {
    let x = (charToInt64(s.charAt(i)) & 0x1f) << (64 - 5 * (i + 1));
    answer |= x;
  }
  return answer;
};

export {
  calculateHourlyRates,
  calculateTokenUnit,
  calculateNovaRarityValues,
  SNAKING_RATES,
  getDreamPools,
  getTocPools,
  calculateUnclaimedDream,
  calculateUnclaimedToc,
  calculateDailyDream,
  calculateHourlyBreeders,
  stringToInt64,
};
