import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Page from "../Page";
import axios from "axios";
import WalletInput from "../Utilities/WalletInput";
import { linkNewWindow } from "../Utilities/LinkUtilities";
import * as AA from "../Utilities/AA";

const AccountDashboard = (props) => {
  const history = useHistory();
  const [wallet, setWallet] = useState(props.match.params.wallet || "");
  const [loading, setLoading] = useState(false);
  const [prices, setPrices] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    if (wallet && wallet.trim()) {
      history.push("/account/" + wallet);
    }
  }, [wallet, history]);

  useEffect(() => {
    let isSubscribed = true;
    if (wallet && wallet.length > 0) {
      setLoading(true);
      const getPriceData = async (coll) => {
        let pd = [];
        const _setPrices = (pd) => {
          if (isSubscribed && pd.length > 0) {
            setPrices(
              [...pd].sort((a, b) => {
                const ap =
                  a.prices.length > 0 ? a.prices[0].suggested_median : 0;
                const bp =
                  b.prices.length > 0 ? b.prices[0].suggested_median : 0;
                return bp - ap;
              })
            );
          }
        };
        for (let i = 0, failures = 0, tooMany = false; i < coll.length; i++) {
          const coll_name = coll[i].collection.collection_name;
          try {
            const response = await axios.get(
              AA.API +
                "atomicmarket/v1/prices/assets?owner=" +
                wallet +
                "&collection_name=" +
                coll_name
            );
            const x = {
              collection: coll_name,
              assets: coll[i].assets,
              prices: response.data.data,
            };
            pd.push(x);
            _setPrices(pd);
            if (!isSubscribed) {
              break;
            }
          } catch (e) {
            failures++;
            tooMany = true;
            console.log(e);
          }
          await new Promise((r) => setTimeout(r, 333));
          if (failures > 10) {
            break;
          }
          if (tooMany) {
            await new Promise((r) => setTimeout(r, 5000));
            i--;
            tooMany = false;
          }
        }
        _setPrices(pd);
        setLoading(false);
      };
      axios
        .get(AA.API + "atomicmarket/v1/prices/assets?owner=" + wallet)
        .then((response) => {
          if (response.data && response.data.data[0]) {
            setTotalPrice(response.data.data[0].suggested_median);
            axios
              .get(AA.API + "atomicassets/v1/accounts/" + wallet)
              .then((response2) => {
                getPriceData(response2.data.data.collections);
              });
          } else {
            setLoading(false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    return () => (isSubscribed = false);
  }, [wallet]);

  return (
    <Page title="WAX Wallet Overview">
      <h2>Collection Overview and Estimated Value</h2>
      <br />
      <div style={{ minWidth: "420px", margin: "auto" }}>
        <WalletInput
          wallet={wallet}
          setWallet={setWallet}
          loading={loading}
          buttonText="SHOW ME STUFF"
        />
        <br />
        <br />
        {totalPrice === 0 ? null : (
          <div>
            Total wallet value:{" "}
            {(totalPrice / 100000000).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " WAX"}
          </div>
        )}
        <br />
        <br />
        {prices.length === 0 ? null : (
          <table
            style={{
              margin: "auto",
              textAlign: "right",
              borderSpacing: "20px 8px",
              minWidth: "360px",
            }}
          >
            <thead>
              <tr style={{ textAlign: "center" }}>
                <td>Collection</td>
                <td>Assets</td>
                <td>Value</td>
                <td>%</td>
              </tr>
            </thead>
            <tbody>
              {prices.map((v) => {
                const p =
                  v.prices && v.prices.length > 0
                    ? (v.prices[0].suggested_median
                        ? v.prices[0].suggested_median
                        : 0) / 100000000
                    : 0;
                const tp = (100 * p) / (totalPrice / 100000000);
                return (
                  <tr key={v.collection}>
                    <td
                      style={{ cursor: "pointer", textAlign: "left" }}
                      onClick={() =>
                        linkNewWindow(
                          "https://wax.atomichub.io/profile/" +
                            wallet +
                            "?collection_name=" +
                            v.collection
                        )
                      }
                    >
                      {v.collection}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {parseInt(v.assets).toLocaleString()}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <nobr>
                        {p.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) + " WAX"}
                      </nobr>
                    </td>
                    <td>{tp.toFixed(2) + "%"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      <br />
      <br />
      <br />
      <br />
    </Page>
  );
};

export default AccountDashboard;
