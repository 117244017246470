import React, { useState, useEffect } from "react";
import Page from "../Page";
import SnakeLoader from "../SnakeLoader";
import {
  calculateDailyDream,
  calculateUnclaimedDream,
  getDreamPools,
} from "../Utilities/Calculate";
import { linkNewWindow } from "../Utilities/LinkUtilities";
import { rpc } from "../Utilities/RPC";

const COLUMNS = {
  account: "account",
  rate: "rate",
  balance: "balance",
};

const VirtualDreamsLeaderboard = (props) => {
  const [dreamers, setDreamers] = useState([]);
  const [currentSort, setCurrentSort] = useState(null);
  // const [tokenPrices, setTokenPrices] = useState({
  //   dream: 0,
  //   wax: 0,
  // });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const run = async () => {
      setLoading(true);

      // const dreamPrice = await getAlcorPrice("DREAM", "WAX");
      // const waxPrice = await getWaxPrice();
      // setTokenPrices({
      //   dream: dreamPrice,
      //   wax: waxPrice,
      // });

      const getDreamers = async (lowerBound = "", result = []) => {
        const r = await rpc.get_table_rows({
          code: "dreamstaking",
          scope: "dreamstaking",
          table: "user",
          json: true,
          limit: 1000,
          lower_bound: lowerBound,
          upper_bound: "",
        });
        if (r.rows && r.rows.length > 0) {
          result = result.concat(r.rows);
          const lb = result[result.length - 1].account + "1";
          return getDreamers(lb, result);
        } else {
          return result;
        }
      };

      const ds = await getDreamers();
      const pools = await getDreamPools();

      for (let i = 0; i < ds.length; i++) {
        const ur = ds[i];
        if (Object.keys(ur).length > 0) {
          const uc = calculateUnclaimedDream(ur, pools);
          const daily = calculateDailyDream(ur, pools);

          const final = [];

          const getUserLevelCount = (userRow, poolName, levelKey) => {
            const aa = userRow.data.find(
              (userPool) => userPool.pool === poolName
            );
            if (aa) {
              const bb = aa.inventory.find((ii) => ii.key === levelKey);
              if (bb) {
                return bb.value;
              }
            }
            return 0;
          };

          pools.forEach((pool) => {
            const levels = Object.fromEntries(
              pool.levels.map((level) => [
                level.key,
                {
                  rate: parseInt(level.value),
                  count: getUserLevelCount(ur, pool.pool, level.key),
                },
              ])
            );
            final.push({
              pool: pool.pool,
              levels: levels,
              daily: parseInt(daily),
              unclaimed: uc,
            });
          });

          ur["staking"] = final;
        }
      }

      const final = [];
      let promises = [];
      let promiseWallets = [];
      for (let i = 0; i < ds.length; i++) {
        let p = rpc.get_currency_balance("virtualdream", ds[i].account);
        promises.push(p);
        promiseWallets.push(ds[i]);
        if ((i + 1) % 50 === 0 || i === ds.length - 1) {
          const responses = await Promise.allSettled(promises);
          for (let j = 0; j < responses.length; j++) {
            const current = promiseWallets[j];
            if (responses[j].value && responses[j].value.length > 0) {
              const b = responses[j].value[0];
              final.push({
                ...current,
                balance: b ? parseInt(b) : 0,
              });
            } else {
              final.push({
                ...current,
                balance: 0,
              });
            }
          }
          promises = [];
          promiseWallets = [];
        }
      }

      setDreamers(final);
      sortTable(COLUMNS.rate);
      setLoading(false);
    };
    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortTable = (col) => {
    if (currentSort === col) {
      setCurrentSort(null);
      setDreamers((old) => {
        return [...old].reverse();
      });
    } else {
      setCurrentSort(col);
      setDreamers((old) => {
        if (col === COLUMNS.account) {
          return [...old.sort((a, b) => a[col].localeCompare(b[col]))];
        } else if (col === COLUMNS.rate) {
          return [
            ...old.sort((a, b) => {
              return b.staking[0].daily - a.staking[0].daily;
            }),
          ];
        } else {
          return [...old.sort((a, b) => b[col] - a[col])];
        }
      });
    }
  };

  const renderHeaderColumn = (col, title) => {
    return (
      <th
        style={{ cursor: "pointer", textAlign: "left" }}
        onClick={() => sortTable(col)}
      >
        {title}
      </th>
    );
  };

  const renderTables = () => {
    return (
      <table
        style={{
          margin: "auto",
          borderSpacing: "10px 4px",
          textAlign: "left",
        }}
      >
        <thead style={{ textAlign: "center" }}>
          <tr>
            <th></th>
            {renderHeaderColumn(COLUMNS.account, "WALLET")}
            {renderHeaderColumn(COLUMNS.rate, "DREAM/day")}
            {renderHeaderColumn(COLUMNS.balance, "BALANCE")}
          </tr>
        </thead>
        <tbody>
          {dreamers
            .filter((d) => !["thedreamshop"].includes(d.account))
            .map((d, i) => {
              return (
                <tr style={{ textAlign: "right" }} key={d.account}>
                  <td>{i + 1}</td>
                  <td style={{ textAlign: "left" }}>
                    <span
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                      onClick={() =>
                        linkNewWindow(
                          "https://wax.atomichub.io/explorer/account/" +
                            d.account +
                            "?collection_name=virtualdream&order=desc&sort=transferred"
                        )
                      }
                    >
                      {d.account}
                    </span>
                  </td>
                  <td>{d.staking[0].daily.toLocaleString()}</td>
                  <td>{d.balance.toLocaleString()}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  };

  return (
    <Page title="VirtualDream Leaderboard">
      <h2>Staking Leaderboard</h2>
      <br />
      {loading ? (
        <SnakeLoader />
      ) : dreamers && dreamers.length > 0 ? (
        renderTables()
      ) : null}
      <br />
      <br />
      <br />
    </Page>
  );
};

export default VirtualDreamsLeaderboard;
