import React, { useContext, useEffect, useState } from "react";
import Page from "../Page";
import { rpc } from "../Utilities/RPC";
import { NovaContext } from "./NovaContextProvider";
import { NovaComboTableData } from "./NovaDisplay";
import { linkNewWindow } from "../Utilities/LinkUtilities";
import SnakeLoader from "../SnakeLoader";

const NovaRaceQueue = () => {
  const novaContext = useContext(NovaContext);
  const [queue, setQueue] = useState([]);
  const [races, setRaces] = useState([]);
  const [racers, setRacers] = useState([]);
  const [lastRaceTime, setLastRaceTime] = useState(0);
  const [boostUse, setBoostUse] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const getLast24Hours = async (ub = 9999999999, result = []) => {
        const r = await rpc.get_table_rows({
          json: true,
          code: "iraces.nova",
          scope: "iraces.nova",
          table: "races",
          index_position: 1,
          limit: 1000,
          lower_bound: 0,
          upper_bound: ub,
          reverse: true,
        });
        result = result.concat(r.rows);
        let end = false;
        const now = Date.now() / 1000;
        for (let i = 0; i < r.rows.length; i++) {
          const x = r.rows[i];
          if (now - x.launch_ts > 86400) {
            end = true;
            break;
          }
        }
        if (end) {
          return result.filter((x) => now - x.launch_ts <= 86400);
        } else {
          return getLast24Hours(r.rows[r.rows.length - 1].id - 1, result);
        }
      };
      const rr = await getLast24Hours();
      let allRacers = [];
      rr.forEach((x) => {
        allRacers = allRacers.concat(x.players.filter((x, i) => i < 8));
      });
      let unique = [...new Set(allRacers)];

      const numRaces = rr.length * 8;
      let numBoosts = 0;
      rr.forEach((row) => {
        if (row.players.length === 9) {
          const a = row.players[8];
          numBoosts += a.split("1").length - 1;
        }
      });
      setBoostUse(numBoosts / numRaces);

      setRacers(unique.length);
      setRaces(rr.length);
      setLoading(false);
    };
    run();
  }, []);

  useEffect(() => {
    if (novaContext.templates) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [novaContext]);

  const refresh = async () => {
    if (novaContext.templates) {
      setLoading(true);
      const r = await rpc.get_table_rows({
        json: true,
        code: "iraces.nova",
        scope: "iraces.nova",
        table: "queue",
        index_position: 1,
        limit: 1000,
        reverse: true,
      });

      for (let i = 0; i < r.rows.length; i++) {
        for (let j = 0; j < r.rows[i].joins.length; j++) {
          r.rows[i].joins[j].combo = await novaContext.getComboData(
            r.rows[i].joins[j]
          );
        }
      }
      const r2 = await rpc.get_table_rows({
        json: true,
        code: "iraces.nova",
        scope: "iraces.nova",
        table: "races",
        limit: 1,
        reverse: true,
      });

      setLastRaceTime(r2.rows[0].launch_ts);
      setQueue(r.rows);
      setLoading(false);
    }
  };

  const renderQueue = () => {
    return (
      <table
        style={{
          margin: "auto",
          textAlign: "center",
          fontSize: "80%",
        }}
      >
        <tbody>
          {queue.map((row) => {
            return (
              <React.Fragment key={"queuerow" + row.id}>
                <tr>
                  <td
                    style={{
                      fontSize: "140%",
                      fontWeight: "bold",
                    }}
                  >
                    {row.id + 1}
                  </td>
                  <td
                    style={{
                      fontSize: "120%",
                      textDecoration: "underline",
                      textAlign: "left",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      linkNewWindow("https://nf.tools/nova/racer/" + row.player)
                    }
                  >
                    {row.player}
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" style={{ paddingBottom: "8px" }}>
                    <table
                      style={{
                        margin: "auto",
                        textAlign: "left",
                        borderSpacing: "12px 2px",
                        fontSize: "80%",
                      }}
                    >
                      <tbody>
                        {row.joins.map((race, i) => {
                          return (
                            <tr key={"queuerow" + row.id + "-" + i}>
                              <NovaComboTableData
                                combo={race.combo}
                                width="64px"
                              />
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <Page title="Nova Rally Race Queue">
      <h2 style={{ margin: "6px" }}>Race Queue</h2>
      {loading ? (
        <SnakeLoader />
      ) : (
        <div>
          <h1 style={{ margin: "6px" }}>{queue.length}/24</h1>
          <div style={{ fontSize: "80%", marginTop: "4px" }}>
            Time since last race:{" "}
            <span style={{ fontSize: "130%" }}>
              {parseInt((Date.now() - lastRaceTime * 1000) / 1000 / 60) +
                "m" +
                parseInt(((Date.now() - lastRaceTime * 1000) / 1000) % 60) +
                "s"}
            </span>
          </div>
          <div style={{ fontSize: "80%", marginTop: "4px" }}>
            Races in last 24 hours:{" "}
            <span style={{ fontSize: "130%" }}>{races}</span>
          </div>
          <div style={{ fontSize: "80%", marginTop: "4px" }}>
            Unique racers in last 24 hours:{" "}
            <span style={{ fontSize: "130%" }}>{racers}</span>
          </div>
          <div style={{ fontSize: "80%", marginTop: "4px" }}>
            Boost use in last 24 hours:{" "}
            <span style={{ fontSize: "130%" }}>
              {(boostUse * 100).toFixed(2)}%
            </span>
          </div>
          <br />
          <button
            style={{
              cursor: "pointer",
              fontFamily: "Cousine",
              fontWeight: "bold",
            }}
            onClick={refresh}
          >
            REFRESH
          </button>
          <br />
          <br />
          {renderQueue()}
        </div>
      )}
      <br />
      <br />
    </Page>
  );
};

export default NovaRaceQueue;
