import React, { useState, useEffect } from "react";
import Page from "../Page";
import SnakeLoader from "../SnakeLoader";
import { rpc } from "../Utilities/RPC";
import * as C from "./Constants";

const TrainRouteFinder = (props) => {
  const [allRoutes, setAllRoutes] = useState([]);
  const NUM_PATHS = 3;

  useEffect(() => {
    setAllRoutes([]);
    const calculatePaths = (ss, commodityTypes, depth, shortMax, longMin) => {
      const getStation = (id) => {
        return ss.find((s) => s.station_id === id);
      };

      const getTotal = (rates, distance) => {
        let subTotal = 0;
        commodityTypes.forEach((x) => {
          const jj = rates.findIndex((xx) => xx.type === x);
          subTotal += rates[jj].multiplier / commodityTypes.length;
        });
        return Math.pow(distance * subTotal, 1.05);
      };

      const result = [];

      const goDeeper = (depth, n, p, t, td, ps) => {
        for (let i = 0; i < n.connected_stations.length; i++) {
          const c1 = n.connected_stations[i];

          if (c1.station_id === ps.station_id) {
            continue;
          }

          const d1 = c1.distance;
          const td1 = td + d1;
          const n1 = getStation(c1.station_id);
          const p1 = [...p, n1];
          const t1 = t + getTotal(n1.type_rates, d1);

          if (depth === 0) {
            if (p1[0] === p1.at(-1)) {
              const obj = {
                path: p1.map((x) => {
                  return {
                    region: C.REGIONS[x.region_id],
                    name: C.STATION_NAMES[x.station_id],
                    rarity: x.rarity,
                    id: x.station_id,
                  };
                }),
                total: t1,
                dist: td1,
              };
              result.push(obj);
            }
          } else {
            goDeeper(depth - 1, n1, p1, t1, td1, n);
          }
        }
      };

      for (let stationIndex = 0; stationIndex < ss.length; stationIndex++) {
        const n0 = ss[stationIndex];
        const p0 = [n0];

        goDeeper(depth, n0, p0, 0, 0, { station_id: 0 });
      }
      result.sort((a, b) => b.total / b.dist - a.total / a.dist);
      const paths = result.filter((x, i) => i % (depth + 1) === 1);

      const gp = (allPaths, compare, isRegion) => {
        const paths = [];
        let pathIndex = 0;
        for (let i = 1; i <= (isRegion ? 5 : NUM_PATHS); i++) {
          let found = false;
          if (isRegion) {
            pathIndex = 0;
          }
          while (!found) {
            if (compare(allPaths[pathIndex], i)) {
              found = true;
              paths.push({
                region: allPaths[pathIndex].path[0].region,
                path: allPaths[pathIndex].path,
                distance: allPaths[pathIndex].dist,
                value: allPaths[pathIndex].total / allPaths[pathIndex].dist,
              });
            }
            pathIndex += 1;
          }
        }
        return paths;
      };

      const gtp = (p) => {
        return {
          path: p.path,
          distance: p.dist,
          value: p.total / p.dist,
        };
      };

      const rp = gp(paths, (a, b) => a.path[0].region === C.REGIONS[b], true);
      const sp = gp(paths, (a) => a.dist < shortMax);
      const lp = gp(paths, (a) => a.dist > longMin);

      return {
        type: commodityTypes[0],
        top_paths: [...Array(NUM_PATHS).keys()].map((i) => gtp(paths[i])),
        region_paths: rp,
        short_paths: sp,
        long_paths: lp,
      };
    };

    const run = async () => {
      const getStations = async (lowerBound = "", result = []) => {
        const r = await rpc.get_table_rows({
          code: "rr.century",
          table: "stations",
          scope: "modern",
          limit: 1000,
          lower_bound: lowerBound,
        });
        if (r.rows.length > 0) {
          result = result.concat(r.rows);
          const lb = parseInt(r.rows[r.rows.length - 1].station_id) + 1;
          return getStations(lb, result);
        } else {
          return result;
        }
      };

      const s = await getStations();

      const tr = [];
      const sr = [];
      Object.values(C.COMMODITY_TYPES).forEach((comm) => {
        tr.push(calculatePaths(s, [comm], 2, 55, 110));
      });
      Object.values(C.COMMODITY_TYPES).forEach((comm) => {
        sr.push(calculatePaths(s, [comm], 3, 90, 150));
      });

      const ar = [];
      for (let i = 0; i < tr.length; i++) {
        const r = tr[i];
        const type = r.type;
        const tp = r.top_paths.concat(sr[i].top_paths);
        const rp = r.region_paths.concat(sr[i].region_paths);
        const sp = r.short_paths.concat(sr[i].short_paths);
        const lp = r.long_paths.concat(sr[i].long_paths);
        ar.push({
          type: type,
          top_paths: tp,
          region_paths: rp,
          short_paths: sp,
          long_paths: lp,
        });
      }
      setAllRoutes(ar);
    };
    run();
  }, []);

  const renderHeader = (text) => {
    return <h4 style={{ marginTop: "12px", marginBottom: "8px" }}>{text}</h4>;
  };

  const renderRoutes = (routes) => {
    return routes.length > 0 ? (
      routes.map((route, i) => {
        return (
          <div key={"route" + route.type + i}>
            <div>
              <h1 style={{ marginTop: "30px", marginBottom: "0px" }}>
                {route.type.replace("_", " ").toUpperCase()}
              </h1>
              {renderHeader("BEST OVERALL")}
              <div>
                <TrainPathTable>
                  {route.top_paths
                    .sort((a, b) => b.value - a.value)
                    .map((p, ii) => (
                      <TrainPath
                        key={"top" + i + ii}
                        region={p.path[0].region}
                        path={p.path}
                        distance={p.distance}
                        value={p.value}
                      />
                    ))}
                </TrainPathTable>
              </div>
            </div>
            <div>
              {renderHeader("BEST SHORT PATHS")}
              <TrainPathTable>
                {route.short_paths
                  .sort((a, b) => b.value - a.value)
                  .map((p, ii) => (
                    <TrainPath
                      key={"short" + route.type + i + ii}
                      region={p.region}
                      path={p.path}
                      distance={p.distance}
                      value={p.value}
                    />
                  ))}
              </TrainPathTable>
            </div>
            <div>
              {renderHeader("BEST LONG PATHS")}
              <TrainPathTable>
                {route.long_paths
                  .sort((a, b) => b.value - a.value)
                  .map((p, ii) => (
                    <TrainPath
                      key={"long" + route.type + i + ii}
                      region={p.region}
                      path={p.path}
                      distance={p.distance}
                      value={p.value}
                    />
                  ))}
              </TrainPathTable>
            </div>
            <div>
              {renderHeader("BEST PER REGION")}
              <TrainPathTable>
                {route.region_paths
                  .sort((a, b) => a.region.localeCompare(b.region))
                  .map((p, ii) => (
                    <TrainPath
                      key={p.region + route.type + i + ii}
                      region={p.region}
                      path={p.path}
                      distance={p.distance}
                      value={p.value}
                    />
                  ))}
              </TrainPathTable>
            </div>
          </div>
        );
      })
    ) : (
      <SnakeLoader />
    );
  };

  return (
    <Page title="Train Route Finder">
      <h2>Best Loops</h2>
      {renderRoutes(allRoutes)}
      <br />
      <br />
      <br />
      <br />
      <br />
    </Page>
  );
};

const TrainPathTable = ({ children }) => {
  return (
    <table style={{ margin: "0px auto", borderSpacing: "2px 2px" }}>
      <tbody>{children}</tbody>
    </table>
  );
};

const TrainPath = ({ region, path, distance, value }) => {
  let shape = "";
  if (path.length === 4) {
    shape = "△";
  } else if (path.length === 5) {
    shape = "◻";
  }
  const shortRegion = {
    Centuryville: "CEN",
    "Pemberton Heights": "PMB",
    "Trevithick Pines": "TRV",
    "Pawpaw Plains": "PAW",
    "James Park": "JAM",
  };
  return (
    <tr>
      <td style={{ textAlign: "right" }}>
        <span style={{ fontWeight: "bold" }}>{distance}</span>
      </td>
      <td style={{ fontSize: "150%", lineHeight: "0px" }}>{shape}</td>
      <td style={{ textAlign: "right" }}>{value ? value.toFixed(1) : ""}</td>
      <td>
        <span
          style={{
            fontSize: "75%",
            border: "1px solid white",
            padding: "1px 2px",
          }}
        >
          {shortRegion[region]}
        </span>
      </td>
      <td style={{ textAlign: "left" }}>
        <nobr>
          {path.map((p, i) => {
            const arrow = i > 0 ? "▸" : "";
            return (
              <span key={"train_path" + i}>
                {arrow}
                <ColorStation name={p.name} rarity={p.rarity} />
              </span>
            );
          })}
        </nobr>
      </td>
    </tr>
  );
};
const ColorStation = ({ name, rarity }) => {
  const COLOR = {
    common: "#666600",
    uncommon: "green",
    rare: "blue",
    epic: "purple",
    legendary: "yellow",
    mythic: "red",
  };

  return (
    <span
      style={{
        fontSize: "75%",
        padding: "1px 3px",
        color: COLOR[rarity],
        fontFamily: "sans-serif",
      }}
    >
      {name}
    </span>
  );
};

export default TrainRouteFinder;
