import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Page from "../Page";
import SnakeLoader from "../SnakeLoader";
import WalletInput from "../Utilities/WalletInput";
import { renderLinkNewWindow } from "../Utilities/LinkUtilities";
import axios from "axios";
import * as AA from "../Utilities/AA";

const NovaCollection = (props) => {
  const [loading, setLoading] = useState(false);
  const [driverData, setDriverData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const history = useHistory();
  const [wallet, setWallet] = useState(props.match.params.wallet || "");

  const RARITY_ORDER = ["Common", "Uncommon", "Rare", "Classic", "Sketch"];
  const LEAGUE_ORDER = ["Rookie", "Intermediate", "Veteran", "Master"];

  useEffect(() => {
    const run = async () => {
      if (wallet) {
        setLoading(true);
        const r1 = await axios.get(
          AA.API +
            "atomicassets/v1/templates?collection_name=novarallywax&schema_name=drivers&limit=1000&order=desc&sort=created"
        );
        const drv = r1.data.data.filter((x) => x.issued_supply > 0);
        const r2 = await axios.get(
          AA.API +
            "atomicassets/v1/templates?collection_name=novarallywax&schema_name=vehicles&limit=1000&order=desc&sort=created"
        );
        const veh = r2.data.data.filter((x) => x.issued_supply > 0);
        const r3 = await axios.get(
          AA.API + "atomicassets/v1/accounts/" + wallet + "/novarallywax"
        );
        const usr = r3.data.data.templates;
        setAssetData(r3.data.data.schemas);

        drv.forEach((d) => {
          const ii = usr.findIndex((x) => x.template_id === d.template_id);
          if (ii >= 0) {
            d.count = usr[ii].assets;
          } else {
            d.count = 0;
          }
        });

        veh.forEach((v) => {
          const ii = usr.findIndex((x) => x.template_id === v.template_id);
          if (ii >= 0) {
            v.count = usr[ii].assets;
          } else {
            v.count = 0;
          }
        });

        setDriverData(drv);
        setVehicleData(veh);
        setLoading(false);
      }
    };
    run();
  }, [wallet]);

  const handleSetWallet = (w) => {
    history.push("/nova/collection/" + w);
    setWallet(w);
  };

  const renderTableHeader = (hasSketch) => {
    return (
      <thead>
        <tr style={{ fontSize: "60%" }}>
          <th></th>
          <th style={{ width: "50px" }}>COMMON</th>
          <th style={{ width: "50px" }}>UNCOMMON&nbsp;</th>
          <th style={{ width: "50px" }}>RARE</th>
          <th style={{ width: "50px" }}>CLASSIC</th>
          {hasSketch ? <th style={{ width: "50px" }}>SKETCH</th> : null}
        </tr>
      </thead>
    );
  };

  const renderDriverTable = () => {
    driverData.sort((a, b) => {
      const aa = a.immutable_data.Quality.localeCompare(
        b.immutable_data.Quality
      );
      const bb = a.immutable_data.name.localeCompare(b.immutable_data.name);
      let cc =
        RARITY_ORDER.indexOf(a.immutable_data.Rarity) -
        RARITY_ORDER.indexOf(b.immutable_data.Rarity);
      cc = cc > 0 ? 1 : cc < 0 ? -1 : 0;
      return aa * 4 + bb * 2 + cc;
    });

    const tableData = [];
    let currentQual = "";
    let currentName = "";
    let totals = {};
    driverData.forEach((v) => {
      const qual = v.immutable_data.Quality;
      const name = v.immutable_data.name;
      if (currentQual !== qual) {
        tableData.push({ league: qual, names: [] });
        currentQual = qual;
      }
      const iQual = tableData.length - 1;
      if (currentName !== name) {
        tableData[iQual].names.push({
          name: name,
          rarities: [],
        });
        currentName = name;
      }
      const iName = tableData[iQual].names.length - 1;

      tableData[iQual].names[iName].rarities.push(v);

      totals[qual] = totals[qual] || {};
      totals[qual][v.immutable_data.Rarity] =
        totals[qual][v.immutable_data.Rarity] || 0;
      totals[qual][v.immutable_data.Rarity] += parseInt(v.count);
    });

    const tables = [];
    tableData
      .filter(
        (table) =>
          table.league === "Rookie" ||
          table.league === "Intermediate" ||
          table.league === "Veteran" ||
          table.league === "Master"
      )
      .sort((a, b) => {
        return LEAGUE_ORDER.indexOf(a.league) - LEAGUE_ORDER.indexOf(b.league);
      })
      .forEach((table) => {
        tables.push(
          <React.Fragment key={table.league + "-drivers"}>
            <h4 style={{ margin: "8px" }}>{table.league} League</h4>
            <table style={{ margin: "auto", marginBottom: "24px" }}>
              {renderTableHeader(true)}
              <tbody>
                {table.names.map((name) => {
                  return (
                    <tr key={name.name}>
                      <td style={{ textAlign: "left" }}>
                        {name.name.startsWith("Otis")
                          ? "Otis Carlton"
                          : name.name}
                      </td>
                      {name.rarities.map((rar, i) => {
                        return (
                          <td key={name.name + "-" + i}>
                            {renderLinkNewWindow(
                              "https://wax.atomichub.io/market?collection_name=novarallywax&order=asc&sort=price&template_id=" +
                                rar.template_id,
                              rar.count ? rar.count : "\u00A0\u00A0",
                              false
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
                <tr>
                  <td></td>
                  {Object.values(totals[table.league]).map((total, i) => {
                    return (
                      <td
                        key={table.league + "drivertotal" + i}
                        style={{ fontWeight: "bold" }}
                      >
                        {total}
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </React.Fragment>
        );
      });
    return tables;
  };

  const renderVehicleTable = () => {
    vehicleData.sort((a, b) => {
      const aa = a.immutable_data.Quality.localeCompare(
        b.immutable_data.Quality
      );
      const bb = a.immutable_data.Manufacturer.localeCompare(
        b.immutable_data.Manufacturer
      );
      const cc = a.immutable_data.name.localeCompare(b.immutable_data.name);
      let dd =
        RARITY_ORDER.indexOf(a.immutable_data.Rarity) -
        RARITY_ORDER.indexOf(b.immutable_data.Rarity);
      dd = dd > 0 ? 1 : dd < 0 ? -1 : 0;
      return aa * 8 + bb * 4 + cc * 2 + dd;
    });

    const tableData = [];
    let totals = {};
    let currentQual = "";
    let currentManu = "";
    let currentName = "";
    vehicleData.forEach((v) => {
      const qual = v.immutable_data.Quality;
      const manu = v.immutable_data.Manufacturer;
      const name = v.immutable_data.name;
      if (currentQual !== qual) {
        tableData.push({ league: qual, manufacturers: [] });
        currentQual = qual;
      }
      const iQual = tableData.length - 1;
      if (currentManu !== manu) {
        tableData[iQual].manufacturers.push({ manufacturer: manu, names: [] });
        currentManu = manu;
      }
      const iManu = tableData[iQual].manufacturers.length - 1;
      if (currentName !== name) {
        tableData[iQual].manufacturers[iManu].names.push({
          name: name,
          rarities: [],
        });
        currentName = name;
      }
      const iName = tableData[iQual].manufacturers[iManu].names.length - 1;

      tableData[iQual].manufacturers[iManu].names[iName].rarities.push(v);

      totals[qual] = totals[qual] || {};
      totals[qual][v.immutable_data.Rarity] =
        totals[qual][v.immutable_data.Rarity] || 0;
      totals[qual][v.immutable_data.Rarity] += parseInt(v.count);
    });

    const tables = [];
    tableData
      .filter(
        (table) =>
          table.league === "Rookie" ||
          table.league === "Intermediate" ||
          table.league === "Veteran" ||
          table.league === "Master"
      )
      .sort((a, b) => {
        return LEAGUE_ORDER.indexOf(a.league) - LEAGUE_ORDER.indexOf(b.league);
      })
      .forEach((table) => {
        tables.push(
          <React.Fragment key={table.league + "-vehicles"}>
            <h4 style={{ margin: "8px" }}>{table.league} League</h4>
            <table style={{ margin: "auto", marginBottom: "24px" }}>
              {renderTableHeader(false)}
              <tbody>
                {table.manufacturers.map((manu) => {
                  return manu.names.map((name) => {
                    return (
                      <tr key={manu.manufacturer + "-" + name.name}>
                        <td style={{ textAlign: "left" }}>
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "80%",
                              fontStyle: "italic",
                            }}
                          >
                            {manu.manufacturer
                              .toUpperCase()
                              .padEnd(6, "\u00A0")}
                          </span>
                          &nbsp;
                          {name.name}
                        </td>
                        {name.rarities.map((rar, i) => {
                          return (
                            <td
                              key={
                                manu.manufacturer + "-" + name.name + "-" + i
                              }
                            >
                              {renderLinkNewWindow(
                                "https://wax.atomichub.io/market?collection_name=novarallywax&order=asc&sort=price&template_id=" +
                                  rar.template_id,
                                rar.count ? rar.count : "\u00A0\u00A0",
                                false
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  });
                })}
                <tr>
                  <td></td>
                  {Object.values(totals[table.league]).map((total, i) => {
                    return (
                      <td
                        key={table.league + "vehicletotal" + i}
                        style={{ fontWeight: "bold" }}
                      >
                        {total}
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </React.Fragment>
        );
      });
    return tables.map((table, i) => {
      return table;
    });
  };

  return (
    <Page title="Nova Rally Collection">
      <h2>Collection Details</h2>
      {loading ? (
        <SnakeLoader />
      ) : (
        <WalletInput
          wallet={props.match.params.wallet || ""}
          setWallet={handleSetWallet}
          buttonText="RALLY ASSETS"
          loading={false}
        />
      )}
      <br />
      {loading
        ? null
        : Object.values(assetData).map((schema) => {
            return (
              <div
                key={"schematotal" + schema.schema_name}
                style={{ marginBottom: "5px" }}
              >
                Total {schema.schema_name}: {schema.assets}
              </div>
            );
          })}
      {loading || !wallet ? null : (
        <div>
          <h1>Vehicles</h1>
          <div>{renderVehicleTable()}</div>
          <h1>Drivers</h1>
          <div>{renderDriverTable()}</div>
        </div>
      )}
      <br />
      <br />
    </Page>
  );
};

export default NovaCollection;
