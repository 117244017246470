export const createAction = (contract, name, user, data) => {
  return {
    account: contract,
    name: name,
    authorization: [
      {
        actor: user,
        permission: "active",
      },
    ],
    data: data,
  };
};

export const signTransaction = async (
  activeUser,
  actions,
  onSuccess = (response) => window.location.reload(),
  onError = (error) => window.location.reload()
) => {
  try {
    const r = await activeUser.signTransaction(
      {
        actions: actions,
      },
      {
        blocksBehind: 3,
        expireSeconds: 30,
      }
    );
    console.log(r.transaction.processed);
    onSuccess(r);
  } catch (e) {
    console.log(e);
    onError(e);
  }
};
