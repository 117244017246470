import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import Page from "../Page";
import WalletInput from "../Utilities/WalletInput";
import axios from "axios";
import { linkNewWindow } from "../Utilities/LinkUtilities";
import SnakeLoader from "../SnakeLoader";
import * as AA from "../Utilities/AA";

const NovaAssetsRPW = (props) => {
  const COLUMNS = {
    mint: "mint",
    type: "type",
    league: "league",
    rarity: "rarity",
    name: "name",
    rr: "rr",
    rp: "rp",
    rw: "rw",
    ir: "ir",
    ip: "ip",
    iw: "iw",
    vr: "vr",
    vp: "vp",
    vw: "vw",
    mr: "mr",
    mp: "mp",
    mw: "mw",
  };
  const FILTERS = {
    Driver: "Driver",
    Vehicle: "Vehicle",
    Both: "Both",
  };
  const UNIQUE_ORDER = {
    league: ["Rookie", "Intermediate", "Veteran", "Master"],
    rarity: ["Common", "Uncommon", "Rare", "Classic", "Sketch"],
  };

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [wallet, setWallet] = useState(props.match.params.wallet || "");
  const [assetData, setAssetData] = useState([]);
  const [currentSort, setCurrentSort] = useState(null);
  const [dvfilter, setDvfilter] = useState(FILTERS.Both);

  const handleSetWallet = (w) => {
    history.push("/nova/rpw/" + w);
    setWallet(w);
  };

  useEffect(() => {
    const getData = async (schema) => {
      const CHUNK = 1000;
      const more = async (schema, pageNumber) => {
        const r = await axios.get(
          AA.API +
            "atomicassets/v1/assets?collection_name=novarallywax&schema_name=" +
            schema +
            "&owner=" +
            wallet +
            "&page=" +
            pageNumber +
            "&limit=" +
            CHUNK +
            "&order=desc&sort=asset_id"
        );
        const result = [];
        r.data.data.forEach((x) => {
          if (
            x.mutable_data["Rookie League Races"] > 0 ||
            x.mutable_data["Intermediate League Races"] > 0 ||
            x.mutable_data["Veteran League Races"] > 0 ||
            x.mutable_data["Master League Races"] > 0
          ) {
            let str =
              x.schema.schema_name.charAt(0).toUpperCase() +
              x.schema.schema_name.slice(1);
            str = str.slice(0, -1);
            result.push({
              type: str,
              id: x.asset_id,
              mint: x.template_mint,
              name: x.data.name,
              rarity: x.data.Rarity,
              league: x.data["Nova League"],
              rr: x.mutable_data["Rookie League Races"] ?? 0,
              rp: x.mutable_data["Rookie League Podium Finishes"] ?? 0,
              rw: x.mutable_data["Rookie League Wins"] ?? 0,
              ir: x.mutable_data["Intermediate League Races"] ?? 0,
              ip: x.mutable_data["Intermediate League Podium Finishes"] ?? 0,
              iw: x.mutable_data["Intermediate League Wins"] ?? 0,
              vr: x.mutable_data["Veteran League Races"] ?? 0,
              vp: x.mutable_data["Veteran League Podium Finishes"] ?? 0,
              vw: x.mutable_data["Veteran League Wins"] ?? 0,
              mr: x.mutable_data["Master League Races"] ?? 0,
              mp: x.mutable_data["Master League Podium Finishes"] ?? 0,
              mw: x.mutable_data["Master League Wins"] ?? 0,
            });
          }
        });
        return result;
      };

      const result = [];
      let numAssets = 1000;
      let page = 1;
      do {
        const a = await more(schema, page);
        numAssets = a.length;
        result.push(...a);
        page++;
      } while (numAssets === 1000);

      return result;
    };

    const run = async () => {
      if (wallet) {
        setLoading(true);
        const v = await getData("vehicles");
        const d = await getData("drivers");
        setAssetData(v.concat(d));
        setCurrentSort(null);
        sortTable(COLUMNS.rr, true);
        sortTable(COLUMNS.rp, true);
        sortTable(COLUMNS.rw, true);
        sortTable(COLUMNS.ir, true);
        sortTable(COLUMNS.ip, true);
        sortTable(COLUMNS.iw, true);
        sortTable(COLUMNS.vr, true);
        sortTable(COLUMNS.vp, true);
        sortTable(COLUMNS.vw, true);
        sortTable(COLUMNS.mr, true);
        sortTable(COLUMNS.mp, true);
        sortTable(COLUMNS.mw, true);
        setLoading(false);
      }
    };

    run();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet]);

  const sortTable = (column, reverseOff) => {
    if (currentSort === column && !reverseOff) {
      setCurrentSort(null);
      setAssetData((old) => {
        return [...old].reverse();
      });
    } else {
      setCurrentSort(column);
      let sort = () => 0;
      if ([COLUMNS.type, COLUMNS.name].includes(column)) {
        sort = (a, b) => a[column].localeCompare(b[column]);
      } else if ([COLUMNS.league, COLUMNS.rarity].includes(column)) {
        sort = (a, b) => {
          let aa =
            UNIQUE_ORDER[column].indexOf(b[column]) -
            UNIQUE_ORDER[column].indexOf(a[column]);
          aa = aa > 0 ? 1 : aa < 0 ? -1 : 0;
          return aa;
        };
      } else {
        sort = (a, b) => {
          const x = b[column] - a[column];
          return column === COLUMNS.mint ? -x : x;
        };
      }
      setAssetData((old) => old.sort(sort));
    }
  };

  const renderColumnHeader = (column, longTitle) => {
    const t = longTitle ? column.toUpperCase() : column.charAt(1).toUpperCase();
    return (
      <th style={{ cursor: "pointer" }} onClick={() => sortTable(column)}>
        {t}
      </th>
    );
  };

  const renderRwpData = (num) => {
    return <td style={{ minWidth: "23px" }}>{num > 0 ? num : ""}</td>;
  };

  const renderOverallData = () => {
    const filteredAssets = assetData.filter((x) => {
      return dvfilter === FILTERS.Both ? true : x.type === dvfilter;
    });
    const getTotal = (assets, column) => {
      return assets.reduce((acc, val) => {
        return acc + parseInt(val[column]);
      }, 0);
    };
    const totals = [
      getTotal(filteredAssets, COLUMNS.rr),
      getTotal(filteredAssets, COLUMNS.rp),
      getTotal(filteredAssets, COLUMNS.rw),
      getTotal(filteredAssets, COLUMNS.ir),
      getTotal(filteredAssets, COLUMNS.ip),
      getTotal(filteredAssets, COLUMNS.iw),
      getTotal(filteredAssets, COLUMNS.vr),
      getTotal(filteredAssets, COLUMNS.vp),
      getTotal(filteredAssets, COLUMNS.vw),
      getTotal(filteredAssets, COLUMNS.mr),
      getTotal(filteredAssets, COLUMNS.mp),
      getTotal(filteredAssets, COLUMNS.mw),
    ];
    return wallet ? (
      <div>
        <label style={{ cursor: "pointer", margin: "6px" }}>
          <input
            type="radio"
            name="dvfilter"
            value={FILTERS.Driver}
            checked={dvfilter === FILTERS.Driver}
            onChange={() => setDvfilter(FILTERS.Driver)}
          />
          Drivers
        </label>
        <label style={{ cursor: "pointer", margin: "6px" }}>
          <input
            type="radio"
            name="dvfilter"
            value={FILTERS.Vehicle}
            checked={dvfilter === FILTERS.Vehicle}
            onChange={() => setDvfilter(FILTERS.Vehicle)}
          />
          Vehicles
        </label>
        <label style={{ cursor: "pointer", margin: "6px" }}>
          <input
            type="radio"
            name="dvfilter"
            value={FILTERS.Both}
            checked={dvfilter === FILTERS.Both}
            onChange={() => setDvfilter(FILTERS.Both)}
          />
          Both
        </label>
        <br />
        <br />
        <table
          style={{
            margin: "auto",
            textAlign: "center",
            borderSpacing: "12px 4px",
            fontSize: "80%",
          }}
        >
          <thead>
            <tr>
              <th colSpan="5"></th>
              <th colSpan="3">ROOKIE</th>
              <th colSpan="3">INTERMEDIATE</th>
              <th colSpan="3">VETERAN</th>
              <th colSpan="3">MASTER</th>
            </tr>
            <tr>
              {renderColumnHeader(COLUMNS.mint, true)}
              {renderColumnHeader(COLUMNS.type, true)}
              {renderColumnHeader(COLUMNS.league, true)}
              {renderColumnHeader(COLUMNS.rarity, true)}
              {renderColumnHeader(COLUMNS.name, true)}
              {renderColumnHeader(COLUMNS.rr)}
              {renderColumnHeader(COLUMNS.rp)}
              {renderColumnHeader(COLUMNS.rw)}
              {renderColumnHeader(COLUMNS.ir)}
              {renderColumnHeader(COLUMNS.ip)}
              {renderColumnHeader(COLUMNS.iw)}
              {renderColumnHeader(COLUMNS.vr)}
              {renderColumnHeader(COLUMNS.vp)}
              {renderColumnHeader(COLUMNS.vw)}
              {renderColumnHeader(COLUMNS.mr)}
              {renderColumnHeader(COLUMNS.mp)}
              {renderColumnHeader(COLUMNS.mw)}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="5"></td>
              {totals.map((total, i) => {
                return <td key={"totals" + i}>{total}</td>;
              })}
            </tr>
            {filteredAssets.map((asset) => {
              if (asset.name === "Otis Carlton Daniels") {
                asset.name = "Otis Carlton";
              }
              return (
                <tr key={asset.id}>
                  <td
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() =>
                      linkNewWindow(
                        "https://wax.atomichub.io/explorer/asset/" + asset.id
                      )
                    }
                  >
                    {asset.mint}
                  </td>
                  <td style={{ textAlign: "left" }}>{asset.type}</td>
                  <td style={{ textAlign: "left" }}>{asset.league}</td>
                  <td style={{ textAlign: "left" }}>{asset.rarity}</td>
                  <td style={{ textAlign: "left" }}>{asset.name}</td>
                  {renderRwpData(asset.rr)}
                  {renderRwpData(asset.rp)}
                  {renderRwpData(asset.rw)}
                  {renderRwpData(asset.ir)}
                  {renderRwpData(asset.ip)}
                  {renderRwpData(asset.iw)}
                  {renderRwpData(asset.vr)}
                  {renderRwpData(asset.vp)}
                  {renderRwpData(asset.vw)}
                  {renderRwpData(asset.mr)}
                  {renderRwpData(asset.mp)}
                  {renderRwpData(asset.mw)}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    ) : null;
  };

  return (
    <Page title="Nova Rally Asset Results">
      <h2>Asset Races/Podiums/Wins</h2>
      <div>
        <WalletInput
          wallet={props.match.params.wallet || ""}
          setWallet={handleSetWallet}
          buttonText="GREAT ASSETS"
          loading={false}
        />
      </div>
      <br />
      {loading ? <SnakeLoader /> : renderOverallData()}
      <br />
      <br />
    </Page>
  );
};

export default NovaAssetsRPW;
