import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Page from "../Page";
import SnakeLoader from "../SnakeLoader";
import { calculateNovaRarityValues } from "../Utilities/Calculate";

const SnakingValues = () => {
  const [snakingValues, setSnakingValues] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const result = await calculateNovaRarityValues("novarallysnk");
      setSnakingValues(result);
      setLoading(false);
    };
    run();
  }, []);

  const renderPoolTitle = (pool) => {
    if (pool === "rookie") {
      return "Nova Rookie League (SNAKOIL)";
    } else if (pool === "intermediate") {
      return "Nova Intermediate League (SNAKGAS)";
    } else if (pool === "veteran") {
      return "Nova Veteran League (SNAKPOW)";
    } else if (pool === "master") {
      return "Nova Master League (SNAKVEN)";
    } else {
      return pool + " (BOOST)";
    }
  };

  return (
    <Page title="Nova Rally Snaking Values">
      <h2>Snaking Values</h2>
      <Link to="/nova/snaking">Snaking Calculator</Link>
      &nbsp;&nbsp;
      <Link to="/nova/leaderboard">Snaking Leaderboard</Link>
      <br />
      {loading || Object.keys(snakingValues).length === 0 ? (
        <SnakeLoader />
      ) : (
        <div>
          {Object.entries(snakingValues)
            .filter((x) => {
              return [
                "rookie",
                "intermediate",
                "veteran",
                "master",
                "olivelandnft",
                "promorplanet",
                "music",
              ].includes(x[0]);
            })
            .sort(([poola], [poolb]) => {
              const POOL_ORDER = [
                "rookie",
                "intermediate",
                "veteran",
                "master",
                "olivelandnft",
                "promorplanet",
                "music",
              ];
              let aa = POOL_ORDER.indexOf(poola) - POOL_ORDER.indexOf(poolb);
              aa = aa > 0 ? 1 : aa < 0 ? -1 : 0;
              return aa;
            })
            .map(([pool, data]) => {
              return (
                <React.Fragment key={pool + "frag"}>
                  <h3 style={{ marginBottom: "6px" }}>
                    {renderPoolTitle(pool)}
                  </h3>
                  {["rookie", "intermediate", "veteran", "master"].includes(
                    pool
                  ) ? (
                    <table style={{ margin: "0px auto" }}>
                      <tbody>
                        <tr>
                          <td style={{ textAlign: "right" }}>Current pool:</td>
                          <td>{data.PoolSize.toLocaleString()}</td>
                        </tr>
                        <tr>
                          <td style={{ textAlign: "right" }}>Expected pool:</td>
                          <td>
                            {parseInt(
                              (data.PoolSize * 1000) / data["Sketch"]
                            ).toLocaleString()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : null}
                  <table style={{ margin: "auto", borderSpacing: "10px 4px" }}>
                    <thead>
                      <tr>
                        <th style={{ textAlign: "left" }}>Rarity</th>
                        <th>per hour</th>
                        <th>per day</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(data).map(([rarity, value]) => {
                        if (
                          rarity === "Wins" ||
                          rarity === "Podium" ||
                          rarity === "PoolSize"
                        )
                          return null;
                        return (
                          <tr key={pool + rarity}>
                            <td style={{ textAlign: "left" }}>{rarity}</td>
                            <td style={{ textAlign: "right" }}>
                              {value.toLocaleString(undefined, {
                                minimumFractionDigits: 1,
                                maximumFractionDigits: 1,
                              })}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {(24 * value).toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </React.Fragment>
              );
            })}
        </div>
      )}
      <br />
      <br />
    </Page>
  );
};

export default SnakingValues;
