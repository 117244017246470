const SnakeLoader = ({ noMargin }) => {
  let segments = [];
  for (let i = 0; i < 10; i++) {
    segments.push(
      <div className="snake-segment" key={"snake-segment" + i}></div>
    );
  }
  let marginBottom = noMargin ? "0px" : "16px";
  let marginTop = noMargin ? "0px" : "32px";

  return (
    <div style={{ paddingTop: "16px" }}>
      <div
        className="snake-loader"
        style={{ marginBottom: marginBottom, marginTop: marginTop }}
      >
        <div className="snake-body">{segments.map((s) => s)}</div>
      </div>
    </div>
  );
};

export default SnakeLoader;
