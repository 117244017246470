import axios from "axios";
import React, { useState, useEffect } from "react";
import firebase from "../Firebase";
import * as AA from "../Utilities/AA";

const NovaContext = React.createContext([]);

const NovaContextProvider = ({ children }) => {
  const [templates, setTemplates] = useState(null);

  const getTemplateData = (templateId) => {
    templateId = "" + templateId;
    console.log(templateId);
    console.log(templates);
    const ii = templates.findIndex((x) => x.templateId === templateId);
    console.log(ii);
    if (ii > -1) {
      return templates[ii].rarity + " " + templates[ii].name;
    }
  };

  const getAssetData = async (assetId) => {
    assetId = "" + assetId; // IMPORTANT
    const result = {
      name: "Unknown",
      rarity: "Unknown",
      league: "Unknown",
    };
    if (templates) {
      const ii = templates.findIndex((x) => x.assetIds.includes(assetId));
      if (ii >= 0) {
        return {
          name: templates[ii].name,
          rarity: templates[ii].rarity,
          league: templates[ii].league,
        };
      } else {
        try {
          const r = await axios.get(
            AA.API + "atomicassets/v1/assets/" + assetId
          );
          result.name = r.data.data.template.immutable_data.name;
          result.rarity = r.data.data.template.immutable_data.Rarity;
          result.league = r.data.data.template.immutable_data["Nova League"];
          const templateId = r.data.data.template.template_id;
          let setAssetIdTemplateId = firebase
            .functions()
            .httpsCallable("setAssetIdTemplateId");
          await setAssetIdTemplateId({
            assetId: [assetId],
            templateId: templateId,
          });
          setTemplates((old) => {
            const ii = old.findIndex((x) => x.templateId === templateId);
            old[ii].assetIds.push(assetId);
            return [...old];
          });
        } catch (e) {
          console.error(e);
        }
      }
    }
    return result;
  };

  const getComboData = async (row) => {
    if (
      row &&
      row.vehicle_asset_id &&
      row.driver1_asset_id &&
      row.driver2_asset_id
    ) {
      const v = await getAssetData(row.vehicle_asset_id);
      const d1 = await getAssetData(row.driver1_asset_id);
      const d2 = await getAssetData(row.driver2_asset_id);
      return {
        vehicleRarity: v.rarity,
        vehicleName: v.name,
        vehicleLeague: v.league,
        driver1Rarity: d1.rarity,
        driver1Name: d1.name,
        driver1League: d1.league,
        driver2Rarity: d2.rarity,
        driver2Name: d2.name,
        driver2League: d2.league,
      };
    } else {
      return {
        vehicleRarity: "",
        vehicleName: "",
        vehicleLeague: "",
        driver1Rarity: "",
        driver1Name: "",
        driver1League: "",
        driver2Rarity: "",
        driver2Name: "",
        driver2League: "",
      };
    }
  };

  useEffect(() => {
    const run = async () => {
      if (!templates) {
        const db = firebase.firestore();
        const novaAssetRef = db.collection("nova-assets");
        novaAssetRef.get().then(async (querySnapshot) => {
          let templateId = "000000";
          let name = "Unknown";
          let rarity = "Unknown";
          let league = "Unknown";
          let assetIds = [];
          let result = [];
          querySnapshot.forEach((doc) => {
            templateId = doc.id;
            const d = doc.data();
            name = d.name ?? name;
            rarity = d.rarity ?? rarity;
            league = d.league ?? league;
            assetIds = d.assetIds ?? assetIds;
            result.push({
              templateId: templateId,
              assetIds: assetIds,
              name: name,
              rarity: rarity,
              league: league,
            });
          });
          setTemplates(result);
        });
      }
    };
    run();
  }, [templates]);

  return (
    <NovaContext.Provider
      value={{
        templates: templates,
        getComboData: getComboData,
        getTemplateData: getTemplateData,
      }}
    >
      {children}
    </NovaContext.Provider>
  );
};

export { NovaContext, NovaContextProvider };
