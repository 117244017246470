import React, { useEffect } from "react";
import firebase from "../Firebase";
import Page from "../Page";

const NovaRacingLeaderboardUpdate = () => {
  if (process.env.NODE_ENV === "development") {
    firebase.functions().useEmulator("localhost", 5001);
  }

  useEffect(() => {
    let updateNovaLeaderboard = firebase
      .functions()
      .httpsCallable("updateNovaLeaderboard");
    let getUniqueNovaRacers = firebase
      .functions()
      .httpsCallable("getUniqueNovaRacers");

    const run = async () => {
      const r2 = await getUniqueNovaRacers();
      const uniqueRacers = r2.data.data;
      console.log(uniqueRacers);

      let promises = [];
      for (let i = 0; i < uniqueRacers.length; i++) {
        const p = updateNovaLeaderboard({ wallet: uniqueRacers[i] }).then(
          (response) => {
            console.log(response.data);
          }
        );
        promises.push(p);
        if ((i + 1) % 10 === 0 || i === uniqueRacers.length - 1) {
          const responses = await Promise.allSettled(promises);
          console.log(responses);
          promises = [];
        }
      }
    };

    run();
  }, []);

  return (
    <Page title="Nova Update">
      <br />
      <br />
    </Page>
  );
};

export default NovaRacingLeaderboardUpdate;
