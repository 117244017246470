import { rpc } from "../Utilities/RPC";

export const getStations = async (
  century = "modern",
  lowerBound = "",
  result = []
) => {
  const r = await rpc.get_table_rows({
    code: "rr.century",
    table: "stations",
    scope: century,
    limit: 1000,
    lower_bound: lowerBound,
  });
  if (r.rows.length > 0) {
    result = result.concat(r.rows);
    const lb = parseInt(r.rows.at(-1).station_id) + 1;
    return getStations(century, lb, result);
  } else {
    return result;
  }
};

export const getRarityRates = async (century) => {
  const r = await rpc.get_table_rows({
    code: "rr.century",
    table: "rates",
    scope: century,
    limit: 1000,
  });
  return Object.fromEntries(r.rows.map((x) => [x.rarity, x.rate]));
};

export const getCommodityValues = async (
  century,
  rarityRates = {
    common: 20,
    uncommon: 45,
    rare: 65,
    epic: 80,
    legendary: 315,
    mythic: 625,
  }
) => {
  const r = await rpc.get_table_rows({
    code: "rr.century",
    table: "templates",
    scope: century,
    limit: 1000,
  });
  return Object.fromEntries(
    r.rows.map((x) => [x.template_id, x.multiplier * rarityRates[x.rarity]])
  );
};

export const getPassengerBasePay = async (
  century = "modern",
  lowerBound = "",
  result = []
) => {
  const r = await rpc.get_table_rows({
    code: "rr.century",
    table: "passengers",
    scope: century,
    limit: 1000,
    lower_bound: lowerBound,
  });
  if (r.rows.length > 0) {
    result = result.concat(r.rows);
    const lb = parseInt(r.rows.at(-1).template_id) + 1;
    return getPassengerBasePay(century, lb, result);
  } else {
    return result;
  }
};
