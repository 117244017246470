import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Page from "../Page";
import SnakeLoader from "../SnakeLoader";
import { RPlanetContext } from "./RPlanetContextProvider";

const AetherLeaderboard = () => {
  const columns = {
    wallet: "wallet",
    aehr: "aehr",
    aether: "aether",
    unclaimed: "unclaimed",
    ftTotal: "ftTotal",
    total: "total",
  };

  const rpContext = useContext(RPlanetContext);

  const [leaders, setLeaders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentSort, setCurrentSort] = useState(null);
  const [showNonPlayer, setShowNonPlayer] = useState(false);

  useEffect(() => {
    if (rpContext.aetherPlayers) {
      let ap = rpContext.aetherPlayers.sort((a, b) => b.total - a.total);
      setLeaders(ap);
      setLoading(false);
    } else {
      rpContext.refresh();
      setLoading(true);
    }
  }, [rpContext]);

  const renderLeaderboard = () => {
    return (
      <table
        style={{
          margin: "auto",
          textAlign: "center",
          borderSpacing: "20px 8px",
          fontSize: "80%",
        }}
      >
        <thead>
          <tr>
            <td colSpan="8">
              <div style={{ fontSize: "80%", marginBottom: "6px" }}>
                *only includes RPlanet elements and rigs
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.wallet)}
            >
              Wallet
            </td>
            <td
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.aehr)}
            >
              AE/hr*
            </td>
            <td
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.aether)}
            >
              Aether
            </td>
            <td
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.unclaimed)}
            >
              Unclaimed*
            </td>
            <td
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.ftTotal)}
            >
              FT Value
            </td>
            <td
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.total)}
            >
              Total
            </td>
          </tr>
        </thead>
        <tbody style={{ textAlign: "right" }}>
          {leaders
            .filter(
              (leader) =>
                (leader.total && leader.total >= 1) ||
                (leader.aehr && leader.aehr >= 1)
            )
            .filter((x) => {
              if (showNonPlayer) {
                return true;
              } else {
                if (
                  [
                    "s.rplanet",
                    "alcordexmain",
                    "alcordexfund",
                    "theuplift.xp",
                    "alcorammswap",
                    "upliftworld",
                    "simplemarket",
                    "graffitiking",
                    "drop.rland",
                  ].includes(x.wallet)
                ) {
                  return false;
                }
                return true;
              }
            })
            .map((leader, i) => {
              return (
                <tr key={i + "-" + leader}>
                  <td>{i + 1}</td>
                  <td style={{ textAlign: "left" }}>
                    <Link to={"/rplanet/aether/value/" + leader.wallet}>
                      {leader.wallet}
                    </Link>
                  </td>
                  <td>
                    {leader.aehr ? parseInt(leader.aehr).toLocaleString() : 0}
                  </td>
                  <td>
                    {leader.aether
                      ? parseInt(leader.aether).toLocaleString()
                      : 0}
                  </td>
                  <td>
                    {leader.unclaimed
                      ? parseInt(leader.unclaimed).toLocaleString()
                      : 0}
                  </td>
                  <td>
                    {leader.ftTotal
                      ? parseInt(leader.ftTotal).toLocaleString()
                      : 0}
                  </td>
                  <td>
                    {leader.total ? parseInt(leader.total).toLocaleString() : 0}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  };

  const renderTotals = () => {
    let filter = () => true;
    if (!showNonPlayer) {
      filter = (x) => {
        if (
          [
            "s.rplanet",
            "alcordexmain",
            "alcordexfund",
            "theuplift.xp",
            "alcorammswap",
            "upliftworld",
            "simplemarket",
          ].includes(x.wallet)
        ) {
          return false;
        }
        return true;
      };
    }
    return (
      <tbody>
        <tr>
          <td style={{ textAlign: "left" }}>Total AE/hr*: </td>
          <td style={{ fontWeight: "bold" }}>
            {parseInt(
              leaders
                .filter(filter)
                .reduce((n, { aehr }) => (aehr ? n + aehr : n), 0)
            ).toLocaleString()}
          </td>
        </tr>
        <tr>
          <td style={{ textAlign: "left" }}>Total Aether Held: </td>
          <td style={{ fontWeight: "bold" }}>
            {parseInt(
              leaders.filter(filter).reduce((n, { aether }) => n + aether, 0)
            ).toLocaleString()}
          </td>
        </tr>
        <tr>
          <td style={{ textAlign: "left" }}>Total Unclaimed*: </td>
          <td style={{ fontWeight: "bold" }}>
            {parseInt(
              leaders
                .filter(filter)
                .reduce((n, { unclaimed }) => n + unclaimed, 0)
            ).toLocaleString()}
          </td>
        </tr>
        <tr>
          <td style={{ textAlign: "left" }}>Total FT Value: </td>
          <td style={{ fontWeight: "bold" }}>
            {parseInt(
              leaders.filter(filter).reduce((n, { ftTotal }) => n + ftTotal, 0)
            ).toLocaleString()}
          </td>
        </tr>
        <tr>
          <td style={{ textAlign: "left" }}>Total Everything: </td>
          <td style={{ fontWeight: "bold" }}>
            {parseInt(
              leaders.filter(filter).reduce((n, { total }) => n + total, 0)
            ).toLocaleString()}
          </td>
        </tr>
      </tbody>
    );
  };

  const sortTable = (col) => {
    if (currentSort === col) {
      setCurrentSort(null);
      setLeaders((old) => {
        return [...old].reverse();
      });
    } else {
      setCurrentSort(col);
      setLeaders((old) => {
        if (col === columns.wallet) {
          return [...old.sort((a, b) => a[col].localeCompare(b[col]))];
        } else {
          return [
            ...old.sort((a, b) => {
              const x = a[col] ? a[col] : 0;
              const y = b[col] ? b[col] : 0;
              return y - x;
            }),
          ];
        }
      });
    }
  };

  const toggleShowNonPlayer = () => {
    setShowNonPlayer((old) => !old);
  };

  return (
    <Page title="Aether Leaderboard">
      <h2>Aether Leaderboard</h2>
      <div>
        (updated periodically, to add or update someone,{" "}
        <Link to="/rplanet/aether/value">
          <nobr>check their aether value</nobr>
        </Link>
        )
      </div>
      <br />
      <Link to="/rplanet/ftleaders">FT Leaders</Link>
      &nbsp;&nbsp;&nbsp;
      <Link to="/rplanet/land/leaderboard">Land Leaders</Link>
      <br />
      <br />
      <table
        style={{
          margin: "auto",
          textAlign: "right",
          borderSpacing: "20px 8px",
        }}
      >
        {renderTotals()}
      </table>
      <span>
        <input
          type="checkbox"
          checked={showNonPlayer}
          onChange={() => toggleShowNonPlayer()}
        />
        <span
          onClick={() => toggleShowNonPlayer()}
          style={{ cursor: "pointer", fontSize: "90%" }}
        >
          Show non-player accounts
        </span>
      </span>
      <br />
      {loading || leaders.length === 0 ? <SnakeLoader /> : renderLeaderboard()}
      <br />
      <br />
    </Page>
  );
};

export default AetherLeaderboard;
