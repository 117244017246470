import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Page from "../Page";
import { calculateHourlyBreeders } from "../Utilities/Calculate";
import {
  AccountBalances,
  AlcorLinks,
  TokenPriceTable,
} from "../Utilities/Display";
import { rpc } from "../Utilities/RPC";
import StakeableAssets from "../Utilities/StakeableAssets";
import useBalances from "../Utilities/useBalances";
import useTokenPrices from "../Utilities/useTokenPrices";
import WalletInput from "../Utilities/WalletInput";

const BreedersStaking = (props) => {
  const [tokenPrices, getTokenPrice] = useTokenPrices();
  const [wallet, setWallet] = useState(props.match.params.wallet || "");
  const balances = useBalances(wallet, "esbcontracts", "esb");
  const history = useHistory();
  const [loading] = useState(false);
  const [hourlyRate, setHourlyRate] = useState(0);
  const [unclaimed, setUnclaimed] = useState(0);

  const handleSetWallet = (w) => {
    history.push("/breeders/staking/" + w);
    setWallet(w);
  };

  useEffect(() => {
    const run = async () => {
      if (wallet && tokenPrices) {
        setHourlyRate(0);
        const r1 = await rpc.get_table_rows({
          json: true,
          code: "breederspool",
          scope: "breederspool",
          table: "pools",
          limit: 1,
        });
        const poolRow = r1.rows[0];

        const r2 = await rpc.get_table_rows({
          json: true,
          code: "breederspool",
          scope: "breederspool",
          table: "users",
          lower_bound: wallet,
          upper_bound: wallet,
          limit: 1,
        });
        const userRow = r2.rows[0];
        if (userRow) {
          const hr = calculateHourlyBreeders(userRow, poolRow);
          const uc = parseFloat(userRow.data[0].unclaimed);
          // console.log(poolRow);
          // console.log(userRow);
          // console.log(hr, uc);
          setUnclaimed(uc);
          setHourlyRate(hr);
          //setActualStaking(r2.rows[0].data);
        }
      }
    };
    run();
  }, [wallet, tokenPrices]);

  const renderBalance = () => {
    return (
      <React.Fragment>
        <TokenPriceTable
          coinPriceArray={[
            ["ESB", getTokenPrice("ESBWAX")],
            ["WAX", getTokenPrice("WAXUSD")],
          ]}
        />
        <br />
        <AlcorLinks coinContractArray={[["esb", "esbcontracts"]]} />
        <br />
        {wallet ? (
          <AccountBalances balanceArray={balances} showDecimals={true} />
        ) : null}
        <br />
        {unclaimed ? (
          <div>
            <div style={{ marginBottom: "6px" }}>Unclaimed:</div>
            <div
              style={{
                fontSize: "120%",
                fontWeight: "bold",
              }}
            >
              {unclaimed.toFixed(4)} ESB
            </div>
          </div>
        ) : null}
        <br />
        {hourlyRate ? (
          <div>
            <div style={{ marginBottom: "6px" }}>Staking Power:</div>
            <div
              style={{
                fontSize: "120%",
                fontWeight: "bold",
              }}
            >
              {hourlyRate.toFixed(4)} ESB/hour
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  return (
    <Page title="Breeders Zone Staking Calculator">
      <h2>Staking Calculator</h2>

      <div>
        <div style={{ margin: "auto" }}>
          <WalletInput
            wallet={wallet}
            setWallet={handleSetWallet}
            loading={loading}
            buttonText="BREED ME"
          />
        </div>
      </div>
      <br />
      {tokenPrices ? renderBalance() : null}
      <br />
      {wallet ? (
        <React.Fragment>
          <h4>Asset IDs:</h4>
          <div>
            <StakeableAssets
              key={"breederszoneassets" + wallet}
              collection="breederszone"
              wallet={wallet}
              noTitle={true}
            />
          </div>
        </React.Fragment>
      ) : null}
      <br />
      <br />
    </Page>
  );
};

export default BreedersStaking;
