import React from "react";
import { Link } from "react-router-dom";
import Page from "../Page";

const MoreLanding = () => {
  return (
    <Page title="Games and Collections">
      <div style={{ textAlign: "left", width: "300px", margin: "0px auto" }}>
        <h3>
          <Link to="/breeders">Breeders Zone</Link>
          <br />
          <br />
          <Link to="/galaktika">Galaktika</Link>
          <br />
          <br />
          <Link to="/nova">Nova Rally</Link>
          <br />
          <br />
          <Link to="/novo">Novopangea</Link>
          <br />
          <br />
          <Link to="/olive">Oliveland</Link>
          <br />
          <br />
          <Link to="/rplanet">RPlanet</Link>
          <br />
          <br />
          <Link to="/totc">Tales of the Crypto</Link>
          <br />
          <br />
          <Link to="/trains">Train of the Century</Link>
          <br />
          <br />
          <Link to="/dream">VirtualDream</Link>
          <br />
          <br />
          <Link to="/account">Account</Link>
          <br />
          <br />
        </h3>
      </div>
    </Page>
  );
};

export default MoreLanding;
