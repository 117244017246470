import React from "react";
import { Link } from "react-router-dom";
import Page from "../Page";

const CenturyTrain = () => {
  return (
    <Page title="Train of the Century">
      <h2>
        <Link to="/trains/build">Train Builder</Link>
        <br />
        <br />
        <Link to="/trains/claim">Claim Railruns</Link>
        <br />
        <br />
        <Link to="/trains/owner">Station Owner Claim</Link>
        <br />
        <br />
        {/* <Link to="/trains/routes">Route Finder</Link> */}
      </h2>
    </Page>
  );
};

export default CenturyTrain;
