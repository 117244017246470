import React, { useEffect, useState } from "react";
import Page from "../Page";
import { linkNewWindow } from "../Utilities/LinkUtilities";
import { rpc } from "../Utilities/RPC";
import WaxLogin from "../Wallet/Login";
import * as TXN from "../Wallet/Transaction";

const WakeWorkers = ({ ual }) => {
  const [workers, setWorkers] = useState([]);

  useEffect(() => {
    const run = async () => {
      if (ual && ual.activeUser) {
        const getAllWorkers = async (lowerBound = "", result = []) => {
          const r = await rpc.get_table_rows({
            code: "game.novo",
            table: "worker",
            scope: "game.novo",
            limit: 1000,
            lower_bound: lowerBound,
          });
          if (r.rows.length > 0) {
            result = result.concat(r.rows);
            const lb = parseInt(r.rows.at(-1).id) + 1;
            return getAllWorkers(lb, result);
          } else {
            return result;
          }
        };
        let w = await getAllWorkers();
        w = w.filter(
          (x) =>
            x.owner === ual.activeUser.accountName &&
            x.rest_end > 0 &&
            x.rest_end < Date.now() / 1000
        );
        setWorkers(w);
      }
    };
    run();
  }, [ual]);

  const wakeAllWorkers = async () => {
    const actions = [];
    for (let i = 0; i < Math.min(25, workers.length); i++) {
      actions.push(
        TXN.createAction(
          "game.novo",
          "wakeworker",
          ual.activeUser.accountName,
          { id: workers[i].id }
        )
      );
    }
    console.log(actions);
    await TXN.signTransaction(ual.activeUser, actions);
  };

  return (
    <Page title="Novopangea Wake Workers">
      <h2>Wake Workers</h2>
      <WaxLogin ual={ual} />
      <br />
      <div style={{ margin: "0px auto", width: "420px" }}>
        {workers.length > 0 ? (
          <div>
            <div
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={wakeAllWorkers}
            >
              BULK WAKE (25 at a time)
            </div>
            <br />
            {workers.map((worker) => {
              const link =
                "https://wax.bloks.io/account/game.novo?loadContract=true&tab=Actions" +
                "&table=worker&account=game.novo&scope=game.novo&limit=10000&action=wakeworker&id=" +
                worker.id;
              return (
                <span
                  key={worker.id}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => linkNewWindow(link)}
                >
                  {worker.id}{" "}
                </span>
              );
            })}
          </div>
        ) : (
          <div>no workers to wake</div>
        )}
      </div>
    </Page>
  );
};

export default WakeWorkers;
