import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Page from "../Page";
import SnakeLoader from "../SnakeLoader";
import { calculateHourlyRates } from "../Utilities/Calculate";
import { TokenPriceTable } from "../Utilities/Display";
import { rpc } from "../Utilities/RPC";
import useTokenPrices from "../Utilities/useTokenPrices";

const SnakingLeaderboard = () => {
  const columns = {
    account: "account",
    snakoil: "snakoil",
    snakgas: "snakgas",
    snakpow: "snakpow",
    snakven: "snakven",
    boost: "boost",
    wax: "wax",
  };

  const [leaders, setLeaders] = useState([]);
  const [leaderCount, setLeaderCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentSort, setCurrentSort] = useState(null);
  const [showNonPlayer, setShowNonPlayer] = useState(false);

  const [tokenPrices, getTokenPrice] = useTokenPrices();

  useEffect(() => {
    const run = async () => {
      if (tokenPrices) {
        setLeaderCount(0);
        setLoading(true);

        const r = await rpc.get_table_rows({
          json: true,
          code: "novarallysnk",
          scope: "novarallysnk",
          table: "staking",
          limit: 1000,
        });
        const poolTable = r.rows;

        const getUsers = async (result = [], lowerBound = "") => {
          const r = await rpc.get_table_rows({
            json: true,
            code: "novarallysnk",
            scope: "novarallysnk",
            table: "user",
            lower_bound: lowerBound,
            limit: 1000,
          });
          if (r.rows.length > 0) {
            setLeaderCount((old) => old + r.rows.length);
            result = result.concat(r.rows);
            const lb = r.rows[r.rows.length - 1].account + "1";
            return getUsers(result, lb);
          } else {
            return result;
          }
        };
        const users = await getUsers();
        users.forEach((user, i) => {
          const userData = calculateHourlyRates(user, poolTable);
          user = {
            ...user,
            snakoil: { day: 0 },
            snakgas: { day: 0 },
            snakpow: { day: 0 },
            snakven: { day: 0 },
            boost: { day: 0 },
            wax: { day: 0 },
            usd: { day: 0 },
          };
          userData.data.forEach((pool) => {
            if (pool.pool === "rookie") {
              const total = parseInt(pool.hourlyTotal) * 24;
              user.snakoil.day += total;
            } else if (pool.pool === "intermediate") {
              const total = parseInt(pool.hourlyTotal) * 24;
              user.snakgas.day += total;
            } else if (pool.pool === "veteran") {
              const total = parseInt(pool.hourlyTotal) * 24;
              user.snakpow.day += total;
            } else if (pool.pool === "master") {
              const total = parseInt(pool.hourlyTotal) * 24;
              user.snakven.day += total;
            } else {
              const total = parseInt(pool.hourlyTotal) * 24;
              user.boost.day += total;
            }
          });
          user.wax.day =
            getTokenPrice("SNAKOILWAX") * user.snakoil.day +
            getTokenPrice("SNAKGASWAX") * user.snakgas.day +
            getTokenPrice("SNAKPOWWAX") * user.snakpow.day +
            getTokenPrice("SNAKVENWAX") * user.snakven.day +
            getTokenPrice("BOOSTWAX") * user.boost.day;
          user.usd.day = getTokenPrice("WAXUSD") * user.wax.day;
          users[i] = user;
        });
        setLeaders(
          users.filter((x) => {
            return x.wax.day > 0;
          })
        );
        sortTable(columns.wax);
        setLoading(false);
      }
    };
    run();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenPrices, getTokenPrice]);

  const sortTable = (col) => {
    if (currentSort === col) {
      setCurrentSort(null);
      setLeaders((old) => {
        return [...old].reverse();
      });
    } else {
      setCurrentSort(col);
      setLeaders((old) => {
        if (col === columns.account) {
          return [...old.sort((a, b) => a[col].localeCompare(b[col]))];
        } else {
          return [
            ...old.sort((a, b) => {
              const x = a[col].day ? a[col].day : 0;
              const y = b[col].day ? b[col].day : 0;
              return y - x;
            }),
          ];
        }
      });
    }
  };

  const renderLeaderboard = () => {
    return (
      <table
        style={{
          margin: "auto",
          textAlign: "right",
          borderSpacing: "12px 4px",
          fontSize: "80%",
        }}
      >
        <thead>
          <tr>
            <th colSpan="2"></th>
            <th
              colSpan="7"
              style={{ textAlign: "center", borderBottom: "1px solid white" }}
            >
              DAILY RATES
            </th>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <th></th>
            <th
              style={{ cursor: "pointer", textAlign: "left" }}
              onClick={() => sortTable(columns.account)}
            >
              SNAKER
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.snakoil)}
            >
              SNAKOIL
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.snakgas)}
            >
              SNAKGAS
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.snakpow)}
            >
              SNAKPOW
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.snakven)}
            >
              SNAKVEN
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.boost)}
            >
              BOOST
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.wax)}
            >
              WAX
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.wax)}
            >
              USD
            </th>
          </tr>
        </thead>
        <tbody>
          {leaders
            .filter((x) =>
              showNonPlayer
                ? true
                : ![
                    "novarallywax",
                    "novarallypro",
                    "novarallyapp",
                    "olivelandnft",
                  ].includes(x.account)
            )
            .map((leader, i) => {
              return (
                <tr key={"leader-" + leader.account}>
                  <td style={{ textAlign: "center" }}>{i + 1}</td>
                  <td style={{ textAlign: "left" }}>
                    <Link to={"/nova/snaking/" + leader.account}>
                      {leader.account}
                    </Link>
                  </td>
                  <td>{leader.snakoil.day.toLocaleString()}</td>
                  <td>{leader.snakgas.day.toLocaleString()}</td>
                  <td>{leader.snakpow.day.toLocaleString()}</td>
                  <td>{leader.snakven.day.toLocaleString()}</td>
                  <td>{leader.boost.day.toLocaleString()}</td>
                  <td>
                    {leader.wax.day.toLocaleString(undefined, {
                      minimumFractionDigits: 3,
                      maximumFractionDigits: 3,
                    })}
                  </td>
                  <td>
                    {leader.usd.day.toLocaleString(undefined, {
                      minimumFractionDigits: 4,
                      maximumFractionDigits: 4,
                    })}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  };

  const toggleShowNonPlayer = () => {
    setShowNonPlayer((old) => !old);
  };

  return (
    <Page title="Nova Rally Snaking Leaderboard">
      <h2>Snaking Leaderboard</h2>
      <Link to="/nova/snaking">Snaking Calculator</Link>
      &nbsp;&nbsp;
      <Link to="/nova/values">Snaking Values</Link>
      <br />
      <div>
        <br />
        <h5 style={{ width: "320px", margin: "auto" }}>
          * does not include extra snaking power from podium finishes or boost
          from Dark Country lands *
        </h5>
        <br />
        {tokenPrices ? (
          <TokenPriceTable
            coinPriceArray={[
              ["SNAKOIL", getTokenPrice("SNAKOILWAX")],
              ["SNAKGAS", getTokenPrice("SNAKGASWAX")],
              ["SNAKPOW", getTokenPrice("SNAKPOWWAX")],
              ["SNAKVEN", getTokenPrice("SNAKVENWAX")],
              ["BOOST", getTokenPrice("BOOSTWAX")],
              ["WAX", getTokenPrice("WAXUSD")],
            ]}
          />
        ) : null}
        <br />
        <span>
          <input
            type="checkbox"
            checked={showNonPlayer}
            onChange={() => toggleShowNonPlayer()}
          />
          <span
            onClick={() => toggleShowNonPlayer()}
            style={{ cursor: "pointer", fontSize: "90%" }}
          >
            Show non-player accounts
          </span>
        </span>
        <br />
        <br />
        {loading ? (
          <div>
            <br />
            <br />
            {leaderCount}
          </div>
        ) : null}
        {loading || leaders.length === 0 ? (
          <SnakeLoader />
        ) : (
          renderLeaderboard()
        )}
      </div>
      <br />
      <br />
    </Page>
  );
};

export default SnakingLeaderboard;
