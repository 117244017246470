import { useState, useEffect } from "react";
import { rpc } from "./RPC";

const useBalances = (wallet, contract, token) => {
  const [balances, setBalances] = useState(null);

  useEffect(() => {
    const run = async () => {
      if (wallet && wallet !== "") {
        let bal = [];
        try {
          bal = await rpc.get_currency_balance(
            contract,
            wallet.toString(),
            token
          );
        } catch (e) {
          console.log(e);
        }
        setBalances(bal);
      }
    };

    run();
  }, [wallet, contract, token]);

  return balances;
};

export default useBalances;
