import axios from "axios";
import React, { useEffect } from "react";
import firebase from "../Firebase";
import Page from "../Page";
import * as AA from "../Utilities/AA";

const NovaUpdateAssetIds = ({ schema }) => {
  if (process.env.NODE_ENV === "development") {
    firebase.functions().useEmulator("localhost", 5001);
  }

  useEffect(() => {
    let setAssetIdTemplateId = firebase
      .functions()
      .httpsCallable("setAssetIdTemplateId");
    let setLastAssetId = firebase.functions().httpsCallable("setLastAssetId");
    let getLastAssetId = firebase.functions().httpsCallable("getLastAssetId");

    const run = async () => {
      const r = await getLastAssetId({ schema: schema });
      const lowerBound = parseInt(r.data ?? 0) + 1;
      console.log("lowerBound", lowerBound);

      const getAssets = async (page = 1, result = []) => {
        const r = await axios.get(
          AA.API +
            "atomicassets/v1/assets?" +
            "collection_name=novarallywax&schema_name=" +
            schema +
            "&page=" +
            page +
            "&limit=1000&order=asc&sort=asset_id&lower_bound=" +
            lowerBound
        );
        if (r.data.data && r.data.data.length > 0) {
          console.log(page, r.data.data);
          result = result.concat(
            r.data.data.map((x) => {
              return {
                assetId: x.asset_id,
                templateId: x.template.template_id,
              };
            })
          );
          if (result.length < 10000) {
            return getAssets(page + 1, result);
          }
        }
        return result;
      };

      const r1 = await getAssets();
      console.log(r1);

      const la = r1[r1.length - 1].assetId;
      console.log(la);
      const lastAsset = await setLastAssetId({
        assetId: la,
        schema: schema,
      });

      const groupBy = (arr, property, group) => {
        return arr.reduce((acc, cur) => {
          acc[cur[property]] = [...(acc[cur[property]] || []), cur[group]];
          return acc;
        }, {});
      };

      const grouped = groupBy(r1, "templateId", "assetId");
      console.log(grouped);

      for (const [template, assets] of Object.entries(grouped)) {
        const r = await setAssetIdTemplateId({
          assetId: assets,
          templateId: template,
        });
        console.log(r.data);
      }
      console.log(lastAsset);
      console.log("added", r1.length, "assets");
    };

    run();
  }, [schema]);

  return (
    <Page title="Nova Update">
      <br />
      <br />
    </Page>
  );
};

export default NovaUpdateAssetIds;
