import React, { useState, useEffect } from "react";
import Page from "../Page";
import SnakeLoader from "../SnakeLoader";
import { rpc } from "../Utilities/RPC";
import WaxLogin from "../Wallet/Login";
import * as C from "./Constants";
import * as API from "./API";
import * as TXN from "../Wallet/Transaction";
import axios from "axios";
import * as AA from "../Utilities/AA";

const ClaimDispatch = ({ ual }) => {
  const [loading, setLoading] = useState(false);
  const [trains, setTrains] = useState([]);
  const [stations, setStations] = useState([]);
  const [refreshCount, setRefreshCount] = useState(0);
  const [doAutoRepair, setDoAutoRepair] = useState(true);

  useEffect(() => {
    const run = async () => {
      if (ual.activeUser) {
        setLoading(true);
        let r = await rpc.get_table_rows({
          code: "rr.century",
          table: "trains",
          scope: ual.activeUser.accountName,
          limit: 1000,
        });
        const allTrains = r.rows;
        r = await rpc.get_table_rows({
          code: "rr.century",
          table: "railruns",
          scope: ual.activeUser.accountName,
          limit: 1000,
        });
        const railruns = r.rows;

        r = await API.getStations("modern");
        setStations(r);

        allTrains.forEach((x) => {
          const rr = railruns.find((y) => y.train === x.train);
          if (rr) {
            x.railrun = rr;
          }
        });
        setTrains(allTrains);
      }
      setLoading(false);
    };
    run();
  }, [ual.activeUser, refreshCount]);

  const refresh = () => {
    setRefreshCount((old) => old + 1);
  };

  const getTrainDispatchAction = async (train, stationId) => {
    const data = {
      arriving: stationId,
      railroader: ual.activeUser.accountName,
      train: train,
    };
    const action = TXN.createAction(
      "rr.century",
      "transport",
      ual.activeUser.accountName,
      data
    );
    return action;
  };

  const dispatchTrain = async (train, stationId) => {
    const action = await getTrainDispatchAction(train, stationId);
    await TXN.signTransaction(ual.activeUser, [action]);
  };

  const getTrainClaimAction = async (trainName, stationId) => {
    const r = await axios.get(AA.API + "atomicassets/v1/assets/" + stationId);
    const data = {
      auto_repair: doAutoRepair,
      owner: r.data.data.owner,
      railroader: ual.activeUser.accountName,
      train: trainName,
    };
    const action = TXN.createAction(
      "rr.century",
      "claim",
      ual.activeUser.accountName,
      data
    );
    return action;
  };

  const claimTrain = async (trainName, stationId) => {
    const action = await getTrainClaimAction(trainName, stationId);
    await TXN.signTransaction(ual.activeUser, [action]);
  };

  const claimAll = async () => {
    setLoading(true);
    const actions = [];
    for (let i = 0; i < trains.length; i++) {
      const train = trains[i];
      if (train.railrun && train.railrun.run_complete < Date.now() / 1000) {
        const action = await getTrainClaimAction(
          train.train,
          train.railrun.arrive_station
        );
        actions.push(action);
      }
    }
    await TXN.signTransaction(ual.activeUser, actions);
    setLoading(false);
  };

  const getTrainClearVerifyAction = (trainName, clearVerify) => {
    return TXN.createAction(
      "rr.century",
      clearVerify,
      ual.activeUser.accountName,
      {
        railroader: ual.activeUser.accountName,
        train: trainName,
      }
    );
  };

  const clearAll = async () => {
    const clearActions = trains.map((train) =>
      getTrainClearVerifyAction(train.train, "cleartrain")
    );
    await TXN.signTransaction(ual.activeUser, clearActions);
  };

  const verifyAll = async () => {
    const verifyActions = trains.map((train) =>
      getTrainClearVerifyAction(train.train, "verifytrain")
    );
    await TXN.signTransaction(ual.activeUser, verifyActions);
  };

  const formatTime = (ms) => {
    return parseInt(ms / 60) + "m";
  };

  const getRegionByStationId = (stationId) => {
    if (stations.length > 0) {
      const obj = stations.find((x) => x.station_id === stationId);
      if (obj && obj.region_id) {
        return C.REGIONS_SHORT[obj.region_id];
      }
    }
    return "???";
  };

  const getColorStation = (stationId) => {
    if (stations.length > 0) {
      const obj = stations.find((x) => x.station_id === stationId);
      if (obj && obj.rarity) {
        return C.ColorRarity(C.STATION_NAMES[stationId], obj.rarity);
      }
    }
    return C.STATION_NAMES[stationId] ?? "???";
  };

  const getConnectedStations = (stationId) => {
    if (stations.length > 0) {
      const obj = stations.find((x) => x.station_id === stationId);
      if (obj && obj.connected_stations) {
        return obj.connected_stations;
      }
    }
    return [];
  };

  const Train = ({ train }) => {
    let status = "";
    let station = train.railrun
      ? train.railrun.arrive_station
      : train.current_station;
    if (train.railrun) {
      if (train.railrun.run_complete < Date.now() / 1000) {
        status = (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => claimTrain(train.train, station)}
          >
            CLAIM
          </span>
        );
      } else {
        status = formatTime(train.railrun.run_complete - Date.now() / 1000);
      }
    } else {
      status = "IDLE";
    }
    return (
      <>
        <tr>
          <td>{train.train}</td>
          <td>{getRegionByStationId(station)}</td>
          <td>{getColorStation(station)}</td>
          <td>{status}</td>
        </tr>
        {status === "IDLE"
          ? getConnectedStations(station).map((s) => {
              const color =
                train.previous_station === s.station_id ? "red" : "white";
              const dispatch = (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => dispatchTrain(train.train, s.station_id)}
                >
                  DISPATCH
                </span>
              );
              return (
                <tr key={station + "-" + s.station_id} style={{ color: color }}>
                  <td style={{ textAlign: "right" }}>{s.distance}</td>
                  <td>{getRegionByStationId(s.station_id)}</td>
                  <td>{getColorStation(s.station_id)}</td>
                  <td>{dispatch}</td>
                </tr>
              );
            })
          : null}
        <tr>
          <td>&nbsp;</td>
        </tr>
      </>
    );
  };

  return (
    <Page title="Claim Railruns">
      <h2>Claim Railruns</h2>
      <WaxLogin ual={ual} />
      {ual.activeUser ? (
        <div>
          <div>
            <input
              type="checkbox"
              defaultChecked={true}
              value={doAutoRepair}
              onChange={(e) => setDoAutoRepair(e.target.checked)}
              style={{ cursor: "pointer" }}
            />{" "}
            Auto Repair
          </div>
          <br />
          {loading ? (
            <SnakeLoader />
          ) : (
            <div>
              <span
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={refresh}
              >
                REFRESH
              </span>
              &nbsp;&nbsp;
              <span
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={claimAll}
              >
                CLAIM ALL
              </span>
            </div>
          )}
          <br />
          <br />
          <table
            style={{
              margin: "0px auto",
              textAlign: "left",
              borderSpacing: "12px 0px",
            }}
          >
            <tbody>
              {trains.map((train) => {
                return <Train train={train} key={"train" + train.train} />;
              })}
            </tbody>
          </table>
          <br />
          <br />
          <br />
          <div
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={clearAll}
          >
            CLEAR ALL TRAINS
          </div>
          <br />
          <div
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={verifyAll}
          >
            VERIFY ALL TRAINS
          </div>
        </div>
      ) : null}
      <br />
      <br />
      <br />
      <br />
      <br />
    </Page>
  );
};

export default ClaimDispatch;
