import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Page from "../Page";
import SnakeLoader from "../SnakeLoader";
import axios from "axios";
import { linkNewWindow } from "../Utilities/LinkUtilities";
import * as AA from "../Utilities/AA";

const LandList = () => {
  const columns = {
    owner: "owner",
    id: "id",
    mineral: "mineral",
    level: "level",
    rarity: "rarity",
    rate: "rate",
  };

  const RARITY_ORDER = [
    "common",
    "uncommon",
    "rare",
    "epic",
    "legendary",
    "mythic",
  ];

  const [landAssets, setLandAssets] = useState([]);
  const [landCount, setLandCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentSort, setCurrentSort] = useState(null);

  useEffect(() => {
    setLandCount(0);
    const getMultiplePages = async (url, limit) => {
      let result = [];
      const more = async (pageNumber, limit) => {
        const response = await axios.get(
          AA.API +
            "atomicassets/v1/" +
            url +
            "&page=" +
            pageNumber +
            "&limit=" +
            limit
        );
        const data = response.data.data;
        result = [...result, ...data];
        setLandCount((old) => old + data.length);
        if (data.length === limit) {
          await more(pageNumber + 1, limit);
        }
      };
      await more(1, limit);
      return result;
    };

    const run = async () => {
      setLoading(true);
      let r1 = await getMultiplePages(
        "assets?collection_name=rplanet&schema_name=lands",
        1000
      );
      let r2 = await getMultiplePages(
        "assets?collection_name=rplanet&schema_name=lands2",
        1000
      );
      const a = r1.concat(r2);
      const final = [];
      a.forEach((land) => {
        final.push({
          id: land.asset_id,
          owner: land.owner,
          mineral: getMineralSymbol(land.name),
          level: land.mutable_data.level ? land.mutable_data.level : 0,
          rarity: land.template.immutable_data.rarity,
          rate: getRate(
            land.mutable_data.level,
            land.template.immutable_data.rarity
          ),
        });
      });
      setLandAssets(final);
      setLoading(false);
    };
    run();
  }, []);

  const getRate = (level, rarity) => {
    let rate = 100;
    if (rarity === "uncommon") rate = 120;
    if (rarity === "rare") rate = 140;
    if (rarity === "epic") rate = 160;
    if (rarity === "legendary") rate = 180;
    if (rarity === "mythic") rate = 200;
    return rate * Math.pow(1.02, level ? level : 0);
  };

  const getMineralSymbol = (mineral) => {
    if (mineral === "Caponium") return "CAPON";
    else if (mineral === "Enefterium") return "ENEFT";
    else if (mineral === "Waxon") return "WAXON";
    else if (mineral === "Wecanite") return "WECAN";
  };

  const renderLandList = () => {
    return (
      <table
        style={{
          margin: "auto",
          textAlign: "center",
          borderSpacing: "20px 8px",
        }}
      >
        <thead>
          <tr>
            <td></td>
            <td
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.id)}
            >
              Asset ID
            </td>
            <td
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.owner)}
            >
              Owner
            </td>
            <td
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.mineral)}
            >
              Mineral
            </td>
            <td
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.rarity)}
            >
              Rarity
            </td>
            <td
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.level)}
            >
              Level
            </td>
            <td
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.rate)}
            >
              Rate
            </td>
          </tr>
        </thead>
        <tbody style={{ textAlign: "right" }}>
          {landAssets.map((land, i) => {
            return (
              <tr key={land.id}>
                <td>{i + 1}</td>
                <td
                  style={{ fontSize: "80%", cursor: "pointer" }}
                  onClick={() =>
                    linkNewWindow(
                      "https://wax.atomichub.io/explorer/asset/" + land.id
                    )
                  }
                >
                  {land.id}
                </td>
                <td style={{ fontSize: "80%" }}>{land.owner}</td>
                <td>{land.mineral}</td>
                <td>{land.rarity}</td>
                <td>{land.level}</td>
                <td>{land.rate.toFixed(1)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const sortTable = (col) => {
    if (currentSort === col) {
      setCurrentSort(null);
      setLandAssets((old) => {
        return [...old].reverse();
      });
    } else {
      setCurrentSort(col);
      setLandAssets((old) => {
        if ([columns.owner, columns.mineral].includes(col)) {
          return [
            ...old.sort((a, b) => {
              const x = a[col] ? a[col] : "";
              const y = b[col] ? b[col] : "";
              return x.localeCompare(y);
            }),
          ];
        } else if ([columns.rarity].includes(col)) {
          return [
            ...old.sort((a, b) => {
              let aa =
                RARITY_ORDER.indexOf(a.rarity) - RARITY_ORDER.indexOf(b.rarity);
              aa = aa > 0 ? 1 : aa < 0 ? -1 : 0;
              return aa;
            }),
          ];
        } else {
          return [
            ...old.sort((a, b) => {
              const x = a[col] ? a[col] : 0;
              const y = b[col] ? b[col] : 0;
              return y - x;
            }),
          ];
        }
      });
    }
  };

  return (
    <Page title="Land List">
      <h2>Land List</h2>
      <Link to="/rplanet/land/leaderboard">Land Leaderboard</Link>
      &nbsp;&nbsp;
      <Link to="/rplanet/land/calculator">Land Calculator</Link>
      <br />
      <br />
      {loading || landAssets.length === 0 ? (
        <div>
          <div>{landCount}</div>
          <SnakeLoader />
        </div>
      ) : (
        renderLandList()
      )}
      <br />
      <br />
    </Page>
  );
};

export default LandList;
