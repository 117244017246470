/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from "react";
import Page from "../Page";
import { rpc } from "../Utilities/RPC";
import {
  NovaComboTableData,
  NovaTimeTableData,
  NovaWalletTableData,
} from "./NovaDisplay";
import SnakeLoader from "../SnakeLoader";
import { NovaContext } from "./NovaContextProvider";
import DeathRallyTotals from "./DeathRallyTotals";
import { useHistory } from "react-router";
import { stringToInt64 } from "../Utilities/Calculate";

const NovaDeathRallyResults = (props) => {
  const novaContext = useContext(NovaContext);
  const history = useHistory();

  const DEATH_ICONS = [
    "🔥",
    "💀",
    "🔫",
    "😵",
    "🔪",
    "☠️",
    "⚰️",
    "⚱️",
    "👻",
    "💣",
    "🗡️",
  ];

  const [rallyEntries, setRallyEntries] = useState([]);
  const [scopes, setScopes] = useState([]);
  const [currentScope, setCurrentScope] = useState(
    props.match.params.scope || undefined
  );
  const [deathIcon, setDeathIcon] = useState(0);
  const [totals, setTotals] = useState({ vehicles: {}, drivers: {} });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentScope) {
      history.push("/nova/death/" + currentScope);
    }
  }, [currentScope, history]);

  useEffect(() => {
    if (scopes.length === 0) {
      const run = async () => {
        setLoading(true);
        const r = await rpc.get_table_by_scope({
          code: "iraces.nova",
          table: "deathrally",
          limit: 1000,
        });

        const sc = r.rows.map((row) => stringToInt64(row.scope)).reverse();
        setScopes(sc);
        if (!currentScope) {
          history.push("/nova/death/" + sc[0]);
        }
      };
      run();
    }
  }, [scopes, currentScope, history]);

  useEffect(() => {
    if (novaContext.templates && scopes) {
      const run = async () => {
        setLoading(true);
        const more = async (scope, lower_bound = "", result = []) => {
          const r = await rpc.get_table_rows({
            code: "iraces.nova",
            table: "deathrally",
            scope: scope,
            json: true,
            limit: 1000,
            lower_bound: lower_bound,
          });
          if (r.rows.length > 0) {
            const lb = r.rows[r.rows.length - 1].player + "1";
            result = result.concat(r.rows);
            return more(scope, lb, result);
          } else {
            return result;
          }
        };

        const cs = currentScope ?? scopes[0];

        const entries = await more(cs);
        const final = [];
        const tots = { drivers: {}, vehicles: {} };

        const add = (num) => {
          return num ? num + 1 : 1;
        };

        for (let i = 0; i < entries.length; i++) {
          const combo = await novaContext.getComboData(entries[i]);

          final.push({
            wallet: entries[i].player,
            position: entries[i].position,
            status: entries[i].status,
            time: entries[i].time,
            ...combo,
          });
          tots.vehicles[combo.vehicleName] = add(
            tots.vehicles[combo.vehicleName]
          );
          tots.drivers[combo.driver1Name] = add(
            tots.drivers[combo.driver1Name]
          );
          tots.drivers[combo.driver2Name] = add(
            tots.drivers[combo.driver2Name]
          );
        }
        final.sort((a, b) => a.position - b.position);
        setRallyEntries(final);
        setTotals(tots);
        setLoading(false);
      };

      run();
    }
  }, [novaContext, currentScope, scopes]);

  const handleIconClick = () => {
    setDeathIcon((old) => {
      if (old === DEATH_ICONS.length - 1) {
        return 0;
      } else {
        return old + 1;
      }
    });
  };

  const renderIcon = (entry) => {
    let icon = "";
    if (entry.status % 4 === 1) {
      icon = "🏆";
    } else if (entry.status % 4 === 2 || entry.position <= 3) {
      icon = "📕";
    } else if (entry.status % 4 === 3) {
      icon = "❎";
    } else if (entry.status % 4 === 0) {
      return (
        <td
          onClick={handleIconClick}
          style={{ textAlign: "center", cursor: "pointer" }}
        >
          {DEATH_ICONS[deathIcon]}
        </td>
      );
    }
    return <td style={{ textAlign: "center" }}>{icon}</td>;
  };

  const renderHeader = (isFinished) => {
    return (
      <tr>
        {isFinished ? <th></th> : null}
        {isFinished ? <th></th> : null}
        <th>RACER</th>
        {isFinished ? <th>TIME</th> : null}
        <th>VEHICLE</th>
        <th>DRIVER 1</th>
        <th>DRIVER 2</th>
      </tr>
    );
  };

  const renderBody = (isFinished) => {
    return rallyEntries.map((entry, i) => {
      return (
        <tr key={entry.wallet}>
          {isFinished ? (
            <td style={{ textAlign: "right" }}>{entry.position}</td>
          ) : null}
          {isFinished ? renderIcon(entry) : null}
          <NovaWalletTableData wallet={entry.wallet} />
          {isFinished ? <NovaTimeTableData time={entry.time} /> : null}
          <NovaComboTableData combo={entry} />
        </tr>
      );
    });
  };

  const renderTable = () => {
    if (rallyEntries) {
      const isFinished = rallyEntries[0] && rallyEntries[0].position > 0;
      return (
        <div>
          <h3>Total racers: {rallyEntries.length}</h3>
          <DeathRallyTotals totals={totals} />
          <br />
          <table
            style={{
              margin: "auto",
              textAlign: "left",
              borderSpacing: "8px 4px",
              fontSize: "80%",
            }}
          >
            <thead>{renderHeader(isFinished)}</thead>
            <tbody>{renderBody(isFinished)}</tbody>
          </table>
        </div>
      );
    }
  };

  const renderDropdown = () => {
    if (scopes.length > 0) {
      const day = 24 * 60 * 60 * 1000;
      return (
        <div>
          <select
            style={{
              textAlign: "center",
              fontFamily: "monospace",
              fontSize: "160%",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            value={currentScope}
            onChange={(e) => setCurrentScope(e.target.value)}
          >
            <option value={scopes[0]}>Upcoming Race</option>
            <option value={scopes[1]}>Previous Race</option>
            {scopes.slice(2).map((scope, i) => {
              return (
                <option key={"option" + scope} value={scope}>
                  {new Date(
                    new Date().setTime(new Date().getTime() - day * 2 - day * i)
                  )
                    .toUTCString()
                    .split(" ")
                    .slice(0, 4)
                    .join(" ")}
                </option>
              );
            })}
          </select>
        </div>
      );
    }
  };

  return (
    <Page title="Nova Rally Death Rally">
      <h2>Death Rally</h2>
      {scopes ? renderDropdown() : null}
      {loading ? <SnakeLoader /> : renderTable()}
      <br />
      <br />
      <br />
      <br />
    </Page>
  );
};

export default NovaDeathRallyResults;
