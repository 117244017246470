import React from "react";
import { Link } from "react-router-dom";
import Page from "../Page";

const NovaRally = () => {
  const headerStyle = {
    width: "170px",
    margin: "24px auto 10px",
    border: "1px solid white",
    padding: "3px",
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
    fontSize: "120%",
  };
  const linkStyle = {
    display: "block",
    padding: "7px",
    fontSize: "120%",
    width: "220px",
    margin: "0px auto",
  };
  return (
    <Page title="Nova Rally">
      <div style={headerStyle}>Snaking Data</div>
      <Link style={linkStyle} to="/nova/leaderboard">
        Leaderboard
      </Link>
      <Link style={linkStyle} to="/nova/snaking">
        Calculator
      </Link>
      <Link style={linkStyle} to="/nova/values">
        Values
      </Link>
      <div style={headerStyle}>Racer Data</div>
      <Link style={linkStyle} to="/nova/racing">
        Leaderboard
      </Link>
      <Link style={linkStyle} to="/nova/top">
        Fastest Wins
      </Link>
      <Link style={linkStyle} to="/nova/racer">
        Racer Stats
      </Link>
      <Link style={linkStyle} to="/nova/pvp">
        PVP Stats
      </Link>
      <div style={headerStyle}>Event Data</div>
      <Link style={linkStyle} to="/nova/recent">
        Recent Races
      </Link>
      <Link style={linkStyle} to="/nova/death">
        Death Rally
      </Link>
      <Link style={linkStyle} to="/nova/tt">
        Time Trials
      </Link>
      <Link style={linkStyle} to="/nova/queue">
        Race Queue
      </Link>
      <div style={headerStyle}>Asset Data</div>
      <Link style={linkStyle} to="/nova/shares">
        SC Shareholders
      </Link>
      <Link style={linkStyle} to="/nova/rpw">
        Races/Podiums/Wins
      </Link>
      <Link style={linkStyle} to="/nova/collection">
        Collection Details
      </Link>
      <Link style={linkStyle} to="/nova/assets">
        Pack Data
      </Link>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </Page>
  );
};

export default NovaRally;
