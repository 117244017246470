import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Page from "../Page";
import {
  calculateDailyDream,
  calculateUnclaimedDream,
  getDreamPools,
} from "../Utilities/Calculate";
import {
  AccountBalances,
  AlcorLinks,
  TokenPriceTable,
} from "../Utilities/Display";
import { linkNewWindow } from "../Utilities/LinkUtilities";
import { rpc } from "../Utilities/RPC";
import StakeableAssets from "../Utilities/StakeableAssets";
import useTokenPrices from "../Utilities/useTokenPrices";
import WalletInput from "../Utilities/WalletInput";

const VirtualDreamsStaking = (props) => {
  const [tokenPrices, getTokenPrice] = useTokenPrices();
  const history = useHistory();
  const [wallet, setWallet] = useState(props.match.params.wallet || "");
  const [stakingPower, setStakingPower] = useState({});
  const [unclaimed, setUnclaimed] = useState(0);
  const [dailyRate, setDailyRate] = useState(0);
  const [balances, setBalances] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (wallet && wallet.trim()) {
      history.push("/dream/staking/" + wallet);
    }
  }, [wallet, history]);

  useEffect(() => {
    if (tokenPrices) {
      const run = async () => {
        setLoading(true);
        setUnclaimed(0);
        setDailyRate(0);
        setBalances([]);
        setStakingPower({});

        if (wallet) {
          let r = await rpc.get_currency_balance("virtualdream", wallet);
          setBalances(r);

          let ur = {};
          const r2 = await rpc.get_table_rows({
            code: "dreamstaking",
            scope: "dreamstaking",
            table: "user",
            json: true,
            limit: 1,
            lower_bound: wallet,
            upper_bound: wallet,
          });
          if (r2.rows && r2.rows[0]) {
            ur = r2.rows[0];
          }

          if (Object.keys(ur).length > 0) {
            const pools = await getDreamPools();
            const uc = calculateUnclaimedDream(ur, pools);
            const daily = calculateDailyDream(ur, pools);

            setUnclaimed(uc);
            setDailyRate(parseInt(daily));

            const final = [];

            const getUserLevelCount = (userRow, poolName, levelKey) => {
              const aa = userRow.data.find(
                (userPool) => userPool.pool === poolName
              );
              if (aa) {
                const bb = aa.inventory.find((ii) => ii.key === levelKey);
                if (bb) {
                  return bb.value;
                }
              }
              return 0;
            };

            pools.forEach((pool) => {
              const levels = Object.fromEntries(
                pool.levels.map((level) => [
                  level.key,
                  {
                    rate: parseInt(level.value),
                    count: getUserLevelCount(ur, pool.pool, level.key),
                  },
                ])
              );
              final.push({
                pool: pool.pool,
                levels: levels,
              });
            });

            setStakingPower(final);
          }
        }
        setLoading(false);
      };
      run();
    }
  }, [wallet, tokenPrices]);

  const renderBalance = () => {
    return (
      <React.Fragment>
        {tokenPrices ? (
          <TokenPriceTable
            coinPriceArray={[
              ["DREAM", getTokenPrice("DREAMWAX")],
              ["WAX", getTokenPrice("WAXUSD")],
            ]}
          />
        ) : null}
        <br />
        <AlcorLinks coinContractArray={[["dream", "virtualdream"]]} />
        <br />
        {wallet ? <AccountBalances balanceArray={balances} /> : null}
        <br />
        {unclaimed ? (
          <div>
            <div style={{ marginBottom: "6px" }}>Unclaimed:</div>
            <div
              style={{
                fontSize: "120%",
                fontWeight: "bold",
              }}
            >
              {unclaimed.toFixed(4)} DREAM
            </div>
          </div>
        ) : null}
        <br />
        {dailyRate ? (
          <div>
            <div style={{ marginBottom: "6px" }}>Staking Power:</div>
            <div
              style={{
                fontSize: "120%",
                fontWeight: "bold",
              }}
            >
              {dailyRate} DREAM/day
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  const renderTables = () => {
    return stakingPower.map((pool) => {
      return (
        <table
          style={{
            margin: "auto",
            borderSpacing: "10px 4px",
            textAlign: "right",
          }}
          key={"pool-" + pool.pool}
        >
          <thead style={{ textAlign: "left" }}>
            <tr>
              <th colSpan="4" style={{ textAlign: "center" }}>
                {pool.pool}
              </th>
            </tr>
            <tr>
              <th>LEVEL</th>
              <th>RATE</th>
              <th>ASSETS</th>
              <th>TOTAL</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(pool.levels).map(([level, data]) => {
              return (
                <tr key={"pool-" + pool.pool + "-" + level}>
                  <td style={{ textAlign: "left" }}>{level}</td>
                  <td>{data.rate}</td>
                  <td>{data.count}</td>
                  <td>{data.rate * data.count}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    });
  };

  return (
    <Page title="VirtualDream Staking">
      <h2>Staking Calculator</h2>
      <div>
        <span
          style={{
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => linkNewWindow("https://bit.ly/StakingRates")}
        >
          Staking Rates
        </span>
        &nbsp;&nbsp;
        <span
          style={{
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() =>
            linkNewWindow(
              "https://wax.bloks.io/account/dreamstaking?loadContract=true&tab=Actions&account=dreamstaking&scope=dreamstaking&limit=100&action=stakeassets"
            )
          }
        >
          Stake Assets
        </span>
        &nbsp;&nbsp;
        <Link to="/claim">Claim $DREAM</Link>
      </div>
      <br />
      <br />
      <div>
        <div style={{ margin: "auto" }}>
          <WalletInput
            wallet={wallet}
            setWallet={setWallet}
            loading={loading}
            buttonText="DREAM ME"
          />
        </div>
      </div>
      <br />
      <br />
      {renderBalance()}
      <br />
      {stakingPower && Object.keys(stakingPower).length > 0
        ? renderTables()
        : null}
      <br />
      {wallet ? (
        <React.Fragment>
          <h4>Asset IDs:</h4>
          <div>
            <StakeableAssets
              key={"virtualdreamassets" + wallet}
              collection="virtualdream"
              wallet={wallet}
              noTitle={true}
            />
          </div>
        </React.Fragment>
      ) : null}
      <br />
      <br />
    </Page>
  );
};

export default VirtualDreamsStaking;
