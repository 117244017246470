import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import Page from "../Page";
import SnakeLoader from "../SnakeLoader";
import WalletInput from "../Utilities/WalletInput";
import { calculateHourlyRates } from "../Utilities/Calculate";
import { rpc } from "../Utilities/RPC";
import useTokenPrices from "../Utilities/useTokenPrices";
import {
  AccountBalances,
  AlcorLinks,
  TokenPriceTable,
} from "../Utilities/Display";

const NovaSnakingPower = (props) => {
  const [tokenPrices, getTokenPrice] = useTokenPrices();
  const history = useHistory();
  const [wallet, setWallet] = useState(props.match.params.wallet || "");
  const [actualSnaking, setActualSnaking] = useState({});
  const [fuelUsage, setFuelUsage] = useState({});
  const [balances, setBalances] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const run = async () => {
      if (wallet && wallet.trim()) {
        setLoading(true);
      }

      setActualSnaking({});
      setBalances([]);
      setFuelUsage({});

      if (wallet && wallet.trim() && tokenPrices) {
        let r = await rpc.get_currency_balance("novarallytok", wallet);
        setBalances(r);

        const r1 = await rpc.get_table_rows({
          json: true,
          code: "novarallysnk",
          scope: "novarallysnk",
          table: "staking",
          lower_bound: "",
          upper_bound: "",
          index_position: 1,
          key_type: "i64",
          limit: 100,
          reverse: false,
          show_payer: false,
        });

        const poolTable = r1.rows;
        const r2 = await rpc.get_table_rows({
          json: true,
          code: "novarallysnk",
          scope: "novarallysnk",
          table: "user",
          lower_bound: wallet,
          upper_bound: wallet,
          index_position: 1,
          key_type: "i64",
          limit: 1,
          reverse: false,
          show_payer: false,
        });

        if (r2.rows[0]) {
          const userData = calculateHourlyRates(r2.rows[0], poolTable);
          const result = {
            snakoil: 0,
            snakgas: 0,
            snakpow: 0,
            snakven: 0,
            boost: 0,
            wax: 0,
            usd: 0,
          };
          userData.data.forEach((pool) => {
            const total = parseInt(pool.hourlyTotal);
            if (pool.pool === "rookie") {
              result.snakoil += total;
            } else if (pool.pool === "intermediate") {
              result.snakgas += total;
            } else if (pool.pool === "veteran") {
              result.snakpow += total;
            } else if (pool.pool === "master") {
              result.snakven += total;
            } else {
              result.boost += total;
            }
          });
          result.wax =
            result.snakoil * getTokenPrice("SNAKOILWAX") +
            result.snakgas * getTokenPrice("SNAKGASWAX") +
            result.snakpow * getTokenPrice("SNAKPOWWAX") +
            result.snakven * getTokenPrice("SNAKVENWAX") +
            result.boost * getTokenPrice("BOOSTWAX");
          result.usd = result.wax * getTokenPrice("WAXUSD");

          setActualSnaking(result);
        }

        const r3 = await rpc.get_table_rows({
          json: true,
          code: "novarallyapp",
          scope: wallet,
          table: "playersinfo",
        });

        if (r3.rows[0]) {
          setFuelUsage(r3.rows[0].fuel_usage);
        }

        setLoading(false);
      }
    };
    run();
  }, [wallet, history, tokenPrices, getTokenPrice]);

  const renderBalance = () => {
    return tokenPrices ? (
      <React.Fragment>
        <TokenPriceTable
          coinPriceArray={[
            ["SNAKOIL", getTokenPrice("SNAKOILWAX")],
            ["SNAKGAS", getTokenPrice("SNAKGASWAX")],
            ["SNAKPOW", getTokenPrice("SNAKPOWWAX")],
            ["SNAKVEN", getTokenPrice("SNAKVENWAX")],
            ["BOOST", getTokenPrice("BOOSTWAX")],
            ["WAX", getTokenPrice("WAXUSD")],
          ]}
        />
        <br />
        <AlcorLinks
          coinContractArray={[
            ["snakoil", "novarallytok"],
            ["snakgas", "novarallytok"],
            ["snakpow", "novarallytok"],
            ["snakven", "novarallytok"],
            ["boost", "novarallytok"],
          ]}
        />
        <br />
        {wallet ? <AccountBalances balanceArray={balances} /> : null}
      </React.Fragment>
    ) : null;
  };

  const renderSnakingRow = (coin) => {
    const h = actualSnaking[coin];
    const d = actualSnaking[coin] * 24;
    const w = actualSnaking[coin] * 24 * 7;
    const m = actualSnaking[coin] * 2 * 365;
    const y = actualSnaking[coin] * 24 * 365;

    const coinToString = (n) => {
      if (coin === "wax" || coin === "usd") {
        return n.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      } else {
        return n.toLocaleString();
      }
    };

    return (
      <tr>
        <td
          style={{
            textAlign: "right",
            fontSize: "120%",
            fontWeight: "bold",
          }}
        >
          {coin.toUpperCase()}
        </td>
        <td>{coinToString(h)}</td>
        <td>{coinToString(d)}</td>
        <td>{coinToString(w)}</td>
        <td>{coinToString(m)}</td>
        <td>{coinToString(y)}</td>
      </tr>
    );
  };

  const renderSnakingPower = () => {
    return (
      <div>
        <div>
          {Object.keys(actualSnaking).length === 0 ? (
            wallet ? (
              <h2>
                NO SNAKING DATA <br />
                AVAILABLE FOR THIS USER
              </h2>
            ) : null
          ) : (
            <React.Fragment>
              <div>
                <h2 style={{ marginBottom: "8px" }}>SNAKING POWER:</h2>
                <table
                  style={{
                    margin: "auto",
                    borderSpacing: "10px 4px",
                    fontSize: "80%",
                  }}
                >
                  <thead>
                    <tr style={{ textAlign: "center" }}>
                      <th></th>
                      <th>hour</th>
                      <th>day</th>
                      <th>week</th>
                      <th>month</th>
                      <th>year</th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: "right" }}>
                    {renderSnakingRow("snakoil")}
                    {renderSnakingRow("snakgas")}
                    {renderSnakingRow("snakpow")}
                    {renderSnakingRow("snakven")}
                    {renderSnakingRow("boost")}
                    {renderSnakingRow("wax")}
                    {renderSnakingRow("usd")}
                  </tbody>
                </table>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  };

  const handleSetWallet = (w) => {
    history.push("/nova/snaking/" + w);
    setWallet(w);
  };

  return (
    <Page title="Snaking Power Calculator">
      <div>
        <div style={{ margin: "auto" }}>
          <h2>Snaking Calculator</h2>
          <Link to="/nova/leaderboard">Snaking Leaderboard</Link>
          &nbsp;&nbsp;
          <Link to="/nova/values">Snaking Values</Link>
          <br />
          <br />
          <h5 style={{ width: "320px", margin: "auto" }}>
            * does not include extra snaking power from podium finishes or boost
            from Dark Country lands *
          </h5>
          <br />
          <WalletInput
            wallet={props.match.params.wallet || ""}
            setWallet={handleSetWallet}
            buttonText="SNAKE ME"
            loading={false}
          />
          <br />
          {renderBalance()}
          {loading ? <SnakeLoader /> : <div>{renderSnakingPower()}</div>}
          <br />
        </div>
      </div>
      {Object.keys(fuelUsage).length > 0 && wallet ? (
        <div>
          <div style={{ marginBottom: "6px", fontWeight: "bold" }}>
            Fuel Usage:
          </div>
          <table style={{ margin: "auto", borderSpacing: "10px 4px" }}>
            <tbody>
              {Object.entries(fuelUsage)
                .filter((x) => !x[1].startsWith("0"))
                .map(([token, balance]) => {
                  return (
                    <tr
                      key={"balance" + token}
                      style={{
                        fontSize: "100%",
                        textAlign: "right",
                      }}
                    >
                      <td>
                        {parseInt(balance.split(" ")[0]).toLocaleString()}{" "}
                      </td>
                      <td style={{ textAlign: "left" }}>
                        {balance.split(" ")[1]}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      ) : null}
      <br />
      <br />
    </Page>
  );
};

export default NovaSnakingPower;
