import React, { useEffect, useState } from "react";
import SnakeLoader from "../SnakeLoader";
import axios from "axios";
import * as AA from "../Utilities/AA";

const STAKEABLE_COLLECTIONS = {
  novarallywax: { title: "Nova Rally", schemas: ["drivers", "vehicles"] },
  olivelandnft: {
    title: "Oliveland",
    schemas: ["olivetrees", "olivelandpre"],
  },
  promorplanet: {
    title: "Promo RPlanet",
    schemas: ["fanart", "discordhero"],
  },
  virtualdream: {
    title: "VirtualDream",
    schemas: [
      "1of1s",
      "blend.dreams",
      "deepthoughts",
      "dreamcoins",
      "dreams",
      "dreams2",
      "dreams3",
      "dreams4",
      "dt.pack.s1",
      "dt.pack.s2",
      "memberships",
      "mini.series",
      "pack.series1",
      "pack.series2",
      "pack.series3",
      "pack.series4",
      "pack.series5",
      "tokens",
      "unique.dt",
      "uniquedreams",
      "vipexclusive",
    ],
  },
  breederszone: {
    title: "Breeders Zone",
    schemas: ["spirits", "promo"],
  },
  galaktikanft: {
    title: "Galaktika",
    schemas: ["cards", "passes", "promo"],
  },
  kickback: {
    title: "kickback",
    schemas: ["genesis"],
  },
};

const StakeableAssets = (props) => {
  const [assets, setAssets] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    let pn = 1;
    let sd = [];
    let next = () => {
      axios
        .get(
          AA.API +
            "atomicassets/v1/assets?owner=" +
            props.wallet +
            "&page=" +
            pn +
            "&limit=1000&order=desc&sort=transferred&collection_name=" +
            props.collection
        )
        .then((r) => {
          sd = [...sd, ...r.data.data];
          if (r.data.data.length === 1000) {
            pn++;
            next();
          } else {
            pn = 1;
            const assetIds = [];
            sd.forEach((a) => {
              if (
                STAKEABLE_COLLECTIONS[props.collection].schemas.includes(
                  a.schema.schema_name
                )
              ) {
                if (props.collection === "kickback") {
                  if (a.name.startsWith("Steve")) assetIds.push(a.asset_id);
                } else {
                  assetIds.push(a.asset_id);
                }
              }
            });
            setAssets(assetIds);
            setLoading(false);
          }
        });
    };
    next();
  }, [props.wallet, props.collection]);

  const handleFocus = (event) => event.target.select();

  const renderTextareas = (assets) => {
    let all = [];
    let chunk = [];
    const _assets = [...assets];
    while (_assets.length > 0) {
      chunk = _assets.splice(0, 500);
      all.push(chunk);
    }
    return <div>{all.map((x, i) => renderTextarea(x, i))}</div>;
  };

  const renderTextarea = (a, i) => {
    return (
      <div
        key={"textarea-" + i}
        style={{
          border: "4px solid transparent",
          display: "inline-block",
        }}
      >
        <textarea
          readOnly={true}
          onClick={() => {
            navigator.clipboard.writeText(
              JSON.stringify(a).replace(/['"]+/g, "")
            );
          }}
          onFocus={handleFocus}
          style={{
            width: "320px",
            height: "320px",
            resize: "none",
            cursor: "pointer",
          }}
          value={JSON.stringify(a).replace(/['"]+/g, "")}
        />
      </div>
    );
  };

  const render = () => {
    return props.wallet ? (
      <div>
        {props.noTitle ? null : (
          <h4>{STAKEABLE_COLLECTIONS[props.collection].title}</h4>
        )}
        <div>
          {assets && assets.length > 0 ? (
            renderTextareas(assets)
          ) : loading ? (
            <SnakeLoader />
          ) : (
            "No assets"
          )}
        </div>
      </div>
    ) : null;
  };

  return render();
};

export default StakeableAssets;

export { STAKEABLE_COLLECTIONS as SNAKEABLE_COLLECTIONS };
