const RGB2Color = (r, g, b) => {
  return (
    "rgb(" + Math.round(r) + "," + Math.round(g) + "," + Math.round(b) + ")"
  );
};

const makeColorGradient = (
  frequency1,
  frequency2,
  frequency3,
  phase1,
  phase2,
  phase3,
  center,
  width,
  len
) => {
  if (center === undefined) center = 64;
  if (width === undefined) width = 63;
  if (len === undefined) len = 5;

  let a = [];
  for (var i = 0; i < len; ++i) {
    var red = Math.sin(frequency1 * i + phase1) * width + center;
    var grn = Math.sin(frequency2 * i + phase2) * width + center;
    var blu = Math.sin(frequency3 * i + phase3) * width + center;
    a.push(RGB2Color(red, grn, blu));
  }
  return a;
};

export { makeColorGradient };
