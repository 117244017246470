import React, { useEffect, useState } from "react";
import SnakeLoader from "../SnakeLoader";
import Page from "../Page";
import firebase from "../Firebase";
import {
  NovaPosition,
  NovaTimeTableData,
  NovaWalletTableData,
} from "./NovaDisplay";

const NovaRacingLeaderboard = () => {
  // const db = firebase.firestore();
  // if (typeof window === "undefined" || !window["_init"]) {
  //   db.useEmulator("localhost", 8080);
  //   if (typeof window !== "undefined") {
  //     window["_init"] = true;
  //   }
  // }

  const COLUMNS = {
    wallet: "wallet",
    fastestTime: "fastestTime",
    totalRaces: "totalRaces",
    podPerc: "podPerc",
    podiums: "podiums",
    winPerc: "winPerc",
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
  };

  const LEAGUES = {
    all: "all",
    rookie: "rookie",
    intermediate: "intermediate",
    veteran: "veteran",
    master: "master",
  };

  const updateNovaLeaderboard = firebase
    .functions()
    .httpsCallable("updateNovaLeaderboard");

  const [leaders, setLeaders] = useState([]);
  const [currentSort, setCurrentSort] = useState(null);
  const [league, setLeague] = useState(LEAGUES.all);
  const [minRaces, setMinRaces] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const run = async () => {
      setLoading(true);

      const fs = await firebase
        .firestore()
        .collection("nova-leaderboard")
        .get();

      const final = fs.docs.map((doc) => {
        return {
          wallet: doc.id,
          leagues: doc.data().leagues,
        };
      });

      final.forEach((x) => {
        for (const [k, v] of Object.entries(x.leagues)) {
          x.leagues[k].totalRaces = x.leagues[k].totalRaces ?? 0;
          x.leagues[k].podiums = x.leagues[k].podiums ?? 0;
          x.leagues[k].podPerc = x.leagues[k].podPerc ?? 0;
          x.leagues[k].winPerc = x.leagues[k].winPerc ?? 0;
          for (const [kk, vv] of Object.entries(v)) {
            if (kk !== "fastestTime") {
              x.leagues[k].totalRaces += vv;
              if (kk === "1" || kk === "2" || kk === "3") {
                x.leagues[k].podiums += vv;
              }
            }
          }
          const pp = x.leagues[k].podiums / x.leagues[k].totalRaces;
          const wp = x.leagues[k]["1"] / x.leagues[k].totalRaces;
          x.leagues[k].podPerc = isNaN(pp) ? 0 : pp;
          x.leagues[k].winPerc = isNaN(wp) ? 0 : wp;
        }
      });

      const mySort = (num) => {
        return (a, b) => {
          const numa = a.leagues.all[num] ?? 0;
          const numb = b.leagues.all[num] ?? 0;
          return numb - numa;
        };
      };
      final.sort(mySort("8"));
      final.sort(mySort("7"));
      final.sort(mySort("6"));
      final.sort(mySort("5"));
      final.sort(mySort("4"));
      final.sort(mySort("3"));
      final.sort(mySort("2"));
      final.sort(mySort("1"));

      setLeaders(final);
      setLoading(false);
    };

    run();
  }, []);

  const sortTable = (col) => {
    if (currentSort === col) {
      setCurrentSort(null);
      setLeaders((old) => {
        return [...old].reverse();
      });
    } else {
      setCurrentSort(col);
      setLeaders((old) => {
        if (col === COLUMNS.wallet) {
          return [...old.sort((a, b) => a[col].localeCompare(b[col]))];
        } else {
          return [
            ...old.sort((a, b) => {
              const lga = a.leagues[league];
              const lgb = b.leagues[league];
              const x = lga ? lga[col] ?? 0 : 0;
              const y = lgb ? lgb[col] ?? 0 : 0;
              if ([COLUMNS.fastestTime].includes(col)) {
                return x - y;
              } else {
                return y - x;
              }
            }),
          ];
        }
      });
    }
  };

  const renderHeaderColumn = (col, title, left) => {
    return (
      <th
        style={{ cursor: "pointer", textAlign: left ? "left" : "center" }}
        onClick={() => sortTable(col)}
      >
        {title}
      </th>
    );
  };

  const renderLeagueRadioButton = (lg) => {
    return (
      <nobr>
        <label style={{ cursor: "pointer", margin: "6px" }}>
          <input
            type="radio"
            name="leaguefilter"
            value={lg}
            checked={league === lg}
            onChange={() => {
              setCurrentSort(null);
              setLeague(lg);
            }}
          />
          {lg}
        </label>
      </nobr>
    );
  };

  const refreshRacer = async (wallet) => {
    const p = await updateNovaLeaderboard({ wallet: wallet });
    setLeaders((old) => {
      const ii = old.findIndex((y) => y.wallet === wallet);
      const x = { wallet: p.data.wallet, leagues: p.data.data.leagues };
      for (const [k, v] of Object.entries(x.leagues)) {
        x.leagues[k].totalRaces = 0;
        x.leagues[k].podiums = 0;
        x.leagues[k].podPerc = 0;
        x.leagues[k].winPerc = 0;
        for (const [kk, vv] of Object.entries(v)) {
          if (
            kk !== "fastestTime" &&
            kk !== "podiums" &&
            kk !== "podPerc" &&
            kk !== "totalRaces" &&
            kk !== "winPerc"
          ) {
            x.leagues[k].totalRaces += vv;
            if (kk === "1" || kk === "2" || kk === "3") {
              x.leagues[k].podiums += vv;
            }
          }
        }
        const pp = x.leagues[k].podiums / x.leagues[k].totalRaces;
        const wp = x.leagues[k]["1"] / x.leagues[k].totalRaces;
        x.leagues[k].podPerc = isNaN(pp) ? 0 : pp;
        x.leagues[k].winPerc = isNaN(wp) ? 0 : wp;
      }
      old[ii] = x;
      return [...old];
    });
  };

  const renderLeaderboard = () => {
    return (
      <div>
        {renderLeagueRadioButton(LEAGUES.all)}
        {renderLeagueRadioButton(LEAGUES.rookie)}
        {renderLeagueRadioButton(LEAGUES.intermediate)}
        {renderLeagueRadioButton(LEAGUES.veteran)}
        {renderLeagueRadioButton(LEAGUES.master)}
        <br />
        <br />
        <div>
          minimum races:&nbsp;
          <select
            value={minRaces}
            onChange={(e) => setMinRaces(e.target.value)}
          >
            <option value={0}>0</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
            <option value={300}>300</option>
            <option value={400}>400</option>
            <option value={500}>500</option>
            <option value={1000}>1000</option>
            <option value={1500}>1500</option>
            <option value={2000}>2000</option>
            <option value={2500}>2500</option>
            <option value={3000}>3000</option>
            <option value={4000}>4000</option>
            <option value={5000}>5000</option>
          </select>
        </div>
        <br />
        <table
          style={{
            margin: "auto",
            textAlign: "center",
            borderSpacing: "12px 4px",
            fontSize: "80%",
          }}
        >
          <thead>
            <tr>
              <th></th>
              <th></th>
              {renderHeaderColumn(COLUMNS.wallet, "RACER", true)}
              {renderHeaderColumn(COLUMNS.fastestTime, "FASTEST", true)}
              {renderHeaderColumn(COLUMNS.totalRaces, "RACES")}
              {renderHeaderColumn(COLUMNS.podPerc, "POD %")}
              {renderHeaderColumn(COLUMNS.podiums, "POD")}
              {renderHeaderColumn(COLUMNS.winPerc, "WIN %")}
              {renderHeaderColumn(COLUMNS["1"], <NovaPosition position="1" />)}
              {renderHeaderColumn(COLUMNS["2"], <NovaPosition position="2" />)}
              {renderHeaderColumn(COLUMNS["3"], <NovaPosition position="3" />)}
              {renderHeaderColumn(COLUMNS["4"], <NovaPosition position="4" />)}
              {renderHeaderColumn(COLUMNS["5"], <NovaPosition position="5" />)}
              {renderHeaderColumn(COLUMNS["6"], <NovaPosition position="6" />)}
              {renderHeaderColumn(COLUMNS["7"], <NovaPosition position="7" />)}
              {renderHeaderColumn(COLUMNS["8"], <NovaPosition position="8" />)}
            </tr>
          </thead>
          <tbody>
            {leaders
              .filter((leader) => leader.leagues[league])
              .filter(
                (leader) =>
                  leader.leagues[league].totalRaces >= minRaces &&
                  leader.leagues[league].totalRaces > 0
              )
              .map((leader, i) => {
                const lg = leader.leagues[league];
                return (
                  <tr key={leader.wallet}>
                    <td>
                      <span
                        onClick={() => refreshRacer(leader.wallet)}
                        style={{ cursor: "pointer", fontWeight: "bold" }}
                      >
                        &#x21bb;
                      </span>
                    </td>
                    <td>{i + 1}</td>
                    <NovaWalletTableData wallet={leader.wallet} />
                    <NovaTimeTableData time={lg.fastestTime} />
                    <td>{lg.totalRaces}</td>
                    <td>{(100 * lg.podPerc).toFixed(2)}%</td>
                    <td>{lg.podiums}</td>
                    <td>{(100 * lg.winPerc).toFixed(2)}%</td>
                    <td>{lg["1"]}</td>
                    <td>{lg["2"]}</td>
                    <td>{lg["3"]}</td>
                    <td>{lg["4"]}</td>
                    <td>{lg["5"]}</td>
                    <td>{lg["6"]}</td>
                    <td>{lg["7"]}</td>
                    <td>{lg["8"]}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <Page title="Nova Rally Racing Leaderboard">
      <h2>Racing Leaderboard</h2>
      {loading ? <SnakeLoader /> : renderLeaderboard()}
      <br />
      <br />
      <br />
    </Page>
  );
};

export default NovaRacingLeaderboard;
