import React from "react";
import { Link } from "react-router-dom";
import Page from "../Page";

const Oliveland = () => {
  return (
    <Page title="Oliveland">
      <h2>
        <Link to="/olive/staking">Staking Calculator + Asset IDs</Link>
        <br />
        <br />
        <Link to="/olive/leaderboard">Staking Leaderboard</Link>
        <br />
        <br />
        <Link to="/olive/values">Staking Values</Link>
        <br />
        <br />
      </h2>
    </Page>
  );
};

export default Oliveland;
