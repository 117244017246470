import { useCallback, useEffect, useState } from "react";
import { rpc } from "../Utilities/RPC";
import axios from "axios";
import { linkNewWindow } from "../Utilities/LinkUtilities";
import SnakeLoader from "../SnakeLoader";
import {
  calculateHourlyRates,
  calculateUnclaimedDream,
  calculateUnclaimedToc,
  getDreamPools,
  getTocPools,
} from "../Utilities/Calculate";
// import * as AA from "../Utilities/AA";

const ClaimSection = ({
  activeUser,
  sectionTitle,
  website,
  tokenContract,
  tokenNames,
  decimals,
  actions,
  buttonText,
  alternateClaimMessage,
  unclaimed,
  loading,
}) => {
  const [balances, setBalances] = useState([]);
  const [txnMessage, setTxnMessage] = useState("");
  const [claimMessage, setClaimMessage] = useState([]);
  const [wasClaimed, setWasClaimed] = useState(false);

  const setBalancesHelper = useCallback(async () => {
    if (tokenNames) {
      const result = [];
      for (let i = 0; i < tokenNames.length; i++) {
        const r = await rpc.get_currency_balance(
          tokenContract,
          activeUser.accountName,
          tokenNames[i]
        );
        result.push(...r);
      }
      return result;
    } else {
      return await rpc.get_currency_balance(
        tokenContract,
        activeUser.accountName
      );
    }
  }, [activeUser, tokenContract, tokenNames]);

  const processResponse = async (processedTxn) => {
    const d = decimals ?? 0;
    const standardTransfer = (txn) => {
      let result = [];
      txn.action_traces.forEach((action_trace) => {
        const inline_traces = action_trace.inline_traces;
        if (inline_traces && inline_traces.length > 0) {
          inline_traces.forEach((inline_trace) => {
            if (inline_trace.act.name === "transfer") {
              const q = inline_trace.act.data.quantity;
              if (q && q.split(" ").length === 2) {
                const p =
                  toFixedLocaleString(parseFloat(q.split(" ")[0]), d) +
                  " " +
                  q.split(" ")[1];
                result.push(p);
              }
            }
          });
        }
      });
      return result;
    };

    console.log(processedTxn);
    setTxnMessage("");
    setWasClaimed(true);
    const b = await setBalancesHelper();
    console.log(sectionTitle, b);
    setBalances(b);
    try {
      if (alternateClaimMessage) {
        setClaimMessage([alternateClaimMessage]);
      } else {
        setClaimMessage(standardTransfer(processedTxn));
      }
    } catch (e) {
      setClaimMessage(["Problem displaying claimed tokens."]);
    }
  };

  const processError = (e) => {
    console.log(e.message);
    setTxnMessage("FAILED!");
    setClaimMessage([e.message]);
  };

  const claimFunction = async () => {
    try {
      const r = await activeUser.signTransaction(
        { actions: actions },
        {
          blocksBehind: 3,
          expireSeconds: 30,
        }
      );
      processResponse(r.transaction.processed);
    } catch (e) {
      processError(e);
    }
  };

  useEffect(() => {
    if (!loading) {
      const run = async () => {
        setTxnMessage("");
        setClaimMessage([]);
        setWasClaimed(false);
        let balanceSet = false;
        if (activeUser) {
          const b = await setBalancesHelper();
          setBalances(b);
          balanceSet = true;
        }
        if (!balanceSet) {
          setBalances([]);
        }
      };
      run();
    }
  }, [activeUser, setBalancesHelper, loading]);

  const renderSection = () => {
    const txn = txnMessage ? <div>{txnMessage}</div> : null;
    const claim =
      claimMessage.length > 0 ? (
        <div style={{ fontSize: "90%" }}>
          {claimMessage.map((x, i) => {
            return <div key={"claimed" + i}>{x}</div>;
          })}
        </div>
      ) : null;
    const uc =
      unclaimed &&
      Array.isArray(unclaimed) &&
      unclaimed.length > 0 &&
      txnMessage === "" &&
      claimMessage.length === 0 ? (
        <div style={{ fontSize: "90%" }}>
          {unclaimed.map((x) => {
            return <div key={sectionTitle + "unclaimed" + x}>{x}</div>;
          })}
        </div>
      ) : (
        ""
      );
    return (
      <table
        style={{
          margin: "auto",
          padding: "10px",
          textAlign: "left",
          width: "400px",
        }}
      >
        <tbody>
          <tr>
            <td
              style={{
                textAlign: "left",
                verticalAlign: "top",
                fontSize: "120%",
              }}
            >
              <SectionTitle sectionTitle={sectionTitle} website={website} />
              <div
                style={{
                  textAlign: "right",
                  verticalAlign: "top",
                  fontSize: "70%",
                }}
              >
                <table
                  style={{
                    width: "100%",
                    paddingLeft: "0px",
                    paddingRight: "16px",
                  }}
                >
                  <tbody>
                    <SectionBalances balances={balances} loading={loading} />
                  </tbody>
                </table>
              </div>
            </td>
            <td
              rowSpan="2"
              style={{
                width: "160px",
                verticalAlign: "top",
              }}
            >
              <div>
                <ClaimButton
                  claimFunction={claimFunction}
                  buttonText={buttonText}
                  wasClaimed={wasClaimed}
                  canClaim={actions && actions.length > 0}
                  loading={loading}
                />
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "80%",
                  paddingTop: "6px",
                }}
              >
                {uc}
                {txn}
                {claim}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  return renderSection();
};

const SectionTitle = ({ sectionTitle, website }) => {
  return (
    <div
      style={{
        textDecoration: "underline",
        cursor: "pointer",
        fontFamily: "sans-serif",
      }}
      onClick={() => linkNewWindow(website)}
    >
      {sectionTitle}
    </div>
  );
};

const SectionBalances = ({ balances, loading }) => {
  let bal = null;
  let noBalance = true;
  if (balances && balances.length > 0) {
    const b = [...balances].reverse();
    bal = b.map((bl, i) => {
      const tokenName = bl.split(" ")[1];
      const tokenNumber = bl.split(" ")[0];
      if (tokenNumber > 0) {
        noBalance = false;
        return (
          <tr key={tokenName + bl + i}>
            <td style={{ textAlign: "left" }}>{tokenName}:</td>
            <td style={{ textAlign: "right" }}>
              {parseInt(tokenNumber).toLocaleString()}
            </td>
          </tr>
        );
      } else {
        return null;
      }
    });
  }
  if (noBalance || !balances || balances.length === 0) {
    bal = (
      <tr>
        <td
          colSpan="2"
          style={{ opacity: "50%", fontStyle: "italic", textAlign: "left" }}
        >
          {loading ? "loading" : "empty"} balance
        </td>
      </tr>
    );
  }
  return bal;
};

const ClaimButton = ({
  claimFunction,
  buttonText,
  wasClaimed,
  canClaim,
  loading,
}) => {
  const btn = wasClaimed ? (
    <button
      disabled={true}
      style={{
        fontWeight: "bold",
        fontSize: "120%",
        width: "160px",
      }}
    >
      Claimed!
    </button>
  ) : canClaim ? (
    <button
      style={{
        cursor: "pointer",
        fontWeight: "bold",
        fontSize: "120%",
        width: "160px",
      }}
      onClick={claimFunction}
    >
      {buttonText}
    </button>
  ) : (
    <div
      style={{
        textAlign: "center",
        fontSize: "80%",
        padding: "8px",
        border: "1px solid white",
      }}
    >
      nothing to claim
    </div>
  );
  if (loading) {
    return <SnakeLoader noMargin={true} />;
  } else {
    return btn;
  }
};

const createAction = (contract, name, user, data) => {
  return {
    account: contract,
    name: name,
    authorization: [
      {
        actor: user,
        permission: "active",
      },
    ],
    data: data,
  };
};

const toFixedLocaleString = (number, decimals) => {
  return number.toLocaleString(undefined, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
};

// const ClaimRPlanetAether = ({ activeUser }) => {
//   const [actions, setActions] = useState([]);
//   const [loading, setLoading] = useState(1);
//   const [unclaimed, setUnclaimed] = useState([]);

//   useEffect(() => {
//     const COLLECTIONS = [
//       "s.rplanet",
//       "alien.worlds",
//       "anyo.b1",
//       "bitcoinelite",
//       "bitcoinorign",
//       "bitverse",
//       "cardinalland",
//       "cartombs.r2",
//       "collectwhale",
//       "crypto5tache",
//       "darkcountryh",
//       "flegendsnfts",
//       "gpk.topps",
//       "graffk1ngsuk",
//       "hodlgod",
//       "hodlmoonboys",
//       "ilovekolobok",
//       "kennbosakgif",
//       "kogsofficial",
//       "metaforce",
//       "monsters.r2",
//       "mteorawaxnft",
//       "mutants.r2",
//       "officialhero",
//       "pumpdumpwars",
//       "robotech",
//       "stf.capcom",
//       "tribalbooks",
//       "ultrahorrors",
//       "ultrarare",
//       "upland.cards",
//       "upliftart",
//       "upliftworld2",
//     ];

//     const getUnclaimed = async (collection, wallet) => {
//       const r = await rpc.get_table_rows({
//         code: "s.rplanet",
//         scope: collection,
//         table: "accounts",
//         json: true,
//         limit: 1,
//         lower_bound: wallet,
//         upper_bound: wallet,
//       });
//       if (r.rows && r.rows[0] && parseFloat(r.rows[0].collected) > 0) {
//         return parseFloat(r.rows[0].collected);
//       } else {
//         return 0;
//       }
//     };

//     const run = async () => {
//       setLoading(true);
//       setUnclaimed([]);
//       let total = 0;
//       for (let i = 0; i < COLLECTIONS.length; i++) {
//         const uc = await getUnclaimed(COLLECTIONS[i], activeUser.accountName);
//         total += uc;
//       }
//       if (total > 0) {
//         setUnclaimed([
//           toFixedLocaleString(Math.round(total * 1000) / 1000, 0) + " AETHER",
//         ]);
//         setActions([
//           createAction("s.rplanet", "claim", activeUser.accountName, {
//             to: activeUser.accountName,
//           }),
//         ]);
//       }
//       setLoading(false);
//     };

//     run();
//   }, [activeUser]);

//   return (
//     <ClaimSection
//       activeUser={activeUser}
//       actions={actions}
//       sectionTitle={"RPlanet Staked Assets"}
//       buttonText={"Claim Aether"}
//       website={"https://rplanet.io/lab"}
//       tokenContract={"e.rplanet"}
//       tokenNames={["AETHER"]}
//       unclaimed={unclaimed}
//       loading={loading}
//     />
//   );
// };

// const ClaimRPlanetMinerals = ({ activeUser }) => {
//   const [actions, setActions] = useState([]);
//   const [unclaimed, setUnclaimed] = useState([]);
//   const [loading, setLoading] = useState(1);

//   useEffect(() => {
//     const calculateUnclaimedMinerals = (assets) => {
//       const uc = {
//         CAPON: 0,
//         ENEFT: 0,
//         WAXON: 0,
//         WECAN: 0,
//       };

//       const calc = (asset) => {
//         const level = asset.mutable_data.level ?? 0;
//         const lastClaim =
//           asset.mutable_data.last_claim ?? asset.minted_at_time / 1000;
//         let multiplier = 1;
//         if (asset.data.rarity === "uncommon") {
//           multiplier = 1.2;
//         } else if (asset.data.rarity === "rare") {
//           multiplier = 1.4;
//         } else if (asset.data.rarity === "epic") {
//           multiplier = 1.6;
//         } else if (asset.data.rarity === "legendary") {
//           multiplier = 1.8;
//         } else if (asset.data.rarity === "mythic") {
//           multiplier = 2;
//         }
//         const now = Date.now() / 1000;
//         const hoursUnclaimed = (now - lastClaim) / 3600;
//         const uc = hoursUnclaimed * multiplier * Math.pow(1.02, level) * 100;
//         return uc;
//       };

//       assets.forEach((asset) => {
//         if (asset.name === "Caponium") {
//           uc.CAPON += calc(asset);
//         } else if (asset.name === "Enefterium") {
//           uc.ENEFT += calc(asset);
//         } else if (asset.name === "Waxon") {
//           uc.WAXON += calc(asset);
//         } else if (asset.name === "Wecanite") {
//           uc.WECAN += calc(asset);
//         }
//       });

//       return uc;
//     };

//     const run = async () => {
//       setLoading(true);
//       setUnclaimed([]);
//       const r = await axios.get(
//         AA.API +
//           "atomicassets/v1/assets?collection_name=rplanet&owner=" +
//           activeUser.accountName +
//           "&page=1&limit=1000&order=desc&sort=asset_id"
//       );
//       const assets = r.data.data ?? [];
//       const landIds = assets
//         .filter(
//           (x) =>
//             x.schema.schema_name === "lands" ||
//             x.schema.schema_name === "lands2"
//         )
//         .map((x) => x.asset_id);
//       if (landIds.length > 0) {
//         const uc = calculateUnclaimedMinerals(assets);
//         const ucArray = Object.entries(uc).map(
//           ([mineral, amount]) =>
//             toFixedLocaleString(Math.round(amount * 1000) / 1000, 0) +
//             " " +
//             mineral
//         );
//         setUnclaimed(ucArray);
//         setActions([
//           createAction("l.rplanet", "claim", activeUser.accountName, {
//             land_ids: landIds,
//             owner: activeUser.accountName,
//           }),
//         ]);
//       }
//       setLoading(false);
//     };

//     run();
//   }, [activeUser]);

//   return (
//     <ClaimSection
//       activeUser={activeUser}
//       actions={actions}
//       sectionTitle={"RPlanet Lands"}
//       buttonText={"Claim Minerals"}
//       website={"https://rplanet.io/lands"}
//       tokenContract={"e.rplanet"}
//       tokenNames={["WECAN", "WAXON", "ENEFT", "CAPON"]}
//       unclaimed={unclaimed}
//       loading={loading}
//     />
//   );
// };

const getUnclaimedNova = (userRow, poolTable) => {
  const { data: collections } = calculateHourlyRates(userRow, poolTable);
  const uc = {
    SNAKOIL: 0,
    SNAKGAS: 0,
    SNAKPOW: 0,
    SNAKVEN: 0,
    BOOST: 0,
    OLV: 0,
  };
  const now = Date.now() / 1000;
  collections.forEach((coll) => {
    const cuc = parseInt(coll.unclaimed);
    const token = coll.unclaimed.split(" ")[1];
    if (cuc > 0) {
      uc[token] += cuc;
    }
    const ht = parseInt(coll.hourlyTotal);
    const tslc = now - coll.last_claim;
    if (ht > 0 && tslc > 3600) {
      uc[token] += ht * parseInt(tslc / 3600);
    }
  });
  return uc;
};

const ClaimNovaRally = ({ activeUser }) => {
  const [actions, setActions] = useState([]);
  const [unclaimed, setUnclaimed] = useState([]);
  const [loading, setLoading] = useState(1);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      setUnclaimed([]);
      const r = await rpc.get_table_rows({
        code: "novarallysnk",
        scope: "novarallysnk",
        table: "user",
        json: true,
        limit: 1,
        lower_bound: activeUser.accountName,
        upper_bound: activeUser.accountName,
      });
      if (r.rows && r.rows[0]) {
        const r1 = await rpc.get_table_rows({
          json: true,
          code: "novarallysnk",
          scope: "novarallysnk",
          table: "staking",
          limit: 1000,
        });

        const uc = getUnclaimedNova(r.rows[0], r1.rows);
        if (
          Object.entries(uc)
            .filter(([t, a]) =>
              ["SNAKOIL", "SNAKGAS", "SNAKPOW", "SNAKVEN", "BOOST"].includes(t)
            )
            .map(([t, a]) => a > 0)
            .includes(true)
        ) {
          const ucArray = Object.entries(uc)
            .filter(([t, a]) => a > 0)
            .map(
              ([token, amount]) => toFixedLocaleString(amount, 0) + " " + token
            );
          setUnclaimed(ucArray);

          setActions([
            createAction("novarallysnk", "claim", activeUser.accountName, {
              username: activeUser.accountName,
            }),
          ]);
        }
      }
      setLoading(false);
    };

    run();
  }, [activeUser]);

  return (
    <ClaimSection
      activeUser={activeUser}
      actions={actions}
      sectionTitle={"Nova Rally"}
      buttonText={"Claim Nova"}
      website={"https://play.novarally.io/"}
      tokenContract={"novarallytok"}
      unclaimed={unclaimed}
      loading={loading}
    />
  );
};

const ClaimOliveland = ({ activeUser }) => {
  const [actions, setActions] = useState([]);
  const [unclaimed, setUnclaimed] = useState([]);
  const [loading, setLoading] = useState(1);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      setUnclaimed([]);
      const r = await rpc.get_table_rows({
        code: "olivelandstk",
        scope: "olivelandstk",
        table: "user",
        json: true,
        limit: 1,
        lower_bound: activeUser.accountName,
        upper_bound: activeUser.accountName,
      });
      if (r.rows && r.rows[0]) {
        const r1 = await rpc.get_table_rows({
          json: true,
          code: "olivelandstk",
          scope: "olivelandstk",
          table: "staking",
          limit: 1000,
        });

        const uc = getUnclaimedNova(r.rows[0], r1.rows);
        if (
          Object.entries(uc)
            .filter(([t, a]) => ["OLV"].includes(t))
            .map(([t, a]) => a > 0)
            .includes(true)
        ) {
          const ucArray = Object.entries(uc)
            .filter(([t, a]) => a > 0)
            .map(
              ([token, amount]) => toFixedLocaleString(amount, 0) + " " + token
            );
          setUnclaimed(ucArray);
          setActions([
            createAction("olivelandstk", "claim", activeUser.accountName, {
              username: activeUser.accountName,
            }),
          ]);
        }
      }
      setLoading(false);
    };

    run();
  }, [activeUser]);

  return (
    <ClaimSection
      activeUser={activeUser}
      actions={actions}
      sectionTitle={"Oliveland"}
      buttonText={"Claim Olives"}
      website={"https://www.oliveland.dev/"}
      tokenContract={"olivelandtok"}
      unclaimed={unclaimed}
      loading={loading}
    />
  );
};

const ClaimTrainCentury = ({ activeUser }) => {
  const [actions, setActions] = useState([]);
  const [unclaimed, setUnclaimed] = useState([]);
  const [loading, setLoading] = useState(1);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const getUnclaimedTrains = (assets) => {
        let uc = 0;
        const now = Date.now() / 1000;
        assets.forEach((asset) => {
          const tslc = now - asset.staked_time;
          const hours = parseInt(tslc / 3600);
          uc += (hours * asset.rate) / 10000;
        });
        return uc;
      };

      const more = async (result, lowerBound) => {
        const r = await rpc.get_table_rows({
          code: "yard.century",
          scope: activeUser.accountName,
          table: "assets",
          json: true,
          limit: 1000,
          lower_bound: lowerBound,
        });
        if (r.rows && r.rows.length > 0) {
          result.push(...r.rows);
          const lb = parseInt(r.rows[r.rows.length - 1].asset_id) + 1;
          return more(result, lb);
        } else {
          return result;
        }
      };
      const trainAssets = await more([], "");

      if (trainAssets.length > 0) {
        const locomotives = trainAssets.filter(
          (x) => x.schema === "locomotive"
        );
        const conductors = trainAssets.filter((x) => x.schema === "conductor");
        const railcars = trainAssets.filter((x) => x.schema === "railcar");

        const a = [];
        const locoUc = getUnclaimedTrains(locomotives);
        const condUc = getUnclaimedTrains(conductors);
        const railUc = getUnclaimedTrains(railcars);
        const uc = locoUc + condUc + railUc;
        if (uc > 0) {
          if (locoUc > 0) {
            a.push(
              createAction("yard.century", "claim", activeUser.accountName, {
                railroader: activeUser.accountName,
                schema: "locomotive",
              })
            );
          }
          if (condUc > 0) {
            a.push(
              createAction("yard.century", "claim", activeUser.accountName, {
                railroader: activeUser.accountName,
                schema: "conductor",
              })
            );
          }
          if (railUc > 0) {
            a.push(
              createAction("yard.century", "claim", activeUser.accountName, {
                railroader: activeUser.accountName,
                schema: "railcar",
              })
            );
          }
          setUnclaimed([toFixedLocaleString(uc, 4) + " TOCIUM"]);
          setActions(a);
        }
      }
      setLoading(false);
    };
    run();
  }, [activeUser]);

  return (
    <ClaimSection
      activeUser={activeUser}
      actions={actions}
      sectionTitle={"Train of the Century"}
      buttonText={"Claim Trains"}
      website={"https://stake.trains.cards/"}
      tokenContract={"toc.century"}
      decimals={4}
      unclaimed={unclaimed}
      loading={loading}
    />
  );
};

const ClaimCoinPirates = ({ activeUser }) => {
  const [actions, setActions] = useState([]);
  const [unclaimed, setUnclaimed] = useState([]);
  const [loading, setLoading] = useState(1);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const r = await rpc.get_table_rows({
        code: "stk.pirates",
        scope: "stk.pirates",
        table: "users",
        json: true,
        limit: 1,
        lower_bound: activeUser.accountName,
        upper_bound: activeUser.accountName,
      });
      if (r.rows && r.rows[0] && parseFloat(r.rows[0].unclaimed) > 0) {
        setUnclaimed([
          toFixedLocaleString(parseFloat(r.rows[0].unclaimed), 4) + " CPR",
        ]);
        setActions([
          createAction("stk.pirates", "stakerclaim", activeUser.accountName, {
            owner: activeUser.accountName,
          }),
        ]);
      }
      setLoading(false);
    };
    run();
  }, [activeUser]);

  return (
    <ClaimSection
      activeUser={activeUser}
      actions={actions}
      sectionTitle={"Coin Pirates"}
      buttonText={"Claim Pirates"}
      website={"https://coinpirates.io/staking/list"}
      tokenContract={"coin.pirates"}
      decimals={4}
      unclaimed={unclaimed}
      loading={loading}
    />
  );
};

const ClaimBreedersZone = ({ activeUser }) => {
  const [actions, setActions] = useState([]);
  const [unclaimed, setUnclaimed] = useState([]);
  const [loading, setLoading] = useState(1);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const r = await rpc.get_table_rows({
        json: true,
        code: "breederspool",
        scope: "breederspool",
        table: "users",
        lower_bound: activeUser.accountName,
        upper_bound: activeUser.accountName,
        limit: 1,
      });
      const userRow = r.rows[0];
      if (userRow) {
        const uc = parseFloat(userRow.data[0].unclaimed);
        if (uc > 0) {
          const ca = toFixedLocaleString(uc, 4) + " ESB";
          setUnclaimed([ca]);
          setActions([
            createAction("breederspool", "claim", activeUser.accountName, {
              username: activeUser.accountName,
              pool_id: 4455366986717,
            }),
          ]);
        }
      }
      setLoading(false);
    };
    run();
  }, [activeUser]);

  return (
    <ClaimSection
      activeUser={activeUser}
      actions={actions}
      sectionTitle={"Breeders Zone"}
      buttonText={"Claim ESB"}
      website={"https://breeders.zone"}
      tokenContract={"esbcontracts"}
      decimals={4}
      unclaimed={unclaimed}
      loading={loading}
    />
  );
};

const ClaimCollectSocial = ({ activeUser }) => {
  const [actions, setActions] = useState([]);
  const [unclaimed, setUnclaimed] = useState([]);
  const [loading, setLoading] = useState(1);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const r = await axios.get(
        "https://immortalgames.io/api/user/" + activeUser.accountName
      );
      if (r.data) {
        const unclaimedInt = parseInt(
          r.data.xp + r.data.miningXp - r.data.claimedXp
        );
        if (unclaimedInt > 0) {
          const ca = toFixedLocaleString(unclaimedInt, 0) + " RACOON";
          setUnclaimed([ca]);
          setActions([
            createAction("collectwhale", "claim", activeUser.accountName, {
              owner: activeUser.accountName,
              coin: unclaimedInt.toFixed(4) + " RACOON",
            }),
          ]);
        }
      }
      setLoading(false);
    };
    run();
  }, [activeUser]);

  return (
    <ClaimSection
      activeUser={activeUser}
      actions={actions}
      sectionTitle={"Collect.Social"}
      buttonText={"Claim Racoon"}
      website={"https://collect.social"}
      tokenContract={"collectwhale"}
      alternateClaimMessage={unclaimed}
      unclaimed={unclaimed}
      loading={loading}
    />
  );
};

const ClaimVirtualDream = ({ activeUser }) => {
  const [actions, setActions] = useState([]);
  const [unclaimed, setUnclaimed] = useState([]);
  const [loading, setLoading] = useState(1);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const pools = await getDreamPools();
      const r2 = await rpc.get_table_rows({
        code: "dreamstaking",
        scope: "dreamstaking",
        table: "user",
        json: true,
        limit: 1,
        lower_bound: activeUser.accountName,
        upper_bound: activeUser.accountName,
      });
      if (r2.rows && r2.rows[0]) {
        const uc = calculateUnclaimedDream(r2.rows[0], pools);
        if (uc > 0) {
          setUnclaimed([toFixedLocaleString(uc, 4) + " DREAM"]);
          setActions([
            createAction("dreamstaking", "claim", activeUser.accountName, {
              _user: activeUser.accountName,
            }),
          ]);
        }
      }
      setLoading(false);
    };
    run();
  }, [activeUser]);

  return (
    <ClaimSection
      activeUser={activeUser}
      actions={actions}
      sectionTitle={"VirtualDream"}
      buttonText={"Claim Dream"}
      website={"https://nf.tools/dream"}
      tokenContract={"virtualdream"}
      decimals={4}
      unclaimed={unclaimed}
      loading={loading}
    />
  );
};

const ClaimTalesOfCrypto = ({ activeUser }) => {
  const [actions, setActions] = useState([]);
  const [unclaimed, setUnclaimed] = useState([]);
  const [loading, setLoading] = useState(1);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const pools = await getTocPools();
      const r2 = await rpc.get_table_rows({
        code: "shroomstaker",
        scope: "shroomstaker",
        table: "user",
        json: true,
        limit: 1,
        lower_bound: activeUser.accountName,
        upper_bound: activeUser.accountName,
      });
      if (r2.rows && r2.rows[0]) {
        const uc = calculateUnclaimedToc(r2.rows[0], pools);
        if (uc > 0) {
          setUnclaimed([toFixedLocaleString(uc, 4) + " RADS"]);
          setActions([
            createAction("shroomstaker", "claim", activeUser.accountName, {
              _user: activeUser.accountName,
            }),
          ]);
        }
      }
      setLoading(false);
    };
    run();
  }, [activeUser]);

  return (
    <ClaimSection
      activeUser={activeUser}
      actions={actions}
      sectionTitle={"Tales of the Crypto"}
      buttonText={"Claim Rads"}
      website={"https://www.totcgame.io/staking"}
      tokenContract={"radshroomers"}
      tokenNames={["RADS"]}
      decimals={4}
      unclaimed={unclaimed}
      loading={loading}
    />
  );
};

const ClaimNeftyBlocks = ({ activeUser }) => {
  const [actions, setActions] = useState([]);
  const [unclaimed, setUnclaimed] = useState([]);
  const [loading, setLoading] = useState(1);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const r = await rpc.get_table_rows({
        code: "reward.nefty",
        scope: "reward.nefty",
        table: "claims",
        json: true,
        limit: 1,
        lower_bound: activeUser.accountName,
        upper_bound: activeUser.accountName,
      });
      let q = 0;
      if (r.rows && r.rows[0] && r.rows[0].balance) {
        r.rows[0].balance.forEach((x) => {
          const num = parseFloat(x.quantity);
          if (num > 0) {
            q += num;
          }
        });
      }
      if (q > 0) {
        setUnclaimed([toFixedLocaleString(q, 4) + " NEFTY"]);
        setActions([
          createAction("reward.nefty", "claim", activeUser.accountName, {
            account: activeUser.accountName,
          }),
        ]);
      }
      setLoading(false);
    };
    run();
  }, [activeUser]);

  return (
    <ClaimSection
      activeUser={activeUser}
      actions={actions}
      sectionTitle={"NeftyBlocks"}
      buttonText={"Claim Nefty"}
      website={"https://neftyblocks.com/nefty/staking-rewards"}
      tokenContract={"token.nefty"}
      decimals={4}
      unclaimed={unclaimed}
      loading={loading}
    />
  );
};

export default ClaimSection;

export {
  // ClaimRPlanetAether,
  // ClaimRPlanetMinerals,
  ClaimNovaRally,
  ClaimOliveland,
  ClaimTrainCentury,
  ClaimCoinPirates,
  ClaimBreedersZone,
  ClaimCollectSocial,
  ClaimVirtualDream,
  ClaimTalesOfCrypto,
  ClaimNeftyBlocks,
};
