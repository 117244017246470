import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Page from "../Page";
import SnakeLoader from "../SnakeLoader";
import { calculateNovaRarityValues } from "../Utilities/Calculate";

const OliveStakingValues = () => {
  const [stakingValues, setStakingValues] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const result = await calculateNovaRarityValues("olivelandstk");
      setStakingValues(result);
      setLoading(false);
    };
    run();
  }, []);

  const renderPoolTitle = (pool) => {
    return pool + " (OLV)";
  };

  return (
    <Page title="Oliveland Staking Values">
      <h2>Staking Values</h2>
      <Link to="/olive/staking">Staking Calculator</Link>
      &nbsp;&nbsp;
      <Link to="/olive/leaderboard">Staking Leaderboard</Link>
      <br />
      {loading || Object.keys(stakingValues).length === 0 ? (
        <SnakeLoader />
      ) : (
        <div>
          {Object.entries(stakingValues)
            .reverse()
            .map(([pool, data]) => {
              return (
                <React.Fragment key={pool + "frag"}>
                  <h3 style={{ marginBottom: "6px" }}>
                    {renderPoolTitle(pool)}
                  </h3>
                  <table style={{ margin: "auto", borderSpacing: "10px 4px" }}>
                    <thead>
                      <tr>
                        <th style={{ textAlign: "left" }}>Rarity</th>
                        <th>per hour</th>
                        <th>per day</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(data).map(([rarity, value]) => {
                        if (rarity === "Wins" || rarity === "Podium")
                          return null;
                        return (
                          <tr key={pool + rarity}>
                            <td style={{ textAlign: "left" }}>{rarity}</td>
                            <td style={{ textAlign: "right" }}>
                              {value.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {(24 * value).toLocaleString(undefined, {
                                minimumFractionDigits: 1,
                                maximumFractionDigits: 1,
                              })}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </React.Fragment>
              );
            })}
        </div>
      )}
      <br />
      <br />
    </Page>
  );
};

export default OliveStakingValues;
