/* eslint-disable linebreak-style */
/* eslint object-curly-spacing: ["error", "always"] */
/* eslint max-len: [2, 200, 4] */
/* eslint-disable comma-dangle */
/* eslint-disable indent */
/* eslint-disable guard-for-in */
/* eslint-disable arrow-parens */
const RPLANET_ELEMENTS = {
  AIR: {
    recipe: ["", "", "", ""],
    gen: 0,
    tier: 1,
    cost: 10000,
    name: "Air",
    date: "",
    order: 0,
    counts: {},
    pattern: "",
  },
  EARTH: {
    recipe: ["", "", "", ""],
    gen: 0,
    tier: 1,
    cost: 10000,
    name: "Earth",
    date: "",
    order: 0,
    counts: {},
    pattern: "",
  },
  WATER: {
    recipe: ["", "", "", ""],
    gen: 0,
    tier: 1,
    cost: 10000,
    name: "Water",
    date: "",
    order: 0,
    counts: {},
    pattern: "",
  },
  FIRE: {
    recipe: ["", "", "", ""],
    gen: 0,
    tier: 1,
    cost: 10000,
    name: "Fire",
    date: "",
    order: 0,
    counts: {},
    pattern: "",
  },
  RAIN: {
    recipe: ["AIR", "AIR", "WATER", "WATER"],
    gen: 1,
    tier: 2,
    cost: 40000,
    name: "Rain",
    inventor: "2ltay.wam",
    date: "2021-02-17T00:30:43.000Z",
    order: 1,
    counts: { AIR: 2, WATER: 2 },
    pattern: "AABB",
  },
  SEA: {
    recipe: ["WATER", "WATER", "WATER", "WATER"],
    gen: 1,
    tier: 2,
    cost: 40000,
    name: "Sea",
    inventor: "bu4ay.wam",
    date: "2021-02-17T00:30:22.000Z",
    order: 2,
    counts: { WATER: 4 },
    pattern: "AAAA",
  },
  MUD: {
    recipe: ["EARTH", "EARTH", "WATER", "WATER"],
    gen: 1,
    tier: 2,
    cost: 40000,
    name: "Mud",
    inventor: "4xjay.wam",
    date: "2021-02-17T00:30:21.000Z",
    order: 3,
    counts: { EARTH: 2, WATER: 2 },
    pattern: "AABB",
  },
  DUST: {
    recipe: ["AIR", "AIR", "EARTH", "EARTH"],
    gen: 1,
    tier: 2,
    cost: 40000,
    name: "Dust",
    inventor: "v2qqk.wam",
    date: "2021-02-17T00:30:42.000Z",
    order: 4,
    counts: { AIR: 2, EARTH: 2 },
    pattern: "AABB",
  },
  PRESS: {
    recipe: ["EARTH", "EARTH", "EARTH", "EARTH"],
    gen: 1,
    tier: 2,
    cost: 40000,
    name: "Pressure",
    inventor: "tjdko.wam",
    date: "2021-02-17T00:31:13.000Z",
    order: 5,
    counts: { EARTH: 4 },
    pattern: "AAAA",
  },
  LAVA: {
    recipe: ["EARTH", "EARTH", "FIRE", "FIRE"],
    gen: 1,
    tier: 2,
    cost: 40000,
    name: "Lava",
    inventor: "jh5aw.wam",
    date: "2021-02-17T00:30:43.000Z",
    order: 6,
    counts: { EARTH: 2, FIRE: 2 },
    pattern: "AABB",
  },
  ENERGY: {
    recipe: ["AIR", "FIRE", "FIRE", "FIRE"],
    gen: 1,
    tier: 2,
    cost: 40000,
    name: "Energy",
    inventor: "4vnqu.wam",
    date: "2021-02-17T00:31:13.000Z",
    order: 7,
    counts: { AIR: 1, FIRE: 3 },
    pattern: "AAAB",
  },
  STEAM: {
    recipe: ["FIRE", "FIRE", "FIRE", "WATER"],
    gen: 1,
    tier: 2,
    cost: 40000,
    name: "Steam",
    inventor: "igdqw.wam",
    date: "2021-02-17T00:30:54.000Z",
    order: 8,
    counts: { FIRE: 3, WATER: 1 },
    pattern: "AAAB",
  },
  WIND: {
    recipe: ["AIR", "ENERGY", "PRESS", "SEA"],
    gen: 1,
    tier: 3,
    cost: 130000,
    name: "Wind",
    inventor: "qu1qw.wam",
    date: "2021-02-17T02:28:22.000Z",
    order: 9,
    counts: { AIR: 1, ENERGY: 1, PRESS: 1, SEA: 1 },
    pattern: "ABCD",
  },
  FLOOD: {
    recipe: ["RAIN", "RAIN", "RAIN", "RAIN"],
    gen: 1,
    tier: 3,
    cost: 160000,
    name: "Flood",
    inventor: "3d2qw.wam",
    date: "2021-02-17T00:45:19.000Z",
    order: 10,
    counts: { RAIN: 4 },
    pattern: "AAAA",
  },
  PLANT: {
    recipe: ["ENERGY", "MUD", "MUD", "RAIN"],
    gen: 1,
    tier: 3,
    cost: 160000,
    name: "Plant",
    inventor: "gxfqu.wam",
    date: "2021-02-18T02:54:37.000Z",
    order: 11,
    counts: { ENERGY: 1, MUD: 2, RAIN: 1 },
    pattern: "AABC",
  },
  OCEAN: {
    recipe: ["SEA", "SEA", "SEA", "SEA"],
    gen: 1,
    tier: 3,
    cost: 160000,
    name: "Ocean",
    inventor: "yqzam.wam",
    date: "2021-02-17T00:39:23.000Z",
    order: 12,
    counts: { SEA: 4 },
    pattern: "AAAA",
  },
  BRICK: {
    recipe: ["FIRE", "MUD", "MUD", "WATER"],
    gen: 1,
    tier: 3,
    cost: 100000,
    name: "Brick",
    inventor: "lukqk.wam",
    date: "2021-02-17T02:20:28.000Z",
    order: 13,
    counts: { FIRE: 1, MUD: 2, WATER: 1 },
    pattern: "AABC",
  },
  GUNPWDR: {
    recipe: ["DUST", "DUST", "ENERGY", "FIRE"],
    gen: 1,
    tier: 3,
    cost: 130000,
    name: "Gunpowder",
    inventor: "frcqu.wam",
    date: "2021-02-18T03:13:33.000Z",
    order: 14,
    counts: { DUST: 2, ENERGY: 1, FIRE: 1 },
    pattern: "AABC",
  },
  ATMSPHE: {
    recipe: ["AIR", "DUST", "PRESS", "RAIN"],
    gen: 1,
    tier: 3,
    cost: 130000,
    name: "Atmosphere",
    inventor: "34aaw.wam",
    date: "2021-02-21T18:04:22.000Z",
    order: 15,
    counts: { AIR: 1, DUST: 1, PRESS: 1, RAIN: 1 },
    pattern: "ABCD",
  },
  OBSIDIN: {
    recipe: ["LAVA", "LAVA", "PRESS", "WATER"],
    gen: 1,
    tier: 3,
    cost: 130000,
    name: "Obsidian",
    inventor: "kl2qy.wam",
    date: "2021-02-17T08:49:29.000Z",
    order: 16,
    counts: { LAVA: 2, PRESS: 1, WATER: 1 },
    pattern: "AABC",
  },
  STONE: {
    recipe: ["AIR", "AIR", "LAVA", "LAVA"],
    gen: 1,
    tier: 3,
    cost: 100000,
    name: "Stone",
    inventor: "zcvqy.wam",
    date: "2021-02-17T00:32:46.000Z",
    order: 17,
    counts: { AIR: 2, LAVA: 2 },
    pattern: "AABB",
  },
  EARTHQ: {
    recipe: ["EARTH", "FIRE", "PRESS", "STEAM"],
    gen: 1,
    tier: 3,
    cost: 100000,
    name: "Earthquake",
    inventor: "f1.aw.wam",
    date: "2021-02-17T02:50:54.000Z",
    order: 18,
    counts: { EARTH: 1, FIRE: 1, PRESS: 1, STEAM: 1 },
    pattern: "ABCD",
  },
  CLOUD: {
    recipe: ["ATMSPHE", "EARTH", "STEAM", "WIND"],
    gen: 1,
    tier: 4,
    cost: 310000,
    name: "Cloud",
    inventor: "p4lqm.wam",
    date: "2021-03-02T08:06:36.000Z",
    order: 19,
    counts: { ATMSPHE: 1, EARTH: 1, STEAM: 1, WIND: 1 },
    pattern: "ABCD",
  },
  GEYSER: {
    recipe: ["EARTHQ", "PRESS", "STEAM", "WATER"],
    gen: 1,
    tier: 4,
    cost: 190000,
    name: "Geyser",
    inventor: "yi3qw.wam",
    date: "2021-02-21T08:10:12.000Z",
    order: 20,
    counts: { EARTHQ: 1, PRESS: 1, STEAM: 1, WATER: 1 },
    pattern: "ABCD",
  },
  WAVE: {
    recipe: ["EARTHQ", "SEA", "WIND", "WIND"],
    gen: 1,
    tier: 4,
    cost: 400000,
    name: "Wave",
    inventor: "frcqu.wam",
    date: "2021-03-20T02:48:32.000Z",
    order: 21,
    counts: { EARTHQ: 1, SEA: 1, WIND: 2 },
    pattern: "AABC",
  },
  HURRCAN: {
    recipe: ["CLOUD", "DUST", "RAIN", "WIND"],
    gen: 1,
    tier: 5,
    cost: 520000,
    name: "Hurricane",
    inventor: "frcqu.wam",
    date: "2021-04-05T08:36:13.000Z",
    order: 22,
    counts: { CLOUD: 1, DUST: 1, RAIN: 1, WIND: 1 },
    pattern: "ABCD",
  },
  VULCANO: {
    recipe: ["EARTHQ", "ENERGY", "EXPLOSN", "LAVA"],
    gen: 1,
    tier: 5,
    cost: 490000,
    name: "Volcano",
    inventor: "xqjam.wam",
    date: "2021-03-28T16:08:58.000Z",
    order: 23,
    counts: { EARTHQ: 1, ENERGY: 1, EXPLOSN: 1, LAVA: 1 },
    pattern: "ABCD",
  },
  TOBACCO: {
    recipe: ["FIRE", "FIRE", "PLANT", "PLANT"],
    gen: 1,
    tier: 4,
    cost: 340000,
    name: "Tobacco",
    inventor: "4vnqu.wam",
    date: "2021-02-18T06:03:48.000Z",
    order: 24,
    counts: { FIRE: 2, PLANT: 2 },
    pattern: "AABB",
  },
  SWAMP: {
    recipe: ["EARTH", "MUD", "PLANT", "RAIN"],
    gen: 1,
    tier: 4,
    cost: 250000,
    name: "Swamp",
    inventor: "3jpqw.wam",
    date: "2021-02-19T07:01:32.000Z",
    order: 25,
    counts: { EARTH: 1, MUD: 1, PLANT: 1, RAIN: 1 },
    pattern: "ABCD",
  },
  MOSS: {
    recipe: ["AIR", "PLANT", "STONE", "WATER"],
    gen: 1,
    tier: 4,
    cost: 280000,
    name: "Moss",
    inventor: "xqjam.wam",
    date: "2021-02-25T19:45:13.000Z",
    order: 26,
    counts: { AIR: 1, PLANT: 1, STONE: 1, WATER: 1 },
    pattern: "ABCD",
  },
  GRASS: {
    recipe: ["EARTH", "PLANT", "RAIN", "RAIN"],
    gen: 1,
    tier: 4,
    cost: 250000,
    name: "Grass",
    inventor: "4g5qy.wam",
    date: "2021-03-08T03:07:31.000Z",
    order: 27,
    counts: { EARTH: 1, PLANT: 1, RAIN: 2 },
    pattern: "AABC",
  },
  COAL: {
    recipe: ["PLANT", "PRESS", "PRESS", "PRESS"],
    gen: 1,
    tier: 4,
    cost: 280000,
    name: "Coal",
    inventor: "2tmay.wam",
    date: "2021-02-21T13:11:44.000Z",
    order: 28,
    counts: { PLANT: 1, PRESS: 3 },
    pattern: "AAAB",
  },
  WALL: {
    recipe: ["BRICK", "BRICK", "BRICK", "MUD"],
    gen: 1,
    tier: 4,
    cost: 340000,
    name: "Wall",
    inventor: "qu1qw.wam",
    date: "2021-02-17T03:18:40.000Z",
    order: 29,
    counts: { BRICK: 3, MUD: 1 },
    pattern: "AAAB",
  },
  EXPLOSN: {
    recipe: ["FIRE", "GUNPWDR", "GUNPWDR", "PRESS"],
    gen: 1,
    tier: 4,
    cost: 310000,
    name: "Explosion",
    inventor: "ldbay.wam",
    date: "2021-02-18T05:56:38.000Z",
    order: 30,
    counts: { FIRE: 1, GUNPWDR: 2, PRESS: 1 },
    pattern: "AABC",
  },
  ASH: {
    recipe: ["LAVA", "LAVA", "LAVA", "VULCANO"],
    gen: 1,
    tier: 6,
    cost: 610000,
    name: "Ash",
    inventor: "pu2aw.wam",
    date: "2021-03-31T22:52:03.000Z",
    order: 31,
    counts: { LAVA: 3, VULCANO: 1 },
    pattern: "AAAB",
  },
  METAL: {
    recipe: ["COAL", "COAL", "FIRE", "STONE"],
    gen: 1,
    tier: 5,
    cost: 670000,
    name: "Metal",
    inventor: "4g5qy.wam",
    date: "2021-03-08T03:49:09.000Z",
    order: 32,
    counts: { COAL: 2, FIRE: 1, STONE: 1 },
    pattern: "AABC",
  },
  SAND: {
    recipe: ["STONE", "STONE", "WIND", "WIND"],
    gen: 1,
    tier: 4,
    cost: 460000,
    name: "Sand",
    inventor: "f1.aw.wam",
    date: "2021-02-18T05:34:19.000Z",
    order: 33,
    counts: { STONE: 2, WIND: 2 },
    pattern: "AABB",
  },
  MNTAIN: {
    recipe: ["EARTH", "EARTHQ", "PRESS", "VULCANO"],
    gen: 1,
    tier: 6,
    cost: 640000,
    name: "Mountain",
    inventor: "kdoqy.wam",
    date: "2021-04-12T02:49:42.000Z",
    order: 34,
    counts: { EARTH: 1, EARTHQ: 1, PRESS: 1, VULCANO: 1 },
    pattern: "ABCD",
  },
  STORM: {
    recipe: ["OCEAN", "RAIN", "WAVE", "WIND"],
    gen: 1,
    tier: 5,
    cost: 730000,
    name: "Storm",
    inventor: "ldbay.wam",
    date: "2021-03-20T03:28:18.000Z",
    order: 35,
    counts: { OCEAN: 1, RAIN: 1, WAVE: 1, WIND: 1 },
    pattern: "ABCD",
  },
  FOG: {
    recipe: ["CLOUD", "EARTH", "STEAM", "WATER"],
    gen: 1,
    tier: 5,
    cost: 370000,
    name: "Fog",
    inventor: "qdypk.wam",
    date: "2021-03-04T07:06:03.000Z",
    order: 36,
    counts: { CLOUD: 1, EARTH: 1, STEAM: 1, WATER: 1 },
    pattern: "ABCD",
  },
  SKY: {
    recipe: ["AIR", "ATMSPHE", "CLOUD", "WIND"],
    gen: 1,
    tier: 5,
    cost: 580000,
    name: "Sky",
    inventor: "2tmay.wam",
    date: "2021-03-16T16:10:09.000Z",
    order: 37,
    counts: { AIR: 1, ATMSPHE: 1, CLOUD: 1, WIND: 1 },
    pattern: "ABCD",
  },
  SOUND: {
    recipe: ["AIR", "AIR", "WAVE", "WAVE"],
    gen: 1,
    tier: 5,
    cost: 820000,
    name: "Sound",
    inventor: "2tmay.wam",
    date: "2021-03-20T03:31:20.000Z",
    order: 38,
    counts: { AIR: 2, WAVE: 2 },
    pattern: "AABB",
  },
  ISLE: {
    recipe: ["EARTH", "EARTH", "OCEAN", "VULCANO"],
    gen: 1,
    tier: 6,
    cost: 670000,
    name: "Isle",
    inventor: "p4lqm.wam",
    date: "2021-04-06T04:13:24.000Z",
    order: 39,
    counts: { EARTH: 2, OCEAN: 1, VULCANO: 1 },
    pattern: "AABC",
  },
  DIAMOND: {
    recipe: ["COAL", "PRESS", "PRESS", "PRESS"],
    gen: 1,
    tier: 5,
    cost: 400000,
    name: "Diamond",
    inventor: "2tmay.wam",
    date: "2021-02-21T13:34:11.000Z",
    order: 40,
    counts: { COAL: 1, PRESS: 3 },
    pattern: "AAAB",
  },
  ARCHIP: {
    recipe: ["ISLE", "ISLE", "ISLE", "SEA"],
    gen: 1,
    tier: 7,
    cost: 2050000,
    name: "Archipelago",
    inventor: "p4lqm.wam",
    date: "2021-04-06T04:29:58.000Z",
    order: 41,
    counts: { ISLE: 3, SEA: 1 },
    pattern: "AAAB",
  },
  FIREWRK: {
    recipe: ["EXPLOSN", "GUNPWDR", "SKY", "SOUND"],
    gen: 1,
    tier: 6,
    cost: 1840000,
    name: "Fireworks",
    inventor: "xqjam.wam",
    date: "2021-03-20T17:01:54.000Z",
    order: 42,
    counts: { EXPLOSN: 1, GUNPWDR: 1, SKY: 1, SOUND: 1 },
    pattern: "ABCD",
  },
  GRENADE: {
    recipe: ["EXPLOSN", "GUNPWDR", "GUNPWDR", "METAL"],
    gen: 1,
    tier: 6,
    cost: 1240000,
    name: "Grenade",
    inventor: "kz.qw.wam",
    date: "2021-03-14T07:43:05.000Z",
    order: 43,
    counts: { EXPLOSN: 1, GUNPWDR: 2, METAL: 1 },
    pattern: "AABC",
  },
  STEEL: {
    recipe: ["COAL", "FIRE", "METAL", "WATER"],
    gen: 1,
    tier: 6,
    cost: 970000,
    name: "Steel",
    inventor: "nhvqy.wam",
    date: "2021-03-12T06:33:45.000Z",
    order: 44,
    counts: { COAL: 1, FIRE: 1, METAL: 1, WATER: 1 },
    pattern: "ABCD",
  },
  RING: {
    recipe: ["DIAMOND", "DIAMOND", "DIAMOND", "METAL"],
    gen: 1,
    tier: 6,
    cost: 1870000,
    name: "Ring",
    inventor: "hi1qy.wam",
    date: "2021-03-10T10:32:28.000Z",
    order: 45,
    counts: { DIAMOND: 3, METAL: 1 },
    pattern: "AAAB",
  },
  BULLET: {
    recipe: ["GUNPWDR", "GUNPWDR", "METAL", "METAL"],
    gen: 1,
    tier: 6,
    cost: 1600000,
    name: "Bullet",
    inventor: "kz.qw.wam",
    date: "2021-03-16T23:27:43.000Z",
    order: 46,
    counts: { GUNPWDR: 2, METAL: 2 },
    pattern: "AABB",
  },
  BLADE: {
    recipe: ["PRESS", "STEEL", "STONE", "WATER"],
    gen: 1,
    tier: 7,
    cost: 1120000,
    name: "Blade",
    inventor: "m53ay.wam",
    date: "2021-03-22T14:34:04.000Z",
    order: 47,
    counts: { PRESS: 1, STEEL: 1, STONE: 1, WATER: 1 },
    pattern: "ABCD",
  },
  ELECTRC: {
    recipe: ["ENERGY", "ENERGY", "METAL", "METAL"],
    gen: 1,
    tier: 6,
    cost: 1420000,
    name: "Electricity",
    inventor: "kz.qw.wam",
    date: "2021-03-16T10:01:47.000Z",
    order: 48,
    counts: { ENERGY: 2, METAL: 2 },
    pattern: "AABB",
  },
  RIVER: {
    recipe: ["CLOUD", "MNTAIN", "RAIN", "RAIN"],
    gen: 1,
    tier: 7,
    cost: 1030000,
    name: "River",
    inventor: "p4lqm.wam",
    date: "2021-04-18T19:58:19.000Z",
    order: 49,
    counts: { CLOUD: 1, MNTAIN: 1, RAIN: 2 },
    pattern: "AABC",
  },
  GLASS: {
    recipe: ["FIRE", "FIRE", "FIRE", "SAND"],
    gen: 1,
    tier: 5,
    cost: 490000,
    name: "Glass",
    inventor: "lsfqw.wam",
    date: "2021-02-22T12:22:34.000Z",
    order: 50,
    counts: { FIRE: 3, SAND: 1 },
    pattern: "AAAB",
  },
  BOILER: {
    recipe: ["METAL", "PRESS", "STEAM", "WATER"],
    gen: 1,
    tier: 6,
    cost: 760000,
    name: "Boiler",
    inventor: "rwck4.wam",
    date: "2021-03-22T00:13:49.000Z",
    order: 51,
    counts: { METAL: 1, PRESS: 1, STEAM: 1, WATER: 1 },
    pattern: "ABCD",
  },
  RUST: {
    recipe: ["AIR", "AIR", "METAL", "WATER"],
    gen: 1,
    tier: 6,
    cost: 700000,
    name: "Rust",
    inventor: "2tmay.wam",
    date: "2021-03-09T02:04:41.000Z",
    order: 52,
    counts: { AIR: 2, METAL: 1, WATER: 1 },
    pattern: "AABC",
  },
  SUN: {
    recipe: ["FIRE", "FIRE", "FIRE", "SKY"],
    gen: 1,
    tier: 6,
    cost: 610000,
    name: "Sun",
    inventor: "2tmay.wam",
    date: "2021-03-16T17:22:29.000Z",
    order: 53,
    counts: { FIRE: 3, SKY: 1 },
    pattern: "AAAB",
  },
  CACTUS: {
    recipe: ["PLANT", "SAND", "SUN", "WIND"],
    gen: 1,
    tier: 7,
    cost: 1360000,
    name: "Cactus",
    inventor: "hgfqq.wam",
    date: "2021-04-04T01:31:23.000Z",
    order: 54,
    counts: { PLANT: 1, SAND: 1, SUN: 1, WIND: 1 },
    pattern: "ABCD",
  },
  DEW: {
    recipe: ["EARTH", "FOG", "GRASS", "WATER"],
    gen: 1,
    tier: 6,
    cost: 640000,
    name: "Dew",
    inventor: "sxyqy.wam",
    date: "2021-03-21T16:21:01.000Z",
    order: 55,
    counts: { EARTH: 1, FOG: 1, GRASS: 1, WATER: 1 },
    pattern: "ABCD",
  },
  LIFE: {
    recipe: ["ENERGY", "LAVA", "OCEAN", "PRESS"],
    gen: 1,
    tier: 4,
    cost: 280000,
    name: "Life",
    inventor: "2gbaq.wam",
    date: "2021-03-26T01:55:52.000Z",
    order: 56,
    counts: { ENERGY: 1, LAVA: 1, OCEAN: 1, PRESS: 1 },
    pattern: "ABCD",
  },
  HORIZON: {
    recipe: ["CLOUD", "SEA", "SKY", "SUN"],
    gen: 1,
    tier: 7,
    cost: 1540000,
    name: "Horizon",
    inventor: "wqhb.wam",
    date: "2021-03-28T11:31:30.000Z",
    order: 57,
    counts: { CLOUD: 1, SEA: 1, SKY: 1, SUN: 1 },
    pattern: "ABCD",
  },
  DESERT: {
    recipe: ["CACTUS", "SAND", "SUN", "WIND"],
    gen: 1,
    tier: 8,
    cost: 2560000,
    name: "Desert",
    inventor: "hmgb.wam",
    date: "2021-04-04T02:21:35.000Z",
    order: 58,
    counts: { CACTUS: 1, SAND: 1, SUN: 1, WIND: 1 },
    pattern: "ABCD",
  },
  BEACH: {
    recipe: ["OCEAN", "PLANT", "SAND", "SUN"],
    gen: 1,
    tier: 7,
    cost: 1390000,
    name: "Beach",
    inventor: "dyqqy.wam",
    date: "2021-04-03T04:11:30.000Z",
    order: 59,
    counts: { OCEAN: 1, PLANT: 1, SAND: 1, SUN: 1 },
    pattern: "ABCD",
  },
  ALCOHOL: {
    recipe: ["FIRE", "FIRE", "PLANT", "WATER"],
    gen: 2,
    tier: 4,
    cost: 190000,
    name: "Alcohol",
    inventor: "3uora.wam",
    date: "2021-04-16T21:52:26.000Z",
    order: 60,
    counts: { FIRE: 2, PLANT: 1, WATER: 1 },
    pattern: "AABC",
  },
  COMPASS: {
    recipe: ["GLASS", "GLASS", "METAL", "METAL"],
    gen: 2,
    tier: 6,
    cost: 2320000,
    name: "Compass",
    inventor: "cmlr.wam",
    date: "2021-04-05T10:58:36.000Z",
    order: 61,
    counts: { GLASS: 2, METAL: 2 },
    pattern: "AABB",
  },
  SNDCLCK: {
    recipe: ["GLASS", "METAL", "SAND", "SAND"],
    gen: 2,
    tier: 6,
    cost: 2080000,
    name: "Sand Clock",
    inventor: "hgfqq.wam",
    date: "2021-04-19T16:45:45.000Z",
    order: 62,
    counts: { GLASS: 1, METAL: 1, SAND: 2 },
    pattern: "AABC",
  },
  TIME: {
    recipe: ["LIFE", "LIFE", "SNDCLCK", "SNDCLCK"],
    gen: 2,
    tier: 7,
    cost: 4720000,
    name: "Time",
    inventor: "yqzam.wam",
    date: "2021-04-20T21:31:27.000Z",
    order: 63,
    counts: { LIFE: 2, SNDCLCK: 2 },
    pattern: "AABB",
  },
  CLAY: {
    recipe: ["MUD", "MUD", "SAND", "STONE"],
    gen: 2,
    tier: 5,
    cost: 640000,
    name: "Clay",
    inventor: "frcqu.wam",
    date: "2021-03-31T05:03:52.000Z",
    order: 64,
    counts: { MUD: 2, SAND: 1, STONE: 1 },
    pattern: "AABC",
  },
  TREE: {
    recipe: ["EARTH", "PLANT", "SUN", "WATER"],
    gen: 2,
    tier: 7,
    cost: 790000,
    name: "Tree",
    inventor: "kz.qw.wam",
    date: "2021-03-31T04:40:36.000Z",
    order: 65,
    counts: { EARTH: 1, PLANT: 1, SUN: 1, WATER: 1 },
    pattern: "ABCD",
  },
  KNIFE: {
    recipe: ["BLADE", "BLADE", "TREE", "TREE"],
    gen: 2,
    tier: 8,
    cost: 3820000,
    name: "Knife",
    inventor: "2tmay.wam",
    date: "2021-04-05T01:23:07.000Z",
    order: 66,
    counts: { BLADE: 2, TREE: 2 },
    pattern: "AABB",
  },
  CLOTH: {
    recipe: ["KNIFE", "PLANT", "PLANT", "PLANT"],
    gen: 2,
    tier: 9,
    cost: 4300000,
    name: "Cloth",
    inventor: "2tmay.wam",
    date: "2021-05-05T15:43:43.000Z",
    order: 67,
    counts: { KNIFE: 1, PLANT: 3 },
    pattern: "AAAB",
  },
  FLAG: {
    recipe: ["CLOTH", "CLOTH", "TREE", "WIND"],
    gen: 2,
    tier: 10,
    cost: 9520000,
    name: "Flag",
    inventor: "xwwtg.wam",
    date: "2021-05-06T01:30:34.000Z",
    order: 68,
    counts: { CLOTH: 2, TREE: 1, WIND: 1 },
    pattern: "AABC",
  },
  GAS: {
    recipe: ["PLANT", "SWAMP", "SWAMP", "SWAMP"],
    gen: 2,
    tier: 5,
    cost: 910000,
    name: "Gas",
    inventor: "xphbw.wam",
    date: "2021-06-16T20:53:22.000Z",
    order: 69,
    counts: { PLANT: 1, SWAMP: 3 },
    pattern: "AAAB",
  },
  FROST: {
    recipe: ["ATMSPHE", "WATER", "WIND", "WIND"],
    gen: 2,
    tier: 4,
    cost: 400000,
    name: "Frost",
    inventor: "ivankazmenko",
    date: "2021-05-30T04:35:00.000Z",
    order: 70,
    counts: { ATMSPHE: 1, WATER: 1, WIND: 2 },
    pattern: "AABC",
  },
  ICE: {
    recipe: ["FROST", "WATER", "WATER", "WATER"],
    gen: 2,
    tier: 5,
    cost: 430000,
    name: "Ice",
    inventor: "ivankazmenko",
    date: "2021-05-30T04:35:24.000Z",
    order: 71,
    counts: { FROST: 1, WATER: 3 },
    pattern: "AAAB",
  },
  FRIDGE: {
    recipe: ["ELECTRC", "FROST", "ICE", "METAL"],
    gen: 2,
    tier: 7,
    cost: 2920000,
    name: "Fridge",
    inventor: "vgvaw.wam",
    date: "2021-05-30T05:43:22.000Z",
    order: 72,
    counts: { ELECTRC: 1, FROST: 1, ICE: 1, METAL: 1 },
    pattern: "ABCD",
  },
  VACUUM: {
    recipe: ["EXPLOSN", "EXPLOSN", "EXPLOSN", "EXPLOSN"],
    gen: 2,
    tier: 5,
    cost: 1240000,
    name: "Vacuum",
    inventor: "lhhqu.wam",
    date: "2021-05-07T00:41:26.000Z",
    order: 73,
    counts: { EXPLOSN: 4 },
    pattern: "AAAA",
  },
  BLCKMTR: {
    recipe: ["ENERGY", "EXPLOSN", "PRESS", "SUN"],
    gen: 2,
    tier: 7,
    cost: 1000000,
    name: "Black Matter",
    inventor: "qlir2.wam",
    date: "2021-05-11T06:25:05.000Z",
    order: 74,
    counts: { ENERGY: 1, EXPLOSN: 1, PRESS: 1, SUN: 1 },
    pattern: "ABCD",
  },
  BLCKHOL: {
    recipe: ["BLCKMTR", "EXPLOSN", "SUN", "VACUUM"],
    gen: 2,
    tier: 8,
    cost: 3160000,
    name: "Black Hole",
    inventor: "e4cti.wam",
    date: "2021-05-11T07:45:51.000Z",
    order: 75,
    counts: { BLCKMTR: 1, EXPLOSN: 1, SUN: 1, VACUUM: 1 },
    pattern: "ABCD",
  },
  BOTTLE: {
    recipe: ["AIR", "FIRE", "GLASS", "PRESS"],
    gen: 2,
    tier: 6,
    cost: 550000,
    name: "Bottle",
    inventor: "kdoqy.wam",
    date: "2021-04-07T05:45:57.000Z",
    order: 76,
    counts: { AIR: 1, FIRE: 1, GLASS: 1, PRESS: 1 },
    pattern: "ABCD",
  },
  HEAT: {
    recipe: ["FIRE", "FIRE", "FIRE", "FIRE"],
    gen: 2,
    tier: 2,
    cost: 40000,
    name: "Heat",
    inventor: "zxzay.wam",
    date: "2021-03-31T03:52:06.000Z",
    order: 77,
    counts: { FIRE: 4 },
    pattern: "AAAA",
  },
  WHEAT: {
    recipe: ["EARTH", "PLANT", "RAIN", "SUN"],
    gen: 2,
    tier: 7,
    cost: 820000,
    name: "Wheat",
    inventor: "rrbay.wam",
    date: "2021-03-31T15:15:50.000Z",
    order: 78,
    counts: { EARTH: 1, PLANT: 1, RAIN: 1, SUN: 1 },
    pattern: "ABCD",
  },
  TNPLT: {
    recipe: ["METAL", "PRESS", "PRESS", "PRESS"],
    gen: 2,
    tier: 6,
    cost: 790000,
    name: "Tinplate",
    inventor: "4k5qq.wam",
    date: "2021-03-31T14:44:16.000Z",
    order: 79,
    counts: { METAL: 1, PRESS: 3 },
    pattern: "AAAB",
  },
  BARREL: {
    recipe: ["TNPLT", "TREE", "TREE", "TREE"],
    gen: 2,
    tier: 8,
    cost: 3160000,
    name: "Barrel",
    inventor: "4x.qi.wam",
    date: "2021-05-02T11:47:14.000Z",
    order: 80,
    counts: { TNPLT: 1, TREE: 3 },
    pattern: "AAAB",
  },
  WHISKEY: {
    recipe: ["ALCOHOL", "BARREL", "WATER", "WHEAT"],
    gen: 2,
    tier: 9,
    cost: 4180000,
    name: "Whiskey",
    inventor: "xqjam.wam",
    date: "2021-05-02T16:42:58.000Z",
    order: 81,
    counts: { ALCOHOL: 1, BARREL: 1, WATER: 1, WHEAT: 1 },
    pattern: "ABCD",
  },
  SNDSTRM: {
    recipe: ["DESERT", "ENERGY", "HURRCAN", "SAND"],
    gen: 2,
    tier: 9,
    cost: 3580000,
    name: "Sandstorm",
    inventor: "unbehindable",
    date: "2021-05-04T10:39:41.000Z",
    order: 82,
    counts: { DESERT: 1, ENERGY: 1, HURRCAN: 1, SAND: 1 },
    pattern: "ABCD",
  },
  BULB: {
    recipe: ["ELECTRC", "GLASS", "METAL", "METAL"],
    gen: 2,
    tier: 7,
    cost: 3250000,
    name: "Bulb",
    inventor: "kz.qw.wam",
    date: "2021-04-07T11:34:11.000Z",
    order: 83,
    counts: { ELECTRC: 1, GLASS: 1, METAL: 2 },
    pattern: "AABC",
  },
  LENS: {
    recipe: ["GLASS", "GLASS", "PRESS", "WATER"],
    gen: 2,
    tier: 6,
    cost: 1030000,
    name: "Lens",
    inventor: ".5hci.wam",
    date: "2021-05-15T17:24:52.000Z",
    order: 84,
    counts: { GLASS: 2, PRESS: 1, WATER: 1 },
    pattern: "AABC",
  },
  MCRSCP: {
    recipe: ["GLASS", "LENS", "METAL", "METAL"],
    gen: 2,
    tier: 7,
    cost: 2860000,
    name: "Microscope",
    inventor: ".5hci.wam",
    date: "2021-05-15T20:00:45.000Z",
    order: 85,
    counts: { GLASS: 1, LENS: 1, METAL: 2 },
    pattern: "AABC",
  },
  ATOM: {
    recipe: ["DUST", "DUST", "DUST", "MCRSCP"],
    gen: 2,
    tier: 8,
    cost: 2980000,
    name: "Atom",
    inventor: "tvvbg.wam",
    date: "2021-06-02T09:55:20.000Z",
    order: 86,
    counts: { DUST: 3, MCRSCP: 1 },
    pattern: "AAAB",
  },
  CARROT: {
    recipe: ["EARTH", "PLANT", "RAIN", "WATER"],
    gen: 2,
    tier: 4,
    cost: 220000,
    name: "Carrot",
    inventor: "rwck4.wam",
    date: "2021-04-01T01:10:09.000Z",
    order: 87,
    counts: { EARTH: 1, PLANT: 1, RAIN: 1, WATER: 1 },
    pattern: "ABCD",
  },
  CABBAGE: {
    recipe: ["EARTH", "EARTH", "PLANT", "WATER"],
    gen: 2,
    tier: 4,
    cost: 190000,
    name: "Cabbage",
    inventor: "kdoqy.wam",
    date: "2021-03-31T11:41:46.000Z",
    order: 88,
    counts: { EARTH: 2, PLANT: 1, WATER: 1 },
    pattern: "AABC",
  },
  CANABIS: {
    recipe: ["EARTH", "PLANT", "SUN", "SUN"],
    gen: 2,
    tier: 7,
    cost: 1390000,
    name: "Cannabis",
    inventor: "4wtqm.wam",
    date: "2021-04-24T09:26:33.000Z",
    order: 89,
    counts: { EARTH: 1, PLANT: 1, SUN: 2 },
    pattern: "AABC",
  },
  PAPER: {
    recipe: ["PLANT", "PLANT", "PRESS", "WATER"],
    gen: 2,
    tier: 4,
    cost: 370000,
    name: "Paper",
    inventor: "waxspservice",
    date: "2021-04-26T20:17:19.000Z",
    order: 90,
    counts: { PLANT: 2, PRESS: 1, WATER: 1 },
    pattern: "AABC",
  },
  OXYGEN: {
    recipe: ["ELECTRC", "METAL", "METAL", "WATER"],
    gen: 2,
    tier: 7,
    cost: 2770000,
    name: "Oxygen",
    inventor: "xqjam.wam",
    date: "2021-04-26T18:22:19.000Z",
    order: 91,
    counts: { ELECTRC: 1, METAL: 2, WATER: 1 },
    pattern: "AABC",
  },
  GARBAGE: {
    recipe: ["AIR", "EARTH", "FIRE", "WATER"],
    gen: 2,
    tier: 2,
    cost: 40000,
    name: "Garbage",
    inventor: "2ltay.wam",
    date: "2021-03-31T04:16:15.000Z",
    order: 92,
    counts: { AIR: 1, EARTH: 1, FIRE: 1, WATER: 1 },
    pattern: "ABCD",
  },
  VIRUS: {
    recipe: ["GARBAGE", "GARBAGE", "GARBAGE", "SUN"],
    gen: 2,
    tier: 7,
    cost: 730000,
    name: "Virus",
    inventor: "y3zra.wam",
    date: "2021-05-10T14:12:09.000Z",
    order: 93,
    counts: { GARBAGE: 3, SUN: 1 },
    pattern: "AAAB",
  },
  SALT: {
    recipe: ["FIRE", "FIRE", "SEA", "WATER"],
    gen: 2,
    tier: 3,
    cost: 70000,
    name: "Salt",
    inventor: "ivankazmenko",
    date: "2021-05-01T18:54:52.000Z",
    order: 94,
    counts: { FIRE: 2, SEA: 1, WATER: 1 },
    pattern: "AABC",
  },
  POT: {
    recipe: ["PRESS", "PRESS", "TNPLT", "TNPLT"],
    gen: 2,
    tier: 7,
    cost: 1660000,
    name: "Pot",
    inventor: "1y1qy.wam",
    date: "2021-04-08T00:58:37.000Z",
    order: 95,
    counts: { PRESS: 2, TNPLT: 2 },
    pattern: "AABB",
  },
  GUN: {
    recipe: ["BULLET", "BULLET", "EXPLOSN", "STEEL"],
    gen: 2,
    tier: 7,
    cost: 4480000,
    name: "Gun",
    inventor: "rrwb.wam",
    date: "2021-04-25T12:48:38.000Z",
    order: 96,
    counts: { BULLET: 2, EXPLOSN: 1, STEEL: 1 },
    pattern: "AABC",
  },
  BROOM: {
    recipe: ["KNIFE", "PLANT", "PLANT", "TREE"],
    gen: 2,
    tier: 9,
    cost: 4930000,
    name: "Broom",
    inventor: "pzlb4.wam",
    date: "2021-05-24T13:44:48.000Z",
    order: 97,
    counts: { KNIFE: 1, PLANT: 2, TREE: 1 },
    pattern: "AABC",
  },
  BLOCK: {
    recipe: ["CLAY", "STONE", "WATER", "WATER"],
    gen: 2,
    tier: 6,
    cost: 760000,
    name: "Block",
    inventor: "xqjam.wam",
    date: "2021-05-22T15:48:25.000Z",
    order: 98,
    counts: { CLAY: 1, STONE: 1, WATER: 2 },
    pattern: "AABC",
  },
  CHAIN: {
    recipe: ["FIRE", "PRESS", "STEEL", "WATER"],
    gen: 2,
    tier: 7,
    cost: 1030000,
    name: "Chain",
    inventor: "3yzay.wam",
    date: "2021-05-11T21:45:49.000Z",
    order: 99,
    counts: { FIRE: 1, PRESS: 1, STEEL: 1, WATER: 1 },
    pattern: "ABCD",
  },
  BLCKCHN: {
    recipe: ["BLOCK", "BLOCK", "BLOCK", "CHAIN"],
    gen: 2,
    tier: 8,
    cost: 3310000,
    name: "Blockchain",
    inventor: "vaaaaaaaaaan",
    date: "2021-05-22T15:53:25.000Z",
    order: 100,
    counts: { BLOCK: 3, CHAIN: 1 },
    pattern: "AAAB",
  },
  SHIT: {
    recipe: ["CABBAGE", "CARROT", "LIFE", "TIME"],
    gen: 2,
    tier: 8,
    cost: 5410000,
    name: "Shit",
    inventor: "hnbb2.wam",
    date: "2021-05-12T10:23:33.000Z",
    order: 101,
    counts: { CABBAGE: 1, CARROT: 1, LIFE: 1, TIME: 1 },
    pattern: "ABCD",
  },
  GOLD: {
    recipe: ["SHIT", "SHIT", "SHIT", "SHIT"],
    gen: 2,
    tier: 9,
    cost: 21640000,
    name: "Gold",
    inventor: "hnbb2.wam",
    date: "2021-05-12T11:12:45.000Z",
    order: 102,
    counts: { SHIT: 4 },
    pattern: "AAAA",
  },
  GRPHITE: {
    recipe: ["EARTH", "EARTH", "EARTH", "EXPLOSN"],
    gen: 2,
    tier: 5,
    cost: 340000,
    name: "Graphite",
    inventor: "gkbdu.wam",
    date: "2021-05-31T12:27:09.000Z",
    order: 103,
    counts: { EARTH: 3, EXPLOSN: 1 },
    pattern: "AAAB",
  },
  BATTERY: {
    recipe: ["ELECTRC", "ELECTRC", "GRPHITE", "GRPHITE"],
    gen: 2,
    tier: 7,
    cost: 3520000,
    name: "Battery",
    inventor: "jd3qy.wam",
    date: "2021-05-31T12:58:43.000Z",
    order: 104,
    counts: { ELECTRC: 2, GRPHITE: 2 },
    pattern: "AABB",
  },
  CALCLTR: {
    recipe: ["BATTERY", "ELECTRC", "GLASS", "TNPLT"],
    gen: 2,
    tier: 8,
    cost: 6220000,
    name: "Calculator",
    inventor: "waxkingluffy",
    date: "2021-06-02T09:42:38.000Z",
    order: 105,
    counts: { BATTERY: 1, ELECTRC: 1, GLASS: 1, TNPLT: 1 },
    pattern: "ABCD",
  },
  BITCOIN: {
    recipe: ["AIR", "BLCKCHN", "CALCLTR", "ELECTRC"],
    gen: 2,
    tier: 9,
    cost: 10960000,
    name: "Bitcoin",
    inventor: "2ltaystorage",
    date: "2021-06-02T16:32:41.000Z",
    order: 106,
    counts: { AIR: 1, BLCKCHN: 1, CALCLTR: 1, ELECTRC: 1 },
    pattern: "ABCD",
  },
  RADITON: {
    recipe: ["SUN", "SUN", "SUN", "SUN"],
    gen: 2,
    tier: 7,
    cost: 2440000,
    name: "Radiation",
    inventor: "gpfay.wam",
    date: "2021-04-04T11:49:26.000Z",
    order: 107,
    counts: { SUN: 4 },
    pattern: "AAAA",
  },
  ATMCBMB: {
    recipe: ["EXPLOSN", "EXPLOSN", "EXPLOSN", "RADITON"],
    gen: 2,
    tier: 8,
    cost: 3370000,
    name: "Atomic Bomb",
    inventor: "cmlr.wam",
    date: "2021-04-07T21:50:30.000Z",
    order: 108,
    counts: { EXPLOSN: 3, RADITON: 1 },
    pattern: "AAAB",
  },
  TEQUILA: {
    recipe: ["ALCOHOL", "CACTUS", "PRESS", "WATER"],
    gen: 2,
    tier: 8,
    cost: 1600000,
    name: "Tequila",
    inventor: "rekowaxbrave",
    date: "2021-05-03T06:46:21.000Z",
    order: 109,
    counts: { ALCOHOL: 1, CACTUS: 1, PRESS: 1, WATER: 1 },
    pattern: "ABCD",
  },
  DRKGLAS: {
    recipe: ["FIRE", "FIRE", "OBSIDIN", "OBSIDIN"],
    gen: 2,
    tier: 4,
    cost: 280000,
    name: "Dark Glass",
    inventor: "3diau.wam",
    date: "2021-03-31T05:52:20.000Z",
    order: 110,
    counts: { FIRE: 2, OBSIDIN: 2 },
    pattern: "AABB",
  },
  GLASSES: {
    recipe: ["DRKGLAS", "DRKGLAS", "METAL", "METAL"],
    gen: 2,
    tier: 6,
    cost: 1900000,
    name: "Glasses",
    inventor: "pt.ay.wam",
    date: "2021-04-06T07:24:00.000Z",
    order: 111,
    counts: { DRKGLAS: 2, METAL: 2 },
    pattern: "AABB",
  },
  PLASMA: {
    recipe: ["HEAT", "HEAT", "HEAT", "SUN"],
    gen: 2,
    tier: 7,
    cost: 730000,
    name: "Plasma",
    inventor: "2ltaystorage",
    date: "2021-05-11T15:10:48.000Z",
    order: 112,
    counts: { HEAT: 3, SUN: 1 },
    pattern: "AAAB",
  },
  REEDPIP: {
    recipe: ["KNIFE", "SOUND", "TREE", "WIND"],
    gen: 2,
    tier: 9,
    cost: 5560000,
    name: "Reed Pipe",
    inventor: "vaaaaaaaaaan",
    date: "2021-05-17T04:55:50.000Z",
    order: 113,
    counts: { KNIFE: 1, SOUND: 1, TREE: 1, WIND: 1 },
    pattern: "ABCD",
  },
  MUSIC: {
    recipe: ["REEDPIP", "REEDPIP", "SOUND", "SOUND"],
    gen: 2,
    tier: 10,
    cost: 12760000,
    name: "Music",
    inventor: "rekowaxbrave",
    date: "2021-05-17T06:06:57.000Z",
    order: 114,
    counts: { REEDPIP: 2, SOUND: 2 },
    pattern: "AABB",
  },
  RAINBOW: {
    recipe: ["HORIZON", "RAIN", "SKY", "SUN"],
    gen: 2,
    tier: 8,
    cost: 2770000,
    name: "Rainbow",
    inventor: "cauemottawax",
    date: "2021-04-26T22:25:13.000Z",
    order: 115,
    counts: { HORIZON: 1, RAIN: 1, SKY: 1, SUN: 1 },
    pattern: "ABCD",
  },
  PRISM: {
    recipe: ["GLASS", "LENS", "RAINBOW", "SUN"],
    gen: 2,
    tier: 9,
    cost: 4900000,
    name: "Prism",
    inventor: "vaaaaaaaaaan",
    date: "2021-05-16T04:08:18.000Z",
    order: 116,
    counts: { GLASS: 1, LENS: 1, RAINBOW: 1, SUN: 1 },
    pattern: "ABCD",
  },
  PAINT: {
    recipe: ["RAINBOW", "WATER", "WATER", "WATER"],
    gen: 2,
    tier: 9,
    cost: 2800000,
    name: "Paint",
    inventor: "2tmay.wam",
    date: "2021-04-27T00:10:23.000Z",
    order: 117,
    counts: { RAINBOW: 1, WATER: 3 },
    pattern: "AAAB",
  },
  SMOKE: {
    recipe: ["AIR", "FIRE", "PLANT", "PLANT"],
    gen: 2,
    tier: 4,
    cost: 340000,
    name: "Smoke",
    inventor: "pddra.wam",
    date: "2021-05-17T01:17:34.000Z",
    order: 118,
    counts: { AIR: 1, FIRE: 1, PLANT: 2 },
    pattern: "AABC",
  },
  PIPE: {
    recipe: ["KNIFE", "SMOKE", "TOBACCO", "TREE"],
    gen: 2,
    tier: 9,
    cost: 5290000,
    name: "Pipe",
    inventor: "vaaaaaaaaaan",
    date: "2021-05-17T01:38:27.000Z",
    order: 119,
    counts: { KNIFE: 1, SMOKE: 1, TOBACCO: 1, TREE: 1 },
    pattern: "ABCD",
  },
  FLOUR: {
    recipe: ["PRESS", "WHEAT", "WHEAT", "WHEAT"],
    gen: 2,
    tier: 8,
    cost: 2500000,
    name: "Flour",
    inventor: "hgfqq.wam",
    date: "2021-04-04T20:34:46.000Z",
    order: 120,
    counts: { PRESS: 1, WHEAT: 3 },
    pattern: "AAAB",
  },
  BREAD: {
    recipe: ["FLOUR", "HEAT", "POT", "WATER"],
    gen: 2,
    tier: 9,
    cost: 4210000,
    name: "Bread",
    inventor: "hgfqq.wam",
    date: "2021-04-19T04:29:49.000Z",
    order: 121,
    counts: { FLOUR: 1, HEAT: 1, POT: 1, WATER: 1 },
    pattern: "ABCD",
  },
  FLSHLGH: {
    recipe: ["BATTERY", "BULB", "GLASS", "TNPLT"],
    gen: 2,
    tier: 8,
    cost: 8050000,
    name: "Flashlight",
    inventor: "jd3qy.wam",
    date: "2021-05-31T13:04:18.000Z",
    order: 122,
    counts: { BATTERY: 1, BULB: 1, GLASS: 1, TNPLT: 1 },
    pattern: "ABCD",
  },
  KITE: {
    recipe: ["KNIFE", "PAPER", "PAPER", "WIND"],
    gen: 2,
    tier: 9,
    cost: 4690000,
    name: "Kite",
    inventor: "horizongamer",
    date: "2021-06-08T09:14:29.000Z",
    order: 123,
    counts: { KNIFE: 1, PAPER: 2, WIND: 1 },
    pattern: "AABC",
  },
  MIRROR: {
    recipe: ["GLASS", "GLASS", "METAL", "PAINT"],
    gen: 2,
    tier: 10,
    cost: 4450000,
    name: "Mirror",
    inventor: "vaaaaaaaaaan",
    date: "2021-05-30T13:47:52.000Z",
    order: 124,
    counts: { GLASS: 2, METAL: 1, PAINT: 1 },
    pattern: "AABC",
  },
  MONEY: {
    recipe: ["GOLD", "GOLD", "PAPER", "PRESS"],
    gen: 2,
    tier: 10,
    cost: 43690000,
    name: "Money",
    inventor: "unbehindable",
    date: "2021-05-18T12:13:00.000Z",
    order: 125,
    counts: { GOLD: 2, PAPER: 1, PRESS: 1 },
    pattern: "AABC",
  },
  PENCIL: {
    recipe: ["GRPHITE", "GRPHITE", "KNIFE", "TREE"],
    gen: 2,
    tier: 9,
    cost: 5290000,
    name: "Pencil",
    inventor: "vaaaaaaaaaan",
    date: "2021-05-31T12:54:54.000Z",
    order: 126,
    counts: { GRPHITE: 2, KNIFE: 1, TREE: 1 },
    pattern: "AABC",
  },
  TSUNAMI: {
    recipe: ["EARTHQ", "OCEAN", "WAVE", "WAVE"],
    gen: 2,
    tier: 5,
    cost: 1060000,
    name: "Tsunami",
    inventor: "xqjam.wam",
    date: "2021-03-31T05:15:14.000Z",
    order: 127,
    counts: { EARTHQ: 1, OCEAN: 1, WAVE: 2 },
    pattern: "AABC",
  },
  ALARM: {
    recipe: ["SOUND", "SOUND", "SOUND", "TIME"],
    gen: 2,
    tier: 8,
    cost: 7180000,
    name: "Alarm",
    inventor: "2tmay.wam",
    date: "2021-04-20T21:45:05.000Z",
    order: 128,
    counts: { SOUND: 3, TIME: 1 },
    pattern: "AAAB",
  },
  BUBBLES: {
    recipe: ["GAS", "PRESS", "WATER", "WATER"],
    gen: 2,
    tier: 6,
    cost: 970000,
    name: "Bubbles",
    inventor: "mzgc4.wam",
    date: "2021-06-16T22:39:29.000Z",
    order: 129,
    counts: { GAS: 1, PRESS: 1, WATER: 2 },
    pattern: "AABC",
  },
  SODA: {
    recipe: ["BUBBLES", "TNPLT", "WATER", "WATER"],
    gen: 2,
    tier: 7,
    cost: 1780000,
    name: "Soda",
    inventor: "eqxo.wam",
    date: "2021-06-16T23:00:33.000Z",
    order: 130,
    counts: { BUBBLES: 1, TNPLT: 1, WATER: 2 },
    pattern: "AABC",
  },
  BACTERI: {
    recipe: ["HEAT", "LIFE", "SWAMP", "WATER"],
    gen: 2,
    tier: 5,
    cost: 580000,
    name: "Bacteria",
    inventor: "noqaw.wam",
    date: "2021-06-23T09:48:26.000Z",
    order: 131,
    counts: { HEAT: 1, LIFE: 1, SWAMP: 1, WATER: 1 },
    pattern: "ABCD",
  },
  OZONE: {
    recipe: ["ATMSPHE", "ATMSPHE", "ELECTRC", "ELECTRC"],
    gen: 2,
    tier: 7,
    cost: 3100000,
    name: "Ozone",
    inventor: "fxkb2.wam",
    date: "2021-07-04T01:17:21.000Z",
    order: 132,
    counts: { ATMSPHE: 2, ELECTRC: 2 },
    pattern: "AABB",
  },
  BOOK: {
    recipe: ["PAPER", "PAPER", "PAPER", "PENCIL"],
    gen: 2,
    tier: 10,
    cost: 6400000,
    name: "Book",
    inventor: "vaaaaaaaaaan",
    date: "2021-05-31T12:56:02.000Z",
    order: 133,
    counts: { PAPER: 3, PENCIL: 1 },
    pattern: "AAAB",
  },
  CABLE: {
    recipe: ["ELECTRC", "METAL", "METAL", "PAPER"],
    gen: 2,
    tier: 7,
    cost: 3130000,
    name: "Cable",
    inventor: "jd3qy.wam",
    date: "2021-06-03T22:11:09.000Z",
    order: 134,
    counts: { ELECTRC: 1, METAL: 2, PAPER: 1 },
    pattern: "AABC",
  },
  PAINTNG: {
    recipe: ["PAINT", "PAINT", "PAPER", "PENCIL"],
    gen: 2,
    tier: 10,
    cost: 11260000,
    name: "Painting",
    inventor: "hq3r2.wam",
    date: "2021-05-31T13:00:21.000Z",
    order: 135,
    counts: { PAINT: 2, PAPER: 1, PENCIL: 1 },
    pattern: "AABC",
  },
  NFT: {
    recipe: ["BLCKCHN", "BUBBLES", "MONEY", "PAINTNG"],
    gen: 2,
    tier: 11,
    cost: 59230000,
    name: "NFT",
    inventor: "bzraw.wam",
    date: "2021-06-18T11:56:52.000Z",
    order: 136,
    counts: { BLCKCHN: 1, BUBBLES: 1, MONEY: 1, PAINTNG: 1 },
    pattern: "ABCD",
  },
  HILL: {
    recipe: ["GRASS", "GRASS", "MNTAIN", "MOSS"],
    gen: 2,
    tier: 7,
    cost: 1420000,
    name: "Hill",
    inventor: "c4dqw.wam",
    date: "2021-06-07T21:43:43.000Z",
    order: 137,
    counts: { GRASS: 2, MNTAIN: 1, MOSS: 1 },
    pattern: "AABC",
  },
  UPLIFT: {
    recipe: ["", "", "", ""],
    gen: 2,
    tier: 0,
    cost: 0,
    name: "Upliftium",
    inventor: "",
    date: "",
    order: 138,
    counts: {},
    pattern: "",
  },
  CRUDE: {
    recipe: ["EARTH", "PLANT", "PRESS", "TREE"],
    gen: 3,
    tier: 8,
    cost: 1000000,
    name: "Crude",
    inventor: "wpsca.wam",
    date: "2021-07-11T02:56:06.000Z",
    order: 139,
    counts: { EARTH: 1, PLANT: 1, PRESS: 1, TREE: 1 },
    pattern: "ABCD",
  },
  PLASTIC: {
    recipe: ["CRUDE", "GAS", "HEAT", "HEAT"],
    gen: 3,
    tier: 9,
    cost: 1990000,
    name: "Plastic",
    inventor: "c4.r4.wam",
    date: "2021-07-11T03:50:32.000Z",
    order: 140,
    counts: { CRUDE: 1, GAS: 1, HEAT: 2 },
    pattern: "AABC",
  },
  SCISSOR: {
    recipe: ["BLADE", "BLADE", "METAL", "PLASTIC"],
    gen: 3,
    tier: 10,
    cost: 4900000,
    name: "Scissors",
    inventor: "vaaaaaaaaaan",
    date: "2021-07-11T03:53:27.000Z",
    order: 141,
    counts: { BLADE: 2, METAL: 1, PLASTIC: 1 },
    pattern: "AABC",
  },
  DRUM: {
    recipe: ["PLASTIC", "SOUND", "TREE", "TREE"],
    gen: 3,
    tier: 10,
    cost: 4390000,
    name: "Drum",
    inventor: "otydy.wam",
    date: "2021-07-11T04:54:37.000Z",
    order: 142,
    counts: { PLASTIC: 1, SOUND: 1, TREE: 2 },
    pattern: "AABC",
  },
  WIRE: {
    recipe: ["ELECTRC", "METAL", "METAL", "PRESS"],
    gen: 3,
    tier: 7,
    cost: 2800000,
    name: "Wire",
    inventor: ".fzqy.wam",
    date: "2021-06-27T21:58:06.000Z",
    order: 143,
    counts: { ELECTRC: 1, METAL: 2, PRESS: 1 },
    pattern: "AABC",
  },
  STRING: {
    recipe: ["SOUND", "SOUND", "SOUND", "WIRE"],
    gen: 3,
    tier: 8,
    cost: 5260000,
    name: "String",
    inventor: "t5qb2.wam",
    date: "2021-06-30T15:07:37.000Z",
    order: 144,
    counts: { SOUND: 3, WIRE: 1 },
    pattern: "AAAB",
  },
  PIANO: {
    recipe: ["MUSIC", "SOUND", "STRING", "TREE"],
    gen: 3,
    tier: 11,
    cost: 19630000,
    name: "Piano",
    inventor: "t5qb2.wam",
    date: "2021-07-01T13:35:36.000Z",
    order: 145,
    counts: { MUSIC: 1, SOUND: 1, STRING: 1, TREE: 1 },
    pattern: "ABCD",
  },
  SEED: {
    recipe: ["LIFE", "LIFE", "RAIN", "SUN"],
    gen: 3,
    tier: 7,
    cost: 1210000,
    name: "Seed",
    inventor: "yxnay.wam",
    date: "2021-06-26T11:09:57.000Z",
    order: 146,
    counts: { LIFE: 2, RAIN: 1, SUN: 1 },
    pattern: "AABC",
  },
  LIMSTON: {
    recipe: ["EARTH", "EARTH", "PRESS", "TIME"],
    gen: 3,
    tier: 8,
    cost: 4780000,
    name: "Limestone",
    inventor: "4umrg.wam",
    date: "2021-07-18T21:11:31.000Z",
    order: 147,
    counts: { EARTH: 2, PRESS: 1, TIME: 1 },
    pattern: "AABC",
  },
  CEMENT: {
    recipe: ["CLAY", "CLAY", "LIMSTON", "STONE"],
    gen: 3,
    tier: 9,
    cost: 6160000,
    name: "Cement",
    inventor: "psueg.wam",
    date: "2021-07-26T10:38:50.000Z",
    order: 148,
    counts: { CLAY: 2, LIMSTONE: 1, STONE: 1 },
    pattern: "AABC",
  },
  TILING: {
    recipe: ["CLAY", "CLAY", "HEAT", "HEAT"],
    gen: 3,
    tier: 6,
    cost: 1360000,
    name: "Tiling",
    inventor: "bzraw.wam",
    date: "2021-06-16T03:19:49.000Z",
    order: 149,
    counts: { CLAY: 2, HEAT: 2 },
    pattern: "AABB",
  },
  CONCRTE: {
    recipe: ["CEMENT", "SAND", "STONE", "WATER"],
    gen: 3,
    tier: 10,
    cost: 6730000,
    name: "Concrete",
    inventor: "5o1b2.wam",
    date: "2021-07-26T10:41:15.000Z",
    order: 150,
    counts: { CEMENT: 1, SAND: 1, STONE: 1, WATER: 1 },
    pattern: "ABCD",
  },
  BUILDNG: {
    recipe: ["BRICK", "CONCRTE", "TILING", "WALL"],
    gen: 3,
    tier: 11,
    cost: 8530000,
    name: "Building",
    inventor: "yhgb4.wam",
    date: "2021-07-26T11:26:22.000Z",
    order: 151,
    counts: { BRICK: 1, CONCRTE: 1, TILING: 1, WALL: 1 },
    pattern: "ABCD",
  },
  ALUMINM: {
    recipe: ["CLAY", "EARTH", "ELECTRC", "WATER"],
    gen: 3,
    tier: 7,
    cost: 2080000,
    name: "Aluminum",
    inventor: "skfrm.wam",
    date: "2021-08-10T18:44:57.000Z",
    order: 152,
    counts: { CLAY: 1, EARTH: 1, ELECTRC: 1, WATER: 1 },
    pattern: "ABCD",
  },
  HOP: {
    recipe: ["LIFE", "PLANT", "SUN", "TIME"],
    gen: 3,
    tier: 8,
    cost: 5770000,
    name: "Hop",
    inventor: "frcqu.wam",
    date: "2021-08-15T13:35:37.000Z",
    order: 153,
    counts: { LIFE: 1, PLANT: 1, SUN: 1, TIME: 1 },
    pattern: "ABCD",
  },
  BEER: {
    recipe: ["BACTERI", "HOP", "TIME", "WATER"],
    gen: 3,
    tier: 9,
    cost: 11080000,
    name: "Beer",
    inventor: "rplanetdevil",
    date: "2021-08-24T16:19:29.000Z",
    order: 154,
    counts: { BACTERI: 1, HOP: 1, TIME: 1, WATER: 1 },
    pattern: "ABCD",
  },
  PUB: {
    recipe: ["BEER", "BUILDNG", "TEQUILA", "WHISKEY"],
    gen: 3,
    tier: 12,
    cost: 25390000,
    name: "Pub",
    inventor: "pkuuc.wam",
    date: "2021-08-24T16:20:29.000Z",
    order: 155,
    counts: { BEER: 1, BUILDNG: 1, TEQUILA: 1, WHISKEY: 1 },
    pattern: "ABCD",
  },
  CLUB: {
    recipe: ["BUILDNG", "MUSIC", "MUSIC", "PUB"],
    gen: 3,
    tier: 13,
    cost: 59440000,
    name: "Club",
    inventor: "1puqy.wam",
    date: "2021-08-26T11:26:48.000Z",
    order: 156,
    counts: { BUILDNG: 1, MUSIC: 2, PUB: 1 },
    pattern: "AABC",
  },
  LIBRARY: {
    recipe: ["BOOK", "BOOK", "BUILDNG", "BUILDNG"],
    gen: 3,
    tier: 12,
    cost: 29860000,
    name: "Library",
    inventor: "kdoqy.wam",
    date: "2021-07-27T03:05:02.000Z",
    order: 157,
    counts: { BOOK: 2, BUILDNG: 2 },
    pattern: "AABB",
  },
  IQ: {
    recipe: ["BOOK", "CALCLTR", "CARROT", "MCRSCP"],
    gen: 3,
    tier: 11,
    cost: 15700000,
    name: "Iq",
    inventor: "tdfay.wam",
    date: "2021-09-23T15:06:44.000Z",
    order: 158,
    counts: { BOOK: 1, CALCLTR: 1, CARROT: 1, MCRSCP: 1 },
    pattern: "ABCD",
  },
  SCIENCE: {
    recipe: ["BOOK", "BOOK", "BOOK", "IQ"],
    gen: 3,
    tier: 12,
    cost: 34900000,
    name: "Science",
    inventor: "vaaaaaaaaaan",
    date: "2021-09-28T16:09:02.000Z",
    order: 159,
    counts: { BOOK: 3, IQ: 1 },
    pattern: "AAAB",
  },
  GASOLIN: {
    recipe: ["CRUDE", "HEAT", "PRESS", "STEAM"],
    gen: 3,
    tier: 9,
    cost: 1120000,
    name: "Gasoline",
    inventor: "dz2aw.wam",
    date: "2021-07-11T13:10:19.000Z",
    order: 160,
    counts: { CRUDE: 1, HEAT: 1, PRESS: 1, STEAM: 1 },
    pattern: "ABCD",
  },
  SEAWEED: {
    recipe: ["OCEAN", "PLANT", "RIVER", "SEA"],
    gen: 3,
    tier: 8,
    cost: 1390000,
    name: "Seaweed",
    inventor: "1puqy.wam",
    date: "2021-06-16T15:48:40.000Z",
    order: 161,
    counts: { OCEAN: 1, PLANT: 1, RIVER: 1, SEA: 1 },
    pattern: "ABCD",
  },
  ENGINE: {
    recipe: ["ALUMINM", "GASOLIN", "IQ", "METAL"],
    gen: 3,
    tier: 12,
    cost: 19570000,
    name: "Engine",
    inventor: "ijar.wam",
    date: "2021-09-30T18:42:23.000Z",
    order: 162,
    counts: { ALUMINM: 1, GASOLIN: 1, IQ: 1, METAL: 1 },
    pattern: "ABCD",
  },
  IDEA: {
    recipe: ["BEACH", "BULB", "CANABIS", "IQ"],
    gen: 3,
    tier: 12,
    cost: 21730000,
    name: "Idea",
    inventor: "xqjam.wam",
    date: "2021-10-15T12:58:14.000Z",
    order: 163,
    counts: { BEACH: 1, BULB: 1, CANABIS: 1, IQ: 1 },
    pattern: "ABCD",
  },
  PICKAX: {
    recipe: ["METAL", "PRESS", "TREE", "TREE"],
    gen: 3,
    tier: 8,
    cost: 2290000,
    name: "Pickax",
    inventor: "dn3rg.wam",
    date: "2021-06-17T10:39:24.000Z",
    order: 164,
    counts: { METAL: 1, PRESS: 1, TREE: 2 },
    pattern: "AABC",
  },
  HAMMER: {
    recipe: ["METAL", "METAL", "TREE", "TREE"],
    gen: 3,
    tier: 8,
    cost: 2920000,
    name: "Hammer",
    inventor: "sxyqy.wam",
    date: "2021-06-15T22:04:03.000Z",
    order: 165,
    counts: { METAL: 2, TREE: 2 },
    pattern: "AABB",
  },
  DEATH: {
    recipe: ["GRENADE", "GUN", "STORM", "VIRUS"],
    gen: 3,
    tier: 8,
    cost: 7180000,
    name: "Death",
    inventor: "evqum.wam",
    date: "2021-11-06T22:17:13.000Z",
    order: 166,
    counts: { GRENADE: 1, GUN: 1, STORM: 1, VIRUS: 1 },
    pattern: "ABCD",
  },
  SILICON: {
    recipe: ["EARTH", "EARTH", "PICKAX", "PICKAX"],
    gen: 3,
    tier: 9,
    cost: 4600000,
    name: "Silicon",
    inventor: "goyrc.wam",
    date: "2021-08-27T03:47:48.000Z",
    order: 167,
    counts: { EARTH: 2, PICKAX: 2 },
    pattern: "AABB",
  },
  MUMMY: {
    recipe: ["", "", "", ""],
    gen: 3,
    tier: 0,
    cost: 0,
    name: "Mummy",
    inventor: "",
    date: "",
    order: 168,
    counts: {},
    pattern: "",
  },
  PLANET: {
    recipe: ["ARCHIP", "ARCHIP", "HORIZON", "OCEAN"],
    gen: 3,
    tier: 8,
    cost: 5800000,
    name: "Planet",
    inventor: "xqgbi.wam",
    date: "2021-09-14T16:44:01.000Z",
    order: 169,
    counts: { ARCHIP: 2, HORIZON: 1, OCEAN: 1 },
    pattern: "AABC",
  },
  FLOWER: {
    recipe: ["PAINT", "PLANT", "SEED", "SUN"],
    gen: 3,
    tier: 10,
    cost: 4780000,
    name: "Flower",
    inventor: "4nkr.wam",
    date: "2021-08-17T17:02:45.000Z",
    order: 170,
    counts: { PAINT: 1, PLANT: 1, SEED: 1, SUN: 1 },
    pattern: "ABCD",
  },
  MOON: {
    recipe: ["EARTH", "FLAG", "PLANET", "VACUUM"],
    gen: 3,
    tier: 11,
    cost: 16570000,
    name: "Moon",
    inventor: "xqjam.wam",
    date: "2021-09-17T08:42:56.000Z",
    order: 171,
    counts: { EARTH: 1, FLAG: 1, PLANET: 1, VACUUM: 1 },
    pattern: "ABCD",
  },
  LIGHT: {
    recipe: ["", "", "", ""],
    gen: 3,
    tier: 0,
    cost: 0,
    name: "Light",
    inventor: "",
    date: "",
    order: 172,
    counts: {},
    pattern: "",
  },
  GRAPE: {
    recipe: ["HILL", "RAIN", "SEED", "SUN"],
    gen: 3,
    tier: 8,
    cost: 3280000,
    name: "Grape",
    inventor: "h4lra.wam",
    date: "2021-06-26T11:34:51.000Z",
    order: 173,
    counts: { HILL: 1, RAIN: 1, SEED: 1, SUN: 1 },
    pattern: "ABCD",
  },
  SULFUR: {
    recipe: ["EARTH", "SMOKE", "VULCANO", "VULCANO"],
    gen: 3,
    tier: 6,
    cost: 1330000,
    name: "Sulfur",
    inventor: "5czbk.wam",
    date: "2021-08-06T13:45:26.000Z",
    order: 174,
    counts: { EARTH: 1, SMOKE: 1, VULCANO: 2 },
    pattern: "AABC",
  },
  PEAT: {
    recipe: ["PLANT", "SUN", "SWAMP", "SWAMP"],
    gen: 3,
    tier: 7,
    cost: 1270000,
    name: "Peat",
    inventor: "dyqqy.wam",
    date: "2021-06-17T18:37:14.000Z",
    order: 175,
    counts: { PLANT: 1, SUN: 1, SWAMP: 2 },
    pattern: "AABC",
  },
  PETRID: {
    recipe: ["BACTERI", "GLASS", "GLASS", "GLASS"],
    gen: 3,
    tier: 6,
    cost: 2050000,
    name: "Petri Dish",
    inventor: "noqaw.wam",
    date: "2021-06-23T10:26:18.000Z",
    order: 176,
    counts: { BACTERI: 1, GLASS: 3 },
    pattern: "AAAB",
  },
  GOLEM: {
    recipe: ["CLAY", "CLAY", "LIFE", "STONE"],
    gen: 3,
    tier: 6,
    cost: 1660000,
    name: "Golem",
    inventor: "cmlr.wam",
    date: "2021-06-21T16:06:53.000Z",
    order: 177,
    counts: { CLAY: 2, LIFE: 1, STONE: 1 },
    pattern: "AABC",
  },
  SPACE: {
    recipe: ["DUST", "TIME", "VACUUM", "VACUUM"],
    gen: 3,
    tier: 8,
    cost: 7240000,
    name: "Space",
    inventor: "5kcbg.wam",
    date: "2021-10-22T23:12:20.000Z",
    order: 178,
    counts: { DUST: 1, TIME: 1, VACUUM: 2 },
    pattern: "AABC",
  },
  ALIEN: {
    recipe: ["LIFE", "PLANET", "RADITON", "SPACE"],
    gen: 3,
    tier: 9,
    cost: 15760000,
    name: "Alien",
    inventor: "khqd2.wam",
    date: "2021-10-23T00:09:37.000Z",
    order: 179,
    counts: { LIFE: 1, PLANET: 1, RADITON: 1, SPACE: 1 },
    pattern: "ABCD",
  },
  BIGBANG: {
    recipe: ["EXPLOSN", "SCIENCE", "SPACE", "TIME"],
    gen: 3,
    tier: 13,
    cost: 47170000,
    name: "Big Bang",
    inventor: "ijar.wam",
    date: "2021-10-25T18:43:35.000Z",
    order: 180,
    counts: { EXPLOSN: 1, SCIENCE: 1, SPACE: 1, TIME: 1 },
    pattern: "ABCD",
  },
  CELL: {
    recipe: ["BACTERI", "LIFE", "MCRSCP", "PETRID"],
    gen: 3,
    tier: 7,
    cost: 5770000,
    name: "Cell",
    inventor: "1puqy.wam",
    date: "2021-07-03T07:54:00.000Z",
    order: 181,
    counts: { BACTERI: 1, LIFE: 1, MCRSCP: 1, PETRID: 1 },
    pattern: "ABCD",
  },
  WINE: {
    recipe: ["BACTERI", "GRAPE", "TIME", "WATER"],
    gen: 3,
    tier: 9,
    cost: 8590000,
    name: "Wine",
    inventor: "c4.r4.wam",
    date: "2021-08-24T20:14:37.000Z",
    order: 182,
    counts: { BACTERI: 1, GRAPE: 1, TIME: 1, WATER: 1 },
    pattern: "ABCD",
  },
  SHELL: {
    recipe: ["", "", "", ""],
    gen: 3,
    tier: 0,
    cost: 0,
    name: "Shell",
    inventor: "",
    date: "",
    order: 183,
    counts: {},
    pattern: "",
  },
  SUNFLWR: {
    recipe: ["EARTH", "PLANT", "SEED", "SUN"],
    gen: 3,
    tier: 7,
    cost: 1990000,
    name: "Sunflower",
    inventor: "3fiue.wam",
    date: "2021-06-26T20:36:18.000Z",
    order: 184,
    counts: { EARTH: 1, PLANT: 1, SEED: 1, SUN: 1 },
    pattern: "ABCD",
  },
  CHMPAGN: {
    recipe: ["BACTERI", "GEYSER", "WATER", "WINE"],
    gen: 3,
    tier: 10,
    cost: 9370000,
    name: "Champagne",
    inventor: "xqjam.wam",
    date: "2021-10-26T18:44:54.000Z",
    order: 185,
    counts: { BACTERI: 1, GEYSER: 1, WATER: 1, WINE: 1 },
    pattern: "ABCD",
  },
  COMET: {
    recipe: ["", "", "", ""],
    gen: 3,
    tier: 0,
    cost: 0,
    name: "Comet",
    inventor: "",
    date: "",
    order: 186,
    counts: {},
    pattern: "",
  },
  MARS: {
    recipe: ["", "", "", ""],
    gen: 3,
    tier: 0,
    cost: 0,
    name: "Mars",
    inventor: "",
    date: "",
    order: 187,
    counts: {},
    pattern: "",
  },
  AMBER: {
    recipe: ["", "", "", ""],
    gen: 3,
    tier: 0,
    cost: 0,
    name: "Amber",
    inventor: "",
    date: "",
    order: 188,
    counts: {},
    pattern: "",
  },
  RESIN: {
    recipe: ["", "", "", ""],
    gen: 3,
    tier: 0,
    cost: 0,
    name: "Resin",
    inventor: "",
    date: "",
    order: 189,
    counts: {},
    pattern: "",
  },
  SYRINGE: {
    recipe: ["METAL", "PLASTIC", "PLASTIC", "PRESS"],
    gen: 3,
    tier: 10,
    cost: 4690000,
    name: "Syringe",
    inventor: "xqjam.wam",
    date: "2021-07-11T15:53:25.000Z",
    order: 190,
    counts: { METAL: 1, PLASTIC: 2, PRESS: 1 },
    pattern: "AABC",
  },
  BONE: {
    recipe: ["", "", "", ""],
    gen: 3,
    tier: 0,
    cost: 0,
    name: "Bone",
    inventor: "",
    date: "",
    order: 191,
    counts: {},
    pattern: "",
  },
  METORIT: {
    recipe: ["", "", "", ""],
    gen: 3,
    tier: 0,
    cost: 0,
    name: "Meteorite",
    inventor: "",
    date: "",
    order: 192,
    counts: {},
    pattern: "",
  },
  OIL: {
    recipe: ["BOILER", "PRESS", "SUNFLWR", "SUNFLWR"],
    gen: 3,
    tier: 8,
    cost: 4780000,
    name: "Oil",
    inventor: "sx2ba.wam",
    date: "2021-06-28T22:35:46.000Z",
    order: 193,
    counts: { BOILER: 1, PRESS: 1, SUNFLWR: 2 },
    pattern: "AABC",
  },
  SALAD: {
    recipe: ["CABBAGE", "CARROT", "OIL", "SALT"],
    gen: 3,
    tier: 9,
    cost: 5260000,
    name: "Salad",
    inventor: "sx2ba.wam",
    date: "2021-06-28T22:43:52.000Z",
    order: 194,
    counts: { CABBAGE: 1, CARROT: 1, OIL: 1, SALT: 1 },
    pattern: "ABCD",
  },
  TSHIRT: {
    recipe: ["CLOTH", "PAINT", "SCISSOR", "SCISSOR"],
    gen: 3,
    tier: 11,
    cost: 16900000,
    name: "T-shirt",
    inventor: "otydy.wam",
    date: "2021-07-12T13:50:31.000Z",
    order: 195,
    counts: { CLOTH: 1, PAINT: 1, SCISSOR: 2 },
    pattern: "AABC",
  },
  MARKER: {
    recipe: ["ALCOHOL", "CLOTH", "PAINT", "PLASTIC"],
    gen: 3,
    tier: 10,
    cost: 9280000,
    name: "Marker",
    inventor: "xqjam.wam",
    date: "2021-07-12T15:16:07.000Z",
    order: 196,
    counts: { ALCOHOL: 1, CLOTH: 1, PAINT: 1, PLASTIC: 1 },
    pattern: "ABCD",
  },
  CHESS: {
    recipe: ["", "", "", ""],
    gen: 3,
    tier: 0,
    cost: 0,
    name: "Chess",
    inventor: "",
    date: "",
    order: 197,
    counts: {},
    pattern: "",
  },
  STOOL: {
    recipe: ["HAMMER", "METAL", "TREE", "TREE"],
    gen: 3,
    tier: 9,
    cost: 5170000,
    name: "Stool",
    inventor: "jd3qy.wam",
    date: "2021-06-16T14:43:51.000Z",
    order: 198,
    counts: { HAMMER: 1, METAL: 1, TREE: 2 },
    pattern: "AABC",
  },
  HANGDRM: {
    recipe: ["", "", "", ""],
    gen: 3,
    tier: 0,
    cost: 0,
    name: "Hang dram",
    inventor: "",
    date: "",
    order: 199,
    counts: {},
    pattern: "",
  },
  CROWN: {
    recipe: ["", "", "", ""],
    gen: 3,
    tier: 0,
    cost: 0,
    name: "Crown",
    inventor: "",
    date: "",
    order: 200,
    counts: {},
    pattern: "",
  },
  PHTFILM: {
    recipe: ["", "", "", ""],
    gen: 3,
    tier: 0,
    cost: 0,
    name: "Photo film",
    inventor: "",
    date: "",
    order: 201,
    counts: {},
    pattern: "",
  },
  COIN: {
    recipe: ["", "", "", ""],
    gen: 3,
    tier: 0,
    cost: 0,
    name: "Coin",
    inventor: "",
    date: "",
    order: 202,
    counts: {},
    pattern: "",
  },
  BACKPAK: {
    recipe: ["CLOTH", "CLOTH", "CLOTH", "SCISSOR"],
    gen: 3,
    tier: 11,
    cost: 17800000,
    name: "Backpack",
    inventor: "e1nu.wam",
    date: "2021-07-11T20:13:27.000Z",
    order: 203,
    counts: { CLOTH: 3, SCISSOR: 1 },
    pattern: "AAAB",
  },
  ETHER: {
    recipe: ["", "", "", ""],
    gen: 3,
    tier: 0,
    cost: 0,
    name: "Ethereum",
    inventor: "",
    date: "",
    order: 204,
    counts: {},
    pattern: "",
  },
  APCLYPS: {
    recipe: ["", "", "", ""],
    gen: 3,
    tier: 0,
    cost: 0,
    name: "Apocalypse",
    inventor: "",
    date: "",
    order: 205,
    counts: {},
    pattern: "",
  },
  KETTLE: {
    recipe: ["ELECTRC", "HEAT", "METAL", "WATER"],
    gen: 3,
    tier: 7,
    cost: 2140000,
    name: "Kettle",
    inventor: "rinb2.wam",
    date: "2021-06-24T11:41:50.000Z",
    order: 206,
    counts: { ELECTRC: 1, HEAT: 1, METAL: 1, WATER: 1 },
    pattern: "ABCD",
  },
  DINOSAR: {
    recipe: ["", "", "", ""],
    gen: 3,
    tier: 0,
    cost: 0,
    name: "Dinosaur",
    inventor: "",
    date: "",
    order: 207,
    counts: {},
    pattern: "",
  },
  VAPE: {
    recipe: ["", "", "", ""],
    gen: 3,
    tier: 0,
    cost: 0,
    name: "Vape",
    inventor: "",
    date: "",
    order: 208,
    counts: {},
    pattern: "",
  },
  SAFE: {
    recipe: ["", "", "", ""],
    gen: 3,
    tier: 0,
    cost: 0,
    name: "Safe",
    inventor: "",
    date: "",
    order: 209,
    counts: {},
    pattern: "",
  },
  RICE: {
    recipe: ["DEW", "EARTH", "PLANT", "RIVER"],
    gen: 3,
    tier: 8,
    cost: 1840000,
    name: "Rice",
    inventor: "irosfearwax1",
    date: "2021-09-18T21:49:05.000Z",
    order: 210,
    counts: { DEW: 1, EARTH: 1, PLANT: 1, RIVER: 1 },
    pattern: "ABCD",
  },
  LAMBO: {
    recipe: ["", "", "", ""],
    gen: 3,
    tier: 0,
    cost: 0,
    name: "Lambo",
    inventor: "",
    date: "",
    order: 211,
    counts: {},
    pattern: "",
  },
};

const RPLANET_TEMPLATES = [
  [170817, 211, 6, 6],
  [170816, 211, 6, 5],
  [170815, 211, 6, 4],
  [170814, 211, 6, 3],
  [170813, 211, 6, 2],
  [170812, 211, 6, 1],
  [170811, 211, 5, 6],
  [170810, 211, 5, 5],
  [170809, 211, 5, 4],
  [170808, 211, 5, 3],
  [170807, 211, 5, 2],
  [170806, 211, 5, 1],
  [170805, 211, 4, 6],
  [170804, 211, 4, 5],
  [170803, 211, 4, 4],
  [170802, 211, 4, 3],
  [170801, 211, 4, 2],
  [170800, 211, 4, 1],
  [170799, 211, 3, 6],
  [170798, 211, 3, 5],
  [170797, 211, 3, 4],
  [170796, 211, 3, 3],
  [170795, 211, 3, 2],
  [170794, 211, 3, 1],
  [170793, 211, 2, 6],
  [170792, 211, 2, 5],
  [170791, 211, 2, 4],
  [170790, 211, 2, 3],
  [170789, 211, 2, 2],
  [170788, 211, 2, 1],
  [170787, 211, 1, 6],
  [170786, 211, 1, 5],
  [170785, 211, 1, 4],
  [170784, 211, 1, 3],
  [170783, 211, 1, 2],
  [170782, 211, 1, 1],
  [170781, 210, 6, 6],
  [170780, 210, 6, 5],
  [170779, 210, 6, 4],
  [170778, 210, 6, 3],
  [170777, 210, 6, 2],
  [170776, 210, 6, 1],
  [170775, 210, 5, 6],
  [170774, 210, 5, 5],
  [170773, 210, 5, 4],
  [170772, 210, 5, 3],
  [170771, 210, 5, 2],
  [170770, 210, 5, 1],
  [170769, 210, 4, 6],
  [170768, 210, 4, 5],
  [170767, 210, 4, 4],
  [170766, 210, 4, 3],
  [170765, 210, 4, 2],
  [170764, 210, 4, 1],
  [170763, 210, 3, 6],
  [170762, 210, 3, 5],
  [170761, 210, 3, 4],
  [170760, 210, 3, 3],
  [170759, 210, 3, 2],
  [170758, 210, 3, 1],
  [170757, 210, 2, 6],
  [170756, 210, 2, 5],
  [170755, 210, 2, 4],
  [170754, 210, 2, 3],
  [170753, 210, 2, 2],
  [170752, 210, 2, 1],
  [170751, 210, 1, 6],
  [170750, 210, 1, 5],
  [170749, 210, 1, 4],
  [170748, 210, 1, 3],
  [170747, 210, 1, 2],
  [170746, 210, 1, 1],
  [170745, 209, 6, 6],
  [170744, 209, 6, 5],
  [170743, 209, 6, 4],
  [170742, 209, 6, 3],
  [170741, 209, 6, 2],
  [170740, 209, 6, 1],
  [170739, 209, 5, 6],
  [170738, 209, 5, 5],
  [170737, 209, 5, 4],
  [170736, 209, 5, 3],
  [170735, 209, 5, 2],
  [170734, 209, 5, 1],
  [170733, 209, 4, 6],
  [170732, 209, 4, 5],
  [170731, 209, 4, 4],
  [170730, 209, 4, 3],
  [170729, 209, 4, 2],
  [170728, 209, 4, 1],
  [170727, 209, 3, 6],
  [170726, 209, 3, 5],
  [170725, 209, 3, 4],
  [170724, 209, 3, 3],
  [170723, 209, 3, 2],
  [170722, 209, 3, 1],
  [170721, 209, 2, 6],
  [170720, 209, 2, 5],
  [170719, 209, 2, 4],
  [170718, 209, 2, 3],
  [170717, 209, 2, 2],
  [170716, 209, 2, 1],
  [170715, 209, 1, 6],
  [170714, 209, 1, 5],
  [170713, 209, 1, 4],
  [170712, 209, 1, 3],
  [170711, 209, 1, 2],
  [170710, 209, 1, 1],
  [170709, 208, 6, 6],
  [170708, 208, 6, 5],
  [170707, 208, 6, 4],
  [170706, 208, 6, 3],
  [170705, 208, 6, 2],
  [170704, 208, 6, 1],
  [170703, 208, 5, 6],
  [170702, 208, 5, 5],
  [170701, 208, 5, 4],
  [170700, 208, 5, 3],
  [170699, 208, 5, 2],
  [170698, 208, 5, 1],
  [170697, 208, 4, 6],
  [170696, 208, 4, 5],
  [170695, 208, 4, 4],
  [170694, 208, 4, 3],
  [170693, 208, 4, 2],
  [170692, 208, 4, 1],
  [170691, 208, 3, 6],
  [170690, 208, 3, 5],
  [170689, 208, 3, 4],
  [170688, 208, 3, 3],
  [170687, 208, 3, 2],
  [170686, 208, 3, 1],
  [170685, 208, 2, 6],
  [170684, 208, 2, 5],
  [170683, 208, 2, 4],
  [170682, 208, 2, 3],
  [170681, 208, 2, 2],
  [170680, 208, 2, 1],
  [170679, 208, 1, 6],
  [170678, 208, 1, 5],
  [170677, 208, 1, 4],
  [170676, 208, 1, 3],
  [170675, 208, 1, 2],
  [170674, 208, 1, 1],
  [170673, 207, 6, 6],
  [170672, 207, 6, 5],
  [170671, 207, 6, 4],
  [170670, 207, 6, 3],
  [170669, 207, 6, 2],
  [170668, 207, 6, 1],
  [170667, 207, 5, 6],
  [170666, 207, 5, 5],
  [170665, 207, 5, 4],
  [170664, 207, 5, 3],
  [170663, 207, 5, 2],
  [170662, 207, 5, 1],
  [170661, 207, 4, 6],
  [170660, 207, 4, 5],
  [170659, 207, 4, 4],
  [170658, 207, 4, 3],
  [170657, 207, 4, 2],
  [170656, 207, 4, 1],
  [170655, 207, 3, 6],
  [170654, 207, 3, 5],
  [170653, 207, 3, 4],
  [170652, 207, 3, 3],
  [170651, 207, 3, 2],
  [170650, 207, 3, 1],
  [170649, 207, 2, 6],
  [170648, 207, 2, 5],
  [170645, 207, 2, 4],
  [170644, 207, 2, 3],
  [170643, 207, 2, 2],
  [170642, 207, 2, 1],
  [170641, 207, 1, 6],
  [170640, 207, 1, 5],
  [170639, 207, 1, 4],
  [170638, 207, 1, 3],
  [170637, 207, 1, 2],
  [170636, 207, 1, 1],
  [170635, 206, 6, 6],
  [170634, 206, 6, 5],
  [170633, 206, 6, 4],
  [170632, 206, 6, 3],
  [170631, 206, 6, 2],
  [170630, 206, 6, 1],
  [170629, 206, 5, 6],
  [170628, 206, 5, 5],
  [170627, 206, 5, 4],
  [170626, 206, 5, 3],
  [170625, 206, 5, 2],
  [170624, 206, 5, 1],
  [170623, 206, 4, 6],
  [170622, 206, 4, 5],
  [170621, 206, 4, 4],
  [170620, 206, 4, 3],
  [170619, 206, 4, 2],
  [170618, 206, 4, 1],
  [170617, 206, 3, 6],
  [170616, 206, 3, 5],
  [170615, 206, 3, 4],
  [170614, 206, 3, 3],
  [170613, 206, 3, 2],
  [170612, 206, 3, 1],
  [170611, 206, 2, 6],
  [170610, 206, 2, 5],
  [170609, 206, 2, 4],
  [170608, 206, 2, 3],
  [170607, 206, 2, 2],
  [170606, 206, 2, 1],
  [170605, 206, 1, 6],
  [170604, 206, 1, 5],
  [170603, 206, 1, 4],
  [170602, 206, 1, 3],
  [170601, 206, 1, 2],
  [170600, 206, 1, 1],
  [170599, 205, 6, 6],
  [170598, 205, 6, 5],
  [170597, 205, 6, 4],
  [170596, 205, 6, 3],
  [170595, 205, 6, 2],
  [170594, 205, 6, 1],
  [170593, 205, 5, 6],
  [170592, 205, 5, 5],
  [170591, 205, 5, 4],
  [170590, 205, 5, 3],
  [170589, 205, 5, 2],
  [170588, 205, 5, 1],
  [170587, 205, 4, 6],
  [170586, 205, 4, 5],
  [170585, 205, 4, 4],
  [170584, 205, 4, 3],
  [170583, 205, 4, 2],
  [170582, 205, 4, 1],
  [170581, 205, 3, 6],
  [170580, 205, 3, 5],
  [170579, 205, 3, 4],
  [170578, 205, 3, 3],
  [170577, 205, 3, 2],
  [170576, 205, 3, 1],
  [170575, 205, 2, 6],
  [170574, 205, 2, 5],
  [170573, 205, 2, 4],
  [170572, 205, 2, 3],
  [170571, 205, 2, 2],
  [170570, 205, 2, 1],
  [170569, 205, 1, 6],
  [170568, 205, 1, 5],
  [170567, 205, 1, 4],
  [170566, 205, 1, 3],
  [170565, 205, 1, 2],
  [170564, 205, 1, 1],
  [170563, 204, 6, 6],
  [170562, 204, 6, 5],
  [170561, 204, 6, 4],
  [170560, 204, 6, 3],
  [170559, 204, 6, 2],
  [170558, 204, 6, 1],
  [170557, 204, 5, 6],
  [170556, 204, 5, 5],
  [170555, 204, 5, 4],
  [170554, 204, 5, 3],
  [170553, 204, 5, 2],
  [170552, 204, 5, 1],
  [170551, 204, 4, 6],
  [170550, 204, 4, 5],
  [170549, 204, 4, 4],
  [170548, 204, 4, 3],
  [170547, 204, 4, 2],
  [170546, 204, 4, 1],
  [170545, 204, 3, 6],
  [170544, 204, 3, 5],
  [170543, 204, 3, 4],
  [170542, 204, 3, 3],
  [170541, 204, 3, 2],
  [170540, 204, 3, 1],
  [170539, 204, 2, 6],
  [170538, 204, 2, 5],
  [170537, 204, 2, 4],
  [170536, 204, 2, 3],
  [170535, 204, 2, 2],
  [170534, 204, 2, 1],
  [170533, 204, 1, 6],
  [170532, 204, 1, 5],
  [170531, 204, 1, 4],
  [170530, 204, 1, 3],
  [170529, 204, 1, 2],
  [170528, 204, 1, 1],
  [170527, 203, 6, 6],
  [170526, 203, 6, 5],
  [170525, 203, 6, 4],
  [170524, 203, 6, 3],
  [170523, 203, 6, 2],
  [170522, 203, 6, 1],
  [170521, 203, 5, 6],
  [170520, 203, 5, 5],
  [170519, 203, 5, 4],
  [170518, 203, 5, 3],
  [170517, 203, 5, 2],
  [170516, 203, 5, 1],
  [170515, 203, 4, 6],
  [170514, 203, 4, 5],
  [170513, 203, 4, 4],
  [170512, 203, 4, 3],
  [170511, 203, 4, 2],
  [170510, 203, 4, 1],
  [170509, 203, 3, 6],
  [170508, 203, 3, 5],
  [170507, 203, 3, 4],
  [170506, 203, 3, 3],
  [170505, 203, 3, 2],
  [170504, 203, 3, 1],
  [170503, 203, 2, 6],
  [170502, 203, 2, 5],
  [170501, 203, 2, 4],
  [170500, 203, 2, 3],
  [170499, 203, 2, 2],
  [170498, 203, 2, 1],
  [170497, 203, 1, 6],
  [170496, 203, 1, 5],
  [170495, 203, 1, 4],
  [170494, 203, 1, 3],
  [170493, 203, 1, 2],
  [170492, 203, 1, 1],
  [170491, 202, 6, 6],
  [170490, 202, 6, 5],
  [170489, 202, 6, 4],
  [170488, 202, 6, 3],
  [170487, 202, 6, 2],
  [170486, 202, 6, 1],
  [170485, 202, 5, 6],
  [170484, 202, 5, 5],
  [170483, 202, 5, 4],
  [170482, 202, 5, 3],
  [170481, 202, 5, 2],
  [170480, 202, 5, 1],
  [170479, 202, 4, 6],
  [170478, 202, 4, 5],
  [170477, 202, 4, 4],
  [170476, 202, 4, 3],
  [170475, 202, 4, 2],
  [170474, 202, 4, 1],
  [170473, 202, 3, 6],
  [170472, 202, 3, 5],
  [170471, 202, 3, 4],
  [170470, 202, 3, 3],
  [170469, 202, 3, 2],
  [170468, 202, 3, 1],
  [170467, 202, 2, 6],
  [170466, 202, 2, 5],
  [170465, 202, 2, 4],
  [170464, 202, 2, 3],
  [170463, 202, 2, 2],
  [170462, 202, 2, 1],
  [170461, 202, 1, 6],
  [170460, 202, 1, 5],
  [170459, 202, 1, 4],
  [170458, 202, 1, 3],
  [170457, 202, 1, 2],
  [170456, 202, 1, 1],
  [170455, 201, 6, 6],
  [170454, 201, 6, 5],
  [170453, 201, 6, 4],
  [170452, 201, 6, 3],
  [170451, 201, 6, 2],
  [170450, 201, 6, 1],
  [170449, 201, 5, 6],
  [170448, 201, 5, 5],
  [170447, 201, 5, 4],
  [170446, 201, 5, 3],
  [170445, 201, 5, 2],
  [170444, 201, 5, 1],
  [170443, 201, 4, 6],
  [170442, 201, 4, 5],
  [170441, 201, 4, 4],
  [170440, 201, 4, 3],
  [170439, 201, 4, 2],
  [170438, 201, 4, 1],
  [170437, 201, 3, 6],
  [170436, 201, 3, 5],
  [170435, 201, 3, 4],
  [170434, 201, 3, 3],
  [170433, 201, 3, 2],
  [170432, 201, 3, 1],
  [170431, 201, 2, 6],
  [170430, 201, 2, 5],
  [170429, 201, 2, 4],
  [170428, 201, 2, 3],
  [170427, 201, 2, 2],
  [170426, 201, 2, 1],
  [170425, 201, 1, 6],
  [170424, 201, 1, 5],
  [170423, 201, 1, 4],
  [170422, 201, 1, 3],
  [170421, 201, 1, 2],
  [170420, 201, 1, 1],
  [170419, 200, 6, 6],
  [170418, 200, 6, 5],
  [170417, 200, 6, 4],
  [170416, 200, 6, 3],
  [170415, 200, 6, 2],
  [170414, 200, 6, 1],
  [170413, 200, 5, 6],
  [170412, 200, 5, 5],
  [170411, 200, 5, 4],
  [170410, 200, 5, 3],
  [170409, 200, 5, 2],
  [170408, 200, 5, 1],
  [170407, 200, 4, 6],
  [170406, 200, 4, 5],
  [170405, 200, 4, 4],
  [170404, 200, 4, 3],
  [170403, 200, 4, 2],
  [170402, 200, 4, 1],
  [170401, 200, 3, 6],
  [170400, 200, 3, 5],
  [170399, 200, 3, 4],
  [170398, 200, 3, 3],
  [170397, 200, 3, 2],
  [170396, 200, 3, 1],
  [170395, 200, 2, 6],
  [170394, 200, 2, 5],
  [170393, 200, 2, 4],
  [170392, 200, 2, 3],
  [170391, 200, 2, 2],
  [170390, 200, 2, 1],
  [170389, 200, 1, 6],
  [170388, 200, 1, 5],
  [170387, 200, 1, 4],
  [170386, 200, 1, 3],
  [170385, 200, 1, 2],
  [170384, 200, 1, 1],
  [170383, 199, 6, 6],
  [170382, 199, 6, 5],
  [170381, 199, 6, 4],
  [170380, 199, 6, 3],
  [170379, 199, 6, 2],
  [170378, 199, 6, 1],
  [170377, 199, 5, 6],
  [170376, 199, 5, 5],
  [170375, 199, 5, 4],
  [170374, 199, 5, 3],
  [170373, 199, 5, 2],
  [170372, 199, 5, 1],
  [170371, 199, 4, 6],
  [170370, 199, 4, 5],
  [170369, 199, 4, 4],
  [170368, 199, 4, 3],
  [170367, 199, 4, 2],
  [170366, 199, 4, 1],
  [170365, 199, 3, 6],
  [170364, 199, 3, 5],
  [170363, 199, 3, 4],
  [170362, 199, 3, 3],
  [170361, 199, 3, 2],
  [170360, 199, 3, 1],
  [170359, 199, 2, 6],
  [170358, 199, 2, 5],
  [170357, 199, 2, 4],
  [170356, 199, 2, 3],
  [170354, 199, 2, 2],
  [170353, 199, 2, 1],
  [170352, 199, 1, 6],
  [170351, 199, 1, 5],
  [170350, 199, 1, 4],
  [170349, 199, 1, 3],
  [170348, 199, 1, 2],
  [170347, 199, 1, 1],
  [170346, 198, 6, 6],
  [170345, 198, 6, 5],
  [170344, 198, 6, 4],
  [170343, 198, 6, 3],
  [170342, 198, 6, 2],
  [170341, 198, 6, 1],
  [170340, 198, 5, 6],
  [170339, 198, 5, 5],
  [170338, 198, 5, 4],
  [170337, 198, 5, 3],
  [170336, 198, 5, 2],
  [170335, 198, 5, 1],
  [170334, 198, 4, 6],
  [170333, 198, 4, 5],
  [170332, 198, 4, 4],
  [170331, 198, 4, 3],
  [170330, 198, 4, 2],
  [170329, 198, 4, 1],
  [170328, 198, 3, 6],
  [170327, 198, 3, 5],
  [170326, 198, 3, 4],
  [170325, 198, 3, 3],
  [170324, 198, 3, 2],
  [170323, 198, 3, 1],
  [170322, 198, 2, 6],
  [170321, 198, 2, 5],
  [170320, 198, 2, 4],
  [170319, 198, 2, 3],
  [170318, 198, 2, 2],
  [170317, 198, 2, 1],
  [170316, 198, 1, 6],
  [170315, 198, 1, 5],
  [170314, 198, 1, 4],
  [170313, 198, 1, 3],
  [170312, 198, 1, 2],
  [170311, 198, 1, 1],
  [170310, 197, 6, 6],
  [170309, 197, 6, 5],
  [170308, 197, 6, 4],
  [170307, 197, 6, 3],
  [170306, 197, 6, 2],
  [170305, 197, 6, 1],
  [170304, 197, 5, 6],
  [170303, 197, 5, 5],
  [170302, 197, 5, 4],
  [170301, 197, 5, 3],
  [170300, 197, 5, 2],
  [170299, 197, 5, 1],
  [170298, 197, 4, 6],
  [170297, 197, 4, 5],
  [170296, 197, 4, 4],
  [170295, 197, 4, 3],
  [170294, 197, 4, 2],
  [170293, 197, 4, 1],
  [170292, 197, 3, 6],
  [170291, 197, 3, 5],
  [170290, 197, 3, 4],
  [170289, 197, 3, 3],
  [170288, 197, 3, 2],
  [170287, 197, 3, 1],
  [170286, 197, 2, 6],
  [170285, 197, 2, 5],
  [170284, 197, 2, 4],
  [170283, 197, 2, 3],
  [170282, 197, 2, 2],
  [170281, 197, 2, 1],
  [170280, 197, 1, 6],
  [170279, 197, 1, 5],
  [170278, 197, 1, 4],
  [170277, 197, 1, 3],
  [170276, 197, 1, 2],
  [170275, 197, 1, 1],
  [170274, 196, 6, 6],
  [170273, 196, 6, 5],
  [170272, 196, 6, 4],
  [170271, 196, 6, 3],
  [170270, 196, 6, 2],
  [170269, 196, 6, 1],
  [170268, 196, 5, 6],
  [170267, 196, 5, 5],
  [170266, 196, 5, 4],
  [170265, 196, 5, 3],
  [170264, 196, 5, 2],
  [170263, 196, 5, 1],
  [170262, 196, 4, 6],
  [170261, 196, 4, 5],
  [170260, 196, 4, 4],
  [170259, 196, 4, 3],
  [170258, 196, 4, 2],
  [170257, 196, 4, 1],
  [170256, 196, 3, 6],
  [170255, 196, 3, 5],
  [170254, 196, 3, 4],
  [170253, 196, 3, 3],
  [170252, 196, 3, 2],
  [170251, 196, 3, 1],
  [170250, 196, 2, 6],
  [170249, 196, 2, 5],
  [170248, 196, 2, 4],
  [170247, 196, 2, 3],
  [170246, 196, 2, 2],
  [170245, 196, 2, 1],
  [170244, 196, 1, 6],
  [170243, 196, 1, 5],
  [170242, 196, 1, 4],
  [170241, 196, 1, 3],
  [170240, 196, 1, 2],
  [170239, 196, 1, 1],
  [170238, 195, 6, 6],
  [170237, 195, 6, 5],
  [170236, 195, 6, 4],
  [170235, 195, 6, 3],
  [170234, 195, 6, 2],
  [170233, 195, 6, 1],
  [170232, 195, 5, 6],
  [170231, 195, 5, 5],
  [170230, 195, 5, 4],
  [170229, 195, 5, 3],
  [170228, 195, 5, 2],
  [170227, 195, 5, 1],
  [170226, 195, 4, 6],
  [170225, 195, 4, 5],
  [170224, 195, 4, 4],
  [170223, 195, 4, 3],
  [170222, 195, 4, 2],
  [170221, 195, 4, 1],
  [170220, 195, 3, 6],
  [170219, 195, 3, 5],
  [170218, 195, 3, 4],
  [170217, 195, 3, 3],
  [170216, 195, 3, 2],
  [170215, 195, 3, 1],
  [170214, 195, 2, 6],
  [170213, 195, 2, 5],
  [170212, 195, 2, 4],
  [170211, 195, 2, 3],
  [170210, 195, 2, 2],
  [170209, 195, 2, 1],
  [170208, 195, 1, 6],
  [170207, 195, 1, 5],
  [170206, 195, 1, 4],
  [170205, 195, 1, 3],
  [170204, 195, 1, 2],
  [170203, 195, 1, 1],
  [170202, 194, 6, 6],
  [170201, 194, 6, 5],
  [170200, 194, 6, 4],
  [170199, 194, 6, 3],
  [170198, 194, 6, 2],
  [170197, 194, 6, 1],
  [170196, 194, 5, 6],
  [170195, 194, 5, 5],
  [170194, 194, 5, 4],
  [170193, 194, 5, 3],
  [170192, 194, 5, 2],
  [170191, 194, 5, 1],
  [170190, 194, 4, 6],
  [170189, 194, 4, 5],
  [170188, 194, 4, 4],
  [170187, 194, 4, 3],
  [170186, 194, 4, 2],
  [170185, 194, 4, 1],
  [170184, 194, 3, 6],
  [170183, 194, 3, 5],
  [170182, 194, 3, 4],
  [170181, 194, 3, 3],
  [170180, 194, 3, 2],
  [170179, 194, 3, 1],
  [170178, 194, 2, 6],
  [170177, 194, 2, 5],
  [170176, 194, 2, 4],
  [170175, 194, 2, 3],
  [170174, 194, 2, 2],
  [170173, 194, 2, 1],
  [170172, 194, 1, 6],
  [170171, 194, 1, 5],
  [170170, 194, 1, 4],
  [170169, 194, 1, 3],
  [170168, 194, 1, 2],
  [170167, 194, 1, 1],
  [170166, 193, 6, 6],
  [170165, 193, 6, 5],
  [170164, 193, 6, 4],
  [170163, 193, 6, 3],
  [170162, 193, 6, 2],
  [170161, 193, 6, 1],
  [170160, 193, 5, 6],
  [170159, 193, 5, 5],
  [170158, 193, 5, 4],
  [170157, 193, 5, 3],
  [170156, 193, 5, 2],
  [170155, 193, 5, 1],
  [170154, 193, 4, 6],
  [170153, 193, 4, 5],
  [170152, 193, 4, 4],
  [170151, 193, 4, 3],
  [170150, 193, 4, 2],
  [170149, 193, 4, 1],
  [170148, 193, 3, 6],
  [170147, 193, 3, 5],
  [170146, 193, 3, 4],
  [170145, 193, 3, 3],
  [170144, 193, 3, 2],
  [170143, 193, 3, 1],
  [170142, 193, 2, 6],
  [170141, 193, 2, 5],
  [170140, 193, 2, 4],
  [170139, 193, 2, 3],
  [170138, 193, 2, 2],
  [170137, 193, 2, 1],
  [170136, 193, 1, 6],
  [170135, 193, 1, 5],
  [170134, 193, 1, 4],
  [170133, 193, 1, 3],
  [170132, 193, 1, 2],
  [170131, 193, 1, 1],
  [170130, 192, 6, 6],
  [170129, 192, 6, 5],
  [170128, 192, 6, 4],
  [170127, 192, 6, 3],
  [170126, 192, 6, 2],
  [170125, 192, 6, 1],
  [170124, 192, 5, 6],
  [170123, 192, 5, 5],
  [170122, 192, 5, 4],
  [170121, 192, 5, 3],
  [170120, 192, 5, 2],
  [170118, 192, 5, 1],
  [170117, 192, 4, 6],
  [170116, 192, 4, 5],
  [170115, 192, 4, 4],
  [170114, 192, 4, 3],
  [170113, 192, 4, 2],
  [170112, 192, 4, 1],
  [170111, 192, 3, 6],
  [170110, 192, 3, 5],
  [170109, 192, 3, 4],
  [170108, 192, 3, 3],
  [170107, 192, 3, 2],
  [170106, 192, 3, 1],
  [170105, 192, 2, 6],
  [170104, 192, 2, 5],
  [170103, 192, 2, 4],
  [170102, 192, 2, 3],
  [170101, 192, 2, 2],
  [170100, 192, 2, 1],
  [170099, 192, 1, 6],
  [170098, 192, 1, 5],
  [170097, 192, 1, 4],
  [170096, 192, 1, 3],
  [170095, 192, 1, 2],
  [170094, 192, 1, 1],
  [170093, 191, 6, 6],
  [170092, 191, 6, 5],
  [170091, 191, 6, 4],
  [170090, 191, 6, 3],
  [170089, 191, 6, 2],
  [170088, 191, 6, 1],
  [170087, 191, 5, 6],
  [170086, 191, 5, 5],
  [170085, 191, 5, 4],
  [170084, 191, 5, 3],
  [170083, 191, 5, 2],
  [170082, 191, 5, 1],
  [170081, 191, 4, 6],
  [170080, 191, 4, 5],
  [170079, 191, 4, 4],
  [170078, 191, 4, 3],
  [170077, 191, 4, 2],
  [170076, 191, 4, 1],
  [170075, 191, 3, 6],
  [170074, 191, 3, 5],
  [170073, 191, 3, 4],
  [170071, 191, 3, 3],
  [170070, 191, 3, 2],
  [170069, 191, 3, 1],
  [170068, 191, 2, 6],
  [170067, 191, 2, 5],
  [170066, 191, 2, 4],
  [170065, 191, 2, 3],
  [170064, 191, 2, 2],
  [170063, 191, 2, 1],
  [170062, 191, 1, 6],
  [170061, 191, 1, 5],
  [170060, 191, 1, 4],
  [170059, 191, 1, 3],
  [170058, 191, 1, 2],
  [170057, 191, 1, 1],
  [170056, 190, 6, 6],
  [170055, 190, 6, 5],
  [170054, 190, 6, 4],
  [170053, 190, 6, 3],
  [170052, 190, 6, 2],
  [170051, 190, 6, 1],
  [170050, 190, 5, 6],
  [170049, 190, 5, 5],
  [170048, 190, 5, 4],
  [170047, 190, 5, 3],
  [170046, 190, 5, 2],
  [170045, 190, 5, 1],
  [170044, 190, 4, 6],
  [170043, 190, 4, 5],
  [170042, 190, 4, 4],
  [170041, 190, 4, 3],
  [170040, 190, 4, 2],
  [170039, 190, 4, 1],
  [170038, 190, 3, 6],
  [170037, 190, 3, 5],
  [170036, 190, 3, 4],
  [170035, 190, 3, 3],
  [170034, 190, 3, 2],
  [170033, 190, 3, 1],
  [170031, 190, 2, 6],
  [170030, 190, 2, 5],
  [170029, 190, 2, 4],
  [170028, 190, 2, 3],
  [170027, 190, 2, 2],
  [170026, 190, 2, 1],
  [170025, 190, 1, 6],
  [170024, 190, 1, 5],
  [170023, 190, 1, 4],
  [170022, 190, 1, 3],
  [170021, 190, 1, 2],
  [170020, 190, 1, 1],
  [170019, 189, 6, 6],
  [170018, 189, 6, 5],
  [170017, 189, 6, 4],
  [170016, 189, 6, 3],
  [170015, 189, 6, 2],
  [170014, 189, 6, 1],
  [170013, 189, 5, 6],
  [170012, 189, 5, 5],
  [170011, 189, 5, 4],
  [170010, 189, 5, 3],
  [170009, 189, 5, 2],
  [170008, 189, 5, 1],
  [170007, 189, 4, 6],
  [170006, 189, 4, 5],
  [170005, 189, 4, 4],
  [170004, 189, 4, 3],
  [170003, 189, 4, 2],
  [170002, 189, 4, 1],
  [170001, 189, 3, 6],
  [170000, 189, 3, 5],
  [169999, 189, 3, 4],
  [169998, 189, 3, 3],
  [169997, 189, 3, 2],
  [169996, 189, 3, 1],
  [169995, 189, 2, 6],
  [169994, 189, 2, 5],
  [169992, 189, 2, 4],
  [169991, 189, 2, 3],
  [169990, 189, 2, 2],
  [169989, 189, 2, 1],
  [169988, 189, 1, 6],
  [169987, 189, 1, 5],
  [169986, 189, 1, 4],
  [169985, 189, 1, 3],
  [169984, 189, 1, 2],
  [169983, 189, 1, 1],
  [169982, 188, 6, 6],
  [169981, 188, 6, 5],
  [169980, 188, 6, 4],
  [169979, 188, 6, 3],
  [169978, 188, 6, 2],
  [169977, 188, 6, 1],
  [169976, 188, 5, 6],
  [169975, 188, 5, 5],
  [169974, 188, 5, 4],
  [169973, 188, 5, 3],
  [169972, 188, 5, 2],
  [169971, 188, 5, 1],
  [169970, 188, 4, 6],
  [169969, 188, 4, 5],
  [169968, 188, 4, 4],
  [169967, 188, 4, 3],
  [169966, 188, 4, 2],
  [169965, 188, 4, 1],
  [169964, 188, 3, 6],
  [169963, 188, 3, 5],
  [169962, 188, 3, 4],
  [169961, 188, 3, 3],
  [169960, 188, 3, 2],
  [169959, 188, 3, 1],
  [169958, 188, 2, 6],
  [169957, 188, 2, 5],
  [169956, 188, 2, 4],
  [169955, 188, 2, 3],
  [169954, 188, 2, 2],
  [169952, 188, 2, 1],
  [169951, 188, 1, 6],
  [169950, 188, 1, 5],
  [169949, 188, 1, 4],
  [169948, 188, 1, 3],
  [169947, 188, 1, 2],
  [169946, 188, 1, 1],
  [169945, 187, 6, 6],
  [169944, 187, 6, 5],
  [169943, 187, 6, 4],
  [169942, 187, 6, 3],
  [169941, 187, 6, 2],
  [169940, 187, 6, 1],
  [169939, 187, 5, 6],
  [169938, 187, 5, 5],
  [169937, 187, 5, 4],
  [169936, 187, 5, 3],
  [169935, 187, 5, 2],
  [169934, 187, 5, 1],
  [169933, 187, 4, 6],
  [169932, 187, 4, 5],
  [169931, 187, 4, 4],
  [169930, 187, 4, 3],
  [169929, 187, 4, 2],
  [169928, 187, 4, 1],
  [169927, 187, 3, 6],
  [169926, 187, 3, 5],
  [169925, 187, 3, 4],
  [169924, 187, 3, 3],
  [169923, 187, 3, 2],
  [169922, 187, 3, 1],
  [169921, 187, 2, 6],
  [169920, 187, 2, 5],
  [169919, 187, 2, 4],
  [169918, 187, 2, 3],
  [169917, 187, 2, 2],
  [169916, 187, 2, 1],
  [169915, 187, 1, 6],
  [169914, 187, 1, 5],
  [169913, 187, 1, 4],
  [169912, 187, 1, 3],
  [169911, 187, 1, 2],
  [169910, 187, 1, 1],
  [169909, 186, 6, 6],
  [169908, 186, 6, 5],
  [169907, 186, 6, 4],
  [169906, 186, 6, 3],
  [169905, 186, 6, 2],
  [169904, 186, 6, 1],
  [169903, 186, 5, 6],
  [169902, 186, 5, 5],
  [169901, 186, 5, 4],
  [169900, 186, 5, 3],
  [169899, 186, 5, 2],
  [169898, 186, 5, 1],
  [169897, 186, 4, 6],
  [169896, 186, 4, 5],
  [169895, 186, 4, 4],
  [169894, 186, 4, 3],
  [169893, 186, 4, 2],
  [169892, 186, 4, 1],
  [169891, 186, 3, 6],
  [169890, 186, 3, 5],
  [169889, 186, 3, 4],
  [169888, 186, 3, 3],
  [169887, 186, 3, 2],
  [169886, 186, 3, 1],
  [169885, 186, 2, 6],
  [169884, 186, 2, 5],
  [169883, 186, 2, 4],
  [169882, 186, 2, 3],
  [169881, 186, 2, 2],
  [169880, 186, 2, 1],
  [169879, 186, 1, 6],
  [169878, 186, 1, 5],
  [169876, 186, 1, 4],
  [169875, 186, 1, 3],
  [169874, 186, 1, 2],
  [169873, 186, 1, 1],
  [169872, 185, 6, 6],
  [169871, 185, 6, 5],
  [169870, 185, 6, 4],
  [169869, 185, 6, 3],
  [169868, 185, 6, 2],
  [169867, 185, 6, 1],
  [169866, 185, 5, 6],
  [169865, 185, 5, 5],
  [169864, 185, 5, 4],
  [169863, 185, 5, 3],
  [169862, 185, 5, 2],
  [169861, 185, 5, 1],
  [169860, 185, 4, 6],
  [169859, 185, 4, 5],
  [169858, 185, 4, 4],
  [169857, 185, 4, 3],
  [169856, 185, 4, 2],
  [169855, 185, 4, 1],
  [169854, 185, 3, 6],
  [169852, 185, 3, 5],
  [169851, 185, 3, 4],
  [169850, 185, 3, 3],
  [169849, 185, 3, 2],
  [169848, 185, 3, 1],
  [169847, 185, 2, 6],
  [169846, 185, 2, 5],
  [169845, 185, 2, 4],
  [169844, 185, 2, 3],
  [169843, 185, 2, 2],
  [169842, 185, 2, 1],
  [169841, 185, 1, 6],
  [169840, 185, 1, 5],
  [169839, 185, 1, 4],
  [169838, 185, 1, 3],
  [169837, 185, 1, 2],
  [169836, 185, 1, 1],
  [169835, 184, 6, 6],
  [169834, 184, 6, 5],
  [169833, 184, 6, 4],
  [169832, 184, 6, 3],
  [169831, 184, 6, 2],
  [169830, 184, 6, 1],
  [169829, 184, 5, 6],
  [169828, 184, 5, 5],
  [169827, 184, 5, 4],
  [169826, 184, 5, 3],
  [169825, 184, 5, 2],
  [169824, 184, 5, 1],
  [169823, 184, 4, 6],
  [169822, 184, 4, 5],
  [169821, 184, 4, 4],
  [169820, 184, 4, 3],
  [169819, 184, 4, 2],
  [169818, 184, 4, 1],
  [169817, 184, 3, 6],
  [169816, 184, 3, 5],
  [169815, 184, 3, 4],
  [169814, 184, 3, 3],
  [169813, 184, 3, 2],
  [169812, 184, 3, 1],
  [169811, 184, 2, 6],
  [169810, 184, 2, 5],
  [169809, 184, 2, 4],
  [169808, 184, 2, 3],
  [169807, 184, 2, 2],
  [169806, 184, 2, 1],
  [169805, 184, 1, 6],
  [169804, 184, 1, 5],
  [169803, 184, 1, 4],
  [169802, 184, 1, 3],
  [169801, 184, 1, 2],
  [169800, 184, 1, 1],
  [169799, 183, 6, 6],
  [169798, 183, 6, 5],
  [169797, 183, 6, 4],
  [169796, 183, 6, 3],
  [169795, 183, 6, 2],
  [169794, 183, 6, 1],
  [169793, 183, 5, 6],
  [169792, 183, 5, 5],
  [169791, 183, 5, 4],
  [169790, 183, 5, 3],
  [169789, 183, 5, 2],
  [169788, 183, 5, 1],
  [169787, 183, 4, 6],
  [169786, 183, 4, 5],
  [169785, 183, 4, 4],
  [169784, 183, 4, 3],
  [169783, 183, 4, 2],
  [169782, 183, 4, 1],
  [169781, 183, 3, 6],
  [169780, 183, 3, 5],
  [169779, 183, 3, 4],
  [169778, 183, 3, 3],
  [169777, 183, 3, 2],
  [169776, 183, 3, 1],
  [169775, 183, 2, 6],
  [169774, 183, 2, 5],
  [169773, 183, 2, 4],
  [169772, 183, 2, 3],
  [169771, 183, 2, 2],
  [169770, 183, 2, 1],
  [169769, 183, 1, 6],
  [169768, 183, 1, 5],
  [169767, 183, 1, 4],
  [169766, 183, 1, 3],
  [169765, 183, 1, 2],
  [169764, 183, 1, 1],
  [169763, 182, 6, 6],
  [169762, 182, 6, 5],
  [169761, 182, 6, 4],
  [169760, 182, 6, 3],
  [169759, 182, 6, 2],
  [169758, 182, 6, 1],
  [169757, 182, 5, 6],
  [169756, 182, 5, 5],
  [169755, 182, 5, 4],
  [169754, 182, 5, 3],
  [169753, 182, 5, 2],
  [169752, 182, 5, 1],
  [169751, 182, 4, 6],
  [169750, 182, 4, 5],
  [169749, 182, 4, 4],
  [169748, 182, 4, 3],
  [169747, 182, 4, 2],
  [169746, 182, 4, 1],
  [169745, 182, 3, 6],
  [169744, 182, 3, 5],
  [169743, 182, 3, 4],
  [169742, 182, 3, 3],
  [169741, 182, 3, 2],
  [169740, 182, 3, 1],
  [169739, 182, 2, 6],
  [169738, 182, 2, 5],
  [169737, 182, 2, 4],
  [169736, 182, 2, 3],
  [169735, 182, 2, 2],
  [169734, 182, 2, 1],
  [169733, 182, 1, 6],
  [169732, 182, 1, 5],
  [169731, 182, 1, 4],
  [169730, 182, 1, 3],
  [169729, 182, 1, 2],
  [169728, 182, 1, 1],
  [169727, 181, 6, 6],
  [169726, 181, 6, 5],
  [169725, 181, 6, 4],
  [169724, 181, 6, 3],
  [169723, 181, 6, 2],
  [169722, 181, 6, 1],
  [169721, 181, 5, 6],
  [169720, 181, 5, 5],
  [169719, 181, 5, 4],
  [169718, 181, 5, 3],
  [169717, 181, 5, 2],
  [169716, 181, 5, 1],
  [169715, 181, 4, 6],
  [169714, 181, 4, 5],
  [169713, 181, 4, 4],
  [169712, 181, 4, 3],
  [169711, 181, 4, 2],
  [169710, 181, 4, 1],
  [169709, 181, 3, 6],
  [169708, 181, 3, 5],
  [169707, 181, 3, 4],
  [169706, 181, 3, 3],
  [169705, 181, 3, 2],
  [169704, 181, 3, 1],
  [169703, 181, 2, 6],
  [169702, 181, 2, 5],
  [169701, 181, 2, 4],
  [169700, 181, 2, 3],
  [169699, 181, 2, 2],
  [169698, 181, 2, 1],
  [169697, 181, 1, 6],
  [169696, 181, 1, 5],
  [169695, 181, 1, 4],
  [169694, 181, 1, 3],
  [169693, 181, 1, 2],
  [169692, 181, 1, 1],
  [169691, 180, 6, 6],
  [169690, 180, 6, 5],
  [169689, 180, 6, 4],
  [169688, 180, 6, 3],
  [169687, 180, 6, 2],
  [169686, 180, 6, 1],
  [169685, 180, 5, 6],
  [169684, 180, 5, 5],
  [169683, 180, 5, 4],
  [169682, 180, 5, 3],
  [169681, 180, 5, 2],
  [169680, 180, 5, 1],
  [169679, 180, 4, 6],
  [169678, 180, 4, 5],
  [169677, 180, 4, 4],
  [169676, 180, 4, 3],
  [169675, 180, 4, 2],
  [169674, 180, 4, 1],
  [169673, 180, 3, 6],
  [169672, 180, 3, 5],
  [169671, 180, 3, 4],
  [169670, 180, 3, 3],
  [169669, 180, 3, 2],
  [169668, 180, 3, 1],
  [169667, 180, 2, 6],
  [169666, 180, 2, 5],
  [169665, 180, 2, 4],
  [169664, 180, 2, 3],
  [169663, 180, 2, 2],
  [169662, 180, 2, 1],
  [169661, 180, 1, 6],
  [169660, 180, 1, 5],
  [169659, 180, 1, 4],
  [169658, 180, 1, 3],
  [169657, 180, 1, 2],
  [169656, 180, 1, 1],
  [169655, 179, 6, 6],
  [169654, 179, 6, 5],
  [169653, 179, 6, 4],
  [169652, 179, 6, 3],
  [169651, 179, 6, 2],
  [169650, 179, 6, 1],
  [169649, 179, 5, 6],
  [169648, 179, 5, 5],
  [169647, 179, 5, 4],
  [169646, 179, 5, 3],
  [169645, 179, 5, 2],
  [169644, 179, 5, 1],
  [169643, 179, 4, 6],
  [169642, 179, 4, 5],
  [169641, 179, 4, 4],
  [169640, 179, 4, 3],
  [169639, 179, 4, 2],
  [169638, 179, 4, 1],
  [169637, 179, 3, 6],
  [169636, 179, 3, 5],
  [169635, 179, 3, 4],
  [169634, 179, 3, 3],
  [169633, 179, 3, 2],
  [169632, 179, 3, 1],
  [169631, 179, 2, 6],
  [169630, 179, 2, 5],
  [169629, 179, 2, 4],
  [169628, 179, 2, 3],
  [169627, 179, 2, 2],
  [169626, 179, 2, 1],
  [169625, 179, 1, 6],
  [169624, 179, 1, 5],
  [169623, 179, 1, 4],
  [169622, 179, 1, 3],
  [169621, 179, 1, 2],
  [169620, 179, 1, 1],
  [169619, 178, 6, 6],
  [169618, 178, 6, 5],
  [169616, 178, 6, 4],
  [169615, 178, 6, 3],
  [169614, 178, 6, 2],
  [169613, 178, 6, 1],
  [169612, 178, 5, 6],
  [169611, 178, 5, 5],
  [169610, 178, 5, 4],
  [169609, 178, 5, 3],
  [169608, 178, 5, 2],
  [169607, 178, 5, 1],
  [169606, 178, 4, 6],
  [169605, 178, 4, 5],
  [169604, 178, 4, 4],
  [169603, 178, 4, 3],
  [169602, 178, 4, 2],
  [169601, 178, 4, 1],
  [169600, 178, 3, 6],
  [169599, 178, 3, 5],
  [169598, 178, 3, 4],
  [169597, 178, 3, 3],
  [169596, 178, 3, 2],
  [169595, 178, 3, 1],
  [169594, 178, 2, 6],
  [169593, 178, 2, 5],
  [169592, 178, 2, 4],
  [169591, 178, 2, 3],
  [169590, 178, 2, 2],
  [169589, 178, 2, 1],
  [169588, 178, 1, 6],
  [169587, 178, 1, 5],
  [169586, 178, 1, 4],
  [169585, 178, 1, 3],
  [169584, 178, 1, 2],
  [169583, 178, 1, 1],
  [169582, 177, 6, 6],
  [169581, 177, 6, 5],
  [169580, 177, 6, 4],
  [169579, 177, 6, 3],
  [169578, 177, 6, 2],
  [169577, 177, 6, 1],
  [169576, 177, 5, 6],
  [169575, 177, 5, 5],
  [169574, 177, 5, 4],
  [169573, 177, 5, 3],
  [169572, 177, 5, 2],
  [169570, 177, 5, 1],
  [169569, 177, 4, 6],
  [169568, 177, 4, 5],
  [169567, 177, 4, 4],
  [169566, 177, 4, 3],
  [169565, 177, 4, 2],
  [169564, 177, 4, 1],
  [169563, 177, 3, 6],
  [169562, 177, 3, 5],
  [169561, 177, 3, 4],
  [169560, 177, 3, 3],
  [169559, 177, 3, 2],
  [169558, 177, 3, 1],
  [169557, 177, 2, 6],
  [169556, 177, 2, 5],
  [169555, 177, 2, 4],
  [169554, 177, 2, 3],
  [169553, 177, 2, 2],
  [169552, 177, 2, 1],
  [169551, 177, 1, 6],
  [169550, 177, 1, 5],
  [169549, 177, 1, 4],
  [169548, 177, 1, 3],
  [169547, 177, 1, 2],
  [169546, 177, 1, 1],
  [169545, 176, 6, 6],
  [169544, 176, 6, 5],
  [169543, 176, 6, 4],
  [169542, 176, 6, 3],
  [169541, 176, 6, 2],
  [169540, 176, 6, 1],
  [169539, 176, 5, 6],
  [169538, 176, 5, 5],
  [169537, 176, 5, 4],
  [169536, 176, 5, 3],
  [169535, 176, 5, 2],
  [169534, 176, 5, 1],
  [169533, 176, 4, 6],
  [169532, 176, 4, 5],
  [169531, 176, 4, 4],
  [169530, 176, 4, 3],
  [169529, 176, 4, 2],
  [169528, 176, 4, 1],
  [169527, 176, 3, 6],
  [169526, 176, 3, 5],
  [169525, 176, 3, 4],
  [169524, 176, 3, 3],
  [169523, 176, 3, 2],
  [169522, 176, 3, 1],
  [169521, 176, 2, 6],
  [169520, 176, 2, 5],
  [169519, 176, 2, 4],
  [169518, 176, 2, 3],
  [169517, 176, 2, 2],
  [169516, 176, 2, 1],
  [169515, 176, 1, 6],
  [169514, 176, 1, 5],
  [169513, 176, 1, 4],
  [169512, 176, 1, 3],
  [169511, 176, 1, 2],
  [169510, 176, 1, 1],
  [169509, 175, 6, 6],
  [169508, 175, 6, 5],
  [169507, 175, 6, 4],
  [169506, 175, 6, 3],
  [169505, 175, 6, 2],
  [169504, 175, 6, 1],
  [169503, 175, 5, 6],
  [169502, 175, 5, 5],
  [169501, 175, 5, 4],
  [169500, 175, 5, 3],
  [169499, 175, 5, 2],
  [169498, 175, 5, 1],
  [169496, 175, 4, 6],
  [169495, 175, 4, 5],
  [169494, 175, 4, 4],
  [169493, 175, 4, 3],
  [169492, 175, 4, 2],
  [169491, 175, 4, 1],
  [169490, 175, 3, 6],
  [169489, 175, 3, 5],
  [169488, 175, 3, 4],
  [169487, 175, 3, 3],
  [169486, 175, 3, 2],
  [169485, 175, 3, 1],
  [169484, 175, 2, 6],
  [169483, 175, 2, 5],
  [169481, 175, 2, 4],
  [169480, 175, 2, 3],
  [169479, 175, 2, 2],
  [169478, 175, 2, 1],
  [169477, 175, 1, 6],
  [169476, 175, 1, 5],
  [169475, 175, 1, 4],
  [169474, 175, 1, 3],
  [169473, 175, 1, 2],
  [169472, 175, 1, 1],
  [169471, 174, 6, 6],
  [169470, 174, 6, 5],
  [169469, 174, 6, 4],
  [169468, 174, 6, 3],
  [169467, 174, 6, 2],
  [169466, 174, 6, 1],
  [169465, 174, 5, 6],
  [169463, 174, 5, 5],
  [169462, 174, 5, 4],
  [169461, 174, 5, 3],
  [169460, 174, 5, 2],
  [169459, 174, 5, 1],
  [169458, 174, 4, 6],
  [169457, 174, 4, 5],
  [169456, 174, 4, 4],
  [169455, 174, 4, 3],
  [169454, 174, 4, 2],
  [169453, 174, 4, 1],
  [169452, 174, 3, 6],
  [169451, 174, 3, 5],
  [169450, 174, 3, 4],
  [169449, 174, 3, 3],
  [169448, 174, 3, 2],
  [169447, 174, 3, 1],
  [169446, 174, 2, 6],
  [169445, 174, 2, 5],
  [169444, 174, 2, 4],
  [169443, 174, 2, 3],
  [169442, 174, 2, 2],
  [169441, 174, 2, 1],
  [169440, 174, 1, 6],
  [169439, 174, 1, 5],
  [169438, 174, 1, 4],
  [169437, 174, 1, 3],
  [169436, 174, 1, 2],
  [169435, 174, 1, 1],
  [169434, 173, 6, 6],
  [169433, 173, 6, 5],
  [169432, 173, 6, 4],
  [169431, 173, 6, 3],
  [169430, 173, 6, 2],
  [169429, 173, 6, 1],
  [169428, 173, 5, 6],
  [169427, 173, 5, 5],
  [169426, 173, 5, 4],
  [169425, 173, 5, 3],
  [169424, 173, 5, 2],
  [169423, 173, 5, 1],
  [169422, 173, 4, 6],
  [169421, 173, 4, 5],
  [169420, 173, 4, 4],
  [169419, 173, 4, 3],
  [169418, 173, 4, 2],
  [169417, 173, 4, 1],
  [169416, 173, 3, 6],
  [169415, 173, 3, 5],
  [169414, 173, 3, 4],
  [169413, 173, 3, 3],
  [169412, 173, 3, 2],
  [169411, 173, 3, 1],
  [169410, 173, 2, 6],
  [169409, 173, 2, 5],
  [169408, 173, 2, 4],
  [169407, 173, 2, 3],
  [169406, 173, 2, 2],
  [169405, 173, 2, 1],
  [169404, 173, 1, 6],
  [169403, 173, 1, 5],
  [169402, 173, 1, 4],
  [169401, 173, 1, 3],
  [169400, 173, 1, 2],
  [169399, 173, 1, 1],
  [169398, 172, 6, 6],
  [169397, 172, 6, 5],
  [169396, 172, 6, 4],
  [169395, 172, 6, 3],
  [169394, 172, 6, 2],
  [169393, 172, 6, 1],
  [169392, 172, 5, 6],
  [169391, 172, 5, 5],
  [169390, 172, 5, 4],
  [169389, 172, 5, 3],
  [169388, 172, 5, 2],
  [169387, 172, 5, 1],
  [169386, 172, 4, 6],
  [169385, 172, 4, 5],
  [169384, 172, 4, 4],
  [169383, 172, 4, 3],
  [169382, 172, 4, 2],
  [169381, 172, 4, 1],
  [169380, 172, 3, 6],
  [169379, 172, 3, 5],
  [169378, 172, 3, 4],
  [169377, 172, 3, 3],
  [169376, 172, 3, 2],
  [169375, 172, 3, 1],
  [169374, 172, 2, 6],
  [169373, 172, 2, 5],
  [169372, 172, 2, 4],
  [169371, 172, 2, 3],
  [169370, 172, 2, 2],
  [169369, 172, 2, 1],
  [169368, 172, 1, 6],
  [169367, 172, 1, 5],
  [169366, 172, 1, 4],
  [169365, 172, 1, 3],
  [169364, 172, 1, 2],
  [169363, 172, 1, 1],
  [169362, 171, 6, 6],
  [169361, 171, 6, 5],
  [169360, 171, 6, 4],
  [169359, 171, 6, 3],
  [169358, 171, 6, 2],
  [169357, 171, 6, 1],
  [169356, 171, 5, 6],
  [169355, 171, 5, 5],
  [169354, 171, 5, 4],
  [169353, 171, 5, 3],
  [169352, 171, 5, 2],
  [169351, 171, 5, 1],
  [169350, 171, 4, 6],
  [169349, 171, 4, 5],
  [169348, 171, 4, 4],
  [169347, 171, 4, 3],
  [169346, 171, 4, 2],
  [169345, 171, 4, 1],
  [169344, 171, 3, 6],
  [169343, 171, 3, 5],
  [169342, 171, 3, 4],
  [169341, 171, 3, 3],
  [169340, 171, 3, 2],
  [169339, 171, 3, 1],
  [169338, 171, 2, 6],
  [169337, 171, 2, 5],
  [169336, 171, 2, 4],
  [169335, 171, 2, 3],
  [169334, 171, 2, 2],
  [169333, 171, 2, 1],
  [169332, 171, 1, 6],
  [169331, 171, 1, 5],
  [169330, 171, 1, 4],
  [169329, 171, 1, 3],
  [169328, 171, 1, 2],
  [169327, 171, 1, 1],
  [169326, 170, 6, 6],
  [169325, 170, 6, 5],
  [169324, 170, 6, 4],
  [169323, 170, 6, 3],
  [169322, 170, 6, 2],
  [169321, 170, 6, 1],
  [169320, 170, 5, 6],
  [169318, 170, 5, 5],
  [169317, 170, 5, 4],
  [169316, 170, 5, 3],
  [169315, 170, 5, 2],
  [169314, 170, 5, 1],
  [169313, 170, 4, 6],
  [169312, 170, 4, 5],
  [169311, 170, 4, 4],
  [169310, 170, 4, 3],
  [169309, 170, 4, 2],
  [169308, 170, 4, 1],
  [169307, 170, 3, 6],
  [169305, 170, 3, 5],
  [169304, 170, 3, 4],
  [169303, 170, 3, 3],
  [169302, 170, 3, 2],
  [169301, 170, 3, 1],
  [169300, 170, 2, 6],
  [169299, 170, 2, 5],
  [169298, 170, 2, 4],
  [169297, 170, 2, 3],
  [169296, 170, 2, 2],
  [169295, 170, 2, 1],
  [169294, 170, 1, 6],
  [169293, 170, 1, 5],
  [169292, 170, 1, 4],
  [169291, 170, 1, 3],
  [169290, 170, 1, 2],
  [169289, 170, 1, 1],
  [169288, 169, 6, 6],
  [169287, 169, 6, 5],
  [169286, 169, 6, 4],
  [169285, 169, 6, 3],
  [169284, 169, 6, 2],
  [169283, 169, 6, 1],
  [169282, 169, 5, 6],
  [169281, 169, 5, 5],
  [169280, 169, 5, 4],
  [169279, 169, 5, 3],
  [169278, 169, 5, 2],
  [169277, 169, 5, 1],
  [169276, 169, 4, 6],
  [169275, 169, 4, 5],
  [169274, 169, 4, 4],
  [169272, 169, 4, 3],
  [169271, 169, 4, 2],
  [169270, 169, 4, 1],
  [169269, 169, 3, 6],
  [169268, 169, 3, 5],
  [169267, 169, 3, 4],
  [169266, 169, 3, 3],
  [169265, 169, 3, 2],
  [169264, 169, 3, 1],
  [169263, 169, 2, 6],
  [169262, 169, 2, 5],
  [169261, 169, 2, 4],
  [169260, 169, 2, 3],
  [169259, 169, 2, 2],
  [169258, 169, 2, 1],
  [169257, 169, 1, 6],
  [169256, 169, 1, 5],
  [169255, 169, 1, 4],
  [169254, 169, 1, 3],
  [169253, 169, 1, 2],
  [169252, 169, 1, 1],
  [169251, 168, 6, 6],
  [169250, 168, 6, 5],
  [169249, 168, 6, 4],
  [169248, 168, 6, 3],
  [169247, 168, 6, 2],
  [169246, 168, 6, 1],
  [169245, 168, 5, 6],
  [169244, 168, 5, 5],
  [169243, 168, 5, 4],
  [169242, 168, 5, 3],
  [169241, 168, 5, 2],
  [169240, 168, 5, 1],
  [169239, 168, 4, 6],
  [169238, 168, 4, 5],
  [169237, 168, 4, 4],
  [169236, 168, 4, 3],
  [169235, 168, 4, 2],
  [169234, 168, 4, 1],
  [169233, 168, 3, 6],
  [169232, 168, 3, 5],
  [169231, 168, 3, 4],
  [169230, 168, 3, 3],
  [169229, 168, 3, 2],
  [169227, 168, 3, 1],
  [169226, 168, 2, 6],
  [169225, 168, 2, 5],
  [169224, 168, 2, 4],
  [169223, 168, 2, 3],
  [169222, 168, 2, 2],
  [169221, 168, 2, 1],
  [169220, 168, 1, 6],
  [169219, 168, 1, 5],
  [169218, 168, 1, 4],
  [169217, 168, 1, 3],
  [169216, 168, 1, 2],
  [169215, 168, 1, 1],
  [169213, 167, 6, 6],
  [169212, 167, 6, 5],
  [169211, 167, 6, 4],
  [169210, 167, 6, 3],
  [169209, 167, 6, 2],
  [169208, 167, 6, 1],
  [169207, 167, 5, 6],
  [169206, 167, 5, 5],
  [169205, 167, 5, 4],
  [169204, 167, 5, 3],
  [169203, 167, 5, 2],
  [169202, 167, 5, 1],
  [169201, 167, 4, 6],
  [169200, 167, 4, 5],
  [169199, 167, 4, 4],
  [169198, 167, 4, 3],
  [169197, 167, 4, 2],
  [169196, 167, 4, 1],
  [169195, 167, 3, 6],
  [169194, 167, 3, 5],
  [169193, 167, 3, 4],
  [169192, 167, 3, 3],
  [169190, 167, 3, 2],
  [169189, 167, 3, 1],
  [169188, 167, 2, 6],
  [169187, 167, 2, 5],
  [169186, 167, 2, 4],
  [169185, 167, 2, 3],
  [169184, 167, 2, 2],
  [169183, 167, 2, 1],
  [169182, 167, 1, 6],
  [169181, 167, 1, 5],
  [169180, 167, 1, 4],
  [169179, 167, 1, 3],
  [169178, 167, 1, 2],
  [169177, 167, 1, 1],
  [169176, 166, 6, 6],
  [169175, 166, 6, 5],
  [169174, 166, 6, 4],
  [169173, 166, 6, 3],
  [169172, 166, 6, 2],
  [169171, 166, 6, 1],
  [169170, 166, 5, 6],
  [169169, 166, 5, 5],
  [169168, 166, 5, 4],
  [169167, 166, 5, 3],
  [169166, 166, 5, 2],
  [169165, 166, 5, 1],
  [169164, 166, 4, 6],
  [169163, 166, 4, 5],
  [169162, 166, 4, 4],
  [169161, 166, 4, 3],
  [169160, 166, 4, 2],
  [169159, 166, 4, 1],
  [169158, 166, 3, 6],
  [169157, 166, 3, 5],
  [169156, 166, 3, 4],
  [169155, 166, 3, 3],
  [169154, 166, 3, 2],
  [169153, 166, 3, 1],
  [169152, 166, 2, 6],
  [169151, 166, 2, 5],
  [169150, 166, 2, 4],
  [169149, 166, 2, 3],
  [169148, 166, 2, 2],
  [169147, 166, 2, 1],
  [169146, 166, 1, 6],
  [169144, 166, 1, 5],
  [169143, 166, 1, 4],
  [169142, 166, 1, 3],
  [169141, 166, 1, 2],
  [169140, 166, 1, 1],
  [169139, 165, 6, 6],
  [169138, 165, 6, 5],
  [169137, 165, 6, 4],
  [169136, 165, 6, 3],
  [169135, 165, 6, 2],
  [169134, 165, 6, 1],
  [169133, 165, 5, 6],
  [169132, 165, 5, 5],
  [169131, 165, 5, 4],
  [169130, 165, 5, 3],
  [169129, 165, 5, 2],
  [169128, 165, 5, 1],
  [169127, 165, 4, 6],
  [169126, 165, 4, 5],
  [169125, 165, 4, 4],
  [169124, 165, 4, 3],
  [169123, 165, 4, 2],
  [169122, 165, 4, 1],
  [169121, 165, 3, 6],
  [169120, 165, 3, 5],
  [169119, 165, 3, 4],
  [169118, 165, 3, 3],
  [169117, 165, 3, 2],
  [169116, 165, 3, 1],
  [169114, 165, 2, 6],
  [169113, 165, 2, 5],
  [169112, 165, 2, 4],
  [169111, 165, 2, 3],
  [169110, 165, 2, 2],
  [169109, 165, 2, 1],
  [169108, 165, 1, 6],
  [169107, 165, 1, 5],
  [169106, 165, 1, 4],
  [169105, 165, 1, 3],
  [169104, 165, 1, 2],
  [169103, 165, 1, 1],
  [169102, 164, 6, 6],
  [169101, 164, 6, 5],
  [169100, 164, 6, 4],
  [169099, 164, 6, 3],
  [169098, 164, 6, 2],
  [169097, 164, 6, 1],
  [169096, 164, 5, 6],
  [169095, 164, 5, 5],
  [169094, 164, 5, 4],
  [169093, 164, 5, 3],
  [169092, 164, 5, 2],
  [169091, 164, 5, 1],
  [169090, 164, 4, 6],
  [169089, 164, 4, 5],
  [169088, 164, 4, 4],
  [169087, 164, 4, 3],
  [169086, 164, 4, 2],
  [169085, 164, 4, 1],
  [169084, 164, 3, 6],
  [169083, 164, 3, 5],
  [169082, 164, 3, 4],
  [169081, 164, 3, 3],
  [169080, 164, 3, 2],
  [169079, 164, 3, 1],
  [169078, 164, 2, 6],
  [169076, 164, 2, 5],
  [169075, 164, 2, 4],
  [169074, 164, 2, 3],
  [169073, 164, 2, 2],
  [169072, 164, 2, 1],
  [169071, 164, 1, 6],
  [169070, 164, 1, 5],
  [169069, 164, 1, 4],
  [169068, 164, 1, 3],
  [169067, 164, 1, 2],
  [169066, 164, 1, 1],
  [169065, 163, 6, 6],
  [169064, 163, 6, 5],
  [169063, 163, 6, 4],
  [169062, 163, 6, 3],
  [169061, 163, 6, 2],
  [169060, 163, 6, 1],
  [169059, 163, 5, 6],
  [169058, 163, 5, 5],
  [169057, 163, 5, 4],
  [169056, 163, 5, 3],
  [169055, 163, 5, 2],
  [169054, 163, 5, 1],
  [169053, 163, 4, 6],
  [169052, 163, 4, 5],
  [169051, 163, 4, 4],
  [169050, 163, 4, 3],
  [169049, 163, 4, 2],
  [169048, 163, 4, 1],
  [169047, 163, 3, 6],
  [169046, 163, 3, 5],
  [169045, 163, 3, 4],
  [169044, 163, 3, 3],
  [169043, 163, 3, 2],
  [169042, 163, 3, 1],
  [169041, 163, 2, 6],
  [169040, 163, 2, 5],
  [169039, 163, 2, 4],
  [169038, 163, 2, 3],
  [169037, 163, 2, 2],
  [169036, 163, 2, 1],
  [169035, 163, 1, 6],
  [169034, 163, 1, 5],
  [169033, 163, 1, 4],
  [169032, 163, 1, 3],
  [169030, 163, 1, 2],
  [169029, 163, 1, 1],
  [169028, 162, 6, 6],
  [169027, 162, 6, 5],
  [169026, 162, 6, 4],
  [169025, 162, 6, 3],
  [169024, 162, 6, 2],
  [169023, 162, 6, 1],
  [169022, 162, 5, 6],
  [169021, 162, 5, 5],
  [169020, 162, 5, 4],
  [169019, 162, 5, 3],
  [169018, 162, 5, 2],
  [169017, 162, 5, 1],
  [169016, 162, 4, 6],
  [169015, 162, 4, 5],
  [169014, 162, 4, 4],
  [169013, 162, 4, 3],
  [169011, 162, 4, 2],
  [169010, 162, 4, 1],
  [169009, 162, 3, 6],
  [169008, 162, 3, 5],
  [169007, 162, 3, 4],
  [169006, 162, 3, 3],
  [169004, 162, 3, 2],
  [169003, 162, 3, 1],
  [169002, 162, 2, 6],
  [169001, 162, 2, 5],
  [169000, 162, 2, 4],
  [168999, 162, 2, 3],
  [168998, 162, 2, 2],
  [168997, 162, 2, 1],
  [168996, 162, 1, 6],
  [168995, 162, 1, 5],
  [168994, 162, 1, 4],
  [168993, 162, 1, 3],
  [168992, 162, 1, 2],
  [168991, 162, 1, 1],
  [168990, 161, 6, 6],
  [168989, 161, 6, 5],
  [168988, 161, 6, 4],
  [168987, 161, 6, 3],
  [168986, 161, 6, 2],
  [168985, 161, 6, 1],
  [168984, 161, 5, 6],
  [168983, 161, 5, 5],
  [168982, 161, 5, 4],
  [168981, 161, 5, 3],
  [168980, 161, 5, 2],
  [168979, 161, 5, 1],
  [168978, 161, 4, 6],
  [168977, 161, 4, 5],
  [168976, 161, 4, 4],
  [168975, 161, 4, 3],
  [168974, 161, 4, 2],
  [168973, 161, 4, 1],
  [168972, 161, 3, 6],
  [168971, 161, 3, 5],
  [168970, 161, 3, 4],
  [168969, 161, 3, 3],
  [168968, 161, 3, 2],
  [168967, 161, 3, 1],
  [168966, 161, 2, 6],
  [168965, 161, 2, 5],
  [168964, 161, 2, 4],
  [168963, 161, 2, 3],
  [168962, 161, 2, 2],
  [168961, 161, 2, 1],
  [168960, 161, 1, 6],
  [168959, 161, 1, 5],
  [168958, 161, 1, 4],
  [168957, 161, 1, 3],
  [168956, 161, 1, 2],
  [168955, 161, 1, 1],
  [168954, 160, 6, 6],
  [168953, 160, 6, 5],
  [168952, 160, 6, 4],
  [168950, 160, 6, 3],
  [168949, 160, 6, 2],
  [168948, 160, 6, 1],
  [168947, 160, 5, 6],
  [168946, 160, 5, 5],
  [168945, 160, 5, 4],
  [168944, 160, 5, 3],
  [168943, 160, 5, 2],
  [168942, 160, 5, 1],
  [168941, 160, 4, 6],
  [168940, 160, 4, 5],
  [168939, 160, 4, 4],
  [168938, 160, 4, 3],
  [168937, 160, 4, 2],
  [168936, 160, 4, 1],
  [168935, 160, 3, 6],
  [168934, 160, 3, 5],
  [168933, 160, 3, 4],
  [168932, 160, 3, 3],
  [168931, 160, 3, 2],
  [168930, 160, 3, 1],
  [168929, 160, 2, 6],
  [168928, 160, 2, 5],
  [168927, 160, 2, 4],
  [168926, 160, 2, 3],
  [168924, 160, 2, 2],
  [168923, 160, 2, 1],
  [168922, 160, 1, 6],
  [168921, 160, 1, 5],
  [168920, 160, 1, 4],
  [168919, 160, 1, 3],
  [168918, 160, 1, 2],
  [168917, 160, 1, 1],
  [168916, 159, 6, 6],
  [168915, 159, 6, 5],
  [168914, 159, 6, 4],
  [168913, 159, 6, 3],
  [168912, 159, 6, 2],
  [168911, 159, 6, 1],
  [168910, 159, 5, 6],
  [168909, 159, 5, 5],
  [168908, 159, 5, 4],
  [168907, 159, 5, 3],
  [168906, 159, 5, 2],
  [168905, 159, 5, 1],
  [168904, 159, 4, 6],
  [168903, 159, 4, 5],
  [168902, 159, 4, 4],
  [168901, 159, 4, 3],
  [168900, 159, 4, 2],
  [168899, 159, 4, 1],
  [168898, 159, 3, 6],
  [168897, 159, 3, 5],
  [168896, 159, 3, 4],
  [168895, 159, 3, 3],
  [168893, 159, 3, 2],
  [168892, 159, 3, 1],
  [168891, 159, 2, 6],
  [168890, 159, 2, 5],
  [168889, 159, 2, 4],
  [168888, 159, 2, 3],
  [168887, 159, 2, 2],
  [168886, 159, 2, 1],
  [168885, 159, 1, 6],
  [168884, 159, 1, 5],
  [168883, 159, 1, 4],
  [168882, 159, 1, 3],
  [168881, 159, 1, 2],
  [168880, 159, 1, 1],
  [168879, 158, 6, 6],
  [168878, 158, 6, 5],
  [168876, 158, 6, 4],
  [168875, 158, 6, 3],
  [168874, 158, 6, 2],
  [168873, 158, 6, 1],
  [168872, 158, 5, 6],
  [168871, 158, 5, 5],
  [168870, 158, 5, 4],
  [168869, 158, 5, 3],
  [168868, 158, 5, 2],
  [168867, 158, 5, 1],
  [168866, 158, 4, 6],
  [168865, 158, 4, 5],
  [168864, 158, 4, 4],
  [168863, 158, 4, 3],
  [168862, 158, 4, 2],
  [168861, 158, 4, 1],
  [168860, 158, 3, 6],
  [168859, 158, 3, 5],
  [168858, 158, 3, 4],
  [168857, 158, 3, 3],
  [168856, 158, 3, 2],
  [168855, 158, 3, 1],
  [168854, 158, 2, 6],
  [168853, 158, 2, 5],
  [168852, 158, 2, 4],
  [168851, 158, 2, 3],
  [168850, 158, 2, 2],
  [168849, 158, 2, 1],
  [168848, 158, 1, 6],
  [168847, 158, 1, 5],
  [168846, 158, 1, 4],
  [168845, 158, 1, 3],
  [168844, 158, 1, 2],
  [168843, 158, 1, 1],
  [168842, 157, 6, 6],
  [168841, 157, 6, 5],
  [168840, 157, 6, 4],
  [168839, 157, 6, 3],
  [168838, 157, 6, 2],
  [168837, 157, 6, 1],
  [168836, 157, 5, 6],
  [168835, 157, 5, 5],
  [168834, 157, 5, 4],
  [168833, 157, 5, 3],
  [168832, 157, 5, 2],
  [168831, 157, 5, 1],
  [168830, 157, 4, 6],
  [168829, 157, 4, 5],
  [168828, 157, 4, 4],
  [168827, 157, 4, 3],
  [168826, 157, 4, 2],
  [168825, 157, 4, 1],
  [168824, 157, 3, 6],
  [168823, 157, 3, 5],
  [168822, 157, 3, 4],
  [168821, 157, 3, 3],
  [168820, 157, 3, 2],
  [168819, 157, 3, 1],
  [168818, 157, 2, 6],
  [168817, 157, 2, 5],
  [168816, 157, 2, 4],
  [168815, 157, 2, 3],
  [168814, 157, 2, 2],
  [168813, 157, 2, 1],
  [168812, 157, 1, 6],
  [168811, 157, 1, 5],
  [168810, 157, 1, 4],
  [168808, 157, 1, 3],
  [168807, 157, 1, 2],
  [168806, 157, 1, 1],
  [168805, 156, 6, 6],
  [168804, 156, 6, 5],
  [168803, 156, 6, 4],
  [168802, 156, 6, 3],
  [168801, 156, 6, 2],
  [168800, 156, 6, 1],
  [168799, 156, 5, 6],
  [168798, 156, 5, 5],
  [168797, 156, 5, 4],
  [168796, 156, 5, 3],
  [168795, 156, 5, 2],
  [168794, 156, 5, 1],
  [168792, 156, 4, 6],
  [168791, 156, 4, 5],
  [168790, 156, 4, 4],
  [168789, 156, 4, 3],
  [168788, 156, 4, 2],
  [168787, 156, 4, 1],
  [168786, 156, 3, 6],
  [168785, 156, 3, 5],
  [168784, 156, 3, 4],
  [168783, 156, 3, 3],
  [168781, 156, 3, 2],
  [168780, 156, 3, 1],
  [168779, 156, 2, 6],
  [168778, 156, 2, 5],
  [168777, 156, 2, 4],
  [168776, 156, 2, 3],
  [168775, 156, 2, 2],
  [168774, 156, 2, 1],
  [168773, 156, 1, 6],
  [168772, 156, 1, 5],
  [168771, 156, 1, 4],
  [168770, 156, 1, 3],
  [168769, 156, 1, 2],
  [168767, 156, 1, 1],
  [168766, 155, 6, 6],
  [168765, 155, 6, 5],
  [168764, 155, 6, 4],
  [168763, 155, 6, 3],
  [168762, 155, 6, 2],
  [168761, 155, 6, 1],
  [168760, 155, 5, 6],
  [168759, 155, 5, 5],
  [168758, 155, 5, 4],
  [168757, 155, 5, 3],
  [168756, 155, 5, 2],
  [168755, 155, 5, 1],
  [168754, 155, 4, 6],
  [168753, 155, 4, 5],
  [168752, 155, 4, 4],
  [168751, 155, 4, 3],
  [168750, 155, 4, 2],
  [168749, 155, 4, 1],
  [168748, 155, 3, 6],
  [168747, 155, 3, 5],
  [168746, 155, 3, 4],
  [168745, 155, 3, 3],
  [168744, 155, 3, 2],
  [168743, 155, 3, 1],
  [168742, 155, 2, 6],
  [168741, 155, 2, 5],
  [168740, 155, 2, 4],
  [168739, 155, 2, 3],
  [168738, 155, 2, 2],
  [168737, 155, 2, 1],
  [168736, 155, 1, 6],
  [168735, 155, 1, 5],
  [168734, 155, 1, 4],
  [168733, 155, 1, 3],
  [168732, 155, 1, 2],
  [168731, 155, 1, 1],
  [168730, 154, 6, 6],
  [168729, 154, 6, 5],
  [168728, 154, 6, 4],
  [168727, 154, 6, 3],
  [168726, 154, 6, 2],
  [168725, 154, 6, 1],
  [168724, 154, 5, 6],
  [168723, 154, 5, 5],
  [168722, 154, 5, 4],
  [168721, 154, 5, 3],
  [168720, 154, 5, 2],
  [168719, 154, 5, 1],
  [168718, 154, 4, 6],
  [168717, 154, 4, 5],
  [168716, 154, 4, 4],
  [168715, 154, 4, 3],
  [168714, 154, 4, 2],
  [168712, 154, 4, 1],
  [168711, 154, 3, 6],
  [168710, 154, 3, 5],
  [168709, 154, 3, 4],
  [168708, 154, 3, 3],
  [168707, 154, 3, 2],
  [168706, 154, 3, 1],
  [168705, 154, 2, 6],
  [168704, 154, 2, 5],
  [168703, 154, 2, 4],
  [168702, 154, 2, 3],
  [168701, 154, 2, 2],
  [168700, 154, 2, 1],
  [168699, 154, 1, 6],
  [168698, 154, 1, 5],
  [168697, 154, 1, 4],
  [168696, 154, 1, 3],
  [168695, 154, 1, 2],
  [168694, 154, 1, 1],
  [168693, 153, 6, 6],
  [168691, 153, 6, 5],
  [168690, 153, 6, 4],
  [168689, 153, 6, 3],
  [168688, 153, 6, 2],
  [168687, 153, 6, 1],
  [168686, 153, 5, 6],
  [168685, 153, 5, 5],
  [168684, 153, 5, 4],
  [168683, 153, 5, 3],
  [168682, 153, 5, 2],
  [168681, 153, 5, 1],
  [168680, 153, 4, 6],
  [168679, 153, 4, 5],
  [168678, 153, 4, 4],
  [168677, 153, 4, 3],
  [168676, 153, 4, 2],
  [168675, 153, 4, 1],
  [168674, 153, 3, 6],
  [168673, 153, 3, 5],
  [168672, 153, 3, 4],
  [168671, 153, 3, 3],
  [168670, 153, 3, 2],
  [168669, 153, 3, 1],
  [168667, 153, 2, 6],
  [168666, 153, 2, 5],
  [168665, 153, 2, 4],
  [168664, 153, 2, 3],
  [168663, 153, 2, 2],
  [168662, 153, 2, 1],
  [168661, 153, 1, 6],
  [168660, 153, 1, 5],
  [168659, 153, 1, 4],
  [168658, 153, 1, 3],
  [168657, 153, 1, 2],
  [168656, 153, 1, 1],
  [168655, 152, 6, 6],
  [168654, 152, 6, 5],
  [168653, 152, 6, 4],
  [168652, 152, 6, 3],
  [168651, 152, 6, 2],
  [168650, 152, 6, 1],
  [168649, 152, 5, 6],
  [168648, 152, 5, 5],
  [168647, 152, 5, 4],
  [168646, 152, 5, 3],
  [168645, 152, 5, 2],
  [168644, 152, 5, 1],
  [168643, 152, 4, 6],
  [168642, 152, 4, 5],
  [168641, 152, 4, 4],
  [168640, 152, 4, 3],
  [168639, 152, 4, 2],
  [168638, 152, 4, 1],
  [168637, 152, 3, 6],
  [168636, 152, 3, 5],
  [168635, 152, 3, 4],
  [168634, 152, 3, 3],
  [168633, 152, 3, 2],
  [168632, 152, 3, 1],
  [168631, 152, 2, 6],
  [168630, 152, 2, 5],
  [168629, 152, 2, 4],
  [168627, 152, 2, 3],
  [168626, 152, 2, 2],
  [168625, 152, 2, 1],
  [168624, 152, 1, 6],
  [168623, 152, 1, 5],
  [168622, 152, 1, 4],
  [168621, 152, 1, 3],
  [168620, 152, 1, 2],
  [168619, 152, 1, 1],
  [168618, 151, 6, 6],
  [168617, 151, 6, 5],
  [168616, 151, 6, 4],
  [168615, 151, 6, 3],
  [168614, 151, 6, 2],
  [168613, 151, 6, 1],
  [168612, 151, 5, 6],
  [168611, 151, 5, 5],
  [168610, 151, 5, 4],
  [168609, 151, 5, 3],
  [168608, 151, 5, 2],
  [168607, 151, 5, 1],
  [168606, 151, 4, 6],
  [168605, 151, 4, 5],
  [168604, 151, 4, 4],
  [168603, 151, 4, 3],
  [168602, 151, 4, 2],
  [168601, 151, 4, 1],
  [168600, 151, 3, 6],
  [168598, 151, 3, 5],
  [168597, 151, 3, 4],
  [168596, 151, 3, 3],
  [168595, 151, 3, 2],
  [168594, 151, 3, 1],
  [168593, 151, 2, 6],
  [168592, 151, 2, 5],
  [168591, 151, 2, 4],
  [168590, 151, 2, 3],
  [168589, 151, 2, 2],
  [168588, 151, 2, 1],
  [168587, 151, 1, 6],
  [168586, 151, 1, 5],
  [168585, 151, 1, 4],
  [168584, 151, 1, 3],
  [168583, 151, 1, 2],
  [168582, 151, 1, 1],
  [168581, 150, 6, 6],
  [168580, 150, 6, 5],
  [168579, 150, 6, 4],
  [168578, 150, 6, 3],
  [168577, 150, 6, 2],
  [168576, 150, 6, 1],
  [168575, 150, 5, 6],
  [168574, 150, 5, 5],
  [168573, 150, 5, 4],
  [168572, 150, 5, 3],
  [168571, 150, 5, 2],
  [168570, 150, 5, 1],
  [168569, 150, 4, 6],
  [168568, 150, 4, 5],
  [168567, 150, 4, 4],
  [168566, 150, 4, 3],
  [168565, 150, 4, 2],
  [168564, 150, 4, 1],
  [168563, 150, 3, 6],
  [168562, 150, 3, 5],
  [168561, 150, 3, 4],
  [168560, 150, 3, 3],
  [168559, 150, 3, 2],
  [168558, 150, 3, 1],
  [168557, 150, 2, 6],
  [168556, 150, 2, 5],
  [168555, 150, 2, 4],
  [168554, 150, 2, 3],
  [168553, 150, 2, 2],
  [168552, 150, 2, 1],
  [168551, 150, 1, 6],
  [168550, 150, 1, 5],
  [168549, 150, 1, 4],
  [168548, 150, 1, 3],
  [168547, 150, 1, 2],
  [168546, 150, 1, 1],
  [168545, 149, 6, 6],
  [168544, 149, 6, 5],
  [168543, 149, 6, 4],
  [168542, 149, 6, 3],
  [168541, 149, 6, 2],
  [168540, 149, 6, 1],
  [168539, 149, 5, 6],
  [168538, 149, 5, 5],
  [168537, 149, 5, 4],
  [168536, 149, 5, 3],
  [168535, 149, 5, 2],
  [168534, 149, 5, 1],
  [168533, 149, 4, 6],
  [168532, 149, 4, 5],
  [168531, 149, 4, 4],
  [168530, 149, 4, 3],
  [168529, 149, 4, 2],
  [168528, 149, 4, 1],
  [168527, 149, 3, 6],
  [168526, 149, 3, 5],
  [168525, 149, 3, 4],
  [168524, 149, 3, 3],
  [168523, 149, 3, 2],
  [168522, 149, 3, 1],
  [168521, 149, 2, 6],
  [168520, 149, 2, 5],
  [168519, 149, 2, 4],
  [168518, 149, 2, 3],
  [168517, 149, 2, 2],
  [168516, 149, 2, 1],
  [168515, 149, 1, 6],
  [168514, 149, 1, 5],
  [168513, 149, 1, 4],
  [168512, 149, 1, 3],
  [168511, 149, 1, 2],
  [168510, 149, 1, 1],
  [168509, 148, 6, 6],
  [168508, 148, 6, 5],
  [168507, 148, 6, 4],
  [168505, 148, 6, 3],
  [168504, 148, 6, 2],
  [168503, 148, 6, 1],
  [168502, 148, 5, 6],
  [168501, 148, 5, 5],
  [168500, 148, 5, 4],
  [168499, 148, 5, 3],
  [168498, 148, 5, 2],
  [168497, 148, 5, 1],
  [168496, 148, 4, 6],
  [168495, 148, 4, 5],
  [168494, 148, 4, 4],
  [168493, 148, 4, 3],
  [168492, 148, 4, 2],
  [168491, 148, 4, 1],
  [168490, 148, 3, 6],
  [168489, 148, 3, 5],
  [168488, 148, 3, 4],
  [168487, 148, 3, 3],
  [168486, 148, 3, 2],
  [168485, 148, 3, 1],
  [168484, 148, 2, 6],
  [168483, 148, 2, 5],
  [168482, 148, 2, 4],
  [168481, 148, 2, 3],
  [168480, 148, 2, 2],
  [168479, 148, 2, 1],
  [168478, 148, 1, 6],
  [168477, 148, 1, 5],
  [168476, 148, 1, 4],
  [168475, 148, 1, 3],
  [168474, 148, 1, 2],
  [168473, 148, 1, 1],
  [168472, 147, 6, 6],
  [168471, 147, 6, 5],
  [168470, 147, 6, 4],
  [168469, 147, 6, 3],
  [168468, 147, 6, 2],
  [168467, 147, 6, 1],
  [168466, 147, 5, 6],
  [168465, 147, 5, 5],
  [168464, 147, 5, 4],
  [168463, 147, 5, 3],
  [168462, 147, 5, 2],
  [168461, 147, 5, 1],
  [168460, 147, 4, 6],
  [168459, 147, 4, 5],
  [168458, 147, 4, 4],
  [168457, 147, 4, 3],
  [168456, 147, 4, 2],
  [168455, 147, 4, 1],
  [168454, 147, 3, 6],
  [168453, 147, 3, 5],
  [168452, 147, 3, 4],
  [168451, 147, 3, 3],
  [168450, 147, 3, 2],
  [168449, 147, 3, 1],
  [168448, 147, 2, 6],
  [168447, 147, 2, 5],
  [168446, 147, 2, 4],
  [168445, 147, 2, 3],
  [168444, 147, 2, 2],
  [168443, 147, 2, 1],
  [168441, 147, 1, 6],
  [168440, 147, 1, 5],
  [168439, 147, 1, 4],
  [168438, 147, 1, 3],
  [168437, 147, 1, 2],
  [168436, 147, 1, 1],
  [168435, 146, 6, 6],
  [168434, 146, 6, 5],
  [168433, 146, 6, 4],
  [168432, 146, 6, 3],
  [168431, 146, 6, 2],
  [168430, 146, 6, 1],
  [168429, 146, 5, 6],
  [168428, 146, 5, 5],
  [168427, 146, 5, 4],
  [168426, 146, 5, 3],
  [168425, 146, 5, 2],
  [168424, 146, 5, 1],
  [168423, 146, 4, 6],
  [168422, 146, 4, 5],
  [168421, 146, 4, 4],
  [168420, 146, 4, 3],
  [168419, 146, 4, 2],
  [168418, 146, 4, 1],
  [168417, 146, 3, 6],
  [168416, 146, 3, 5],
  [168415, 146, 3, 4],
  [168414, 146, 3, 3],
  [168413, 146, 3, 2],
  [168412, 146, 3, 1],
  [168411, 146, 2, 6],
  [168409, 146, 2, 5],
  [168408, 146, 2, 4],
  [168407, 146, 2, 3],
  [168406, 146, 2, 2],
  [168405, 146, 2, 1],
  [168404, 146, 1, 6],
  [168403, 146, 1, 5],
  [168402, 146, 1, 4],
  [168401, 146, 1, 3],
  [168400, 146, 1, 2],
  [168399, 146, 1, 1],
  [168398, 145, 6, 6],
  [168397, 145, 6, 5],
  [168396, 145, 6, 4],
  [168395, 145, 6, 3],
  [168394, 145, 6, 2],
  [168393, 145, 6, 1],
  [168392, 145, 5, 6],
  [168391, 145, 5, 5],
  [168390, 145, 5, 4],
  [168389, 145, 5, 3],
  [168388, 145, 5, 2],
  [168387, 145, 5, 1],
  [168386, 145, 4, 6],
  [168385, 145, 4, 5],
  [168384, 145, 4, 4],
  [168383, 145, 4, 3],
  [168382, 145, 4, 2],
  [168381, 145, 4, 1],
  [168380, 145, 3, 6],
  [168379, 145, 3, 5],
  [168378, 145, 3, 4],
  [168377, 145, 3, 3],
  [168376, 145, 3, 2],
  [168375, 145, 3, 1],
  [168374, 145, 2, 6],
  [168373, 145, 2, 5],
  [168372, 145, 2, 4],
  [168371, 145, 2, 3],
  [168370, 145, 2, 2],
  [168369, 145, 2, 1],
  [168368, 145, 1, 6],
  [168367, 145, 1, 5],
  [168366, 145, 1, 4],
  [168365, 145, 1, 3],
  [168364, 145, 1, 2],
  [168363, 145, 1, 1],
  [168362, 144, 6, 6],
  [168361, 144, 6, 5],
  [168360, 144, 6, 4],
  [168359, 144, 6, 3],
  [168358, 144, 6, 2],
  [168357, 144, 6, 1],
  [168356, 144, 5, 6],
  [168355, 144, 5, 5],
  [168354, 144, 5, 4],
  [168353, 144, 5, 3],
  [168352, 144, 5, 2],
  [168351, 144, 5, 1],
  [168350, 144, 4, 6],
  [168349, 144, 4, 5],
  [168348, 144, 4, 4],
  [168347, 144, 4, 3],
  [168346, 144, 4, 2],
  [168345, 144, 4, 1],
  [168344, 144, 3, 6],
  [168343, 144, 3, 5],
  [168342, 144, 3, 4],
  [168341, 144, 3, 3],
  [168340, 144, 3, 2],
  [168339, 144, 3, 1],
  [168338, 144, 2, 6],
  [168337, 144, 2, 5],
  [168336, 144, 2, 4],
  [168335, 144, 2, 3],
  [168334, 144, 2, 2],
  [168333, 144, 2, 1],
  [168332, 144, 1, 6],
  [168331, 144, 1, 5],
  [168330, 144, 1, 4],
  [168329, 144, 1, 3],
  [168328, 144, 1, 2],
  [168326, 144, 1, 1],
  [168325, 143, 6, 6],
  [168324, 143, 6, 5],
  [168323, 143, 6, 4],
  [168322, 143, 6, 3],
  [168321, 143, 6, 2],
  [168320, 143, 6, 1],
  [168319, 143, 5, 6],
  [168318, 143, 5, 5],
  [168317, 143, 5, 4],
  [168316, 143, 5, 3],
  [168315, 143, 5, 2],
  [168314, 143, 5, 1],
  [168313, 143, 4, 6],
  [168312, 143, 4, 5],
  [168311, 143, 4, 4],
  [168310, 143, 4, 3],
  [168309, 143, 4, 2],
  [168308, 143, 4, 1],
  [168307, 143, 3, 6],
  [168306, 143, 3, 5],
  [168305, 143, 3, 4],
  [168304, 143, 3, 3],
  [168303, 143, 3, 2],
  [168302, 143, 3, 1],
  [168301, 143, 2, 6],
  [168300, 143, 2, 5],
  [168299, 143, 2, 4],
  [168298, 143, 2, 3],
  [168297, 143, 2, 2],
  [168296, 143, 2, 1],
  [168295, 143, 1, 6],
  [168294, 143, 1, 5],
  [168293, 143, 1, 4],
  [168292, 143, 1, 3],
  [168291, 143, 1, 2],
  [168290, 143, 1, 1],
  [168289, 141, 6, 6],
  [168288, 141, 6, 5],
  [168287, 141, 6, 4],
  [168286, 141, 6, 3],
  [168285, 141, 6, 2],
  [168284, 141, 6, 1],
  [168283, 141, 5, 6],
  [168282, 141, 5, 5],
  [168281, 141, 5, 4],
  [168280, 141, 5, 3],
  [168279, 141, 5, 2],
  [168278, 141, 5, 1],
  [168277, 141, 4, 6],
  [168276, 141, 4, 5],
  [168275, 141, 4, 4],
  [168274, 141, 4, 3],
  [168273, 141, 4, 2],
  [168272, 141, 4, 1],
  [168271, 141, 3, 6],
  [168270, 141, 3, 5],
  [168269, 141, 3, 4],
  [168268, 141, 3, 3],
  [168267, 141, 3, 2],
  [168266, 141, 3, 1],
  [168265, 141, 2, 6],
  [168264, 141, 2, 5],
  [168263, 141, 2, 4],
  [168262, 141, 2, 3],
  [168261, 141, 2, 2],
  [168260, 141, 2, 1],
  [168259, 141, 1, 6],
  [168258, 141, 1, 5],
  [168257, 141, 1, 4],
  [168256, 141, 1, 3],
  [168255, 141, 1, 2],
  [168254, 141, 1, 1],
  [168253, 142, 6, 6],
  [168252, 142, 6, 5],
  [168251, 142, 6, 4],
  [168250, 142, 6, 3],
  [168249, 142, 6, 2],
  [168248, 142, 6, 1],
  [168247, 142, 5, 6],
  [168246, 142, 5, 5],
  [168245, 142, 5, 4],
  [168244, 142, 5, 3],
  [168243, 142, 5, 2],
  [168242, 142, 5, 1],
  [168241, 142, 4, 6],
  [168240, 142, 4, 5],
  [168239, 142, 4, 4],
  [168238, 142, 4, 3],
  [168237, 142, 4, 2],
  [168236, 142, 4, 1],
  [168235, 142, 3, 6],
  [168234, 142, 3, 5],
  [168233, 142, 3, 4],
  [168232, 142, 3, 3],
  [168231, 142, 3, 2],
  [168230, 142, 3, 1],
  [168229, 142, 2, 6],
  [168228, 142, 2, 5],
  [168227, 142, 2, 4],
  [168226, 142, 2, 3],
  [168225, 142, 2, 2],
  [168224, 142, 2, 1],
  [168223, 142, 1, 6],
  [168222, 142, 1, 5],
  [168221, 142, 1, 4],
  [168220, 142, 1, 3],
  [168219, 142, 1, 2],
  [168218, 142, 1, 1],
  [168217, 140, 6, 6],
  [168216, 140, 6, 5],
  [168215, 140, 6, 4],
  [168214, 140, 6, 3],
  [168213, 140, 6, 2],
  [168211, 140, 6, 1],
  [168210, 140, 5, 6],
  [168208, 140, 5, 5],
  [168207, 140, 5, 4],
  [168206, 140, 5, 3],
  [168205, 140, 5, 2],
  [168204, 140, 5, 1],
  [168203, 140, 4, 6],
  [168202, 140, 4, 5],
  [168201, 140, 4, 4],
  [168200, 140, 4, 3],
  [168199, 140, 4, 2],
  [168198, 140, 4, 1],
  [168197, 140, 3, 6],
  [168196, 140, 3, 5],
  [168195, 140, 3, 4],
  [168194, 140, 3, 3],
  [168193, 140, 3, 2],
  [168192, 140, 3, 1],
  [168191, 140, 2, 6],
  [168190, 140, 2, 5],
  [168189, 140, 2, 4],
  [168188, 140, 2, 3],
  [168187, 140, 2, 2],
  [168186, 140, 2, 1],
  [168185, 140, 1, 6],
  [168184, 140, 1, 5],
  [168183, 140, 1, 4],
  [168182, 140, 1, 3],
  [168181, 140, 1, 2],
  [168180, 140, 1, 1],
  [168179, 139, 6, 6],
  [168178, 139, 6, 5],
  [168177, 139, 6, 4],
  [168176, 139, 6, 3],
  [168175, 139, 6, 2],
  [168174, 139, 6, 1],
  [168173, 139, 5, 6],
  [168172, 139, 5, 5],
  [168171, 139, 5, 4],
  [168170, 139, 5, 3],
  [168169, 139, 5, 2],
  [168168, 139, 5, 1],
  [168167, 139, 4, 6],
  [168166, 139, 4, 5],
  [168165, 139, 4, 4],
  [168164, 139, 4, 3],
  [168163, 139, 4, 2],
  [168162, 139, 4, 1],
  [168161, 139, 3, 6],
  [168160, 139, 3, 5],
  [168159, 139, 3, 4],
  [168158, 139, 3, 3],
  [168157, 139, 3, 2],
  [168156, 139, 3, 1],
  [168155, 139, 2, 6],
  [168154, 139, 2, 5],
  [168153, 139, 2, 4],
  [168152, 139, 2, 3],
  [168151, 139, 2, 2],
  [168150, 139, 2, 1],
  [168149, 139, 1, 6],
  [168148, 139, 1, 5],
  [168147, 139, 1, 4],
  [168146, 139, 1, 3],
  [168145, 139, 1, 2],
  [168144, 139, 1, 1],
  [86817, 138, 6, 6],
  [86816, 138, 6, 5],
  [86815, 138, 6, 4],
  [86814, 138, 6, 3],
  [86812, 138, 6, 2],
  [86811, 138, 6, 1],
  [86810, 138, 5, 6],
  [86809, 138, 5, 5],
  [86808, 138, 5, 4],
  [86807, 138, 5, 3],
  [86806, 138, 5, 2],
  [86804, 138, 5, 1],
  [86803, 138, 4, 6],
  [86802, 138, 4, 5],
  [86801, 138, 4, 4],
  [86800, 138, 4, 3],
  [86799, 138, 4, 2],
  [86798, 138, 4, 1],
  [86797, 138, 3, 6],
  [86796, 138, 3, 5],
  [86795, 138, 3, 4],
  [86794, 138, 3, 3],
  [86793, 138, 3, 2],
  [86792, 138, 3, 1],
  [86791, 138, 2, 6],
  [86790, 138, 2, 5],
  [86789, 138, 2, 4],
  [86788, 138, 2, 3],
  [86787, 138, 2, 2],
  [86786, 138, 2, 1],
  [86784, 138, 1, 6],
  [86783, 138, 1, 5],
  [86782, 138, 1, 4],
  [86781, 138, 1, 3],
  [86780, 138, 1, 2],
  [86779, 138, 1, 1],
  [86778, 137, 6, 6],
  [86777, 137, 6, 5],
  [86776, 137, 6, 4],
  [86775, 137, 6, 3],
  [86774, 137, 6, 2],
  [86773, 137, 6, 1],
  [86772, 137, 5, 6],
  [86771, 137, 5, 5],
  [86770, 137, 5, 4],
  [86769, 137, 5, 3],
  [86768, 137, 5, 2],
  [86767, 137, 5, 1],
  [86766, 137, 4, 6],
  [86765, 137, 4, 5],
  [86764, 137, 4, 4],
  [86763, 137, 4, 3],
  [86762, 137, 4, 2],
  [86761, 137, 4, 1],
  [86760, 137, 3, 6],
  [86759, 137, 3, 5],
  [86758, 137, 3, 4],
  [86757, 137, 3, 3],
  [86756, 137, 3, 2],
  [86755, 137, 3, 1],
  [86754, 137, 2, 6],
  [86753, 137, 2, 5],
  [86752, 137, 2, 4],
  [86751, 137, 2, 3],
  [86750, 137, 2, 2],
  [86749, 137, 2, 1],
  [86748, 137, 1, 6],
  [86746, 137, 1, 5],
  [86745, 137, 1, 4],
  [86744, 137, 1, 3],
  [86743, 137, 1, 2],
  [86742, 137, 1, 1],
  [86741, 136, 6, 6],
  [86740, 136, 6, 5],
  [86739, 136, 6, 4],
  [86738, 136, 6, 3],
  [86737, 136, 6, 2],
  [86736, 136, 6, 1],
  [86735, 136, 5, 6],
  [86734, 136, 5, 5],
  [86733, 136, 5, 4],
  [86731, 136, 5, 3],
  [86730, 136, 5, 2],
  [86728, 136, 5, 1],
  [86727, 136, 4, 6],
  [86726, 136, 4, 5],
  [86725, 136, 4, 4],
  [86724, 136, 4, 3],
  [86723, 136, 4, 2],
  [86722, 136, 4, 1],
  [86721, 136, 3, 6],
  [86720, 136, 3, 5],
  [86719, 136, 3, 4],
  [86718, 136, 3, 3],
  [86717, 136, 3, 2],
  [86716, 136, 3, 1],
  [86715, 136, 2, 6],
  [86714, 136, 2, 5],
  [86713, 136, 2, 4],
  [86712, 136, 2, 3],
  [86711, 136, 2, 2],
  [86710, 136, 2, 1],
  [86708, 136, 1, 6],
  [86707, 136, 1, 5],
  [86706, 136, 1, 4],
  [86705, 136, 1, 3],
  [86704, 136, 1, 2],
  [86703, 136, 1, 1],
  [86702, 135, 6, 6],
  [86701, 135, 6, 5],
  [86700, 135, 6, 4],
  [86699, 135, 6, 3],
  [86698, 135, 6, 2],
  [86697, 135, 6, 1],
  [86696, 135, 5, 6],
  [86695, 135, 5, 5],
  [86694, 135, 5, 4],
  [86693, 135, 5, 3],
  [86691, 135, 5, 2],
  [86690, 135, 5, 1],
  [86689, 135, 4, 6],
  [86688, 135, 4, 5],
  [86687, 135, 4, 4],
  [86686, 135, 4, 3],
  [86685, 135, 4, 2],
  [86684, 135, 4, 1],
  [86683, 135, 3, 6],
  [86682, 135, 3, 5],
  [86681, 135, 3, 4],
  [86680, 135, 3, 3],
  [86679, 135, 3, 2],
  [86678, 135, 3, 1],
  [86677, 135, 2, 6],
  [86676, 135, 2, 5],
  [86675, 135, 2, 4],
  [86674, 135, 2, 3],
  [86673, 135, 2, 2],
  [86672, 135, 2, 1],
  [86671, 135, 1, 6],
  [86670, 135, 1, 5],
  [86669, 135, 1, 4],
  [86668, 135, 1, 3],
  [86667, 135, 1, 2],
  [86666, 135, 1, 1],
  [86665, 134, 6, 6],
  [86664, 134, 6, 5],
  [86663, 134, 6, 4],
  [86661, 134, 6, 3],
  [86660, 134, 6, 2],
  [86659, 134, 6, 1],
  [86658, 134, 5, 6],
  [86656, 134, 5, 5],
  [86655, 134, 5, 4],
  [86654, 134, 5, 3],
  [86653, 134, 5, 2],
  [86652, 134, 5, 1],
  [86651, 134, 4, 6],
  [86650, 134, 4, 5],
  [86649, 134, 4, 4],
  [86648, 134, 4, 3],
  [86647, 134, 4, 2],
  [86646, 134, 4, 1],
  [86645, 134, 3, 6],
  [86644, 134, 3, 5],
  [86643, 134, 3, 4],
  [86642, 134, 3, 3],
  [86641, 134, 3, 2],
  [86640, 134, 3, 1],
  [86639, 134, 2, 6],
  [86637, 134, 2, 5],
  [86636, 134, 2, 4],
  [86635, 134, 2, 3],
  [86634, 134, 2, 2],
  [86633, 134, 2, 1],
  [86632, 134, 1, 6],
  [86631, 134, 1, 5],
  [86630, 134, 1, 4],
  [86629, 134, 1, 3],
  [86628, 134, 1, 2],
  [86627, 134, 1, 1],
  [86626, 133, 6, 6],
  [86625, 133, 6, 5],
  [86624, 133, 6, 4],
  [86623, 133, 6, 3],
  [86622, 133, 6, 2],
  [86621, 133, 6, 1],
  [86620, 133, 5, 6],
  [86619, 133, 5, 5],
  [86618, 133, 5, 4],
  [86617, 133, 5, 3],
  [86616, 133, 5, 2],
  [86615, 133, 5, 1],
  [86614, 133, 4, 6],
  [86613, 133, 4, 5],
  [86612, 133, 4, 4],
  [86611, 133, 4, 3],
  [86610, 133, 4, 2],
  [86609, 133, 4, 1],
  [86608, 133, 3, 6],
  [86607, 133, 3, 5],
  [86606, 133, 3, 4],
  [86605, 133, 3, 3],
  [86604, 133, 3, 2],
  [86603, 133, 3, 1],
  [86602, 133, 2, 6],
  [86601, 133, 2, 5],
  [86600, 133, 2, 4],
  [86599, 133, 2, 3],
  [86598, 133, 2, 2],
  [86597, 133, 2, 1],
  [86596, 133, 1, 6],
  [86595, 133, 1, 5],
  [86594, 133, 1, 4],
  [86593, 133, 1, 3],
  [86592, 133, 1, 2],
  [86590, 133, 1, 1],
  [86589, 132, 6, 6],
  [86588, 132, 6, 5],
  [86587, 132, 6, 4],
  [86586, 132, 6, 3],
  [86585, 132, 6, 2],
  [86584, 132, 6, 1],
  [86583, 132, 5, 6],
  [86582, 132, 5, 5],
  [86581, 132, 5, 4],
  [86580, 132, 5, 3],
  [86579, 132, 5, 2],
  [86578, 132, 5, 1],
  [86577, 132, 4, 6],
  [86576, 132, 4, 5],
  [86575, 132, 4, 4],
  [86574, 132, 4, 3],
  [86573, 132, 4, 2],
  [86572, 132, 4, 1],
  [86571, 132, 3, 6],
  [86570, 132, 3, 5],
  [86569, 132, 3, 4],
  [86568, 132, 3, 3],
  [86567, 132, 3, 2],
  [86566, 132, 3, 1],
  [86565, 132, 2, 6],
  [86564, 132, 2, 5],
  [86563, 132, 2, 4],
  [86562, 132, 2, 3],
  [86561, 132, 2, 2],
  [86560, 132, 2, 1],
  [86559, 132, 1, 6],
  [86558, 132, 1, 5],
  [86557, 132, 1, 4],
  [86556, 132, 1, 3],
  [86555, 132, 1, 2],
  [86554, 132, 1, 1],
  [86553, 131, 6, 6],
  [86552, 131, 6, 5],
  [86551, 131, 6, 4],
  [86550, 131, 6, 3],
  [86549, 131, 6, 2],
  [86548, 131, 6, 1],
  [86547, 131, 5, 6],
  [86546, 131, 5, 5],
  [86545, 131, 5, 4],
  [86544, 131, 5, 3],
  [86543, 131, 5, 2],
  [86542, 131, 5, 1],
  [86541, 131, 4, 6],
  [86540, 131, 4, 5],
  [86539, 131, 4, 4],
  [86538, 131, 4, 3],
  [86537, 131, 4, 2],
  [86536, 131, 4, 1],
  [86535, 131, 3, 6],
  [86534, 131, 3, 5],
  [86533, 131, 3, 4],
  [86532, 131, 3, 3],
  [86531, 131, 3, 2],
  [86530, 131, 3, 1],
  [86529, 131, 2, 6],
  [86528, 131, 2, 5],
  [86527, 131, 2, 4],
  [86526, 131, 2, 3],
  [86525, 131, 2, 2],
  [86524, 131, 2, 1],
  [86523, 131, 1, 6],
  [86522, 131, 1, 5],
  [86521, 131, 1, 4],
  [86520, 131, 1, 3],
  [86519, 131, 1, 2],
  [86518, 131, 1, 1],
  [86517, 130, 6, 6],
  [86516, 130, 6, 5],
  [86515, 130, 6, 4],
  [86514, 130, 6, 3],
  [86513, 130, 6, 2],
  [86512, 130, 6, 1],
  [86511, 130, 5, 6],
  [86510, 130, 5, 5],
  [86509, 130, 5, 4],
  [86508, 130, 5, 3],
  [86507, 130, 5, 2],
  [86506, 130, 5, 1],
  [86505, 130, 4, 6],
  [86503, 130, 4, 5],
  [86502, 130, 4, 4],
  [86501, 130, 4, 3],
  [86500, 130, 4, 2],
  [86499, 130, 4, 1],
  [86498, 130, 3, 6],
  [86497, 130, 3, 5],
  [86495, 130, 3, 4],
  [86494, 130, 3, 3],
  [86493, 130, 3, 2],
  [86492, 130, 3, 1],
  [86491, 130, 2, 6],
  [86490, 130, 2, 5],
  [86489, 130, 2, 4],
  [86488, 130, 2, 3],
  [86486, 130, 2, 2],
  [86485, 130, 2, 1],
  [86484, 130, 1, 6],
  [86483, 130, 1, 5],
  [86481, 130, 1, 4],
  [86480, 130, 1, 3],
  [86479, 130, 1, 2],
  [86478, 130, 1, 1],
  [86477, 129, 6, 6],
  [86476, 129, 6, 5],
  [86475, 129, 6, 4],
  [86474, 129, 6, 3],
  [86473, 129, 6, 2],
  [86472, 129, 6, 1],
  [86471, 129, 5, 6],
  [86470, 129, 5, 5],
  [86469, 129, 5, 4],
  [86468, 129, 5, 3],
  [86467, 129, 5, 2],
  [86466, 129, 5, 1],
  [86465, 129, 4, 6],
  [86464, 129, 4, 5],
  [86463, 129, 4, 4],
  [86462, 129, 4, 3],
  [86461, 129, 4, 2],
  [86460, 129, 4, 1],
  [86459, 129, 3, 6],
  [86458, 129, 3, 5],
  [86457, 129, 3, 4],
  [86456, 129, 3, 3],
  [86455, 129, 3, 2],
  [86454, 129, 3, 1],
  [86453, 129, 2, 6],
  [86452, 129, 2, 5],
  [86451, 129, 2, 4],
  [86450, 129, 2, 3],
  [86449, 129, 2, 2],
  [86448, 129, 2, 1],
  [86447, 129, 1, 6],
  [86446, 129, 1, 5],
  [86445, 129, 1, 4],
  [86444, 129, 1, 3],
  [86443, 129, 1, 2],
  [86442, 129, 1, 1],
  [86441, 128, 6, 6],
  [86439, 128, 6, 5],
  [86438, 128, 6, 4],
  [86437, 128, 6, 3],
  [86436, 128, 6, 2],
  [86435, 128, 6, 1],
  [86434, 128, 5, 6],
  [86433, 128, 5, 5],
  [86432, 128, 5, 4],
  [86431, 128, 5, 3],
  [86430, 128, 5, 2],
  [86429, 128, 5, 1],
  [86428, 128, 4, 6],
  [86427, 128, 4, 5],
  [86426, 128, 4, 4],
  [86425, 128, 4, 3],
  [86424, 128, 4, 2],
  [86423, 128, 4, 1],
  [86422, 128, 3, 6],
  [86421, 128, 3, 5],
  [86420, 128, 3, 4],
  [86419, 128, 3, 3],
  [86418, 128, 3, 2],
  [86417, 128, 3, 1],
  [86416, 128, 2, 6],
  [86415, 128, 2, 5],
  [86414, 128, 2, 4],
  [86413, 128, 2, 3],
  [86412, 128, 2, 2],
  [86411, 128, 2, 1],
  [86410, 128, 1, 6],
  [86408, 128, 1, 5],
  [86407, 128, 1, 4],
  [86405, 128, 1, 3],
  [86404, 128, 1, 2],
  [86403, 128, 1, 1],
  [86402, 127, 6, 6],
  [86401, 127, 6, 5],
  [86400, 127, 6, 4],
  [86399, 127, 6, 3],
  [86398, 127, 6, 2],
  [86397, 127, 6, 1],
  [86396, 127, 5, 6],
  [86395, 127, 5, 5],
  [86394, 127, 5, 4],
  [86393, 127, 5, 3],
  [86392, 127, 5, 2],
  [86391, 127, 5, 1],
  [86390, 127, 4, 6],
  [86389, 127, 4, 5],
  [86388, 127, 4, 4],
  [86387, 127, 4, 3],
  [86386, 127, 4, 2],
  [86385, 127, 4, 1],
  [86384, 127, 3, 6],
  [86383, 127, 3, 5],
  [86382, 127, 3, 4],
  [86381, 127, 3, 3],
  [86380, 127, 3, 2],
  [86379, 127, 3, 1],
  [86378, 127, 2, 6],
  [86377, 127, 2, 5],
  [86376, 127, 2, 4],
  [86375, 127, 2, 3],
  [86374, 127, 2, 2],
  [86373, 127, 2, 1],
  [86372, 127, 1, 6],
  [86371, 127, 1, 5],
  [86370, 127, 1, 4],
  [86369, 127, 1, 3],
  [86368, 127, 1, 2],
  [86367, 127, 1, 1],
  [86366, 126, 6, 6],
  [86365, 126, 6, 5],
  [86364, 126, 6, 4],
  [86363, 126, 6, 3],
  [86362, 126, 6, 2],
  [86361, 126, 6, 1],
  [86360, 126, 5, 6],
  [86359, 126, 5, 5],
  [86358, 126, 5, 4],
  [86357, 126, 5, 3],
  [86356, 126, 5, 2],
  [86355, 126, 5, 1],
  [86354, 126, 4, 6],
  [86353, 126, 4, 5],
  [86352, 126, 4, 4],
  [86351, 126, 4, 3],
  [86350, 126, 4, 2],
  [86349, 126, 4, 1],
  [86348, 126, 3, 6],
  [86347, 126, 3, 5],
  [86346, 126, 3, 4],
  [86345, 126, 3, 3],
  [86344, 126, 3, 2],
  [86343, 126, 3, 1],
  [86342, 126, 2, 6],
  [86341, 126, 2, 5],
  [86340, 126, 2, 4],
  [86339, 126, 2, 3],
  [86338, 126, 2, 2],
  [86337, 126, 2, 1],
  [86336, 126, 1, 6],
  [86335, 126, 1, 5],
  [86334, 126, 1, 4],
  [86333, 126, 1, 3],
  [86332, 126, 1, 2],
  [86330, 126, 1, 1],
  [86329, 125, 6, 6],
  [86328, 125, 6, 5],
  [86327, 125, 6, 4],
  [86326, 125, 6, 3],
  [86324, 125, 6, 2],
  [86323, 125, 6, 1],
  [86322, 125, 5, 6],
  [86321, 125, 5, 5],
  [86320, 125, 5, 4],
  [86319, 125, 5, 3],
  [86317, 125, 5, 2],
  [86316, 125, 5, 1],
  [86315, 125, 4, 6],
  [86314, 125, 4, 5],
  [86313, 125, 4, 4],
  [86312, 125, 4, 3],
  [86311, 125, 4, 2],
  [86310, 125, 4, 1],
  [86309, 125, 3, 6],
  [86308, 125, 3, 5],
  [86307, 125, 3, 4],
  [86306, 125, 3, 3],
  [86305, 125, 3, 2],
  [86304, 125, 3, 1],
  [86303, 125, 2, 6],
  [86302, 125, 2, 5],
  [86301, 125, 2, 4],
  [86300, 125, 2, 3],
  [86299, 125, 2, 2],
  [86298, 125, 2, 1],
  [86297, 125, 1, 6],
  [86296, 125, 1, 5],
  [86295, 125, 1, 4],
  [86294, 125, 1, 3],
  [86293, 125, 1, 2],
  [86292, 125, 1, 1],
  [86291, 124, 6, 6],
  [86290, 124, 6, 5],
  [86288, 124, 6, 4],
  [86287, 124, 6, 3],
  [86286, 124, 6, 2],
  [86285, 124, 6, 1],
  [86284, 124, 5, 6],
  [86283, 124, 5, 5],
  [86282, 124, 5, 4],
  [86281, 124, 5, 3],
  [86280, 124, 5, 2],
  [86279, 124, 5, 1],
  [86278, 124, 4, 6],
  [86277, 124, 4, 5],
  [86276, 124, 4, 4],
  [86275, 124, 4, 3],
  [86274, 124, 4, 2],
  [86273, 124, 4, 1],
  [86272, 124, 3, 6],
  [86271, 124, 3, 5],
  [86270, 124, 3, 4],
  [86269, 124, 3, 3],
  [86268, 124, 3, 2],
  [86267, 124, 3, 1],
  [86266, 124, 2, 6],
  [86265, 124, 2, 5],
  [86264, 124, 2, 4],
  [86263, 124, 2, 3],
  [86262, 124, 2, 2],
  [86261, 124, 2, 1],
  [86260, 124, 1, 6],
  [86259, 124, 1, 5],
  [86258, 124, 1, 4],
  [86257, 124, 1, 3],
  [86256, 124, 1, 2],
  [86255, 124, 1, 1],
  [86254, 123, 6, 6],
  [86253, 123, 6, 5],
  [86252, 123, 6, 4],
  [86251, 123, 6, 3],
  [86250, 123, 6, 2],
  [86249, 123, 6, 1],
  [86248, 123, 5, 6],
  [86246, 123, 5, 5],
  [86245, 123, 5, 4],
  [86244, 123, 5, 3],
  [86243, 123, 5, 2],
  [86242, 123, 5, 1],
  [86241, 123, 4, 6],
  [86240, 123, 4, 5],
  [86239, 123, 4, 4],
  [86238, 123, 4, 3],
  [86237, 123, 4, 2],
  [86236, 123, 4, 1],
  [86235, 123, 3, 6],
  [86234, 123, 3, 5],
  [86233, 123, 3, 4],
  [86232, 123, 3, 3],
  [86231, 123, 3, 2],
  [86230, 123, 3, 1],
  [86229, 123, 2, 6],
  [86228, 123, 2, 5],
  [86227, 123, 2, 4],
  [86226, 123, 2, 3],
  [86225, 123, 2, 2],
  [86224, 123, 2, 1],
  [86223, 123, 1, 6],
  [86222, 123, 1, 5],
  [86221, 123, 1, 4],
  [86220, 123, 1, 3],
  [86218, 123, 1, 2],
  [86217, 123, 1, 1],
  [86216, 122, 6, 6],
  [86215, 122, 6, 5],
  [86214, 122, 6, 4],
  [86213, 122, 6, 3],
  [86212, 122, 6, 2],
  [86211, 122, 6, 1],
  [86210, 122, 5, 6],
  [86209, 122, 5, 5],
  [86208, 122, 5, 4],
  [86207, 122, 5, 3],
  [86206, 122, 5, 2],
  [86205, 122, 5, 1],
  [86204, 122, 4, 6],
  [86203, 122, 4, 5],
  [86202, 122, 4, 4],
  [86201, 122, 4, 3],
  [86200, 122, 4, 2],
  [86199, 122, 4, 1],
  [86198, 122, 3, 6],
  [86197, 122, 3, 5],
  [86196, 122, 3, 4],
  [86195, 122, 3, 3],
  [86194, 122, 3, 2],
  [86193, 122, 3, 1],
  [86192, 122, 2, 6],
  [86191, 122, 2, 5],
  [86189, 122, 2, 4],
  [86188, 122, 2, 3],
  [86187, 122, 2, 2],
  [86186, 122, 2, 1],
  [86185, 122, 1, 6],
  [86184, 122, 1, 5],
  [86183, 122, 1, 4],
  [86182, 122, 1, 3],
  [86181, 122, 1, 2],
  [86180, 122, 1, 1],
  [86179, 121, 6, 6],
  [86178, 121, 6, 5],
  [86177, 121, 6, 4],
  [86176, 121, 6, 3],
  [86175, 121, 6, 2],
  [86174, 121, 6, 1],
  [86173, 121, 5, 6],
  [86172, 121, 5, 5],
  [86171, 121, 5, 4],
  [86170, 121, 5, 3],
  [86169, 121, 5, 2],
  [86168, 121, 5, 1],
  [86167, 121, 4, 6],
  [86166, 121, 4, 5],
  [86165, 121, 4, 4],
  [86164, 121, 4, 3],
  [86163, 121, 4, 2],
  [86162, 121, 4, 1],
  [86161, 121, 3, 6],
  [86160, 121, 3, 5],
  [86159, 121, 3, 4],
  [86158, 121, 3, 3],
  [86157, 121, 3, 2],
  [86156, 121, 3, 1],
  [86155, 121, 2, 6],
  [86154, 121, 2, 5],
  [86153, 121, 2, 4],
  [86152, 121, 2, 3],
  [86151, 121, 2, 2],
  [86150, 121, 2, 1],
  [86149, 121, 1, 6],
  [86148, 121, 1, 5],
  [86147, 121, 1, 4],
  [86146, 121, 1, 3],
  [86145, 121, 1, 2],
  [86144, 121, 1, 1],
  [86143, 120, 6, 6],
  [86142, 120, 6, 5],
  [86141, 120, 6, 4],
  [86140, 120, 6, 3],
  [86138, 120, 6, 2],
  [86137, 120, 6, 1],
  [86136, 120, 5, 6],
  [86135, 120, 5, 5],
  [86134, 120, 5, 4],
  [86133, 120, 5, 3],
  [86132, 120, 5, 2],
  [86131, 120, 5, 1],
  [86130, 120, 4, 6],
  [86129, 120, 4, 5],
  [86128, 120, 4, 4],
  [86127, 120, 4, 3],
  [86126, 120, 4, 2],
  [86125, 120, 4, 1],
  [86124, 120, 3, 6],
  [86123, 120, 3, 5],
  [86122, 120, 3, 4],
  [86121, 120, 3, 3],
  [86120, 120, 3, 2],
  [86119, 120, 3, 1],
  [86118, 120, 2, 6],
  [86117, 120, 2, 5],
  [86116, 120, 2, 4],
  [86115, 120, 2, 3],
  [86114, 120, 2, 2],
  [86113, 120, 2, 1],
  [86112, 120, 1, 6],
  [86111, 120, 1, 5],
  [86110, 120, 1, 4],
  [86109, 120, 1, 3],
  [86108, 120, 1, 2],
  [86107, 120, 1, 1],
  [86106, 119, 6, 6],
  [86105, 119, 6, 5],
  [86104, 119, 6, 4],
  [86103, 119, 6, 3],
  [86102, 119, 6, 2],
  [86101, 119, 6, 1],
  [86100, 119, 5, 6],
  [86099, 119, 5, 5],
  [86098, 119, 5, 4],
  [86097, 119, 5, 3],
  [86096, 119, 5, 2],
  [86095, 119, 5, 1],
  [86094, 119, 4, 6],
  [86092, 119, 4, 5],
  [86091, 119, 4, 4],
  [86090, 119, 4, 3],
  [86089, 119, 4, 2],
  [86088, 119, 4, 1],
  [86087, 119, 3, 6],
  [86085, 119, 3, 5],
  [86084, 119, 3, 4],
  [86083, 119, 3, 3],
  [86082, 119, 3, 2],
  [86080, 119, 3, 1],
  [86079, 119, 2, 6],
  [86078, 119, 2, 5],
  [86077, 119, 2, 4],
  [86076, 119, 2, 3],
  [86075, 119, 2, 2],
  [86074, 119, 2, 1],
  [86073, 119, 1, 6],
  [86072, 119, 1, 5],
  [86071, 119, 1, 4],
  [86070, 119, 1, 3],
  [86069, 119, 1, 2],
  [86068, 119, 1, 1],
  [86067, 118, 6, 6],
  [86066, 118, 6, 5],
  [86065, 118, 6, 4],
  [86064, 118, 6, 3],
  [86063, 118, 6, 2],
  [86062, 118, 6, 1],
  [86061, 118, 5, 6],
  [86060, 118, 5, 5],
  [86059, 118, 5, 4],
  [86058, 118, 5, 3],
  [86057, 118, 5, 2],
  [86056, 118, 5, 1],
  [86055, 118, 4, 6],
  [86054, 118, 4, 5],
  [86053, 118, 4, 4],
  [86052, 118, 4, 3],
  [86051, 118, 4, 2],
  [86050, 118, 4, 1],
  [86049, 118, 3, 6],
  [86048, 118, 3, 5],
  [86047, 118, 3, 4],
  [86046, 118, 3, 3],
  [86045, 118, 3, 2],
  [86044, 118, 3, 1],
  [86042, 118, 2, 6],
  [86041, 118, 2, 5],
  [86040, 118, 2, 4],
  [86039, 118, 2, 3],
  [86038, 118, 2, 2],
  [86037, 118, 2, 1],
  [86036, 118, 1, 6],
  [86035, 118, 1, 5],
  [86034, 118, 1, 4],
  [86033, 118, 1, 3],
  [86032, 118, 1, 2],
  [86031, 118, 1, 1],
  [86030, 117, 6, 6],
  [86029, 117, 6, 5],
  [86028, 117, 6, 4],
  [86027, 117, 6, 3],
  [86026, 117, 6, 2],
  [86025, 117, 6, 1],
  [86024, 117, 5, 6],
  [86023, 117, 5, 5],
  [86022, 117, 5, 4],
  [86021, 117, 5, 3],
  [86020, 117, 5, 2],
  [86018, 117, 5, 1],
  [86017, 117, 4, 6],
  [86016, 117, 4, 5],
  [86015, 117, 4, 4],
  [86014, 117, 4, 3],
  [86013, 117, 4, 2],
  [86012, 117, 4, 1],
  [86011, 117, 3, 6],
  [86010, 117, 3, 5],
  [86009, 117, 3, 4],
  [86008, 117, 3, 3],
  [86007, 117, 3, 2],
  [86006, 117, 3, 1],
  [86005, 117, 2, 6],
  [86004, 117, 2, 5],
  [86003, 117, 2, 4],
  [86002, 117, 2, 3],
  [86001, 117, 2, 2],
  [86000, 117, 2, 1],
  [85999, 117, 1, 6],
  [85998, 117, 1, 5],
  [85997, 117, 1, 4],
  [85996, 117, 1, 3],
  [85995, 117, 1, 2],
  [85994, 117, 1, 1],
  [85993, 116, 6, 6],
  [85992, 116, 6, 5],
  [85991, 116, 6, 4],
  [85990, 116, 6, 3],
  [85989, 116, 6, 2],
  [85988, 116, 6, 1],
  [85987, 116, 5, 6],
  [85986, 116, 5, 5],
  [85985, 116, 5, 4],
  [85984, 116, 5, 3],
  [85983, 116, 5, 2],
  [85981, 116, 5, 1],
  [85980, 116, 4, 6],
  [85979, 116, 4, 5],
  [85978, 116, 4, 4],
  [85977, 116, 4, 3],
  [85976, 116, 4, 2],
  [85975, 116, 4, 1],
  [85974, 116, 3, 6],
  [85973, 116, 3, 5],
  [85972, 116, 3, 4],
  [85971, 116, 3, 3],
  [85970, 116, 3, 2],
  [85969, 116, 3, 1],
  [85968, 116, 2, 6],
  [85967, 116, 2, 5],
  [85966, 116, 2, 4],
  [85965, 116, 2, 3],
  [85964, 116, 2, 2],
  [85963, 116, 2, 1],
  [85962, 116, 1, 6],
  [85961, 116, 1, 5],
  [85960, 116, 1, 4],
  [85959, 116, 1, 3],
  [85958, 116, 1, 2],
  [85957, 116, 1, 1],
  [85956, 115, 6, 6],
  [85955, 115, 6, 5],
  [85954, 115, 6, 4],
  [85953, 115, 6, 3],
  [85952, 115, 6, 2],
  [85951, 115, 6, 1],
  [85950, 115, 5, 6],
  [85948, 115, 5, 5],
  [85947, 115, 5, 4],
  [85946, 115, 5, 3],
  [85945, 115, 5, 2],
  [85944, 115, 5, 1],
  [85942, 115, 4, 6],
  [85941, 115, 4, 5],
  [85940, 115, 4, 4],
  [85939, 115, 4, 3],
  [85938, 115, 4, 2],
  [85937, 115, 4, 1],
  [85936, 115, 3, 6],
  [85935, 115, 3, 5],
  [85934, 115, 3, 4],
  [85933, 115, 3, 3],
  [85932, 115, 3, 2],
  [85931, 115, 3, 1],
  [85930, 115, 2, 6],
  [85929, 115, 2, 5],
  [85928, 115, 2, 4],
  [85927, 115, 2, 3],
  [85926, 115, 2, 2],
  [85925, 115, 2, 1],
  [85924, 115, 1, 6],
  [85923, 115, 1, 5],
  [85922, 115, 1, 4],
  [85921, 115, 1, 3],
  [85920, 115, 1, 2],
  [85919, 115, 1, 1],
  [85918, 114, 6, 6],
  [85917, 114, 6, 5],
  [85916, 114, 6, 4],
  [85915, 114, 6, 3],
  [85914, 114, 6, 2],
  [85913, 114, 6, 1],
  [85912, 114, 5, 6],
  [85911, 114, 5, 5],
  [85910, 114, 5, 4],
  [85909, 114, 5, 3],
  [85908, 114, 5, 2],
  [85907, 114, 5, 1],
  [85906, 114, 4, 6],
  [85905, 114, 4, 5],
  [85904, 114, 4, 4],
  [85903, 114, 4, 3],
  [85902, 114, 4, 2],
  [85901, 114, 4, 1],
  [85899, 114, 3, 6],
  [85898, 114, 3, 5],
  [85897, 114, 3, 4],
  [85896, 114, 3, 3],
  [85895, 114, 3, 2],
  [85894, 114, 3, 1],
  [85893, 114, 2, 6],
  [85892, 114, 2, 5],
  [85891, 114, 2, 4],
  [85890, 114, 2, 3],
  [85889, 114, 2, 2],
  [85888, 114, 2, 1],
  [85887, 114, 1, 6],
  [85886, 114, 1, 5],
  [85885, 114, 1, 4],
  [85884, 114, 1, 3],
  [85883, 114, 1, 2],
  [85882, 114, 1, 1],
  [85881, 113, 6, 6],
  [85880, 113, 6, 5],
  [85879, 113, 6, 4],
  [85878, 113, 6, 3],
  [85877, 113, 6, 2],
  [85876, 113, 6, 1],
  [85875, 113, 5, 6],
  [85874, 113, 5, 5],
  [85873, 113, 5, 4],
  [85872, 113, 5, 3],
  [85871, 113, 5, 2],
  [85870, 113, 5, 1],
  [85869, 113, 4, 6],
  [85868, 113, 4, 5],
  [85867, 113, 4, 4],
  [85866, 113, 4, 3],
  [85865, 113, 4, 2],
  [85864, 113, 4, 1],
  [85863, 113, 3, 6],
  [85862, 113, 3, 5],
  [85861, 113, 3, 4],
  [85860, 113, 3, 3],
  [85859, 113, 3, 2],
  [85858, 113, 3, 1],
  [85857, 113, 2, 6],
  [85856, 113, 2, 5],
  [85855, 113, 2, 4],
  [85854, 113, 2, 3],
  [85853, 113, 2, 2],
  [85852, 113, 2, 1],
  [85851, 113, 1, 6],
  [85850, 113, 1, 5],
  [85849, 113, 1, 4],
  [85848, 113, 1, 3],
  [85847, 113, 1, 2],
  [85844, 113, 1, 1],
  [85843, 112, 6, 6],
  [85842, 112, 6, 5],
  [85841, 112, 6, 4],
  [85840, 112, 6, 3],
  [85839, 112, 6, 2],
  [85838, 112, 6, 1],
  [85837, 112, 5, 6],
  [85836, 112, 5, 5],
  [85835, 112, 5, 4],
  [85834, 112, 5, 3],
  [85833, 112, 5, 2],
  [85832, 112, 5, 1],
  [85831, 112, 4, 6],
  [85830, 112, 4, 5],
  [85829, 112, 4, 4],
  [85828, 112, 4, 3],
  [85827, 112, 4, 2],
  [85826, 112, 4, 1],
  [85825, 112, 3, 6],
  [85824, 112, 3, 5],
  [85823, 112, 3, 4],
  [85822, 112, 3, 3],
  [85821, 112, 3, 2],
  [85820, 112, 3, 1],
  [85819, 112, 2, 6],
  [85818, 112, 2, 5],
  [85817, 112, 2, 4],
  [85816, 112, 2, 3],
  [85815, 112, 2, 2],
  [85814, 112, 2, 1],
  [85813, 112, 1, 6],
  [85812, 112, 1, 5],
  [85811, 112, 1, 4],
  [85810, 112, 1, 3],
  [85809, 112, 1, 2],
  [85808, 112, 1, 1],
  [85807, 111, 6, 6],
  [85806, 111, 6, 5],
  [85805, 111, 6, 4],
  [85804, 111, 6, 3],
  [85802, 111, 6, 2],
  [85801, 111, 6, 1],
  [85800, 111, 5, 6],
  [85799, 111, 5, 5],
  [85798, 111, 5, 4],
  [85797, 111, 5, 3],
  [85796, 111, 5, 2],
  [85795, 111, 5, 1],
  [85794, 111, 4, 6],
  [85793, 111, 4, 5],
  [85792, 111, 4, 4],
  [85791, 111, 4, 3],
  [85790, 111, 4, 2],
  [85789, 111, 4, 1],
  [85788, 111, 3, 6],
  [85787, 111, 3, 5],
  [85786, 111, 3, 4],
  [85785, 111, 3, 3],
  [85784, 111, 3, 2],
  [85783, 111, 3, 1],
  [85782, 111, 2, 6],
  [85781, 111, 2, 5],
  [85780, 111, 2, 4],
  [85779, 111, 2, 3],
  [85778, 111, 2, 2],
  [85776, 111, 2, 1],
  [85775, 111, 1, 6],
  [85774, 111, 1, 5],
  [85773, 111, 1, 4],
  [85772, 111, 1, 3],
  [85771, 111, 1, 2],
  [85770, 111, 1, 1],
  [85769, 110, 6, 6],
  [85768, 110, 6, 5],
  [85767, 110, 6, 4],
  [85766, 110, 6, 3],
  [85765, 110, 6, 2],
  [85764, 110, 6, 1],
  [85763, 110, 5, 6],
  [85762, 110, 5, 5],
  [85761, 110, 5, 4],
  [85760, 110, 5, 3],
  [85759, 110, 5, 2],
  [85758, 110, 5, 1],
  [85756, 110, 4, 6],
  [85755, 110, 4, 5],
  [85754, 110, 4, 4],
  [85753, 110, 4, 3],
  [85752, 110, 4, 2],
  [85751, 110, 4, 1],
  [85750, 110, 3, 6],
  [85749, 110, 3, 5],
  [85748, 110, 3, 4],
  [85747, 110, 3, 3],
  [85746, 110, 3, 2],
  [85745, 110, 3, 1],
  [85744, 110, 2, 6],
  [85743, 110, 2, 5],
  [85742, 110, 2, 4],
  [85741, 110, 2, 3],
  [85740, 110, 2, 2],
  [85739, 110, 2, 1],
  [85738, 110, 1, 6],
  [85737, 110, 1, 5],
  [85736, 110, 1, 4],
  [85734, 110, 1, 3],
  [85733, 110, 1, 2],
  [85732, 110, 1, 1],
  [85731, 109, 6, 6],
  [85730, 109, 6, 5],
  [85729, 109, 6, 4],
  [85728, 109, 6, 3],
  [85727, 109, 6, 2],
  [85726, 109, 6, 1],
  [85725, 109, 5, 6],
  [85724, 109, 5, 5],
  [85723, 109, 5, 4],
  [85722, 109, 5, 3],
  [85721, 109, 5, 2],
  [85720, 109, 5, 1],
  [85719, 109, 4, 6],
  [85717, 109, 4, 5],
  [85716, 109, 4, 4],
  [85715, 109, 4, 3],
  [85714, 109, 4, 2],
  [85713, 109, 4, 1],
  [85712, 109, 3, 6],
  [85711, 109, 3, 5],
  [85709, 109, 3, 4],
  [85708, 109, 3, 3],
  [85707, 109, 3, 2],
  [85706, 109, 3, 1],
  [85705, 109, 2, 6],
  [85704, 109, 2, 5],
  [85703, 109, 2, 4],
  [85702, 109, 2, 3],
  [85700, 109, 2, 2],
  [85699, 109, 2, 1],
  [85698, 109, 1, 6],
  [85697, 109, 1, 5],
  [85696, 109, 1, 4],
  [85695, 109, 1, 3],
  [85693, 109, 1, 2],
  [85692, 109, 1, 1],
  [85691, 108, 6, 6],
  [85690, 108, 6, 5],
  [85689, 108, 6, 4],
  [85688, 108, 6, 3],
  [85687, 108, 6, 2],
  [85686, 108, 6, 1],
  [85685, 108, 5, 6],
  [85684, 108, 5, 5],
  [85683, 108, 5, 4],
  [85682, 108, 5, 3],
  [85681, 108, 5, 2],
  [85680, 108, 5, 1],
  [85679, 108, 4, 6],
  [85678, 108, 4, 5],
  [85677, 108, 4, 4],
  [85676, 108, 4, 3],
  [85675, 108, 4, 2],
  [85674, 108, 4, 1],
  [85673, 108, 3, 6],
  [85672, 108, 3, 5],
  [85671, 108, 3, 4],
  [85670, 108, 3, 3],
  [85669, 108, 3, 2],
  [85668, 108, 3, 1],
  [85667, 108, 2, 6],
  [85666, 108, 2, 5],
  [85665, 108, 2, 4],
  [85664, 108, 2, 3],
  [85663, 108, 2, 2],
  [85662, 108, 2, 1],
  [85661, 108, 1, 6],
  [85660, 108, 1, 5],
  [85659, 108, 1, 4],
  [85658, 108, 1, 3],
  [85657, 108, 1, 2],
  [85656, 108, 1, 1],
  [85655, 107, 6, 6],
  [85654, 107, 6, 5],
  [85653, 107, 6, 4],
  [85652, 107, 6, 3],
  [85651, 107, 6, 2],
  [85650, 107, 6, 1],
  [85649, 107, 5, 6],
  [85648, 107, 5, 5],
  [85647, 107, 5, 4],
  [85646, 107, 5, 3],
  [85645, 107, 5, 2],
  [85644, 107, 5, 1],
  [85643, 107, 4, 6],
  [85642, 107, 4, 5],
  [85641, 107, 4, 4],
  [85640, 107, 4, 3],
  [85639, 107, 4, 2],
  [85638, 107, 4, 1],
  [85637, 107, 3, 6],
  [85636, 107, 3, 5],
  [85635, 107, 3, 4],
  [85634, 107, 3, 3],
  [85633, 107, 3, 2],
  [85632, 107, 3, 1],
  [85631, 107, 2, 6],
  [85630, 107, 2, 5],
  [85629, 107, 2, 4],
  [85628, 107, 2, 3],
  [85627, 107, 2, 2],
  [85626, 107, 2, 1],
  [85625, 107, 1, 6],
  [85624, 107, 1, 5],
  [85623, 107, 1, 4],
  [85622, 107, 1, 3],
  [85621, 107, 1, 2],
  [85620, 107, 1, 1],
  [85619, 106, 6, 6],
  [85618, 106, 6, 5],
  [85617, 106, 6, 4],
  [85616, 106, 6, 3],
  [85615, 106, 6, 2],
  [85614, 106, 6, 1],
  [85612, 106, 5, 6],
  [85611, 106, 5, 5],
  [85610, 106, 5, 4],
  [85609, 106, 5, 3],
  [85608, 106, 5, 2],
  [85607, 106, 5, 1],
  [85606, 106, 4, 6],
  [85605, 106, 4, 5],
  [85604, 106, 4, 4],
  [85602, 106, 4, 3],
  [85601, 106, 4, 2],
  [85600, 106, 4, 1],
  [85599, 106, 3, 6],
  [85598, 106, 3, 5],
  [85597, 106, 3, 4],
  [85596, 106, 3, 3],
  [85595, 106, 3, 2],
  [85594, 106, 3, 1],
  [85593, 106, 2, 6],
  [85592, 106, 2, 5],
  [85591, 106, 2, 4],
  [85590, 106, 2, 3],
  [85589, 106, 2, 2],
  [85588, 106, 2, 1],
  [85586, 106, 1, 6],
  [85585, 106, 1, 5],
  [85584, 106, 1, 4],
  [85583, 106, 1, 3],
  [85582, 106, 1, 2],
  [85581, 106, 1, 1],
  [85580, 105, 6, 6],
  [85579, 105, 6, 5],
  [85578, 105, 6, 4],
  [85577, 105, 6, 3],
  [85576, 105, 6, 2],
  [85575, 105, 6, 1],
  [85574, 105, 5, 6],
  [85573, 105, 5, 5],
  [85572, 105, 5, 4],
  [85571, 105, 5, 3],
  [85570, 105, 5, 2],
  [85569, 105, 5, 1],
  [85568, 105, 4, 6],
  [85567, 105, 4, 5],
  [85566, 105, 4, 4],
  [85565, 105, 4, 3],
  [85563, 105, 4, 2],
  [85560, 105, 4, 1],
  [85559, 105, 3, 6],
  [85558, 105, 3, 5],
  [85556, 105, 3, 4],
  [85555, 105, 3, 3],
  [85553, 105, 3, 2],
  [85552, 105, 3, 1],
  [85551, 105, 2, 6],
  [85550, 105, 2, 5],
  [85549, 105, 2, 4],
  [85548, 105, 2, 3],
  [85547, 105, 2, 2],
  [85546, 105, 2, 1],
  [85545, 105, 1, 6],
  [85544, 105, 1, 5],
  [85543, 105, 1, 4],
  [85542, 105, 1, 3],
  [85541, 105, 1, 2],
  [85540, 105, 1, 1],
  [85539, 104, 6, 6],
  [85538, 104, 6, 5],
  [85536, 104, 6, 4],
  [85535, 104, 6, 3],
  [85534, 104, 6, 2],
  [85533, 104, 6, 1],
  [85532, 104, 5, 6],
  [85531, 104, 5, 5],
  [85530, 104, 5, 4],
  [85529, 104, 5, 3],
  [85528, 104, 5, 2],
  [85527, 104, 5, 1],
  [85526, 104, 4, 6],
  [85525, 104, 4, 5],
  [85524, 104, 4, 4],
  [85523, 104, 4, 3],
  [85522, 104, 4, 2],
  [85520, 104, 4, 1],
  [85519, 104, 3, 6],
  [85518, 104, 3, 5],
  [85517, 104, 3, 4],
  [85516, 104, 3, 3],
  [85515, 104, 3, 2],
  [85514, 104, 3, 1],
  [85513, 104, 2, 6],
  [85512, 104, 2, 5],
  [85511, 104, 2, 4],
  [85510, 104, 2, 3],
  [85509, 104, 2, 2],
  [85508, 104, 2, 1],
  [85507, 104, 1, 6],
  [85506, 104, 1, 5],
  [85505, 104, 1, 4],
  [85504, 104, 1, 3],
  [85503, 104, 1, 2],
  [85502, 104, 1, 1],
  [85501, 103, 6, 6],
  [85500, 103, 6, 5],
  [85499, 103, 6, 4],
  [85498, 103, 6, 3],
  [85497, 103, 6, 2],
  [85496, 103, 6, 1],
  [85495, 103, 5, 6],
  [85494, 103, 5, 5],
  [85493, 103, 5, 4],
  [85492, 103, 5, 3],
  [85491, 103, 5, 2],
  [85490, 103, 5, 1],
  [85489, 103, 4, 6],
  [85488, 103, 4, 5],
  [85486, 103, 4, 4],
  [85485, 103, 4, 3],
  [85484, 103, 4, 2],
  [85483, 103, 4, 1],
  [85482, 103, 3, 6],
  [85481, 103, 3, 5],
  [85480, 103, 3, 4],
  [85479, 103, 3, 3],
  [85478, 103, 3, 2],
  [85477, 103, 3, 1],
  [85476, 103, 2, 6],
  [85475, 103, 2, 5],
  [85474, 103, 2, 4],
  [85473, 103, 2, 3],
  [85472, 103, 2, 2],
  [85471, 103, 2, 1],
  [85470, 103, 1, 6],
  [85468, 103, 1, 5],
  [85467, 103, 1, 4],
  [85466, 103, 1, 3],
  [85465, 103, 1, 2],
  [85464, 103, 1, 1],
  [85463, 102, 6, 6],
  [85462, 102, 6, 5],
  [85461, 102, 6, 4],
  [85460, 102, 6, 3],
  [85459, 102, 6, 2],
  [85458, 102, 6, 1],
  [85457, 102, 5, 6],
  [85456, 102, 5, 5],
  [85455, 102, 5, 4],
  [85454, 102, 5, 3],
  [85452, 102, 5, 2],
  [85451, 102, 5, 1],
  [85450, 102, 4, 6],
  [85449, 102, 4, 5],
  [85448, 102, 4, 4],
  [85447, 102, 4, 3],
  [85446, 102, 4, 2],
  [85445, 102, 4, 1],
  [85444, 102, 3, 6],
  [85443, 102, 3, 5],
  [85442, 102, 3, 4],
  [85441, 102, 3, 3],
  [85440, 102, 3, 2],
  [85439, 102, 3, 1],
  [85437, 102, 2, 6],
  [85436, 102, 2, 5],
  [85435, 102, 2, 4],
  [85434, 102, 2, 3],
  [85433, 102, 2, 2],
  [85432, 102, 2, 1],
  [85431, 102, 1, 6],
  [85430, 102, 1, 5],
  [85429, 102, 1, 4],
  [85428, 102, 1, 3],
  [85427, 102, 1, 2],
  [85425, 102, 1, 1],
  [85424, 101, 6, 6],
  [85423, 101, 6, 5],
  [85422, 101, 6, 4],
  [85421, 101, 6, 3],
  [85420, 101, 6, 2],
  [85419, 101, 6, 1],
  [85417, 101, 5, 6],
  [85416, 101, 5, 5],
  [85415, 101, 5, 4],
  [85414, 101, 5, 3],
  [85413, 101, 5, 2],
  [85412, 101, 5, 1],
  [85411, 101, 4, 6],
  [85409, 101, 4, 5],
  [85408, 101, 4, 4],
  [85407, 101, 4, 3],
  [85406, 101, 4, 2],
  [85405, 101, 4, 1],
  [85403, 101, 3, 6],
  [85402, 101, 3, 5],
  [85401, 101, 3, 4],
  [85400, 101, 3, 3],
  [85399, 101, 3, 2],
  [85398, 101, 3, 1],
  [85397, 101, 2, 6],
  [85396, 101, 2, 5],
  [85395, 101, 2, 4],
  [85394, 101, 2, 3],
  [85393, 101, 2, 2],
  [85392, 101, 2, 1],
  [85391, 101, 1, 6],
  [85390, 101, 1, 5],
  [85388, 101, 1, 4],
  [85387, 101, 1, 3],
  [85386, 101, 1, 2],
  [85385, 101, 1, 1],
  [85384, 100, 6, 6],
  [85383, 100, 6, 5],
  [85382, 100, 6, 4],
  [85381, 100, 6, 3],
  [85380, 100, 6, 2],
  [85379, 100, 6, 1],
  [85378, 100, 5, 6],
  [85377, 100, 5, 5],
  [85376, 100, 5, 4],
  [85375, 100, 5, 3],
  [85374, 100, 5, 2],
  [85373, 100, 5, 1],
  [85372, 100, 4, 6],
  [85371, 100, 4, 5],
  [85370, 100, 4, 4],
  [85369, 100, 4, 3],
  [85368, 100, 4, 2],
  [85367, 100, 4, 1],
  [85366, 100, 3, 6],
  [85365, 100, 3, 5],
  [85363, 100, 3, 4],
  [85362, 100, 3, 3],
  [85361, 100, 3, 2],
  [85360, 100, 3, 1],
  [85358, 100, 2, 6],
  [85357, 100, 2, 5],
  [85356, 100, 2, 4],
  [85355, 100, 2, 3],
  [85354, 100, 2, 2],
  [85353, 100, 2, 1],
  [85352, 100, 1, 6],
  [85351, 100, 1, 5],
  [85350, 100, 1, 4],
  [85349, 100, 1, 3],
  [85348, 100, 1, 2],
  [85347, 100, 1, 1],
  [85346, 99, 6, 6],
  [85345, 99, 6, 5],
  [85344, 99, 6, 4],
  [85343, 99, 6, 3],
  [85342, 99, 6, 2],
  [85341, 99, 6, 1],
  [85339, 99, 5, 6],
  [85338, 99, 5, 5],
  [85337, 99, 5, 4],
  [85336, 99, 5, 3],
  [85335, 99, 5, 2],
  [85334, 99, 5, 1],
  [85333, 99, 4, 6],
  [85332, 99, 4, 5],
  [85331, 99, 4, 4],
  [85330, 99, 4, 3],
  [85329, 99, 4, 2],
  [85328, 99, 4, 1],
  [85290, 99, 3, 6],
  [85288, 99, 3, 5],
  [85287, 99, 3, 4],
  [85286, 99, 3, 3],
  [85285, 99, 3, 2],
  [85284, 99, 3, 1],
  [85283, 99, 2, 6],
  [85282, 99, 2, 5],
  [85281, 99, 2, 4],
  [85280, 99, 2, 3],
  [85279, 99, 2, 2],
  [85278, 99, 2, 1],
  [85277, 99, 1, 6],
  [85276, 99, 1, 5],
  [85275, 99, 1, 4],
  [85274, 99, 1, 3],
  [85273, 99, 1, 2],
  [85272, 99, 1, 1],
  [85271, 98, 6, 6],
  [85270, 98, 6, 5],
  [85269, 98, 6, 4],
  [85268, 98, 6, 3],
  [85267, 98, 6, 2],
  [85265, 98, 6, 1],
  [85262, 98, 5, 6],
  [85261, 98, 5, 5],
  [85260, 98, 5, 4],
  [85259, 98, 5, 3],
  [85258, 98, 5, 2],
  [85257, 98, 5, 1],
  [85256, 98, 4, 6],
  [85255, 98, 4, 5],
  [85254, 98, 4, 4],
  [85253, 98, 4, 3],
  [85252, 98, 4, 2],
  [85251, 98, 4, 1],
  [85250, 98, 3, 6],
  [85249, 98, 3, 5],
  [85248, 98, 3, 4],
  [85247, 98, 3, 3],
  [85246, 98, 3, 2],
  [85245, 98, 3, 1],
  [85244, 98, 2, 6],
  [85243, 98, 2, 5],
  [85241, 98, 2, 4],
  [85240, 98, 2, 3],
  [85239, 98, 2, 2],
  [85238, 98, 2, 1],
  [85237, 98, 1, 6],
  [85236, 98, 1, 5],
  [85235, 98, 1, 4],
  [85234, 98, 1, 3],
  [85233, 98, 1, 2],
  [85232, 98, 1, 1],
  [85231, 97, 6, 6],
  [85230, 97, 6, 5],
  [85229, 97, 6, 4],
  [85228, 97, 6, 3],
  [85227, 97, 6, 2],
  [85226, 97, 6, 1],
  [85225, 97, 5, 6],
  [85224, 97, 5, 5],
  [85223, 97, 5, 4],
  [85222, 97, 5, 3],
  [85221, 97, 5, 2],
  [85220, 97, 5, 1],
  [85219, 97, 4, 6],
  [85218, 97, 4, 5],
  [85217, 97, 4, 4],
  [85216, 97, 4, 3],
  [85215, 97, 4, 2],
  [85214, 97, 4, 1],
  [85213, 97, 3, 6],
  [85212, 97, 3, 5],
  [85211, 97, 3, 4],
  [85210, 97, 3, 3],
  [85208, 97, 3, 2],
  [85207, 97, 3, 1],
  [85206, 97, 2, 6],
  [85205, 97, 2, 5],
  [85204, 97, 2, 4],
  [85203, 97, 2, 3],
  [85202, 97, 2, 2],
  [85201, 97, 2, 1],
  [85200, 97, 1, 6],
  [85198, 97, 1, 5],
  [85197, 97, 1, 4],
  [85196, 97, 1, 3],
  [85195, 97, 1, 2],
  [85194, 97, 1, 1],
  [85192, 96, 6, 6],
  [85191, 96, 6, 5],
  [85190, 96, 6, 4],
  [85189, 96, 6, 3],
  [85188, 96, 6, 2],
  [85187, 96, 6, 1],
  [85186, 96, 5, 6],
  [85185, 96, 5, 5],
  [85184, 96, 5, 4],
  [85183, 96, 5, 3],
  [85182, 96, 5, 2],
  [85181, 96, 5, 1],
  [85180, 96, 4, 6],
  [85179, 96, 4, 5],
  [85178, 96, 4, 4],
  [85177, 96, 4, 3],
  [85176, 96, 4, 2],
  [85175, 96, 4, 1],
  [85174, 96, 3, 6],
  [85173, 96, 3, 5],
  [85172, 96, 3, 4],
  [85171, 96, 3, 3],
  [85170, 96, 3, 2],
  [85169, 96, 3, 1],
  [85168, 96, 2, 6],
  [85167, 96, 2, 5],
  [85166, 96, 2, 4],
  [85165, 96, 2, 3],
  [85164, 96, 2, 2],
  [85162, 96, 2, 1],
  [85161, 96, 1, 6],
  [85160, 96, 1, 5],
  [85159, 96, 1, 4],
  [85158, 96, 1, 3],
  [85157, 96, 1, 2],
  [85156, 96, 1, 1],
  [85155, 95, 6, 6],
  [85154, 95, 6, 5],
  [85153, 95, 6, 4],
  [85152, 95, 6, 3],
  [85151, 95, 6, 2],
  [85150, 95, 6, 1],
  [85149, 95, 5, 6],
  [85148, 95, 5, 5],
  [85147, 95, 5, 4],
  [85146, 95, 5, 3],
  [85145, 95, 5, 2],
  [85144, 95, 5, 1],
  [85143, 95, 4, 6],
  [85142, 95, 4, 5],
  [85141, 95, 4, 4],
  [85139, 95, 4, 3],
  [85138, 95, 4, 2],
  [85137, 95, 4, 1],
  [85133, 95, 3, 6],
  [85132, 95, 3, 5],
  [85131, 95, 3, 4],
  [85130, 95, 3, 3],
  [85129, 95, 3, 2],
  [85128, 95, 3, 1],
  [85127, 95, 2, 6],
  [85126, 95, 2, 5],
  [85125, 95, 2, 4],
  [85124, 95, 2, 3],
  [85123, 95, 2, 2],
  [85122, 95, 2, 1],
  [85121, 95, 1, 6],
  [85120, 95, 1, 5],
  [85119, 95, 1, 4],
  [85118, 95, 1, 3],
  [85117, 95, 1, 2],
  [85116, 95, 1, 1],
  [85115, 94, 6, 6],
  [85114, 94, 6, 5],
  [85113, 94, 6, 4],
  [85112, 94, 6, 3],
  [85111, 94, 6, 2],
  [85110, 94, 6, 1],
  [85109, 94, 5, 6],
  [85108, 94, 5, 5],
  [85107, 94, 5, 4],
  [85105, 94, 5, 3],
  [85104, 94, 5, 2],
  [85103, 94, 5, 1],
  [85102, 94, 4, 6],
  [85101, 94, 4, 5],
  [85100, 94, 4, 4],
  [85099, 94, 4, 3],
  [85098, 94, 4, 2],
  [85097, 94, 4, 1],
  [85096, 94, 3, 6],
  [85095, 94, 3, 5],
  [85094, 94, 3, 4],
  [85093, 94, 3, 3],
  [85092, 94, 3, 2],
  [85091, 94, 3, 1],
  [85090, 94, 2, 6],
  [85089, 94, 2, 5],
  [85088, 94, 2, 4],
  [85087, 94, 2, 3],
  [85086, 94, 2, 2],
  [85085, 94, 2, 1],
  [85084, 94, 1, 6],
  [85083, 94, 1, 5],
  [85082, 94, 1, 4],
  [85081, 94, 1, 3],
  [85080, 94, 1, 2],
  [85079, 94, 1, 1],
  [85078, 93, 6, 6],
  [85077, 93, 6, 5],
  [85076, 93, 6, 4],
  [85075, 93, 6, 3],
  [85074, 93, 6, 2],
  [85073, 93, 6, 1],
  [85072, 93, 5, 6],
  [85071, 93, 5, 5],
  [85070, 93, 5, 4],
  [85069, 93, 5, 3],
  [85067, 93, 5, 2],
  [85066, 93, 5, 1],
  [85065, 93, 4, 6],
  [85064, 93, 4, 5],
  [85063, 93, 4, 4],
  [85062, 93, 4, 3],
  [85061, 93, 4, 2],
  [85060, 93, 4, 1],
  [85059, 93, 3, 6],
  [85057, 93, 3, 5],
  [85056, 93, 3, 4],
  [85055, 93, 3, 3],
  [85054, 93, 3, 2],
  [85053, 93, 3, 1],
  [85052, 93, 2, 6],
  [85051, 93, 2, 5],
  [85050, 93, 2, 4],
  [85049, 93, 2, 3],
  [85048, 93, 2, 2],
  [85047, 93, 2, 1],
  [85046, 93, 1, 6],
  [85045, 93, 1, 5],
  [85044, 93, 1, 4],
  [85043, 93, 1, 3],
  [85042, 93, 1, 2],
  [85041, 93, 1, 1],
  [85040, 92, 6, 6],
  [85039, 92, 6, 5],
  [85038, 92, 6, 4],
  [85037, 92, 6, 3],
  [85036, 92, 6, 2],
  [85035, 92, 6, 1],
  [85034, 92, 5, 6],
  [85033, 92, 5, 5],
  [85032, 92, 5, 4],
  [85031, 92, 5, 3],
  [85030, 92, 5, 2],
  [85029, 92, 5, 1],
  [85028, 92, 4, 6],
  [85027, 92, 4, 5],
  [85026, 92, 4, 4],
  [85025, 92, 4, 3],
  [85024, 92, 4, 2],
  [85023, 92, 4, 1],
  [85022, 92, 3, 6],
  [85021, 92, 3, 5],
  [85020, 92, 3, 4],
  [85019, 92, 3, 3],
  [85018, 92, 3, 2],
  [85017, 92, 3, 1],
  [85016, 92, 2, 6],
  [85015, 92, 2, 5],
  [85014, 92, 2, 4],
  [85013, 92, 2, 3],
  [85012, 92, 2, 2],
  [85011, 92, 2, 1],
  [85010, 92, 1, 6],
  [85009, 92, 1, 5],
  [85008, 92, 1, 4],
  [85007, 92, 1, 3],
  [85006, 92, 1, 2],
  [85004, 92, 1, 1],
  [85003, 91, 6, 6],
  [85002, 91, 6, 5],
  [85001, 91, 6, 4],
  [85000, 91, 6, 3],
  [84999, 91, 6, 2],
  [84998, 91, 6, 1],
  [84997, 91, 5, 6],
  [84996, 91, 5, 5],
  [84995, 91, 5, 4],
  [84994, 91, 5, 3],
  [84993, 91, 5, 2],
  [84992, 91, 5, 1],
  [84991, 91, 4, 6],
  [84990, 91, 4, 5],
  [84989, 91, 4, 4],
  [84988, 91, 4, 3],
  [84987, 91, 4, 2],
  [84986, 91, 4, 1],
  [84985, 91, 3, 6],
  [84984, 91, 3, 5],
  [84983, 91, 3, 4],
  [84982, 91, 3, 3],
  [84981, 91, 3, 2],
  [84980, 91, 3, 1],
  [84979, 91, 2, 6],
  [84978, 91, 2, 5],
  [84977, 91, 2, 4],
  [84976, 91, 2, 3],
  [84975, 91, 2, 2],
  [84974, 91, 2, 1],
  [84973, 91, 1, 6],
  [84972, 91, 1, 5],
  [84971, 91, 1, 4],
  [84970, 91, 1, 3],
  [84969, 91, 1, 2],
  [84968, 91, 1, 1],
  [84967, 90, 6, 6],
  [84966, 90, 6, 5],
  [84965, 90, 6, 4],
  [84964, 90, 6, 3],
  [84963, 90, 6, 2],
  [84962, 90, 6, 1],
  [84961, 90, 5, 6],
  [84960, 90, 5, 5],
  [84959, 90, 5, 4],
  [84958, 90, 5, 3],
  [84957, 90, 5, 2],
  [84956, 90, 5, 1],
  [84955, 90, 4, 6],
  [84954, 90, 4, 5],
  [84953, 90, 4, 4],
  [84952, 90, 4, 3],
  [84951, 90, 4, 2],
  [84950, 90, 4, 1],
  [84949, 90, 3, 6],
  [84948, 90, 3, 5],
  [84947, 90, 3, 4],
  [84946, 90, 3, 3],
  [84945, 90, 3, 2],
  [84944, 90, 3, 1],
  [84943, 90, 2, 6],
  [84942, 90, 2, 5],
  [84941, 90, 2, 4],
  [84940, 90, 2, 3],
  [84939, 90, 2, 2],
  [84938, 90, 2, 1],
  [84937, 90, 1, 6],
  [84936, 90, 1, 5],
  [84935, 90, 1, 4],
  [84934, 90, 1, 3],
  [84933, 90, 1, 2],
  [84932, 90, 1, 1],
  [84931, 89, 6, 6],
  [84930, 89, 6, 5],
  [84929, 89, 6, 4],
  [84928, 89, 6, 3],
  [84927, 89, 6, 2],
  [84926, 89, 6, 1],
  [84924, 89, 5, 6],
  [84923, 89, 5, 5],
  [84922, 89, 5, 4],
  [84921, 89, 5, 3],
  [84920, 89, 5, 2],
  [84919, 89, 5, 1],
  [84918, 89, 4, 6],
  [84917, 89, 4, 5],
  [84915, 89, 4, 4],
  [84913, 89, 4, 3],
  [84912, 89, 4, 2],
  [84911, 89, 4, 1],
  [84910, 89, 3, 6],
  [84909, 89, 3, 5],
  [84907, 89, 3, 4],
  [84906, 89, 3, 3],
  [84905, 89, 3, 2],
  [84904, 89, 3, 1],
  [84903, 89, 2, 6],
  [84902, 89, 2, 5],
  [84901, 89, 2, 4],
  [84900, 89, 2, 3],
  [84899, 89, 2, 2],
  [84898, 89, 2, 1],
  [84897, 89, 1, 6],
  [84896, 89, 1, 5],
  [84895, 89, 1, 4],
  [84894, 89, 1, 3],
  [84893, 89, 1, 2],
  [84892, 89, 1, 1],
  [84891, 88, 6, 6],
  [84890, 88, 6, 5],
  [84889, 88, 6, 4],
  [84888, 88, 6, 3],
  [84887, 88, 6, 2],
  [84886, 88, 6, 1],
  [84885, 88, 5, 6],
  [84884, 88, 5, 5],
  [84883, 88, 5, 4],
  [84882, 88, 5, 3],
  [84881, 88, 5, 2],
  [84880, 88, 5, 1],
  [84879, 88, 4, 6],
  [84878, 88, 4, 5],
  [84877, 88, 4, 4],
  [84875, 88, 4, 3],
  [84874, 88, 4, 2],
  [84873, 88, 4, 1],
  [84872, 88, 3, 6],
  [84871, 88, 3, 5],
  [84870, 88, 3, 4],
  [84869, 88, 3, 3],
  [84868, 88, 3, 2],
  [84867, 88, 3, 1],
  [84866, 88, 2, 6],
  [84865, 88, 2, 5],
  [84864, 88, 2, 4],
  [84863, 88, 2, 3],
  [84862, 88, 2, 2],
  [84861, 88, 2, 1],
  [84860, 88, 1, 6],
  [84859, 88, 1, 5],
  [84858, 88, 1, 4],
  [84857, 88, 1, 3],
  [84856, 88, 1, 2],
  [84855, 88, 1, 1],
  [84854, 87, 6, 6],
  [84853, 87, 6, 5],
  [84852, 87, 6, 4],
  [84851, 87, 6, 3],
  [84850, 87, 6, 2],
  [84849, 87, 6, 1],
  [84848, 87, 5, 6],
  [84847, 87, 5, 5],
  [84846, 87, 5, 4],
  [84845, 87, 5, 3],
  [84844, 87, 5, 2],
  [84843, 87, 5, 1],
  [84842, 87, 4, 6],
  [84841, 87, 4, 5],
  [84840, 87, 4, 4],
  [84839, 87, 4, 3],
  [84838, 87, 4, 2],
  [84837, 87, 4, 1],
  [84836, 87, 3, 6],
  [84835, 87, 3, 5],
  [84834, 87, 3, 4],
  [84833, 87, 3, 3],
  [84832, 87, 3, 2],
  [84831, 87, 3, 1],
  [84830, 87, 2, 6],
  [84829, 87, 2, 5],
  [84828, 87, 2, 4],
  [84827, 87, 2, 3],
  [84826, 87, 2, 2],
  [84825, 87, 2, 1],
  [84824, 87, 1, 6],
  [84823, 87, 1, 5],
  [84822, 87, 1, 4],
  [84821, 87, 1, 3],
  [84820, 87, 1, 2],
  [84819, 87, 1, 1],
  [84818, 86, 6, 6],
  [84817, 86, 6, 5],
  [84816, 86, 6, 4],
  [84815, 86, 6, 3],
  [84814, 86, 6, 2],
  [84813, 86, 6, 1],
  [84811, 86, 5, 6],
  [84810, 86, 5, 5],
  [84809, 86, 5, 4],
  [84808, 86, 5, 3],
  [84807, 86, 5, 2],
  [84806, 86, 5, 1],
  [84805, 86, 4, 6],
  [84804, 86, 4, 5],
  [84803, 86, 4, 4],
  [84802, 86, 4, 3],
  [84801, 86, 4, 2],
  [84799, 86, 4, 1],
  [84798, 86, 3, 6],
  [84797, 86, 3, 5],
  [84796, 86, 3, 4],
  [84795, 86, 3, 3],
  [84794, 86, 3, 2],
  [84793, 86, 3, 1],
  [84792, 86, 2, 6],
  [84791, 86, 2, 5],
  [84790, 86, 2, 4],
  [84789, 86, 2, 3],
  [84788, 86, 2, 2],
  [84787, 86, 2, 1],
  [84786, 86, 1, 6],
  [84785, 86, 1, 5],
  [84784, 86, 1, 4],
  [84783, 86, 1, 3],
  [84782, 86, 1, 2],
  [84781, 86, 1, 1],
  [84780, 85, 6, 6],
  [84779, 85, 6, 5],
  [84777, 85, 6, 4],
  [84776, 85, 6, 3],
  [84775, 85, 6, 2],
  [84773, 85, 6, 1],
  [84772, 85, 5, 6],
  [84771, 85, 5, 5],
  [84770, 85, 5, 4],
  [84769, 85, 5, 3],
  [84768, 85, 5, 2],
  [84767, 85, 5, 1],
  [84766, 85, 4, 6],
  [84765, 85, 4, 5],
  [84764, 85, 4, 4],
  [84763, 85, 4, 3],
  [84762, 85, 4, 2],
  [84761, 85, 4, 1],
  [84760, 85, 3, 6],
  [84759, 85, 3, 5],
  [84758, 85, 3, 4],
  [84757, 85, 3, 3],
  [84756, 85, 3, 2],
  [84755, 85, 3, 1],
  [84754, 85, 2, 6],
  [84753, 85, 2, 5],
  [84752, 85, 2, 4],
  [84751, 85, 2, 3],
  [84750, 85, 2, 2],
  [84749, 85, 2, 1],
  [84748, 85, 1, 6],
  [84747, 85, 1, 5],
  [84746, 85, 1, 4],
  [84745, 85, 1, 3],
  [84744, 85, 1, 2],
  [84743, 85, 1, 1],
  [84742, 84, 6, 6],
  [84741, 84, 6, 5],
  [84740, 84, 6, 4],
  [84739, 84, 6, 3],
  [84738, 84, 6, 2],
  [84736, 84, 6, 1],
  [84735, 84, 5, 6],
  [84734, 84, 5, 5],
  [84733, 84, 5, 4],
  [84732, 84, 5, 3],
  [84731, 84, 5, 2],
  [84730, 84, 5, 1],
  [84729, 84, 4, 6],
  [84728, 84, 4, 5],
  [84727, 84, 4, 4],
  [84726, 84, 4, 3],
  [84725, 84, 4, 2],
  [84723, 84, 4, 1],
  [84722, 84, 3, 6],
  [84721, 84, 3, 5],
  [84720, 84, 3, 4],
  [84719, 84, 3, 3],
  [84718, 84, 3, 2],
  [84717, 84, 3, 1],
  [84716, 84, 2, 6],
  [84715, 84, 2, 5],
  [84714, 84, 2, 4],
  [84713, 84, 2, 3],
  [84712, 84, 2, 2],
  [84710, 84, 2, 1],
  [84708, 84, 1, 6],
  [84707, 84, 1, 5],
  [84706, 84, 1, 4],
  [84705, 84, 1, 3],
  [84704, 84, 1, 2],
  [84703, 84, 1, 1],
  [84702, 83, 6, 6],
  [84701, 83, 6, 5],
  [84700, 83, 6, 4],
  [84699, 83, 6, 3],
  [84698, 83, 6, 2],
  [84697, 83, 6, 1],
  [84696, 83, 5, 6],
  [84695, 83, 5, 5],
  [84694, 83, 5, 4],
  [84693, 83, 5, 3],
  [84692, 83, 5, 2],
  [84691, 83, 5, 1],
  [84690, 83, 4, 6],
  [84689, 83, 4, 5],
  [84688, 83, 4, 4],
  [84687, 83, 4, 3],
  [84686, 83, 4, 2],
  [84685, 83, 4, 1],
  [84684, 83, 3, 6],
  [84683, 83, 3, 5],
  [84682, 83, 3, 4],
  [84681, 83, 3, 3],
  [84680, 83, 3, 2],
  [84679, 83, 3, 1],
  [84678, 83, 2, 6],
  [84677, 83, 2, 5],
  [84676, 83, 2, 4],
  [84675, 83, 2, 3],
  [84673, 83, 2, 2],
  [84672, 83, 2, 1],
  [84671, 83, 1, 6],
  [84670, 83, 1, 5],
  [84669, 83, 1, 4],
  [84668, 83, 1, 3],
  [84667, 83, 1, 2],
  [84666, 83, 1, 1],
  [84665, 82, 6, 6],
  [84664, 82, 6, 5],
  [84663, 82, 6, 4],
  [84662, 82, 6, 3],
  [84661, 82, 6, 2],
  [84660, 82, 6, 1],
  [84659, 82, 5, 6],
  [84658, 82, 5, 5],
  [84657, 82, 5, 4],
  [84656, 82, 5, 3],
  [84655, 82, 5, 2],
  [84654, 82, 5, 1],
  [84653, 82, 4, 6],
  [84652, 82, 4, 5],
  [84651, 82, 4, 4],
  [84650, 82, 4, 3],
  [84649, 82, 4, 2],
  [84647, 82, 4, 1],
  [84646, 82, 3, 6],
  [84645, 82, 3, 5],
  [84644, 82, 3, 4],
  [84643, 82, 3, 3],
  [84642, 82, 3, 2],
  [84641, 82, 3, 1],
  [84640, 82, 2, 6],
  [84639, 82, 2, 5],
  [84638, 82, 2, 4],
  [84637, 82, 2, 3],
  [84636, 82, 2, 2],
  [84635, 82, 2, 1],
  [84634, 82, 1, 6],
  [84633, 82, 1, 5],
  [84632, 82, 1, 4],
  [84631, 82, 1, 3],
  [84630, 82, 1, 2],
  [84629, 82, 1, 1],
  [84628, 81, 6, 6],
  [84627, 81, 6, 5],
  [84626, 81, 6, 4],
  [84625, 81, 6, 3],
  [84624, 81, 6, 2],
  [84623, 81, 6, 1],
  [84622, 81, 5, 6],
  [84621, 81, 5, 5],
  [84620, 81, 5, 4],
  [84619, 81, 5, 3],
  [84618, 81, 5, 2],
  [84617, 81, 5, 1],
  [84616, 81, 4, 6],
  [84615, 81, 4, 5],
  [84614, 81, 4, 4],
  [84613, 81, 4, 3],
  [84612, 81, 4, 2],
  [84611, 81, 4, 1],
  [84610, 81, 3, 6],
  [84609, 81, 3, 5],
  [84608, 81, 3, 4],
  [84607, 81, 3, 3],
  [84606, 81, 3, 2],
  [84605, 81, 3, 1],
  [84604, 81, 2, 6],
  [84603, 81, 2, 5],
  [84602, 81, 2, 4],
  [84601, 81, 2, 3],
  [84600, 81, 2, 2],
  [84599, 81, 2, 1],
  [84597, 81, 1, 6],
  [84596, 81, 1, 5],
  [84595, 81, 1, 4],
  [84594, 81, 1, 3],
  [84593, 81, 1, 2],
  [84592, 81, 1, 1],
  [84591, 80, 6, 6],
  [84590, 80, 6, 5],
  [84589, 80, 6, 4],
  [84588, 80, 6, 3],
  [84587, 80, 6, 2],
  [84586, 80, 6, 1],
  [84585, 80, 5, 6],
  [84584, 80, 5, 5],
  [84583, 80, 5, 4],
  [84582, 80, 5, 3],
  [84581, 80, 5, 2],
  [84580, 80, 5, 1],
  [84579, 80, 4, 6],
  [84578, 80, 4, 5],
  [84577, 80, 4, 4],
  [84576, 80, 4, 3],
  [84575, 80, 4, 2],
  [84574, 80, 4, 1],
  [84573, 80, 3, 6],
  [84572, 80, 3, 5],
  [84571, 80, 3, 4],
  [84570, 80, 3, 3],
  [84569, 80, 3, 2],
  [84568, 80, 3, 1],
  [84567, 80, 2, 6],
  [84566, 80, 2, 5],
  [84565, 80, 2, 4],
  [84564, 80, 2, 3],
  [84563, 80, 2, 2],
  [84562, 80, 2, 1],
  [84561, 80, 1, 6],
  [84560, 80, 1, 5],
  [84559, 80, 1, 4],
  [84558, 80, 1, 3],
  [84557, 80, 1, 2],
  [84556, 80, 1, 1],
  [84555, 79, 6, 6],
  [84554, 79, 6, 5],
  [84553, 79, 6, 4],
  [84552, 79, 6, 3],
  [84551, 79, 6, 2],
  [84550, 79, 6, 1],
  [84549, 79, 5, 6],
  [84548, 79, 5, 5],
  [84547, 79, 5, 4],
  [84546, 79, 5, 3],
  [84545, 79, 5, 2],
  [84544, 79, 5, 1],
  [84543, 79, 4, 6],
  [84541, 79, 4, 5],
  [84540, 79, 4, 4],
  [84539, 79, 4, 3],
  [84538, 79, 4, 2],
  [84537, 79, 4, 1],
  [84536, 79, 3, 6],
  [84535, 79, 3, 5],
  [84534, 79, 3, 4],
  [84533, 79, 3, 3],
  [84532, 79, 3, 2],
  [84531, 79, 3, 1],
  [84530, 79, 2, 6],
  [84528, 79, 2, 5],
  [84527, 79, 2, 4],
  [84526, 79, 2, 3],
  [84525, 79, 2, 2],
  [84524, 79, 2, 1],
  [84523, 79, 1, 6],
  [84522, 79, 1, 5],
  [84521, 79, 1, 4],
  [84520, 79, 1, 3],
  [84519, 79, 1, 2],
  [84517, 79, 1, 1],
  [84516, 78, 6, 6],
  [84515, 78, 6, 5],
  [84514, 78, 6, 4],
  [84513, 78, 6, 3],
  [84512, 78, 6, 2],
  [84511, 78, 6, 1],
  [84510, 78, 5, 6],
  [84509, 78, 5, 5],
  [84508, 78, 5, 4],
  [84507, 78, 5, 3],
  [84506, 78, 5, 2],
  [84505, 78, 5, 1],
  [84504, 78, 4, 6],
  [84503, 78, 4, 5],
  [84502, 78, 4, 4],
  [84501, 78, 4, 3],
  [84500, 78, 4, 2],
  [84499, 78, 4, 1],
  [84498, 78, 3, 6],
  [84497, 78, 3, 5],
  [84496, 78, 3, 4],
  [84495, 78, 3, 3],
  [84494, 78, 3, 2],
  [84493, 78, 3, 1],
  [84492, 78, 2, 6],
  [84491, 78, 2, 5],
  [84490, 78, 2, 4],
  [84489, 78, 2, 3],
  [84488, 78, 2, 2],
  [84487, 78, 2, 1],
  [84486, 78, 1, 6],
  [84485, 78, 1, 5],
  [84484, 78, 1, 4],
  [84483, 78, 1, 3],
  [84482, 78, 1, 2],
  [84481, 78, 1, 1],
  [84480, 77, 6, 6],
  [84479, 77, 6, 5],
  [84478, 77, 6, 4],
  [84477, 77, 6, 3],
  [84476, 77, 6, 2],
  [84475, 77, 6, 1],
  [84474, 77, 5, 6],
  [84473, 77, 5, 5],
  [84472, 77, 5, 4],
  [84471, 77, 5, 3],
  [84470, 77, 5, 2],
  [84469, 77, 5, 1],
  [84468, 77, 4, 6],
  [84467, 77, 4, 5],
  [84466, 77, 4, 4],
  [84465, 77, 4, 3],
  [84464, 77, 4, 2],
  [84463, 77, 4, 1],
  [84462, 77, 3, 6],
  [84461, 77, 3, 5],
  [84460, 77, 3, 4],
  [84459, 77, 3, 3],
  [84458, 77, 3, 2],
  [84457, 77, 3, 1],
  [84456, 77, 2, 6],
  [84455, 77, 2, 5],
  [84454, 77, 2, 4],
  [84453, 77, 2, 3],
  [84452, 77, 2, 2],
  [84451, 77, 2, 1],
  [84450, 77, 1, 6],
  [84449, 77, 1, 5],
  [84448, 77, 1, 4],
  [84447, 77, 1, 3],
  [84446, 77, 1, 2],
  [84445, 77, 1, 1],
  [84444, 76, 6, 6],
  [84443, 76, 6, 5],
  [84442, 76, 6, 4],
  [84441, 76, 6, 3],
  [84440, 76, 6, 2],
  [84439, 76, 6, 1],
  [84438, 76, 5, 6],
  [84437, 76, 5, 5],
  [84436, 76, 5, 4],
  [84435, 76, 5, 3],
  [84434, 76, 5, 2],
  [84433, 76, 5, 1],
  [84432, 76, 4, 6],
  [84431, 76, 4, 5],
  [84429, 76, 4, 4],
  [84428, 76, 4, 3],
  [84427, 76, 4, 2],
  [84426, 76, 4, 1],
  [84425, 76, 3, 6],
  [84424, 76, 3, 5],
  [84423, 76, 3, 4],
  [84422, 76, 3, 3],
  [84421, 76, 3, 2],
  [84420, 76, 3, 1],
  [84419, 76, 2, 6],
  [84418, 76, 2, 5],
  [84417, 76, 2, 4],
  [84416, 76, 2, 3],
  [84415, 76, 2, 2],
  [84414, 76, 2, 1],
  [84413, 76, 1, 6],
  [84412, 76, 1, 5],
  [84411, 76, 1, 4],
  [84410, 76, 1, 3],
  [84409, 76, 1, 2],
  [84408, 76, 1, 1],
  [84407, 75, 6, 6],
  [84406, 75, 6, 5],
  [84405, 75, 6, 4],
  [84404, 75, 6, 3],
  [84403, 75, 6, 2],
  [84402, 75, 6, 1],
  [84401, 75, 5, 6],
  [84400, 75, 5, 5],
  [84399, 75, 5, 4],
  [84398, 75, 5, 3],
  [84397, 75, 5, 2],
  [84396, 75, 5, 1],
  [84395, 75, 4, 6],
  [84394, 75, 4, 5],
  [84393, 75, 4, 4],
  [84392, 75, 4, 3],
  [84390, 75, 4, 2],
  [84389, 75, 4, 1],
  [84388, 75, 3, 6],
  [84387, 75, 3, 5],
  [84386, 75, 3, 4],
  [84385, 75, 3, 3],
  [84384, 75, 3, 2],
  [84383, 75, 3, 1],
  [84382, 75, 2, 6],
  [84381, 75, 2, 5],
  [84380, 75, 2, 4],
  [84379, 75, 2, 3],
  [84378, 75, 2, 2],
  [84377, 75, 2, 1],
  [84376, 75, 1, 6],
  [84375, 75, 1, 5],
  [84374, 75, 1, 4],
  [84373, 75, 1, 3],
  [84372, 75, 1, 2],
  [84371, 75, 1, 1],
  [84370, 74, 6, 6],
  [84369, 74, 6, 5],
  [84368, 74, 6, 4],
  [84367, 74, 6, 3],
  [84366, 74, 6, 2],
  [84365, 74, 6, 1],
  [84364, 74, 5, 6],
  [84363, 74, 5, 5],
  [84362, 74, 5, 4],
  [84361, 74, 5, 3],
  [84360, 74, 5, 2],
  [84359, 74, 5, 1],
  [84358, 74, 4, 6],
  [84357, 74, 4, 5],
  [84356, 74, 4, 4],
  [84355, 74, 4, 3],
  [84354, 74, 4, 2],
  [84353, 74, 4, 1],
  [84352, 74, 3, 6],
  [84351, 74, 3, 5],
  [84350, 74, 3, 4],
  [84349, 74, 3, 3],
  [84348, 74, 3, 2],
  [84347, 74, 3, 1],
  [84346, 74, 2, 6],
  [84345, 74, 2, 5],
  [84344, 74, 2, 4],
  [84343, 74, 2, 3],
  [84342, 74, 2, 2],
  [84341, 74, 2, 1],
  [84340, 74, 1, 6],
  [84339, 74, 1, 5],
  [84338, 74, 1, 4],
  [84337, 74, 1, 3],
  [84336, 74, 1, 2],
  [84335, 74, 1, 1],
  [84334, 73, 6, 6],
  [84333, 73, 6, 5],
  [84332, 73, 6, 4],
  [84331, 73, 6, 3],
  [84330, 73, 6, 2],
  [84329, 73, 6, 1],
  [84328, 73, 5, 6],
  [84327, 73, 5, 5],
  [84326, 73, 5, 4],
  [84325, 73, 5, 3],
  [84324, 73, 5, 2],
  [84323, 73, 5, 1],
  [84322, 73, 4, 6],
  [84321, 73, 4, 5],
  [84320, 73, 4, 4],
  [84319, 73, 4, 3],
  [84318, 73, 4, 2],
  [84317, 73, 4, 1],
  [84316, 73, 3, 6],
  [84315, 73, 3, 5],
  [84314, 73, 3, 4],
  [84313, 73, 3, 3],
  [84312, 73, 3, 2],
  [84311, 73, 3, 1],
  [84310, 73, 2, 6],
  [84309, 73, 2, 5],
  [84308, 73, 2, 4],
  [84307, 73, 2, 3],
  [84306, 73, 2, 2],
  [84305, 73, 2, 1],
  [84304, 73, 1, 6],
  [84303, 73, 1, 5],
  [84302, 73, 1, 4],
  [84301, 73, 1, 3],
  [84300, 73, 1, 2],
  [84299, 73, 1, 1],
  [84298, 72, 6, 6],
  [84297, 72, 6, 5],
  [84296, 72, 6, 4],
  [84295, 72, 6, 3],
  [84294, 72, 6, 2],
  [84293, 72, 6, 1],
  [84292, 72, 5, 6],
  [84291, 72, 5, 5],
  [84290, 72, 5, 4],
  [84289, 72, 5, 3],
  [84288, 72, 5, 2],
  [84287, 72, 5, 1],
  [84286, 72, 4, 6],
  [84285, 72, 4, 5],
  [84284, 72, 4, 4],
  [84283, 72, 4, 3],
  [84282, 72, 4, 2],
  [84281, 72, 4, 1],
  [84280, 72, 3, 6],
  [84279, 72, 3, 5],
  [84278, 72, 3, 4],
  [84277, 72, 3, 3],
  [84275, 72, 3, 2],
  [84274, 72, 3, 1],
  [84273, 72, 2, 6],
  [84272, 72, 2, 5],
  [84271, 72, 2, 4],
  [84270, 72, 2, 3],
  [84269, 72, 2, 2],
  [84268, 72, 2, 1],
  [84267, 72, 1, 6],
  [84266, 72, 1, 5],
  [84265, 72, 1, 4],
  [84264, 72, 1, 3],
  [84263, 72, 1, 2],
  [84262, 72, 1, 1],
  [84261, 71, 6, 6],
  [84260, 71, 6, 5],
  [84259, 71, 6, 4],
  [84258, 71, 6, 3],
  [84257, 71, 6, 2],
  [84256, 71, 6, 1],
  [84255, 71, 5, 6],
  [84254, 71, 5, 5],
  [84253, 71, 5, 4],
  [84252, 71, 5, 3],
  [84251, 71, 5, 2],
  [84250, 71, 5, 1],
  [84249, 71, 4, 6],
  [84248, 71, 4, 5],
  [84247, 71, 4, 4],
  [84246, 71, 4, 3],
  [84245, 71, 4, 2],
  [84244, 71, 4, 1],
  [84243, 71, 3, 6],
  [84242, 71, 3, 5],
  [84241, 71, 3, 4],
  [84240, 71, 3, 3],
  [84239, 71, 3, 2],
  [84238, 71, 3, 1],
  [84237, 71, 2, 6],
  [84236, 71, 2, 5],
  [84235, 71, 2, 4],
  [84234, 71, 2, 3],
  [84233, 71, 2, 2],
  [84232, 71, 2, 1],
  [84231, 71, 1, 6],
  [84230, 71, 1, 5],
  [84229, 71, 1, 4],
  [84228, 71, 1, 3],
  [84226, 71, 1, 2],
  [84225, 71, 1, 1],
  [84224, 70, 6, 6],
  [84223, 70, 6, 5],
  [84222, 70, 6, 4],
  [84221, 70, 6, 3],
  [84220, 70, 6, 2],
  [84219, 70, 6, 1],
  [84218, 70, 5, 6],
  [84217, 70, 5, 5],
  [84216, 70, 5, 4],
  [84215, 70, 5, 3],
  [84214, 70, 5, 2],
  [84213, 70, 5, 1],
  [84212, 70, 4, 6],
  [84211, 70, 4, 5],
  [84210, 70, 4, 4],
  [84209, 70, 4, 3],
  [84208, 70, 4, 2],
  [84207, 70, 4, 1],
  [84205, 70, 3, 6],
  [84204, 70, 3, 5],
  [84203, 70, 3, 4],
  [84202, 70, 3, 3],
  [84201, 70, 3, 2],
  [84200, 70, 3, 1],
  [84199, 70, 2, 6],
  [84198, 70, 2, 5],
  [84197, 70, 2, 4],
  [84196, 70, 2, 3],
  [84195, 70, 2, 2],
  [84194, 70, 2, 1],
  [84193, 70, 1, 6],
  [84192, 70, 1, 5],
  [84191, 70, 1, 4],
  [84190, 70, 1, 3],
  [84189, 70, 1, 2],
  [84188, 70, 1, 1],
  [84186, 69, 6, 6],
  [84185, 69, 6, 5],
  [84184, 69, 6, 4],
  [84183, 69, 6, 3],
  [84182, 69, 6, 2],
  [84181, 69, 6, 1],
  [84180, 69, 5, 6],
  [84179, 69, 5, 5],
  [84178, 69, 5, 4],
  [84177, 69, 5, 3],
  [84176, 69, 5, 2],
  [84175, 69, 5, 1],
  [84174, 69, 4, 6],
  [84173, 69, 4, 5],
  [84172, 69, 4, 4],
  [84171, 69, 4, 3],
  [84170, 69, 4, 2],
  [84169, 69, 4, 1],
  [84168, 69, 3, 6],
  [84167, 69, 3, 5],
  [84166, 69, 3, 4],
  [84165, 69, 3, 3],
  [84164, 69, 3, 2],
  [84163, 69, 3, 1],
  [84162, 69, 2, 6],
  [84161, 69, 2, 5],
  [84160, 69, 2, 4],
  [84159, 69, 2, 3],
  [84158, 69, 2, 2],
  [84157, 69, 2, 1],
  [84156, 69, 1, 6],
  [84155, 69, 1, 5],
  [84154, 69, 1, 4],
  [84153, 69, 1, 3],
  [84152, 69, 1, 2],
  [84151, 69, 1, 1],
  [84150, 68, 6, 6],
  [84149, 68, 6, 5],
  [84148, 68, 6, 4],
  [84147, 68, 6, 3],
  [84146, 68, 6, 2],
  [84145, 68, 6, 1],
  [84144, 68, 5, 6],
  [84143, 68, 5, 5],
  [84142, 68, 5, 4],
  [84141, 68, 5, 3],
  [84140, 68, 5, 2],
  [84139, 68, 5, 1],
  [84138, 68, 4, 6],
  [84137, 68, 4, 5],
  [84136, 68, 4, 4],
  [84135, 68, 4, 3],
  [84134, 68, 4, 2],
  [84133, 68, 4, 1],
  [84132, 68, 3, 6],
  [84131, 68, 3, 5],
  [84130, 68, 3, 4],
  [84129, 68, 3, 3],
  [84128, 68, 3, 2],
  [84127, 68, 3, 1],
  [84126, 68, 2, 6],
  [84125, 68, 2, 5],
  [84124, 68, 2, 4],
  [84123, 68, 2, 3],
  [84122, 68, 2, 2],
  [84121, 68, 2, 1],
  [84120, 68, 1, 6],
  [84119, 68, 1, 5],
  [84118, 68, 1, 4],
  [84117, 68, 1, 3],
  [84116, 68, 1, 2],
  [84115, 68, 1, 1],
  [84114, 67, 6, 6],
  [84113, 67, 6, 5],
  [84112, 67, 6, 4],
  [84111, 67, 6, 3],
  [84110, 67, 6, 2],
  [84109, 67, 6, 1],
  [84108, 67, 5, 6],
  [84106, 67, 5, 5],
  [84105, 67, 5, 4],
  [84104, 67, 5, 3],
  [84103, 67, 5, 2],
  [84102, 67, 5, 1],
  [84101, 67, 4, 6],
  [84100, 67, 4, 5],
  [84099, 67, 4, 4],
  [84098, 67, 4, 3],
  [84097, 67, 4, 2],
  [84096, 67, 4, 1],
  [84095, 67, 3, 6],
  [84094, 67, 3, 5],
  [84093, 67, 3, 4],
  [84092, 67, 3, 3],
  [84091, 67, 3, 2],
  [84090, 67, 3, 1],
  [84089, 67, 2, 6],
  [84088, 67, 2, 5],
  [84087, 67, 2, 4],
  [84086, 67, 2, 3],
  [84085, 67, 2, 2],
  [84084, 67, 2, 1],
  [84083, 67, 1, 6],
  [84082, 67, 1, 5],
  [84081, 67, 1, 4],
  [84080, 67, 1, 3],
  [84079, 67, 1, 2],
  [84078, 67, 1, 1],
  [84077, 66, 6, 6],
  [84076, 66, 6, 5],
  [84075, 66, 6, 4],
  [84074, 66, 6, 3],
  [84073, 66, 6, 2],
  [84072, 66, 6, 1],
  [84071, 66, 5, 6],
  [84070, 66, 5, 5],
  [84069, 66, 5, 4],
  [84068, 66, 5, 3],
  [84067, 66, 5, 2],
  [84066, 66, 5, 1],
  [84065, 66, 4, 6],
  [84064, 66, 4, 5],
  [84063, 66, 4, 4],
  [84062, 66, 4, 3],
  [84061, 66, 4, 2],
  [84060, 66, 4, 1],
  [84059, 66, 3, 6],
  [84058, 66, 3, 5],
  [84056, 66, 3, 4],
  [84055, 66, 3, 3],
  [84054, 66, 3, 2],
  [84053, 66, 3, 1],
  [84052, 66, 2, 6],
  [84051, 66, 2, 5],
  [84050, 66, 2, 4],
  [84049, 66, 2, 3],
  [84048, 66, 2, 2],
  [84047, 66, 2, 1],
  [84046, 66, 1, 6],
  [84045, 66, 1, 5],
  [84044, 66, 1, 4],
  [84043, 66, 1, 3],
  [84042, 66, 1, 2],
  [84041, 66, 1, 1],
  [84040, 65, 6, 6],
  [84039, 65, 6, 5],
  [84038, 65, 6, 4],
  [84037, 65, 6, 3],
  [84036, 65, 6, 2],
  [84035, 65, 6, 1],
  [84034, 65, 5, 6],
  [84033, 65, 5, 5],
  [84032, 65, 5, 4],
  [84031, 65, 5, 3],
  [84030, 65, 5, 2],
  [84029, 65, 5, 1],
  [84028, 65, 4, 6],
  [84027, 65, 4, 5],
  [84026, 65, 4, 4],
  [84025, 65, 4, 3],
  [84024, 65, 4, 2],
  [84023, 65, 4, 1],
  [84022, 65, 3, 6],
  [84021, 65, 3, 5],
  [84020, 65, 3, 4],
  [84019, 65, 3, 3],
  [84018, 65, 3, 2],
  [84017, 65, 3, 1],
  [84016, 65, 2, 6],
  [84015, 65, 2, 5],
  [84014, 65, 2, 4],
  [84013, 65, 2, 3],
  [84012, 65, 2, 2],
  [84011, 65, 2, 1],
  [84010, 65, 1, 6],
  [84009, 65, 1, 5],
  [84008, 65, 1, 4],
  [84007, 65, 1, 3],
  [84006, 65, 1, 2],
  [84005, 65, 1, 1],
  [84004, 64, 6, 6],
  [84003, 64, 6, 5],
  [84002, 64, 6, 4],
  [84001, 64, 6, 3],
  [84000, 64, 6, 2],
  [83999, 64, 6, 1],
  [83998, 64, 5, 6],
  [83997, 64, 5, 5],
  [83996, 64, 5, 4],
  [83995, 64, 5, 3],
  [83994, 64, 5, 2],
  [83993, 64, 5, 1],
  [83992, 64, 4, 6],
  [83991, 64, 4, 5],
  [83990, 64, 4, 4],
  [83989, 64, 4, 3],
  [83988, 64, 4, 2],
  [83987, 64, 4, 1],
  [83986, 64, 3, 6],
  [83985, 64, 3, 5],
  [83984, 64, 3, 4],
  [83983, 64, 3, 3],
  [83982, 64, 3, 2],
  [83981, 64, 3, 1],
  [83980, 64, 2, 6],
  [83979, 64, 2, 5],
  [83978, 64, 2, 4],
  [83977, 64, 2, 3],
  [83976, 64, 2, 2],
  [83975, 64, 2, 1],
  [83974, 64, 1, 6],
  [83973, 64, 1, 5],
  [83972, 64, 1, 4],
  [83971, 64, 1, 3],
  [83970, 64, 1, 2],
  [83969, 64, 1, 1],
  [83968, 63, 6, 6],
  [83967, 63, 6, 5],
  [83966, 63, 6, 4],
  [83965, 63, 6, 3],
  [83964, 63, 6, 2],
  [83963, 63, 6, 1],
  [83962, 63, 5, 6],
  [83961, 63, 5, 5],
  [83960, 63, 5, 4],
  [83959, 63, 5, 3],
  [83958, 63, 5, 2],
  [83957, 63, 5, 1],
  [83956, 63, 4, 6],
  [83955, 63, 4, 5],
  [83954, 63, 4, 4],
  [83953, 63, 4, 3],
  [83952, 63, 4, 2],
  [83951, 63, 4, 1],
  [83950, 63, 3, 6],
  [83949, 63, 3, 5],
  [83948, 63, 3, 4],
  [83947, 63, 3, 3],
  [83946, 63, 3, 2],
  [83945, 63, 3, 1],
  [83944, 63, 2, 6],
  [83943, 63, 2, 5],
  [83942, 63, 2, 4],
  [83941, 63, 2, 3],
  [83940, 63, 2, 2],
  [83939, 63, 2, 1],
  [83938, 63, 1, 6],
  [83937, 63, 1, 5],
  [83936, 63, 1, 4],
  [83935, 63, 1, 3],
  [83934, 63, 1, 2],
  [83933, 63, 1, 1],
  [83932, 62, 6, 6],
  [83931, 62, 6, 5],
  [83930, 62, 6, 4],
  [83929, 62, 6, 3],
  [83928, 62, 6, 2],
  [83927, 62, 6, 1],
  [83926, 62, 5, 6],
  [83925, 62, 5, 5],
  [83923, 62, 5, 4],
  [83922, 62, 5, 3],
  [83921, 62, 5, 2],
  [83920, 62, 5, 1],
  [83919, 62, 4, 6],
  [83918, 62, 4, 5],
  [83917, 62, 4, 4],
  [83916, 62, 4, 3],
  [83915, 62, 4, 2],
  [83914, 62, 4, 1],
  [83913, 62, 3, 6],
  [83912, 62, 3, 5],
  [83911, 62, 3, 4],
  [83910, 62, 3, 3],
  [83909, 62, 3, 2],
  [83908, 62, 3, 1],
  [83907, 62, 2, 6],
  [83906, 62, 2, 5],
  [83905, 62, 2, 4],
  [83904, 62, 2, 3],
  [83903, 62, 2, 2],
  [83902, 62, 2, 1],
  [83901, 62, 1, 6],
  [83900, 62, 1, 5],
  [83899, 62, 1, 4],
  [83898, 62, 1, 3],
  [83897, 62, 1, 2],
  [83896, 62, 1, 1],
  [83895, 61, 6, 6],
  [83894, 61, 6, 5],
  [83893, 61, 6, 4],
  [83892, 61, 6, 3],
  [83891, 61, 6, 2],
  [83890, 61, 6, 1],
  [83889, 61, 5, 6],
  [83888, 61, 5, 5],
  [83887, 61, 5, 4],
  [83886, 61, 5, 3],
  [83885, 61, 5, 2],
  [83884, 61, 5, 1],
  [83883, 61, 4, 6],
  [83882, 61, 4, 5],
  [83881, 61, 4, 4],
  [83880, 61, 4, 3],
  [83879, 61, 4, 2],
  [83878, 61, 4, 1],
  [83877, 61, 3, 6],
  [83876, 61, 3, 5],
  [83875, 61, 3, 4],
  [83874, 61, 3, 3],
  [83873, 61, 3, 2],
  [83872, 61, 3, 1],
  [83871, 61, 2, 6],
  [83870, 61, 2, 5],
  [83869, 61, 2, 4],
  [83868, 61, 2, 3],
  [83867, 61, 2, 2],
  [83866, 61, 2, 1],
  [83865, 61, 1, 6],
  [83864, 61, 1, 5],
  [83863, 61, 1, 4],
  [83862, 61, 1, 3],
  [83861, 61, 1, 2],
  [83860, 61, 1, 1],
  [83859, 60, 6, 6],
  [83858, 60, 6, 5],
  [83857, 60, 6, 4],
  [83856, 60, 6, 3],
  [83855, 60, 6, 2],
  [83854, 60, 6, 1],
  [83853, 60, 5, 6],
  [83852, 60, 5, 5],
  [83851, 60, 5, 4],
  [83850, 60, 5, 3],
  [83849, 60, 5, 2],
  [83848, 60, 5, 1],
  [83847, 60, 4, 6],
  [83846, 60, 4, 5],
  [83845, 60, 4, 4],
  [83844, 60, 4, 3],
  [83843, 60, 4, 2],
  [83842, 60, 4, 1],
  [83841, 60, 3, 6],
  [83840, 60, 3, 5],
  [83839, 60, 3, 4],
  [83838, 60, 3, 3],
  [83837, 60, 3, 2],
  [83836, 60, 3, 1],
  [83835, 60, 2, 6],
  [83834, 60, 2, 5],
  [83833, 60, 2, 4],
  [83832, 60, 2, 3],
  [83831, 60, 2, 2],
  [83830, 60, 2, 1],
  [83829, 60, 1, 6],
  [83828, 60, 1, 5],
  [83827, 60, 1, 4],
  [83826, 60, 1, 3],
  [83825, 60, 1, 2],
  [83824, 60, 1, 1],
  [62228, 59, 6, 6],
  [62227, 59, 6, 5],
  [62226, 59, 6, 4],
  [62225, 59, 6, 3],
  [62224, 59, 6, 2],
  [62223, 59, 6, 1],
  [62222, 59, 5, 6],
  [62221, 59, 5, 5],
  [62220, 59, 5, 4],
  [62219, 59, 5, 3],
  [62218, 59, 5, 2],
  [62217, 59, 5, 1],
  [62216, 59, 4, 6],
  [62215, 59, 4, 5],
  [62214, 59, 4, 4],
  [62213, 59, 4, 3],
  [62212, 59, 4, 2],
  [62211, 59, 4, 1],
  [62210, 59, 3, 6],
  [62209, 59, 3, 5],
  [62208, 59, 3, 4],
  [62207, 59, 3, 3],
  [62206, 59, 3, 2],
  [62205, 59, 3, 1],
  [62204, 59, 2, 6],
  [62203, 59, 2, 5],
  [62202, 59, 2, 4],
  [62201, 59, 2, 3],
  [62200, 59, 2, 2],
  [62199, 59, 2, 1],
  [62198, 59, 1, 6],
  [62197, 59, 1, 5],
  [62196, 59, 1, 4],
  [62195, 59, 1, 3],
  [62194, 59, 1, 2],
  [62193, 59, 1, 1],
  [62192, 58, 6, 6],
  [62191, 58, 6, 5],
  [62190, 58, 6, 4],
  [62189, 58, 6, 3],
  [62188, 58, 6, 2],
  [62187, 58, 6, 1],
  [62186, 58, 5, 6],
  [62185, 58, 5, 5],
  [62184, 58, 5, 4],
  [62183, 58, 5, 3],
  [62182, 58, 5, 2],
  [62181, 58, 5, 1],
  [62180, 58, 4, 6],
  [62179, 58, 4, 5],
  [62178, 58, 4, 4],
  [62177, 58, 4, 3],
  [62176, 58, 4, 2],
  [62175, 58, 4, 1],
  [62174, 58, 3, 6],
  [62173, 58, 3, 5],
  [62172, 58, 3, 4],
  [62171, 58, 3, 3],
  [62169, 58, 3, 2],
  [62168, 58, 3, 1],
  [62167, 58, 2, 6],
  [62166, 58, 2, 5],
  [62165, 58, 2, 4],
  [62164, 58, 2, 3],
  [62163, 58, 2, 2],
  [62162, 58, 2, 1],
  [62161, 58, 1, 6],
  [62160, 58, 1, 5],
  [62159, 58, 1, 4],
  [62158, 58, 1, 3],
  [62157, 58, 1, 2],
  [62156, 58, 1, 1],
  [62155, 57, 6, 6],
  [62154, 57, 6, 5],
  [62153, 57, 6, 4],
  [62152, 57, 6, 3],
  [62151, 57, 6, 2],
  [62150, 57, 6, 1],
  [62149, 57, 5, 6],
  [62148, 57, 5, 5],
  [62147, 57, 5, 4],
  [62146, 57, 5, 3],
  [62144, 57, 5, 2],
  [62143, 57, 5, 1],
  [62142, 57, 4, 6],
  [62141, 57, 4, 5],
  [62140, 57, 4, 4],
  [62139, 57, 4, 3],
  [62138, 57, 4, 2],
  [62137, 57, 4, 1],
  [62136, 57, 3, 6],
  [62135, 57, 3, 5],
  [62133, 57, 3, 4],
  [62132, 57, 3, 3],
  [62131, 57, 3, 2],
  [62130, 57, 3, 1],
  [62129, 57, 2, 6],
  [62128, 57, 2, 5],
  [62127, 57, 2, 4],
  [62126, 57, 2, 3],
  [62125, 57, 2, 2],
  [62124, 57, 2, 1],
  [62123, 57, 1, 6],
  [62122, 57, 1, 5],
  [62121, 57, 1, 4],
  [62120, 57, 1, 3],
  [62119, 57, 1, 2],
  [62118, 57, 1, 1],
  [62117, 56, 6, 6],
  [62116, 56, 6, 5],
  [62115, 56, 6, 4],
  [62114, 56, 6, 3],
  [62113, 56, 6, 2],
  [62112, 56, 6, 1],
  [62111, 56, 5, 6],
  [62110, 56, 5, 5],
  [62109, 56, 5, 4],
  [62108, 56, 5, 3],
  [62107, 56, 5, 2],
  [62106, 56, 5, 1],
  [62105, 56, 4, 6],
  [62104, 56, 4, 5],
  [62103, 56, 4, 4],
  [62102, 56, 4, 3],
  [62101, 56, 4, 2],
  [62100, 56, 4, 1],
  [62099, 56, 3, 6],
  [62098, 56, 3, 5],
  [62097, 56, 3, 4],
  [62096, 56, 3, 3],
  [62095, 56, 3, 2],
  [62094, 56, 3, 1],
  [62093, 56, 2, 6],
  [62092, 56, 2, 5],
  [62091, 56, 2, 4],
  [62090, 56, 2, 3],
  [62089, 56, 2, 2],
  [62088, 56, 2, 1],
  [62087, 56, 1, 6],
  [62086, 56, 1, 5],
  [62085, 56, 1, 4],
  [62084, 56, 1, 3],
  [62083, 56, 1, 2],
  [62082, 56, 1, 1],
  [62081, 55, 6, 6],
  [62080, 55, 6, 5],
  [62079, 55, 6, 4],
  [62078, 55, 6, 3],
  [62077, 55, 6, 2],
  [62076, 55, 6, 1],
  [62075, 55, 5, 6],
  [62074, 55, 5, 5],
  [62073, 55, 5, 4],
  [62072, 55, 5, 3],
  [62071, 55, 5, 2],
  [62070, 55, 5, 1],
  [62069, 55, 4, 6],
  [62068, 55, 4, 5],
  [62067, 55, 4, 4],
  [62066, 55, 4, 3],
  [62065, 55, 4, 2],
  [62064, 55, 4, 1],
  [62063, 55, 3, 6],
  [62062, 55, 3, 5],
  [62061, 55, 3, 4],
  [62060, 55, 3, 3],
  [62059, 55, 3, 2],
  [62058, 55, 3, 1],
  [62057, 55, 2, 6],
  [62056, 55, 2, 5],
  [62055, 55, 2, 4],
  [62054, 55, 2, 3],
  [62053, 55, 2, 2],
  [62052, 55, 2, 1],
  [62051, 55, 1, 6],
  [62050, 55, 1, 5],
  [62049, 55, 1, 4],
  [62048, 55, 1, 3],
  [62047, 55, 1, 2],
  [62046, 55, 1, 1],
  [62045, 54, 6, 6],
  [62044, 54, 6, 5],
  [62043, 54, 6, 4],
  [62042, 54, 6, 3],
  [62041, 54, 6, 2],
  [62040, 54, 6, 1],
  [62039, 54, 5, 6],
  [62038, 54, 5, 5],
  [62037, 54, 5, 4],
  [62036, 54, 5, 3],
  [62035, 54, 5, 2],
  [62034, 54, 5, 1],
  [62033, 54, 4, 6],
  [62032, 54, 4, 5],
  [62031, 54, 4, 4],
  [62030, 54, 4, 3],
  [62029, 54, 4, 2],
  [62028, 54, 4, 1],
  [62027, 54, 3, 6],
  [62026, 54, 3, 5],
  [62025, 54, 3, 4],
  [62024, 54, 3, 3],
  [62023, 54, 3, 2],
  [62022, 54, 3, 1],
  [62021, 54, 2, 6],
  [62020, 54, 2, 5],
  [62019, 54, 2, 4],
  [62018, 54, 2, 3],
  [62017, 54, 2, 2],
  [62016, 54, 2, 1],
  [62015, 54, 1, 6],
  [62014, 54, 1, 5],
  [62013, 54, 1, 4],
  [62012, 54, 1, 3],
  [62011, 54, 1, 2],
  [62010, 54, 1, 1],
  [62009, 53, 6, 6],
  [62008, 53, 6, 5],
  [62007, 53, 6, 4],
  [62006, 53, 6, 3],
  [62005, 53, 6, 2],
  [62004, 53, 6, 1],
  [62003, 53, 5, 6],
  [62002, 53, 5, 5],
  [62001, 53, 5, 4],
  [62000, 53, 5, 3],
  [61999, 53, 5, 2],
  [61998, 53, 5, 1],
  [61997, 53, 4, 6],
  [61996, 53, 4, 5],
  [61995, 53, 4, 4],
  [61994, 53, 4, 3],
  [61993, 53, 4, 2],
  [61991, 53, 4, 1],
  [61990, 53, 3, 6],
  [61989, 53, 3, 5],
  [61988, 53, 3, 4],
  [61987, 53, 3, 3],
  [61986, 53, 3, 2],
  [61985, 53, 3, 1],
  [61984, 53, 2, 6],
  [61983, 53, 2, 5],
  [61982, 53, 2, 4],
  [61981, 53, 2, 3],
  [61980, 53, 2, 2],
  [61979, 53, 2, 1],
  [61978, 53, 1, 6],
  [61977, 53, 1, 5],
  [61976, 53, 1, 4],
  [61975, 53, 1, 3],
  [61973, 53, 1, 2],
  [61972, 53, 1, 1],
  [61971, 52, 6, 6],
  [61970, 52, 6, 5],
  [61969, 52, 6, 4],
  [61968, 52, 6, 3],
  [61967, 52, 6, 2],
  [61966, 52, 6, 1],
  [61965, 52, 5, 6],
  [61964, 52, 5, 5],
  [61963, 52, 5, 4],
  [61962, 52, 5, 3],
  [61961, 52, 5, 2],
  [61960, 52, 5, 1],
  [61959, 52, 4, 6],
  [61958, 52, 4, 5],
  [61957, 52, 4, 4],
  [61956, 52, 4, 3],
  [61954, 52, 4, 2],
  [61953, 52, 4, 1],
  [61952, 52, 3, 6],
  [61951, 52, 3, 5],
  [61950, 52, 3, 4],
  [61949, 52, 3, 3],
  [61948, 52, 3, 2],
  [61947, 52, 3, 1],
  [61946, 52, 2, 6],
  [61945, 52, 2, 5],
  [61944, 52, 2, 4],
  [61943, 52, 2, 3],
  [61942, 52, 2, 2],
  [61940, 52, 2, 1],
  [61939, 52, 1, 6],
  [61938, 52, 1, 5],
  [61937, 52, 1, 4],
  [61936, 52, 1, 3],
  [61935, 52, 1, 2],
  [61934, 52, 1, 1],
  [61933, 51, 6, 6],
  [61932, 51, 6, 5],
  [61931, 51, 6, 4],
  [61929, 51, 6, 3],
  [61928, 51, 6, 2],
  [61927, 51, 6, 1],
  [61926, 51, 5, 6],
  [61925, 51, 5, 5],
  [61924, 51, 5, 4],
  [61923, 51, 5, 3],
  [61922, 51, 5, 2],
  [61921, 51, 5, 1],
  [61920, 51, 4, 6],
  [61919, 51, 4, 5],
  [61918, 51, 4, 4],
  [61917, 51, 4, 3],
  [61916, 51, 4, 2],
  [61915, 51, 4, 1],
  [61914, 51, 3, 6],
  [61913, 51, 3, 5],
  [61912, 51, 3, 4],
  [61911, 51, 3, 3],
  [61910, 51, 3, 2],
  [61909, 51, 3, 1],
  [61908, 51, 2, 6],
  [61907, 51, 2, 5],
  [61906, 51, 2, 4],
  [61905, 51, 2, 3],
  [61904, 51, 2, 2],
  [61903, 51, 2, 1],
  [61901, 51, 1, 6],
  [61900, 51, 1, 5],
  [61899, 51, 1, 4],
  [61898, 51, 1, 3],
  [61897, 51, 1, 2],
  [61896, 51, 1, 1],
  [61895, 50, 6, 6],
  [61894, 50, 6, 5],
  [61893, 50, 6, 4],
  [61892, 50, 6, 3],
  [61891, 50, 6, 2],
  [61890, 50, 6, 1],
  [61889, 50, 5, 6],
  [61888, 50, 5, 5],
  [61887, 50, 5, 4],
  [61886, 50, 5, 3],
  [61885, 50, 5, 2],
  [61884, 50, 5, 1],
  [61883, 50, 4, 6],
  [61882, 50, 4, 5],
  [61881, 50, 4, 4],
  [61880, 50, 4, 3],
  [61879, 50, 4, 2],
  [61878, 50, 4, 1],
  [61877, 50, 3, 6],
  [61876, 50, 3, 5],
  [61875, 50, 3, 4],
  [61874, 50, 3, 3],
  [61873, 50, 3, 2],
  [61872, 50, 3, 1],
  [61871, 50, 2, 6],
  [61870, 50, 2, 5],
  [61869, 50, 2, 4],
  [61868, 50, 2, 3],
  [61867, 50, 2, 2],
  [61866, 50, 2, 1],
  [61865, 50, 1, 6],
  [61864, 50, 1, 5],
  [61862, 50, 1, 4],
  [61861, 50, 1, 3],
  [61860, 50, 1, 2],
  [61859, 50, 1, 1],
  [61858, 49, 6, 6],
  [61856, 49, 6, 5],
  [61855, 49, 6, 4],
  [61854, 49, 6, 3],
  [61853, 49, 6, 2],
  [61852, 49, 6, 1],
  [61851, 49, 5, 6],
  [61850, 49, 5, 5],
  [61849, 49, 5, 4],
  [61848, 49, 5, 3],
  [61847, 49, 5, 2],
  [61846, 49, 5, 1],
  [61845, 49, 4, 6],
  [61844, 49, 4, 5],
  [61843, 49, 4, 4],
  [61842, 49, 4, 3],
  [61841, 49, 4, 2],
  [61840, 49, 4, 1],
  [61839, 49, 3, 6],
  [61838, 49, 3, 5],
  [61837, 49, 3, 4],
  [61836, 49, 3, 3],
  [61835, 49, 3, 2],
  [61834, 49, 3, 1],
  [61833, 49, 2, 6],
  [61832, 49, 2, 5],
  [61831, 49, 2, 4],
  [61830, 49, 2, 3],
  [61829, 49, 2, 2],
  [61828, 49, 2, 1],
  [61827, 49, 1, 6],
  [61826, 49, 1, 5],
  [61825, 49, 1, 4],
  [61824, 49, 1, 3],
  [61823, 49, 1, 2],
  [61822, 49, 1, 1],
  [61821, 48, 6, 6],
  [61820, 48, 6, 5],
  [61819, 48, 6, 4],
  [61818, 48, 6, 3],
  [61817, 48, 6, 2],
  [61816, 48, 6, 1],
  [61815, 48, 5, 6],
  [61814, 48, 5, 5],
  [61813, 48, 5, 4],
  [61812, 48, 5, 3],
  [61811, 48, 5, 2],
  [61810, 48, 5, 1],
  [61809, 48, 4, 6],
  [61808, 48, 4, 5],
  [61807, 48, 4, 4],
  [61806, 48, 4, 3],
  [61805, 48, 4, 2],
  [61804, 48, 4, 1],
  [61803, 48, 3, 6],
  [61802, 48, 3, 5],
  [61801, 48, 3, 4],
  [61800, 48, 3, 3],
  [61799, 48, 3, 2],
  [61798, 48, 3, 1],
  [61797, 48, 2, 6],
  [61796, 48, 2, 5],
  [61795, 48, 2, 4],
  [61794, 48, 2, 3],
  [61793, 48, 2, 2],
  [61792, 48, 2, 1],
  [61791, 48, 1, 6],
  [61790, 48, 1, 5],
  [61789, 48, 1, 4],
  [61788, 48, 1, 3],
  [61787, 48, 1, 2],
  [61785, 48, 1, 1],
  [61784, 47, 6, 6],
  [61783, 47, 6, 5],
  [61782, 47, 6, 4],
  [61781, 47, 6, 3],
  [61780, 47, 6, 2],
  [61779, 47, 6, 1],
  [61778, 47, 5, 6],
  [61777, 47, 5, 5],
  [61776, 47, 5, 4],
  [61775, 47, 5, 3],
  [61774, 47, 5, 2],
  [61773, 47, 5, 1],
  [61772, 47, 4, 6],
  [61771, 47, 4, 5],
  [61770, 47, 4, 4],
  [61769, 47, 4, 3],
  [61768, 47, 4, 2],
  [61767, 47, 4, 1],
  [61766, 47, 3, 6],
  [61764, 47, 3, 5],
  [61763, 47, 3, 4],
  [61762, 47, 3, 3],
  [61761, 47, 3, 2],
  [61760, 47, 3, 1],
  [61759, 47, 2, 6],
  [61758, 47, 2, 5],
  [61757, 47, 2, 4],
  [61756, 47, 2, 3],
  [61755, 47, 2, 2],
  [61753, 47, 2, 1],
  [61752, 47, 1, 6],
  [61751, 47, 1, 5],
  [61750, 47, 1, 4],
  [61749, 47, 1, 3],
  [61748, 47, 1, 2],
  [61747, 47, 1, 1],
  [61746, 46, 6, 6],
  [61745, 46, 6, 5],
  [61744, 46, 6, 4],
  [61743, 46, 6, 3],
  [61742, 46, 6, 2],
  [61741, 46, 6, 1],
  [61740, 46, 5, 6],
  [61739, 46, 5, 5],
  [61738, 46, 5, 4],
  [61737, 46, 5, 3],
  [61736, 46, 5, 2],
  [61735, 46, 5, 1],
  [61734, 46, 4, 6],
  [61733, 46, 4, 5],
  [61732, 46, 4, 4],
  [61731, 46, 4, 3],
  [61730, 46, 4, 2],
  [61729, 46, 4, 1],
  [61728, 46, 3, 6],
  [61727, 46, 3, 5],
  [61726, 46, 3, 4],
  [61725, 46, 3, 3],
  [61724, 46, 3, 2],
  [61723, 46, 3, 1],
  [61721, 46, 2, 6],
  [61720, 46, 2, 5],
  [61719, 46, 2, 4],
  [61718, 46, 2, 3],
  [61717, 46, 2, 2],
  [61716, 46, 2, 1],
  [61715, 46, 1, 6],
  [61714, 46, 1, 5],
  [61713, 46, 1, 4],
  [61712, 46, 1, 3],
  [61711, 46, 1, 2],
  [61710, 46, 1, 1],
  [61709, 45, 6, 6],
  [61708, 45, 6, 5],
  [61707, 45, 6, 4],
  [61706, 45, 6, 3],
  [61705, 45, 6, 2],
  [61704, 45, 6, 1],
  [61703, 45, 5, 6],
  [61702, 45, 5, 5],
  [61701, 45, 5, 4],
  [61700, 45, 5, 3],
  [61698, 45, 5, 2],
  [61697, 45, 5, 1],
  [61696, 45, 4, 6],
  [61695, 45, 4, 5],
  [61694, 45, 4, 4],
  [61693, 45, 4, 3],
  [61692, 45, 4, 2],
  [61691, 45, 4, 1],
  [61690, 45, 3, 6],
  [61689, 45, 3, 5],
  [61688, 45, 3, 4],
  [61686, 45, 3, 3],
  [61685, 45, 3, 2],
  [61684, 45, 3, 1],
  [61683, 45, 2, 6],
  [61682, 45, 2, 5],
  [61681, 45, 2, 4],
  [61680, 45, 2, 3],
  [61679, 45, 2, 2],
  [61678, 45, 2, 1],
  [61677, 45, 1, 6],
  [61676, 45, 1, 5],
  [61675, 45, 1, 4],
  [61674, 45, 1, 3],
  [61673, 45, 1, 2],
  [61672, 45, 1, 1],
  [61671, 44, 6, 6],
  [61670, 44, 6, 5],
  [61669, 44, 6, 4],
  [61668, 44, 6, 3],
  [61667, 44, 6, 2],
  [61666, 44, 6, 1],
  [61665, 44, 5, 6],
  [61664, 44, 5, 5],
  [61663, 44, 5, 4],
  [61662, 44, 5, 3],
  [61660, 44, 5, 2],
  [61659, 44, 5, 1],
  [61658, 44, 4, 6],
  [61657, 44, 4, 5],
  [61656, 44, 4, 4],
  [61655, 44, 4, 3],
  [61654, 44, 4, 2],
  [61653, 44, 4, 1],
  [61652, 44, 3, 6],
  [61651, 44, 3, 5],
  [61650, 44, 3, 4],
  [61649, 44, 3, 3],
  [61648, 44, 3, 2],
  [61647, 44, 3, 1],
  [61646, 44, 2, 6],
  [61645, 44, 2, 5],
  [61644, 44, 2, 4],
  [61643, 44, 2, 3],
  [61642, 44, 2, 2],
  [61641, 44, 2, 1],
  [61640, 44, 1, 6],
  [61639, 44, 1, 5],
  [61638, 44, 1, 4],
  [61637, 44, 1, 3],
  [61636, 44, 1, 2],
  [61635, 44, 1, 1],
  [61634, 43, 6, 6],
  [61633, 43, 6, 5],
  [61632, 43, 6, 4],
  [61631, 43, 6, 3],
  [61630, 43, 6, 2],
  [61628, 43, 6, 1],
  [61627, 43, 5, 6],
  [61626, 43, 5, 5],
  [61625, 43, 5, 4],
  [61624, 43, 5, 3],
  [61623, 43, 5, 2],
  [61622, 43, 5, 1],
  [61621, 43, 4, 6],
  [61620, 43, 4, 5],
  [61619, 43, 4, 4],
  [61618, 43, 4, 3],
  [61617, 43, 4, 2],
  [61616, 43, 4, 1],
  [61615, 43, 3, 6],
  [61614, 43, 3, 5],
  [61613, 43, 3, 4],
  [61612, 43, 3, 3],
  [61611, 43, 3, 2],
  [61609, 43, 3, 1],
  [61608, 43, 2, 6],
  [61607, 43, 2, 5],
  [61606, 43, 2, 4],
  [61605, 43, 2, 3],
  [61604, 43, 2, 2],
  [61603, 43, 2, 1],
  [61602, 43, 1, 6],
  [61601, 43, 1, 5],
  [61600, 43, 1, 4],
  [61599, 43, 1, 3],
  [61598, 43, 1, 2],
  [61597, 43, 1, 1],
  [61596, 42, 6, 6],
  [61594, 42, 6, 5],
  [61593, 42, 6, 4],
  [61591, 42, 6, 3],
  [61590, 42, 6, 2],
  [61589, 42, 6, 1],
  [61588, 42, 5, 6],
  [61587, 42, 5, 5],
  [61586, 42, 5, 4],
  [61585, 42, 5, 3],
  [61584, 42, 5, 2],
  [61583, 42, 5, 1],
  [61582, 42, 4, 6],
  [61581, 42, 4, 5],
  [61580, 42, 4, 4],
  [61579, 42, 4, 3],
  [61578, 42, 4, 2],
  [61577, 42, 4, 1],
  [61576, 42, 3, 6],
  [61575, 42, 3, 5],
  [61574, 42, 3, 4],
  [61573, 42, 3, 3],
  [61572, 42, 3, 2],
  [61571, 42, 3, 1],
  [61570, 42, 2, 6],
  [61569, 42, 2, 5],
  [61568, 42, 2, 4],
  [61567, 42, 2, 3],
  [61566, 42, 2, 2],
  [61565, 42, 2, 1],
  [61564, 42, 1, 6],
  [61563, 42, 1, 5],
  [61562, 42, 1, 4],
  [61560, 42, 1, 3],
  [61559, 42, 1, 2],
  [61558, 42, 1, 1],
  [61557, 41, 6, 6],
  [61556, 41, 6, 5],
  [61555, 41, 6, 4],
  [61554, 41, 6, 3],
  [61553, 41, 6, 2],
  [61552, 41, 6, 1],
  [61551, 41, 5, 6],
  [61550, 41, 5, 5],
  [61549, 41, 5, 4],
  [61548, 41, 5, 3],
  [61547, 41, 5, 2],
  [61546, 41, 5, 1],
  [61545, 41, 4, 6],
  [61544, 41, 4, 5],
  [61543, 41, 4, 4],
  [61542, 41, 4, 3],
  [61541, 41, 4, 2],
  [61540, 41, 4, 1],
  [61539, 41, 3, 6],
  [61538, 41, 3, 5],
  [61537, 41, 3, 4],
  [61536, 41, 3, 3],
  [61535, 41, 3, 2],
  [61534, 41, 3, 1],
  [61533, 41, 2, 6],
  [61532, 41, 2, 5],
  [61531, 41, 2, 4],
  [61530, 41, 2, 3],
  [61529, 41, 2, 2],
  [61528, 41, 2, 1],
  [61527, 41, 1, 6],
  [61526, 41, 1, 5],
  [61525, 41, 1, 4],
  [61524, 41, 1, 3],
  [61523, 41, 1, 2],
  [61522, 41, 1, 1],
  [61521, 40, 6, 6],
  [61520, 40, 6, 5],
  [61519, 40, 6, 4],
  [61518, 40, 6, 3],
  [61517, 40, 6, 2],
  [61516, 40, 6, 1],
  [61515, 40, 5, 6],
  [61514, 40, 5, 5],
  [61513, 40, 5, 4],
  [61512, 40, 5, 3],
  [61511, 40, 5, 2],
  [61510, 40, 5, 1],
  [61509, 40, 4, 6],
  [61508, 40, 4, 5],
  [61507, 40, 4, 4],
  [61506, 40, 4, 3],
  [61505, 40, 4, 2],
  [61504, 40, 4, 1],
  [61503, 40, 3, 6],
  [61502, 40, 3, 5],
  [61501, 40, 3, 4],
  [61500, 40, 3, 3],
  [61499, 40, 3, 2],
  [61498, 40, 3, 1],
  [61497, 40, 2, 6],
  [61496, 40, 2, 5],
  [61495, 40, 2, 4],
  [61494, 40, 2, 3],
  [61493, 40, 2, 2],
  [61492, 40, 2, 1],
  [61491, 40, 1, 6],
  [61490, 40, 1, 5],
  [61489, 40, 1, 4],
  [61488, 40, 1, 3],
  [61487, 40, 1, 2],
  [61486, 40, 1, 1],
  [61485, 39, 6, 6],
  [61484, 39, 6, 5],
  [61483, 39, 6, 4],
  [61482, 39, 6, 3],
  [61481, 39, 6, 2],
  [61480, 39, 6, 1],
  [61479, 39, 5, 6],
  [61478, 39, 5, 5],
  [61477, 39, 5, 4],
  [61476, 39, 5, 3],
  [61475, 39, 5, 2],
  [61474, 39, 5, 1],
  [61473, 39, 4, 6],
  [61472, 39, 4, 5],
  [61471, 39, 4, 4],
  [61470, 39, 4, 3],
  [61469, 39, 4, 2],
  [61468, 39, 4, 1],
  [61467, 39, 3, 6],
  [61466, 39, 3, 5],
  [61465, 39, 3, 4],
  [61464, 39, 3, 3],
  [61463, 39, 3, 2],
  [61462, 39, 3, 1],
  [61461, 39, 2, 6],
  [61460, 39, 2, 5],
  [61459, 39, 2, 4],
  [61458, 39, 2, 3],
  [61457, 39, 2, 2],
  [61456, 39, 2, 1],
  [61455, 39, 1, 6],
  [61453, 39, 1, 5],
  [61452, 39, 1, 4],
  [61451, 39, 1, 3],
  [61450, 39, 1, 2],
  [61449, 39, 1, 1],
  [61448, 38, 6, 6],
  [61447, 38, 6, 5],
  [61446, 38, 6, 4],
  [61445, 38, 6, 3],
  [61444, 38, 6, 2],
  [61443, 38, 6, 1],
  [61442, 38, 5, 6],
  [61441, 38, 5, 5],
  [61439, 38, 5, 4],
  [61438, 38, 5, 3],
  [61437, 38, 5, 2],
  [61436, 38, 5, 1],
  [61435, 38, 4, 6],
  [61434, 38, 4, 5],
  [61433, 38, 4, 4],
  [61432, 38, 4, 3],
  [61431, 38, 4, 2],
  [61430, 38, 4, 1],
  [61429, 38, 3, 6],
  [61427, 38, 3, 5],
  [61426, 38, 3, 4],
  [61425, 38, 3, 3],
  [61424, 38, 3, 2],
  [61423, 38, 3, 1],
  [61422, 38, 2, 6],
  [61421, 38, 2, 5],
  [61420, 38, 2, 4],
  [61419, 38, 2, 3],
  [61418, 38, 2, 2],
  [61417, 38, 2, 1],
  [61416, 38, 1, 6],
  [61415, 38, 1, 5],
  [61414, 38, 1, 4],
  [61413, 38, 1, 3],
  [61412, 38, 1, 2],
  [61411, 38, 1, 1],
  [61410, 37, 6, 6],
  [61409, 37, 6, 5],
  [61408, 37, 6, 4],
  [61407, 37, 6, 3],
  [61406, 37, 6, 2],
  [61405, 37, 6, 1],
  [61404, 37, 5, 6],
  [61403, 37, 5, 5],
  [61402, 37, 5, 4],
  [61401, 37, 5, 3],
  [61400, 37, 5, 2],
  [61399, 37, 5, 1],
  [61398, 37, 4, 6],
  [61397, 37, 4, 5],
  [61396, 37, 4, 4],
  [61395, 37, 4, 3],
  [61394, 37, 4, 2],
  [61393, 37, 4, 1],
  [61392, 37, 3, 6],
  [61391, 37, 3, 5],
  [61390, 37, 3, 4],
  [61389, 37, 3, 3],
  [61388, 37, 3, 2],
  [61387, 37, 3, 1],
  [61386, 37, 2, 6],
  [61385, 37, 2, 5],
  [61384, 37, 2, 4],
  [61383, 37, 2, 3],
  [61382, 37, 2, 2],
  [61381, 37, 2, 1],
  [61380, 37, 1, 6],
  [61379, 37, 1, 5],
  [61378, 37, 1, 4],
  [61377, 37, 1, 3],
  [61376, 37, 1, 2],
  [61375, 37, 1, 1],
  [61374, 36, 6, 6],
  [61373, 36, 6, 5],
  [61372, 36, 6, 4],
  [61371, 36, 6, 3],
  [61370, 36, 6, 2],
  [61369, 36, 6, 1],
  [61368, 36, 5, 6],
  [61367, 36, 5, 5],
  [61366, 36, 5, 4],
  [61365, 36, 5, 3],
  [61364, 36, 5, 2],
  [61363, 36, 5, 1],
  [61362, 36, 4, 6],
  [61361, 36, 4, 5],
  [61360, 36, 4, 4],
  [61359, 36, 4, 3],
  [61358, 36, 4, 2],
  [61357, 36, 4, 1],
  [61356, 36, 3, 6],
  [61355, 36, 3, 5],
  [61354, 36, 3, 4],
  [61353, 36, 3, 3],
  [61352, 36, 3, 2],
  [61351, 36, 3, 1],
  [61350, 36, 2, 6],
  [61349, 36, 2, 5],
  [61348, 36, 2, 4],
  [61346, 36, 2, 3],
  [61345, 36, 2, 2],
  [61344, 36, 2, 1],
  [61343, 36, 1, 6],
  [61342, 36, 1, 5],
  [61341, 36, 1, 4],
  [61340, 36, 1, 3],
  [61339, 36, 1, 2],
  [61338, 36, 1, 1],
  [61337, 35, 6, 6],
  [61336, 35, 6, 5],
  [61335, 35, 6, 4],
  [61334, 35, 6, 3],
  [61333, 35, 6, 2],
  [61332, 35, 6, 1],
  [61331, 35, 5, 6],
  [61330, 35, 5, 5],
  [61329, 35, 5, 4],
  [61328, 35, 5, 3],
  [61327, 35, 5, 2],
  [61326, 35, 5, 1],
  [61325, 35, 4, 6],
  [61324, 35, 4, 5],
  [61323, 35, 4, 4],
  [61322, 35, 4, 3],
  [61321, 35, 4, 2],
  [61320, 35, 4, 1],
  [61319, 35, 3, 6],
  [61318, 35, 3, 5],
  [61317, 35, 3, 4],
  [61316, 35, 3, 3],
  [61315, 35, 3, 2],
  [61314, 35, 3, 1],
  [61313, 35, 2, 6],
  [61312, 35, 2, 5],
  [61311, 35, 2, 4],
  [61310, 35, 2, 3],
  [61309, 35, 2, 2],
  [61308, 35, 2, 1],
  [61307, 35, 1, 6],
  [61306, 35, 1, 5],
  [61305, 35, 1, 4],
  [61303, 35, 1, 3],
  [61302, 35, 1, 2],
  [61301, 35, 1, 1],
  [61300, 34, 6, 6],
  [61299, 34, 6, 5],
  [61298, 34, 6, 4],
  [61297, 34, 6, 3],
  [61296, 34, 6, 2],
  [61295, 34, 6, 1],
  [61294, 34, 5, 6],
  [61293, 34, 5, 5],
  [61292, 34, 5, 4],
  [61291, 34, 5, 3],
  [61290, 34, 5, 2],
  [61289, 34, 5, 1],
  [61288, 34, 4, 6],
  [61287, 34, 4, 5],
  [61286, 34, 4, 4],
  [61285, 34, 4, 3],
  [61284, 34, 4, 2],
  [61283, 34, 4, 1],
  [61282, 34, 3, 6],
  [61281, 34, 3, 5],
  [61280, 34, 3, 4],
  [61279, 34, 3, 3],
  [61278, 34, 3, 2],
  [61277, 34, 3, 1],
  [61276, 34, 2, 6],
  [61275, 34, 2, 5],
  [61274, 34, 2, 4],
  [61273, 34, 2, 3],
  [61272, 34, 2, 2],
  [61271, 34, 2, 1],
  [61270, 34, 1, 6],
  [61269, 34, 1, 5],
  [61268, 34, 1, 4],
  [61267, 34, 1, 3],
  [61266, 34, 1, 2],
  [61264, 34, 1, 1],
  [61263, 33, 6, 6],
  [61262, 33, 6, 5],
  [61261, 33, 6, 4],
  [61260, 33, 6, 3],
  [61259, 33, 6, 2],
  [61258, 33, 6, 1],
  [61257, 33, 5, 6],
  [61256, 33, 5, 5],
  [61255, 33, 5, 4],
  [61254, 33, 5, 3],
  [61253, 33, 5, 2],
  [61252, 33, 5, 1],
  [61251, 33, 4, 6],
  [61250, 33, 4, 5],
  [61249, 33, 4, 4],
  [61248, 33, 4, 3],
  [61247, 33, 4, 2],
  [61246, 33, 4, 1],
  [61245, 33, 3, 6],
  [61244, 33, 3, 5],
  [61243, 33, 3, 4],
  [61242, 33, 3, 3],
  [61241, 33, 3, 2],
  [61240, 33, 3, 1],
  [61239, 33, 2, 6],
  [61238, 33, 2, 5],
  [61237, 33, 2, 4],
  [61236, 33, 2, 3],
  [61235, 33, 2, 2],
  [61234, 33, 2, 1],
  [61233, 33, 1, 6],
  [61232, 33, 1, 5],
  [61231, 33, 1, 4],
  [61230, 33, 1, 3],
  [61229, 33, 1, 2],
  [61228, 33, 1, 1],
  [61227, 32, 6, 6],
  [61226, 32, 6, 5],
  [61225, 32, 6, 4],
  [61224, 32, 6, 3],
  [61223, 32, 6, 2],
  [61222, 32, 6, 1],
  [61221, 32, 5, 6],
  [61220, 32, 5, 5],
  [61219, 32, 5, 4],
  [61218, 32, 5, 3],
  [61217, 32, 5, 2],
  [61216, 32, 5, 1],
  [61215, 32, 4, 6],
  [61214, 32, 4, 5],
  [61213, 32, 4, 4],
  [61212, 32, 4, 3],
  [61211, 32, 4, 2],
  [61210, 32, 4, 1],
  [61209, 32, 3, 6],
  [61208, 32, 3, 5],
  [61207, 32, 3, 4],
  [61206, 32, 3, 3],
  [61205, 32, 3, 2],
  [61204, 32, 3, 1],
  [61203, 32, 2, 6],
  [61202, 32, 2, 5],
  [61201, 32, 2, 4],
  [61200, 32, 2, 3],
  [61199, 32, 2, 2],
  [61198, 32, 2, 1],
  [61197, 32, 1, 6],
  [61196, 32, 1, 5],
  [61195, 32, 1, 4],
  [61194, 32, 1, 3],
  [61193, 32, 1, 2],
  [61192, 32, 1, 1],
  [61191, 31, 6, 6],
  [61190, 31, 6, 5],
  [61189, 31, 6, 4],
  [61188, 31, 6, 3],
  [61187, 31, 6, 2],
  [61186, 31, 6, 1],
  [61185, 31, 5, 6],
  [61184, 31, 5, 5],
  [61183, 31, 5, 4],
  [61182, 31, 5, 3],
  [61181, 31, 5, 2],
  [61180, 31, 5, 1],
  [61179, 31, 4, 6],
  [61178, 31, 4, 5],
  [61177, 31, 4, 4],
  [61176, 31, 4, 3],
  [61175, 31, 4, 2],
  [61174, 31, 4, 1],
  [61172, 31, 3, 6],
  [61171, 31, 3, 5],
  [61170, 31, 3, 4],
  [61169, 31, 3, 3],
  [61168, 31, 3, 2],
  [61167, 31, 3, 1],
  [61166, 31, 2, 6],
  [61165, 31, 2, 5],
  [61164, 31, 2, 4],
  [61163, 31, 2, 3],
  [61162, 31, 2, 2],
  [61161, 31, 2, 1],
  [61160, 31, 1, 6],
  [61159, 31, 1, 5],
  [61157, 31, 1, 4],
  [61156, 31, 1, 3],
  [61155, 31, 1, 2],
  [61154, 31, 1, 1],
  [61153, 30, 6, 6],
  [61152, 30, 6, 5],
  [61151, 30, 6, 4],
  [61150, 30, 6, 3],
  [61149, 30, 6, 2],
  [61148, 30, 6, 1],
  [61147, 30, 5, 6],
  [61146, 30, 5, 5],
  [61145, 30, 5, 4],
  [61143, 30, 5, 3],
  [61142, 30, 5, 2],
  [61141, 30, 5, 1],
  [61140, 30, 4, 6],
  [61139, 30, 4, 5],
  [61138, 30, 4, 4],
  [61137, 30, 4, 3],
  [61136, 30, 4, 2],
  [61135, 30, 4, 1],
  [61134, 30, 3, 6],
  [61133, 30, 3, 5],
  [61132, 30, 3, 4],
  [61131, 30, 3, 3],
  [61130, 30, 3, 2],
  [61129, 30, 3, 1],
  [61128, 30, 2, 6],
  [61127, 30, 2, 5],
  [61126, 30, 2, 4],
  [61125, 30, 2, 3],
  [61124, 30, 2, 2],
  [61123, 30, 2, 1],
  [61122, 30, 1, 6],
  [61121, 30, 1, 5],
  [61120, 30, 1, 4],
  [61119, 30, 1, 3],
  [61117, 30, 1, 2],
  [61116, 30, 1, 1],
  [61115, 29, 6, 6],
  [61114, 29, 6, 5],
  [61113, 29, 6, 4],
  [61112, 29, 6, 3],
  [61111, 29, 6, 2],
  [61110, 29, 6, 1],
  [61109, 29, 5, 6],
  [61107, 29, 5, 5],
  [61106, 29, 5, 4],
  [61105, 29, 5, 3],
  [61104, 29, 5, 2],
  [61103, 29, 5, 1],
  [61102, 29, 4, 6],
  [61101, 29, 4, 5],
  [61100, 29, 4, 4],
  [61099, 29, 4, 3],
  [61098, 29, 4, 2],
  [61097, 29, 4, 1],
  [61096, 29, 3, 6],
  [61095, 29, 3, 5],
  [61094, 29, 3, 4],
  [61092, 29, 3, 3],
  [61091, 29, 3, 2],
  [61090, 29, 3, 1],
  [61089, 29, 2, 6],
  [61088, 29, 2, 5],
  [61087, 29, 2, 4],
  [61086, 29, 2, 3],
  [61085, 29, 2, 2],
  [61084, 29, 2, 1],
  [61083, 29, 1, 6],
  [61082, 29, 1, 5],
  [61080, 29, 1, 4],
  [61079, 29, 1, 3],
  [61078, 29, 1, 2],
  [61077, 29, 1, 1],
  [61076, 28, 6, 6],
  [61075, 28, 6, 5],
  [61074, 28, 6, 4],
  [61073, 28, 6, 3],
  [61072, 28, 6, 2],
  [61071, 28, 6, 1],
  [61070, 28, 5, 6],
  [61069, 28, 5, 5],
  [61068, 28, 5, 4],
  [61067, 28, 5, 3],
  [61066, 28, 5, 2],
  [61065, 28, 5, 1],
  [61064, 28, 4, 6],
  [61063, 28, 4, 5],
  [61062, 28, 4, 4],
  [61061, 28, 4, 3],
  [61060, 28, 4, 2],
  [61059, 28, 4, 1],
  [61058, 28, 3, 6],
  [61057, 28, 3, 5],
  [61056, 28, 3, 4],
  [61055, 28, 3, 3],
  [61054, 28, 3, 2],
  [61053, 28, 3, 1],
  [61052, 28, 2, 6],
  [61051, 28, 2, 5],
  [61049, 28, 2, 4],
  [61048, 28, 2, 3],
  [61047, 28, 2, 2],
  [61046, 28, 2, 1],
  [61045, 28, 1, 6],
  [61044, 28, 1, 5],
  [61043, 28, 1, 4],
  [61042, 28, 1, 3],
  [61041, 28, 1, 2],
  [61040, 28, 1, 1],
  [61039, 27, 6, 6],
  [61037, 27, 6, 5],
  [61036, 27, 6, 4],
  [61035, 27, 6, 3],
  [61034, 27, 6, 2],
  [61032, 27, 6, 1],
  [61031, 27, 5, 6],
  [61030, 27, 5, 5],
  [61029, 27, 5, 4],
  [61028, 27, 5, 3],
  [61027, 27, 5, 2],
  [61026, 27, 5, 1],
  [61025, 27, 4, 6],
  [61024, 27, 4, 5],
  [61023, 27, 4, 4],
  [61022, 27, 4, 3],
  [61021, 27, 4, 2],
  [61020, 27, 4, 1],
  [61019, 27, 3, 6],
  [61018, 27, 3, 5],
  [61017, 27, 3, 4],
  [61016, 27, 3, 3],
  [61014, 27, 3, 2],
  [61013, 27, 3, 1],
  [61012, 27, 2, 6],
  [61011, 27, 2, 5],
  [61010, 27, 2, 4],
  [61009, 27, 2, 3],
  [61008, 27, 2, 2],
  [61007, 27, 2, 1],
  [61006, 27, 1, 6],
  [61005, 27, 1, 5],
  [61004, 27, 1, 4],
  [61003, 27, 1, 3],
  [61002, 27, 1, 2],
  [61001, 27, 1, 1],
  [61000, 26, 6, 6],
  [60999, 26, 6, 5],
  [60998, 26, 6, 4],
  [60997, 26, 6, 3],
  [60996, 26, 6, 2],
  [60995, 26, 6, 1],
  [60994, 26, 5, 6],
  [60993, 26, 5, 5],
  [60992, 26, 5, 4],
  [60991, 26, 5, 3],
  [60990, 26, 5, 2],
  [60989, 26, 5, 1],
  [60988, 26, 4, 6],
  [60987, 26, 4, 5],
  [60986, 26, 4, 4],
  [60985, 26, 4, 3],
  [60984, 26, 4, 2],
  [60983, 26, 4, 1],
  [60982, 26, 3, 6],
  [60981, 26, 3, 5],
  [60980, 26, 3, 4],
  [60979, 26, 3, 3],
  [60978, 26, 3, 2],
  [60977, 26, 3, 1],
  [60976, 26, 2, 6],
  [60975, 26, 2, 5],
  [60974, 26, 2, 4],
  [60973, 26, 2, 3],
  [60972, 26, 2, 2],
  [60971, 26, 2, 1],
  [60970, 26, 1, 6],
  [60969, 26, 1, 5],
  [60968, 26, 1, 4],
  [60967, 26, 1, 3],
  [60966, 26, 1, 2],
  [60965, 26, 1, 1],
  [60964, 25, 6, 6],
  [60963, 25, 6, 5],
  [60962, 25, 6, 4],
  [60961, 25, 6, 3],
  [60960, 25, 6, 2],
  [60959, 25, 6, 1],
  [60958, 25, 5, 6],
  [60957, 25, 5, 5],
  [60956, 25, 5, 4],
  [60955, 25, 5, 3],
  [60954, 25, 5, 2],
  [60953, 25, 5, 1],
  [60952, 25, 4, 6],
  [60951, 25, 4, 5],
  [60950, 25, 4, 4],
  [60949, 25, 4, 3],
  [60948, 25, 4, 2],
  [60947, 25, 4, 1],
  [60946, 25, 3, 6],
  [60945, 25, 3, 5],
  [60944, 25, 3, 4],
  [60943, 25, 3, 3],
  [60942, 25, 3, 2],
  [60941, 25, 3, 1],
  [60940, 25, 2, 6],
  [60939, 25, 2, 5],
  [60938, 25, 2, 4],
  [60937, 25, 2, 3],
  [60936, 25, 2, 2],
  [60935, 25, 2, 1],
  [60934, 25, 1, 6],
  [60933, 25, 1, 5],
  [60932, 25, 1, 4],
  [60931, 25, 1, 3],
  [60930, 25, 1, 2],
  [60929, 25, 1, 1],
  [60928, 24, 6, 6],
  [60927, 24, 6, 5],
  [60926, 24, 6, 4],
  [60925, 24, 6, 3],
  [60924, 24, 6, 2],
  [60923, 24, 6, 1],
  [60922, 24, 5, 6],
  [60921, 24, 5, 5],
  [60920, 24, 5, 4],
  [60919, 24, 5, 3],
  [60918, 24, 5, 2],
  [60917, 24, 5, 1],
  [60916, 24, 4, 6],
  [60915, 24, 4, 5],
  [60914, 24, 4, 4],
  [60913, 24, 4, 3],
  [60912, 24, 4, 2],
  [60911, 24, 4, 1],
  [60910, 24, 3, 6],
  [60909, 24, 3, 5],
  [60908, 24, 3, 4],
  [60907, 24, 3, 3],
  [60906, 24, 3, 2],
  [60905, 24, 3, 1],
  [60904, 24, 2, 6],
  [60903, 24, 2, 5],
  [60902, 24, 2, 4],
  [60901, 24, 2, 3],
  [60900, 24, 2, 2],
  [60899, 24, 2, 1],
  [60898, 24, 1, 6],
  [60897, 24, 1, 5],
  [60896, 24, 1, 4],
  [60895, 24, 1, 3],
  [60894, 24, 1, 2],
  [60893, 24, 1, 1],
  [60892, 23, 6, 6],
  [60891, 23, 6, 5],
  [60890, 23, 6, 4],
  [60889, 23, 6, 3],
  [60888, 23, 6, 2],
  [60887, 23, 6, 1],
  [60886, 23, 5, 6],
  [60885, 23, 5, 5],
  [60884, 23, 5, 4],
  [60883, 23, 5, 3],
  [60882, 23, 5, 2],
  [60881, 23, 5, 1],
  [60880, 23, 4, 6],
  [60879, 23, 4, 5],
  [60878, 23, 4, 4],
  [60877, 23, 4, 3],
  [60876, 23, 4, 2],
  [60875, 23, 4, 1],
  [60874, 23, 3, 6],
  [60873, 23, 3, 5],
  [60872, 23, 3, 4],
  [60871, 23, 3, 3],
  [60870, 23, 3, 2],
  [60869, 23, 3, 1],
  [60868, 23, 2, 6],
  [60867, 23, 2, 5],
  [60866, 23, 2, 4],
  [60865, 23, 2, 3],
  [60864, 23, 2, 2],
  [60863, 23, 2, 1],
  [60862, 23, 1, 6],
  [60861, 23, 1, 5],
  [60860, 23, 1, 4],
  [60859, 23, 1, 3],
  [60858, 23, 1, 2],
  [60857, 23, 1, 1],
  [60856, 22, 6, 6],
  [60855, 22, 6, 5],
  [60854, 22, 6, 4],
  [60853, 22, 6, 3],
  [60852, 22, 6, 2],
  [60851, 22, 6, 1],
  [60850, 22, 5, 6],
  [60849, 22, 5, 5],
  [60848, 22, 5, 4],
  [60847, 22, 5, 3],
  [60846, 22, 5, 2],
  [60845, 22, 5, 1],
  [60844, 22, 4, 6],
  [60843, 22, 4, 5],
  [60842, 22, 4, 4],
  [60841, 22, 4, 3],
  [60840, 22, 4, 2],
  [60839, 22, 4, 1],
  [60838, 22, 3, 6],
  [60837, 22, 3, 5],
  [60836, 22, 3, 4],
  [60835, 22, 3, 3],
  [60834, 22, 3, 2],
  [60833, 22, 3, 1],
  [60832, 22, 2, 6],
  [60831, 22, 2, 5],
  [60830, 22, 2, 4],
  [60829, 22, 2, 3],
  [60828, 22, 2, 2],
  [60827, 22, 2, 1],
  [60826, 22, 1, 6],
  [60825, 22, 1, 5],
  [60824, 22, 1, 4],
  [60823, 22, 1, 3],
  [60822, 22, 1, 2],
  [60821, 22, 1, 1],
  [60820, 21, 6, 6],
  [60819, 21, 6, 5],
  [60818, 21, 6, 4],
  [60817, 21, 6, 3],
  [60816, 21, 6, 2],
  [60815, 21, 6, 1],
  [60814, 21, 5, 6],
  [60813, 21, 5, 5],
  [60812, 21, 5, 4],
  [60811, 21, 5, 3],
  [60810, 21, 5, 2],
  [60809, 21, 5, 1],
  [60808, 21, 4, 6],
  [60807, 21, 4, 5],
  [60806, 21, 4, 4],
  [60805, 21, 4, 3],
  [60804, 21, 4, 2],
  [60803, 21, 4, 1],
  [60802, 21, 3, 6],
  [60801, 21, 3, 5],
  [60800, 21, 3, 4],
  [60799, 21, 3, 3],
  [60798, 21, 3, 2],
  [60797, 21, 3, 1],
  [60796, 21, 2, 6],
  [60795, 21, 2, 5],
  [60794, 21, 2, 4],
  [60793, 21, 2, 3],
  [60792, 21, 2, 2],
  [60791, 21, 2, 1],
  [60790, 21, 1, 6],
  [60789, 21, 1, 5],
  [60788, 21, 1, 4],
  [60787, 21, 1, 3],
  [60786, 21, 1, 2],
  [60785, 21, 1, 1],
  [60784, 20, 6, 6],
  [60783, 20, 6, 5],
  [60782, 20, 6, 4],
  [60781, 20, 6, 3],
  [60780, 20, 6, 2],
  [60779, 20, 6, 1],
  [60778, 20, 5, 6],
  [60777, 20, 5, 5],
  [60776, 20, 5, 4],
  [60775, 20, 5, 3],
  [60774, 20, 5, 2],
  [60773, 20, 5, 1],
  [60771, 20, 4, 6],
  [60770, 20, 4, 5],
  [60769, 20, 4, 4],
  [60768, 20, 4, 3],
  [60767, 20, 4, 2],
  [60766, 20, 4, 1],
  [60765, 20, 3, 6],
  [60764, 20, 3, 5],
  [60763, 20, 3, 4],
  [60762, 20, 3, 3],
  [60761, 20, 3, 2],
  [60760, 20, 3, 1],
  [60759, 20, 2, 6],
  [60758, 20, 2, 5],
  [60757, 20, 2, 4],
  [60756, 20, 2, 3],
  [60755, 20, 2, 2],
  [60754, 20, 2, 1],
  [60753, 20, 1, 6],
  [60752, 20, 1, 5],
  [60751, 20, 1, 4],
  [60750, 20, 1, 3],
  [60749, 20, 1, 2],
  [60748, 20, 1, 1],
  [60747, 19, 6, 6],
  [60746, 19, 6, 5],
  [60745, 19, 6, 4],
  [60744, 19, 6, 3],
  [60743, 19, 6, 2],
  [60742, 19, 6, 1],
  [60741, 19, 5, 6],
  [60740, 19, 5, 5],
  [60739, 19, 5, 4],
  [60738, 19, 5, 3],
  [60737, 19, 5, 2],
  [60736, 19, 5, 1],
  [60735, 19, 4, 6],
  [60734, 19, 4, 5],
  [60733, 19, 4, 4],
  [60732, 19, 4, 3],
  [60731, 19, 4, 2],
  [60730, 19, 4, 1],
  [60729, 19, 3, 6],
  [60728, 19, 3, 5],
  [60727, 19, 3, 4],
  [60726, 19, 3, 3],
  [60725, 19, 3, 2],
  [60724, 19, 3, 1],
  [60723, 19, 2, 6],
  [60722, 19, 2, 5],
  [60721, 19, 2, 4],
  [60720, 19, 2, 3],
  [60719, 19, 2, 2],
  [60718, 19, 2, 1],
  [60717, 19, 1, 6],
  [60716, 19, 1, 5],
  [60715, 19, 1, 4],
  [60714, 19, 1, 3],
  [60713, 19, 1, 2],
  [60712, 19, 1, 1],
  [60711, 18, 6, 6],
  [60710, 18, 6, 5],
  [60709, 18, 6, 4],
  [60708, 18, 6, 3],
  [60707, 18, 6, 2],
  [60706, 18, 6, 1],
  [60705, 18, 5, 6],
  [60704, 18, 5, 5],
  [60703, 18, 5, 4],
  [60702, 18, 5, 3],
  [60701, 18, 5, 2],
  [60700, 18, 5, 1],
  [60699, 18, 4, 6],
  [60698, 18, 4, 5],
  [60697, 18, 4, 4],
  [60696, 18, 4, 3],
  [60695, 18, 4, 2],
  [60694, 18, 4, 1],
  [60693, 18, 3, 6],
  [60692, 18, 3, 5],
  [60691, 18, 3, 4],
  [60690, 18, 3, 3],
  [60689, 18, 3, 2],
  [60688, 18, 3, 1],
  [60687, 18, 2, 6],
  [60686, 18, 2, 5],
  [60685, 18, 2, 4],
  [60684, 18, 2, 3],
  [60683, 18, 2, 2],
  [60682, 18, 2, 1],
  [60681, 18, 1, 6],
  [60680, 18, 1, 5],
  [60679, 18, 1, 4],
  [60678, 18, 1, 3],
  [60677, 18, 1, 2],
  [60676, 18, 1, 1],
  [60675, 17, 6, 6],
  [60674, 17, 6, 5],
  [60673, 17, 6, 4],
  [60672, 17, 6, 3],
  [60671, 17, 6, 2],
  [60670, 17, 6, 1],
  [60669, 17, 5, 6],
  [60668, 17, 5, 5],
  [60667, 17, 5, 4],
  [60666, 17, 5, 3],
  [60665, 17, 5, 2],
  [60664, 17, 5, 1],
  [60663, 17, 4, 6],
  [60662, 17, 4, 5],
  [60661, 17, 4, 4],
  [60660, 17, 4, 3],
  [60659, 17, 4, 2],
  [60658, 17, 4, 1],
  [60657, 17, 3, 6],
  [60656, 17, 3, 5],
  [60655, 17, 3, 4],
  [60654, 17, 3, 3],
  [60653, 17, 3, 2],
  [60652, 17, 3, 1],
  [60651, 17, 2, 6],
  [60650, 17, 2, 5],
  [60649, 17, 2, 4],
  [60648, 17, 2, 3],
  [60647, 17, 2, 2],
  [60645, 17, 2, 1],
  [60644, 17, 1, 6],
  [60643, 17, 1, 5],
  [60642, 17, 1, 4],
  [60641, 17, 1, 3],
  [60640, 17, 1, 2],
  [60639, 17, 1, 1],
  [60638, 16, 6, 6],
  [60637, 16, 6, 5],
  [60636, 16, 6, 4],
  [60635, 16, 6, 3],
  [60634, 16, 6, 2],
  [60633, 16, 6, 1],
  [60632, 16, 5, 6],
  [60631, 16, 5, 5],
  [60630, 16, 5, 4],
  [60629, 16, 5, 3],
  [60628, 16, 5, 2],
  [60627, 16, 5, 1],
  [60626, 16, 4, 6],
  [60625, 16, 4, 5],
  [60624, 16, 4, 4],
  [60622, 16, 4, 3],
  [60621, 16, 4, 2],
  [60620, 16, 4, 1],
  [60619, 16, 3, 6],
  [60618, 16, 3, 5],
  [60617, 16, 3, 4],
  [60616, 16, 3, 3],
  [60615, 16, 3, 2],
  [60614, 16, 3, 1],
  [60613, 16, 2, 6],
  [60612, 16, 2, 5],
  [60611, 16, 2, 4],
  [60610, 16, 2, 3],
  [60609, 16, 2, 2],
  [60608, 16, 2, 1],
  [60607, 16, 1, 6],
  [60606, 16, 1, 5],
  [60605, 16, 1, 4],
  [60604, 16, 1, 3],
  [60603, 16, 1, 2],
  [60602, 16, 1, 1],
  [60601, 15, 6, 6],
  [60600, 15, 6, 5],
  [60599, 15, 6, 4],
  [60598, 15, 6, 3],
  [60597, 15, 6, 2],
  [60596, 15, 6, 1],
  [60595, 15, 5, 6],
  [60594, 15, 5, 5],
  [60593, 15, 5, 4],
  [60592, 15, 5, 3],
  [60591, 15, 5, 2],
  [60590, 15, 5, 1],
  [60589, 15, 4, 6],
  [60588, 15, 4, 5],
  [60587, 15, 4, 4],
  [60586, 15, 4, 3],
  [60585, 15, 4, 2],
  [60584, 15, 4, 1],
  [60583, 15, 3, 6],
  [60582, 15, 3, 5],
  [60581, 15, 3, 4],
  [60580, 15, 3, 3],
  [60579, 15, 3, 2],
  [60578, 15, 3, 1],
  [60577, 15, 2, 6],
  [60576, 15, 2, 5],
  [60575, 15, 2, 4],
  [60574, 15, 2, 3],
  [60573, 15, 2, 2],
  [60572, 15, 2, 1],
  [60571, 15, 1, 6],
  [60570, 15, 1, 5],
  [60569, 15, 1, 4],
  [60568, 15, 1, 3],
  [60567, 15, 1, 2],
  [60566, 15, 1, 1],
  [60565, 14, 6, 6],
  [60564, 14, 6, 5],
  [60563, 14, 6, 4],
  [60562, 14, 6, 3],
  [60561, 14, 6, 2],
  [60560, 14, 6, 1],
  [60559, 14, 5, 6],
  [60558, 14, 5, 5],
  [60557, 14, 5, 4],
  [60556, 14, 5, 3],
  [60555, 14, 5, 2],
  [60554, 14, 5, 1],
  [60553, 14, 4, 6],
  [60552, 14, 4, 5],
  [60551, 14, 4, 4],
  [60550, 14, 4, 3],
  [60549, 14, 4, 2],
  [60548, 14, 4, 1],
  [60547, 14, 3, 6],
  [60546, 14, 3, 5],
  [60545, 14, 3, 4],
  [60544, 14, 3, 3],
  [60543, 14, 3, 2],
  [60541, 14, 3, 1],
  [60540, 14, 2, 6],
  [60539, 14, 2, 5],
  [60538, 14, 2, 4],
  [60537, 14, 2, 3],
  [60536, 14, 2, 2],
  [60535, 14, 2, 1],
  [60534, 14, 1, 6],
  [60533, 14, 1, 5],
  [60532, 14, 1, 4],
  [60531, 14, 1, 3],
  [60530, 14, 1, 2],
  [60529, 14, 1, 1],
  [60528, 13, 6, 6],
  [60526, 13, 6, 5],
  [60525, 13, 6, 4],
  [60524, 13, 6, 3],
  [60523, 13, 6, 2],
  [60522, 13, 6, 1],
  [60521, 13, 5, 6],
  [60520, 13, 5, 5],
  [60519, 13, 5, 4],
  [60518, 13, 5, 3],
  [60517, 13, 5, 2],
  [60516, 13, 5, 1],
  [60515, 13, 4, 6],
  [60514, 13, 4, 5],
  [60513, 13, 4, 4],
  [60512, 13, 4, 3],
  [60511, 13, 4, 2],
  [60510, 13, 4, 1],
  [60509, 13, 3, 6],
  [60508, 13, 3, 5],
  [60507, 13, 3, 4],
  [60506, 13, 3, 3],
  [60505, 13, 3, 2],
  [60504, 13, 3, 1],
  [60503, 13, 2, 6],
  [60502, 13, 2, 5],
  [60501, 13, 2, 4],
  [60500, 13, 2, 3],
  [60499, 13, 2, 2],
  [60498, 13, 2, 1],
  [60497, 13, 1, 6],
  [60496, 13, 1, 5],
  [60495, 13, 1, 4],
  [60494, 13, 1, 3],
  [60493, 13, 1, 2],
  [60492, 13, 1, 1],
  [60491, 12, 6, 6],
  [60490, 12, 6, 5],
  [60489, 12, 6, 4],
  [60487, 12, 6, 3],
  [60486, 12, 6, 2],
  [60485, 12, 6, 1],
  [60484, 12, 5, 6],
  [60483, 12, 5, 5],
  [60482, 12, 5, 4],
  [60481, 12, 5, 3],
  [60480, 12, 5, 2],
  [60479, 12, 5, 1],
  [60478, 12, 4, 6],
  [60477, 12, 4, 5],
  [60476, 12, 4, 4],
  [60475, 12, 4, 3],
  [60474, 12, 4, 2],
  [60473, 12, 4, 1],
  [60472, 12, 3, 6],
  [60471, 12, 3, 5],
  [60470, 12, 3, 4],
  [60469, 12, 3, 3],
  [60467, 12, 3, 2],
  [60466, 12, 3, 1],
  [60465, 12, 2, 6],
  [60464, 12, 2, 5],
  [60463, 12, 2, 4],
  [60462, 12, 2, 3],
  [60460, 12, 2, 2],
  [60459, 12, 2, 1],
  [60458, 12, 1, 6],
  [60457, 12, 1, 5],
  [60456, 12, 1, 4],
  [60455, 12, 1, 3],
  [60454, 12, 1, 2],
  [60453, 12, 1, 1],
  [60452, 11, 6, 6],
  [60451, 11, 6, 5],
  [60450, 11, 6, 4],
  [60449, 11, 6, 3],
  [60448, 11, 6, 2],
  [60447, 11, 6, 1],
  [60446, 11, 5, 6],
  [60445, 11, 5, 5],
  [60444, 11, 5, 4],
  [60443, 11, 5, 3],
  [60442, 11, 5, 2],
  [60441, 11, 5, 1],
  [60440, 11, 4, 6],
  [60439, 11, 4, 5],
  [60438, 11, 4, 4],
  [60437, 11, 4, 3],
  [60436, 11, 4, 2],
  [60435, 11, 4, 1],
  [60434, 11, 3, 6],
  [60433, 11, 3, 5],
  [60432, 11, 3, 4],
  [60431, 11, 3, 3],
  [60430, 11, 3, 2],
  [60429, 11, 3, 1],
  [60428, 11, 2, 6],
  [60427, 11, 2, 5],
  [60426, 11, 2, 4],
  [60425, 11, 2, 3],
  [60424, 11, 2, 2],
  [60423, 11, 2, 1],
  [60422, 11, 1, 6],
  [60421, 11, 1, 5],
  [60420, 11, 1, 4],
  [60419, 11, 1, 3],
  [60418, 11, 1, 2],
  [60417, 11, 1, 1],
  [60416, 10, 6, 6],
  [60415, 10, 6, 5],
  [60414, 10, 6, 4],
  [60413, 10, 6, 3],
  [60412, 10, 6, 2],
  [60411, 10, 6, 1],
  [60410, 10, 5, 6],
  [60409, 10, 5, 5],
  [60408, 10, 5, 4],
  [60407, 10, 5, 3],
  [60406, 10, 5, 2],
  [60405, 10, 5, 1],
  [60404, 10, 4, 6],
  [60403, 10, 4, 5],
  [60402, 10, 4, 4],
  [60401, 10, 4, 3],
  [60400, 10, 4, 2],
  [60399, 10, 4, 1],
  [60398, 10, 3, 6],
  [60397, 10, 3, 5],
  [60396, 10, 3, 4],
  [60395, 10, 3, 3],
  [60394, 10, 3, 2],
  [60393, 10, 3, 1],
  [60392, 10, 2, 6],
  [60391, 10, 2, 5],
  [60390, 10, 2, 4],
  [60389, 10, 2, 3],
  [60388, 10, 2, 2],
  [60387, 10, 2, 1],
  [60386, 10, 1, 6],
  [60385, 10, 1, 5],
  [60384, 10, 1, 4],
  [60383, 10, 1, 3],
  [60382, 10, 1, 2],
  [60381, 10, 1, 1],
  [60380, 9, 6, 6],
  [60379, 9, 6, 5],
  [60378, 9, 6, 4],
  [60377, 9, 6, 3],
  [60376, 9, 6, 2],
  [60375, 9, 6, 1],
  [60374, 9, 5, 6],
  [60373, 9, 5, 5],
  [60372, 9, 5, 4],
  [60371, 9, 5, 3],
  [60370, 9, 5, 2],
  [60369, 9, 5, 1],
  [60368, 9, 4, 6],
  [60367, 9, 4, 5],
  [60366, 9, 4, 4],
  [60365, 9, 4, 3],
  [60364, 9, 4, 2],
  [60363, 9, 4, 1],
  [60362, 9, 3, 6],
  [60361, 9, 3, 5],
  [60360, 9, 3, 4],
  [60359, 9, 3, 3],
  [60358, 9, 3, 2],
  [60357, 9, 3, 1],
  [60356, 9, 2, 6],
  [60355, 9, 2, 5],
  [60354, 9, 2, 4],
  [60353, 9, 2, 3],
  [60352, 9, 2, 2],
  [60351, 9, 2, 1],
  [60350, 9, 1, 6],
  [60349, 9, 1, 5],
  [60348, 9, 1, 4],
  [60347, 9, 1, 3],
  [60346, 9, 1, 2],
  [60345, 9, 1, 1],
  [60344, 8, 6, 6],
  [60343, 8, 6, 5],
  [60342, 8, 6, 4],
  [60341, 8, 6, 3],
  [60340, 8, 6, 2],
  [60339, 8, 6, 1],
  [60338, 8, 5, 6],
  [60337, 8, 5, 5],
  [60336, 8, 5, 4],
  [60335, 8, 5, 3],
  [60334, 8, 5, 2],
  [60333, 8, 5, 1],
  [60332, 8, 4, 6],
  [60331, 8, 4, 5],
  [60330, 8, 4, 4],
  [60329, 8, 4, 3],
  [60328, 8, 4, 2],
  [60327, 8, 4, 1],
  [60326, 8, 3, 6],
  [60325, 8, 3, 5],
  [60324, 8, 3, 4],
  [60323, 8, 3, 3],
  [60322, 8, 3, 2],
  [60321, 8, 3, 1],
  [60320, 8, 2, 6],
  [60319, 8, 2, 5],
  [60318, 8, 2, 4],
  [60317, 8, 2, 3],
  [60316, 8, 2, 2],
  [60315, 8, 2, 1],
  [60314, 8, 1, 6],
  [60313, 8, 1, 5],
  [60312, 8, 1, 4],
  [60311, 8, 1, 3],
  [60310, 8, 1, 2],
  [60309, 8, 1, 1],
  [60308, 7, 6, 6],
  [60307, 7, 6, 5],
  [60306, 7, 6, 4],
  [60305, 7, 6, 3],
  [60304, 7, 6, 2],
  [60303, 7, 6, 1],
  [60302, 7, 5, 6],
  [60301, 7, 5, 5],
  [60300, 7, 5, 4],
  [60298, 7, 5, 3],
  [60297, 7, 5, 2],
  [60296, 7, 5, 1],
  [60295, 7, 4, 6],
  [60294, 7, 4, 5],
  [60293, 7, 4, 4],
  [60292, 7, 4, 3],
  [60291, 7, 4, 2],
  [60290, 7, 4, 1],
  [60289, 7, 3, 6],
  [60288, 7, 3, 5],
  [60287, 7, 3, 4],
  [60286, 7, 3, 3],
  [60285, 7, 3, 2],
  [60284, 7, 3, 1],
  [60283, 7, 2, 6],
  [60282, 7, 2, 5],
  [60281, 7, 2, 4],
  [60280, 7, 2, 3],
  [60279, 7, 2, 2],
  [60278, 7, 2, 1],
  [60277, 7, 1, 6],
  [60276, 7, 1, 5],
  [60275, 7, 1, 4],
  [60274, 7, 1, 3],
  [60273, 7, 1, 2],
  [60272, 7, 1, 1],
  [60271, 6, 6, 6],
  [60270, 6, 6, 5],
  [60269, 6, 6, 4],
  [60268, 6, 6, 3],
  [60267, 6, 6, 2],
  [60266, 6, 6, 1],
  [60265, 6, 5, 6],
  [60264, 6, 5, 5],
  [60263, 6, 5, 4],
  [60262, 6, 5, 3],
  [60261, 6, 5, 2],
  [60260, 6, 5, 1],
  [60259, 6, 4, 6],
  [60258, 6, 4, 5],
  [60257, 6, 4, 4],
  [60256, 6, 4, 3],
  [60255, 6, 4, 2],
  [60254, 6, 4, 1],
  [60253, 6, 3, 6],
  [60252, 6, 3, 5],
  [60251, 6, 3, 4],
  [60250, 6, 3, 3],
  [60249, 6, 3, 2],
  [60248, 6, 3, 1],
  [60247, 6, 2, 6],
  [60246, 6, 2, 5],
  [60245, 6, 2, 4],
  [60244, 6, 2, 3],
  [60243, 6, 2, 2],
  [60242, 6, 2, 1],
  [60241, 6, 1, 6],
  [60240, 6, 1, 5],
  [60239, 6, 1, 4],
  [60238, 6, 1, 3],
  [60237, 6, 1, 2],
  [60236, 6, 1, 1],
  [60235, 5, 6, 6],
  [60234, 5, 6, 5],
  [60233, 5, 6, 4],
  [60232, 5, 6, 3],
  [60231, 5, 6, 2],
  [60230, 5, 6, 1],
  [60229, 5, 5, 6],
  [60228, 5, 5, 5],
  [60227, 5, 5, 4],
  [60226, 5, 5, 3],
  [60225, 5, 5, 2],
  [60224, 5, 5, 1],
  [60223, 5, 4, 6],
  [60222, 5, 4, 5],
  [60221, 5, 4, 4],
  [60220, 5, 4, 3],
  [60219, 5, 4, 2],
  [60218, 5, 4, 1],
  [60217, 5, 3, 6],
  [60216, 5, 3, 5],
  [60215, 5, 3, 4],
  [60214, 5, 3, 3],
  [60213, 5, 3, 2],
  [60212, 5, 3, 1],
  [60211, 5, 2, 6],
  [60210, 5, 2, 5],
  [60209, 5, 2, 4],
  [60208, 5, 2, 3],
  [60207, 5, 2, 2],
  [60206, 5, 2, 1],
  [60205, 5, 1, 6],
  [60204, 5, 1, 5],
  [60203, 5, 1, 4],
  [60202, 5, 1, 3],
  [60201, 5, 1, 2],
  [60200, 5, 1, 1],
  [60199, 4, 6, 6],
  [60198, 4, 6, 5],
  [60197, 4, 6, 4],
  [60196, 4, 6, 3],
  [60195, 4, 6, 2],
  [60194, 4, 6, 1],
  [60193, 4, 5, 6],
  [60192, 4, 5, 5],
  [60191, 4, 5, 4],
  [60190, 4, 5, 3],
  [60189, 4, 5, 2],
  [60188, 4, 5, 1],
  [60187, 4, 4, 6],
  [60186, 4, 4, 5],
  [60185, 4, 4, 4],
  [60184, 4, 4, 3],
  [60183, 4, 4, 2],
  [60182, 4, 4, 1],
  [60181, 4, 3, 6],
  [60180, 4, 3, 5],
  [60179, 4, 3, 4],
  [60178, 4, 3, 3],
  [60177, 4, 3, 2],
  [60176, 4, 3, 1],
  [60175, 4, 2, 6],
  [60174, 4, 2, 5],
  [60173, 4, 2, 4],
  [60172, 4, 2, 3],
  [60171, 4, 2, 2],
  [60170, 4, 2, 1],
  [60169, 4, 1, 6],
  [60168, 4, 1, 5],
  [60167, 4, 1, 4],
  [60166, 4, 1, 3],
  [60165, 4, 1, 2],
  [60164, 4, 1, 1],
  [60163, 3, 6, 6],
  [60162, 3, 6, 5],
  [60161, 3, 6, 4],
  [60160, 3, 6, 3],
  [60159, 3, 6, 2],
  [60158, 3, 6, 1],
  [60157, 3, 5, 6],
  [60156, 3, 5, 5],
  [60155, 3, 5, 4],
  [60154, 3, 5, 3],
  [60153, 3, 5, 2],
  [60152, 3, 5, 1],
  [60151, 3, 4, 6],
  [60150, 3, 4, 5],
  [60149, 3, 4, 4],
  [60148, 3, 4, 3],
  [60147, 3, 4, 2],
  [60146, 3, 4, 1],
  [60145, 3, 3, 6],
  [60144, 3, 3, 5],
  [60143, 3, 3, 4],
  [60142, 3, 3, 3],
  [60141, 3, 3, 2],
  [60140, 3, 3, 1],
  [60139, 3, 2, 6],
  [60138, 3, 2, 5],
  [60137, 3, 2, 4],
  [60136, 3, 2, 3],
  [60135, 3, 2, 2],
  [60134, 3, 2, 1],
  [60133, 3, 1, 6],
  [60132, 3, 1, 5],
  [60131, 3, 1, 4],
  [60130, 3, 1, 3],
  [60129, 3, 1, 2],
  [60128, 3, 1, 1],
  [60127, 2, 6, 6],
  [60126, 2, 6, 5],
  [60125, 2, 6, 4],
  [60124, 2, 6, 3],
  [60123, 2, 6, 2],
  [60122, 2, 6, 1],
  [60121, 2, 5, 6],
  [60120, 2, 5, 5],
  [60119, 2, 5, 4],
  [60118, 2, 5, 3],
  [60117, 2, 5, 2],
  [60116, 2, 5, 1],
  [60115, 2, 4, 6],
  [60114, 2, 4, 5],
  [60113, 2, 4, 4],
  [60112, 2, 4, 3],
  [60111, 2, 4, 2],
  [60110, 2, 4, 1],
  [60109, 2, 3, 6],
  [60108, 2, 3, 5],
  [60107, 2, 3, 4],
  [60106, 2, 3, 3],
  [60105, 2, 3, 2],
  [60104, 2, 3, 1],
  [60103, 2, 2, 6],
  [60102, 2, 2, 5],
  [60101, 2, 2, 4],
  [60100, 2, 2, 3],
  [60099, 2, 2, 2],
  [60098, 2, 2, 1],
  [60097, 2, 1, 6],
  [60096, 2, 1, 5],
  [60095, 2, 1, 4],
  [60094, 2, 1, 3],
  [60093, 2, 1, 2],
  [60092, 2, 1, 1],
  [60091, 1, 6, 6],
  [60090, 1, 6, 5],
  [60089, 1, 6, 4],
  [60088, 1, 6, 3],
  [60087, 1, 6, 2],
  [60086, 1, 6, 1],
  [60085, 1, 5, 6],
  [60084, 1, 5, 5],
  [60083, 1, 5, 4],
  [60082, 1, 5, 3],
  [60081, 1, 5, 2],
  [60080, 1, 5, 1],
  [60079, 1, 4, 6],
  [60078, 1, 4, 5],
  [60077, 1, 4, 4],
  [60076, 1, 4, 3],
  [60075, 1, 4, 2],
  [60074, 1, 4, 1],
  [60073, 1, 3, 6],
  [60072, 1, 3, 5],
  [60071, 1, 3, 4],
  [60070, 1, 3, 3],
  [60069, 1, 3, 2],
  [60068, 1, 3, 1],
  [60067, 1, 2, 6],
  [60066, 1, 2, 5],
  [60065, 1, 2, 4],
  [60064, 1, 2, 3],
  [60063, 1, 2, 2],
  [60062, 1, 2, 1],
  [60061, 1, 1, 6],
  [60060, 1, 1, 5],
  [60059, 1, 1, 4],
  [60058, 1, 1, 3],
  [60057, 1, 1, 2],
  [60056, 1, 1, 1],
  [60055, 0, 6, 6],
  [60054, 0, 6, 5],
  [60053, 0, 6, 4],
  [60052, 0, 6, 3],
  [60051, 0, 6, 2],
  [60050, 0, 6, 1],
  [60049, 0, 5, 6],
  [60048, 0, 5, 5],
  [60047, 0, 5, 4],
  [60046, 0, 5, 3],
  [60045, 0, 5, 2],
  [60044, 0, 5, 1],
  [60043, 0, 4, 6],
  [60042, 0, 4, 5],
  [60041, 0, 4, 4],
  [60040, 0, 4, 3],
  [60039, 0, 4, 2],
  [60038, 0, 4, 1],
  [60037, 0, 3, 6],
  [60036, 0, 3, 5],
  [60035, 0, 3, 4],
  [60034, 0, 3, 3],
  [60033, 0, 3, 2],
  [60032, 0, 3, 1],
  [60031, 0, 2, 6],
  [60030, 0, 2, 5],
  [60029, 0, 2, 4],
  [60028, 0, 2, 3],
  [60027, 0, 2, 2],
  [60026, 0, 2, 1],
  [60025, 0, 1, 6],
  [60024, 0, 1, 5],
  [60023, 0, 1, 4],
  [60022, 0, 1, 3],
  [60021, 0, 1, 2],
  [60020, 0, 1, 1],
  [60019, -1, 6, 6],
  [60018, -1, 6, 5],
  [60017, -1, 6, 4],
  [60016, -1, 6, 3],
  [60015, -1, 6, 2],
  [60014, -1, 6, 1],
  [60013, -1, 5, 6],
  [60012, -1, 5, 5],
  [60011, -1, 5, 4],
  [60010, -1, 5, 3],
  [60009, -1, 5, 2],
  [60008, -1, 5, 1],
  [60007, -1, 4, 6],
  [60006, -1, 4, 5],
  [60005, -1, 4, 4],
  [60004, -1, 4, 3],
  [60003, -1, 4, 2],
  [60002, -1, 4, 1],
  [60001, -1, 3, 6],
  [60000, -1, 3, 5],
  [59999, -1, 3, 4],
  [59998, -1, 3, 3],
  [59997, -1, 3, 2],
  [59996, -1, 3, 1],
  [59995, -1, 2, 6],
  [59994, -1, 2, 5],
  [59993, -1, 2, 4],
  [59992, -1, 2, 3],
  [59991, -1, 2, 2],
  [59990, -1, 2, 1],
  [59989, -1, 1, 6],
  [59988, -1, 1, 5],
  [59987, -1, 1, 4],
  [59986, -1, 1, 3],
  [59985, -1, 1, 2],
  [59984, -1, 1, 1],
  [59983, -2, 6, 6],
  [59982, -2, 6, 5],
  [59981, -2, 6, 4],
  [59980, -2, 6, 3],
  [59979, -2, 6, 2],
  [59978, -2, 6, 1],
  [59977, -2, 5, 6],
  [59976, -2, 5, 5],
  [59975, -2, 5, 4],
  [59974, -2, 5, 3],
  [59973, -2, 5, 2],
  [59972, -2, 5, 1],
  [59971, -2, 4, 6],
  [59970, -2, 4, 5],
  [59969, -2, 4, 4],
  [59968, -2, 4, 3],
  [59967, -2, 4, 2],
  [59966, -2, 4, 1],
  [59965, -2, 3, 6],
  [59964, -2, 3, 5],
  [59963, -2, 3, 4],
  [59962, -2, 3, 3],
  [59961, -2, 3, 2],
  [59960, -2, 3, 1],
  [59959, -2, 2, 6],
  [59958, -2, 2, 5],
  [59957, -2, 2, 4],
  [59956, -2, 2, 3],
  [59955, -2, 2, 2],
  [59954, -2, 2, 1],
  [59953, -2, 1, 6],
  [59952, -2, 1, 5],
  [59951, -2, 1, 4],
  [59950, -2, 1, 3],
  [59949, -2, 1, 2],
  [59948, -2, 1, 1],
  [59947, -3, 6, 6],
  [59946, -3, 6, 5],
  [59945, -3, 6, 4],
  [59944, -3, 6, 3],
  [59943, -3, 6, 2],
  [59942, -3, 6, 1],
  [59941, -3, 5, 6],
  [59940, -3, 5, 5],
  [59939, -3, 5, 4],
  [59938, -3, 5, 3],
  [59937, -3, 5, 2],
  [59936, -3, 5, 1],
  [59935, -3, 4, 6],
  [59934, -3, 4, 5],
  [59933, -3, 4, 4],
  [59932, -3, 4, 3],
  [59931, -3, 4, 2],
  [59930, -3, 4, 1],
  [59928, -3, 3, 6],
  [59927, -3, 3, 5],
  [59926, -3, 3, 4],
  [59925, -3, 3, 3],
  [59924, -3, 3, 2],
  [59923, -3, 3, 1],
  [59922, -3, 2, 6],
  [59921, -3, 2, 5],
  [59920, -3, 2, 4],
  [59919, -3, 2, 3],
  [59918, -3, 2, 2],
  [59917, -3, 2, 1],
  [59916, -3, 1, 6],
  [59915, -3, 1, 5],
  [59914, -3, 1, 4],
  [59913, -3, 1, 3],
  [59912, -3, 1, 2],
  [59911, -3, 1, 1],
];

module.exports = {
  RPLANET_ELEMENTS,
  RPLANET_TEMPLATES,
};
