import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Page from "../Page";
import SnakeLoader from "../SnakeLoader";
import axios from "axios";
import useTokenPrices from "../Utilities/useTokenPrices";
import * as AA from "../Utilities/AA";

const LandLeaderboard = () => {
  const columns = {
    wallet: "wallet",
    locationSwineland: "locationSwineland",
    locationBoarvallis: "locationBoarvallis",
    raritycommon: "raritycommon",
    rarityuncommon: "rarityuncommon",
    rarityrare: "rarityrare",
    rarityepic: "rarityepic",
    raritylegendary: "raritylegendary",
    raritymythic: "raritymythic",
    mineralWecanite: "mineralWecanite",
    mineralWaxon: "mineralWaxon",
    mineralEnefterium: "mineralEnefterium",
    mineralCaponium: "mineralCaponium",
    rateWecanite: "rateWecanite",
    rateWaxon: "rateWaxon",
    rateEnefterium: "rateEnefterium",
    rateCaponium: "rateCaponium",
    rateTotal: "rateTotal",
    waxRateWecanite: "waxRateWecanite",
    waxRateWaxon: "waxRateWaxon",
    waxRateEnefterium: "waxRateEnefterium",
    waxRateCaponium: "waxRateCaponium",
    waxRateTotal: "waxRateTotal",
    levels: "levels",
    maxLevel: "maxLevel",
  };

  const [tokenPrices, getTokenPrice] = useTokenPrices();
  const [leaders, setLeaders] = useState([]);
  const [landCount, setLandCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentSort, setCurrentSort] = useState(null);

  useEffect(() => {
    setLandCount(0);
    const getMultiplePages = async (url, limit) => {
      let result = [];
      const more = async (pageNumber, limit) => {
        const response = await axios.get(
          AA.API +
            "atomicassets/v1/" +
            url +
            "&page=" +
            pageNumber +
            "&limit=" +
            limit
        );
        const data = response.data.data;
        result = [...result, ...data];
        setLandCount((old) => old + data.length);
        if (data.length === limit) {
          await more(pageNumber + 1, limit);
        }
      };
      await more(1, limit);
      return result;
    };

    const run = async () => {
      if (tokenPrices) {
        setLoading(true);
        const tp = {
          Wecanite: getTokenPrice("WECANWAX"),
          Waxon: getTokenPrice("WAXONWAX"),
          Enefterium: getTokenPrice("ENEFTWAX"),
          Caponium: getTokenPrice("CAPONWAX"),
        };
        let r1 = await getMultiplePages(
          "assets?collection_name=rplanet&schema_name=lands",
          1000
        );
        let r2 = await getMultiplePages(
          "assets?collection_name=rplanet&schema_name=lands2",
          1000
        );
        const a = r1.concat(r2);
        const ldrs = [];
        a.forEach((l) => {
          const land = {
            id: l.asset_id,
            owner: l.owner,
            location: l.template.immutable_data.location,
            mineral: l.name,
            level: l.mutable_data.level ? l.mutable_data.level : 0,
            rarity: l.template.immutable_data.rarity,
            rate: getRate(
              l.mutable_data.level,
              l.template.immutable_data.rarity
            ),
          };
          const ii = ldrs.findIndex((leader) => leader.wallet === land.owner);
          if (ii >= 0) {
            ldrs[ii].lands.push(land);
          } else {
            ldrs.push({ wallet: land.owner, lands: [land] });
          }
        });

        let data = {
          locationSwineland: 0,
          locationBoarvallis: 0,
          raritycommon: 0,
          rarityuncommon: 0,
          rarityrare: 0,
          rarityepic: 0,
          raritylegendary: 0,
          raritymythic: 0,
          mineralWecanite: 0,
          mineralWaxon: 0,
          mineralEnefterium: 0,
          mineralCaponium: 0,
          rateWecanite: 0,
          rateWaxon: 0,
          rateEnefterium: 0,
          rateCaponium: 0,
          rateTotal: 0,
          waxRateWecanite: 0,
          waxRateWaxon: 0,
          waxRateEnefterium: 0,
          waxRateCaponium: 0,
          waxRateTotal: 0,
          levels: 0,
          maxLevel: 0,
        };
        const tots = {};
        Object.keys(data).forEach((key) => {
          tots[key] = 0;
        });
        ldrs.forEach((leader) => {
          data = {
            locationSwineland: 0,
            locationBoarvallis: 0,
            raritycommon: 0,
            rarityuncommon: 0,
            rarityrare: 0,
            rarityepic: 0,
            raritylegendary: 0,
            raritymythic: 0,
            mineralWecanite: 0,
            mineralWaxon: 0,
            mineralEnefterium: 0,
            mineralCaponium: 0,
            rateWecanite: 0,
            rateWaxon: 0,
            rateEnefterium: 0,
            rateCaponium: 0,
            rateTotal: 0,
            waxRateWecanite: 0,
            waxRateWaxon: 0,
            waxRateEnefterium: 0,
            waxRateCaponium: 0,
            waxRateTotal: 0,
            levels: 0,
            maxLevel: 0,
          };
          leader.lands.forEach((land) => {
            data["location" + land.location]++;
            data["rarity" + land.rarity]++;
            data["mineral" + land.mineral]++;
            data["rate" + land.mineral] += land.rate;
            data.rateTotal += land.rate;
            data["waxRate" + land.mineral] += land.rate * tp[land.mineral];
            data.levels += land.level;
            data.maxLevel = Math.max(data.maxLevel, land.level);
          });
          data.waxRateTotal =
            data.waxRateWecanite +
            data.waxRateWaxon +
            data.waxRateEnefterium +
            data.waxRateCaponium;
          leader.data = data;
          delete leader.lands;
          Object.entries(data).forEach(([key, value]) => {
            tots[key] += value;
          });
        });

        tots.maxLevel = 200;
        ldrs.push({ wallet: "TOTALS", data: tots });
        setLeaders(
          ldrs.sort(
            (a, b) =>
              b.data[columns.waxRateTotal] - a.data[columns.waxRateTotal]
          )
        );
        setLoading(false);
      }
    };
    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenPrices, getTokenPrice]);

  const getRate = (level, rarity) => {
    let rate = 100;
    if (rarity === "uncommon") rate = 120;
    if (rarity === "rare") rate = 140;
    if (rarity === "epic") rate = 160;
    if (rarity === "legendary") rate = 180;
    if (rarity === "mythic") rate = 200;
    return rate * Math.pow(1.02, level ? level : 0);
  };

  const renderLandLeaderboard = () => {
    return (
      <table
        style={{
          margin: "auto",
          textAlign: "center",
          borderSpacing: "16px 6px",
          fontSize: "80%",
        }}
      >
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th style={{ borderBottom: "1px solid white" }} colSpan="2">
              Location
            </th>
            <th style={{ borderBottom: "1px solid white" }} colSpan="6">
              Rarity
            </th>
            <th style={{ borderBottom: "1px solid white" }} colSpan="4">
              Mineral
            </th>
            <th style={{ borderBottom: "1px solid white" }} colSpan="2">
              Levels
            </th>
            <th style={{ borderBottom: "1px solid white" }} colSpan="5">
              Mineral Rates (hourly)
            </th>
            <th style={{ borderBottom: "1px solid white" }} colSpan="5">
              Wax Rates (hourly)
            </th>
          </tr>
          <tr>
            <th></th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.wallet)}
            >
              Owner
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.locationSwineland)}
            >
              S
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.locationBoarvallis)}
            >
              B
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.raritycommon)}
            >
              C
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.rarityuncommon)}
            >
              U
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.rarityrare)}
            >
              R
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.rarityepic)}
            >
              E
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.raritylegendary)}
            >
              L
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.raritymythic)}
            >
              M
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.mineralWecanite)}
            >
              We
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.mineralWaxon)}
            >
              Wa
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.mineralEnefterium)}
            >
              En
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.mineralCaponium)}
            >
              Ca
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.maxLevel)}
            >
              Max
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.levels)}
            >
              Total
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.rateWecanite)}
            >
              We
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.rateWaxon)}
            >
              Wa
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.rateEnefterium)}
            >
              En
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.rateCaponium)}
            >
              Ca
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.rateTotal)}
            >
              Total
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.waxRateWecanite)}
            >
              We
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.waxRateWaxon)}
            >
              Wa
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.waxRateEnefterium)}
            >
              En
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.waxRateCaponium)}
            >
              Ca
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => sortTable(columns.waxRateTotal)}
            >
              Total
            </th>
          </tr>
        </thead>
        <tbody style={{ textAlign: "right" }}>
          {leaders
            .sort((a, b) => (a.wallet === "TOTALS" ? -1 : 1))
            .map((leader, i) => {
              return (
                <tr key={leader.wallet}>
                  <td>{i > 0 ? i : ""}</td>
                  <td>{leader.wallet}</td>
                  <td>{leader.data.locationSwineland}</td>
                  <td>{leader.data.locationBoarvallis}</td>
                  <td>{leader.data.raritycommon}</td>
                  <td>{leader.data.rarityuncommon}</td>
                  <td>{leader.data.rarityrare}</td>
                  <td>{leader.data.rarityepic}</td>
                  <td>{leader.data.raritylegendary}</td>
                  <td>{leader.data.raritymythic}</td>
                  <td>{leader.data.mineralWecanite}</td>
                  <td>{leader.data.mineralWaxon}</td>
                  <td>{leader.data.mineralEnefterium}</td>
                  <td>{leader.data.mineralCaponium}</td>
                  <td>{leader.data.maxLevel}</td>
                  <td>{leader.data.levels}</td>
                  <td>{leader.data.rateWecanite.toFixed(1)}</td>
                  <td>{leader.data.rateWaxon.toFixed(1)}</td>
                  <td>{leader.data.rateEnefterium.toFixed(1)}</td>
                  <td>{leader.data.rateCaponium.toFixed(1)}</td>
                  <td>{leader.data.rateTotal.toFixed(1)}</td>
                  <td>{leader.data.waxRateWecanite.toFixed(2)}</td>
                  <td>{leader.data.waxRateWaxon.toFixed(2)}</td>
                  <td>{leader.data.waxRateEnefterium.toFixed(2)}</td>
                  <td>{leader.data.waxRateCaponium.toFixed(2)}</td>
                  <td>{leader.data.waxRateTotal.toFixed(3)}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  };

  const sortTable = (col) => {
    if (currentSort === col) {
      setCurrentSort(null);
      setLeaders((old) => {
        return [...old].reverse();
      });
    } else {
      setCurrentSort(col);
      setLeaders((old) => {
        if ([columns.wallet].includes(col)) {
          return [...old.sort((a, b) => a.wallet.localeCompare(b.wallet))];
        } else {
          return [...old.sort((a, b) => b.data[col] - a.data[col])];
        }
      });
    }
  };

  return (
    <Page title="Land Leaderboard">
      <h2>Land Leaderboard</h2>
      <Link to="/rplanet/land/list">Land List</Link>
      &nbsp;&nbsp;
      <Link to="/rplanet/land/calculator">Land Calculator</Link>
      <br />
      <br />
      {loading || leaders.length === 0 ? (
        <div>
          <div>{landCount}</div>
          <SnakeLoader />
        </div>
      ) : (
        renderLandLeaderboard()
      )}
      <br />
      <br />
    </Page>
  );
};

export default LandLeaderboard;
