import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBmyeyqBPggNBLmptBE8Uf9j4fG1xM0B2U",
  authDomain: "nf-tools-1234.firebaseapp.com",
  projectId: "nf-tools-1234",
  storageBucket: "nf-tools-1234.appspot.com",
  messagingSenderId: "61286351951",
  appId: "1:61286351951:web:7ac4fc39e780810385d63a",
  databaseURL: "https://nf-tools-1234-default-rtdb.firebaseio.com",
};

firebase.initializeApp(firebaseConfig);

export default firebase;