import { JsonRpc } from "eosjs";

const rpc = new JsonRpc("https://wax.greymass.com/", { fetch });

// const rpc = () => {
//   const a = ["https://api.wax.alohaeos.com/"];
//   const r = new JsonRpc(a[0], { fetch });
//   try {
//     await r.get_table_rows({
//       code: "novarallyapp",
//       scope: "van",
//       table: "playerraces",
//       json: true,
//       limit: 1,
//     });
//     return r;
//   } catch {
//     return new JsonRpc("https://api.wax.alohaeos.com/", { fetch });
//   }
// }

export { rpc };
