import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Page from "../Page";
import axios from "axios";
import WalletInput from "../Utilities/WalletInput";
import SnakeLoader from "../SnakeLoader";
import * as AA from "../Utilities/AA";

const AssetIds = (props) => {
  const history = useHistory();
  const [wallet, setWallet] = useState(props.match.params.wallet || "");
  const [allCollections, setAllCollections] = useState([]);
  const [currentCollection, setCurrentCollection] = useState(
    props.match.params.collection || ""
  );
  const [schemas, setSchemas] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCurrentCollection("");
    setSchemas([]);
  }, [wallet]);

  useEffect(() => {
    if (wallet && wallet.trim()) {
      if (currentCollection && currentCollection.trim()) {
        history.push("/assets/" + wallet + "/" + currentCollection);
      } else {
        history.push("/assets/" + wallet);
      }
    }
  }, [wallet, currentCollection, history]);

  useEffect(() => {
    const run = async () => {
      if (wallet && wallet.length > 0) {
        setLoading(true);
        const r = await axios.get(
          AA.API + "atomicassets/v1/accounts/" + wallet
        );
        const colls = r.data.data.collections.map(
          (coll) => coll.collection.collection_name
        );
        setAllCollections(colls.sort());
        setLoading(false);
      }
    };
    run();
  }, [wallet]);

  useEffect(() => {
    const run = async () => {
      if (currentCollection && currentCollection.length > 0) {
        setLoading(true);
        const r = await axios.get(
          AA.API +
            "atomicassets/v1/schemas?page=1&limit=1000&order=desc&sort=created&collection_name=" +
            currentCollection
        );
        const sch = {};
        const a = r.data.data.sort((a, b) =>
          a.schema_name.localeCompare(b.schema_name)
        );
        a.forEach((s) => {
          sch[s.schema_name] = true;
        });
        setSchemas(sch);
        setLoading(false);
      }
    };
    run();
  }, [currentCollection]);

  const toggleChecked = (sch) => {
    setSchemas((old) => {
      return { ...old, [sch]: !old[sch] };
    });
  };

  const toggleSelectAll = () => {
    setSchemas((old) => {
      const obj = { ...old };
      Object.keys(old).forEach((k) => {
        if (selectAll) {
          obj[k] = true;
        } else {
          obj[k] = false;
        }
      });
      return obj;
    });
    setSelectAll((old) => !old);
  };

  return (
    <Page title="Asset Data">
      <h2>Asset Data</h2>
      <br />
      {loading ? (
        <SnakeLoader />
      ) : (
        <div style={{ minWidth: "420px", margin: "auto" }}>
          <WalletInput
            wallet={wallet}
            setWallet={setWallet}
            loading={loading}
            buttonText="GIMME THE DATA"
          />
          <br />
          <div>
            {allCollections.length > 0 ? (
              <div>
                <select
                  value={currentCollection}
                  onChange={(e) => setCurrentCollection(e.target.value)}
                >
                  <option value="">(collection)</option>
                  {allCollections.map((coll) => {
                    return (
                      <option key={"collection-" + coll} value={coll}>
                        {coll}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : null}
          </div>
          <br />
          <div style={{ width: "120px", margin: "auto", fontSize: "80%" }}>
            <button
              type="button"
              onClick={() => toggleSelectAll()}
              style={{ marginBottom: "4px" }}
            >
              {selectAll ? "Select All" : "Select None"}
            </button>
            <div style={{ textAlign: "left" }}>
              {Object.entries(schemas).length > 0
                ? Object.entries(schemas).map(([schema, checked]) => {
                    return (
                      <div key={currentCollection + "-" + schema}>
                        <input
                          type="checkbox"
                          checked={checked}
                          onChange={() => toggleChecked(schema)}
                          style={{ cursor: "pointer" }}
                        />
                        <span
                          onClick={() => toggleChecked(schema)}
                          style={{ cursor: "pointer" }}
                        >
                          {schema}
                        </span>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      )}
      <br />
      <br />
      <br />
      <br />
    </Page>
  );
};

export default AssetIds;
