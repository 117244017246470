import React, { useEffect } from "react";
import firebase from "../Firebase";
import Page from "../Page";
//import axios from "axios";

const PrivateAetherLeaderboardUpdate = () => {
  if (process.env.NODE_ENV === "development") {
    firebase.functions().useEmulator("localhost", 5001);
  }

  useEffect(() => {
    let getTopAetherWallets = firebase
      .functions()
      .httpsCallable("getTopAetherWallets");
    let updateAetherPlayerDataExternal = firebase
      .functions()
      .httpsCallable("updateAetherPlayerDataExternal");
    let getAetherPlayerData = firebase
      .functions()
      .httpsCallable("getAetherPlayerData");

    getTopAetherWallets().then((response) => {
      console.log(response.data);

      const getSetPlayer = async (wallet, i) => {
        try {
          const result = await getAetherPlayerData({
            wallet: wallet,
          });
          const aether = result.data.aether;
          const aehr = result.data.aehr;
          const unclaimed = result.data.unclaimed;
          const ft = result.data.ft;
          const ftTotal = result.data.ftTotal;
          const total = result.data.total;
          const data = {
            wallet: wallet,
            aether: aether,
            aehr: aehr,
            unclaimed: unclaimed,
            ft: ft,
            ftTotal: ftTotal,
            total: total,
          };
          try {
            await updateAetherPlayerDataExternal(data);
          } catch (e) {
            console.log("update failed");
            console.log("data", data);
            console.log(e);
          }
          console.log(i + 1, data);
        } catch (e) {
          console.log("getting data failed");
          console.log("data", wallet);
          console.log(e);
        }
      };

      const getData = async (wallets) => {
        let numWallets = wallets.length;
        let currentBatch = [];
        for (let i = 1; i <= numWallets; i++) {
          currentBatch.push(getSetPlayer(wallets[i - 1], i));
          if (i % 20 === 0 || i === numWallets) {
            try {
              const r = await Promise.allSettled(currentBatch);
              console.log(r);
            } catch (e) {
              console.log(e);
            }
            currentBatch = [];
          }
        }
      };
      getData(response.data);
    });
  }, []);

  return (
    <Page title="Aether Update">
      <br />
      <br />
    </Page>
  );
};

export default PrivateAetherLeaderboardUpdate;
