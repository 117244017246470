import React, { useState, useEffect, useContext } from "react";
import Page from "../Page";
import SnakeLoader from "../SnakeLoader";
import {
  NovaComboTableData,
  NovaDateTableData,
  NovaRaceIdTableData,
  NovaTimeTableData,
  NovaWalletTableData,
} from "./NovaDisplay";
import { NovaContext } from "./NovaContextProvider";
import axios from "axios";

const NovaTimeTrials = () => {
  const novaContext = useContext(NovaContext);

  const [loading, setLoading] = useState(false);
  const [topRaces, setTopRaces] = useState([]);

  const START_DATE = 1666483200;
  const END_DATE = START_DATE + 604800;

  useEffect(() => {
    if (novaContext.templates) {
      const run = async () => {
        setLoading(true);

        const getRaces = async (pageNum = 0, result = []) => {
          const r = await axios.get(
            "https://nr-api.win-win.software/api/v1/races/?currentAccount=van&isCurrentOnly=false&page=" +
              pageNum +
              "&size=200"
          );
          const d = new Date(r.data.data.at(-1).startedAt).getTime() / 1000;
          if (d > START_DATE) {
            result = result.concat(r.data.data);
            const pn = pageNum + 1;
            return getRaces(pn, result);
          } else {
            result = result.concat(r.data.data);
            return result;
          }
        };

        const a = await getRaces();

        const result = a.filter(
          (x) =>
            new Date(x.startedAt).getTime() / 1000 > START_DATE &&
            new Date(x.startedAt).getTime() / 1000 < END_DATE &&
            x.winner.timeMs > 0
        );

        result.sort((a, b) => {
          return a.winner.timeMs - b.winner.timeMs;
        });

        const getAssetData = async (race_time, winner, race_id) => {
          const oldRace = {
            vehicle_asset_id: winner.vehicleAssetId,
            driver1_asset_id: winner.driver1AssetId,
            driver2_asset_id: winner.driver2AssetId,
          };

          const combo = await novaContext.getComboData(oldRace);

          const result = {
            race_id: race_id,
            race_time: race_time,
            winner: winner.accountName,
            time: winner.timeMs,
            ...combo,
          };
          return result;
        };

        const tr = [];
        const found = {
          common: false,
          uncommon: false,
          rare: false,
          classic: false,
        };

        for (let i = 0; i < 100; i++) {
          if (
            result[i] &&
            (!found.common || !found.uncommon || !found.rare || !found.classic)
          ) {
            const a = await getAssetData(
              new Date(result[i].startedAt).getTime() / 1000,
              result[i].winner,
              result[i].id
            );
            if (!found[a.vehicleRarity.toLowerCase()]) {
              tr.push(a);
              found[a.vehicleRarity.toLowerCase()] = true;
            }
          } else {
            break;
          }
        }

        if (result.at(-1)) {
          tr.push(
            await getAssetData(
              new Date(result.at(-1).startedAt).getTime() / 1000,
              result.at(-1).winner,
              result.at(-1).id
            )
          );
        }

        setTopRaces(tr);

        setLoading(false);
      };

      run();
    }
  }, [END_DATE, START_DATE, novaContext]);

  const renderTopRaces = () => {
    return topRaces.length > 0 ? (
      <table
        style={{
          margin: "auto",
          textAlign: "left",
          borderSpacing: "12px 4px",
          fontSize: "80%",
        }}
      >
        <thead>
          <tr>
            <th>ID</th>
            <th>DATE</th>
            <th>WINNER</th>
            <th>TIME</th>
            <th>VEHICLE</th>
            <th>DRIVER 1</th>
            <th>DRIVER 2</th>
          </tr>
        </thead>
        <tbody>
          {topRaces.map((race, i) => {
            return (
              <tr key={"toprace" + i}>
                <NovaRaceIdTableData id={race.race_id} />
                <NovaDateTableData date={race.race_time} />
                <NovaWalletTableData wallet={race.winner} />
                <NovaTimeTableData time={race.time} />
                <NovaComboTableData combo={race} />
              </tr>
            );
          })}
        </tbody>
      </table>
    ) : null;
  };

  return (
    <Page title="Nova Rally Time Trials">
      <h2>Time Trials</h2>
      <br />
      Start: {new Date(START_DATE * 1000).toUTCString()}
      <br />
      <br />
      End: {new Date(END_DATE * 1000).toUTCString()}
      <br />
      <br />
      <br />
      {loading || !topRaces ? <SnakeLoader /> : renderTopRaces()}
      <br />
    </Page>
  );
};

export default NovaTimeTrials;
