import React from "react";

const DeathRallyTotals = ({ totals }) => {
  return (
    <table
      style={{
        margin: "auto",
        borderSpacing: "12px 4px",
      }}
    >
      <tbody>
        <tr>
          <td style={{ verticalAlign: "top" }}>
            <table
              style={{
                textAlign: "left",
                borderSpacing: "12px 4px",
                fontSize: "60%",
              }}
            >
              <tbody>
                {Object.entries(totals.vehicles)
                  .sort(([ak, av], [bk, bv]) => {
                    return bv - av;
                  })
                  .map(([k, v]) => {
                    return (
                      <tr key={"total" + k}>
                        <td>{k}</td>
                        <td style={{ textAlign: "right" }}>{v}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </td>
          <td>
            <table
              style={{
                textAlign: "left",
                borderSpacing: "12px 4px",
                fontSize: "60%",
              }}
            >
              <tbody>
                {Object.entries(totals.drivers)
                  .sort(([ak, av], [bk, bv]) => {
                    return bv - av;
                  })
                  .map(([k, v]) => {
                    if (k === "Otis Carlton Daniels") k = "Otis Carlton";
                    return (
                      <tr key={"total" + k}>
                        <td>{k}</td>
                        <td style={{ textAlign: "right" }}>{v}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default DeathRallyTotals;
