import React, { useState, useEffect } from "react";
import Page from "../Page";
import { rpc } from "../Utilities/RPC";
import {
  NovaPositionTableData,
  NovaRaceIdTableData,
  NovaTimeTableData,
} from "./NovaDisplay";

const NovaPvpStats = () => {
  const [wallet1, setWallet1] = useState("");
  const [wallet2, setWallet2] = useState("");
  const [walletInput1, setWalletInput1] = useState("");
  const [walletInput2, setWalletInput2] = useState("");
  const [raceData, setRaceData] = useState([]);
  const [overallData, setOverallData] = useState({});

  useEffect(() => {
    const run = async () => {
      if (wallet1 && wallet2 && wallet1 !== wallet2) {
        const result = { [wallet1]: [], [wallet2]: [] };
        const more = async (wallet, lowerBound) => {
          const r = await rpc.get_table_rows({
            code: "novarallyapp",
            scope: wallet,
            table: "playerraces",
            json: true,
            limit: 1000,
            lower_bound: lowerBound,
            upper_bound: "",
            reverse: false,
            show_payer: false,
          });
          if (r.rows.length > 0) {
            result[wallet].push(...r.rows);
            const lb = r.rows[r.rows.length - 1].race_id + 1;
            return more(wallet, lb);
          } else {
            return result[wallet];
          }
        };
        const data1 = await more(wallet1, "");
        const data2 = await more(wallet2, "");
        const rd = [];

        let p1wins = 0;
        data1.forEach((x) => {
          data2.forEach((y) => {
            if (x.race_id === y.race_id) {
              let winner = "";
              if (x.position < y.position) {
                winner = wallet1;
                p1wins++;
              } else winner = wallet2;
              rd.push({ player1: x, player2: y, winner: winner });
            }
          });
        });

        const p1perc = p1wins / rd.length;
        let ow = "";
        let perc = p1perc;
        if (p1perc < 0.5) {
          ow = wallet2;
          perc = 1 - p1perc;
        } else if (p1perc > 0.5) {
          ow = wallet1;
        } else ow = "TIE";
        setOverallData({ winner: ow, perc: perc });

        setRaceData(rd.reverse());
      }
    };

    run();
  }, [wallet1, wallet2]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (walletInput1.trim()) {
      setWallet1(walletInput1.trim());
    }
    if (walletInput2.trim()) {
      setWallet2(walletInput2.trim());
    }
  };

  const handleWalletChange1 = (e) => {
    setWalletInput1(e.target.value.toLowerCase().trim());
  };

  const handleWalletChange2 = (e) => {
    setWalletInput2(e.target.value.toLowerCase().trim());
  };

  const renderOverallData = () => {
    return wallet1 && wallet2 && raceData && raceData.length > 0 ? (
      <table
        style={{
          margin: "auto",
          textAlign: "center",
          borderSpacing: "12px 4px",
          fontSize: "120%",
        }}
      >
        <tbody>
          <tr>
            <td>RACES</td>
            <td>WINNER</td>
            <td>WIN RATE</td>
          </tr>
          <tr>
            <td>{raceData.length}</td>
            <td>{overallData.winner}</td>
            <td>{(100 * overallData.perc).toFixed(2)}%</td>
          </tr>
        </tbody>
      </table>
    ) : null;
  };

  const renderRaceList = () => {
    if (wallet1 && wallet2) {
      if (raceData && raceData.length > 0) {
        return (
          <table
            style={{
              margin: "auto",
              textAlign: "center",
              borderSpacing: "12px 4px",
              fontSize: "80%",
            }}
          >
            <thead>
              <tr>
                <td>ID</td>
                <td>P1 Time</td>
                <td>P1</td>
                <td>Winner</td>
                <td>P2</td>
                <td>P2 Time</td>
              </tr>
            </thead>
            <tbody>
              {raceData.map((race) => {
                return (
                  <tr key={race.player1.race_id}>
                    <NovaRaceIdTableData id={race.player1.race_id} />
                    <NovaTimeTableData time={race.player1.total_time_ts} />
                    <NovaPositionTableData position={race.player1.position} />
                    <td>{race.winner}</td>
                    <NovaPositionTableData position={race.player2.position} />
                    <NovaTimeTableData time={race.player2.total_time_ts} />
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      } else {
        return <div>These players never raced each other.</div>;
      }
    } else {
      return null;
    }
  };

  return (
    <Page title="Nova Rally PVP Stats">
      <h2>PVP Stats</h2>
      <br />
      <div>
        <form onSubmit={handleSubmit}>
          <input
            size="12"
            type="text"
            style={{
              fontFamily: "Cousine",
              textAlign: "center",
              textTransform: "lowercase",
            }}
            name="walletInput1"
            onChange={handleWalletChange1}
            value={walletInput1}
          />
          &nbsp;
          <input
            size="12"
            type="text"
            style={{
              fontFamily: "Cousine",
              textAlign: "center",
              textTransform: "lowercase",
            }}
            name="walletInput2"
            onChange={handleWalletChange2}
            value={walletInput2}
          />
          <br />
          <br />
          <input
            type="submit"
            style={{ fontFamily: "Cousine", fontWeight: "bold" }}
            value={"BATTLE STATS"}
          />
        </form>
      </div>
      <br />
      {renderOverallData()}
      <br />
      {renderRaceList()}
      <br />
      <br />
      <br />
    </Page>
  );
};

export default NovaPvpStats;
