import React, { useState, useEffect } from "react";
import Page from "../Page";
import axios from "axios";
import SnakeLoader from "../SnakeLoader";
import * as AA from "../Utilities/AA";

const NovaAssetData = () => {
  const [assets, setAssets] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const assetData = {
      vehicles: {
        Common: {
          expectedOdds: 0,
          actualOdds: 0,
          expectedTotal: 0,
          actualTotal: 0,
          percentDifference: 0,
          power: 1000,
          snaking: 0,
        },
        Uncommon: {
          expectedOdds: 0,
          actualOdds: 0,
          expectedTotal: 0,
          actualTotal: 0,
          percentDifference: 0,
          power: 3500,
          snaking: 0,
        },
        Rare: {
          expectedOdds: 0,
          actualOdds: 0,
          expectedTotal: 0,
          actualTotal: 0,
          percentDifference: 0,
          power: 12500,
          snaking: 0,
        },
        Classic: {
          expectedOdds: 0,
          actualOdds: 0,
          expectedTotal: 0,
          actualTotal: 0,
          percentDifference: 0,
          power: 35000,
          snaking: 0,
        },
      },
      drivers: {
        Common: {
          expectedOdds: 0,
          actualOdds: 0,
          expectedTotal: 0,
          actualTotal: 0,
          percentDifference: 0,
          power: 1000,
          snaking: 0,
        },
        Uncommon: {
          expectedOdds: 0,
          actualOdds: 0,
          expectedTotal: 0,
          actualTotal: 0,
          percentDifference: 0,
          power: 3500,
          snaking: 0,
        },
        Rare: {
          expectedOdds: 0,
          actualOdds: 0,
          expectedTotal: 0,
          actualTotal: 0,
          percentDifference: 0,
          power: 12500,
          snaking: 0,
        },
        Classic: {
          expectedOdds: 0,
          actualOdds: 0,
          expectedTotal: 0,
          actualTotal: 0,
          percentDifference: 0,
          power: 35000,
          snaking: 0,
        },
        Sketch: {
          expectedOdds: 0,
          actualOdds: 0,
          expectedTotal: 0,
          actualTotal: 0,
          percentDifference: 0,
          power: 100000,
          snaking: 0,
        },
      },
      packs: {
        Standard: {
          template: 167252,
          numVehicles: 1,
          numDrivers: 9,
          vehicleOdds: {
            Common: 0.75,
            Uncommon: 0.17,
            Rare: 0.06,
            Classic: 0.02,
          },
          driverOdds: {
            Common: 0.72,
            Uncommon: 0.18,
            Rare: 0.07,
            Classic: 0.025,
            Sketch: 0.003,
          },
        },
        Rare: {
          template: 167320,
          numVehicles: 2,
          numDrivers: 18,
          vehicleOdds: {
            Common: 0.735,
            Uncommon: 0.178,
            Rare: 0.064,
            Classic: 0.023,
          },
          driverOdds: {
            Common: 0.705,
            Uncommon: 0.19,
            Rare: 0.0755,
            Classic: 0.026,
            Sketch: 0.0035,
          },
        },
        Gold: {
          template: 167332,
          numVehicles: 3,
          numDrivers: 27,
          vehicleOdds: {
            Common: 0.714,
            Uncommon: 0.19,
            Rare: 0.07,
            Classic: 0.026,
          },
          driverOdds: {
            Common: 0.687,
            Uncommon: 0.2,
            Rare: 0.081,
            Classic: 0.028,
            Sketch: 0.004,
          },
        },
        Legendary: {
          template: 167345,
          numVehicles: 4,
          numDrivers: 36,
          vehicleOdds: {
            Common: 0.696,
            Uncommon: 0.199,
            Rare: 0.076,
            Classic: 0.029,
          },
          driverOdds: {
            Common: 0.677,
            Uncommon: 0.2045,
            Rare: 0.084,
            Classic: 0.03,
            Sketch: 0.0045,
          },
        },
      },
      totals: {
        vehicles: {
          expectedOdds: 0,
          actualOdds: 0,
          expectedTotal: 0,
          actualTotal: 0,
          percentDifference: 0,
          power: 0,
          snaking: 0,
        },
        drivers: {
          expectedOdds: 0,
          actualOdds: 0,
          expectedTotal: 0,
          actualTotal: 0,
          percentDifference: 0,
          power: 0,
          snaking: 0,
        },
        packs: {
          issued: 0,
          opened: 0,
          remaining: 0,
          percentOpened: 0,
          percentRemaining: 0,
        },
      },
    };
    const requests = [];
    Object.values(assetData.packs).forEach((pack) => {
      requests.push(
        axios.get(
          AA.API +
            "atomicassets/v1/templates/novarallywax/" +
            pack.template +
            "/stats"
        )
      );
    });

    setLoading(true);
    Promise.allSettled(requests)
      .then((responses) => {
        const tot = assetData.totals;
        Object.values(assetData.packs).forEach((p, i) => {
          const res = responses[i].value.data.data;
          p.issued = parseInt(res.assets);
          p.opened = parseInt(res.burned);
          p.remaining = p.issued - p.opened;
          p.percentOpened = p.opened / p.issued;
          p.percentRemaining = 1 - p.percentOpened;
          Object.keys(p.vehicleOdds).forEach((key, i) => {
            assetData.vehicles[key].expectedTotal +=
              p.opened * p.numVehicles * p.vehicleOdds[key];
          });
          Object.keys(p.driverOdds).forEach((key, i) => {
            assetData.drivers[key].expectedTotal +=
              p.opened * p.numDrivers * p.driverOdds[key];
          });
          tot.vehicles.expectedTotal += p.opened * p.numVehicles;
          tot.drivers.expectedTotal += p.opened * p.numDrivers;
          tot.packs.issued += p.issued;
          tot.packs.opened += p.opened;
          tot.packs.remaining += p.remaining;
        });
        tot.packs.percentOpened = tot.packs.opened / tot.packs.issued;
        tot.packs.percentRemaining = 1 - tot.packs.percentOpened;
        return axios
          .get(
            AA.API +
              "atomicassets/v1/templates?" +
              "collection_name=novarallywax&page=1&limit=1000&has_assets=true&order=desc&sort=created"
          )
          .then((response) => {
            const res = response.data.data;
            res.forEach((v) => {
              const s = v.schema.schema_name;
              if (s === "drivers" || s === "vehicles") {
                const x = parseInt(v.issued_supply);
                assetData[s][v.immutable_data.Rarity].actualTotal += x;
                assetData.totals[s].actualTotal += x;
              }
            });
            const calc = (name) => {
              const obj = assetData[name];
              Object.keys(obj).forEach((key) => {
                const a = obj[key];
                a.expectedOdds =
                  a.expectedTotal / assetData.totals[name].expectedTotal;
                a.actualOdds =
                  a.actualTotal / assetData.totals[name].actualTotal;
                a.percentDifference =
                  (a.actualTotal - a.expectedTotal) / a.expectedTotal;
                a.snaking = a.actualTotal * a.power;
              });
              Object.keys(obj).forEach((key) => {
                assetData.totals[name].snaking += obj[key].snaking;
              });
            };
            calc("vehicles");
            calc("drivers");
            setAssets(assetData);
            setLoading(false);
          })
          .catch((err) => {
            console.log("ERROR 2");
            console.log(err);
          });
      })
      .catch((err) => {
        console.log("ERROR 1");
        console.log(err);
      });
  }, []);

  const formatPerc = (x) => {
    return (x * 100).toFixed(2) + "%";
  };

  const renderPacks = () => {
    const rowOrder = ["Standard", "Rare", "Gold", "Legendary"];
    return (
      <div>
        <div>
          {/* <br />
          <div
            style={{
              textDecoration: "underline",
              fontSize: "1.5em",
            }}
          >
            PACKS
          </div> */}
          <br />
          <table
            style={{
              margin: "auto",
              textAlign: "right",
              borderSpacing: "20px 8px",
            }}
          >
            <thead style={{ textAlign: "center" }}>
              <tr>
                <td style={{ textAlign: "left" }}>Pack Type</td>
                <td>Issued</td>
                <td>Open</td>
                <td>Remain</td>
                <td>Open</td>
                <td>Remain</td>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              <tr>
                <td colSpan="8" style={{ borderTop: "1px solid white" }}></td>
              </tr>
              {Object.keys(assets.packs)
                .sort((a, b) => {
                  return rowOrder.indexOf(a) - rowOrder.indexOf(b);
                })
                .map((row) => {
                  const r = assets.packs[row];
                  return row !== "Total" ? (
                    <tr key={"packs-" + row} style={{ textAlign: "right" }}>
                      <td style={{ textAlign: "left" }}>{row}</td>
                      <td>{r.issued.toLocaleString()}</td>
                      <td>{r.opened.toLocaleString()}</td>
                      <td>{r.remaining.toLocaleString()}</td>
                      <td>{formatPerc(r.percentOpened)}</td>
                      <td>{formatPerc(r.percentRemaining)}</td>
                    </tr>
                  ) : null;
                })}
              <tr>
                <td colSpan="8" style={{ borderTop: "1px solid white" }}></td>
              </tr>
              <tr style={{ textAlign: "right" }}>
                <td style={{ textAlign: "left" }}>TOTAL</td>
                <td>{assets.totals.packs.issued.toLocaleString()}</td>
                <td>{assets.totals.packs.opened.toLocaleString()}</td>
                <td>{assets.totals.packs.remaining.toLocaleString()}</td>
                <td>{formatPerc(assets.totals.packs.percentOpened)}</td>
                <td>{formatPerc(assets.totals.packs.percentRemaining)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  // const renderAssets = (title) => {
  //   const rowOrder = [
  //     "Common",
  //     "Uncommon",
  //     "Rare",
  //     "Classic",
  //     "Sketch",
  //     "Total",
  //   ];
  //   return assets.totals ? (
  //     <div>
  //       <br />
  //       <div
  //         style={{
  //           textDecoration: "underline",
  //           fontSize: "1.5em",
  //         }}
  //       >
  //         {title.toUpperCase()}
  //       </div>
  //       <br />
  //       <table
  //         style={{
  //           margin: "auto",
  //           textAlign: "right",
  //           borderSpacing: "20px 8px",
  //         }}
  //       >
  //         <thead style={{ textAlign: "center" }}>
  //           <tr>
  //             <td style={{ textAlign: "left" }}>Rarity</td>
  //             <td>Expected</td>
  //             <td>Actual</td>
  //             <td>Expected</td>
  //             <td>Actual</td>
  //             <td>Diff</td>
  //             <td>Power</td>
  //             <td>Snaking</td>
  //           </tr>
  //         </thead>
  //         <tbody style={{ textAlign: "center" }}>
  //           <tr>
  //             <td colSpan="8" style={{ borderTop: "1px solid white" }}></td>
  //           </tr>
  //           {Object.keys(assets[title])
  //             .sort((a, b) => {
  //               return rowOrder.indexOf(a) - rowOrder.indexOf(b);
  //             })
  //             .map((row) => {
  //               const r = assets[title][row];
  //               return (
  //                 <tr key={title + "-" + row} style={{ textAlign: "right" }}>
  //                   <td style={{ textAlign: "left" }}>{row}</td>
  //                   <td>{formatPerc(r.expectedOdds)}</td>
  //                   <td>{formatPerc(r.actualOdds)}</td>
  //                   <td>{Math.round(r.expectedTotal).toLocaleString()}</td>
  //                   <td>{r.actualTotal.toLocaleString()}</td>
  //                   <td>{formatPerc(r.percentDifference)}</td>
  //                   <td>{r.power.toLocaleString()}</td>
  //                   <td>{r.snaking.toLocaleString()}</td>
  //                 </tr>
  //               );
  //             })}
  //           <tr>
  //             <td colSpan="8" style={{ borderTop: "1px solid white" }}></td>
  //           </tr>
  //           <tr style={{ textAlign: "right" }}>
  //             <td style={{ textAlign: "left" }}>TOTAL</td>
  //             <td></td>
  //             <td></td>
  //             <td>{assets.totals[title].expectedTotal.toLocaleString()}</td>
  //             <td>{assets.totals[title].actualTotal.toLocaleString()}</td>
  //             <td></td>
  //             <td></td>
  //             <td>{assets.totals[title].snaking.toLocaleString()}</td>
  //           </tr>
  //         </tbody>
  //       </table>
  //     </div>
  //   ) : null;
  // };

  // const renderDrivers = () => {
  //   return renderAssets("drivers");
  // };
  // const renderVehicles = () => {
  //   return renderAssets("vehicles");
  // };

  const render = () => {
    return assets.totals ? (
      <div>
        <div>{renderPacks()}</div>
        {/* <div>{renderDrivers()}</div>
        <div>{renderVehicles()}</div>
        <br />
        <div>
          <div style={{ fontSize: "1.5em" }}>
            <div style={{ textDecoration: "underline" }}>
              TOTAL SNAKING POWER
            </div>
            <h3 style={{ margin: "4px" }}>
              {(
                assets.totals.vehicles.snaking + assets.totals.drivers.snaking
              ).toLocaleString()}
            </h3>
            per day
          </div>
        </div> */}
        <br />
        <br />
        <br />
        <br />
      </div>
    ) : null;
  };

  return (
    <Page title="Nova Rally Asset Data">
      <h2>Pack Data</h2>
      {loading ? <SnakeLoader /> : render()}
    </Page>
  );
};

export default NovaAssetData;
