import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import firebase from "../Firebase";
import { RPLANET_ELEMENTS } from "rplanet";
import SnakeLoader from "../SnakeLoader";
import Page from "../Page";
import { RPlanetContext } from "./RPlanetContextProvider";

const FTLeaderboard = (props) => {
  if (process.env.NODE_ENV === "development") {
    firebase.functions().useEmulator("localhost", 5001);
  }

  const rpContext = useContext(RPlanetContext);

  const [ftLeaders, setFtLeaders] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (rpContext.aetherPlayers) {
      let elementLeaders = Object.fromEntries(
        Object.entries(RPLANET_ELEMENTS).filter(([k, v]) => v.inventor !== "")
      );
      Object.keys(elementLeaders).forEach((symbol) => {
        elementLeaders[symbol].leaders = [];
      });
      elementLeaders = Object.fromEntries(
        Object.entries(elementLeaders).sort(([, a], [, b]) => b.cost - a.cost)
      );

      rpContext.aetherPlayers.forEach((ap) => {
        if (ap.ft) {
          Object.keys(ap.ft).forEach((el) => {
            if (elementLeaders[el]) {
              elementLeaders[el].leaders.push({
                wallet: ap.wallet,
                amount: ap.ft[el],
              });
            }
          });
        }
      });
      setFtLeaders(elementLeaders);
      setLoading(false);
    } else {
      rpContext.refresh();
      setLoading(true);
    }
  }, [rpContext]);

  const renderFtLeaderboard = () => {
    return (
      <div>
        {Object.entries(ftLeaders).map(([symbol, e]) => {
          return (
            <div
              key={symbol}
              style={{
                display: "inline-block",
                border: "4px solid transparent",
                maxWidth: "195px",
                verticalAlign: "top",
              }}
            >
              <table style={{ borderSpacing: "10px 2px" }}>
                <thead>
                  <tr>
                    <td colSpan="2">
                      <h3 style={{ margin: "6px" }}>{e.name}</h3>
                      <div style={{ fontSize: "75%" }}>({symbol})</div>
                      <h6 style={{ margin: "6px" }}>
                        {e.cost.toLocaleString()} AE
                      </h6>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {e.leaders && e.leaders.length > 0 ? (
                    e.leaders
                      .sort((a, b) => b.amount - a.amount)
                      .slice(0, 10)
                      .map((leader, i) => {
                        if (leader.amount > 0) {
                          const amount = parseInt(leader.amount);
                          // let a = leader.amount.toString().split(".");
                          // const amount = a[1] ? (
                          //   <span>
                          //     {a[0] + "."}
                          //     <span
                          //       style={{
                          //         fontSize: "small",
                          //       }}
                          //     >
                          //       {a[1]}
                          //     </span>
                          //   </span>
                          // ) : (
                          //   leader.amount
                          // );
                          return (
                            <tr key={symbol + "-" + i}>
                              <td style={{ textAlign: "left", width: "120px" }}>
                                <Link
                                  to={"/rplanet/aether/value/" + leader.wallet}
                                >
                                  {leader.wallet}
                                </Link>
                              </td>
                              <td style={{ textAlign: "right", width: "66px" }}>
                                {amount}
                              </td>
                            </tr>
                          );
                        } else {
                          return (
                            <tr key={symbol + "-" + i}>
                              <td></td>
                              <td></td>
                            </tr>
                          );
                        }
                      })
                  ) : (
                    <tr>
                      <td>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;
                      </td>
                      <td>&nbsp;&nbsp;&nbsp;</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Page title="RPlanet FT Leaders">
      <h2>FT Leaders</h2>
      <div>
        (updated periodically, to add or update someone,{" "}
        <Link to="/rplanet/aether/value">
          <nobr>check their aether value</nobr>
        </Link>
        )
      </div>
      <br />
      <Link to="/rplanet/aether/leaderboard">Aether Leaders</Link>
      &nbsp;&nbsp;&nbsp;
      <Link to="/rplanet/land/leaderboard">Land Leaders</Link>
      <br />
      <br />
      {loading || ftLeaders.length === 0 ? (
        <SnakeLoader />
      ) : (
        renderFtLeaderboard()
      )}
      <br />
      <br />
      <br />
      <br />
    </Page>
  );
};

export default FTLeaderboard;
