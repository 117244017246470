const linkNewWindow = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

const renderLinkNewWindow = (url, title, underline) => {
  let textdec = {};
  if (underline) {
    textdec = { textDecoration: "underline" };
  }
  return (
    <span
      onClick={() => linkNewWindow(url)}
      style={{
        ...textdec,
        cursor: "pointer",
        fontWeight: "bold",
      }}
    >
      {title}
    </span>
  );
};

export { linkNewWindow, renderLinkNewWindow };
