import React, { useState, useEffect } from "react";
import firebase from "../Firebase";

const RPlanetContext = React.createContext([]);

const RPlanetContextProvider = ({ children }) => {
  // if (typeof window === "undefined" || !window["_init"]) {
  //   db.useEmulator("localhost", 8080);
  //   if (typeof window !== "undefined") {
  //     window["_init"] = true;
  //   }
  // }

  const [state, setState] = useState({
    lastUpdate: null,
    doUpdate: false,
    data: null,
  });

  const refreshContext = () => {
    if (!state.lastUpdate || Date.now() - state.lastUpdate > 5 * 60 * 1000) {
      setState((old) => {
        return { ...old, doUpdate: true };
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (state.doUpdate) {
        setState((old) => {
          return { ...old, lastUpdate: Date.now(), doUpdate: false };
        });
        await firebase
          .firestore()
          .collection("aether-player")
          .get()
          .then((querySnapshot) => {
            const a = [];
            querySnapshot.forEach((doc) => {
              const d = doc.data().data;
              a.push({
                wallet: doc.id,
                aehr: d.aehr,
                aether: d.aether,
                unclaimed: d.unclaimed,
                ft: d.ft,
                ftTotal: d.ftTotal,
                total: d.total,
              });
            });
            setState((old) => {
              return {
                ...old,
                data: a,
              };
            });
          });
      }
    };
    fetchData();
  }, [state.doUpdate]);

  return (
    <RPlanetContext.Provider
      value={{
        aetherPlayers: state.data,
        refresh: refreshContext,
      }}
    >
      {children}
    </RPlanetContext.Provider>
  );
};

export { RPlanetContext, RPlanetContextProvider };
