import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Page from "../Page";
import WalletInput from "../Utilities/WalletInput";
import { calculateHourlyRates } from "../Utilities/Calculate";
import useTokenPrices from "../Utilities/useTokenPrices";
import { rpc } from "../Utilities/RPC";
import {
  AccountBalances,
  AlcorLinks,
  TokenPriceTable,
} from "../Utilities/Display";
import useBalances from "../Utilities/useBalances";

const OliveStaking = (props) => {
  const [tokenPrices, getTokenPrice] = useTokenPrices();
  const [wallet, setWallet] = useState(props.match.params.wallet || "");
  const balances = useBalances(wallet, "olivelandtok", "olv");
  const history = useHistory();
  const [actualStaking, setActualStaking] = useState("");

  const handleSetWallet = (w) => {
    history.push("/olive/staking/" + w);
    setWallet(w);
  };

  useEffect(() => {
    const run = async () => {
      if (wallet && wallet.trim() && tokenPrices) {
        setActualStaking({});

        const r1 = await rpc.get_table_rows({
          json: true,
          code: "olivelandstk",
          scope: "olivelandstk",
          table: "staking",
          lower_bound: "",
          upper_bound: "",
          index_position: 1,
          key_type: "i64",
          limit: 100,
          reverse: false,
          show_payer: false,
        });
        const poolTable = r1.rows;

        const r2 = await rpc.get_table_rows({
          json: true,
          code: "olivelandstk",
          scope: "olivelandstk",
          table: "user",
          lower_bound: wallet,
          upper_bound: wallet,
          index_position: 1,
          key_type: "i64",
          limit: 1,
          reverse: false,
          show_payer: false,
        });
        if (r2.rows[0]) {
          const userData = calculateHourlyRates(r2.rows[0], poolTable);
          const result = {
            olv: {
              hour: 0,
              day: 0,
              week: 0,
              month: 0,
              year: 0,
            },
          };
          userData.data.forEach((pool) => {
            const total = parseInt(pool.hourlyTotal);
            result.olv.hour += total;
            result.olv.day += total * 24;
            result.olv.week += total * 24 * 7;
            result.olv.month += parseInt((total * 24 * 365) / 12);
            result.olv.year += total * 24 * 365;
          });
          setActualStaking(result);
        }
      }
    };
    run();
  }, [wallet, tokenPrices]);

  const renderStakingPower = () => {
    let waxTotal = {};
    let usdTotal = {};
    if (Object.keys(actualStaking).length > 0) {
      waxTotal = {
        hour: actualStaking.olv.hour * getTokenPrice("OLVWAX"),
        day: actualStaking.olv.day * getTokenPrice("OLVWAX"),
        week: actualStaking.olv.week * getTokenPrice("OLVWAX"),
        month: actualStaking.olv.month * getTokenPrice("OLVWAX"),
        year: actualStaking.olv.year * getTokenPrice("OLVWAX"),
      };
      Object.entries(waxTotal).forEach(([k, v]) => {
        usdTotal[k] = v * getTokenPrice("WAXUSD");
      });
    }

    const formatPrice = (num) => {
      return num.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    };

    const renderRow = (time) => {
      return (
        <tr>
          <td style={{ textAlign: "left" }}>{time}</td>
          <td>{actualStaking.olv[time].toLocaleString()}</td>
          <td>{formatPrice(waxTotal[time])}</td>
          <td>{formatPrice(usdTotal[time])}</td>
        </tr>
      );
    };

    return (
      <div>
        <div>
          {Object.keys(actualStaking).length === 0 ? (
            wallet ? (
              <h2>
                NO STAKING DATA <br />
                AVAILABLE FOR THIS USER
              </h2>
            ) : null
          ) : (
            <React.Fragment>
              <div>
                <h2 style={{ marginBottom: "8px" }}>STAKING POWER:</h2>
                <table style={{ margin: "auto", borderSpacing: "10px 4px" }}>
                  <thead>
                    <tr>
                      <th style={{ textAlign: "left" }}>time</th>
                      <th style={{ textAlign: "right" }}>OLV</th>
                      <th style={{ textAlign: "right" }}>WAX</th>
                      <th style={{ textAlign: "right" }}>USD</th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: "right" }}>
                    {renderRow("hour")}
                    {renderRow("day")}
                    {renderRow("week")}
                    {renderRow("month")}
                    {renderRow("year")}
                  </tbody>
                </table>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  };

  const renderBalance = () => {
    return (
      <React.Fragment>
        <TokenPriceTable
          coinPriceArray={[
            ["OLV", getTokenPrice("OLVWAX")],
            ["WAX", getTokenPrice("WAXUSD")],
          ]}
        />
        <br />
        <AlcorLinks coinContractArray={[["olv", "olivelandtok"]]} />
        <br />
        {wallet ? <AccountBalances balanceArray={balances} /> : null}
      </React.Fragment>
    );
  };

  return (
    <Page title="Oliveland Staking">
      <h2>Staking</h2>
      <Link to="/olive/leaderboard">Staking Leaderboard</Link>
      &nbsp;&nbsp;
      <Link to="/olive/values">Staking Values</Link>
      <br />
      <br />
      <WalletInput
        wallet={props.match.params.wallet || ""}
        setWallet={handleSetWallet}
        buttonText="OLIVE ME"
        loading={false}
      />
      <br />
      {tokenPrices ? renderBalance() : null}
      {wallet && tokenPrices ? renderStakingPower() : null}
      <br />
      <br />
    </Page>
  );
};

export default OliveStaking;
