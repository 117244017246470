import React from "react";
import { Link } from "react-router-dom";
import Page from "../Page";

const Novopangea = () => {
  return (
    <Page title="Novopangea">
      <h2>
        <Link to="/novo/wake">Wake Workers</Link>
        <br />
        <br />
      </h2>
    </Page>
  );
};

export default Novopangea;
